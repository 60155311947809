import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@env/environment";
import { SmartAssistantOrchestratorSearchRequest } from "@models/utilities/smart-assistant/orchestrator/smart-assistant-orchestrator-search-request.dto";
import { Page } from "@models/common/page";
import { SmartAssistantOrchestratorInfo } from "@models/utilities/smart-assistant/orchestrator/smart-assistant-orchestrator-info.dto";
import { SaveSmartAssistantOrchestratorRequest } from "@models/utilities/smart-assistant/orchestrator/save-smart-assistant-orchestrator-request.dto";
import { ChangeSmartAssistantOrchestratorContextStatusRequest } from "@models/utilities/smart-assistant/orchestrator/context/change-smart-assistant-orchestrator-context-status-request.dto";
import { SaveSmartAssistantOrchestratorContextRequest } from "@models/utilities/smart-assistant/orchestrator/context/save-smart-assistant-orchestrator-context-request.dto";
import { ChangeSmartAssistantOrchestratorStatusRequest } from "@models/utilities/smart-assistant/orchestrator/change-smart-assistant-orchestrator-status-request.dto";
import { SmartAssistantOrchestratorContextInfo } from '@models/utilities/smart-assistant/orchestrator/context/smart-assistant-orchestrator-context-info.dto';
import {
  ChangeSmartAssistantOrchestratorContextDefaultStatusRequest
} from '@models/utilities/smart-assistant/orchestrator/context/change-smart-assistant-orchestrator-context-default-status-request.dto';

@Injectable({
  providedIn: 'root'
})
export class SmartAssistantOrchestratorService {

  private apiUrl: string = `${environment.apiUrl}/smart-assistant-orchestrators`;

  constructor(
    private http: HttpClient,
  ) {

  }

  searchSmartAssistantOrchestrators(smartAssistantOrchestratorSearch: SmartAssistantOrchestratorSearchRequest): Observable<Page<SmartAssistantOrchestratorInfo>> {
    const endpoint = `${this.apiUrl}/search-smart-assistant-orchestrators`;
    return this.http.post<Page<SmartAssistantOrchestratorInfo>>(endpoint, smartAssistantOrchestratorSearch);
  }

  getSmartAssistantOrchestrator(id: number): Observable<SmartAssistantOrchestratorInfo> {
    const endpoint = `${this.apiUrl}/get-smart-assistant-orchestrator/${id}`;
    return this.http.get<SmartAssistantOrchestratorInfo>(endpoint);
  }

  saveSmartAssistantOrchestrator(saveSmartAssistantRequest: SaveSmartAssistantOrchestratorRequest): Observable<SmartAssistantOrchestratorInfo> {
    const endpoint = `${this.apiUrl}/save-smart-assistant-orchestrator`;
    return this.http.post<SmartAssistantOrchestratorInfo>(endpoint, saveSmartAssistantRequest);
  }

  deleteSmartAssistantOrchestrator(id: number): Observable<void> {
    const endpoint = `${this.apiUrl}/delete-smart-assistant-orchestrator/${id}`;
    return this.http.delete<void>(endpoint);
  }

  changeSmartAssistantOrchestratorStatus(changeSmartAssistantOrchestratorStatusRequest: ChangeSmartAssistantOrchestratorStatusRequest): Observable<any> {
    const endpoint = `${this.apiUrl}/change-smart-assistant-orchestrator-status`;
    return this.http.post<any>(endpoint, changeSmartAssistantOrchestratorStatusRequest);
  }

  saveSmartAssistantOrchestratorContext(saveSmartAssistantOrchestratorContextRequest: SaveSmartAssistantOrchestratorContextRequest): Observable<SmartAssistantOrchestratorInfo> {
    const endpoint = `${this.apiUrl}/save-smart-assistant-orchestrator-context`;
    return this.http.post<SmartAssistantOrchestratorInfo>(endpoint, saveSmartAssistantOrchestratorContextRequest);
  }

  deleteSmartAssistantOrchestratorContext(id: number): Observable<SmartAssistantOrchestratorInfo> {
    const endpoint = `${this.apiUrl}/delete-smart-assistant-orchestrator-context/${id}`;
    return this.http.delete<SmartAssistantOrchestratorInfo>(endpoint);
  }

  changeSmartAssistantOrchestratorContextStatus(changeSmartAssistantOrchestratorContextStatusRequest: ChangeSmartAssistantOrchestratorContextStatusRequest): Observable<any> {
    const endpoint = `${this.apiUrl}/change-smart-assistant-orchestrator-context-status`;
    return this.http.post<any>(endpoint, changeSmartAssistantOrchestratorContextStatusRequest);
  }

  changeSmartAssistantOrchestratorContextDefaultStatus(changeSmartAssistantOrchestratorContextDefaultStatusRequest: ChangeSmartAssistantOrchestratorContextDefaultStatusRequest): Observable<any> {
    const endpoint = `${this.apiUrl}/change-smart-assistant-orchestrator-context-default-status`;
    return this.http.post<any>(endpoint, changeSmartAssistantOrchestratorContextDefaultStatusRequest);
  }

}