export class ChangeDefaultTeamRequest {

  shopId: number;
  teamId: number;

  constructor(shopId: number, teamId: number) {
    this.shopId = shopId;
    this.teamId = teamId;
  }

}