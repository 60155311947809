<a
  class="nav-link d-flex align-items-center justify-content-center chat-list-item cursor-pointer p-2"
  [ngClass]="{ 
    'read': chat.seen,
    'unread': !chat.seen,
    'active': chat.selected,
  }"
  data-bs-toggle="tab"
  data-chat-thread="data-chat-thread"
  role="tab">

  <div class="
    avatar avatar-xl 
    status-online 
    position-relative 
    me-2">
    <div
      *ngIf="!chat.profilePictureUrl"
      class="avatar-name rounded-circle"
      [ngStyle]="{'color': chat.color}">
      <span>{{ chat.chatName[0] ?? chat.chatPhoneNumber }}</span>
    </div>
    <img *ngIf="chat.profilePictureUrl"
         [src]="chat.profilePictureUrl"
         class="rounded-circle border border-2 border-white">
    <i
      class="fa-brands chat-type-icon"
      [ngClass]="{
        'fa-whatsapp text-success': chat?.channel === ChannelType.WhatsApp || chat?.channel === ChannelType.WhatsAppWeb,
        'fa-facebook-messenger text-primary': chat?.channel === ChannelType.Messenger,
      }">
    </i>
  </div>
  <div class="flex-1 d-xl-block">
    <div class="d-flex justify-content-between align-items-center">
      <h5 class="text-900 fw-normal name text-truncate chat-name">
        {{ chat.chatAlias ? chat.chatAlias : chat.chatName }}
      </h5>
      <p class="fs--1 text-800 mb-0" *ngIf="chat.lastMessageSentTime">
        {{ chat.lastMessageSentTime }}
      </p>
      <p class="fs--2 text-800 mb-0" *ngIf="!chat.lastMessageSentTime">
        {{ chat.createdAt | date: 'dd/MM/yyyy HH:mm:ss' }}
      </p>
    </div>
    <div class="d-flex justify-content-between">
      <p class="fs--1 mb-0 text-600 message text-truncate last-message-label"
         [class.text-dark]="!chat.seen"
         [class.no-messages-label]="!chat.lastMessageType">
        <ng-container *ngIf="chat.lastMessageStatusClass && chat.lastMessageStatus !== MessageStatus.Pending">
          <ng-container *ngFor="let status of Object.values(MessageStatus)">
            <ng-container *ngIf="chat.lastMessageStatus === status">
              <i class="fa fs--1" [ngClass]="chat.lastMessageStatusClass"></i>
            </ng-container>
          </ng-container>
        </ng-container>
        {{ chat.lastMessageTypeLabel }}
      </p>

      <div *ngIf="!isMobile" class="d-flex align-self-center"
           [placement]="isMobile ? 'left' : 'right'"
           (mouseenter)="openPopover(p2)"
           (mouseleave)="closePopover(p2)"
           [autoClose]="false"
           ngbPopover="Este es un popover personalizado"
           popoverTitle="Previsualización"
           container="body"
           [ngbPopover]="popoverContent"
           #p2="ngbPopover">
        <span class="fa-solid fa-circle text-success me-1" [hidden]="chat.seen"></span>
        <span class="fa-solid fa-circle text-warning me-1" [hidden]="chat.answered || !chat.seen"></span>
        <span class="fa-solid fa-note-sticky text-warning me-1" [hidden]="!chat.note"></span>
        <span class="fas fa-person-running-fast text-warning me-1" [hidden]="!chat.humanAssistantAttention"></span>
        <span class="fas fa-tags" [hidden]="!chat.chatTags || chat.chatTags.length === 0"></span>
        <ng-template #popoverContent>
          <div class="chat-info-popover"
               (mouseenter)="activateOpenedPopover()"
               (mouseleave)="closePopover(p2)">
            <div class="mb-3">
              <h5>
                <i class="fa fa-tags fs--1"></i>
                {{ chat.chatTags?.length ? 'Etiquetas' : 'Sin etiquetas' }}
              </h5>
              <span *ngFor="let chatTag of chat.chatTags"
                    role="button"
                    class="badge badge-phoenix fs--2 me-1"
                    [ngStyle]="{
                  'color': chatTag.color,
                  'border': '1px solid ' + chatTag.color,
                }">
                {{ chatTag.name }}
                <i class="fas fa-xmark tag-xmark-icon badge-icon"></i>
              </span>
            </div>
            <div class="mb-3">
              <h5>
                <i class="fa fa-note-sticky fs--1 text-warning"></i>
                {{ chat.note ? 'Notas' : 'Sin notas' }}
              </h5>
              <pre
                class="fs--1 mb-0 chat-note-content scrollbar"
                *ngIf="chat.note">{{ chat.note }}</pre>
            </div>
            <div class="mb-3">
              <h5>
                <i class="fa fa-user fs--1 text-primary"></i>
                {{ chat.userId ? 'Asesor' : 'Sin asesor' }}
              </h5>
              <p class="fs--1 mb-0" *ngIf="chat.userId">
                {{ chat.user.name }}
              </p>
            </div>
          </div>
        </ng-template>

      </div>
    </div>
    <div class="col d-flex flex-wrap mt-2" *ngIf="!isMobile">
      <ng-container *ngIf="chat.chatTags?.length > 0">
        <span *ngFor="let chatTag of chat.chatTags?.slice(0, 5)"
              role="button"
              class="badge bg-light text-dark me-1 my-1"
              [ngStyle]="{
                'border-color': chatTag.color,
                'color': chatTag.color,
                'border': '1px solid ' + chatTag.color,
                'font-size': '0.75rem',
                'padding': '0.3rem 0.5rem',
                'border-radius': '6px'
              }"
              [title]="chatTag.name"> {{ chatTag.name.toUpperCase() }}
        </span>
      </ng-container>
      <!-- Si hay más de 5 etiquetas, muestra un badge que indica cuántas etiquetas faltan -->
      <ng-container *ngIf="chat.chatTags?.length > 5">
        <span class="badge bg-secondary text-dark my-auto" role="button" title="Más etiquetas">
            +{{ chat.chatTags.length - 5 }}
        </span>
      </ng-container>
    </div>


    <div *ngIf="isMobile">
         <span
           *ngFor="let chatTag of chat.chatTags?.slice(0, 3)"
           role="button"
           class="badge bg-light text-dark border me-1 my-1"
           [ngStyle]="{
              'border-color': chatTag.color,
              'color': chatTag.color,
              'border': '1px solid ' + chatTag.color,
              'font-size': '0.75rem',
              'padding': '0.3rem 0.5rem',
              'border-radius': '6px'
            }"
         [title]="chatTag.name"> {{ chatTag.name }}
      </span>
      <ng-container *ngIf="chat.chatTags.length > 3">
      <span class="badge bg-secondary text-dark my-auto" role="button" title="Más etiquetas">
          +{{ chat.chatTags.length - 3 }}
      </span>
      </ng-container>
    </div>
  </div>
</a>
