<div class="row mb-3">
  <div class="col-12">
    <div class="row mb-1">
      <div class="col">
        <h4>
          Botones
          <span
            role="button"
            class="badge badge-phoenix badge-phoenix-info fs--2">
            Opcional
          </span>
        </h4>
        <p class="mb-1 fs--1">
          Crea botones que permitan a los clientes responder a tu mensaje o realizar una acción. (Máximo 10 botones)
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <!-- Selector de tipo de botones -->
        <div class="dropdown mb-2">
          <button class="btn btn-secondary dropdown-toggle"
                  type="button" id="dropdownMenuButton"
                  data-bs-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-plus"></i>
            Agregar un botón
          </button>
          <div class="dropdown-menu pt-0 pb-2" aria-labelledby="dropdownMenuButton">
            <!-- Encabezado del menú -->
            <h6 class="dropdown-header text-dark">
              Botones de respuesta rápida
            </h6>
            <a class="dropdown-item"
               [ngClass]="{
                'disabled text-muted': false,
                'cursor-pointer text-dark': true
               }"
               (click)="addCustomButton()">
              <div class="d-flex flex-column">
                <span>
                  <i class="fa-duotone fa-text text-primary me-1"></i>
                  Personalizado
                </span>
              </div>
            </a>

            <!-- Divisor -->
            <div class="dropdown-divider"></div>

            <!-- Encabezado del menú -->
            <h6 class="dropdown-header text-dark">
              Botones de llamada a la acción
            </h6>
            <a class="dropdown-item"
               [ngClass]="{
                'disabled text-muted': buttonTypeCounter[CallToActionType.GoToWebSite] === 2,
                'cursor-pointer text-dark': buttonTypeCounter[CallToActionType.GoToWebSite] < 2
               }"
               (click)="addCallToActionButton(CallToActionType.GoToWebSite)">
              <div class="d-flex flex-column">
                <span>
                  <i class="fa-duotone fa-globe-pointer text-info me-1"></i>
                  Ir al sitio web
                </span>
                <span class="text-muted">
                  2 botones como máximo
                </span>
              </div>
            </a>
            <a class="dropdown-item"
               [ngClass]="{
                'disabled text-muted': buttonTypeCounter[CallToActionType.CallToPhoneNumber] == 1 || buttonTypeCounter[CallToActionType.CallToWhatsAppNumber] == 1,
                'cursor-pointer text-dark': buttonTypeCounter[CallToActionType.CallToPhoneNumber] < 1 && buttonTypeCounter[CallToActionType.CallToWhatsAppNumber] < 1
               }"
               (click)="addCallToActionButton(CallToActionType.CallToPhoneNumber)">
              <div class="d-flex flex-column">
                <div>
                  <i class="fa-duotone fa-phone text-primary me-1"></i>
                  Llamar al número de teléfono
                </div>
                <span class="text-muted">
                  1 botón como máximo
                </span>
              </div>
            </a>
            <a class="dropdown-item"
               [ngClass]="{
                'disabled text-muted': buttonTypeCounter[CallToActionType.CallToWhatsAppNumber] == 1 || buttonTypeCounter[CallToActionType.CallToPhoneNumber] == 1,
                'cursor-pointer text-dark': buttonTypeCounter[CallToActionType.CallToWhatsAppNumber] < 1 && buttonTypeCounter[CallToActionType.CallToPhoneNumber] < 1
               }"
               (click)="addCallToActionButton(CallToActionType.CallToWhatsAppNumber)">
              <div class="d-flex flex-column">
                <span>
                  <i class="fab fa-whatsapp text-success me-1"></i>
                  Llamar en WhatsApp
                </span>
                <span class="text-muted">
                  1 botón como máximo
                </span>
              </div>
            </a>
            <a class="dropdown-item"
               [ngClass]="{
                'disabled text-muted': buttonTypeCounter[CallToActionType.CopyOfferCode] == 1,
                'cursor-pointer text-dark': buttonTypeCounter[CallToActionType.CopyOfferCode] < 1
               }"
               (click)="addCallToActionButton(CallToActionType.CopyOfferCode)">
              <div class="d-flex flex-column">
                <span>
                  <i class="fa-duotone fa-copy text-primary me-1"></i>
                  Copiar código de oferta
                </span>
                <span class="text-muted">
                  1 botón como máximo
                </span>
              </div>
            </a>
          </div>
        </div>
        <p>
          <i class="fa-duotone fa-lightbulb me-1"></i>
          Si agregas más de tres botones, aparecerán en una lista.
        </p>
      </div>
    </div>
    <div class="row mb-2" *ngIf="callToActionButtons?.length > 0">
      <div class="col-12">
        <div class="card">
          <div class="card-header py-2">
            <h5 class="card-title mb-0">
              <button class="btn btn-sm btn-phoenix-secondary px-2" *ngIf="customButtons?.length > 0">
                <i class="fa-solid fa-arrow-up-arrow-down"></i>
              </button>
              Llamada a la acción
            </h5>
          </div>
          <div class="card-body">
            <pre *ngIf="false">{{ buttonTypeCounter | json }}</pre>
            <div class="row">
              <ng-container *ngFor="let goToWebSiteActionButton of getActionButtonsByType(CallToActionType.GoToWebSite)">
                <ng-container *ngTemplateOutlet="goToWebSiteActionButtonTemplate; context: { $implicit: goToWebSiteActionButton }">
                </ng-container>
              </ng-container>
            </div>
            <div class="row">
              <ng-container *ngFor="let callToPhoneNumberActionButton of getActionButtonsByType(CallToActionType.CallToPhoneNumber)">
                <ng-container *ngTemplateOutlet="callToPhoneNumberButtonTemplate; context: { $implicit: callToPhoneNumberActionButton }">
                </ng-container>
              </ng-container>
            </div>
            <div class="row">
              <ng-container *ngFor="let callToWhatsAppActionButton of getActionButtonsByType(CallToActionType.CallToWhatsAppNumber)">
                <ng-container *ngTemplateOutlet="callToWhatsAppButtonTemplate; context: { $implicit: callToWhatsAppActionButton }">
                </ng-container>
              </ng-container>
            </div>
            <div class="row">
              <ng-container *ngFor="let copyOfferCodeActionButton of getActionButtonsByType(CallToActionType.CopyOfferCode)">
                <ng-container *ngTemplateOutlet="copyOfferCodeButtonTemplate; context: { $implicit: copyOfferCodeActionButton }">
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="customButtons?.length > 0">
      <div class="col-12">
        <div class="card">
          <div class="card-header py-2">
            <h5 class="card-title mb-0">
              <button class="btn btn-sm btn-phoenix-secondary px-2" *ngIf="callToActionButtons?.length > 0">
                <i class="fa-solid fa-arrow-up-arrow-down"></i>
              </button>
              Respuesta rápida
            </h5>
          </div>
          <div class="card-body">
            <div class="row">
              <ng-container *ngFor="let customButton of customButtons">
                <ng-container *ngTemplateOutlet="customButtonTemplate; context: { $implicit: customButton }">
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #customButtonTemplate let-customButton>
  <form>
    <div class="row g-3 d-flex align-items-end mb-2">
      <div class="col-auto">
        <label for="customActionType" class="form-label">
          Tipo de acción
        </label>
        <select class="form-select" id="customActionType" disabled>
          <option>
            Personalizado
          </option>
        </select>
      </div>
      <div class="col-auto">
        <label for="customButtonText" class="form-label">
          Texto del botón
        </label>
        <input type="text"
               name="text"
               (input)="notifyChanges()"
               [(ngModel)]="customButton.text"
               class="form-control"
               id="customButtonText"
               maxlength="25"
               placeholder="Escribe el texto del botón aquí">
      </div>
      <div class="col-auto">
        <button type="button" class="btn btn-phoenix-danger"
                (click)="removeCustomButton(customButton)">
          <i class="fa-duotone fa-trash-xmark"></i>
        </button>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #goToWebSiteActionButtonTemplate let-goToWebSiteActionButton>
  <form>
    <div class="row g-3 d-flex align-items-end mb-2">
      <div class="col-auto">
        <label for="actionType" class="form-label">
          Tipo de acción
        </label>
        <select class="form-select" id="actionType" disabled>
          <option>
            Ir al sitio web
          </option>
        </select>
      </div>
      <div class="col-auto">
        <label for="buttonText" class="form-label">
          Texto del botón
        </label>
        <input type="text"
               name="text"
               (input)="notifyChanges()"
               [(ngModel)]="goToWebSiteActionButton.text"
               class="form-control"
               id="buttonText"
               maxlength="25"
               placeholder="Escribe el texto del botón aquí">
      </div>
      <div class="col-auto">
        <label for="urlType" class="form-label">
          Tipo de URL
        </label>
        <select class="form-select" id="urlType"
                name="urlType"
                [(ngModel)]="goToWebSiteActionButton.urlType">
          <option [value]="CallToActionUrlType.Static">
            Estática
          </option>
          <option [value]="CallToActionUrlType.Dynamic">
            Dinámica
          </option>
        </select>
      </div>
      <div class="col-auto">
        <label for="websiteUrl" class="form-label">
          URL del sitio web
        </label>
        <input type="url"
               name="websiteUrl"
               (input)="notifyChanges()"
               [(ngModel)]="goToWebSiteActionButton.websiteUrl"
               class="form-control"
               id="websiteUrl"
               maxlength="2000"
               placeholder="https://www.ejemplo.com/">
      </div>
      <div class="col-auto">
        <button type="button" class="btn btn-phoenix-danger"
                (click)="removeCallToActionButton(goToWebSiteActionButton)">
          <i class="fa-duotone fa-trash-xmark"></i>
        </button>
      </div>
    </div>
    <div class="row g-3 d-flex align-items-end mb-2"
         *ngIf="goToWebSiteActionButton.urlType === CallToActionUrlType.Dynamic">
      <div class="col-auto">
        <p class="mb-1">
          Para ayudarnos a revisar tu plantilla de mensaje, agrega un ejempl ode la URL del sitio web.
          No uses información real del cliente.
        </p>
        <label for="sampleWebsiteUrl" class="form-label">
          Agregar URL de muestra
        </label>
        <input type="text"
               name="text"
               (input)="notifyChanges()"
               [(ngModel)]="goToWebSiteActionButton.example"
               class="form-control"
               id="sampleWebsiteUrl"
               maxlength="25"
               [placeholder]="'Ingresa la URL completa de ' + goToWebSiteActionButton.websiteUrl + '\{\{1\}\}'">
      </div>
    </div>
  </form>
</ng-template>

<ng-template #callToPhoneNumberButtonTemplate let-callToPhoneNumberActionButton>
  <form>
    <div class="row g-3 d-flex align-items-end mb-2">
      <div class="col-auto">
        <label for="callToPhoneNumberButtonActionType" class="form-label">
          Tipo de acción
        </label>
        <select class="form-select" id="callToPhoneNumberButtonActionType" disabled>
          <option>
            Llamar al número de teléfono
          </option>
        </select>
      </div>
      <div class="col-auto">
        <label for="callToPhoneNumberButtonText" class="form-label">
          Texto del botón ({{callToPhoneNumberActionButton.text?.length}}/25)
        </label>
        <input type="text"
               name="text"
               (input)="notifyChanges()"
               [(ngModel)]="callToPhoneNumberActionButton.text"
               class="form-control"
               id="callToPhoneNumberButtonText"
               maxlength="25"
               placeholder="Escribe el texto del botón aquí">
      </div>
      <div class="col-auto">
        <label class="form-label">
          País
        </label>
        <app-phone-number-typeahead
          [floating]="false"
          [countryCodeChosen]="callToPhoneNumberActionButton"
          (countryCodeChosenEvent)="setSelectedCountryCode($event)">
        </app-phone-number-typeahead>
      </div>
      <div class="col-auto">
        <label for="callToPhoneNumberButtonPhoneNumber" class="form-label">
          Número de teléfono ({{callToPhoneNumberActionButton.phoneNumber?.length}}/20)
        </label>
        <input type="text"
               name="phoneNumber"
               (input)="notifyChanges()"
               [(ngModel)]="callToPhoneNumberActionButton.phoneNumber"
               (keydown)="validatePhoneNumber($event)"
               maxLength="20"
               class="form-control"
               id="callToPhoneNumberButtonPhoneNumber"
               maxlength="20">
      </div>
      <div class="col-auto">
        <button type="button" class="btn btn-phoenix-danger"
                (click)="removeCallToActionButton(callToPhoneNumberActionButton)">
          <i class="fa-duotone fa-trash-xmark"></i>
        </button>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #callToWhatsAppButtonTemplate let-callToWhatsAppActionButton>
  <form>
    <div class="row g-3 d-flex align-items-end mb-2">
      <div class="col-auto">
        <label for="callToWhatsAppButtonActionType" class="form-label">
          Tipo de acción
        </label>
        <select class="form-select" id="callToWhatsAppButtonActionType" disabled>
          <option>
            Llamar a número de WhatsApp
          </option>
        </select>
      </div>
      <div class="col-auto">
        <label for="callToWhatsAppButtonText" class="form-label">
          Texto del botón ({{callToWhatsAppActionButton.text?.length}}/25)
        </label>
        <input type="text"
               name="text"
               (input)="notifyChanges()"
               [(ngModel)]="callToWhatsAppActionButton.text"
               class="form-control"
               id="callToWhatsAppButtonText"
               maxlength="25"
               placeholder="Escribe el texto del botón aquí">
      </div>
      <div class="col-auto">
        <button type="button" class="btn btn-phoenix-danger"
                (click)="removeCallToActionButton(callToWhatsAppActionButton)">
          <i class="fa-duotone fa-trash-xmark"></i>
        </button>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #copyOfferCodeButtonTemplate let-copyOfferCodeActionButton>
  <form>
    <div class="row g-3 d-flex align-items-end mb-2">
      <div class="col-auto">
        <label for="copyOfferCodeButtonActionType" class="form-label">
          Tipo de acción
        </label>
        <select class="form-select" id="copyOfferCodeButtonActionType" disabled>
          <option>
            Copiar código de oferta
          </option>
        </select>
      </div>
      <div class="col-auto">
        <label for="copyOfferCodeButtonText" class="form-label">
          Texto del botón
        </label>
        <input type="text"
               name="text"
               (input)="notifyChanges()"
               [(ngModel)]="copyOfferCodeActionButton.text"
               [attr.disabled]="true"
               class="form-control"
               id="copyOfferCodeButtonText"
               maxlength="25"
               placeholder="Escribe el texto del botón aquí">
      </div>
      <div class="col-auto">
        <label for="copyOfferCodeButtonCode" class="form-label">
          Código de oferta ({{copyOfferCodeActionButton.offerCode?.length}}/15)
        </label>
        <input type="text"
               name="phoneNumber"
               placeholder="Ingresa una muestra"
               (input)="notifyChanges()"
               [(ngModel)]="copyOfferCodeActionButton.offerCode"
               maxLength="20"
               class="form-control"
               id="copyOfferCodeButtonCode"
               maxlength="15">
      </div>
      <div class="col-auto">
        <button type="button" class="btn btn-phoenix-danger"
                (click)="removeCallToActionButton(copyOfferCodeActionButton)">
          <i class="fa-duotone fa-trash-xmark"></i>
        </button>
      </div>
    </div>
  </form>
</ng-template>