<footer class="pb-4 bg-primary-3 text-light" id="footer">
  <div class="container">
    <div class="row mb-5">
      <div class="col">
        <div class="card card-body border-0 o-hidden mb-0 bg-primary text-light">
          <div class="position-relative d-flex flex-column flex-md-row justify-content-between align-items-center">
            <div class="h3 mb-md-0">
              Comienza a digitalizarte e incrementar ventas <br>
              con ayuda de <strong class="text-deep-blue">IVANA</strong>
            </div>
            <a href="#" class="btn btn-lg btn-white text-deep-blue">
              Empezar
            </a>
          </div>
          <div class="decoration layer-0">
            <img class="bg-primary-2" src="./assets/lib/leap/img/decorations/deco-blob-1.svg" alt="deco-blob-1 decoration" data-inject-svg />
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-6 col-lg-3 col-xl-2">
        <h5>Navegar</h5>
        <ul class="nav flex-column">
          <li class="nav-item">
            <a href="#" class="nav-link">Demostraciones</a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">Páginas</a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">Blog</a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">Portafolio</a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">Elementos</a>
          </li>
        </ul>
      </div>
      <div class="col-6 col-lg">
        <h5>Contacto</h5>
        <ul class="list-unstyled">
          <li class="mb-3 d-flex">
            <img class="icon" src="./assets/lib/leap/img/icons/theme/map/marker-1.svg" alt="icono de marcador-1" data-inject-svg />
            <div class="ml-3">
              <span>348 Avenida Greenpoint <br />Brooklyn, NY </span>
            </div>
          </li>
          <li class="mb-3 d-flex">
            <img class="icon" src="./assets/lib/leap/img/icons/theme/communication/call-1.svg" alt="icono de llamada-1" data-inject-svg />
            <div class="ml-3">
              <span>+61 3928 3324</span>
              <span class="d-block text-muted text-small">Lun - Vie 9am - 5pm</span>
            </div>
          </li>
          <li class="mb-3 d-flex">
            <img class="icon" src="./assets/lib/leap/img/icons/theme/communication/mail.svg" alt="icono de correo" data-inject-svg />
            <div class="ml-3">
              <a href="#">hello@company.io</a>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-lg-5 col-xl-4 mt-3 mt-lg-0">
        <h5>Suscribirse</h5>
        <p>Las últimas noticias, artículos y recursos de Leap, enviados directamente a su bandeja de entrada cada mes.</p>
        <form action="https://mailform.mediumra.re/leap/mailchimp.php" data-form-email novalidate>
          <div class="form-row">
            <div class="col-12">
              <input type="email" class="form-control mb-2" placeholder="Dirección de correo electrónico" name="email" required>
            </div>
            <div class="col-12">
              <div class="d-none alert alert-success" role="alert" data-success-message> Gracias, un miembro de nuestro equipo se pondrá en contacto pronto. </div>
              <div class="d-none alert alert-danger" role="alert" data-error-message> Por favor, complete todos los campos correctamente. </div>
              <div data-recaptcha data-sitekey="6Lemz4gUAAAAAElq4ZHFBzI7j8QUiYMn9I0mzQWG" data-size="invisible" data-badge="bottomleft"></div>
              <button type="submit" class="btn btn-primary btn-loading btn-block" data-loading-text="Enviando">
                <img class="icon" src="./assets/lib/leap/img/icons/theme/code/loading.svg" alt="icono de carga" data-inject-svg />
                <span>Suscribirse</span>
              </button>
            </div>
          </div>
        </form>
        <small class="text-muted form-text">Nunca compartiremos sus detalles. Consulte nuestra <a href="#">Política de privacidad</a>
        </small>
      </div>
    </div>
    <div class="row justify-content-center mb-2">
      <div class="col-auto">
        <ul class="nav">
          <li class="nav-item">
            <a href="#" class="nav-link">
              <img class="icon " src="./assets/lib/leap/img/icons/social/instagram.svg" alt="icono social de instagram" data-inject-svg />
            </a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">
              <img class="icon " src="./assets/lib/leap/img/icons/social/twitter.svg" alt="icono social de twitter" data-inject-svg />
            </a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">
              <img class="icon " src="./assets/lib/leap/img/icons/social/youtube.svg" alt="icono social de youtube" data-inject-svg />
            </a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">
              <img class="icon " src="./assets/lib/leap/img/icons/social/medium.svg" alt="icono social de medium" data-inject-svg />
            </a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">
              <img class="icon " src="./assets/lib/leap/img/icons/social/facebook.svg" alt="icono social de facebook" data-inject-svg />
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col col-md-auto text-center">
        <small class="text-muted">&copy;2020 Esta página está protegida por reCAPTCHA y está sujeta a la <a href="https://www.google.com/policies/privacy/">Política de privacidad</a> y <a href="https://policies.google.com/terms">Términos de servicio</a> de Google.
        </small>
      </div>
    </div>
  </div>
</footer>