<div class="container-small px-lg-7 px-xxl-3">
  <div class="text-center mb-5 position-relative">
    <h5 class="text-info mb-3">Aplicaciones Favoritas</h5>
    <h2 class="mb-2 lh-base">Descubre las aplicaciones más populares</h2>
  </div>
  <div class="carousel testimonial-carousel slide position-relative dark__bg-1100" id="carouselApps"
       data-bs-ride="carousel">
    <div class="bg-holder d-none d-md-block"
         style="background-image:url(./assets/img/background/39.png);background-size:186px;background-position:top 20px right 20px;">
    </div>
    <!--/.bg-holder-->
    <img class="position-absolute d-none d-lg-block" src="./assets/img/background/bg-left-22.png" width="150" alt=""
         style="top: -100px; left: -70px" />
    <img class="position-absolute d-none d-lg-block" src="./assets/img/background/bg-right-22.png" width="150"
         alt=""
         style="bottom: -80px; right: -80px" />
    <div class="carousel-inner">
      <div class="carousel-item text-center py-8 px-5 px-xl-15" *ngFor="let app of apps; let i = index"
           [ngClass]="{'active': i === activeIndex}">
        <img src="./assets/img/placeholder/no_image_found-light.webp" alt="WhatsApp" class="mb-2 rounded-circle"
             style="max-width: 100px;">
        <h3 class="fw-semi-bold fst-italic mt-3 mb-2 w-xl-70 mx-auto lh-base">{{ app.name }}</h3>
        <div class="mb-3">
          <span *ngFor="let star of getStars(app.generalRating)" [class]="star"></span>
        </div>
        <p class="mb-4">{{ app.description }}</p>
        <div class="mt-2">
          <a href="#" class="btn btn-primary" (click)="goToAppDetails(app.id)">Ver más</a>
        </div>
      </div>

    </div>
    <div class="carousel-indicators">
      <button *ngFor="let app of apps; let i = index" [class.active]="i === activeIndex" type="button"
              data-bs-target="#carouselApps" [attr.data-bs-slide-to]="i"
              [attr.aria-label]="'Slide ' + (i + 1)"></button>
    </div>
  </div>
</div>