import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ChatInfo } from '@app/models/chat/chat/chat-info.dto';
import {
  SearchWhatsAppMessageTemplateRequest,
} from '@app/models/chat/whatsapp-message-template/search-whatsapp-message-template-request.dto';
import {
  WhatsAppMessageTemplateInfo,
} from '@app/models/chat/whatsapp-message-template/whatsapp-message-template-info.dto';
import { UserInfo } from '@app/models/account/user/user.info.dto';
import { AuthService } from '@app/services/auth/auth.service';
import { MessageTemplateService } from '@app/services/chat/message-template.service';
import { EventEmitterService, NotificationTopic } from '@app/services/data/event-emitter.service';
import { DataKey, LocalDataService } from '@app/services/data/local-data.service';
import { WhatsAppConfigService } from '@services/config/whatsapp-config.service';
import { WhatsAppConfigInfo } from '@models/config/whatsapp/whatsapp-config-info.dto';
import { FunnelService } from '@services/utilities/funnel.service';
import { FunnelSearchRequest } from '@models/utilities/funnel/funnel-search-request.dto';
import { GetFunnelStageChatsRequest } from '@models/utilities/funnel/stage/get-funnel-stage-chats-request.dto';
import { FunnelInfo } from '@models/utilities/funnel/funnel-info.dto';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
})
export class ChatComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() public showSideChatList: boolean = true;
  @Input() public fromContactsComponent: boolean = false;
  @Input() public chat: ChatInfo;
  funnels: FunnelInfo[] = [];

  public isMobile: boolean;

  public errorCode: string = '';

  private user: UserInfo;
  private componentSubscriptions: Subscription[] = [];

  constructor(
    private eventEmitterService: EventEmitterService,
    private authService: AuthService,
    private localDataService: LocalDataService,
    private toastr: ToastrService,
    private messageTemplateService: MessageTemplateService,
    private whatsAppConfigService: WhatsAppConfigService,
    private funnelService: FunnelService,
  ) {
    this.user = this.authService.getUser();
  }

  ngOnInit() {
    this.initializeChatSelectedSubscription();
    this.initializeShowSideChatListSubscription();
    this.initializeCheckIfMobileSubscription();
    this.fillMessageTemplates();
    this.loadFunnels();
  }

  ngAfterViewInit() {
    (window as any).phoenix.initEverything();
  }

  ngOnDestroy() {
    this.componentSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  private loadFunnels() {
    this.funnelService.searchFunnels(new FunnelSearchRequest(this.user.shop.id, -1)).subscribe({
      next: (funnels) => {
        console.log(funnels);
        this.funnels = funnels.content;
      },
      error: (error) => {
        this.toastr.error('Error al obtener los embudos');
      },
    });
  }


  private initializeCheckIfMobileSubscription() {

    this.isMobile = window.innerWidth <= 992;
    this.showSideChatList = this.isMobile;

    const eventEmitter = this.eventEmitterService.getEventEmitter(NotificationTopic.IsMobile);

    const subscription = eventEmitter.subscribe((isMobile: boolean) => {
      this.isMobile = isMobile;
    });

    this.componentSubscriptions.push(subscription);
  }

  private initializeChatSelectedSubscription() {

    const eventEmitter = this.eventEmitterService.getEventEmitter(NotificationTopic.ChatSelected);

    const subscription = eventEmitter.subscribe((chat: ChatInfo) => {
      this.chat = chat;
      if (this.chat) {
        this.showSideChatList = false;
      }
    });

    this.componentSubscriptions.push(subscription);
  }

  private initializeShowSideChatListSubscription() {

    const eventEmitter = this.eventEmitterService.getEventEmitter(NotificationTopic.ShowSideChatList);

    const subscription = eventEmitter.subscribe(() => {
      this.showSideChatList = true;
    });

    this.componentSubscriptions.push(subscription);
  }

  private fillMessageTemplates() {

    const searchWhatsAppMessageTemplateRequest = new SearchWhatsAppMessageTemplateRequest(this.user.shop.id);
    searchWhatsAppMessageTemplateRequest.searchTerm = '';

    this.messageTemplateService.searchWhatsAppMessageTemplates(searchWhatsAppMessageTemplateRequest).subscribe({
      next: (whatsAppMessageTemplates: WhatsAppMessageTemplateInfo[]) => {
        this.localDataService.set(DataKey.MessageTemplates, JSON.stringify(whatsAppMessageTemplates));
      },
      error: (error) => {
        // this.toastr.error('Error al obtener las plantillas de mensajes de WhatsApp');
      },
    });

  }

}
