
<div class="offcanvas-header align-items-start border-bottom flex-column">
  <div class="pt-1 w-100 d-flex justify-content-between align-items-start">
    <div>
      <h4 class="mb-2 me-2 lh-sm">
        Elige una plantilla de mensaje
      </h4>
      <p class="mb-0 fs--1 text-info-500">
        Aquí podrás elegir la plantilla de mensaje autorizada por Meta
        que deseas enviar a tus contactos.
      </p>
    </div>
    <button class="btn p-1 fw-bolder" type="button" (click)="close()">
      <i class="fas fa-xmark fs-2"></i>
    </button>
  </div>
  <ng-container *ngIf="false">
    <div class="row">
      <pre>
        {{
          {
            configuringBroadcast,
            showFillParameters,
            showFillRecipientParameters,
            showMessageTemplates,
            emptyMessageTemplateRecipient,
            showSelectedMessageTemplate,
            selectedWhatsAppMessageTemplate: selectedWhatsAppMessageTemplate !== undefined,
          } | json
        }}
      </pre>
    </div>
  </ng-container>
</div>
<div class="offcanvas-body scrollbar">
  <!-- Header -->
  <div class="d-flex align-items-center justify-content-between mb-2">
    <!-- Seleccionando una plantilla -->
    <ng-container *ngIf="showMessageTemplates">
      <div class="d-flex flex-column w-100">
        <h4 class="mb-2">
          Seleccione una plantilla
        </h4>
        <div class="form-icon-container mb-1">
          <input
            [(ngModel)]="templateNameTerm"
            (input)="searchMessageTemplates()"
            class="form-control form-icon-input"
            type="text"
            placeholder="Buscar por nombre o número" />
          <span class="fas fa-i text-900 fs--1 form-icon"></span>
        </div>
      </div>
    </ng-container>
    <!-- Una vez seleccionada la plantilla -->
    <ng-container *ngIf="showSelectedMessageTemplate">
      <h4 class="mb-0">
        Plantilla seleccionada
      </h4>
      <button
        class="btn btn-link pe-0 py-0"
        type="button"
        (click)="goToShowMessageTemplates()">
        Volver
      </button>
    </ng-container>
    <!-- Rellenando parámetros -->
    <ng-container *ngIf="showFillParameters">
      <h4 class="mb-0">
        Rellene los campos necesarios
      </h4>
      <button
        class="btn btn-link pe-0 py-0"
        type="button"
        (click)="selectTemplate(selectedWhatsAppMessageTemplate)">
        Volver
      </button>
    </ng-container>
    <!-- Rellenando parámetros del destinatario -->
    <ng-container *ngIf="showFillRecipientParameters">
      <h4 class="mb-0">
        Rellene los campos del destinatario
      </h4>
      <button
        class="btn btn-link pe-0 py-0"
        type="button"
        (click)="goToFillParameters(selectedWhatsAppMessageTemplate)">
        Volver
      </button>
    </ng-container>
  </div>
  <!-- Body -->
  <div class="row g-3">
    <div class="col-sm-12">
      <!-- Sin plantillas que mostrar -->
      <div class="card border border-primary mb-2" *ngIf="whatsAppMessageTemplates.length === 0">
        <div class="card-body">
          <h4 class="card-title lh-sm">
            <i class="fa fa-warning text-warning"></i>
            No hay plantillas disponibles
          </h4>
          <p class="card-text mb-2">
            Puede crear plantillas desde el módulo de configuración de Plantillas de mensaje.
          </p>
          <p class="card-text mb-2">
            De <b>clic</b> en el botón de abajo para ir.
          </p>
          <button
            type="button"
            class="btn btn-phoenix-success float-end create-message-template-btn"
            (click)="goToMessageTemplates()">
            <i class="fab fa-plus"></i>
            Crear
          </button>
        </div>
      </div>
      <!-- Seleccionando una plantilla -->
      <div *ngIf="showMessageTemplates" class="scrollbar message-templates-items-container">
        <div class="card mb-3"
          *ngFor="let whatsAppMessageTemplate of whatsAppMessageTemplates">
          <ng-container *ngTemplateOutlet="messageTemplate; context: {
              $implicit: whatsAppMessageTemplate
            }">
          </ng-container>
        </div>
      </div>
      <!-- Una vez seleccionada la plantilla -->
      <div *ngIf="showSelectedMessageTemplate">
        <div class="mb-3">
          <div class="scrollbar message-template-item-container">
            <div class="card mb-3">
              <ng-container *ngTemplateOutlet="messageTemplate; context: {
                $implicit: selectedWhatsAppMessageTemplate
              }">
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <!-- Si se necesitan llenar parámetros -->
      <div *ngIf="showFillParameters">
        <div class="message-template-item-fill-container">
          <div class="card mb-3">
            <ng-container *ngTemplateOutlet="messageTemplateParameters">
            </ng-container>
          </div>
        </div>
      </div>
      <!-- Si se necesitan llenar parámetros del destinatario -->
      <div *ngIf="showFillRecipientParameters">
        <div class="message-template-item-fill-container">
          <div class="card mb-3">
            <ng-container *ngTemplateOutlet="messageTemplateRecipientParameters">
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #messageTemplate let-whatsAppMessageTemplate>
  <div class="card-body">
    <a *ngIf="showMessageTemplates"
      class="dropdown-indicator-icon position-absolute text-700 ps-4 py-1"
      (click)="openTemplate(collapse, whatsAppMessageTemplate)"
      role="button">
      <span class="fa-solid fa-angle-down"></span>
    </a>
    <div class="d-flex align-items-center justify-content-between mb-1">
      <div class="d-flex">
        <i class="fa-solid fa-hashtag me-2"></i>
        <p class="mb-0 fs--1 fw-semi-bold text-700 date">
          {{ whatsAppMessageTemplate.id }}
        </p>
      </div>
    </div>
    <div class="deals-items-head d-flex align-items-center mb-1">
      <p class="text-primary fw-bold line-clamp-1 me-3 mb-0">
        {{ whatsAppMessageTemplate.name }}
      </p>
      <!-- Fase de selección de plantilla -->
      <div *ngIf="showMessageTemplates"
        class="ms-auto fs--1 text-info-500 text-1100 fw-semi-bold mb-0">
        <!-- Botón de selección de plantillas -->
        <!-- Si la plantilla está aprobada -->
        <ng-container *ngIf="true">
        <!-- <ng-container *ngIf="whatsAppMessageTemplate.status === 'APPROVED'"> -->
          <!-- Si el template está abierto -->
          <ng-container *ngIf="whatsAppMessageTemplate.opened">
            <!-- Si el template no contiene parámetros -->
            <ng-container *ngIf="whatsAppMessageTemplate.parametersCount === 0">
              <button *ngIf="whatsAppMessageTemplate.status && this.emptyMessageTemplateRecipient"
                class="btn btn-sm btn-phoenix-success px-2"
                [disabled]="sendingMessageTemplate"
                (click)="sendMessageTemplate(whatsAppMessageTemplate)">
                <i class="fa fa-paper-plane me-1"></i>
                {{ sendingMessageTemplate ? 'Enviando mensaje...' : 'Enviar mensaje 1' }}
              </button>
              <button *ngIf="!emptyMessageTemplateRecipient"
                class="btn btn-sm btn-phoenix-primary px-2"
                (click)="goToFillRecipient(whatsAppMessageTemplate)">
                Continuar 1
                <i class="fa-solid fa-arrow-right ms-1"></i>
              </button>
            </ng-container>
            <!-- Si el template tiene parámetros -->
            <ng-container *ngIf="whatsAppMessageTemplate.parametersCount > 0">
              <button *ngIf="whatsAppMessageTemplate.status"
                class="btn btn-sm btn-phoenix-primary px-2"
                (click)="goToFillParameters()">
                Completar
                <i class="fa-solid fa-arrow-right"></i>
              </button>
            </ng-container>
          </ng-container>
          <!-- Si el template no está abierto -->
          <ng-container *ngIf="!whatsAppMessageTemplate.opened">
            <button *ngIf="whatsAppMessageTemplate.status"
              class="btn btn-sm btn-phoenix-primary px-2"
              (click)="selectTemplate(whatsAppMessageTemplate)">
              Continuar 2
              <i class="fa-solid fa-arrow-right"></i>
            </button>
          </ng-container>
        </ng-container>
        <!-- Si la plantilla no está aprobada -->
        <ng-container *ngIf="false">
        <!-- <ng-container *ngIf="whatsAppMessageTemplate.status !== 'APPROVED'"> -->
          <button class="btn btn-sm btn-phoenix-primary px-2" disabled>
            No disponible
          </button>
        </ng-container>
      </div>
      <!-- Fase de visualización de plantilla seleccionada -->
      <ng-container *ngIf="showSelectedMessageTemplate">
        <div class="ms-auto fs--1 text-info-500 text-1100 fw-semi-bold mb-0">
          <ng-container *ngIf="whatsAppMessageTemplate.parametersCount === 0">
            <button *ngIf="emptyMessageTemplateRecipient"
              class="btn btn-sm btn-phoenix-success px-2"
              [disabled]="sendingMessageTemplate"
              (click)="sendMessageTemplate()">
              <i class="fa fa-paper-plane me-1"></i>
              {{ sendingMessageTemplate ? 'Enviando mensaje...' : 'Enviar mensaje 2' }}
            </button>
            <button *ngIf="!emptyMessageTemplateRecipient"
              class="btn btn-sm btn-phoenix-primary px-2"
              (click)="goToFillRecipient(whatsAppMessageTemplate)">
              Continuar 3
              <i class="fa-solid fa-arrow-right ms-1"></i>
            </button>
          </ng-container>
          <ng-container *ngIf="whatsAppMessageTemplate.parametersCount > 0">
            <button
              class="btn btn-sm btn-phoenix-primary px-2"
              (click)="goToFillParameters()">
              Completar
              <i class="fa-solid fa-arrow-right"></i>
            </button>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <div class="d-flex flex-between-center mt-2 overflow-x-scroll scrollbar">
      <div class="d-flex align-items-center">
        <span class="badge badge-phoenix badge-phoenix-success"
          *ngIf="whatsAppMessageTemplate.status === 'APPROVED'">
          Aprobado
        </span>
        <span class="badge badge-phoenix badge-phoenix-warning"
          *ngIf="whatsAppMessageTemplate.status === 'PENDING'">
          En revisión
        </span>
        <span class="badge badge-phoenix badge-phoenix-danger"
          *ngIf="whatsAppMessageTemplate.status === 'REJECTED'">
          Rechazado
        </span>
        <span class="badge badge-phoenix badge-phoenix-info ms-1"
          *ngIf="whatsAppMessageTemplate.category === 'MARKETING'">
          Marketing
        </span>
        <span class="badge badge-phoenix badge-phoenix-info ms-1"
          *ngIf="whatsAppMessageTemplate.category === 'UTILITY'">
          Utilidad
        </span>
        <span class="badge badge-phoenix badge-phoenix-info ms-1"
          *ngIf="whatsAppMessageTemplate.category === 'AUTHENTICATION'">
          Autenticación
        </span>
        <span class="badge badge-phoenix badge-phoenix-warning ms-1"
          *ngIf="whatsAppMessageTemplate.parametersCount > 0">
          Con parámetros ({{ whatsAppMessageTemplate.parametersCount }})
        </span>
      </div>
    </div>
    <div class="collapse"
      #collapse="ngbCollapse"
      [(ngbCollapse)]="!whatsAppMessageTemplate.opened"
      [class.show]="showSelectedMessageTemplate">
      <div class="card mt-3">
        <img *ngIf="
          whatsAppMessageTemplate.headerComponent &&
          whatsAppMessageTemplate.headerComponent.format == WhatsAppMessageTemplateComponentHeaderType.Image &&
          whatsAppMessageTemplate.headerComponent.example"
          class="card-img-top"
          [src]="whatsAppMessageTemplate.headerComponent.example.header_handle[0]">
        <div class="card-body">
          <p *ngIf="
            whatsAppMessageTemplate.headerComponent &&
            whatsAppMessageTemplate.headerComponent.format == WhatsAppMessageTemplateComponentHeaderType.Text">
            {{ whatsAppMessageTemplate.headerComponent.text }}
          </p>
          <pre class="card-text mb-0" style="white-space: pre-wrap;">{{ whatsAppMessageTemplate.bodyComponent.text }}</pre>
          <p class="mt-3 pb-1 mb-0 text-muted fs--1" *ngIf="whatsAppMessageTemplate.footerComponent">
            {{ whatsAppMessageTemplate.footerComponent.text }}
          </p>
        </div>
        <div class="card-footer py-2" *ngIf="whatsAppMessageTemplate.buttonsComponent">
          <div class="d-grid">
            <ng-container
              *ngFor="let button of whatsAppMessageTemplate.buttonsComponent.buttons; let last = last;">
              <button
                type="button"
                class="btn btn-phoenix-primary mb-1">
                <i class="fa fa-reply me-1"></i>
                {{ button.text }}
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #messageTemplateParameters>
  <div class="card-body">
    <div class="d-grid mb-2">
      <button
        *ngIf="emptyMessageTemplateRecipient"
        type="button"
        class="btn btn-phoenix-success mb-1"
        [disabled]="!selectedWhatsAppMessageTemplate.validParameters || sendingMessageTemplate"
        (click)="sendMessageTemplate()">
        <i class="fa fa-paper-plane me-1"></i>
        {{ sendingMessageTemplate ? 'Enviando mensaje...' : 'Enviar mensaje 3' }}
      </button>
      <button
        *ngIf="!emptyMessageTemplateRecipient"
        type="button"
        class="btn btn-phoenix-success mb-1"
        [disabled]="!selectedWhatsAppMessageTemplate.validParameters"
        (click)="goToFillRecipient(selectedWhatsAppMessageTemplate)">
        Continuar 4
         <i class="fa fa-arrow-right me-1"></i>
      </button>
    </div>
    <div class="card">
      <ng-container *ngIf="selectedWhatsAppMessageTemplate.headerComponent as header">
        <ng-container *ngIf="header.format === WhatsAppMessageTemplateComponentHeaderType.Image">
          <img class="card-img-top" [src]="!this.selectedHeaderAttachment ? header.example.header_handle[0] : this.selectedHeaderAttachment.fileUrl">
        </ng-container>
      </ng-container>
      <div class="card-body px-3 pt-3">
        <ng-container *ngIf="selectedWhatsAppMessageTemplate.headerComponent as header">
          <ng-container *ngIf="header.format === WhatsAppMessageTemplateComponentHeaderType.Text">
            <h5>Encabezado:</h5>
            <ng-container *ngIf="header.parameters?.length === 0">
              <p>
                {{ header.text }}
              </p>
            </ng-container>
            <ng-container *ngIf="header.parameters?.length">
              <p class="mb-1"
                [innerHTML]="header.parametersText | safeHtml">
              </p>
              <div class="mb-2">
                <input
                  *ngFor="let headerParam of header.parameters"
                  type="text" class="form-control mb-2"
                  (focus)="scrollToParam(headerParam.id)"
                  (input)="onWriteParameter($event, headerParam, header)"
                  [value]="headerParam.value"
                  [placeholder]="headerParam.name" />
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="
            header.format === WhatsAppMessageTemplateComponentHeaderType.Image ||
            header.format === WhatsAppMessageTemplateComponentHeaderType.Video ||
            header.format === WhatsAppMessageTemplateComponentHeaderType.Document">
            <h5>Encabezado:</h5>
            <div class="d-grid">
              <button
                class="btn btn-sm btn-phoenix-primary mb-2"
                (click)="showMessageTemplateAttachments()">
                {{ selectedHeaderAttachment ? 'Elegir otro archivo' : 'Elegir archivo' }}
              </button>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="selectedWhatsAppMessageTemplate.bodyComponent as body">
          <h5 class="mb-1">Cuerpo:</h5>
          <ng-container *ngIf="body.parameters.length === 0">
            <p>
              {{ body.text }}
            </p>
          </ng-container>
          <ng-container *ngIf="body.parameters?.length">
            <div class="card-text card-text-fill-parameters mb-2 scrollbar position-relative">
              <p
                [innerHTML]="body.parametersText | safeHtml">
              </p>
            </div>
            <div class="mb-2">
              <ng-container *ngFor="let bodyParameter of body.parameters">
                <input type="text" class="form-control mb-2"
                  (focus)="scrollToParam(bodyParameter.id)"
                  (input)="onWriteParameter($event, bodyParameter, body)"
                  [value]="bodyParameter.value"
                  [placeholder]="bodyParameter.name" />
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="selectedWhatsAppMessageTemplate.footerComponent as footer">
          <h5 class="mt-2 mb-1">Pie:</h5>
          <p class="mb-1 text-muted fs--1">
            {{ footer.text }}
          </p>
        </ng-container>
        <ng-container *ngIf="selectedWhatsAppMessageTemplate.buttonsComponent as buttons">
          <h5 class="mt-2 mb-1">Botones:</h5>
          <div class="d-grid">
            <ng-container *ngFor="let button of buttons.buttons">
              <ng-container *ngIf="!button.parameter">
                <button type="button" class="btn btn-phoenix-primary mb-1">
                  <i class="fa fa-reply me-1"></i>
                  {{ button.text }}
                </button>
              </ng-container>
              <ng-container *ngIf="button.parameter as buttonParameter">
                <button type="button" class="btn btn-phoenix-primary mb-1">
                  <i class="fa fa-reply me-1"></i>
                  {{ button.text + ' **' }}
                </button>
                <input type="text" class="form-control mb-1"
                  (input)="onWriteButtonParameter($event, button)"
                  [placeholder]="button.url" />
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #messageTemplateRecipientParameters>
  <div class="card-body">
    <form
      [formGroup]="recipientForm"
      (submit)="sendMessageTemplate()">
      <div class="d-grid mb-2">
        <button
          [disabled]="selectedCountryCode === undefined || recipientForm.invalid || sendingMessageTemplate"
          type="submit"
          class="btn btn-phoenix-success mb-1">
          <i class="fa fa-paper-plane me-1"></i>
          {{ sendingMessageTemplate ? 'Enviando mensaje...' : 'Enviar mensaje 4' }}
        </button>
      </div>
      <div class="form-floating mb-2">
        <input
          id="chatName"
          type="text"
          class="form-control"
          placeholder="Introduce el nombre del contacto"
          formControlName="chatName"
          [ngClass]="{
            'is-invalid': recipientForm.get('chatName').touched && recipientForm.get('chatName').errors,
            'is-valid': recipientForm.get('chatName').valid
          }">
        <label for="chatName">
          Nombre del contacto (opcional)
        </label>
      </div>
      <div class="row g-2">
        <div class="col-5">
          <app-phone-number-typeahead
            (countryCodeChosenEvent)="setSelectedCountryCode($event)">
          </app-phone-number-typeahead>
        </div>
        <div class="col-7">
          <div class="form-floating mb-2">
            <input
              id="chatPhoneNumber"
              type="text"
              class="form-control"
              placeholder="Introduce el número del contacto"
              formControlName="chatPhoneNumber"
              maxlength="14"
              (keydown)="validatePhoneNumber($event)"
              [ngClass]="{
                'is-invalid': recipientForm.get('chatPhoneNumber').touched && recipientForm.get('chatPhoneNumber').errors,
                'is-valid': recipientForm.get('chatPhoneNumber').valid
              }">
            <label for="chatPhoneNumber">
              Número de WhatsApp
            </label>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #messageTemplateAttachmentsModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Archivos
    </h4>
    <button
      type="button"
      class="btn-close"
      (click)="modal.close()">
    </button>
  </div>
  <div class="modal-body">
    <div class="card-body">
      <div class="row">
        <div class="col-7">
          <h6 class="fw-semi-bold mb-2">Imágenes</h6>
          <div class="row g-2 mb-2">
            <div *ngIf="selectedWhatsAppMessageTemplate.attachments.length === 0">
              <p>
                No hay imágenes disponibles
              </p>
            </div>
            <div class="col-auto" role="button"
              (click)="selectAttachment(attachment)"
              *ngFor="let attachment of selectedWhatsAppMessageTemplate.attachments">
              <img
                class="fit-cover rounded-2 hover-bg-200"
                [src]="attachment.fileUrl"
                alt="" height="100" width="100">
            </div>
          </div>
        </div>
        <div class="col-5">
          <uppy-dashboard
            [uppy]="uppy"
            [props]="uppyDashboardProps">
          </uppy-dashboard>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container *ngIf="selectedHeaderAttachment">
      <button
        type="button"
        class="btn btn-secondary me-2"
        (click)="cancelAttachmentSelection()">
        Limpiar
      </button>
      <button
        type="button"
        class="btn btn-success"
        (click)="modal.close()">
        Seleccionar
      </button>
    </ng-container>
    <button *ngIf="!selectedHeaderAttachment"
      type="button"
      class="btn btn-secondary"
      (click)="cancelAttachmentSelection()">
      Cerrar
    </button>
  </div>
</ng-template>