
export class SendMessengerMessageRequest {

  messaging_type: string;
  recipient: SendMessengerMessageRecipientRequest;
  message: SendMessengerMessageMessageRequest;

}

export class SendMessengerMessageRecipientRequest {
  id: string;
}

export class SendMessengerMessageMessageRequest {
  text: string;
  attachment: SendMessengerMessageAttachmentRequest;
}

export class SendMessengerMessageAttachmentRequest {
  type: string;
  payload: SendMessengerMessagePayloadRequest;
}

export class SendMessengerMessagePayloadRequest {
  url: string;
  is_reusable: boolean;
}