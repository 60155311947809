import { PaginatedSearchRequest } from "../../common/paginated-search-request.dto";
import { SaveBroadcastRequest } from "./save-broadcast-request.dto";

export class SearchBroadcastChatsRequest extends PaginatedSearchRequest {

  saveBroadcastRequest: SaveBroadcastRequest;

  constructor(size: number) {
    super();
    this.page = 0;
    this.size = size;
  }

}