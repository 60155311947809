
<div class="modal-body smart-assistant-topic-item-modal">
  <div class="row g-4 py-0">
    <div [ngClass]="{
            'col-lg-6 col-sm-12': showActions,
            'col-12': !showActions
         }">
      <div class="card border border-light shadow-lg">
        <div class="card-body smart-assistant-topic-item-info scrollbar">
          <ng-container *ngTemplateOutlet="smartAssistantTopicItemInfo"></ng-container>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-sm-12" *ngIf="showActions">
      <div class="card border border-light shadow-lg smart-assistant-topic-item-actions">
        <div class="card-body">
          <ng-container *ngIf="showActionList">
            <ng-container *ngTemplateOutlet="smartAssistantTopicItemActionsList"></ng-container>
          </ng-container>
          <ng-container *ngIf="showActionForm">
            <app-smart-assistant-topic-item-action
              [smartAssistantTopicItemAction]="selectedSmartAssistantTopicItemAction">
            </app-smart-assistant-topic-item-action>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer border-top-0 pt-0">
  <!-- Botones -->
  <div class="d-flex justify-content-end">
    <button
      type="button"
      class="btn btn-phoenix-primary" (click)="cancelSave()">
      Cancelar
    </button>
    <button
      class="btn btn-primary ms-3"
      (click)="saveSmartAssistantTopicItem()"
      [disabled]="smartAssistantTopicItemForm.invalid || saving ">
      Guardar
    </button>
  </div>
</div>

<ng-template #smartAssistantTopicItemInfo>
  <form [formGroup]="smartAssistantTopicItemForm"
        (submit)="saveSmartAssistantTopicItem()">
    <h4 class="card-title">
      Información de la tarjeta
    </h4>
    <!-- Nombre de la tarjeta -->
    <div class="row px-0 pb-3">
      <div class="col-12">
        <div class="form-floating">
          <input
            class="form-control"
            id="smartAssistantTopicItemName"
            type="text"
            placeholder="Nombre de la tarjeta"
            formControlName="name"
            [ngClass]="{
              'is-invalid': smartAssistantTopicItemForm.get('name')?.touched && smartAssistantTopicItemForm.get('name')?.invalid,
              'is-valid': smartAssistantTopicItemForm.get('name')?.touched && smartAssistantTopicItemForm.get('name')?.valid
            }">
          <label for="smartAssistantTopicItemName">
            Nombre de la tarjeta
          </label>
        </div>
      </div>
    </div>
    <!-- Contenido de la tarjeta -->
    <div class="row px-0 pb-3">
      <div class="col-12">
        <div class="form-floating">
          <textarea
            class="form-control scrollbar"
            rows="16"
            id="smartAssistantTopicItemContentOrContext"
            [placeholder]="showActions ? 'Contexto de la tarjeta' : 'Contenido de la tarjeta'"
            formControlName="contentOrContext"
            [ngClass]="{
            'is-invalid': smartAssistantTopicItemForm.get('contentOrContext').touched && smartAssistantTopicItemForm.get('contentOrContext').invalid,
            'is-valid': smartAssistantTopicItemForm.get('contentOrContext').touched && smartAssistantTopicItemForm.get('contentOrContext').valid
          }"></textarea>
          <label for="smartAssistantTopicItemContentOrContext">
            {{ showActions ? 'Contexto de la tarjeta' : 'Contenido de la tarjeta' }}
          </label>
        </div>
      </div>
    </div>
    <div class="row px-0 pb-3">
      <div class="col-12">
        <div class="d-flex justify-content-end">
          <div class="form-check form-switch">
            <input class="form-check-input form-check-md"
                   id="showActionsCheck"
                   [checked]="showActions"
                   type="checkbox"
                   (change)="toggleShowActions($event)" />
            <label class="form-check-label" for="showActionsCheck">
              Enviar acciones
            </label>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #smartAssistantTopicItemActionsList>
  <div class="d-flex align-items-center">
    <h4 class="card-title mb-0">
      Acciones de la tarjeta
    </h4>
  </div>
  <div class="px-0">
    <div class="mb-3 mt-3 d-flex justify-content-between align-items-center">
      <a class="text-primary smart-topic-item-save" role="button"
         (click)="showAddSmartAssistantTopicItemAction()">
        <i class="fa fa-plus"></i>
        Agregar nueva acción
      </a>
    </div>
    <ul class="list-group scrollbar smart-assistant-topic-item-actions-list">
      <ng-container *ngFor="let smartAssistantTopicItemAction of smartAssistantTopicItemActions">
        <li class="list-group-item py-3" *ngIf="!smartAssistantTopicItemAction.removed">
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column mb-1 mb-md-0 text-1100 fw-semi-bold fs--1">
              <ng-container *ngIf="smartAssistantTopicItemAction.type === SmartAssistantTopicItemActionType.QuickReply">
                <h5 class="mb-1 text-warning mb-2">
                  <i class="fa-duotone fa-reply-all me-1"></i>
                  {{ smartAssistantTopicItemAction.typeDescription }}
                </h5>
                <span>
                   {{ smartAssistantTopicItemAction.quickReply?.name }}
                </span>
              </ng-container>
              <ng-container *ngIf="smartAssistantTopicItemAction.type === SmartAssistantTopicItemActionType.Message">
                <h5 class="mb-1 text-primary mb-2">
                  <i class="fa-duotone fa-messages me-1"></i>
                  {{ smartAssistantTopicItemAction.typeDescription }}
                </h5>
                <span>
                   {{ smartAssistantTopicItemAction.message }}
                </span>
              </ng-container>
              <ng-container *ngIf="smartAssistantTopicItemAction.type === SmartAssistantTopicItemActionType.HttpCall">
                <h5 class="mb-1 text-primary mb-2">
                  <i class="fa-duotone fa-messages me-1"></i>
                  {{ smartAssistantTopicItemAction.typeDescription }}
                </h5>
                <span>
                   {{ smartAssistantTopicItemAction.httpCallUrl }}
                </span>
              </ng-container>
              <ng-container *ngIf="smartAssistantTopicItemAction.type === SmartAssistantTopicItemActionType.SmartMessage">
                <h5 class="mb-1 text-primary mb-2">
                  <i class="fa-duotone fa-messages me-1"></i>
                  {{ smartAssistantTopicItemAction.typeDescription }}
                </h5>
                <span>
                   {{ smartAssistantTopicItemAction.message }}
                </span>
              </ng-container>
              <ng-container *ngIf="smartAssistantTopicItemAction.type === SmartAssistantTopicItemActionType.ChatTag">
                <h5 class="mb-1 text-danger mb-2">
                  <i class="fa-duotone fa-tags me-1"></i>
                  {{ smartAssistantTopicItemAction.typeDescription }}
                </h5>
                <div class="mb-1">
                  <span
                    *ngFor="let chatTag of smartAssistantTopicItemAction.chatTags"
                    role="button"
                    class="badge badge-phoenix fs--2 me-1"
                    [ngStyle]="{
                      'color': chatTag.color,
                      'border': '1px solid ' + chatTag.color,
                    }">
                    {{ chatTag.name }}
                  </span>
                </div>
              </ng-container>
              <ng-container *ngIf="smartAssistantTopicItemAction.type === SmartAssistantTopicItemActionType.HummanAssitantAttention">
                <h5 class="mb-1 text-dark mb-2">
                  <i class="fa fa-person-running  "></i>
                  {{ smartAssistantTopicItemAction.typeDescription }}
                </h5>
                <span>
                  Se notificará a un asistente humano
                </span>
              </ng-container>
            </div>
            <div>
              <i class="fa fa-edit text-primary cursor-pointer"
                 (click)="showEditSmartAssistantTopicItemAction(smartAssistantTopicItemAction)">
              </i>
              <i class="fa fa-trash text-danger cursor-pointer ms-2"
                (click)="deleteSmartAssistantTopicItemAction(smartAssistantTopicItemAction)">
              </i>
            </div>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-template>