export enum AppCategoryType {
  Communication = 'communication', // Comunicación
  Productivity = 'productivity', // Productividad
  Social = 'social', // Social
  Finance = 'finance', // Finanzas
  Education = 'education', // Educación
  Entertainment = 'entertainment', // Entretenimiento
  Health = 'health', // Salud
  Travel = 'travel', // Viajes
  Shopping = 'shopping', // Compras
  Other = 'other' // Otra categoría
}
