
<div class="row">
  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3" style="max-height: 600px;" *ngFor="let chat of chats">
    <app-chat-content [sideSettingsButton]="false" [chat]="chat"></app-chat-content>
  </div>
  <div *ngIf="chats?.length === 0">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <h3>
        No tienes chats aún.
      </h3>
    </div>
  </div>
</div>
