
export class QuickReplyButtonInfo {

  text: string;

  constructor(text: string) {
    this.text = text;
  }

}
