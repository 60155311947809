import { WhatsAppMessageTemplateComponentType } from "./type/whatsapp-message-template-component.type";
import { WhatsAppMessageTemplateButton } from "./whatsapp-message-template-button.dto";
import { WhatsAppMessageTemplateExample } from "./whatsapp-message-template-example.dto";

export class WhatsAppMessageTemplateComponentRequest {

  type: WhatsAppMessageTemplateComponentType;
  format: string;
  text: string;
  buttons: WhatsAppMessageTemplateButton[];
  example: WhatsAppMessageTemplateExample;

}