
export const emojiMartI18n = {
  search: 'Buscar',
  emojilist: 'Lista de emojis',
  notfound: 'No se encontraron emojis',
  clear: 'Limpiar',
  categories: {
    search: 'Resultados de búsqueda',
    recent: 'Usados con frecuencia',
    people: 'Emojis y personas',
    nature: 'Animales y naturaleza',
    foods: 'Comida y bebida',
    activity: 'Actividad',
    places: 'Viajes y lugares',
    objects: 'Objetos',
    symbols: 'Símbolos',
    flags: 'Banderas',
    custom: 'Personalizado'
  },
  skintones: {
    1: 'Tono de piel predeterminado',
    2: 'Tono de piel claro',
    3: 'Tono de piel medio-claro',
    4: 'Tono de piel medio',
    5: 'Tono de piel medio-oscuro',
    6: 'Tono de piel oscuro'
  }
}
