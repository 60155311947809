import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ChatInfo } from "@models/chat/chat/chat-info.dto";
import { Page } from "@models/common/page";
import { ChatSearchRequest } from "@models/chat/chat/chat-search-request.dto";
import { ChatService } from "@services/chat/chat.service";
import { UserInfo } from "@models/account/user/user.info.dto";
import { ShopInfo } from "@models/account/shop/shop-info.dto";
import { AuthService } from "@services/auth/auth.service";

@Component({
  selector: 'app-chat-monitor',
  templateUrl: './chat-monitor.component.html',
  styleUrls: ['./chat-monitor.component.css'],
})
export class ChatMonitorComponent implements OnInit {

  private readonly user: UserInfo;
  public readonly shop: ShopInfo;

  public chats: ChatInfo[] = [];
  public totalChats: number = 0;
  public loadingChats: boolean = false;

  public chatSearchRequest: ChatSearchRequest;

  constructor(
    private readonly chatService: ChatService,
    private readonly authService: AuthService,
  ) {
    this.user = this.authService.getUser();
    this.shop = this.user.shop;
  }

  ngOnInit() {
    this.initializeChatSearchRequest();
    this.loadChats(false);
  }

  private initializeChatSearchRequest() {
    this.chatSearchRequest = new ChatSearchRequest(
      this.user.shop.id, 10, this.user,
    );
  }

  private loadChats(filtering: boolean, callback?: () => void) {

    this.loadingChats = true;

    if (filtering) {
      this.chatSearchRequest.page = 0;
      this.chats = [];
    }

    this.chatService.searchChats(this.chatSearchRequest).subscribe({
      next: (chatsPage: Page<ChatInfo>) => {
        this.loadingChats = false;
        if (!filtering) {
          this.chats.push(...chatsPage.content);
        }
        else
          this.chats = chatsPage.content;
        this.totalChats = chatsPage.totalElements;
        (window as any).phoenix.initEverything();
        callback && callback();
      },
      error: (error: any) => {
        this.loadingChats = false;
        console.error('Error en la carga de chats:', error);
      },
    });

  }

}
