import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { lastValueFrom, Subscription } from 'rxjs';
import { NgbModal, NgbOffcanvas, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { SafeUrl } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { v4 as uuidv4 } from 'uuid';
import Uppy, { UploadedUppyFile, UppyFile } from '@uppy/core';
import { DashboardOptions } from '@uppy/dashboard';
import DropTarget from '@uppy/drop-target';
import AwsS3 from '@uppy/aws-s3';
import saveAs from 'file-saver';
import { Fancybox } from '@fancyapps/ui';

import { ChatInfo } from '@app/models/chat/chat/chat-info.dto';
import { MessageInfo } from '@app/models/chat/message/message-info.dto';
import { MessageSearchRequest } from '@app/models/chat/message/message-search-request.dto';
import { Page } from '@app/models/common/page';
import { MarkChatAsSeenRequest } from '@app/models/chat/chat/mark-chat-as-seen-request.dto';
import { SendMessageRequest } from '@app/models/chat/message/send-message-request.dto';
import { UserInfo } from '@app/models/account/user/user.info.dto';
import { GetSignedUrlRequest } from '@app/models/media/get-signed-url-request.dto';
import { FileMetadata } from '@app/models/media/file-metadata.dto';
import { QuickReplySearchRequest } from '@app/models/chat/quick-reply/quick-reply-search-request.dto';
import { QuickReplyInfo } from '@app/models/chat/quick-reply/quick-reply-info.dto';
import { QuickReplyItemInfo } from '@app/models/chat/quick-reply/item/quick-reply-item-info.dto';
import { AssignChatAdvisorRequest } from '@app/models/chat/chat/assign-chat-advisor-request.dto';
import { ArchiveChatRequest } from '@app/models/chat/chat/archive-chat-request.dto';
import { SetChatAliasRequest } from '@app/models/chat/chat/set-chat-alias-request.dto';
import { UpdateMessageNoteRequest } from '@app/models/chat/message/update-message-note-request.dto';

import { AuthService } from '@app/services/auth/auth.service';
import { MessageService } from '@app/services/chat/message.service';
import { EventEmitterService, NotificationTopic } from '@app/services/data/event-emitter.service';
import { ChatService } from '@app/services/chat/chat.service';
import { AudioRecordingService } from '@app/services/media/audio-recorder.service';
import { MediaService } from '@app/services/media/media.service';
import { FileService } from '@app/services/file/file.service';
import { SpinnerService } from '@app/services/data/spinner.service';
import { DataKey, LocalDataService } from '@services/data/local-data.service';
import { QuickReplyService } from '@services/chat/quick-reply.service';

import {
  MessageType,
  getMessageTypeByMediaType,
  isMediaMessage,
  messageTypes,
} from '@app/type/chat/message-content.type';
import { MessageStatus } from '@app/type/chat/message-status.type';
import { ChannelType } from '@type/communication/channel.type';
import { getMessageStatusClassString, normalizeText } from '@app/utils/chat-utils';
import { emojiMartI18n } from '@constants/emoji-mart-constants';
import { UppyLocaleValues } from '@app/constants/uppy-constants';

import {
  SendMessageRecipientRequest, SendWhatsAppInteractiveActionRequest,
  SendWhatsAppInteractiveBodyRequest, SendWhatsAppInteractiveButtonReplyRequest, SendWhatsAppInteractiveButtonRequest,
  SendWhatsAppInteractiveRequest, SendWhatsAppMediaRequest,
  SendWhatsAppMessageContextRequest, SendWhatsAppMessageRequest,
  SendWhatsAppReactionRequest, SendWhatsAppTextRequest,
} from '@models/chat/message/whatsapp/whatsapp-message-requests.dto';

import {
  SendMessengerMessageMessageRequest,
  SendMessengerMessageRequest,
} from '@models/chat/message/messenger/messenger-message-requests.dto';

import { QuickReplyButtonInfo } from '@models/chat/quick-reply/item/quick-reply-button-info.dto';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChatSummaryInfo } from '@models/chat/chat/chat-summary-info.dto';
import { QuickReplyGroupInfo } from "@models/chat/quick-reply/group/quick-reply-group-info.dto";
import { FunnelService } from '@services/utilities/funnel.service';
import { FunnelInfo } from '@models/utilities/funnel/funnel-info.dto';
import { FunnelStageInfo } from '@models/utilities/funnel/stage/funnel-stage-info.dto';
import { UpdateFunnelStageRequest } from '@models/utilities/funnel/stage/update-funnel-stage-request.dto';

@Component({
  selector: 'app-chat-content',
  templateUrl: './chat-content.component.html',
  styleUrls: ['./chat-content.component.css'],
})
export class ChatContentComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('p4', { static: false }) popover: NgbPopover;

  // Form controls
  public chatAliasFormControl: FormControl = new FormControl('');
  public textAreaFormControl: FormControl = new FormControl('');
  public noteMessageFormControl: FormControl = new FormControl('');
  public summaryChatFormControl: FormControl = new FormControl('');
  public funnelForm: FormGroup;

  // Chat information
  public chatName: string;
  public chatAlias: string;
  public afterChatsLoaded: boolean = false;
  public replyingMessage: MessageInfo;
  public reactingMessage: MessageInfo;
  public chatThread: any;
  public chatAdvisorChosen: UserInfo;

  // Messages
  public messages: MessageInfo[] = [];
  public mediaMessages: MessageInfo[] = [];
  public documentMessages: MessageInfo[] = [];
  public quickReplies: QuickReplyInfo[] = [];
  public filteredQuickReplies: QuickReplyInfo[] = [];
  public hideMessages: boolean = false;
  public firstLoad: boolean = true;
  public selectedGroups: string = 'all';
  public groups: QuickReplyGroupInfo[] = [];

  // Message note
  public creatingNote = false;
  private messageNoteSelected: MessageInfo;

  // Uppy
  public showingUppy: boolean = false;
  public uppy: Uppy;
  public uppyDashboardProps: DashboardOptions;

  // Audio recording
  public isRecording: boolean = false;
  public recordedTime: string;
  public blobUrl: SafeUrl;
  public blob: Blob;

  // UI State
  public chatContentSpinnerText: string;
  public showChatSettings: boolean = false;
  public showChatTemplateSender: boolean = false;
  public showWhatsAppWebMessageSender: boolean = false;
  public openedPopover: any = null;

  // Protected constants
  protected readonly MessageType = MessageType;
  protected readonly ChannelType = ChannelType;
  protected readonly emojiMartI18n = emojiMartI18n;

  // Private state
  private currentUser: UserInfo;
  private componentSubscriptions: Subscription[] = [];
  private messageSearchRequest: MessageSearchRequest;
  private messageSearchPage: Page<MessageInfo>;
  private messageQueue: MessageInfo[] = [];
  private isProcessingQueue = false; // Variable de bloqueo

  // Inputs
  @Input()
  public chat: ChatInfo;

  @Input()
  public sideSettingsButton: boolean = true;

  // ViewChild elements
  @ViewChild('sideOffCanvas')
  public sideOffCanvas: TemplateRef<any>;

  @ViewChild('messageField')
  private messageField: ElementRef;

  @ViewChild('noteMessageField')
  private noteMessageField: ElementRef;

  @ViewChild('chatScrollContainer')
  private chatScrollContainer: ElementRef;

  @ViewChild('assignAdvisorModal', { static: true })
  private assignAdvisorModal: TemplateRef<any>;

  @ViewChild('chatThreadModal', { static: true })
  private chatThreadModal: TemplateRef<any>;

  @ViewChild('chatResumeModal', { static: true })
  private chatResumeModal: TemplateRef<any>;

  constructor(
    private elRef: ElementRef,
    private offCanvasService: NgbOffcanvas,
    private eventEmitterService: EventEmitterService,
    private localDataService: LocalDataService,
    private messageService: MessageService,
    private quickReplyService: QuickReplyService,
    private modalService: NgbModal,
    private mediaService: MediaService,
    private fileService: FileService,
    private chatService: ChatService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private spinnerService: SpinnerService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private funnelService: FunnelService,
    private audioRecordingService: AudioRecordingService,
  ) {
    this.currentUser = this.authService.getUser();
  }

  ngOnInit() {
    this.loadQuickReplies();
    this.initializeSearchMessageRequest();
    this.initializeFunnelFormGroup();
    this.initializeChatSelectedSubscription();
    this.initializeReplyingMessageSubscription();
    this.initializeReactingMessageSubscription();
    this.initializeSameChatSelectedSubscription();
    this.initializeMessageNoteSubscription();
    this.initializeOnChatRequiresAssistanceEvent();
    this.initializeDeleteMessageNoteSubscription();
    this.initializeFunnelStageChangedSubscription();
    this.initializeShowSendTemplateMessage();
    this.initializeShowSendWhatsAppWebMessage();
    this.initializeOnMessageReceivedEvent();
    this.initializeOnMessageStatusSubscription();
    this.initializeGoToMessageSubscription();
    this.initializeToggleThemeSubscription();
    this.initializeAudioRecorderEvents();
    this.initializeUppy();
    this.checkChat();
    this.loadGroups();
    this.initializeFunnelStageUpdatedSubscription();
  }

  ngAfterViewInit() {
    (window as any).phoenix.initEverything();
    Fancybox.bind(this.elRef.nativeElement, '[data-fancybox]', {
      // Custom options
    });

  }

  ngOnDestroy() {
    // Component subscriptions
    this.componentSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });

    // Fancybox
    Fancybox.unbind(this.elRef.nativeElement);
    Fancybox.close();

    // Audio recording
    this.abortAudioRecording();
  }

  public setMessageReady(message: MessageInfo) {
    message.ready = true;
    for (const message of this.messages) {
      if (!message.ready) return;
    }
    this.hideMessages = false;
    this.spinner.hide('chat-content-spinner').then(() => {
    });
    if (this.firstLoad) {
      this.scrollToBottom();
      this.firstLoad = false;
    }
  }

  initializeChatAliasFormControl() {
    this.chatAliasFormControl.setValue(this.chat.chatAlias ? this.chat.chatAlias : this.chat.chatName);
  }

  @Input() funnels: FunnelInfo[] = [];
  stages: FunnelStageInfo[] = [];

  initializeFunnelFormGroup() {
    this.funnelForm = this.fb.group({
      funnel: ['', Validators.required],
      stage: ['', Validators.required],
    });
  }

  initializeFunnelFormValues() {
    if (this.chat && this.chat.funnelStageId) {
      for (const funnel of this.funnels) {
        const stage = funnel.stages.find(s => s.id === this.chat.funnelStageId);
        this.stages = funnel.stages;
        if (stage) {
          this.funnelForm.get('funnel').setValue(funnel.id);
          this.funnelForm.get('stage').setValue(stage.id);
          break;
        }
      }
    } else {
      this.stages = [];
      this.funnelForm.get('funnel').setValue('');
      this.funnelForm.get('stage').setValue('');
    }
  }

  onFunnelChange(event: any): void {
    const selectedFunnelId: number = +event.target.value;
    const selectedFunnel: FunnelInfo = this.funnels.find(f => f.id === selectedFunnelId);
    this.stages = selectedFunnel.stages;
    this.funnelForm.get('stage').setValue('');
  }

  // Manejar cambio de etapa
  onStageChange(event: any): void {

    const selectedStageId = +event.target.value;
    const updateChatFunnelStageRequest = new UpdateFunnelStageRequest(selectedStageId, this.chat.id);

    this.funnelService.updateChatFunnelStage(updateChatFunnelStageRequest).subscribe({
      next: (updatedChat: ChatInfo) => {
        this.chat.funnelStageId = updatedChat.funnelStageId;
      },
      error: (error) => {
        console.log('Error al actualizar la etapa');
      },
    });
  }

  onStageAddedOrUpdated(stage: FunnelStageInfo): void {

    const funnel = this.funnels.find(f => f.id === stage.funnelId);

    if (funnel) {
      const existingStage = funnel.stages.find(s => s.id === stage.id);

      if (existingStage) {
        // Actualizar la información de la etapa si ya existe
        Object.assign(existingStage, stage);
      } else {
        // Agregar la nueva etapa si no existe
        funnel.stages.push(stage);
      }

      // Si el embudo está seleccionado, refrescar las etapas
      if (this.funnelForm.get('funnel')?.value === funnel.id) {
        this.stages = funnel.stages;
      }
    }
  }

  // Eliminar una etapa en tiempo real
  onStageRemoved(stage: FunnelStageInfo): void {
    const funnel = this.funnels.find(f => f.id === stage.funnelId);
    if (funnel) {
      funnel.stages = funnel.stages.filter(s => s.id !== stage.id);
      if (this.funnelForm.get('funnel')?.value == funnel.id) {
        this.stages = funnel.stages;  // Refrescar las etapas si el embudo está seleccionado
      }
    }
  }

  onFunnelAddedOrUpdated(funnel: FunnelInfo): void {
    const existingFunnelIndex = this.funnels.findIndex(f => f.id === funnel.id);

    if (existingFunnelIndex !== -1) {
      Object.assign(this.funnels[existingFunnelIndex], funnel);
    } else {
      this.funnels.push(funnel);
    }

    if (this.funnelForm.get('funnel')?.value === funnel.id) {
      this.stages = funnel.stages;  // Refrescar las etapas si el embudo está seleccionado
    }
  }

  initializeFunnelStageChangedSubscription() {
    const eventEmitter = this.eventEmitterService.getEventEmitter(NotificationTopic.FunnelStageChangedEventTopic);
    const subscription = eventEmitter.subscribe((chat: ChatInfo) => {
      this.chat.funnelStageId = chat.funnelStageId;
    });
    this.componentSubscriptions.push(subscription);
  }

  initializeFunnelStageUpdatedSubscription() {
    const funnelStageAddedEventEmitter = this.eventEmitterService.getEventEmitter(NotificationTopic.FunnelStageAddedEventTopic);
    const funnelStageRemovedEventEmitter = this.eventEmitterService.getEventEmitter(NotificationTopic.FunnelStageRemovedEventTopic);
    const funnelAddedEventEmitter = this.eventEmitterService.getEventEmitter(NotificationTopic.FunnelAddedEventTopic);
    const funnelRemovedEventEmitter = this.eventEmitterService.getEventEmitter(NotificationTopic.FunnelRemovedEventTopic);

    const funnelStageAddedSubscription = funnelStageAddedEventEmitter.subscribe((funnelStage: FunnelStageInfo) => {
      this.onStageAddedOrUpdated(funnelStage);
    });

    const funnelStageRemovedSubscription = funnelStageRemovedEventEmitter.subscribe((funnelStage: FunnelStageInfo) => {
      this.onStageRemoved(funnelStage);
    });

    const funnelAddedSubscription = funnelAddedEventEmitter.subscribe((funnel: FunnelInfo) => {
      this.onFunnelAddedOrUpdated(funnel);
    });

    const funnelRemovedSubscription = funnelRemovedEventEmitter.subscribe((funnel: FunnelInfo) => {
      this.funnels = this.funnels.filter(f => f.id !== funnel.id);
    });

    this.componentSubscriptions.push(funnelAddedSubscription);
    this.componentSubscriptions.push(funnelRemovedSubscription);
    this.componentSubscriptions.push(funnelStageRemovedSubscription);
    this.componentSubscriptions.push(funnelStageAddedSubscription);
  }


  initializeMessageNoteSubscription() {

    const eventEmitter = this.eventEmitterService.getEventEmitter(
      NotificationTopic.ShowMessageNote,
    );

    const subscription = eventEmitter.subscribe((message: MessageInfo) => {
      this.creatingNote = true;
      this.noteMessageFormControl.setValue(message.content);
      this.messageNoteSelected = message;
    });

    this.componentSubscriptions.push(subscription);
  }

  private initializeOnChatRequiresAssistanceEvent() {

    const eventEmitter = this.eventEmitterService.getEventEmitter(NotificationTopic.ChatRequiresAssistance);

    const subscription: Subscription = eventEmitter.subscribe((data: any) => {
      const { chatId, humanAssistantAttention } = data;
      if (this.chat.id === chatId) {
        if (humanAssistantAttention) {
          this.chat.smartAssistantEnabled = false;
        }
      }
    });

    this.componentSubscriptions.push(subscription);
  }

  initializeDeleteMessageNoteSubscription() {
    const eventEmitter = this.eventEmitterService.getEventEmitter(
      NotificationTopic.DeleteMessageNote,
    );

    const subscription = eventEmitter.subscribe((message: MessageInfo) => {
      this.deleteMessageNote(message);
    });

    this.componentSubscriptions.push(subscription);
  }

  showSideChatList() {
    this.eventEmitterService.emit(NotificationTopic.ShowSideChatList, true);
  }

  loadQuickReplies() {
    const searchRequest = new QuickReplySearchRequest(this.currentUser.shop.id, -1);
    const subscription = this.quickReplyService.searchQuickReplies(searchRequest).subscribe({
      next: (quickRepliesPage: Page<any>) => {
        this.quickReplies = quickRepliesPage.content;
        this.adjustQuickReplies(this.quickReplies);
      },
      error: (error) => {
        console.log(error);
      },
    });
    this.componentSubscriptions.push(subscription);
  }

  private loadGroups() {
    this.quickReplyService.getQuickReplyGroups().subscribe({
      next: (groups: QuickReplyGroupInfo[]) => {
        this.groups = groups;
      },
      error: (error: any) => {
        console.error('Error loading groups:', error);
        this.toastr.error('Error al cargar las categorias');
      }
    });
  }

  adjustQuickReplies(quickReplies: QuickReplyInfo[]) {
    for (const quickReply of quickReplies) {
      quickReply.textItem = quickReply.items.find(i => i.type === MessageType.Text);
      quickReply.mediaItems = quickReply.items.filter(i => isMediaMessage(i.type));
    }
  }

  initializeReplyingMessageSubscription() {
    const eventEmitter = this.eventEmitterService.getEventEmitter(NotificationTopic.MessageReply);
    const subscription = eventEmitter.subscribe((message: MessageInfo) => {
      this.replyingMessage = message;
    });

    this.componentSubscriptions.push(subscription);
  }

  initializeGoToMessageSubscription() {
    const eventEmitter = this.eventEmitterService.getEventEmitter(NotificationTopic.GoToMessage);
    const subscription = eventEmitter.subscribe((data: any) => {
      this.scrollToMessage(data).then(() => {
      });
    });
    this.componentSubscriptions.push(subscription);
  }

  private async scrollToMessage(data?: any) {
    let chatPage = this.messageSearchRequest.page;
    let totalChatPages = this.messageSearchPage.totalPages;

    let targetMessageId = null;

    if (data) {
      const { messageId } = data;
      if (this.messages.find(message => message.id === messageId)) {
        targetMessageId = messageId;
      } else {
        while (!this.messages.find(message => message.id === messageId) && chatPage <= totalChatPages) {
          const page: Page<MessageInfo> = await this.getConversationMessages(++this.messageSearchRequest.page);
          this.messages.unshift(...page.content);
        }
        targetMessageId = messageId;
      }
    }

    await this.scrollChatContainer(targetMessageId);
  }

  getConversationMessages(page: number) {
    return new Promise<Page<MessageInfo>>((resolve) => {
        const searchRequest = new MessageSearchRequest();
        searchRequest.page = page;
        searchRequest.size = 20;
        searchRequest.chatId = this.chat.id;
        this.messageService.searchMessages(searchRequest)
          .subscribe((page: Page<MessageInfo>) => {
            resolve(page);
          });
      },
    );
  }

  private async scrollChatContainer(targetMessageId: string) {
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        let targetOffsetTop = 0;
        if (targetMessageId) {
          const targetElement = document.getElementById('m' + targetMessageId);
          if (targetElement) {
            const { offsetTop, offsetHeight } = targetElement;
            targetOffsetTop = offsetTop - (window.innerHeight / 2) + (offsetHeight / 2);
            targetElement.classList.add('fade-effect');
            setTimeout(() => {
              targetElement.classList.remove('fade-effect');
            }, 2000);
          }
        }
        const scrollElement = this.chatScrollContainer.nativeElement;
        scrollElement.scrollTop = scrollElement.scrollHeight - scrollElement.clientHeight;
        if (targetOffsetTop !== 0) {
          scrollElement.scrollTop = targetOffsetTop;
        }
        resolve();
      }, 100);
    });
  }

  initializeReactingMessageSubscription() {
    const eventEmitter = this.eventEmitterService.getEventEmitter(NotificationTopic.MessageReaction);
    const subscription = eventEmitter.subscribe((request: any) => {
      const { messageInfo, emoji } = request;
      this.reactingMessage = messageInfo;
      this.sendReactMessage(emoji);
    });

    this.componentSubscriptions.push(subscription);
  }

  private initializeChatSelectedSubscription() {

    const eventEmitter = this.eventEmitterService.getEventEmitter(NotificationTopic.ChatSelected);

    const subscription = eventEmitter.subscribe((chat: ChatInfo) => {

      if (this.messageField?.nativeElement)
        this.messageField.nativeElement.focus();

      this.fillChatContent(chat);
      this.initializeChatGallery();

      if (this.chat && this.funnels?.length > 0) {
        this.initializeFunnelFormValues();
      }
    });

    this.componentSubscriptions.push(subscription);
  }

  private initializeSameChatSelectedSubscription() {
    const eventEmitter = this.eventEmitterService.getEventEmitter(
      NotificationTopic.SameChatSelected,
    );

    const subscription = eventEmitter.subscribe(() => {
      if (this.messageField?.nativeElement)
        this.messageField.nativeElement.focus();
    });

    this.componentSubscriptions.push(subscription);
  }

  private checkChat() {
    this.fillChatContent(this.chat);
  }

  private fillChatContent(chat: ChatInfo) {

    if (!chat) return;
    if (this.chat) this.chat.selected = false;

    this.chat = chat;
    this.chat.selected = true;

    this.initializeSearchMessageRequest();
    this.initializeChatAliasFormControl();
    this.messageSearchRequest.chatId = chat.id;

    this.fillChatInfo();
    this.fillChatMessages(true);
    this.markChatAsSeen();
  }

  private initializeShowSendTemplateMessage() {

    const eventEmitter = this.eventEmitterService.getEventEmitter(
      NotificationTopic.ShowSendTemplateMessage,
    );

    const subscription = eventEmitter.subscribe(() => {
      this.localDataService.set(DataKey.EmptyMessageTemplateRecipient, 'false');
      this.localDataService.set(DataKey.ConfiguringBroadcast, 'false');
      this.openSideOffCanvas('chat-template-sender');
    });

    this.componentSubscriptions.push(subscription);
  }

  private initializeShowSendWhatsAppWebMessage() {

    const eventEmitter = this.eventEmitterService.getEventEmitter(
      NotificationTopic.ShowSendWhatsAppWebMessage,
    );

    const subscription = eventEmitter.subscribe(() => {
      this.openSideOffCanvas('whatsapp-web-message');
    });

    this.componentSubscriptions.push(subscription);
  }

  private initializeToggleThemeSubscription() {

    const eventEmitter = this.eventEmitterService.getEventEmitter(
      NotificationTopic.ToggleTheme,
    );

    const subscription = eventEmitter.subscribe(() => {
      const theme = window.localStorage.getItem('phoenixTheme');
      const newTheme = theme === 'dark' ? 'light' : 'dark';
      this.uppyDashboardProps = { ...this.uppyDashboardProps, theme: newTheme };
    });

    this.componentSubscriptions.push(subscription);
  }

  private initializeOnMessageReceivedEvent() {

    const eventEmitter = this.eventEmitterService.getEventEmitter(NotificationTopic.MessageReceived);

    const subscription: Subscription = eventEmitter.subscribe((data: any) => {

      const { message, chat } = data;

      const { chatId, identifier } = message;
      if (!this.chat || this.chat.id !== chatId) return;

      if (!this.chat.chatAlias) this.chat.chatName = chat.chatName;

      const messageIndex: number = this.messages.findIndex(
        (m: MessageInfo) => m.identifier === identifier,
      );

      if (messageIndex > 0 && identifier !== null) {
        const messageFound: MessageInfo = this.messages[messageIndex];
        Object.assign(messageFound, message);
      } else {
        if (message.type === MessageType.Reaction) {
          this.checkReactionMessage(message);
          return;
        } else {
          this.messages.push(message);
          this.cdr.detectChanges();
          setTimeout(() => {
            this.scrollToBottom();
          }, 10);
        }
      }

      if (message.customerMessage && this.chat.expired) {
        this.chat.expired = false;
      }

      this.markChatAsSeen();
    });

    this.componentSubscriptions.push(subscription);
  }

  private checkReactionMessage(message: MessageInfo) {
    const reactedMessageIndex: number = this.messages.findIndex(
      (m: MessageInfo) => m.wamid === message.reactionWamid,
    );
    if (reactedMessageIndex > 0) {
      const reactedMessage = this.messages[reactedMessageIndex];
      reactedMessage.reactionEmoji = message.reactionEmoji;
    }
  }

  private initializeOnMessageStatusSubscription() {

    const eventEmitter = this.eventEmitterService.getEventEmitter(
      NotificationTopic.MessageStatus,
    );

    const subscription = eventEmitter.subscribe((data: any) => {

      const message = data as MessageInfo;

      if (message.smart && !this.chat.smartAssistantUsed)
        this.chat.smartAssistantUsed = true;

      const { id, chatId, identifier } = message;
      if (!this.chat || this.chat.id !== chatId) return;

      if (!identifier && id) {
        const messageIndex = this.messages.findIndex(m => m.id === id);
        if (messageIndex >= 0) {
          const messageFound = this.messages[messageIndex];
          Object.assign(messageFound, message);
          messageFound.statusClass = getMessageStatusClassString(message.status);
        } else {
          this.messageQueue.push(message);
          this.processMessageQueue();
        }
        return;
      }

      const messageIndex = this.messages.findIndex(m => m.identifier === identifier);

      if (messageIndex >= 0) {
        const messageFound = this.messages[messageIndex];
        Object.assign(messageFound, message);
        messageFound.statusClass = getMessageStatusClassString(message.status);
      } else {
        this.messageQueue.push(message);
        this.processMessageQueue(); // Iniciar el procesamiento de la cola si no está en curso
      }

      // message.statusClass = getMessageStatusClassString(message.status);
      // message.formattedSentTime = getFormattedMessageTime(message.sentTime);
      // message.formattedFileSize = getFormattedFileSize(message.fileSize);

      if (message.customerMessage && this.chat.expired) {
        this.chat.expired = false;
      }

      this.markChatAsSeen();

    });

    this.componentSubscriptions.push(subscription);
  }

  private processMessageQueue() {

    // Verificar si ya se está procesando la cola
    if (this.isProcessingQueue) {
      return;
    }

    // Establecer la variable de bloqueo para evitar que otros procesos accedan a la cola
    this.isProcessingQueue = true;

    if (this.messageQueue.length > 0) {
      const message = this.messageQueue.shift();
      this.messageService.getMessage(message.id).subscribe({
        next: (messageFound: MessageInfo) => {
          messageFound.statusClass = getMessageStatusClassString(
            messageFound.status,
          );
          this.messages.push(messageFound);
          setTimeout(() => {
            this.scrollToBottom();
          }, 10);
          this.processMessageQueue(); // Llamar de nuevo para procesar el siguiente mensaje en la cola
        },
        error: (error) => {
          console.log(error);
          this.processMessageQueue(); // Llamar de nuevo para procesar el siguiente mensaje en la cola en caso de error
        },
        complete: () => {
          // Liberar la variable de bloqueo para permitir que otros procesos accedan a la cola
          this.isProcessingQueue = false;
        },
      });
    } else {
      // Liberar la variable de bloqueo si la cola está vacía
      this.isProcessingQueue = false;
    }
  }

  private initializeSearchMessageRequest() {
    this.messageSearchRequest = new MessageSearchRequest();
    this.messageSearchRequest.page = 0;
    this.messageSearchRequest.size = 20;
  }

  private initializeUppy() {

    this.uppy = new Uppy({
      debug: false,
      autoProceed: false,
      locale: UppyLocaleValues,
    });

    this.uppy.on('files-added', (files: UppyFile[]) => {

      this.validateUppyFiles(files);

      setTimeout(() => {
        const element = document.querySelector('.uppy-Dashboard-files');
        if (element) element.classList.add('scrollbar');
      }, 1);
    });

    this.uppy.on('complete', () => {
      setTimeout(() => {
        this.uppy.setState({
          files: {},
          currentUploads: {},
          totalProgress: 0,
        });
        this.showingUppy = false;
      }, 1000);
    });

    this.uppyDashboardProps = {
      width: '100%',
      height: 350,
      hideUploadButton: true,
    };

    this.uppy.use(DropTarget, {
      target: '#tab-chat-content-thread',
      onDrop: () => {
        this.showingUppy = true;
      },
    });

    this.uppy.use(AwsS3, {
      id: 'uppy-aws-s3',
      getUploadParameters: async (uppyFile: UppyFile) => {
        const prefix = this.currentUser.shop.uuid;
        const getSignedUrlRequest = new GetSignedUrlRequest(
          prefix,
          uppyFile.name,
          uppyFile.type,
        );
        const response = await lastValueFrom(
          this.messageService.getWhatsAppSignedFileUrl(getSignedUrlRequest),
        );

        (uppyFile as any).fileKey = response.key;

        return {
          method: 'PUT',
          url: response.signedUrl,
          headers: {
            'Content-Type': uppyFile.type,
          },
        };
      },
    });

    const theme = localStorage.getItem('phoenixTheme');

    if (theme === 'dark' || theme === 'light') {
      this.uppyDashboardProps.theme = theme;
    }
  }

  public toggleUppy() {
    this.showingUppy = !this.showingUppy;
    if (!this.showingUppy) {
      this.uppy.cancelAll();
    }
  }

  private validateUppyFiles(uppyFiles: UppyFile[]) {

    const invalidFiles: UppyFile[] = [];

    if (this.uppy.getFiles().length > 0) {
      this.showingUppy = true;
    }

    for (const uppyFile of uppyFiles) {
      const { type, size } = uppyFile;
      const { sizeLimit } = messageTypes[getMessageTypeByMediaType(type)];

      if (size > sizeLimit) {
        invalidFiles.push(uppyFile);
        this.uppy.removeFile(uppyFile.id);
      }
    }

    for (const invalidFile of invalidFiles) {
      this.toastr.warning(
        `${(invalidFile.size / 1048576).toFixed(2)} MB`,
        `${invalidFile.name}`,
      );
    }

    if (invalidFiles.length > 0) {
      let message =
        'Los siguientes archivos no pudieron ser agregados debido a que superan el límite de tamaño';
      this.toastr.info(message);
    }
  }

  private scrollToBottom() {
    this.afterChatsLoaded = false;
    const scrollElement = this.chatScrollContainer.nativeElement;
    scrollElement.scrollTop = scrollElement.scrollHeight;
    this.afterChatsLoaded = true;
  }

  private fillChatInfo() {

    this.chatService.getChatById(this.chat.id).subscribe({
      next: (chat: ChatInfo) => {
        this.chat = chat;
      },
      error: (error) => {
        console.log(error);
      },
    });

  }

  private fillChatMessages(reload?: boolean) {

    this.chatContentSpinnerText = 'Cargando mensajes...';

    if (!this.chat.synchronized) {
      this.chat.synchronized = true;
    }

    if (reload) {
      this.spinner.show('chat-content-spinner');
      this.hideMessages = true;
      this.clearMessages();
    }

    if (this.messageSearchRequest.page === 0) this.firstLoad = true;

    const subscription = this.messageService.searchMessages(this.messageSearchRequest).subscribe({
      next: (messagesPage: Page<MessageInfo>) => {
        this.messageSearchPage = messagesPage;
        this.formatMessages(messagesPage.content);
        this.appendMessages(messagesPage.content, reload);
        if (reload) {
          this.cdr.detectChanges();
          setTimeout(() => this.scrollToBottom(), 100);
        }
      },
      error: (error) => {
        console.log(error);
      },
    });

    this.componentSubscriptions.push(subscription);
  }

  private appendMessages(newMessages: MessageInfo[], reload: boolean) {
    const scrollElement = this.chatScrollContainer.nativeElement;
    const previousScrollHeight = scrollElement.scrollHeight;
    if (reload) this.clearMessages();
    let allReady: boolean = true;
    for (const message of newMessages) {
      if (message.type === MessageType.Image || message.type === MessageType.Video) {
        message.ready = false;
        allReady = false;
      }
    }
    if (allReady) {
      this.hideMessages = false;
    }
    this.messages.unshift(...newMessages);
    setTimeout(() => {
      if (scrollElement.scrollTop === 0) {
        scrollElement.scrollTop =
          scrollElement.scrollHeight - previousScrollHeight;
      }
    }, 50);
  }

  private clearMessages() {
    this.messages = [];
  }

  public onScrolledUp() {
    if (this.chat.synchronized)
      if (this.messageSearchPage.last) return;
    this.messageSearchRequest.page++;
    this.fillChatMessages();
  }

  private formatMessages(messages: MessageInfo[]) {
    for (const message of messages) {
      message.statusClass = getMessageStatusClassString(message.status);
      message.ready = true;
    }
  }

  public onKeydown(event: any) {

    const textAreaElement = this.messageField.nativeElement;
    let textAreaContent = this.textAreaFormControl.value;

    if (event.code === 'Enter') {
      if (event.ctrlKey) {
        this.textAreaFormControl.setValue(textAreaContent + '\n');
        textAreaElement.scrollTop = textAreaElement.scrollHeight;
      } else if (event.shiftKey) {
      } else {
        textAreaContent = textAreaContent.trim();
        event.preventDefault();

        if (textAreaContent || this.uppy.getFiles().length > 0) {
          if (textAreaContent.startsWith('/')) {
            const textToFilter = textAreaContent.toLowerCase().replace('/', '');
            const quickReply = this.quickReplies.find(
              (qr) => qr.name.toLowerCase() === textToFilter,
            );
            if (quickReply) {
              this.sendQuickReply(quickReply).then(() => {
                if (this.messageField && this.messageField.nativeElement instanceof HTMLTextAreaElement) {
                  this.messageField.nativeElement.focus();
                }
              });
            }
          } else {
            this.textAreaFormControl.setValue(textAreaContent);
            this.sendMessage().then(() => {
              console.log('ok');
            });
          }
        }
      }
    }
  }

  public onKeydownNote(event: any) {

    const textAreaElement = this.noteMessageField.nativeElement;
    let textAreaContent = this.noteMessageFormControl.value;

    if (event.code === 'Enter') {
      if (event.ctrlKey) {
        this.noteMessageFormControl.setValue(textAreaContent + '\n');
        textAreaElement.scrollTop = textAreaElement.scrollHeight;
      } else if (event.shiftKey) {
      } else {
        textAreaContent = textAreaContent.trim();
        event.preventDefault();
        if (textAreaContent) {
          this.noteMessageFormControl.setValue(textAreaContent);
          this.creatingNote = false;
          this.sendMessage().then(() => {
            console.log('ok');
          });
        }
      }
    }
  }

  public onKeyup(event: any, popover?: any) {

    const textAreaContent = this.textAreaFormControl.value.trim();

    if (textAreaContent.startsWith('/')) {
      const textToFilter = textAreaContent.toLowerCase().replace('/', '');

      if (textToFilter) {
        this.filteredQuickReplies = this.quickReplies.filter((qr) => {
          return normalizeText(qr.name).includes(normalizeText(textToFilter));
        });
      } else {
        this.filteredQuickReplies = this.quickReplies;
      }

      if (popover)
        if (!popover.isOpen()) {
          this.openedPopover = popover;
          popover.open();
        }
    } else if (textAreaContent === '') {
      this.openedPopover = null;
      if (popover)
        popover.close();
    }
  }

  public openPopover() {
    this.filteredQuickReplies = this.quickReplies;
    this.popover.open();
  }

  public openChatSummaryModal() {

    if (this.chat.chatSummary) this.summaryChatFormControl.setValue(this.chat.chatSummary);

    const modalRef = this.modalService.open(this.chatResumeModal, { size: 'lg' });

    modalRef.hidden.subscribe(() => this.summaryChatFormControl.setValue(''));
    modalRef.dismissed.subscribe(() => this.summaryChatFormControl.setValue(''));
  }

  public closePopover() {
    this.popover.close();
  }

  async sendQuickReply(quickReply: QuickReplyInfo, event?: any) {

    if (event) event.stopPropagation();
    if (this.popover.isOpen()) this.popover.close();

    this.textAreaFormControl.setValue('');

    if (this.openedPopover) {
      this.openedPopover.close();
      this.openedPopover = null;
    }

    const quickReplyResponse = await this.getQuickReply(quickReply.id);

    const textItem = quickReplyResponse.items.find(i => i.type === MessageType.Text);
    const mediaItems = quickReplyResponse.items.filter(i => isMediaMessage(i.type));

    const sendFilesPromises = [];

    for (const mediaItem of mediaItems)
      sendFilesPromises.push(this.sendQuickReplyItemMediaMessage(mediaItem));

    await Promise.all(sendFilesPromises);

    if (textItem) {
      setTimeout(() => {
        this.sendTextMessage(textItem.content, MessageType.Text, quickReplyResponse.buttons);
      }, 1000);
    }

    if (this.messageField && this.messageField.nativeElement instanceof HTMLTextAreaElement) {
      this.messageField.nativeElement.focus();
    }
  }

  private getQuickReply(quickReplyId: number) {
    return new Promise<QuickReplyInfo>((resolve, reject) => {
      const subscription = this.quickReplyService.getQuickReply(quickReplyId).subscribe({
        next: (quickReplyResponse: QuickReplyInfo) => {
          resolve(quickReplyResponse);
        },
        error: (error) => {
          reject(error);
        },
      });
      this.componentSubscriptions.push(subscription);
    });
  }

  selectQuickReply(quickReply: QuickReplyInfo) {

    this.textAreaFormControl.setValue('');

    if (this.openedPopover) {
      this.openedPopover.close();
      this.openedPopover = null;
    }

    const subscription = this.quickReplyService.getQuickReply(quickReply.id).subscribe({
      next: (quickReplyResponse: QuickReplyInfo) => {
        const textItem = quickReplyResponse.items.find(
          (i) => i.type === MessageType.Text,
        );
        const mediaItems = quickReplyResponse.items.filter((i) =>
          isMediaMessage(i.type),
        );

        if (textItem) this.textAreaFormControl.setValue(textItem.content);
        if (mediaItems.length > 0) this.showingUppy = true;

        for (const mediaItem of mediaItems) {
          this.fileService.getFileBlob(mediaItem.fileKey).then((blob: Blob) => {
            this.uppy.addFile({
              data: blob,
              name: mediaItem.fileName,
              type: mediaItem.fileContentType,
              size: mediaItem.fileSize,
            });
          });
        }
        if (this.messageField && this.messageField.nativeElement instanceof HTMLTextAreaElement) {
          this.messageField.nativeElement.focus();
        }
      },
      error: (error) => {
        console.log(error);
      },
    });

    this.componentSubscriptions.push(subscription);
  }

  async sendMessage() {

    const textAreaContent = this.textAreaFormControl.value.trim();
    const noteMessageContent = this.noteMessageFormControl.value.trim();

    if (this.uppy.getFiles().length > 0) {
      const allUploadResponse = await this.uppy.upload();
      for (const uploadedUppyFile of allUploadResponse.successful) {
        await this.sendUppyFileMessage(uploadedUppyFile);
      }
    }

    if (textAreaContent) {
      this.sendTextMessage(textAreaContent, MessageType.Text);
      this.textAreaFormControl.setValue('');
    }

    if (noteMessageContent) {

      if (!this.messageNoteSelected) this.sendTextMessage(noteMessageContent, MessageType.Note);
      else this.updateMessageNote(noteMessageContent);

      this.noteMessageFormControl.setValue('');
      this.creatingNote = false;
    }

    this.chat.humanAssistantAttention = false;

    this.eventEmitterService.emit(NotificationTopic.ChatRequiresAssistance, {
      chatId: this.chat.id,
      hummanAssistantAttention: this.chat.humanAssistantAttention,
    });
  }

  updateMessageNote(noteMessageContent: string) {

    const message = this.messageNoteSelected;

    const updateMessageNoteRequest = new UpdateMessageNoteRequest();
    updateMessageNoteRequest.content = noteMessageContent;
    updateMessageNoteRequest.messageId = message.id;

    this.messageService.updateMessageNote(updateMessageNoteRequest).subscribe({
      next: () => {
        this.messageNoteSelected.content = noteMessageContent;
        this.eventEmitterService.emit(NotificationTopic.MessageNoteUpdated, this.messageNoteSelected);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  deleteMessageNote(message: MessageInfo) {

    this.messageService.deleteMessageNote(message.id).subscribe({
      next: () => {
        const messageIndex = this.messages.findIndex((m) => m.id === message.id);
        this.messages.splice(messageIndex, 1);
      },
      error: (error) => {
        console.log(error);
      },
    });

  }

  private sendReactMessage(emoji: string) {

    if (this.reactingMessage) {
      const message = this.messages.find(message => message.wamid === this.reactingMessage.wamid);
      message.reactionEmoji = emoji;
      message.reactionWamid = this.reactingMessage.wamid;
    }

    const sendWhatsAppReactionRequest = new SendWhatsAppReactionRequest();
    sendWhatsAppReactionRequest.message_id = this.reactingMessage.wamid;
    sendWhatsAppReactionRequest.emoji = emoji;

    const sendWhatsAppMessageRequest = new SendWhatsAppMessageRequest();
    sendWhatsAppMessageRequest.reaction = sendWhatsAppReactionRequest;

    const sendMessageRecipientRequest = new SendMessageRecipientRequest();
    sendMessageRecipientRequest.chatId = this.chat.id;
    sendMessageRecipientRequest.chatName = this.chat.chatName;
    sendMessageRecipientRequest.chatPhoneNumber = this.chat.chatPhoneNumber;

    const sendMessageRequest = new SendMessageRequest();

    sendMessageRequest.shopId = this.currentUser.shop.id;
    //sendMessageRequest.identifier = identifier;
    sendMessageRequest.channelType = this.chat.channel;
    sendMessageRequest.recipient = sendMessageRecipientRequest;
    sendMessageRequest.type = MessageType.Reaction;
    sendMessageRequest.sendWhatsAppMessageRequest = sendWhatsAppMessageRequest;

    const subscription = this.messageService.sendMessage(sendMessageRequest).subscribe({
      next: () => {
      },
      error: (error) => {
        console.log(error);
      },
    });

    this.componentSubscriptions.push(subscription);
  }

  private sendTextMessage(textMessage: string, messageType: MessageType, buttons?: QuickReplyButtonInfo[]) {

    const identifier = uuidv4();

    const previewMessage = new MessageInfo();

    previewMessage.id = undefined;
    previewMessage.identifier = identifier;
    previewMessage.chatId = this.chat.id;
    previewMessage.wamid = undefined;
    previewMessage.content = textMessage;
    previewMessage.phoneNumber = this.chat.chatPhoneNumber;
    previewMessage.sentTime = new Date();
    previewMessage.status = MessageStatus.Sent;
    previewMessage.type = messageType;
    previewMessage.customerMessage = false;
    previewMessage.statusClass = getMessageStatusClassString(previewMessage.status);
    previewMessage.hidden = false;
    previewMessage.senderId = this.currentUser.id;
    previewMessage.sentBy = this.currentUser.name;

    if (buttons?.length > 0) {
      previewMessage.interactiveButtons = JSON.stringify(
        buttons.map(button => {
          return {
            type: 'reply',
            reply: {
              title: button.text,
            },
          };
        }),
      );
      previewMessage.type = MessageType.Interactive;
    }

    if (messageType === MessageType.Note) {
      previewMessage.status = MessageStatus.Read;
      previewMessage.statusClass = getMessageStatusClassString(previewMessage.status);
    }

    if (this.replyingMessage) {
      previewMessage.contextMessage = this.replyingMessage;
    }

    console.log('previewMessage', previewMessage);
    this.messages.push(previewMessage);

    this.cdr.detectChanges();
    this.scrollToBottom();

    this.eventEmitterService.emit(NotificationTopic.MessageReceived, {
      chat: this.chat,
      message: previewMessage,
    });

    const sendMessageRequest = this.getTextMessageRequest(textMessage, identifier, messageType, buttons);

    const subscription = this.messageService.sendMessage(sendMessageRequest)
      .subscribe({
        next: (message: MessageInfo) => {
          previewMessage.id = message.id;
        },
        error: (error) => {
          console.log(error);
        },
      });

    this.componentSubscriptions.push(subscription);
  }

  private getTextMessageRequest(textMessage: string, identifier: string, messageType: MessageType, buttons?: QuickReplyButtonInfo[]) {

    const sendMessageRequest = new SendMessageRequest();

    sendMessageRequest.type = messageType;
    sendMessageRequest.shopId = this.currentUser.shop.id;
    sendMessageRequest.identifier = identifier;
    sendMessageRequest.channelType = this.chat.channel;
    sendMessageRequest.senderId = this.currentUser.id;
    sendMessageRequest.sentBy = this.currentUser.name;

    const sendMessageRecipientRequest = new SendMessageRecipientRequest();
    sendMessageRecipientRequest.chatId = this.chat.id;
    sendMessageRecipientRequest.chatName = this.chat.chatName;

    switch (this.chat.channel) {

      case ChannelType.WhatsApp:
      case ChannelType.WhatsAppWeb:

        sendMessageRecipientRequest.chatPhoneNumber = this.chat.chatPhoneNumber;

        const sendWhatsAppMessageRequest = new SendWhatsAppMessageRequest();

        if (buttons?.length > 0) {

          sendMessageRequest.type = MessageType.Interactive;

          const sendWhatsAppInteractiveActionRequest = new SendWhatsAppInteractiveActionRequest();

          for (let i = 0; i < buttons.length; i++) {

            const button = buttons[i];

            const sendWhatsAppInteractiveButtonReplyRequest = new SendWhatsAppInteractiveButtonReplyRequest();
            sendWhatsAppInteractiveButtonReplyRequest.title = button.text;
            sendWhatsAppInteractiveButtonReplyRequest.id = (i + 1).toString();

            const sendWhatsAppInteractiveButtonRequest = new SendWhatsAppInteractiveButtonRequest();
            sendWhatsAppInteractiveButtonRequest.type = 'reply';
            sendWhatsAppInteractiveButtonRequest.reply = sendWhatsAppInteractiveButtonReplyRequest;

            sendWhatsAppInteractiveActionRequest.buttons.push(sendWhatsAppInteractiveButtonRequest);
          }

          const sendWhatsAppInteractiveBodyRequest = new SendWhatsAppInteractiveBodyRequest();
          sendWhatsAppInteractiveBodyRequest.text = textMessage;

          const sendWhatsAppInteractiveRequest = new SendWhatsAppInteractiveRequest();
          sendWhatsAppInteractiveRequest.type = 'button';
          sendWhatsAppInteractiveRequest.body = sendWhatsAppInteractiveBodyRequest;
          sendWhatsAppInteractiveRequest.action = sendWhatsAppInteractiveActionRequest;

          sendWhatsAppMessageRequest.interactive = sendWhatsAppInteractiveRequest;

        } else {

          const sendWhatsAppTextRequest = new SendWhatsAppTextRequest();
          sendWhatsAppTextRequest.body = textMessage;

          sendWhatsAppMessageRequest.text = sendWhatsAppTextRequest;

          if (this.replyingMessage) {

            const sendWhatsAppMessageContextRequest = new SendWhatsAppMessageContextRequest();
            sendWhatsAppMessageContextRequest.message_id = this.replyingMessage.wamid;
            sendWhatsAppMessageContextRequest.contextFrom = this.replyingMessage.phoneNumber;
            sendWhatsAppMessageRequest.context = sendWhatsAppMessageContextRequest;

            this.replyingMessage = null;
          }
        }

        sendMessageRequest.sendWhatsAppMessageRequest = sendWhatsAppMessageRequest;

        break;
      case ChannelType.Messenger:

        sendMessageRecipientRequest.chatMessengerContactId = this.chat.chatMessengerContactId;

        const sendMessengerMessageMessageRequest = new SendMessengerMessageMessageRequest();
        sendMessengerMessageMessageRequest.text = textMessage;

        const sendMessengerMessageRequest = new SendMessengerMessageRequest();
        sendMessengerMessageRequest.message = sendMessengerMessageMessageRequest;

        sendMessageRequest.sendMessengerMessageRequest = sendMessengerMessageRequest;

        break;
    }

    sendMessageRequest.recipient = sendMessageRecipientRequest;

    return sendMessageRequest;
  }

  private sendUppyFileMessage(uploadedUppyFile: UploadedUppyFile<any, any>) {
    const { name, type, size, uploadURL } = uploadedUppyFile;
    const { fileKey } = uploadedUppyFile as any;
    return this.sendFileMessage(name, type, size, uploadURL, fileKey);
  }

  private sendQuickReplyItemMediaMessage(quickReplyItem: QuickReplyItemInfo) {
    const { fileName, fileContentType, fileSize, fileUrl, fileKey } = quickReplyItem;
    return this.sendFileMessage(
      fileName,
      fileContentType,
      fileSize,
      fileUrl,
      fileKey,
    );
  }

  private sendFileMessage(fileName: string, fileType: string, fileSize: number, fileUrl: string, fileKey: string) {

    return new Promise<void>((resolve) => {

      const messageType = getMessageTypeByMediaType(fileType);

      const identifier = uuidv4();

      const previewMessage = new MessageInfo();
      previewMessage.id = undefined;
      previewMessage.identifier = identifier;
      previewMessage.chatId = this.chat.id;
      previewMessage.wamid = undefined;
      previewMessage.content = undefined;
      previewMessage.phoneNumber = this.chat.chatPhoneNumber;
      previewMessage.sentTime = new Date();
      previewMessage.status = MessageStatus.Pending;
      previewMessage.type = messageType;
      previewMessage.customerMessage = false;
      previewMessage.fileUrl = fileUrl;
      previewMessage.fileName = fileName;
      previewMessage.fileContentType = fileType;
      previewMessage.fileSize = fileSize;
      previewMessage.statusClass = getMessageStatusClassString(previewMessage.status);
      previewMessage.hidden = false;
      previewMessage.senderId = this.currentUser.id;
      previewMessage.sentBy = this.currentUser.name;

      if (this.replyingMessage) {
        previewMessage.contextMessage = this.replyingMessage;
      }
      this.messages.push(previewMessage);
      this.cdr.detectChanges();
      this.scrollToBottom();

      this.eventEmitterService.emit(
        NotificationTopic.MessageReceived,
        previewMessage,
      );

      const sendWhatsAppMediaRequest = new SendWhatsAppMediaRequest();
      sendWhatsAppMediaRequest.link = fileUrl;

      if (messageType === MessageType.Document) {
        sendWhatsAppMediaRequest.filename = fileName;
      }

      const sendWhatsAppMessageRequest = new SendWhatsAppMessageRequest();
      sendWhatsAppMessageRequest[messageType] = sendWhatsAppMediaRequest;

      if (this.replyingMessage) {
        const sendWhatsAppMessageContextRequest = new SendWhatsAppMessageContextRequest();
        sendWhatsAppMessageContextRequest.message_id = this.replyingMessage.wamid;
        sendWhatsAppMessageContextRequest.contextFrom = this.replyingMessage.phoneNumber;
        sendWhatsAppMessageRequest.context = sendWhatsAppMessageContextRequest;
      }

      const sendMessageRecipientRequest = new SendMessageRecipientRequest();
      sendMessageRecipientRequest.chatId = this.chat.id;
      sendMessageRecipientRequest.chatName = this.chat.chatName;
      sendMessageRecipientRequest.chatPhoneNumber = this.chat.chatPhoneNumber;

      const fileMetadata = new FileMetadata();
      fileMetadata.fileKey = fileKey;
      fileMetadata.fileName = fileName;
      fileMetadata.fileContentType = fileType;
      fileMetadata.fileSize = fileSize;
      fileMetadata.fileUrl = fileUrl;
      fileMetadata.uuid = previewMessage.uuid;

      const sendMessageRequest = new SendMessageRequest();

      sendMessageRequest.shopId = this.currentUser.shop.id;
      sendMessageRequest.identifier = identifier;
      sendMessageRequest.channelType = this.chat.channel;
      sendMessageRequest.recipient = sendMessageRecipientRequest;
      sendMessageRequest.type = messageType;
      sendMessageRequest.fileMetadata = fileMetadata;
      sendMessageRequest.senderId = this.currentUser.id;
      sendMessageRequest.sentBy = this.currentUser.name;
      sendMessageRequest.sendWhatsAppMessageRequest = sendWhatsAppMessageRequest;

      this.replyingMessage = null;

      const subscription = this.messageService.sendMessage(sendMessageRequest).subscribe({
        next: () => {
          resolve();
        },
        error: (error) => {
          console.log(error);
        },
      });

      this.componentSubscriptions.push(subscription);
    });
  }

  private sendAudioMessage() {

    const fileName = 'audio.aac';
    const fileContentType = 'audio/aac';
    const messageType = MessageType.Audio;

    const identifier = uuidv4();

    const previewMessage = new MessageInfo();
    previewMessage.id = undefined;
    previewMessage.identifier = identifier;
    previewMessage.chatId = this.chat.id;
    previewMessage.wamid = undefined;
    previewMessage.content = undefined;
    previewMessage.phoneNumber = this.chat.chatPhoneNumber;
    previewMessage.sentTime = new Date();
    previewMessage.status = MessageStatus.Pending;
    previewMessage.type = messageType;
    previewMessage.customerMessage = false;
    previewMessage.fileUrl = this.blobUrl.toString();
    previewMessage.fileName = fileName;
    previewMessage.fileContentType = null;
    previewMessage.fileSize = null;
    previewMessage.statusClass = getMessageStatusClassString(previewMessage.status);
    previewMessage.hidden = false;

    this.messages.push(previewMessage);
    this.cdr.detectChanges();
    this.scrollToBottom();

    this.eventEmitterService.emit(
      NotificationTopic.MessageReceived,
      previewMessage,
    );

    const prefix = this.currentUser.shop.uuid;
    const getSignedUrlRequest = new GetSignedUrlRequest(
      prefix,
      fileName,
      fileContentType,
    );

    const subscription = this.messageService
      .getWhatsAppSignedFileUrl(getSignedUrlRequest)
      .subscribe({
        next: (signedFileResponse) => {
          this.mediaService
            .uploadMedia(signedFileResponse.signedUrl, this.blob)
            .subscribe({
              next: () => {
                const sendWhatsAppMediaRequest = new SendWhatsAppMediaRequest();
                sendWhatsAppMediaRequest.link = signedFileResponse.publicUrl;

                const sendWhatsAppMessageRequest = new SendWhatsAppMessageRequest();
                sendWhatsAppMessageRequest[messageType] = sendWhatsAppMediaRequest;

                const sendMessageRecipientRequest = new SendMessageRecipientRequest();
                sendMessageRecipientRequest.chatId = this.chat.id;
                sendMessageRecipientRequest.chatName = this.chat.chatName;
                sendMessageRecipientRequest.chatPhoneNumber = this.chat.chatPhoneNumber;

                const fileMetadata = new FileMetadata();
                fileMetadata.fileKey = signedFileResponse.key;
                fileMetadata.fileName = fileName;
                fileMetadata.fileContentType = fileContentType;
                fileMetadata.fileSize = this.blob.size;
                fileMetadata.fileUrl = signedFileResponse.publicUrl;
                fileMetadata.uuid = previewMessage.uuid;

                const sendMessageRequest = new SendMessageRequest();

                sendMessageRequest.shopId = this.currentUser.shop.id;
                sendMessageRequest.identifier = identifier;
                sendMessageRequest.channelType = this.chat.channel;
                sendMessageRequest.recipient = sendMessageRecipientRequest;
                sendMessageRequest.type = messageType;
                sendMessageRequest.fileMetadata = fileMetadata;
                sendMessageRequest.sendWhatsAppMessageRequest = sendWhatsAppMessageRequest;

                this.messageService.sendMessage(sendMessageRequest).subscribe({
                  next: () => {
                  },
                  error: (error) => {
                    console.log(error);
                  },
                });
              },
              error: (error) => {
                console.log(error);
              },
            });
        },
        error: (error) => {
          console.log(error);
        },
      });

    this.componentSubscriptions.push(subscription);
  }

  private markChatAsSeen() {

    const markChatAsSeenRequest = new MarkChatAsSeenRequest(this.chat.id, true);

    const subscription = this.chatService.markChatAsSeen(markChatAsSeenRequest).subscribe({
      next: () => {
      },
      error: (error) => {
        console.log(error);
      },
    });

    this.componentSubscriptions.push(subscription);
  }

  public initializeAudioRecorderEvents() {

    const recordingFailedSubscription = this.audioRecordingService
      .recordingFailed()
      .subscribe(() => (this.isRecording = false));

    const recordedTimeSubscription = this.audioRecordingService
      .getRecordedTime()
      .subscribe((time: string) => (this.recordedTime = time));

    const recordedBlobSubscription = this.audioRecordingService.getRecordedBlob().subscribe((data) => {
      const { blob } = data;
      this.blobUrl = URL.createObjectURL(blob).toString();
      this.blob = blob;
      this.sendAudioMessage();
    });

    this.componentSubscriptions.push(...[
      recordingFailedSubscription,
      recordedTimeSubscription,
      recordedBlobSubscription,
    ]);
  }

  public startAudioRecording() {
    if (this.isRecording) return;
    this.isRecording = true;
    this.audioRecordingService.startRecording();
  }

  public abortAudioRecording() {
    if (!this.isRecording) return;
    this.isRecording = false;
    this.audioRecordingService.abortRecording();
  }

  public stopAudioRecording() {
    if (!this.isRecording) return;
    this.isRecording = false;
    this.audioRecordingService.stopRecording();
  }

  public openChatTemplateSender() {
    this.localDataService.set(DataKey.EmptyMessageTemplateRecipient, 'true');
    this.localDataService.set(DataKey.ConfiguringBroadcast, 'false');
    this.openSideOffCanvas('chat-template-sender');
  }

  public openChatSettings() {
    this.openSideOffCanvas('chat-settings');
  }

  private openSideOffCanvas(component: string) {

    const ableToOpen = !this.showChatSettings && !this.showChatTemplateSender && !this.showWhatsAppWebMessageSender;

    switch (component) {
      case 'chat-settings':
        this.showChatSettings = true;
        break;
      case 'chat-template-sender':
        this.showChatTemplateSender = true;
        break;
      case 'whatsapp-web-message':
        this.showWhatsAppWebMessageSender = true;
        break
    }

    if (ableToOpen) {

      const offCanvasRef = this.offCanvasService.open(this.sideOffCanvas, {
        position: 'end',
        panelClass: 'chat-settings-offcanvas',
        scroll: true,
        backdrop: false,
      });

      const subscription = offCanvasRef.hidden.subscribe(() => {

        if (this.showChatSettings) this.showChatSettings = false;
        if (this.showChatTemplateSender) this.showChatTemplateSender = false;
        if (this.showWhatsAppWebMessageSender) this.showWhatsAppWebMessageSender = false;

        this.localDataService.set(DataKey.EmptyMessageTemplateRecipient, 'true',);
      });

      this.componentSubscriptions.push(subscription);
    }
  }

  public openEmojiPopover(popover: any) {
    setTimeout(() => {
      popover.open();
    }, 10);
  }

  public selectEmoji(event: any) {
    this.textAreaFormControl.setValue(this.textAreaFormControl.value + event.emoji.native);
  }

  public toggleChatSeen() {

    const markChatAsSeenRequest = new MarkChatAsSeenRequest(this.chat.id, !this.chat.seen);

    const subscription = this.chatService.markChatAsSeen(markChatAsSeenRequest).subscribe({
      next: () => {
        this.chat.seen = !this.chat.seen;
        const seenEvent = {
          seen: this.chat.seen,
          chatId: this.chat.id,
        };
        this.eventEmitterService.emit(NotificationTopic.ChatSeen, seenEvent);

      },
      error: (error) => {
        console.log(error);
      },
    });

    this.componentSubscriptions.push(subscription);

  }

  public toggleChatArchive() {

    if (!this.chat && !this.chat.id) return;

    let afterActionMessage: string = this.chat.archived ? 'Chat desarchivado' : 'Chat archivado';
    const chatIds: number[] = [this.chat.id];

    const archiveChatRequest: ArchiveChatRequest = new ArchiveChatRequest(
      chatIds, !this.chat.archived,
    );
    this.chatService.archiveChat(archiveChatRequest).subscribe({
      next: () => {
        this.chat.archived = !this.chat.archived;
        this.offCanvasService.dismiss();
        this.toastr.success(afterActionMessage);
        const archiveEvent = {
          archive: this.chat.archived,
          chatId: this.chat.id,
        };
        this.eventEmitterService.emit(NotificationTopic.ChatArchive, archiveEvent);
      },
      error: (error: any) => {
        console.error('Error al archivar el contacto:', error);
        this.toastr.error('Error al archivar el contacto');
      },
    });
  }

  public showChatThread() {

    this.spinnerService.show('Obteniendo hilo de chat...');

    this.chatService.getChatThread(this.chat.id).subscribe({
      next: (chatThread: any) => {

        this.chatThread = chatThread;

        this.modalService.open(this.chatThreadModal, {
          size: 'lg',
          centered: true,
        });

        this.spinnerService.hide();
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  public openAdvisorPopover(popover: any) {
    setTimeout(() => {
      popover.open();
    }, 50);
  }

  public closeAdvisorPopover(popover: any) {
    setTimeout(() => {
      popover.close();
    }, 100);
  }

  public showAssignAdvisorModal() {
    this.modalService.open(this.assignAdvisorModal, {
      backdrop: 'static',
      centered: true,
    });
  }

  public setChatAdvisorChosen(user: UserInfo) {
    this.chatAdvisorChosen = user;
  }

  public assignUserToChat() {

    const user = this.chatAdvisorChosen;
    const assignChatAdvisorRequest = new AssignChatAdvisorRequest(
      [this.chat.id], user.id,
    );

    this.chatService.assignChatAdvisor(assignChatAdvisorRequest).subscribe({
      next: () => {

        this.chat.user = user;
        this.chat.userId = user.id;

        this.modalService.dismissAll();
        this.toastr.success('Asesor asignado correctamente', '¡Exito!');
      },
      error: (error: any) => {
        console.error('Error al asignar el asesor:', error);
        this.toastr.error('Error al asignar el asesor');
      },
    });
  }

  private initializeChatGallery() {

    const mediaMessageTypes = [MessageType.Image, MessageType.Video, MessageType.Audio];
    const documentMessageTypes = [MessageType.Document, MessageType.File];

    if (this.chat) {
      this.chatService.getMediaMessages(this.chat.id).subscribe({
        next: (mediaMessages) => {
          this.mediaMessages = mediaMessages.filter(mm => mediaMessageTypes.includes(mm.type));
          this.documentMessages = mediaMessages.filter(mm => documentMessageTypes.includes(mm.type));
        },
        error: () => {

        },
      });
    }
  }

  public downloadFile(message: MessageInfo) {

    this.fileService.getFileBlob(message.fileKey).then((blob: Blob) => {
      saveAs(blob, message.fileName);
      this.toastr.success('Archivo descargado.');
      setTimeout(() => this.toastr.clear(), 1500);
    });

  }

  public showCreateNote() {
    this.creatingNote = true;
    this.textAreaFormControl.setValue('');
  }

  public closeReplyingMessage() {
    this.replyingMessage = null;
  }

  public cancelCreateNote() {
    this.creatingNote = false;
  }

  @HostListener('document:keydown.escape', ['$event'])
  public handleKeyboardEvent(event: KeyboardEvent) {
    if (this.replyingMessage && event.key === 'Escape') {
      this.replyingMessage = null;
    }
  }

  public changeChatAlias() {

    const chatAlias = this.chatAliasFormControl.value;
    if (!chatAlias) this.chatAliasFormControl.setValue(this.chat.chatName);

    const setChatAliasRequest = new SetChatAliasRequest();
    setChatAliasRequest.chatAlias = chatAlias;
    setChatAliasRequest.chatId = this.chat.id;

    this.chat.chatAlias = chatAlias;

    this.chatService.setChatAlias(setChatAliasRequest).subscribe({
      next: () => {
        this.toastr.success('Alias cambiado correctamente', '¡Exito!');
      },
      error: () => {
        this.toastr.error('Error al cambiar el alias del chat');
      },
    });
  }

  public onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const files = clipboardData.files;
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.uppy.addFile({
          data: file,
          name: file.name,
          type: file.type,
          size: file.size,
        });
      }
    }
  }

  public shouldShowExpiredChat() {

    if (this.chat?.channel === ChannelType.WhatsAppWeb) return false;

    if (this.chat?.expired && !this.hideMessages) return true;

    return false;
  }

  summaryChat(): void {

    const chatId = this.chat.id;

    this.spinnerService.show('Resumiendo chat...');

    this.chatService.summaryChat(chatId).subscribe({
      next: (response: ChatSummaryInfo) => {
        const { summary, lastSummaryChatDate } = response;

        // Actualizar el chat con el nuevo resumen y fecha
        this.chat.chatSummary = summary;
        this.chat.lastSummaryChatDate = lastSummaryChatDate;

        // Establecer el valor del resumen en el formulario
        this.summaryChatFormControl.setValue(summary);

        // Mostrar notificación de éxito
        this.toastr.success('Resumen del chat generado correctamente', '¡Éxito!');
        this.spinnerService.hide();
      },
      error: (error) => {
        // Mostrar notificación de error y registrar el problema
        this.toastr.error('Error al generar el resumen del chat');
        console.error('Error al generar el resumen del chat:', error);
      },
    });
  }

  public filterByGroups(event?: Event) {
    
    if (this.selectedGroups === 'all') {
      this.filteredQuickReplies = this.quickReplies;
    } else {
      this.filteredQuickReplies = this.quickReplies.filter(qr =>
        qr.groups.some(group => group.name === this.selectedGroups)
      );
    }
  }

}
