import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserInfo } from '@app/models/account/user/user.info.dto';
import { AuthService } from '@app/services/auth/auth.service';
import { UserService } from '../../../../services/account/user.service';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Page } from '../../../../models/common/page';
import { UserSearchRequest } from '../../../../models/account/user/user-search-request.dto';
import { debounceTime, distinctUntilChanged, firstValueFrom, of, switchMap } from 'rxjs';
import { CheckUserNameExistenceRequest } from '../../../../models/account/user/check-user-name-existence-request.dto';
import { UserRoleType, UserRoleTypeMetadata } from '../../../../type/account/user-role.type';
import { EnumInfo } from '../../../../models/common/enum-info.dto';
import { getTypes } from '../../../../utils/enum-utils';
import { SaveUserRequest } from '../../../../models/account/user/save-user-request.dto';
import { ShopInfo } from '@app/models/account/shop/shop-info.dto';
import { GetSignedUrlRequest } from '@app/models/media/get-signed-url-request.dto';
import { MediaService } from '@app/services/media/media.service';
import { SpinnerService } from '../../../../services/data/spinner.service';
import { ChangePasswordRequest } from '../../../../models/account/user/change-password-request.dto';
import { ReassignUserChatsRequest } from '../../../../models/account/user/reassign-user-chats-request.dto';
import { UserStatusType } from '../../../../type/account/user-status.type';
import { ChangeUserStatusRequest } from '../../../../models/account/user/change-user-status-request.dto';

@Component({
  selector: 'app-users-config',
  templateUrl: './users-config.component.html',
  styleUrls: ['./users-config.component.css'],
})
export class UsersConfigComponent implements OnInit {

  @ViewChild('reassignUserChatsModal')
  private reassignUserChatsModal: TemplateRef<any>;
  public isDeletingUser: boolean = false;

  public UserRoleType = UserRoleType;
  public userRoleTypes: EnumInfo[] = getTypes(UserRoleType, UserRoleTypeMetadata);
  protected readonly UserStatusType = UserStatusType;

  public profilePictureFile: File;
  public defaultProfilePictureUrl: string = 'assets/img/placeholder/no_image_found-dark.webp';
  public profilePictureUrl: string = this.defaultProfilePictureUrl;
  public profilePictureChanged: boolean = false;
  public userForm: FormGroup;
  public changePasswordForm: FormGroup;
  public duplicatedName: boolean = false;
  public sameName: boolean = false;
  public savingUser: boolean = false;

  public firstLoad: boolean = true;
  public adminUsers: UserInfo[] = [];
  public users: UserInfo[] = [];
  public selectedUser: UserInfo;
  private searchRequest: UserSearchRequest;
  private defaultPageSize: number = -1;
  public availableUsers: UserInfo[] = [];
  public searchUserFormControl: FormControl = new FormControl('');

  public showChangePassInputs: boolean = false;
  public currentUser: UserInfo;

  // OffCanvas
  @ViewChild('saveUserOffCanvas')
  public saveUserOffCanvas: TemplateRef<any>;


  constructor(
    private userService: UserService,
    private mediaService: MediaService,
    private authService: AuthService,
    private toastr: ToastrService,
    private offCanvasService: NgbOffcanvas,
    private spinnerService: SpinnerService,
    private modalService: NgbModal,
  ) {
    this.currentUser = this.authService.getUser();
  }

  ngOnInit() {
    this.initializeForm();
    this.initializeUserSearchRequest();
    this.initializeUserNameValidator();
    this.initializeSearchUserFormControl();
    this.loadUsers();
  }

  initializeForm() {

    this.userForm = new FormGroup({
      role: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      username: new FormControl('', [Validators.required]),
    });
    this.changePasswordForm = new FormGroup({
      password: new FormControl(''),
      newPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
      confirmNewPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
    }, {
      validators: this.passwordMatchValidator,
    });

  }

  initializeUserSearchRequest() {
    this.searchRequest = new UserSearchRequest(
      this.currentUser.shop.id,
      this.defaultPageSize,
    );
  }

  initializeUserNameValidator() {
    this.userForm.get('username')
      .valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((query) => {
        return of(query.trim());
      }),
    ).subscribe((name: string) => {
      console.log(name);
      this.checkUserNameExistence(name);
    });
  }

  private initializeSearchUserFormControl() {
    this.searchUserFormControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((query) => {
          return of(query.trim());
        }),
      ).subscribe((searchTerm: string) => {
      this.searchRequest.searchTerm = searchTerm;
      this.loadUsers();
    });
  }

  private loadUsers() {

    this.spinnerService.show('Cargando usuarios...');

    this.userService.searchUsers(this.searchRequest).subscribe({
      next: (page: Page<UserInfo>) => {
        this.users = page.content;
        this.setAdminUsers();
        this.spinnerService.hide();
        if (this.firstLoad) this.firstLoad = false;
      },
      error: (error: any) => {
        this.spinnerService.hide();
        console.error('Error en la carga de usuarios:', error);
      },
      complete: () => {
      },
    });
  }

  private setAdminUsers() {
    this.adminUsers = this.users.filter((user: UserInfo) => user.role === UserRoleType.Admin);
  }

  isEditingUser: boolean = false;

  public openSaveUser(user?: UserInfo) {
    this.selectedUserRole = '';

    if (user) {
      this.selectedUser = user;
      this.isEditingUser = true;
      this.setUserFormValues(user);
    }
    const offCanvasRef = this.offCanvasService.open(
      this.saveUserOffCanvas,
      {
        position: 'end',
      },
    );

    offCanvasRef.hidden.subscribe(() => {
      this.selectedUser = undefined;
      this.showChangePassInputs = false;
      this.duplicatedName = false;
      this.sameName = false;
      this.profilePictureUrl = this.defaultProfilePictureUrl;
      this.initializeForm();
      this.initializeUserNameValidator();
    });
  }

  private setUserFormValues(user: UserInfo) {

    this.selectedUserRole = user.role;
    this.profilePictureFile = null;

    this.userForm.get('role').setValue(user.role);
    this.userForm.get('name').setValue(user.name);

    const status = user.status === UserStatusType.Active;
    this.userStatusControl.setValue(status);

    switch (user.role) {
      case UserRoleType.Admin:
        this.userForm.get('username').setValue(user.username);
        this.userForm.get('username').disable();
        this.userForm.get('role').disable();
        break;
      case UserRoleType.ChatAdvisor:
        this.userForm.get('username').setValue(user.username);
        this.userForm.get('username').disable();
        this.userForm.get('role').disable();
        break;
      default:
        break;
    }
  }

  public async saveUser() {

    const {
      role,
      name,
      username,
      email,
    } = this.userForm.getRawValue();

    if (this.userForm.valid) {

      this.savingUser = true;
      this.userForm.disable();

      const saveUserRequest = new SaveUserRequest();
      saveUserRequest.role = role;
      saveUserRequest.name = name.trim();
      saveUserRequest.username = username.trim();
      saveUserRequest.email = email?.trim();

      // Updating user
      if (this.selectedUser) {
        saveUserRequest.id = this.selectedUser.id;
        if (this.profilePictureChanged && this.profilePictureFile) {
          saveUserRequest.profilePictureUrl = await this.uploadProfilePicture(this.profilePictureFile, this.currentUser.shop);
        } else {
          saveUserRequest.profilePictureUrl = this.selectedUser.profilePictureUrl;
        }
        // Creating user
      } else {
        if (this.profilePictureFile) {
          saveUserRequest.profilePictureUrl = await this.uploadProfilePicture(this.profilePictureFile, this.currentUser.shop);
          ;
        }
      }

      this.userService.saveUser(saveUserRequest)
        .subscribe({
          next: (savedUser: UserInfo) => {
            setTimeout(() => {
              if (this.selectedUser) {
                const index = this.users.findIndex((user: UserInfo) => user.id === savedUser.id);
                this.users[index] = savedUser;
              } else {
                this.users.unshift(savedUser);
                this.setAdminUsers();
              }
              this.savingUser = false;
              this.offCanvasService.dismiss();
              this.toastr.success('Usuario creado con éxito');
            }, 1000);
          },
          error: (error: any) => {
            this.savingUser = false;
            const errorMessage = 'Error al crear el usuario';
            this.toastr.error(errorMessage);
            // this.errorCode = '';
            console.error(errorMessage, error);
          },
          complete: () => {

          },
        });
    }

  }

  public async uploadProfilePicture(file: File, shop: ShopInfo) {

    const prefix = `${shop.uuid}`;
    const getSignedUrlRequest = new GetSignedUrlRequest(prefix, file.name, file.type);
    const getSignedUrlResponse = await firstValueFrom(this.userService.generateProfilePictureSignedUrl(getSignedUrlRequest));
    const { publicUrl, signedUrl } = getSignedUrlResponse;

    await firstValueFrom(this.mediaService.uploadMedia(signedUrl, file));

    return publicUrl;
  }

  public openChooseProfilePicture() {
    const input = document.getElementById('profilePictureInput');
    input.click();
  }

  public onProfilePictureChosen(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.profilePictureFile = input.files[0];
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.profilePictureUrl = event.target.result;
      };
      reader.readAsDataURL(this.profilePictureFile);

      if (this.selectedUser) {
        this.profilePictureChanged = true;
      }
    }
  }

  private checkUserNameExistence(name: string) {

    if (!name || name.length === 0) {
      this.duplicatedName = false;
      return;
    }

    if (this.selectedUser && this.selectedUser.name === name) {
      this.duplicatedName = false;
      this.sameName = true;
      return;
    }

    this.sameName = false;

    const checkUserNameExistenceRequest = new CheckUserNameExistenceRequest(this.currentUser.shop.id, name);

    this.userService.checkUserNameExistence(checkUserNameExistenceRequest)
      .subscribe({
        next: (exists) => {
          this.duplicatedName = exists;
        },
        error: () => {
          this.duplicatedName = true;
        },
        complete: () => {
        },
      });
  }

  private passwordMatchValidator: ValidatorFn = (abstractControl: any): ValidationErrors | null => {
    const { controls } = abstractControl;
    const newPassword = controls.newPassword;
    const confirmNewPassword = controls.confirmNewPassword;

    if (newPassword.value !== confirmNewPassword.value) {
      confirmNewPassword.setErrors({ passwordMismatch: true });
    }

    return null;
  };

  public showChangePasswordInputs() {
    this.showChangePassInputs = true;
  }

  public changePassword() {

    if (this.changePasswordForm.valid) {
      const {
        password,
        newPassword,
        confirmNewPassword,
      } = this.changePasswordForm.controls;

      const changePasswordRequest: ChangePasswordRequest = {
        userId: this.selectedUser.id,
        newPassword: newPassword.value,
        confirmNewPassword: confirmNewPassword.value,
      };

      if (password.value) {
        changePasswordRequest.password = password.value;
      }

      this.userService.changePassword(changePasswordRequest).subscribe({
        next: () => {
          this.toastr.success('Contraseña cambiada con éxito');
          this.offCanvasService.dismiss();
        },
        error: (error: any) => {
          this.toastr.error(error.error.message);
        },
        complete: () => {
        },
      });
    }
  }

  public deleteUser(user: UserInfo) {
    if (!confirm('Es necesario reasignar los chats del agente antes de eliminarlo')) return;
    this.isDeletingUser = true;
    this.openReassignChatsModal(user);

  }

  public openReassignChatsModal(user: UserInfo) {
    this.selectedUser = user;
    this.availableUsers = this.users;
    const modalRef = this.modalService.open(this.reassignUserChatsModal, {
      centered: true,
    });

    this.availableUsers = this.users.filter(u => u.id !== user.id);

    modalRef.hidden.subscribe(() => {
      this.selectedUser = undefined;
      this.reassignToUserControl.setValue('');
      this.isDeletingUser = false;
    });
  }

  reassignToUserControl: FormControl = new FormControl('');

  public reassignUserChats(isDeletingUser?: boolean) {
    if (!this.reassignToUserControl.value) {
      this.toastr.error('Debe seleccionar un usuario', 'Error');
      return;
    }
    const reassignUserChatsRequest = new ReassignUserChatsRequest();
    reassignUserChatsRequest.fromUserId = this.selectedUser.id;
    reassignUserChatsRequest.toUserId = this.reassignToUserControl.value;
    this.userService.reassignChats(reassignUserChatsRequest).subscribe({
      next: () => {
        this.toastr.success('Chats reasignados con éxito', 'Éxito');
        this.modalService.dismissAll();
        if (isDeletingUser) {
          this.deletingUser(this.selectedUser.id);
        }

      },
      error: (error: any) => {
        this.toastr.error(error.error.message);
      },
      complete: () => {
      },
    });
  }


  private deletingUser(userId: number) {
    this.userService.deleteUser(userId)
      .subscribe({
        next: () => {
          const index = this.users.findIndex(u => u.id === userId);
          this.users.splice(index, 1);
          this.setAdminUsers();
          this.toastr.success('Usuario eliminado con éxito', 'Éxito');
        },
        error: (error: any) => {
          const errorMessage = 'Error al eliminar el usuario';
          this.toastr.error(errorMessage, 'Error');
          console.error(errorMessage, error);
        },
        complete: () => {

        },
      });
  }

  userStatusControl = new FormControl();

  public changeUserStatus() {
    const request = new ChangeUserStatusRequest();
    request.userId = this.selectedUser.id;
    request.status = this.userStatusControl.value;


    this.userService.changeUserStatus(request)
      .subscribe({
        next: (status) => {
          this.selectedUser.status = status;
        },
        error: (error: any) => {
          const status = this.selectedUser.status === UserStatusType.Active;
          this.userStatusControl.setValue(status);
          this.toastr.error(error.error.message);
        },
        complete: () => {
        },
      });

  }

  public getActivationLink() {
    this.userService.getActivationLink(this.selectedUser.id)
      .subscribe({
        next: (response: any) => {
          this.toastr.success('Enlace de activación copiado al portapapeles', 'Éxito');
          console.log(response);
          navigator.clipboard.writeText(response.activationLink);
        },
        error: (error: any) => {
          this.toastr.error(error.error.message);
        },
        complete: () => {
        },
      });
  }

  selectedUserRole: string = '';

  onUserRoleChange(event: any) {
    this.selectedUserRole = event.target.value;
  }

  isCurrentUser(user: UserInfo): boolean {
    return this.currentUser.id === user.id;
  }

  isAdmin(user: UserInfo): boolean {
    return user.role === UserRoleType.Admin;
  }

  canReassignChats(user: UserInfo): boolean {
    return this.users.length > 1 && user.role !== UserRoleType.SystemAdmin;
  }

  canDeleteUser(user: UserInfo): boolean {
    return user.role !== UserRoleType.Admin ||
      (user.role === UserRoleType.Admin && this.adminUsers.length > 1) &&
      this.currentUser.id !== user.id;
  }

  isChatAdvisor(currentUser: UserInfo, user: UserInfo): boolean {
    return currentUser.id === user.id && currentUser.role === UserRoleType.ChatAdvisor;
  }

  protected readonly UserRoleTypeMetadata = UserRoleTypeMetadata;
}
