import { AppGalleryMediaType } from '../../type/marketplace/app-gallery-media.type';

export class AppDetailGalleryRequest  {

  id: number;
  appId: number;
  deleted: boolean;
  fileKey: string;
  fileUrl: string;
  fileName: string;
  fileContentType: string;
  fileSize: number;
  type: AppGalleryMediaType;

}
