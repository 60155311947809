
export class AddWhatsAppMessageTemplateAttachmentRequest {

  shopId: number;
  messageTemplateId: string;
  messageTemplateName: string;
  fileUrl: string;
  fileKey: string;
  fileName: string;
  fileContentType: string;
  fileSize: number;

}