import { MessageType } from "@app/type/chat/message-content.type";
import { MessageStatus } from '@type/chat/message-status.type';
import { UserInfo } from "@models/account/user/user.info.dto";

export class MessageInfo {

  // Identificadores y metadatos
  id: number;
  uuid: string;
  chatId: number;
  identifier: string;
  mid: string;
  pageId: string;
  wamid: string;
  phoneNumber: string;
  messengerContactId: string;
  senderId: number;
  sentBy: string;

  // Contenido y tipo de mensaje
  content: string;
  type: MessageType;
  customerMessage: boolean;
  highlighted: boolean;
  status: MessageStatus;
  sentTime: any;
  sender: UserInfo;

  // Archivo adjunto
  fileAudioTranscription: string;
  fileContentType: string;
  fileImageDescription: string;
  fileKey: string;
  fileMediaCaption: string;
  fileName: string;
  fileSize: number;
  fileUrl: string;

  // Ubicación (si aplica)
  latitude: number;
  longitude: number;

  // Reacción a mensaje
  reactionEmoji: string;
  reactionWamid: string;

  // Contexto del mensaje (respuesta o reenviado)
  contextFrom: string;
  contextForwarded: boolean;
  contextId: string;
  contextMessage: MessageInfo;

  // Información de referencia (referidos)
  referralBody: string;
  referralHeadline: string;
  referralMediaType: string;
  referralSourceId: string;
  referralSourceType: string;
  referralSourceUrl: string;
  referralThumbnailUrl: string;
  referralVideoUrl: string;

  // Mensaje interactivo
  interactiveButtons: string;

  // Plantilla de mensaje
  messageTemplateBodyContent: string;
  messageTemplateButtonsContent: string;
  messageTemplateFooterContent: string;
  messageTemplateHeaderContent: string;
  messageTemplateId: string;
  messageTemplateName: string;

  // Información del remitente
  smart: boolean;

  // Angular (propiedades relacionadas con el frontend)
  formattedFileSize: string;
  formattedSentTime: string;
  hidden: boolean = true;
  ready: boolean = true;
  statusClass: string;
  showFileImageDescription: boolean = false;
}
