
<div class="card mb-3">
  <div class="card-body">
    <h4 class="card-title mb-3">
      Facebook Messenger
    </h4>
    <div class="row gx-3">
      <div class="col-12 col-sm-6 col-xl-12">
        <div class="row">
          <div class="col-md-8">
            <div class="form-group mb-3" *ngIf="messengerConfigs?.length > 0">
              <h5>Conectado a:</h5>
              <ul>
                <li *ngFor="let messengerConfig of messengerConfigs"
                    value="{{ messengerConfig.identifier }}">
                  {{ messengerConfig.name }}
                </li>
              </ul>
            </div>
            <button class="btn btn-outline-primary btn-md" (click)="disconnect()" *ngIf="messengerConfigs?.length > 0">
              <i class="fa-brands fa-facebook me-1"></i>
              Desconectar
            </button>
            <button class="btn btn-outline-primary btn-md" (click)="signIn()" *ngIf="messengerConfigs?.length === 0">
              <i class="fa-brands fa-facebook me-1"></i>
              Conectar
            </button>
          </div>

        </div>
        <div class="form-group mt-2 small text-muted">
          Todos los campos son requeridos para el funcionamiento de la aplicación, en
          caso de no contar con alguno
          de los campos contacte con un administrador.
        </div>
      </div>
    </div>
  </div>
</div>
