import { Component, Input, OnInit } from '@angular/core';
import { SmartAssistantOrchestratorService } from '@services/utilities/smart-assistant/smart-assistant-orchestrator.service';
import { AuthService } from '@services/auth/auth.service';
import { UserInfo } from '@models/account/user/user.info.dto';
import { SmartAssistantOrchestratorInfo } from '@models/utilities/smart-assistant/orchestrator/smart-assistant-orchestrator-info.dto';
import { SaveSmartAssistantOrchestratorContextRequest } from '@models/utilities/smart-assistant/orchestrator/context/save-smart-assistant-orchestrator-context-request.dto';
import { SmartAssistantService } from '@services/utilities/smart-assistant/smart-assistant.service';
import { SmartAssistantSearchRequest } from '@models/utilities/smart-assistant/smart-assistant-search-request.dto';
import { Page } from '@models/common/page';
import { SmartAssistantInfo } from '@models/utilities/smart-assistant/smart-assistant-info.dto';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SmartAssistantOrchestratorContextInfo } from '@models/utilities/smart-assistant/orchestrator/context/smart-assistant-orchestrator-context-info.dto';
import { SpinnerService } from '@services/data/spinner.service';
import { EventEmitterService, NotificationTopic } from '@services/data/event-emitter.service';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeSmartAssistantOrchestratorContextDefaultStatusRequest
} from '@models/utilities/smart-assistant/orchestrator/context/change-smart-assistant-orchestrator-context-default-status-request.dto';

@Component({
  selector: 'app-smart-assistant-orchestrator-cards',
  templateUrl: './smart-assistant-orchestrator-cards.component.html',
  styleUrls: ['./smart-assistant-orchestrator-cards.component.css']
})
export class SmartAssistantOrchestratorCardsComponent implements OnInit {

  @Input()
  public smartAssistantOrchestrator: SmartAssistantOrchestratorInfo;

  public createSmartAssistantOrchestratorContextFormGroup: FormGroup;
  public updateSmartAssistantOrchestratorContextFormGroup: FormGroup;

  // Lists
  public smartAssistants: SmartAssistantInfo[] = [];
  public availableCreateSmartAssistants: SmartAssistantInfo[] = [];
  public availableUpdateSmartAssistants: SmartAssistantInfo[] = [];

  public addingSmartAssistantOrchestratorContext: boolean = false;

  private user: UserInfo;

  private componentSubscriptions: Subscription[] = [];

  constructor(
    private readonly authService: AuthService,
    private readonly smartAssistantService: SmartAssistantService,
    private readonly smartAssistantOrchestratorService: SmartAssistantOrchestratorService,
    private readonly spinnerService: SpinnerService,
    private readonly eventEmitterService: EventEmitterService,
  ) {
    this.user = this.authService.getUser();
    this.initializeForms();
  }

  ngOnInit() {
    this.fillSmartAssistants();
    this.initializeSmartAssistantSelectedEvent();
  }

  private initializeSmartAssistantSelectedEvent() {

    const subscription = this.eventEmitterService.getEventEmitter(NotificationTopic.OpenSaveSmartAssistantOrchestrator).subscribe({
      next: (smartAssistantOrchestrator: SmartAssistantOrchestratorInfo) => {

        this.smartAssistantOrchestrator = smartAssistantOrchestrator;
        this.addingSmartAssistantOrchestratorContext = false;

        this.initializeForms();

        if (smartAssistantOrchestrator)
          this.adjustAvailableCreateSmartAssistants();
      },
    });

    this.componentSubscriptions.push(subscription);
  }

  private adjustAvailableCreateSmartAssistants() {
    this.availableCreateSmartAssistants = [...this.smartAssistants];
    for (const smartAssistantOrchestratorContext of this.smartAssistantOrchestrator.smartAssistantOrchestratorContexts) {
      const contextIndex = this.availableCreateSmartAssistants.findIndex(smartAssistant => smartAssistant.id === smartAssistantOrchestratorContext.smartAssistant.id)
      if (contextIndex >= 0) {
        this.availableCreateSmartAssistants.splice(contextIndex, 1);
      }
    }
  }

  private adjustAvailableUpdateSmartAssistants(smartAssistantOrchestratorContext: SmartAssistantOrchestratorContextInfo) {
    this.availableUpdateSmartAssistants = [...this.availableCreateSmartAssistants];
    this.availableUpdateSmartAssistants.push(smartAssistantOrchestratorContext.smartAssistant);
  }

  private initializeForms() {
    this.initializeCreateSmartAssistantOrchestratorContextForm();
    this.initializeUpdateSmartAssistantOrchestratorContextForm();
  }

  private initializeCreateSmartAssistantOrchestratorContextForm() {
    this.createSmartAssistantOrchestratorContextFormGroup = new FormGroup({
      context: new FormControl('', [Validators.required]),
      smartAssistantId: new FormControl('', [Validators.required]),
    });
  }

  private initializeUpdateSmartAssistantOrchestratorContextForm() {
    this.updateSmartAssistantOrchestratorContextFormGroup = new FormGroup({
      context: new FormControl('', [Validators.required]),
      smartAssistantId: new FormControl('', [Validators.required]),
    });
  }

  private fillSmartAssistants() {

    const smartAssistantSearch = new SmartAssistantSearchRequest(
      this.user.shop.id, -1,
    );

    this.smartAssistantService.searchSmartAssistants(smartAssistantSearch).subscribe({
      next: (page: Page<SmartAssistantInfo>) => {
        this.smartAssistants = page.content;
      },
      error: (error: any) => {
        console.error(error);
      }
    });
  }

  public showAddSmartAssistantOrchestratorContext() {
    this.addingSmartAssistantOrchestratorContext = true;
  }

  public cancelAddSmartAssistantOrchestratorContext() {
    this.addingSmartAssistantOrchestratorContext = false;
    this.initializeCreateSmartAssistantOrchestratorContextForm();
  }

  public showEditSmartAssistantOrchestratorContext(smartAssistantOrchestratorContext: SmartAssistantOrchestratorContextInfo) {

    this.updateSmartAssistantOrchestratorContextFormGroup.get('context').setValue(smartAssistantOrchestratorContext.context);
    this.updateSmartAssistantOrchestratorContextFormGroup.get('smartAssistantId').setValue(smartAssistantOrchestratorContext.smartAssistant.id);

    const currentSmartAssistantOrchestratorContext = this.smartAssistantOrchestrator.smartAssistantOrchestratorContexts.find(context => context.editing);
    if (currentSmartAssistantOrchestratorContext) currentSmartAssistantOrchestratorContext.editing = false;

    smartAssistantOrchestratorContext.editing = true;
    this.adjustAvailableUpdateSmartAssistants(smartAssistantOrchestratorContext);
  }

  public cancelEditSmartAssistantOrchestratorContext(smartAssistantOrchestratorContext: SmartAssistantOrchestratorContextInfo) {
    smartAssistantOrchestratorContext.editing = false;
    this.initializeUpdateSmartAssistantOrchestratorContextForm();
  }

  public saveSmartAssistantOrchestratorContext(smartAssistantOrchestratorContext?: SmartAssistantOrchestratorContextInfo) {

    let saveSmartAssistantOrchestratorContextRequest: SaveSmartAssistantOrchestratorContextRequest;

    if (!smartAssistantOrchestratorContext) {

      this.addingSmartAssistantOrchestratorContext = false;

      const { context, smartAssistantId } = this.createSmartAssistantOrchestratorContextFormGroup.value;

      saveSmartAssistantOrchestratorContextRequest = new SaveSmartAssistantOrchestratorContextRequest(
        this.smartAssistantOrchestrator.id, context, smartAssistantId
      );

    } else {

      const { context, smartAssistantId } = this.updateSmartAssistantOrchestratorContextFormGroup.value;

      saveSmartAssistantOrchestratorContextRequest = new SaveSmartAssistantOrchestratorContextRequest(
        this.smartAssistantOrchestrator.id, context, smartAssistantId
      );

      saveSmartAssistantOrchestratorContextRequest.id = smartAssistantOrchestratorContext.id;
    }

    this.spinnerService.show('Guardando contexto');

    this.smartAssistantOrchestratorService.saveSmartAssistantOrchestratorContext(saveSmartAssistantOrchestratorContextRequest).subscribe({
      next: (smartAssistantOrchestrator: SmartAssistantOrchestratorInfo) => {

        this.smartAssistantOrchestrator = smartAssistantOrchestrator;
        this.initializeForms();

        this.adjustAvailableCreateSmartAssistants();

        this.spinnerService.hide();
      },
      error: (error: any) => {
        console.error(error);
        this.spinnerService.hide();
      }
    });

  }

  public deleteSmartAssistantOrchestratorContext(contextId: number) {

    if (!confirm('¿Está seguro que desea eliminar este contexto?')) return;

    this.spinnerService.show('Eliminando contexto');

    this.smartAssistantOrchestratorService.deleteSmartAssistantOrchestratorContext(contextId).subscribe({
      next: (smartAssistantOrchestrator: SmartAssistantOrchestratorInfo) => {

        this.smartAssistantOrchestrator = smartAssistantOrchestrator;

        this.adjustAvailableCreateSmartAssistants();

        this.spinnerService.hide();
      },
      error: (error: any) => {
        console.error(error);
        this.spinnerService.hide();
      }
    });
  }

  public openTestSmartAssistant() {
    this.eventEmitterService.emit(NotificationTopic.OpenTestSmartAssistant, {
      smartAssistantOrchestrator: this.smartAssistantOrchestrator,
    });
  }

  public changeSmartAssistantOrchestratorContextDefaultStatus(smartAssistantOrchestratorContext: SmartAssistantOrchestratorContextInfo) {

    const defaultEnabled = !smartAssistantOrchestratorContext.defaultEnabled;

    const request = new ChangeSmartAssistantOrchestratorContextDefaultStatusRequest(
      this.smartAssistantOrchestrator.id, smartAssistantOrchestratorContext.id, defaultEnabled,
    );

    this.smartAssistantOrchestratorService.changeSmartAssistantOrchestratorContextDefaultStatus(request).subscribe({
      next: () => {
        if (defaultEnabled) {
          const context = this.smartAssistantOrchestrator.smartAssistantOrchestratorContexts.find(context => context.defaultEnabled);
          if (context) context.defaultEnabled = false;
        }
        smartAssistantOrchestratorContext.defaultEnabled = defaultEnabled;
      },
      error: (httpErrorResponse: HttpErrorResponse) => {
        const { error } = httpErrorResponse;
        console.log('error', error);
      },
    });

  }

}
