<div class="mb-9">
  <div class="row g-3">
    <div class="col-auto">
      <h2 class="mb-2">
        Etiquetas
      </h2>
      <p class="text-700 lh-sm mb-2">
        Administra las etiquetas que se pueden asignar a los chats.
      </p>
    </div>
  </div>
  <div class="mb-4">
    <div class="row g-3">
      <div class="col-auto">
        <div class="search-box">
          <form
            class="position-relative"
            data-bs-toggle="search"
            data-bs-display="static">
            <input
              class="form-control search-input search"
              type="search"
              placeholder="Buscar etiquetas"
              aria-label="Search"
              [formControl]="searchChatTagFormControl"/>
            <span class="fas fa-search search-box-icon"></span>
          </form>
        </div>
      </div>
      <div class="col-auto">
        <button
          class="btn btn-primary"
          (click)="openSaveChatTag()">
          <span class="fas fa-plus">
          </span>
          Crear etiqueta
        </button>
      </div>
    </div>
  </div>
  <div class="
    mx-n4 mx-lg-n6 
    px-4 px-lg-6
    py-4 
    bg-white 
    border-top 
    border-bottom 
    border-200 
    position-relative
    top-1"
    [class.transparent]="firstLoad"
    [class.not-transparent]="!firstLoad">
    <div class="table-responsive table-responsive-sm ms-n1 ps-1 scrollbar">
      <table
        class="table table-sm w-100"
        datatable
        [dtOptions]="dtc.options"
        [dtTrigger]="dtc.trigger"
      >
        <thead>
          <tr>
            <th
              class="white-space-nowrap align-middle ps-0 w-25"
              scope="col">
              Nombre
            </th>
            <th
              class="align-middle ps-3 w-25"
              scope="col">
              Previsualización
            </th>
            <th
              class="align-middle ps-3 w-50 text-center"
              scope="col">
              Opciones
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="chatTagsPage.content.length === 0">
            <td colspan="3">
              No hay etiquetas registradas.
            </td>
          </tr>
          <tr *ngFor="let chatTag of chatTagsPage.content">
            <td class="align-middle white-space-nowrap ps-0">
              <a
                class="fw-bold fs-0"
                role="button"
                [routerLink]=""
                (click)="openEditChatTag(chatTag)">
                {{ chatTag.name }}
              </a>
            </td>
            <td class="align-middle white-space-nowrap ps-3">
              <span
                role="button"
                class="badge badge-phoenix fs--2 me-1"
                [ngStyle]="{
                  'color': chatTag.color,
                  'border': '1px solid ' + chatTag.color,
                }">
                {{ chatTag.name }}
                <i class="fas fa-xmark tag-xmark-icon badge-icon"></i>
              </span>
            </td>
            <td class="align-middle white-space-nowrap ps-3 text-center">
              <div class="d-flex justify-content-center">
                <div (click)="showDeleteChatTagConfirmation(chatTag)">
                  <i class="fa fa-trash text-danger" role="button">
                  </i>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #saveChatTagOffCanvas let-offcanvas>
  <div class="offcanvas-header align-items-start border-bottom flex-column">
    <div class="pt-1 w-100 d-flex justify-content-between align-items-start">
      <div>
        <h4 class="mb-2 me-2 lh-sm">
          {{ selectedChatTag ? 'Editar etiqueta' : 'Personaliza una etiqueta nueva' }}
        </h4>
        <p class="mb-0 fs--1 text-info-500">
          Elige el nombre y el color de fondo que desees.
        </p>
      </div>
      <button
        class="btn p-1 fw-bolder"
        type="button"
        (click)="offcanvas.close()">
        <i class="fas fa-xmark fs-2"></i>
      </button>
    </div>
  </div>
  <div class="offcanvas-body">
    <form
      [formGroup]="chatTagsForm"
      (submit)="saveChatTag()">
      <div class="row g-3">
        <div class="col-sm-6 col-md-8">
          <div class="form-floating">
            <input
              class="form-control"
              id="name"
              type="text"
              placeholder="Nombre de la etiqueta"
              formControlName="name"
              [ngClass]="{
                'is-invalid': (chatTagsForm.get('name').touched && chatTagsForm.get('name').invalid) || duplicatedName,
                'is-valid': checkingDuplicatedName === 'after-checking' && !duplicatedName && chatTagsForm.get('name').valid
              }">
            <label for="name">
              Nombre de la etiqueta
            </label>
            <div class="valid-feedback" *ngIf="!sameName">
              Nombre disponible!
            </div>
            <div
              *ngIf="chatTagsForm.get('name').touched && chatTagsForm.get('name').invalid"
              class="invalid-feedback"
            >
              Ingrese un nombre para la etiqueta, por favor.
            </div>
            <div
              *ngIf="duplicatedName"
              class="invalid-feedback">
              La etiqueta ya existe, ingrese otro nombre.
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4">
          <input
            class="chat-tag-color-picker"
            type="color"
            name="color"
            formControlName="color"
          >
        </div>
        <div
          class="col-sm-12"
          *ngIf="chatTagsForm.valid">
          <span
            role="button"
            class="badge badge-phoenix fs--2 me-1"
            [ngStyle]="{
              'color': chatTagsForm.get('color').value,
              'border': '1px solid ' + chatTagsForm.get('color').value,
            }">
            {{ chatTagsForm.get('name').value }}
            <i class="fas fa-xmark tag-xmark-icon badge-icon"></i>
          </span>
        </div>
        <div class="col-12">
          <div class="row g-3 justify-content-end">
            <div class="col-auto">
              <button
                type="button"
                class="btn btn-phoenix-primary"
                (click)="offcanvas.close()">
                Cancelar
              </button>
            </div>
            <div class="col-auto">
              <button
                class="btn btn-primary"
                [disabled]="chatTagsForm.invalid">
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>