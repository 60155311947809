<nav
  class="navbar navbar-top fixed-top navbar-expand"
  id="navbarCombo"
  data-navbar-top="combo"
  data-move-target="#navbarVerticalNav">
  <div
    class="collapse navbar-collapse justify-content-between"
    id="navbarTopCollapse">
    <div class="navbar-logo">
      <button
        class="btn navbar-toggler navbar-toggler-humburger-icon hover-bg-transparent"
        type="button"
        (click)="toggleSidebar()">
        <span class="navbar-toggle-icon">
          <span class="toggle-line"></span>
        </span>
      </button>
      <a class="navbar-brand me-1 me-sm-3" [routerLink]="['/app/chat']">
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center py-0">
            <img
              [src]="currentTheme === 'dark' ? './assets/img/logo/logo2.png' : './assets/img/logo/logo.png'"
              alt="ivana-logo"
              width="120"
            />
          </div>
        </div>
      </a>
    </div>
    <ng-container *ngTemplateOutlet="middleMenu"></ng-container>
    <ul class="navbar-nav navbar-nav-icons flex-row">
      <li class="nav-item me-6 d-none d-sm-none d-md-flex d-lg-flex">
        <ng-container *ngTemplateOutlet="subscriptionInformation"></ng-container>
      </li>
      <li class="nav-item">
        <ng-container *ngTemplateOutlet="themeStyle"></ng-container>
      </li>
      <li class="nav-item dropdown">
        <ng-container *ngTemplateOutlet="userAuthInformation"></ng-container>
      </li>
    </ul>
  </div>
</nav>

<!-- Middle Menu -->
<ng-template #middleMenu>
  <ng-container *ngIf="false">
    <ul class="navbar-nav navbar-nav-top" *ngIf="false"
        data-dropdown-on-hover="data-dropdown-on-hover">
      <li class="nav-item dropdown">
        <a
          class="nav-link dropdown-toggle lh-1 cursor-pointer"
          role="button"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside">
          <span class="uil fs-0 me-2 uil-chart-pie"></span>
          Home
        </a>
        <ul class="dropdown-menu navbar-dropdown-caret">
          <li>
            <a class="dropdown-item" [routerLink]="['/app/chat']">
              <div class="dropdown-item-wrapper">
                <span class="me-2 uil" data-feather="shopping-cart"></span>
                Ecommerce
              </div>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item cursor-pointer">
              <div class="dropdown-item-wrapper">
                <span class="me-2 uil" data-feather="clipboard"></span>
                Project management
              </div>
            </a>
          </li>
          <li>
            <a class="dropdown-item cursor-pointer">
              <div class="dropdown-item-wrapper">
                <span class="me-2 uil" data-feather="phone"></span>
                CRM
              </div>
            </a>
          </li>
          <li>
            <a class="dropdown-item cursor-pointer">
              <div class="dropdown-item-wrapper">
                <span class="me-2 uil" data-feather="share-2"></span>
                Social feed
              </div>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </ng-container>
</ng-template>

<!-- Subscription information -->
<ng-template #subscriptionInformation>

  <ng-container *ngIf="shop.trialPeriod && !shop.alreadySubscribed">
    <div class="d-flex flex-column">
      <span class="text-primary fs--1 me-3">
        <i class="fa fa-clock me-1"></i>
        <b>En periodo de prueba</b>
      </span>
      <span class="text-success text-end fs--1 me-3">
        {{ shop.remainingTrialDays }} {{ shop.remainingTrialDays > 1 ? 'días restantes' : 'día restante' }}
      </span>
    </div>
    <button
      class="btn btn-outline-success btn-sm rounded-pill py-1"
      data-bs-toggle="tooltip"
      data-bs-placement="bottom"
      [routerLink]="['/app/config/subscription']"
      title="Inicie su suscripción ahora">
      <span>
        Obtener
      </span>
    </button>
  </ng-container>

  <ng-container *ngIf="!shop.trialPeriod && !shop.alreadySubscribed">
    <div class="d-flex flex-column">
      <span role="button" class="text-danger fs--1 me-3">
        <i class="fa fa-clock me-1"></i>
        <b>Su periodo de prueba ha vencido</b>
      </span>
    </div>
    <button
      class="btn btn-outline-success btn-sm rounded-pill py-1"
      data-bs-toggle="tooltip"
      data-bs-placement="bottom"
      title="Inicie su suscripción ahora"
      [routerLink]="['/app/config/subscription']">
      <span>
        Obtener
      </span>
    </button>
  </ng-container>

  <ng-container *ngIf="!shop.trialPeriod && shop.alreadySubscribed">

  </ng-container>
</ng-template>

<!-- Theme control -->
<ng-template #themeStyle>
  <div class="theme-control-toggle px-2">
    <input (click)="toggleTheme()"
           class="form-check-input ms-0 theme-control-toggle-input"
           type="checkbox"
           data-theme-control="phoenixTheme"
           value="dark"
           id="themeControlToggle"
    />
    <label
      class="mb-0 theme-control-toggle-label theme-control-toggle-light"
      for="themeControlToggle"
      data-bs-toggle="tooltip"
      data-bs-placement="bottom"
      title="Cambiar tema">
      <i class="fa fa-moon"></i>
    </label>
    <label
      class="mb-0 theme-control-toggle-label theme-control-toggle-dark"
      for="themeControlToggle"
      data-bs-toggle="tooltip"
      data-bs-placement="bottom"
      title="Cambiar tema">
      <i class="fa fa-sun"></i>
    </label>
  </div>
</ng-template>

<!-- Authenticated user -->
<ng-template #userAuthInformation>
  <a
    class="nav-link lh-1 pe-0 cursor-pointer"
    id="navbarDropdownUser"
    role="button"
    data-bs-toggle="dropdown"
    data-bs-auto-close="outside">
    <div class="avatar avatar-l ">
      <img
        class="rounded-circle "
        [src]="profilePictureUrl"
        alt=""
      />
    </div>
  </a>
  <div
    class="dropdown-menu dropdown-menu-end navbar-dropdown-caret py-0 dropdown-profile shadow border border-300">
    <div class="card position-relative border-0">
      <div class="card-body p-0">
        <div class="text-center pt-4 pb-3">
          <div class="avatar avatar-xl ">
            <img
              class="rounded-circle "
              [src]="profilePictureUrl"
              alt=""
            />
          </div>
          <h6 class="mt-2 text-black">
            {{ user.name }}
          </h6>
          <h6 class="mt-2 text-primary">
            {{ user.shop.name }}
          </h6>
          <h6 class="mt-2 text-primary-300">
            {{ user.shop.id }}
          </h6>
        </div>
      </div>
      <div class="overflow-auto scrollbar">
        <ul class="nav d-flex flex-column mb-2 pb-1">
          <li class="nav-item">
            <a class="nav-link px-3 cursor-pointer" (click)="goToLink('app/config/users')">
              <span class="me-2 text-900" data-feather="user"></span>
              <span>Perfil</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link px-3 cursor-pointer" 
            href="#nv-settings"
            role="button"
            data-bs-toggle="collapse"
            aria-expanded="false"
            aria-controls="nv-settings ">
              <span class="me-2 text-900" data-feather="settings"></span>
              Configuración
            </a>
          </li>
        </ul>
      </div>
        <!-- menu pa desplgar -->
        <div class="collapse" id="nv-settings">
          <ul class="nav flex-column">
            <li class="nav-item">
              <a role="button" class="nav-link py-2" (click)="goToLink('app/config/integrations')">
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon whatsapp-icon me-2">
                    <span class="fab fa-connectdevelop"></span>
                  </span>
                  <span class="nav-link-text ps-0">
                    Integraciones
                  </span>
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a role="button" class="nav-link py-2"  (click)="goToLink('app/config/users')">
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon users-icon me-2">
                    <i class="fa-duotone fa-user-group"></i>
                  </span>
                  <span class="nav-link-text ps-0">
                    Usuarios
                  </span>
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a role="button" class="nav-link py-2" (click)="goToLink('app/config/users-groups')">
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon teams-icon me-2">
                    <i class="fa-duotone fa-users"></i>
                  </span>
                  <span class="nav-link-text ps-0">
                    Teams
                  </span>
                </div>
              </a>
            </li>
            <li class="nav-item mb-3">
              <a role="button" class="nav-link py-2" (click)="goToLink('app/config/subscription')">
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon text-primary me-2">
                    <span class="fa-duotone fa-credit-card"></span>
                  </span>
                  <span class="nav-link-text ps-0">
                    Suscripción
                  </span>
                </div>
              </a>
            </li>
          </ul>
        </div>

      <div class="card-footer border-top">
        <div class="px-1">
          <a class="btn btn-phoenix-secondary d-flex flex-center w-100"
            (click)="signOut()">
            <span class="me-2" data-feather="log-out"></span>
            Cerrar sesión
          </a>
        </div>
      </div>
    </div>
  </div>
  
</ng-template>