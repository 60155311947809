import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SmartAssistantInfo } from '@models/utilities/smart-assistant/smart-assistant-info.dto';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageType } from '@type/chat/message-content.type';
import { EventEmitterService, NotificationTopic } from '@services/data/event-emitter.service';
import { Subscription } from 'rxjs';
import {
  SmartAssistantOrchestratorInfo
} from "@models/utilities/smart-assistant/orchestrator/smart-assistant-orchestrator-info.dto";

@Component({
  selector: 'app-smart-assistants',
  templateUrl: './smart-assistants.component.html',
  styleUrls: ['./smart-assistants.component.css'],
})
export class SmartAssistantsComponent implements OnInit, AfterViewInit, OnDestroy {

  // Enums
  public MessageType = MessageType;

  public smartAssistant: SmartAssistantInfo;
  public smartAssistantOrchestrator: SmartAssistantOrchestratorInfo;
  public testSmartAssistant: SmartAssistantInfo;
  public testSmartAssistantOrchestrator: SmartAssistantOrchestratorInfo;

  public firstLoad: boolean = true;

  // OffCanvas
  @ViewChild('saveSmartAssistantOffCanvas')
  public saveSmartAssistantOffCanvas: TemplateRef<any>;

  // Modals
  @ViewChild('testSmartAssistantModal')
  private testSmartAssistantModal: TemplateRef<any>;
  private componentSubscriptions: Subscription[] = [];

  constructor(
    private modalService: NgbModal,
    private eventEmitterService: EventEmitterService,
  ) {

  }

  ngOnDestroy(): void {
    this.componentSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  ngOnInit() {
    this.initializeSmartAssistantSelectedEvent();
    this.initializeSmartAssistantOrchestratorSelectedEvent();
    this.initializeTestSmartAssistantEvent();
  }

  ngAfterViewInit() {

  }

  private initializeSmartAssistantSelectedEvent() {

    const subscription = this.eventEmitterService.getEventEmitter(NotificationTopic.OpenSaveSmartAssistant).subscribe({
      next: (smartAssistant: SmartAssistantInfo) => {
        this.smartAssistant = smartAssistant;
      },
    });

    this.componentSubscriptions.push(subscription);
  }

  private initializeSmartAssistantOrchestratorSelectedEvent() {

    const subscription = this.eventEmitterService.getEventEmitter(NotificationTopic.OpenSaveSmartAssistantOrchestrator).subscribe({
      next: (smartAssistantOrchestrator: SmartAssistantOrchestratorInfo) => {
        this.smartAssistantOrchestrator = smartAssistantOrchestrator;
      },
    });

    this.componentSubscriptions.push(subscription);
  }

  private initializeTestSmartAssistantEvent() {

    const subscription = this.eventEmitterService.getEventEmitter(NotificationTopic.OpenTestSmartAssistant).subscribe({
      next: (data: any) => {
        this.openTestSmartAssistant(data);
      },
    });

    this.componentSubscriptions.push(subscription);
  }

  public openTestSmartAssistant(data: any) {

    this.testSmartAssistant = data.smartAssistant;
    this.testSmartAssistantOrchestrator = data.smartAssistantOrchestrator;

    const modalRef = this.modalService.open(this.testSmartAssistantModal, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    modalRef.hidden.subscribe(() => {
      // this.selectedSmartAssistant = undefined;
    });
  }

}
