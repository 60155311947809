<ng-container *ngIf="testMessage?.type === SmartAssistantTopicItemActionType.Message">
  <app-chat-message
    [chat]="chat"
    [message]="testMessage?.message"
    *ngIf="testMessage?.message?.type !== MessageType.Reaction">
  </app-chat-message>
</ng-container>

<ng-container *ngIf="testMessage?.type === SmartAssistantTopicItemActionType.ChatTag">
  <div class="d-flex justify-content-start">
    <div class="avatar avatar-m me-3 flex-shrink-0">
      <div class="avatar-name rounded-circle" [ngStyle]="{'color': chat.color}">
        <span>{{ chat.chatName[0] }}</span>
      </div>
    </div>
    <div class="card border border-danger" style="min-width: 200px;">
      <div class="card-body" style="padding: 16px;">
        <h4 class="card-title text-danger" style="margin-bottom: 5px;">
          <i class="fa fa-tags"></i>
          Etiquetar
        </h4>
        <div class="mb-1">
        <span
          *ngFor="let chatTag of testMessage.chatTags"
          role="button"
          class="badge badge-phoenix fs--2 me-1"
          [ngStyle]="{
            'color': chatTag.color,
            'border': '1px solid ' + chatTag.color,
          }">
          {{ chatTag.name }}
        </span>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="testMessage?.type === SmartAssistantTopicItemActionType.HummanAssitantAttention">
  <div class="d-flex justify-content-start">
    <div class="avatar avatar-m me-3 flex-shrink-0">
      <div class="avatar-name rounded-circle" [ngStyle]="{'color': chat.color}">
        <span>{{ chat.chatName[0] }}</span>
      </div>
    </div>
    <div class="card border border-primary" style="min-width: 200px;">
      <div class="card-body" style="padding: 16px;">
        <h4 class="card-title text-dark mb-0">
          <i class="fa fa-person-running"></i>
          Se solicita asistencia personalizada
        </h4>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="testMessage?.type === SmartAssistantTopicItemActionType.HttpCall">
  <div class="d-flex justify-content-start">
    <div class="avatar avatar-m me-3 flex-shrink-0">
      <div class="avatar-name rounded-circle" [ngStyle]="{'color': chat.color}">
        <span>{{ chat.chatName[0] }}</span>
      </div>
    </div>
    <div class="card border border-primary" style="min-width: 200px;">
      <div class="card-body" style="padding: 16px;">
        <h4 class="card-title text-dark mb-0">
          <i class="fa fa-person-running"></i>
          Se realizo una llamada http al endpoint {{ testMessage?.httpCallUrl }}
        </h4>
      </div>
    </div>
  </div>
</ng-container>