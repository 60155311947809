<div class="collapse navbar-collapse" id="navbarVerticalCollapse"
     #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
  <div class="navbar-vertical-content">
    <ul class="navbar-nav flex-column" id="navbarVerticalNav">
      <li class="nav-item">
        <p class="navbar-vertical-label">
          Menú
        </p>
        <ng-container *ngIf="currentUser.role != UserRoleType.SystemAdmin">
          <div class="nav-item-wrapper">
            <a
              class="nav-link label-1"
              [routerLink]="['']"
              (click)="goToLink('app/chat')"
              role="button">
              <div class="d-flex align-items-center">
              <span class="nav-link-icon text-primary">
                <i class="fa-duotone fa-messages"></i>
              </span>
                <span class="nav-link-text-wrapper">
                <span class="nav-link-text">
                  Chat
                </span>
              </span>
              </div>
            </a>
          </div>
          <div class="nav-item-wrapper">
            <a
              class="nav-link label-1"
              [routerLink]="['']"
              (click)="goToLink('app/chat-monitor')"
              role="button">
              <div class="d-flex align-items-center">
                <span class="nav-link-icon text-primary">
                  <i class="fa-duotone fa-desktop"></i>
                </span>
                <span class="nav-link-text-wrapper">
                  <span class="nav-link-text">
                    Monitor
                  </span>
                </span>
              </div>
            </a>
          </div>
          <div class="nav-item-wrapper">
            <a
              class="nav-link label-1"
              [routerLink]="['']"
              (click)="goToLink('app/smart-assistants')"
              role="button">
              <div class="d-flex align-items-center">
              <span class="nav-link-icon text-warning">
                <i class="fa fa-bolt"></i>
              </span>
                <span class="nav-link-text-wrapper">
                <span class="nav-link-text">
                  Asistentes
                </span>
              </span>
              </div>
            </a>
          </div>

          <div *ngIf="shouldShowWhatsAppTemplates()" class="nav-item-wrapper">
            <a
              class="nav-link dropdown-indicator label-1"
              href="#nv-replies"
              role="button"
              data-bs-toggle="collapse"
              aria-expanded="false"
              aria-controls="nv-replies">
              <div class="d-flex align-items-center">
                <div class="dropdown-indicator-icon">
                  <i class="fas fa-caret-right"></i>
                </div>
                <span class="nav-link-icon">
                <i class="fa-duotone fa-reply-all text-primary"></i>
              </span>
                <span class="nav-link-text">
                Respuestas
              </span>
              </div>
            </a>
            <div class="parent-wrapper label-1">
              <ul
                class="nav collapse parent"
                data-bs-parent="#navbarVerticalCollapse"
                id="nv-replies">
                <li class="collapsed-nav-item-title d-none">
                  Respuestas
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link py-2"
                    [routerLink]="['']"
                    (click)="goToLink('app/quick-replies')">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-icon users-icon me-2">
                        <i class="fa-duotone fa-reply-all text-primary"></i>
                      </span>
                      <span class="nav-link-text ps-0">
                        Respuestas rápidas
                      </span>
                    </div>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link py-2"
                    [routerLink]="['']"
                    (click)="goToLink('app/quick-replies-groups')">
                    <div class="d-flex align-items-center">
                    <span class="nav-link-icon whatsapp-icon me-2">
                      <span class=" fa fa-solid fa-layer-group text-primary"></span>
                    </span>
                      <span class="nav-link-text ps-0">
                      Grupos de respuestas
                    </span>
                    </div>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link py-2"
                    [routerLink]="['']"
                    (click)="goToLink('app/message-templates')">
                    <div class="d-flex align-items-center">
                    <span class="nav-link-icon whatsapp-icon me-2">
                      <span class="fab fa-whatsapp"></span>
                    </span>
                      <span class="nav-link-text ps-0">
                      Plantillas de mensajes
                    </span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div *ngIf="!shouldShowWhatsAppTemplates()" class="nav-item-wrapper">
            <a
              class="nav-link label-1"
              [routerLink]="['']"
              (click)="goToLink('app/quick-replies')"
              role="button">
              <div class="d-flex align-items-center">
              <span class="nav-link-icon">
                <i class="fa-duotone fa-reply-all text-primary"></i>
              </span>
                <span class="nav-link-text-wrapper">
                <span class="nav-link-text">
                  Respuestas rápidas
                </span>
              </span>
              </div>
            </a>
          </div>

          <div class="nav-item-wrapper">
            <a
              class="nav-link label-1"
              [routerLink]="['']"
              (click)="goToLink('app/board')"
              role="button">
              <div class="d-flex align-items-center">
                <span class="nav-link-icon text-indigo">
                  <i class="fa fa-chart-kanban"></i>
                </span>
                <span class="nav-link-text-wrapper">
                  <span class="nav-link-text">
                    Tableros
                  </span>
                </span>
              </div>
            </a>
          </div>
          <div class="nav-item-wrapper">
            <a
              class="nav-link label-1"
              [routerLink]="['']"
              (click)="goToLink('app/funnel')"
              role="button">
              <div class="d-flex align-items-center">
                <span class="nav-link-icon text-success">
                  <i class="fa-duotone fa-filters"></i>
                </span>
                <span class="nav-link-text-wrapper">
                  <span class="nav-link-text">
                    Embudos
                  </span>
                </span>
              </div>
            </a>
          </div>
          <div class="nav-item-wrapper">
            <a
              class="nav-link label-1"
              [routerLink]="['']"
              (click)="goToLink('app/broadcast')"
              role="button">
              <div class="d-flex align-items-center">
              <span class="nav-link-icon broadcast-icon">
                <i class="fa-duotone fa-bullhorn"></i>
              </span>
                <span class="nav-link-text-wrapper">
                <span class="nav-link-text">
                  Broadcast
                </span>
              </span>
              </div>
            </a>
          </div>
          <div class="nav-item-wrapper">
            <a
              class="nav-link label-1"
              [routerLink]="['']"
              (click)="goToLink('app/tags')"
              role="button">
              <div class="d-flex align-items-center">
              <span class="nav-link-icon">
                <i class="fa-duotone fa-tags text-danger"></i>
              </span>
                <span class="nav-link-text-wrapper">
                <span class="nav-link-text">
                  Etiquetas
                </span>
              </span>
              </div>
            </a>
          </div>
          <div class="nav-item-wrapper">
            <a
              class="nav-link label-1"
              [routerLink]="['']"
              (click)="goToLink('app/contacts')"
              role="button">
              <div class="d-flex align-items-center">
              <span class="nav-link-icon text-primary">
                <i class="fa-duotone fa-address-card"></i>
              </span>
                <span class="nav-link-text-wrapper">
                <span class="nav-link-text">
                  Contactos
                </span>
              </span>
              </div>
            </a>
          </div>
          <div class="nav-item-wrapper">
            <a
              class="nav-link dropdown-indicator label-1"
              *ngIf="currentUser.role != UserRoleType.ChatAdvisor"
              href="#nv-settings"
              role="button"
              data-bs-toggle="collapse"
              aria-expanded="false"
              aria-controls="nv-settings">
              <div class="d-flex align-items-center">
                <div class="dropdown-indicator-icon">
                  <i class="fas fa-caret-right"></i>
                </div>
                <span class="nav-link-icon">
                <i class="fa-duotone fa-gear"></i>
              </span>
                <span class="nav-link-text">
                Configuración
              </span>
              </div>
            </a>
            <div class="parent-wrapper label-1">
              <ul
                class="nav collapse parent"
                data-bs-parent="#navbarVerticalCollapse"
                id="nv-settings">
                <li class="collapsed-nav-item-title d-none">
                  Configuración
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link py-2"
                    [routerLink]="['']"
                    (click)="goToLink('app/config/integrations')">
                    <div class="d-flex align-items-center">
                    <span class="nav-link-icon whatsapp-icon me-2">
                      <span class="fab fa-connectdevelop"></span>
                    </span>
                      <span class="nav-link-text ps-0">
                      Integraciones
                    </span>
                    </div>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link py-2"
                    [routerLink]="['']"
                    (click)="goToLink('app/config/users')">
                    <div class="d-flex align-items-center">
                    <span class="nav-link-icon users-icon me-2">
                      <i class="fa-duotone fa-user-group"></i>
                    </span>
                      <span class="nav-link-text ps-0">
                      Usuarios
                    </span>
                    </div>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link py-2"
                    [routerLink]="['']"
                    (click)="goToLink('app/config/users-groups')">
                    <div class="d-flex align-items-center">
                    <span class="nav-link-icon teams-icon me-2">
                     <i class="fa-duotone fa-users"></i>
                    </span>
                      <span class="nav-link-text ps-0">
                      Teams
                    </span>
                    </div>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link py-2"
                    [routerLink]="['']"
                    (click)="goToLink('app/config/subscription')">
                    <div class="d-flex align-items-center">
                    <span class="nav-link-icon text-primary me-2">
                      <span class="fa-duotone fa-credit-card"></span>
                    </span>
                      <span class="nav-link-text ps-0">
                      Suscripción
                    </span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="nav-item-wrapper">
            <a
              class="nav-link label-1"
              [routerLink]="['']"
              (click)="goToLink('app/marketplace')"
              role="button">
              <div class="d-flex align-items-center">
              <span class="nav-link-icon text-primary">
                <i class="fa-duotone fa-grid-2-plus"></i>
              </span>
                <span class="nav-link-text-wrapper">
                <span class="nav-link-text">
                  Mercado de apps
                </span>
              </span>
              </div>
            </a>
          </div>
        </ng-container>
        <ng-container *ngIf="currentUser.role == UserRoleType.SystemAdmin">
          <div class="nav-item-wrapper">
            <a
              class="nav-link label-1"
              [routerLink]="['']"
              (click)="goToLink('app/admin/marketplace')"
              role="button">
              <div class="d-flex align-items-center">
              <span class="nav-link-icon text-primary">
                <i class="fa-duotone fa-gears"></i>
              </span>
                <span class="nav-link-text-wrapper">
                <span class="nav-link-text">
                  Administración de Marketplace
                </span>
              </span>
              </div>
            </a>
          </div>
        </ng-container>
      </li>
    </ul>
  </div>
</div>
<div class="navbar-vertical-footer">
  <button #navbarVerticalToggle
          class="btn navbar-vertical-toggle border-0 fw-semi-bold w-100 white-space-nowrap d-flex align-items-center">
    <span class="uil uil-left-arrow-to-left fs-0"></span>
    <span class="uil uil-arrow-from-right fs-0"></span>
    <span class="navbar-vertical-footer-text ms-2">
      Minimizar
    </span>
  </button>
</div>