
export class SaveChatNoteRequest {

  chatId: number;
  note: string;

  constructor(chatId: number, note: string) {
    this.chatId = chatId;
    this.note = note;
  }

}
