import { Component, Input } from '@angular/core';
import { AppPluginInfo } from '../../../../../models/marketplace/app-plugin-info.dto';

@Component({
  selector: 'app-app-info',
  templateUrl: './app-info.component.html',
  styleUrls: ['./app-info.component.css']
})
export class AppInfoComponent {

  @Input()
  public appPluginInfo: AppPluginInfo;

  @Input()
  public stars = null;

  constructor() { }
}
