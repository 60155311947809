import { AfterViewChecked, AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AppDetailGalleryService } from '../../../../../services/marketplace/app-detail-gallery.service';
import { AppDetailGalleryInfo } from '../../../../../models/marketplace/app-detail-gallery-info.dto';
import lgZoom from 'lightgallery/plugins/zoom';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
import { LightGallery } from 'lightgallery/lightgallery';

@Component({
  selector: 'app-app-gallery',
  templateUrl: './app-gallery.component.html',
  styleUrls: ['./app-gallery.component.css'],
})
export class AppGalleryComponent implements OnInit, AfterViewInit, AfterViewChecked {

  @Input()
  public appId: number;
  public galleryInfo: AppDetailGalleryInfo[] = [];

  public dataImages: string[] = [];


  constructor(
    private appDetailGalleryService: AppDetailGalleryService,
  ) {
  }

  ngOnInit() {
    this.getGalleryImages();
  }

  ngAfterViewInit() {
  }

  private lightGallery: LightGallery;

  ngAfterViewChecked(): void {
    this.lightGallery.refresh();
  }

  getGalleryImages() {
    this.appDetailGalleryService.getAppGalleryImages(this.appId).subscribe({
      next: (gallery) => {
        this.galleryInfo = gallery;
        this.getDataImages(this.galleryInfo);
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  getDataImages(galleryInfo: AppDetailGalleryInfo[]) {
    this.dataImages = galleryInfo.map((gallery) => {
      return gallery.mediaUrl;
    });
  }

  images: string[] = [
    'https://via.placeholder.com/600x400',
    'https://via.placeholder.com/600x400/ff0000',
    'https://via.placeholder.com/600x400/00ff00',
    'https://via.placeholder.com/600x400/0000ff',
    'https://via.placeholder.com/600x400/ffff00',
    'https://via.placeholder.com/600x400/00ffff',
  ];
  selectedImage: string = this.images[0];

  onSelectImage(image: string): void {
    this.selectedImage = image;
  }

  settings = {
    counter: false,
    plugins: [lgZoom],
  };
  onBeforeSlide = (detail: BeforeSlideDetail): void => {
    const { index, prevIndex } = detail;
    console.log(index, prevIndex);
  };
  onInit = (detail): void => {
    this.lightGallery = detail.instance;
  };
}
