<div class="mb-9">
  <div class="row g-3">
    <div class="col-auto">
      <h2 class="mb-2">
        Contactos
      </h2>
      <p class="text-700 lh-sm mb-2">
        Administra y filtra tus contactos.
      </p>
    </div>
  </div>
  <div class="mb-2">
    <div class="row g-3">
      <div class="col-auto">
        <div class="search-box">
          <form
            class="position-relative"
            data-bs-toggle="search"
            data-bs-display="static">
            <input
              class="form-control search-input search"
              type="search"
              placeholder="Buscar contactos"
              [formControl]="searchChatsFormControl" />
            <span class="fas fa-search search-box-icon"></span>
          </form>
        </div>
      </div>
      <div class="col-auto">
        <button
          class="btn btn-primary"
          (click)="openSaveChat()">
          <span class="fas fa-plus">
          </span>
          Crear contacto
        </button>
      </div>
      <div class="col-auto">
        <ul class="nav nav-links mb-lg-2 mx-n3">
          <li class="nav-item">
            <a class="nav-link"
               role="button"
               href="javascript:void(0);"
               [class.active]="currentTab === ChatTabType.All"
               (click)="changeTab(ChatTabType.All)">
        <span>
          Todos
        </span>
              <span class="text-700 fw-semi-bold">
          ({{ allChatsCount }})
        </span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link"
               role="button"
               href="javascript:void(0);"
               [class.active]="currentTab === ChatTabType.Archived"
               (click)="changeTab(ChatTabType.Archived)">
        <span>
          Archivados
        </span>
              <span class="text-700 fw-semi-bold">
          ({{ archivedChatsCount }})
        </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="
    mx-n4 mx-lg-n6
    px-4 px-lg-6
    py-2
    bg-white
    border-top
    border-200
    position-relative
    top-1">
    <ul class="nav">
      <li class="nav-item">
        <a
          class="nav-link px-0 me-2"
          (click)="openAssignChatAdvisor()"
          data-bs-toggle="tooltip" data-bs-placement="top" title="Asignar / desasignar asesor"
          [routerLink]="" role="button">
          <i class="fa fa-user"></i>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link px-0 mx-2"
          data-bs-toggle="tooltip" data-bs-placement="top" title="Archivar"
          (click)="archiveChats(true)"
          [routerLink]="" role="button">
          <i class="fa fa-box-archive text-warning"></i>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link px-0 mx-2"
          data-bs-toggle="tooltip" data-bs-placement="top" title="Desarchivar"
          (click)="archiveChats(false)"
          [routerLink]="" role="button">
          <i class="fa fa-folder-open"></i>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link px-0 mx-2"
          data-bs-toggle="tooltip" data-bs-placement="top" title="Etiquetar"
          (click)="openTagChats()"
          [routerLink]="" role="button">
          <i class="fa fa-tags text-danger"></i>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link px-0 mx-2"
          data-bs-toggle="tooltip" data-bs-placement="top" title="Importar contactos"
          (click)="openImportContacts()"
          [routerLink]="" role="button">
          <i class="fa fa-file-import"></i>
        </a>
      </li>
      <li class="nav-item ms-auto">
        <input
          placeholder="Filtrar por rango de fechas"
          class="form-control"
          type="text"
          [formatDate]="formatDate"
          (flatpickrClose)="filterByDate($event)"
          mwlFlatpickr
          [(ngModel)]="rangeValue"
          [altInput]="true"
          [convertModelValue]="true"
          [maxDate]="currentDate"
          mode="range">
      </li>
      <li class="nav-item ms-2">
        <button
          class="btn btn-secondary dropdown-toggle square-btn-md"
          data-bs-toggle="dropdown">
          <i class="fa fa-gear"></i>
        </button>
        <div class="dropdown-menu">
          <a class="dropdown-item"
             (click)="changeDateRangeFieldType(ChatSearchDangeRangeFieldType.CreationDate)"
             [class.active]="chatSearchRequest.dateRangeFieldType === ChatSearchDangeRangeFieldType.CreationDate"
             href="javascript:void(0);">
            <i class="fa fa-calendar me-2"></i>
            Usar fecha de inicio de chat
          </a>
          <a class="dropdown-item"
             (click)="changeDateRangeFieldType(ChatSearchDangeRangeFieldType.LastMessageDate)"
             [class.active]="chatSearchRequest.dateRangeFieldType === ChatSearchDangeRangeFieldType.LastMessageDate"
             href="javascript:void(0);">
            <i class="fa fa-clock me-2"></i>
            Usar fecha de último mensaje
          </a>
        </div>
      </li>
      <li class="nav-item ms-2">
        <button class="btn btn-secondary square-btn-md" (click)="clearDateRange()">
          <i class="fa fa-broom"></i>
        </button>
      </li>
    </ul>
  </div>
  <div class="
    mx-n4 mx-lg-n6 
    px-4 px-lg-6
    py-4 
    bg-white 
    border-top 
    border-bottom 
    border-200 
    position-relative
    top-1">
    <div class="table-responsive ms-n1 ps-1 scrollbar main-panel-messages">
      <ul class="nav flex-column nav-pills nav-pills-soft"
          infiniteScroll
          [infiniteScrollUpDistance]="1.5"
          [infiniteScrollThrottle]="100"
          [infiniteScrollContainer]="'.main-panel-messages'"
          [fromRoot]="true"
          (scrolled)="onScroll()">
        <table class="table">
          <thead style="position: sticky; top:0">
            <tr>
              <th
                class="align-middle ps-0 text-center header"
                scope="col">
                <input class="form-check-input" type="checkbox"
                       [checked]="allChatsChecked"
                       (click)="toggleAllChatsSelection($event)">
              </th>
              <th
                style="width: 22%;"
                class="align-middle header"
                scope="col">
                Nombre
              </th>
              <th
                class="align-middle text-center header"
                scope="col">
                Chat
              </th>
              <th
                style="width: 5%;"
                class="align-middle text-center header"
                scope="col">
                Canal
              </th>
              <th
                style="width: 10%;"
                class="align-middle text-center header"
                scope="col">
                Celular
              </th>
              <th
                style="width: 10%;"
                class="align-middle text-center header"
                scope="col">
                Asesor
              </th>
              <th
                style="width: 20%;"
                class="align-middle text-center header"
                scope="col">
                Fecha de creación
              </th>
              <th
                style="width: 20%;"
                class="align-middle text-center header"
                scope="col">
                Último mensaje
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="!loadingChats && chats.length === 0">
              <td colspan="8">
                No hay contactos registradas.
              </td>
            </tr>
            <tr *ngFor="let chat of chats">
              <td class="text-center">
                <input class="form-check-input"
                       type="checkbox"
                       [checked]="chat.checked"
                       (change)="toggleChatSelection($event, chat)">
              </td>
              <td class="text-start">
                <a
                  class="fw-bold fs-0"
                  role="button"
                  [routerLink]=""
                  (click)="openSaveChat(chat)">
                  {{ chat.chatName }}
                </a>
                <i class="fa fa-box-archive ms-2" *ngIf="chat.archived"></i>
              </td>
              <td class="text-center">
                <a
                  class="fw-bold fs-0"
                  role="button"
                  [routerLink]=""
                  (click)="openChat(chat)">
                  Abrir chat
                </a>
              </td>
              <td class="text-center">
                <i class="fab fa-whatsapp text-success" *ngIf="chat.channel === ChannelType.WhatsApp"></i>
                <i class="fab fa-facebook-messenger" *ngIf="chat.channel === ChannelType.Messenger"></i>
                <i class="fab fa-instagram" *ngIf="chat.channel === ChannelType.Instagram"></i>
              </td>
              <td class="text-center">
                {{ chat.chatPhoneNumber }}
              </td>
              <td class="text-center" *ngIf="chat.userId">
                {{ chat.user.name }}
              </td>
              <td class="text-center text-warning" *ngIf="!chat.userId">
                Sin asesor
              </td>
              <td class="text-center">
                {{ chat.createdAt | date: 'dd/MM/yyyy hh:mm a' }}
              </td>
              <td class="text-center" *ngIf="chat.lastMessageSentTime">
                {{ chat.lastMessageSentTime | date: 'dd/MM/yyyy hh:mm a' }}
              </td>
              <td class="text-center text-warning" *ngIf="!chat.lastMessageSentTime">
                Sin mensajes
              </td>
            </tr>
          </tbody>
        </table>
      </ul>
    </div>
    <div class="d-flex align-items-center justify-content-center pt-2" *ngIf="loadingChats">
      <div class="spinner-border text-primary"></div>
      <h4 class="text-600 ms-2 pt-1">
        Cargando contactos...
      </h4>
    </div>
  </div>
</div>

<ng-template #saveChatOffCanvas let-offcanvas>
  <div class="offcanvas-header align-items-start border-bottom flex-column">
    <div class="pt-1 w-100 d-flex justify-content-between align-items-start">
      <div>
        <h4 class="mb-2 me-2 lh-sm">
          {{
            selectedChat ?
              'Editar contacto' :
              'Crear un contacto nuevo'
          }}
        </h4>
        <p class="mb-0 fs--1 text-info-500">
          {{
            selectedChat ?
              'Edita la información de tu contacto.' :
              'Crea un nuevo contacto para administrar tus chats y encontrarlos más fácilmente.'
          }}
        </p>
      </div>
      <button
        class="btn p-1 fw-bolder"
        type="button"
        (click)="offcanvas.close()">
        <i class="fas fa-xmark fs-2"></i>
      </button>
    </div>
  </div>
  <div class="offcanvas-body scrollbar">
    <form
      [formGroup]="chatForm"
      (submit)="saveChat()">
      <!-- Tag de información del chat -->
      <div class="row mb-2">
        <h5 class="text-primary">
          Información del chat
        </h5>
      </div>
      <!-- Nombre del chat -->
      <div class="row mb-3">
        <div class="col-12">
          <div class="form-floating">
            <input
              class="form-control"
              id="chatName"
              type="text"
              placeholder="Nombre del chat"
              formControlName="chatName"
              [ngClass]="{
                'is-invalid': chatForm.get('chatName').touched && chatForm.get('chatName').invalid,
                'is-valid': chatForm.get('chatName').touched && chatForm.get('chatName').valid
              }">
            <label for="chatName">
              Nombre del chat
            </label>
            <div
              *ngIf="chatForm.get('chatName').touched && chatForm.get('chatName').invalid"
              class="invalid-feedback"
            >
              Ingrese un nombre para el chat, por favor.
            </div>
          </div>
        </div>
      </div>
      <!-- Numero del chat -->
      <div class="row mb-3">
        <div class="col-12">
          <div class="form-floating">
            <input
              class="form-control"
              id="chatPhoneNumber"
              type="text"
              placeholder="Número del contacto"
              formControlName="chatPhoneNumber"
              [ngClass]="{
                'is-invalid': chatForm.get('chatPhoneNumber').touched && chatForm.get('chatPhoneNumber').invalid,
                'is-valid': chatForm.get('chatPhoneNumber').valid
              }">
            <label for="chatPhoneNumber">
              Número de teléfono
            </label>
            <div
              *ngIf="chatForm.get('chatPhoneNumber').touched && chatForm.get('chatPhoneNumber').invalid"
              class="invalid-feedback"
            >
              Ingrese un número de teléfono para el contacto, por favor.
            </div>
          </div>
        </div>
      </div>
      <!-- Tag de información del contacto -->
      <div class="row mb-2">
        <h5 class="text-primary">
          Información del contacto
        </h5>
      </div>
      <div class="scrollbar contacts-extra-info mb-3">
        <!-- Teléfono extra -->
        <div class="row mb-3">
          <div class="col-12">
            <div class="form-floating">
              <input
                class="form-control"
                id="contactExtraPhoneNumber"
                type="text"
                placeholder="Número de teléfono adicional"
                formControlName="contactExtraPhoneNumber"
                [ngClass]="{
                  'is-invalid': chatForm.get('contactExtraPhoneNumber').touched && chatForm.get('contactExtraPhoneNumber').invalid,
                  'is-valid': chatForm.get('contactExtraPhoneNumber').touched && chatForm.get('contactExtraPhoneNumber').valid
                }">
              <label for="contactExtraPhoneNumber">Número de teléfono adicional</label>
              <div
                *ngIf="chatForm.get('contactExtraPhoneNumber').touched && chatForm.get('contactExtraPhoneNumber').invalid"
                class="invalid-feedback"
              >
                Ingrese un número de teléfono adicional válido, por favor.
              </div>
            </div>
          </div>
        </div>
        <!-- Email -->
        <div class="row mb-3">
          <div class="col-12">
            <div class="form-floating">
              <input
                class="form-control"
                id="contactEmail"
                type="text"
                placeholder="Correo electrónico de contacto"
                formControlName="contactEmail"
                [ngClass]="{
                  'is-invalid': chatForm.get('contactEmail').touched && chatForm.get('contactEmail').invalid,
                  'is-valid': chatForm.get('contactEmail').touched && chatForm.get('contactEmail').valid
                }">
              <label for="contactEmail">Correo electrónico de contacto</label>
              <div
                *ngIf="chatForm.get('contactEmail').touched && chatForm.get('contactEmail').invalid"
                class="invalid-feedback"
              >
                Ingrese un correo electrónico válido, por favor.
              </div>
            </div>
          </div>
        </div>
        <!-- Calle -->
        <div class="row mb-3">
          <div class="col-12">
            <div class="form-floating">
              <input
                class="form-control"
                id="contactStreet"
                type="text"
                placeholder="Calle"
                formControlName="contactStreet"
                [ngClass]="{
                  'is-invalid': chatForm.get('contactStreet').touched && chatForm.get('contactStreet').invalid,
                  'is-valid': chatForm.get('contactStreet').touched && chatForm.get('contactStreet').valid
                }">
              <label for="contactStreet">Calle</label>
              <div
                *ngIf="chatForm.get('contactStreet').touched && chatForm.get('contactStreet').invalid"
                class="invalid-feedback"
              >
                Ingrese una calle válida, por favor.
              </div>
            </div>
          </div>
        </div>
        <!-- Colonia -->
        <div class="row mb-3">
          <div class="col-12">
            <div class="form-floating">
              <input
                class="form-control"
                id="contactColony"
                type="text"
                placeholder="Colonia"
                formControlName="contactColony"
                [ngClass]="{
                  'is-invalid': chatForm.get('contactColony').touched && chatForm.get('contactColony').invalid,
                  'is-valid': chatForm.get('contactColony').touched && chatForm.get('contactColony').valid
                }">
              <label for="contactColony">Colonia</label>
              <div
                *ngIf="chatForm.get('contactColony').touched && chatForm.get('contactColony').invalid"
                class="invalid-feedback"
              >
                Ingrese una colonia válida, por favor.
              </div>
            </div>
          </div>
        </div>
        <!-- País -->
        <div class="row mb-3">
          <div class="col-12">
            <div class="form-floating">
              <input
                class="form-control"
                id="contactCountry"
                type="text"
                placeholder="País"
                formControlName="contactCountry"
                [ngClass]="{
                'is-invalid': chatForm.get('contactCountry').touched && chatForm.get('contactCountry').invalid,
                'is-valid': chatForm.get('contactCountry').touched && chatForm.get('contactCountry').valid
              }">
              <label for="contactCountry">País</label>
              <div
                *ngIf="chatForm.get('contactCountry').touched && chatForm.get('contactCountry').invalid"
                class="invalid-feedback"
              >
                Ingrese un país válido, por favor.
              </div>
            </div>
          </div>
        </div>
        <!-- Estado -->
        <div class="row mb-3">
          <div class="col-12">
            <div class="form-floating">
              <input
                class="form-control"
                id="contactState"
                type="text"
                placeholder="Estado"
                formControlName="contactState"
                [ngClass]="{
                  'is-invalid': chatForm.get('contactState').touched && chatForm.get('contactState').invalid,
                  'is-valid': chatForm.get('contactState').touched && chatForm.get('contactState').valid
                }">
              <label for="contactState">Estado</label>
              <div
                *ngIf="chatForm.get('contactState').touched && chatForm.get('contactState').invalid"
                class="invalid-feedback"
              >
                Ingrese un estado válido, por favor.
              </div>
            </div>
          </div>
        </div>
        <!-- Ciudad -->
        <div class="row mb-3">
          <div class="col-12">
            <div class="form-floating">
              <input
                class="form-control"
                id="contactCity"
                type="text"
                placeholder="Ciudad"
                formControlName="contactCity"
                [ngClass]="{
                  'is-invalid': chatForm.get('contactCity').touched && chatForm.get('contactCity').invalid,
                  'is-valid': chatForm.get('contactCity').touched && chatForm.get('contactCity').valid
                }">
              <label for="contactCity">Ciudad</label>
              <div
                *ngIf="chatForm.get('contactCity').touched && chatForm.get('contactCity').invalid"
                class="invalid-feedback"
              >
                Ingrese una ciudad válida, por favor.
              </div>
            </div>
          </div>
        </div>
        <!-- Referencia de dirección -->
        <div class="row">
          <div class="col-12">
            <div class="form-floating">
              <input
                class="form-control"
                id="contactAddressReference"
                type="text"
                placeholder="Referencia de dirección"
                formControlName="contactAddressReference"
                [ngClass]="{
                  'is-invalid': chatForm.get('contactAddressReference').touched && chatForm.get('contactAddressReference').invalid,
                  'is-valid': chatForm.get('contactAddressReference').touched && chatForm.get('contactAddressReference').valid
                }">
              <label for="contactAddressReference">Referencia de dirección</label>
              <div
                *ngIf="chatForm.get('contactAddressReference').touched && chatForm.get('contactAddressReference').invalid"
                class="invalid-feedback"
              >
                Ingrese una referencia de dirección válida, por favor.
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Botones -->
      <div class="row g-3 justify-content-end">
        <div class="col-auto">
          <button
            type="button"
            class="btn btn-phoenix-primary"
            (click)="offcanvas.close()">
            Cancelar
          </button>
        </div>
        <div class="col-auto">
          <button
            class="btn btn-primary"
            [disabled]="chatForm.invalid">
            Guardar
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #assignChatAdvisorOffCanvas let-offcanvas>
  <div class="offcanvas-header align-items-start border-bottom flex-column">
    <div class="pt-1 w-100 d-flex justify-content-between align-items-start">
      <div>
        <h4 class="mb-2 me-2 lh-sm">
          Asigna tus contactos a un asesor
        </h4>
        <p class="mb-0 fs--1 text-info">
          Atiende a tus contactos de manera personalizada y organizada entre tus colaboradores.
        </p>
      </div>
      <button
        class="btn p-1 fw-bolder"
        type="button"
        (click)="offcanvas.close()">
        <i class="fas fa-xmark fs-2"></i>
      </button>
    </div>
  </div>
  <div class="offcanvas-body">
    <form
      [formGroup]="advisorForm"
      (submit)="assignChatAdvisor()">
      <div class="row g-3">
        <div class="col-12">
          <div class="mb-3">
            <label class="form-label" for="advisorSelect">
              Asesor
            </label>
            <select id="advisorSelect" class="form-select"
                    formControlName="user"
                    [ngClass]="{
                      'is-invalid': advisorForm.get('user').touched && advisorForm.get('user').invalid,
                      'is-valid': advisorForm.get('user').touched && advisorForm.get('user').valid
                    }">
              <option selected="" [value]="''">
                Sin asesor
              </option>
              <option [value]="user.id" *ngFor="let user of users">
                {{ user.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12">
          <div class="row g-3 justify-content-end">
            <div class="col-auto">
              <button
                type="button"
                class="btn btn-phoenix-primary"
                (click)="offcanvas.close()">
                Cancelar
              </button>
            </div>
            <div class="col-auto">
              <button
                class="btn btn-primary"
                [disabled]="advisorForm.invalid">
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #tagChatsOffCanvas let-offcanvas>
  <div class="offcanvas-header align-items-start border-bottom flex-column">
    <div class="pt-1 w-100 d-flex justify-content-between align-items-start">
      <div>
        <h4 class="mb-2 me-2 lh-sm">
          Asigna tus contactos a etiquetas
        </h4>
        <p class="mb-0 fs--1 text-info">
          Organiza tus contactos por etiquetas para una mejor administración.
        </p>
      </div>
      <button
        class="btn p-1 fw-bolder"
        type="button"
        (click)="offcanvas.close()">
        <i class="fas fa-xmark fs-2"></i>
      </button>
    </div>
  </div>
  <div class="offcanvas-body">
    <div class="mb-3">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <h4 class="mb-0">
          Etiquetas
        </h4>
      </div>
      <div class="row g-2">
        <div class="col-12">
          <div class="form-floating">
            <input
              id="tagChatsFormControl"
              type="text"
              class="form-control"
              [formControl]="tagsFormControl"
              placeholder="Buscar etiquetas"
              [editable]="false"
              #instance="ngbTypeahead"
              [ngbTypeahead]="searchChatTags"
              [resultFormatter]="formatResult"
              [inputFormatter]="formatInput"
              (focus)="focus$.next($any($event).target.value)"
              (click)="click$.next($any($event).target.value)"
            >
            <label for="tagChatsFormControl">
              {{ chatTagsAvailable.length > 0 ? 'Buscar etiquetas' : 'No hay más etiquetas' }}
            </label>
          </div>
        </div>
        <div class="col-12">
          <div class="mb-1">
            <span
              *ngFor="let chatTag of chatTagsChosen"
              (click)="removeChatTag(chatTag)"
              role="button"
              class="badge badge-phoenix fs--2 me-1"
              [ngStyle]="{
                'color': chatTag.color,
                'border': '1px solid ' + chatTag.color,
              }">
              {{ chatTag.name }}
              <i class="fas fa-xmark tag-xmark-icon badge-icon"></i>
            </span>
          </div>
        </div>
        <div class="col-12">
          <div class="row g-3 justify-content-end">
            <div class="col-auto">
              <button
                type="button"
                class="btn btn-phoenix-primary"
                (click)="offcanvas.close()">
                Cancelar
              </button>
            </div>
            <div class="col-auto">
              <button
                (click)="assignChatTags()"
                class="btn btn-primary"
                [disabled]="chatTagsChosen.length === 0">
                Etiquetar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #importContactsOffCanvas let-offcanvas>
  <div class="offcanvas-header align-items-start border-bottom flex-column">
    <div class="pt-1 w-100 d-flex justify-content-between align-items-start">
      <div>
        <h4 class="mb-2 me-2 lh-sm">
          Importa tus contactos
        </h4>
        <p class="mb-0 fs--1 text-info">
          Importa tus contactos facilmente y etiquetalos para una mejor administración.
        </p>
      </div>
      <button
        class="btn p-1 fw-bolder"
        type="button"
        (click)="offcanvas.close()">
        <i class="fas fa-xmark fs-2"></i>
      </button>
    </div>
  </div>
  <div class="offcanvas-body">
    <app-import-contacts></app-import-contacts>
  </div>
</ng-template>

<ng-template #chatModal>
  <div class="modal-body">
    <app-chat
      [chat]="selectedChat"
      [showSideChatList]="false"
      [fromContactsComponent]="true">
    </app-chat>
  </div>
</ng-template>