import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private router: Router,
  ) {
  }

  public requestPermission() {
    if ('Notification' in window) {
      Notification.requestPermission().then(permission => {
        console.log('Notification permission:', permission);
      });
    }
  }

  public showNotification(title: string, url: string, options?: NotificationOptions): void {
    if ('Notification' in window && Notification.permission === 'granted') {
      const notification = new Notification(title, options);
      if (url) {
        notification.onclick = () => {
          this.router.navigateByUrl(url).then(() => {});
        };
      }
    }
  }

}