<div *ngIf="message" class="mb-1 p-3 rounded-2 position-relative" [ngClass]="{
  'received-message-content border': message.customerMessage,
  'sent-message-content bg-primary light text-white': !message.customerMessage,
}">
  <app-chat-context-message
    *ngIf="message.contextMessage"
    [message]="message.contextMessage"
    [chat]="chat">
  </app-chat-context-message>
  <p class="fw-bold mb-1">Ubicación:</p>
  <a target="_blank" [href]="'https://www.google.com/maps?q=' + message.latitude + ',' + message.longitude + '&z=15'">
    {{ 'https://www.google.com/maps?q=' + message.latitude + ',' + message.longitude + '&z=15' }}
  </a>
  <div class="scrollbar mt-3 pe-2" *ngIf="message.fileMediaCaption">
    <p class="mb-0">
      {{ message.fileMediaCaption }}
    </p>
  </div>
  <span class="reaction-emoji" *ngIf="message.reactionEmoji">
    {{ message.reactionEmoji }}
  </span>
</div>