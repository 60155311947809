import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { Page } from '@app/models/common/page';
import { ChatTagSearchRequest } from '@app/models/chat/tag/chat-tag-search-request.dto';
import { ChatTagInfo } from '@app/models/chat/tag/chat-tag-info.dto';
import { ChatTagRequest } from '@app/models/chat/tag/chat-tag-request.dto';
import { CheckChatTagNameExistenceRequest } from '@app/models/chat/tag/check-chat-tag-name-existence-request.dto';

@Injectable({
  providedIn: 'root'
})
export class ChatTagService {

  private apiUrl: string = `${environment.apiUrl}/chat-tags`;

  constructor(
    private http: HttpClient,
  ) {

  }

  checkChatTagNameExistence(checkChatTagNameExistence: CheckChatTagNameExistenceRequest): Observable<Page<ChatTagInfo>> {
    const endpoint = `${this.apiUrl}/check-chat-tag-name-existence`;
    return this.http.post<any>(endpoint, checkChatTagNameExistence);
  }

  searchChatTags(chatTagSearch: ChatTagSearchRequest): Observable<Page<ChatTagInfo>> {
    const endpoint = `${this.apiUrl}/search-chat-tags`;
    return this.http.post<Page<ChatTagInfo>>(endpoint, chatTagSearch);
  }

  saveChatTag(chatTag: ChatTagRequest): Observable<ChatTagInfo> {
    const endpoint = `${this.apiUrl}/save-chat-tag`;
    return this.http.post<ChatTagInfo>(endpoint, chatTag);
  }

  deleteChatTag(id: number) {
    const endpoint = `${this.apiUrl}/delete-chat-tag/${id}`;
    return this.http.delete<any>(endpoint);
  }

}