import moment from "moment/moment";

export const dateFormatter = (value: any): string => {
  const date = moment(value);
  if (!date.isValid()) {
    return '';
  }
  // return date.locale('es').format('DD [de] MMMM [de] YYYY');
  return date.format('DD/MM/YYYY');
};

export const dateTimeFormatter = (value: any): string => {
  const date = moment(value);
  if (!date.isValid()) {
    return '';
  }
  return date.format('DD/MM/YYYY HH:mm:ss');
};

export const timeFormatter = (value: any): string => {
  const date = moment(value);
  if (!date.isValid()) {
    return '';
  }
  return date.format('HH:mm');
};

export const formatTimestamp = (timestamp: number): string => {
  const now = new Date();
  const messageDate = new Date(timestamp);

  const sameDay = now.toDateString() === messageDate.toDateString();
  const yesterday = new Date(now);
  yesterday.setDate(now.getDate() - 1);

  if (sameDay) {
    return messageDate.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit', hour12: false });
  } else if (yesterday.toDateString() === messageDate.toDateString()) {
    return 'Ayer';
  } else if (now.getTime() - messageDate.getTime() < 7 * 24 * 60 * 60 * 1000) {
    return messageDate.toLocaleDateString('es-ES', { weekday: 'long' });
  } else {
    return messageDate.toLocaleDateString('es-ES', { day: 'numeric', month: 'numeric', year: 'numeric' });
  }
}
