<div class="row">

  <div class="col-xl-3 col-lg-4 col-sm-3 mb-3"
       [hidden]="smartAssistant !== undefined || smartAssistantOrchestrator !== undefined">
    <app-smart-assistants-list></app-smart-assistants-list>
    <app-smart-assistant-orchestrators-list></app-smart-assistant-orchestrators-list>
  </div>

  <app-smart-assistant-info
    [hidden]="smartAssistant === undefined"
    class="col-xl-3 col-lg-4 col-sm-3 mb-3">
  </app-smart-assistant-info>
  <app-smart-assistant-orchestrator-info
    [hidden]="smartAssistantOrchestrator === undefined"
    class="col-xl-3 col-lg-4 col-sm-3 mb-3">
  </app-smart-assistant-orchestrator-info>


  <app-empty-smart-assistant-board
    *ngIf="!smartAssistant && !smartAssistantOrchestrator"
    class="col-xl-9 col-lg-8 col-sm-9">
  </app-empty-smart-assistant-board>

  <app-smart-assistants-board
    [hidden]="!smartAssistant || smartAssistant?.id === undefined"
    class="col-xl-9 col-lg-8 col-sm-9">
  </app-smart-assistants-board>

  <app-smart-assistant-orchestrator-cards
    [smartAssistantOrchestrator]="smartAssistantOrchestrator"
    [hidden]="!smartAssistantOrchestrator || smartAssistantOrchestrator?.id === undefined"
    class="col-xl-9 col-lg-8 col-sm-9">
  </app-smart-assistant-orchestrator-cards>

</div>

<ng-template #testSmartAssistantModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">
      {{ testSmartAssistant?.name || testSmartAssistantOrchestrator?.name }}
    </h5>
    <button
      type="button"
      class="btn-close me-2"
      (click)="modal.close()">
    </button>
  </div>
  <div class="modal-body">
    <app-test-smart-assistant
      [smartAssistant]="testSmartAssistant"
      [smartAssistantOrchestrator]="smartAssistantOrchestrator">
    </app-test-smart-assistant>
  </div>
</ng-template>