
import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ChatInfo } from '@app/models/chat/chat/chat-info.dto';
import { MessageInfo } from '@app/models/chat/message/message-info.dto';
import { EventEmitterService } from '@app/services/data/event-emitter.service';
import { MessageType } from '@app/type/chat/message-content.type';
import { MessageStatus } from "../../../../type/chat/message-status.type";

@Component({
  selector: 'app-chat-context-message',
  templateUrl: './context-message.component.html',
  styleUrls: ['./context-message.component.css']
})
export class ContextMessageComponent implements OnInit, AfterViewInit, OnDestroy {

  MessageType = MessageType;
  MessageStatus = MessageStatus;
  Object = Object;

  @Input()
  public message: MessageInfo;

  @Input() 
  public chat: ChatInfo;

  private componentSubscriptions: Subscription[] = [];

  constructor(
    private eventEmitterService: EventEmitterService,
  ) {

  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    
  }

  ngOnDestroy() {
    this.componentSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
