import { WhatsAppMessageTemplateAttachmentInfo } from "./internal/whatsapp-message-template-attachment-info.dto";
import { WhatsAppMessageTemplateComponentInfo } from "./whatsapp-message-template-component-info.dto";

export class WhatsAppMessageTemplateInfo {

  id: string;
  name: string;
  components: WhatsAppMessageTemplateComponentInfo[];
  language: string;
  status: string;
  category: string;
  attachments: WhatsAppMessageTemplateAttachmentInfo[];

  // Angular
  validParameters: boolean = false;
  headerComponent: WhatsAppMessageTemplateComponentInfo;
  headerComponent1: WhatsAppMessageTemplateComponentInfo;
  bodyComponent: WhatsAppMessageTemplateComponentInfo;
  footerComponent: WhatsAppMessageTemplateComponentInfo;
  buttonsComponent: WhatsAppMessageTemplateComponentInfo;
  languageValue: any;
  opened: boolean = false;
  parametersCount: number;
  file: File;

}
