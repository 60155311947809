import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { SubscriptionTypeInfo } from "../../models/subscription/subscription-type-info.dto";
import { GetStripeCheckoutSessionRequest } from '../../models/subscription/get-stripe-checkout-session-request.dto';
import { UpdateSubscriptionTypeRequest } from '../../models/subscription/update-subscription-type-request.dto';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  private apiUrl: string = `${environment.apiUrl}/subscriptions`;

  constructor(
    private http: HttpClient,
  ) {

  }

  public getSubscriptionTypes(): Observable<SubscriptionTypeInfo[]> {
    const endpoint = `${this.apiUrl}/get-subscription-types`;
    return this.http.get<SubscriptionTypeInfo[]>(endpoint);
  }

  public getStripeCheckoutSession(getStripeCheckoutSessionRequest: GetStripeCheckoutSessionRequest): Observable<any> {
    const endpoint = `${this.apiUrl}/get-stripe-checkout-session`;
    return this.http.post<any>(endpoint, getStripeCheckoutSessionRequest);
  }

  public getStripeBillingPortalSession(shopId: number): Observable<any> {
    const endpoint = `${this.apiUrl}/get-stripe-billing-portal-session/${shopId}`;
    return this.http.get<any>(endpoint);
  }

  public updateSubscriptionType(updateSubscriptionTypeRequest: UpdateSubscriptionTypeRequest) {
    const endpoint = `${this.apiUrl}/update-subscription-type`;
    return this.http.post<any>(endpoint, updateSubscriptionTypeRequest);
  }

  public changeSubscription(changeSubscriptionRequest: any) {
    const endpoint = `${this.apiUrl}/change-subscription`;
    return this.http.post<any>(endpoint, changeSubscriptionRequest);
  }

}