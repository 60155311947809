import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageInfo } from '@app/models/chat/message/message-info.dto';
import { EventEmitterService, NotificationTopic } from '@app/services/data/event-emitter.service';
import { ChatInfo } from '../../../../../models/chat/chat/chat-info.dto';

export const regexLink = /(((https?:\/\/)|(www\.))[-a-zA-Z0-9@:%.\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9_@:%\+.~#?&//=]*))/g;
export const linkReplacement = '<a href=\"$1\" target=\"_blank\" class=\"dark-url\">$1</a>';

@Component({
  selector: 'app-chat-note-message',
  templateUrl: './note-message.component.html',
  styleUrls: ['./note-message.component.css'],
})
export class NoteMessageComponent implements OnInit, AfterViewInit, OnDestroy {
  regexLink: RegExp = regexLink;
  link: string = linkReplacement;
  @Input()
  public message: MessageInfo;

  @Input()
  public chat: ChatInfo;

  private componentSubscriptions: Subscription[] = [];

  constructor(
    private eventEmitterService: EventEmitterService,
  ) {

  }

  ngOnInit() {

  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.componentSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  editMessageNote(selectedMessageNote: MessageInfo) {
    this.eventEmitterService.emit(NotificationTopic.ShowMessageNote, selectedMessageNote);
  }

  deleteMessageNote(selectedMessageNote: MessageInfo) {
    if (confirm('¿Está seguro que desea eliminar la nota?'))
      this.eventEmitterService.emit(NotificationTopic.DeleteMessageNote, selectedMessageNote);
  }

}
