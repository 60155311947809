import { UserRoleType } from "../../../type/account/user-role.type";

export class SaveUserRequest {

  id: number;
  profilePictureUrl: string;
  role: UserRoleType;
  name: string;
  username: string;
  email: string;
  phoneNumber: string;

}
