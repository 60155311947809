
export class DeleteSmartAssistantTopicItemRequest {

  smartAssistantTopicItemId: number;
  smartAssistantId: number;

  constructor(smartAssistantTopicItemId: number, smartAssistantId: number) {
    this.smartAssistantTopicItemId = smartAssistantTopicItemId;
    this.smartAssistantId = smartAssistantId;
  }

}