import { Component, OnDestroy, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgbCollapse, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UserInfo } from '@models/account/user/user.info.dto';
import { AuthService } from '@services/auth/auth.service';
import { UserRoleType } from '@type/account/user-role.type';
import { EventEmitterService, NotificationTopic } from '@services/data/event-emitter.service';
import { WhatsAppConfigInfo } from '@models/config/whatsapp/whatsapp-config-info.dto';
import { WhatsAppConfigService } from '@services/config/whatsapp-config.service';
import { WhatsappWebStatusType } from '@type/chat/whatsapp-web/whatsapp-web-status.type';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, OnDestroy {

  protected readonly UserRoleType = UserRoleType;

  public whatsAppConfig: WhatsAppConfigInfo;

  @ViewChild('navbarVerticalToggle', { static: true })
  public navbarVerticalToggle: ElementRef;

  @ViewChild(NgbCollapse) ngbCollapse: NgbCollapse;
  public isCollapsed: boolean = true;
  public isMobile: boolean;
  public currentUser: UserInfo;
  private componentSubscriptions: Subscription[] = [];

  constructor(
    private offCanvasService: NgbOffcanvas,
    public router: Router,
    private renderer2: Renderer2,
    private eventEmitterService: EventEmitterService,
    private authService: AuthService,
    private whatsAppConfigService: WhatsAppConfigService,
  ) {
    this.currentUser = this.authService.getUser();

  }

  ngOnInit() {
    this.initializeToggleSidebarSubscription();
    this.checkWhatsAppConfig();
  }

  ngAfterViewInit() {

    this.isMobile = window.innerWidth <= 992;

    if (this.navbarVerticalToggle) {
      
      this.renderer2.listen(this.navbarVerticalToggle.nativeElement, 'click', (event) => {
        
        this.navbarVerticalToggle.nativeElement.blur();

        const classList = document.documentElement.classList
        const collapsed = classList.contains('navbar-vertical-collapsed')

        classList.toggle('navbar-vertical-collapsed');
  
        localStorage.setItem('phoenixIsNavbarVerticalCollapsed', JSON.stringify(!collapsed));
      });
    }
  }

  ngOnDestroy() {
    this.componentSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  initializeToggleSidebarSubscription() {

    const eventEmitter = this.eventEmitterService.getEventEmitter(NotificationTopic.ToggleSidebar)

    const subscription = eventEmitter.subscribe(() => {
      this.ngbCollapse.toggle();
    });

    this.componentSubscriptions.push(subscription);
  }

  initCheckIfMobileSubscription() {
      
    const eventEmitter = this.eventEmitterService.getEventEmitter(NotificationTopic.IsMobile);

    const subscription = eventEmitter.subscribe((isMobile: boolean) => {
      this.isMobile = isMobile;
    });

    this.componentSubscriptions.push(subscription);
  }

  goToLink(url: string) {

    this.offCanvasService.hasOpenOffcanvas() && this.offCanvasService.dismiss();

    this.router.navigate([url]).then(() => {  });
    if (this.isMobile)
      this.ngbCollapse.toggle();
  }

  private checkWhatsAppConfig() {

    this.whatsAppConfigService.getConfig().subscribe({
      next: (whatsAppConfig: WhatsAppConfigInfo) => {
        this.whatsAppConfig = whatsAppConfig;
      },
      error: (error: any) => {
        console.error('Error fetching WhatsApp config:', error);
      }
    });

  }

  public shouldShowWhatsAppTemplates(): boolean {

    if (this.whatsAppConfig?.whatsappWebInstanceStatus?.status === WhatsappWebStatusType.CONNECTED) {
      return false;
    }

    return true;
  }

}
