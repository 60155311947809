<form [formGroup]="smartAssistantTopicItemActionForm"
      (submit)="saveSmartAssistantTopicItemAction()">

  <div class="d-flex justify-content-between align-items-center mb-3">
    <h4 class="card-title mb-0">
      Agregar nueva acción
    </h4>
  </div>
  <div class="smart-assistant-topic-item-action-form">

    <!-- Tipo de acción -->
    <div class="row px-0 mb-3">
      <div class="col-12">
        <div class="form-floating">
          <select id="smartAssistantTopicItemActionType"
                  class="form-select"
                  (change)="changeSmartAssistantTopicItemActionType($event)"
                  formControlName="type">
            <option selected="" [value]="''" disabled>
              Seleccione un tipo
            </option>
            <option [value]="smartAssistantTopicItemActionType.value"
                    *ngFor="let smartAssistantTopicItemActionType of SmartAssistantTopicItemActionTypes">
              {{ smartAssistantTopicItemActionType.description }}
            </option>
          </select>
          <label for="smartAssistantTopicItemActionType">
            Tipo de acción
          </label>
        </div>
      </div>
    </div>

    <!-- Mensaje -->
    <div class="row px-0 mb-3"
         *ngIf="
            selectedSmartAssistantTopicItemActionType === SmartAssistantTopicItemActionType.Message ||
            selectedSmartAssistantTopicItemActionType === SmartAssistantTopicItemActionType.SmartMessage">
      <div class="col-12">
        <div class="form-floating">
          <textarea
            class="form-control scrollbar"
            rows="32"
            id="smartAssistantTopicItemActionMessage"
            placeholder="Mensaje"
            formControlName="message"
            [ngClass]="{
              'is-invalid': smartAssistantTopicItemActionForm.get('message').touched && smartAssistantTopicItemActionForm.get('message').invalid,
              'is-valid': smartAssistantTopicItemActionForm.get('message').touched && smartAssistantTopicItemActionForm.get('message').valid
            }"></textarea>
          <label for="smartAssistantTopicItemActionMessage">
            Mensaje
          </label>
          <div
            *ngIf="smartAssistantTopicItemActionForm.get('message').touched && smartAssistantTopicItemActionForm.get('message').invalid"
            class="invalid-feedback text-warning">
            Ingrese el contenido de la tarjeta, por favor.
          </div>
        </div>
      </div>
    </div>

    <!-- Respuesta rápida -->
    <div class="row px-0 mb-3"
         *ngIf="selectedSmartAssistantTopicItemActionType === SmartAssistantTopicItemActionType.QuickReply">
      <div class="col-12">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            id="smartAssistantTopicItemActionQuickReply"
            #messageField
            placeHolder="Busca una respuesta rápida"
            ngbPopover="Este es un popover personalizado"
            triggers="manual"
            popoverTitle="Respuestas rápidas"
            [autoClose]="true"
            [placement]="'bottom-left'"
            [popoverClass]="'quick-replies-popover'"
            [ngbPopover]="quickRepliesPopoverContent"
            #p2="ngbPopover"
            [formControl]="textAreaFormControl"
            (focus)="showQuickRepliesPopover(p2)"
            (keyup)="onKeyup($event, p2)"
            (keydown)="onKeydown($event)"
          />
          <label for="smartAssistantTopicItemActionQuickReply">
            Busca una respuesta rápida
          </label>
        </div>
        <div class="card border border-success mt-3" *ngIf="selectedQuickReply">
          <div class="card-body">
            <h4 class="card-title">
              <b>{{ selectedQuickReply?.name }}</b>
            </h4>
            <p class="card-text mb-1">
              {{ selectedQuickReply?.textItem?.content }}
            </p>
            <p class="card-text text-primary mb-0" *ngIf="selectedQuickReply?.mediaItems?.length > 1">
              {{ selectedQuickReply?.mediaItems?.length }} archivos adjuntos
            </p>
            <p class="card-text text-primary mb-0" *ngIf="selectedQuickReply?.mediaItems?.length === 1">
              1 archivo adjunto
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Etiquetas de chat -->
    <div class="row px-0 mb-3"
         *ngIf="selectedSmartAssistantTopicItemActionType === SmartAssistantTopicItemActionType.ChatTag && !loadingChatTags">
      <div class="col-12">
        <app-typeahead
          [id]="'chatTags'"
          [placeHolder]="'Buscar etiquetas...'"
          [elementsAll]="chatTags"
          [initialElementsChosen]="selectedChatTags"
          (elementsChosenEvent)="selectChatTags($event)">
        </app-typeahead>
      </div>
    </div>

    <!-- Llamada http -->
    <div class="row px-0 mb-3"
         *ngIf="
            selectedSmartAssistantTopicItemActionType === SmartAssistantTopicItemActionType.HttpCall">
      <div class="col-12 mb-3">
        <div class="form-floating">
          <input
            class="form-control scrollbar"
            type="text"
            id="httpCallItemActionMessage"
            placeholder="Llamada Http"
            formControlName="httpCallUrl"
            [ngClass]="{
              'is-invalid': smartAssistantTopicItemActionForm.get('httpCallUrl').touched && smartAssistantTopicItemActionForm.get('httpCallUrl').invalid,
              'is-valid': smartAssistantTopicItemActionForm.get('httpCallUrl').touched && smartAssistantTopicItemActionForm.get('httpCallUrl').valid
            }">
          <label for="httpCallItemActionMessage">
            Llamada Http
          </label>
          <div
            *ngIf="smartAssistantTopicItemActionForm.get('httpCallUrl').touched && smartAssistantTopicItemActionForm.get('httpCallUrl').invalid"
            class="invalid-feedback text-warning">
            Ingrese la url de destino, por favor.
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="form-floating">
          <textarea
            class="form-control scrollbar"
            rows="16"
            id="httpCallInstructionItemAction"
            placeholder="Instrucciones de la llamada"
            formControlName="httpCallInstructions"
            [ngClass]="{
              'is-invalid': smartAssistantTopicItemActionForm.get('httpCallInstructions').touched && smartAssistantTopicItemActionForm.get('httpCallInstructions').invalid,
              'is-valid': smartAssistantTopicItemActionForm.get('httpCallInstructions').touched && smartAssistantTopicItemActionForm.get('httpCallInstructions').valid
            }"></textarea>
          <label for="smartAssistantTopicItemActionMessage">
            Instrucciones de la llamada
          </label>
          <div
            *ngIf="smartAssistantTopicItemActionForm.get('httpCallInstructions').touched && smartAssistantTopicItemActionForm.get('httpCallInstructions').invalid"
            class="invalid-feedback text-warning">
            Ingrese las instrucciones de la llamada
          </div>
        </div>
      </div>
    </div>

    <!-- Botones -->
    <div class="row px-0 mb-3">
      <div class="col-12 d-flex justify-content-end">
        <button type="submit" class="btn btn-sm btn-outline-secondary" (click)="goToActionsList()">
          <i class="fa fa-arrow-left"></i>
          Volver
        </button>
        <button type="submit" class="btn btn-sm btn-outline-primary ms-2"
                *ngIf="selectedSmartAssistantTopicItemActionType"
                [disabled]="!validateSmartAssistantTopicItemActionForm()">
          <i class="fa fa-save"></i>
          Guardar
        </button>
      </div>
    </div>


  </div>
</form>

<ng-template #quickRepliesPopoverContent>
  <div class="quick-replies-popover scrollbar">
    <ul class="list-group list-group-flush">
      <li class="list-group-item list-group-item-action text-dark"
          *ngIf="quickReplies && filteredQuickReplies.length === 0">
        Sin coincidencias
      </li>
      <li class="list-group-item list-group-item-action text-dark"
          *ngIf="quickReplies.length === 0">
        Sin respuestas rápidas
      </li>
      <li class="list-group-item list-group-item-action text-dark cursor-pointer"
          (click)="selectQuickReply(quickReply)"
          *ngFor="let quickReply of filteredQuickReplies">
        <div class="d-flex align-items-center justify-content-between">
          <strong class="text-truncate me-3 quick-reply-name-text">
            {{ quickReply.name }}
          </strong>
        </div>
        <p class="text-truncate mt-2 mb-1" *ngIf="quickReply.textItem">
          {{ quickReply.textItem.content }}
        </p>
        <p class="text-primary mb-1" *ngIf="quickReply.mediaItems?.length > 1">
          {{ quickReply.mediaItems?.length }} archivos adjuntos
        </p>
        <p class="text-primary mb-1" *ngIf="quickReply.mediaItems?.length === 1">
          1 archivo adjunto
        </p>
      </li>
    </ul>
  </div>
</ng-template>
