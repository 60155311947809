import { UserRoleType } from '../type/account/user-role.type';

const allRoutes = [
  '/app/chat',
  '/app/board',
  '/app/funnel',
  '/app/tags',
  '/app/contacts',
  '/app/quick-replies',
  '/app/message-templates',
  '/app/broadcast',
  '/app/smart-assistants',
  '/app/config/users',
  '/app/config/users-groups',
  '/app/config/integrations',
  '/app/config/subscription',
  '/app/marketplace',
  '/app/admin/marketplace',
];

const systemAdminRoutes = [
  '/admin/marketplace',
  '/admin',
];

export const getUserRoutes = (role: UserRoleType) => {
  switch (role) {
    case UserRoleType.SystemAdmin:
      return systemAdminRoutes;
    case UserRoleType.Admin:
      return allRoutes;
    case UserRoleType.ChatAdvisor:
      const ignoreRoutes = [
        // '/app/config/users',
        '/app/config/users-groups',
        '/app/config/integrations',
        '/app/config/subscription',
      ];
      return allRoutes.filter(route => !ignoreRoutes.includes(route));
  }
}