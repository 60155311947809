import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { HttpClient } from "@angular/common/http";
import { BroadcastSearchRequest } from "../../models/utilities/broadcast/broadcast-search-request.dto";
import { Observable } from "rxjs";
import { Page } from "../../models/common/page";
import { BroadcastInfo } from "../../models/utilities/broadcast/broadcast-info.dto";
import { SaveBroadcastRequest } from "../../models/utilities/broadcast/save-broadcast-request.dto";
import { ChatInfo } from "../../models/chat/chat/chat-info.dto";
import { SearchBroadcastChatsRequest } from "../../models/utilities/broadcast/search-broadcast-chats-request.dto";

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {

  private apiUrl: string = `${environment.apiUrl}/broadcasts`;

  constructor(
    private http: HttpClient,
  ) {

  }

  searchBroadcasts(broadcastSearch: BroadcastSearchRequest): Observable<Page<BroadcastInfo>> {
    const endpoint = `${this.apiUrl}/search-broadcasts`;
    return this.http.post<Page<BroadcastInfo>>(endpoint, broadcastSearch);
  }

  saveBroadcast(saveBroadcastRequest: SaveBroadcastRequest): Observable<BroadcastInfo> {
    const endpoint = `${this.apiUrl}/save-broadcast`;
    return this.http.post<BroadcastInfo>(endpoint, saveBroadcastRequest);
  }

  abortBroadcast(id: number): Observable<void> {
    const endpoint = `${this.apiUrl}/abort-broadcast/${id}`;
    return this.http.post<void>(endpoint, null);
  }

  searchBroadcastChats(searchBroadcastChatsRequest: SearchBroadcastChatsRequest): Observable<Page<ChatInfo>> {
    const endpoint = `${this.apiUrl}/search-broadcast-chats`;
    return this.http.post<Page<ChatInfo>>(endpoint, searchBroadcastChatsRequest);
  }

  sendBroadcast(id: number): Observable<void> {
    const endpoint = `${this.apiUrl}/send-broadcast/${id}`;
    return this.http.post<void>(endpoint, null);
  }

  deleteBroadcast(id: number): Observable<void> {
    const endpoint = `${this.apiUrl}/delete-broadcast/${id}`;
    return this.http.delete<void>(endpoint);
  }

}