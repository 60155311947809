import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SignUpRequest } from '@app/models/account/sign-up-request.dto';
import { environment } from '@env/environment';
import { TokenResponse } from '@app/models/auth/token-response.dto';
import { UserInfo } from '@app/models/account/user/user.info.dto';
import { Page } from '../../models/common/page';
import { ChatTagInfo } from '../../models/chat/tag/chat-tag-info.dto';
import { UserSearchRequest } from '../../models/account/user/user-search-request.dto';
import { CheckUserNameExistenceRequest } from '../../models/account/user/check-user-name-existence-request.dto';
import { SaveUserRequest } from '../../models/account/user/save-user-request.dto';
import { GetSignedUrlRequest } from '@app/models/media/get-signed-url-request.dto';
import { GetSignedUrlResponse } from '@app/models/media/get-signed-url-response.dto';
import { ChangePasswordRequest } from '../../models/account/user/change-password-request.dto';
import { ResetPasswordRequest } from '../../models/account/user/reset-password-request.dto';
import { ReassignUserChatsRequest } from '../../models/account/user/reassign-user-chats-request.dto';
import { ChangeUserStatusRequest } from '../../models/account/user/change-user-status-request.dto';
import { ActivateUserRequest } from '../../models/account/user/activate-user-request.dto';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  private apiUrl: string = `${environment.apiUrl}/users`;

  constructor(
    private http: HttpClient,
  ) {

  }

  signUp(signUpRequest: SignUpRequest): Observable<TokenResponse> {
    const endpoint = `${this.apiUrl}/sign-up`;
    return this.http.post<TokenResponse>(endpoint, signUpRequest);
  }

  saveUser(saveUserRequest: SaveUserRequest): Observable<UserInfo> {
    const endpoint = `${this.apiUrl}/save-user`;
    return this.http.post<UserInfo>(endpoint, saveUserRequest);
  }

  deleteUser(id: number): Observable<void> {
    const endpoint = `${this.apiUrl}/delete-user/${id}`;
    return this.http.delete<void>(endpoint);
  }

  generateProfilePictureSignedUrl(getSignedUrlRequest: GetSignedUrlRequest): Observable<GetSignedUrlResponse> {
    const endpoint = `${this.apiUrl}/generate-profile-picture-signed-url`;
    return this.http.post<GetSignedUrlResponse>(endpoint, getSignedUrlRequest);
  }

  getUserInfo(): Observable<UserInfo> {
    const endpoint = `${this.apiUrl}/me`;
    return this.http.get<UserInfo>(endpoint);
  }

  checkUserNameExistence(checkUserNameExistence: CheckUserNameExistenceRequest): Observable<boolean> {
    const endpoint = `${this.apiUrl}/check-user-name-existence`;
    return this.http.post<boolean>(endpoint, checkUserNameExistence);
  }

  searchUsers(userSearch: UserSearchRequest): Observable<Page<UserInfo>> {
    const endpoint = `${this.apiUrl}/search-users`;
    return this.http.post<Page<UserInfo>>(endpoint, userSearch);
  }

  changePassword(changePasswordRequest: ChangePasswordRequest): Observable<any> {
    const endpoint = `${this.apiUrl}/change-password`;
    return this.http.post<any>(endpoint, changePasswordRequest);
  }

  resetPassword(createAccountRequest: ResetPasswordRequest): Observable<any> {
    const endpoint = `${this.apiUrl}/reset-password`;
    return this.http.post<any>(endpoint, createAccountRequest);
  }

  confirmResetPassword(resetPasswordRequest: ResetPasswordRequest): Observable<any> {
    const endpoint = `${this.apiUrl}/confirm-reset-password`;
    return this.http.post<any>(endpoint, resetPasswordRequest);
  }

  reassignChats(reassignchatRequest: ReassignUserChatsRequest): Observable<any> {
    const endpoint = `${this.apiUrl}/reassign-user-chats`;
    return this.http.post<any>(endpoint, reassignchatRequest);
  }

  changeUserStatus(changeUserStatusRequest: ChangeUserStatusRequest): Observable<any> {
    const endpoint = `${this.apiUrl}/change-user-status`;
    return this.http.post<any>(endpoint, changeUserStatusRequest);
  }

  activeUser(activateUserRequest: ActivateUserRequest): Observable<any> {
    const endpoint = `${this.apiUrl}/activate-user`;
    return this.http.post<any>(endpoint, activateUserRequest);
  }

  getActivationLink(id: number): Observable<any> {
    const endpoint = `${this.apiUrl}/get-activation-link/${id}`;
    return this.http.post<any>(endpoint, {});
  }
}