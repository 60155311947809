
<div *ngIf="message" class="mb-1 p-3 rounded-2" [ngClass]="{
  'received-message-content border': message.customerMessage, 
  'sent-message-content bg-primary light text-white': !message.customerMessage, 
}">
  <app-chat-context-message
    *ngIf="message.contextMessage"
    [message]="message.contextMessage"
    [chat]="chat">
  </app-chat-context-message>
  <a
    [href]="message.fileUrl"
    data-fancybox="gallery">
    <img
      (load)="onImageLoad()"
      (error)="onImageLoad()"
      class="rounded-2 fit-cover mt-1 img-message-content"
      [src]="message.fileUrl">
  </a>
  <i class="fa fa-bolt text-warning ms-3" role="button" *ngIf="message.customerMessage" (click)="showFileImageDescription()"></i>
  <div class="scrollbar img-message-description mt-3 pe-2" *ngIf="message.showFileImageDescription">
    <p class="mb-0">
      {{ message.fileImageDescription }}
    </p>
  </div>
  <div class="scrollbar mt-3 pe-2" *ngIf="message.fileMediaCaption">
    <p class="mb-0">
      {{ message.fileMediaCaption }}
    </p>
  </div>
  <span class="reaction-emoji" *ngIf="message.reactionEmoji">
    {{ message.reactionEmoji }}
  </span>
</div>
