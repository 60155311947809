<div
  class="tab-pane h-100"
  id="tab-chat-content-thread"
  role="tabpanel"
  [class.fade]="chat === undefined">

  <div class="card flex-1 h-100 phoenix-offcanvas-container">

    <ngx-spinner
      [name]="'chat-content-spinner'"
      bdColor="rgba(0, 0, 0, 0.4)"
      color="#fff"
      [fullScreen]="false"
      [type]="'ball-scale-multiple'"
      size="medium"
      style="color: white;">
      <p class="loading-spinner">
        {{ chatContentSpinnerText ?? 'Cargando mensajes...' }}
      </p>
    </ngx-spinner>

    <div class="card-header p-3 p-md-4 d-flex flex-between-center">
      <div class="d-flex align-items-center">
        <button (click)="showSideChatList()"
                class="btn ps-0 pe-2 text-700 d-sm-none"
                data-phoenix-toggle="offcanvas"
                data-phoenix-target="#chat-sidebar">
          <span class="fa-solid fa-chevron-left"></span>
        </button>
        <div class="d-flex flex-column">
          <div class="d-flex flex-column flex-md-row align-items-md-center">
            <button class="btn fs-1 fw-semi-bold text-1100 d-flex align-items-center p-0 me-3 text-start"
                    data-phoenix-toggle="offcanvas"
                    data-phoenix-target="#thread-details-0">
              <span class="line-clamp-1">
                {{ chat?.chatAlias ? chat.chatAlias : chat?.chatName }}
              </span>
              <span class="fa-solid fa-chevron-down ms-2 fs--2"></span>
            </button>
            <div *ngIf="!chat?.expired">
              <p class="fs--1 mb-0 me-2">
                <i
                  class="fa-brands fs-1 me-2"
                  [ngClass]="{
                    'fa-whatsapp text-success': chat?.channel === ChannelType.WhatsApp || ChannelType.WhatsAppWeb, 
                    'fa-facebook-messenger text-primary': chat?.channel === ChannelType.Messenger,
                  }">
                </i>
              </p>
            </div>
            <div *ngIf="chat?.channel === ChannelType.WhatsApp && chat?.expired">
              <p class="fs--1 mb-0 me-2">
                <span class="fa-solid fa-circle text-danger fs--3 me-2"></span>
              </p>
            </div>
          </div>
          <span class="line-clamp-1">
            {{ chat?.chatPhoneNumber }}
          </span>
        </div>

        <form [formGroup]="funnelForm" class="d-flex align-items-center ms-3">
          <div class="me-3">
            <select
              class="form-select"
              formControlName="funnel"
              (change)="onFunnelChange($event)">
              <option selected [value]="''" disabled>Seleccione un embudo</option>
              <option *ngFor="let funnel of funnels" [value]="funnel.id">{{ funnel.name }}</option>
            </select>
          </div>

          <!-- Select de Etapas del Embudo -->
          <div *ngIf="stages?.length > 0">
            <select
              class="form-select"
              formControlName="stage"
              (change)="onStageChange($event)">
              <option selected [value]="''" disabled>Seleccione una etapa</option>
              <option *ngFor="let stage of stages" [value]="stage.id">{{ stage.name }}</option>
            </select>
          </div>
        </form>
      </div>
      <div class="d-flex align-items-center">
        <span
          *ngIf="chat && chat.smartAssistantUsed"
          role="button"
          class="badge badge-phoenix text-warning fs--1 me-3 border border-warning advisor-info">
          <i class="fa fa-bolt text-warning"></i>
          IVANA
        </span>
        <span
          *ngIf="chat && chat.user"
          role="button"
          class="badge badge-phoenix text-primary fs--1 me-3 border border-primary advisor-info">
          <i class="fa fa-user-headset"></i>
          {{ chat.user.name }}
        </span>
        <button class="btn btn-icon btn-primary me-1"
                (click)="showAssignAdvisorModal()"
                [placement]="'bottom-right'"
                (mouseenter)="openAdvisorPopover(p3)"
                (mouseleave)="closeAdvisorPopover(p3)"
                [autoClose]="false"
                ngbPopover="Este es un popover personalizado"
                popoverTitle="Asignar asesor"
                [ngbPopover]="popoverContent"
                #p3="ngbPopover">
          <span class="fa fa-user-headset"></span>
        </button>
        <button
          *ngIf="!sideSettingsButton"
          (click)="openChatSettings()"
          class="btn btn-icon btn-phoenix-secondary me-1" type="button">
          <span class="fa-solid fa-gear"></span>
        </button>
        <button class="btn btn-icon btn-phoenix-primary" type="button"
                data-bs-toggle="dropdown" data-boundary="window"
                data-bs-reference="parent">
          <span class="fa-solid fa-ellipsis-vertical"></span>
        </button>
        <ul class="dropdown-menu dropdown-menu-end p-0">
          <li>
            <a class="dropdown-item cursor-pointer" (click)="toggleChatSeen()" *ngIf="chat">
              <i class="fa-regular fa-eye" *ngIf="!chat.seen"></i>
              <i class="fa-regular fa-eye-slash" *ngIf="chat.seen"></i>
              <span class="ms-1">
                {{ chat.seen ? "Marcar como no leído" : "Marcar como leído" }}
              </span>
            </a>
          </li>
          <li>
            <a class="dropdown-item cursor-pointer" (click)="toggleChatArchive()" *ngIf="chat">
              <i class="fa-solid fa-box-open" *ngIf="chat.archived"></i>
              <i class="fa-solid fa-box" *ngIf="!chat.archived"></i>
              <span class="ms-1">
                {{ chat.archived ? "Desarchivar" : "Archivar" }}
              </span>
            </a>
          </li>
          <li>
            <a class="dropdown-item cursor-pointer" (click)="showChatThread()" *ngIf="chat">
              <i class="fa-regular fa-bolt text-warning"></i>
              <span class="ms-1">
                Obtener hilo de chat
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="card-body chat-background p-0 scrollbar position-relative"
         #chatScrollContainer
         id="scrollContainer"
         [class.transparent]="hideMessages"
         [class.not-transparent]="!hideMessages">
      <div
        class="p-3 p-sm-4 inner-chat-background"
        [class.p-after-load]="afterChatsLoaded"
        infiniteScroll
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="50"
        [infiniteScrollContainer]="'#scrollContainer'"
        [fromRoot]="true"
        (scrolledUp)="onScrolledUp()">
        <div class="d-flex chat-message" *ngFor="let message of messages">
          <app-chat-message
            (messageReady)="setMessageReady(message)"
            [id]="'m' + message.id"
            class="d-flex mb-3 flex-1"
            [ngClass]="{
              'justify-content-end': !message.customerMessage
            }"
            [chat]="chat"
            [message]="message"
            *ngIf="message.type !== MessageType.Reaction">
          </app-chat-message>
        </div>
      </div>
    </div>

    <div class="card-footer"
         [class.transparent]="hideMessages"
         [class.not-transparent]="!hideMessages">
      <ng-container *ngIf="!shouldShowExpiredChat()">
        <div class="row" *ngIf="replyingMessage">
          <div class="col">
            <app-chat-context-message
              [message]="replyingMessage"
              [chat]="chat">
            </app-chat-context-message>
          </div>
          <div class="col-auto align-self-center">
            <button class="btn px-0" (click)="closeReplyingMessage()"><i class="fa-regular fa-circle-x fs-1"></i>
            </button>
          </div>
        </div>

        <textarea *ngIf="!creatingNote"
                  class="form-control chat-textarea outline-none scrollbar border-0 ps-1 mb-2"
                  #messageField
                  placeHolder="Escribe un mensaje aquí..."
                  ngbPopover="Este es un popover personalizado"
                  triggers="manual"
                  [popoverTitle]="popoverTitleTemplate"
                  [autoClose]="false"
                  [placement]="'top-left'"
                  [popoverClass]="'quick-replies-popover'"
                  [ngbPopover]="quickRepliesPopoverContent"
                  #p4="ngbPopover"
                  (paste)="onPaste($event)"
                  [formControl]="textAreaFormControl"
                  (keydown)="onKeydown($event)"
                  (keyup)="onKeyup($event, p4)"
        ></textarea>
        <textarea *ngIf="creatingNote"
                  class="form-control chat-textarea outline-none scrollbar border-0 ps-1 mb-2"
                  #noteMessageField
                  (keydown)="onKeydownNote($event)"
                  placeHolder="Escribe el contenido de la nota"
                  [formControl]="noteMessageFormControl"
        ></textarea>

        <ng-container *ngIf="showingUppy">
          <uppy-dashboard
            [uppy]="uppy"
            [props]="uppyDashboardProps">
          </uppy-dashboard>
        </ng-container>

        <ng-container *ngIf="isRecording">
          <div class="d-flex justify-content-start mt-2">
            <div class="d-flex align-items-center chat-bar-action-icons">
              <button
                type="button"
                class="btn btn-link border-0 py-0 px-2 text-900 fs-1"
                (click)="abortAudioRecording()">
                <span class="fa-solid fa-trash"></span>
              </button>
              <div class="spinner-grow spinner-grow-sm px-2 text-danger" role="status"></div>
              <span class="px-2">
                {{ recordedTime }}
              </span>
              <canvas id="audioVisualizerCanvas"></canvas>
            </div>
            <button
              type="button"
              id="sendAudioRecordingBtn"
              class="btn btn-link border-0 py-0 px-2 text-900 text-danger-500 fs-1"
              (click)="stopAudioRecording()">
              <span class="fa-solid fa-paper-plane"></span>
            </button>
          </div>
        </ng-container>

        <ng-container *ngIf="creatingNote">
          <div class="d-flex justify-content-between align-items-end mt-2">
            <button class="btn btn-secondary fs--2" type="button" (click)="cancelCreateNote()">
              Cancelar
            </button>
            <button class="btn btn-primary fs--2" type="button" (click)="sendMessage()">
              Guardar <span class="fa-solid fa-floppy-disk ms-1"></span>
            </button>
          </div>
        </ng-container>

        <ng-container *ngIf="!isRecording && !creatingNote">
          <div class="d-flex justify-content-between align-items-end mt-2">
            <div class="chat-bar-action-icons">
              <button
                type="button"
                class="btn btn-link py-0 ps-0 pe-2 text-900 fs-1 btn-emoji"
                (click)="openEmojiPopover(p2)"
                [placement]="'top'"
                [autoClose]="'outside'"
                ngbPopover="Este es un popover personalizado"
                [container]="'body'"
                [popoverClass]="'popover-custom'"
                [ngbPopover]="emojiPopoverContent"
                #p2="ngbPopover">
                <span class="fa-regular fa-face-smile"></span>
              </button>
              <button
                type="button"
                class="btn btn-link py-0 px-2 text-900 fs-1"
                (click)="toggleUppy()">
                <div *ngIf="!showingUppy">
                  <span class="fa-solid fa-paperclip"></span>
                </div>
                <div *ngIf="showingUppy">
                  <span class="fa-solid fa-xmark text-danger"></span>
                </div>
              </button>
              <button
                type="button"
                class="btn btn-link py-0 px-2 text-900 fs-1"
                *ngIf="!isRecording"
                (click)="startAudioRecording()">
                <span class="fa-solid fa-microphone"></span>
              </button>
              <button type="button" class="btn btn-link py-0 px-2 text-900 fs-1"
                      (click)="showCreateNote()">
                <span class="fa-solid fa-note-sticky text-warning"></span>
              </button>
              <button
                type="button"
                class="btn btn-link py-0 px-2 text-900 fs-1"
                (click)="openPopover()">
                <span class="fa-solid fa-list text-primary"></span>
              </button>
              <button
                type="button"
                class="btn btn-link py-0 px-2 text-900 fs-1"
                (click)="openChatSummaryModal()">
                <span class="fa-solid fa-sparkles text-purple"></span>
              </button>
            </div>
            <button class="btn btn-primary fs--2" type="button" (click)="sendMessage()">
              Enviar <span class="fa-solid fa-paper-plane ms-1"></span>
            </button>
          </div>
        </ng-container>

      </ng-container>

      <ng-container *ngIf="shouldShowExpiredChat()">
        <div class="alert alert-outline-warning d-flex align-items-center mb-0" role="alert">
          <span class="fas fa-info-circle text-warning fs-3 me-3"></span>
          <p class="mb-0 flex-1">
            Chat caducado.
            <a href="javascript:void(0);" role="button" (click)="openChatTemplateSender()">
              Envíe un mensaje de plantilla.
            </a>
          </p>
        </div>
      </ng-container>

    </div>

    <div class="phoenix-offcanvas phoenix-offcanvas-top h-100 w-100 bg-white scrollbar z-index-0"
         id="thread-details-0">
      <div class="border-bottom p-4">
        <div class="d-flex align-content-center" data-phoenix-dismiss="offcanvas">
          <button class="btn p-0">
            <span class="fa-solid fa-chevron-left fs--1 text-700"></span>
          </button>
          <div class="d-flex flex-column ms-2">
            <div class="d-flex flex-column flex-md-row align-items-md-center">
              <button class="btn fs-1 fw-semi-bold text-1100 d-flex align-items-center p-0 me-3 text-start"
                      data-phoenix-toggle="offcanvas"
                      data-phoenix-target="#thread-details-0">
                <span class="line-clamp-1">
                  {{ chat?.chatAlias ? chat.chatAlias : chat?.chatName }}
                </span>
              </button>
              <div *ngIf="!chat?.expired">
                <p class="fs--1 mb-0 me-2">
                  <i
                    class="fa-brands fs-1 me-2"
                    [ngClass]="{
                      'fa-whatsapp text-success': chat?.channel === ChannelType.WhatsApp || ChannelType.WhatsAppWeb,
                      'fa-facebook-messenger text-primary': chat?.channel === ChannelType.Messenger,
                    }">
                  </i>
                </p>
              </div>
              <div *ngIf="chat?.channel === ChannelType.WhatsApp && chat?.expired">
                <p class="fs--1 mb-0 me-2">
                  <span class="fa-solid fa-circle text-danger fs--3 me-2"></span>
                </p>
              </div>
            </div>
            <span class="line-clamp-1">
              {{ chat?.chatPhoneNumber }}
            </span>
          </div>
        </div>
      </div>
      <div class="p-4 px-sm-5 scrollbar">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <button class="btn d-block p-0 fw-semi-bold mb-3 collapsed " data-bs-toggle="collapse"
                    href="#collapseChatAlias" role="button" aria-expanded="false" aria-controls="collapseChatAlias">
              <span class="fa-solid fa-user-pen me-3"></span>
              Cambiar nombre
            </button>
            <div class="collapse" id="collapseChatAlias">
              <form>
                <div class="row g-2 ">
                  <div class="d-flex justify-content-between">
                    <div class="form-floating w-100">
                      <input
                        id="floatingInput"
                        type="text"
                        class="form-control mb-3"
                        placeholder="Buscar etiquetas"
                        [formControl]="chatAliasFormControl">
                      <label for="floatingInput">
                        Cambia el nombre del chat
                      </label>
                    </div>
                    <button class="btn btn-phoenix-primary ms-2 mb-3"
                            type="submit"
                            (click)="changeChatAlias()">
                      <i class="fa fa-save text-primary fs-1"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <button class="btn d-block p-0 fw-semi-bold mb-3">
              <span class="fa-solid fa-palette me-3"></span>
              Cambiar color
            </button>
            <button class="btn d-block p-0 fw-semi-bold mb-3">
              <span class="fa-solid fa-bell-slash me-3"></span>
              Silenciar
            </button>
          </div>
          <div class="col-6">
            <button class="btn d-block p-0 fw-semi-bold mb-3">
              <span class="fas fa-history me-3"></span>
              Borrar historial de asistencia inteligente
            </button>
            <button class="btn d-block p-0 fw-semi-bold mb-3">
              <span class="fas fa-ban me-3"></span>
              Marcar como spam
            </button>
          </div>
        </div>
        <hr>
        <div class="row mt-4">
          <div class="col-lg-6 col-md-12">
            <div class="d-flex mb-2">
              <span class="fa-solid fa-photo-film me-3 fs--1"></span>
              <h6 class="fw-semi-bold mb-2">
                Galería
              </h6>
            </div>
            <div class="row g-2 pt-0 gallery-wrapper scrollbar" *ngIf="mediaMessages?.length > 0">
              <div class="col-auto" *ngFor="let mediaMessage of mediaMessages">
                <ng-container [ngSwitch]="mediaMessage.type">
                  <div *ngSwitchCase="MessageType.Image" class="position-relative">
                    <a [href]="mediaMessage.fileUrl" class="fancybox " data-fancybox="gallery">
                      <img class="fit-cover rounded-2 hover-bg-200" [src]="mediaMessage.fileUrl" height="100"
                           width="100" />
                      <span class="rounded-circle">
                      <span
                        class="position-absolute image-item-wrapper rounded-circle translate-middle text-center pt-1 px-1">
                        <i class="fa-regular fa-image"></i>
                      </span>
                      </span>
                    </a>
                  </div>
                  <div *ngSwitchCase="MessageType.Video" class="position-relative">
                    <a [href]="mediaMessage.fileUrl" class="fancybox text-decoration-none" data-fancybox="gallery">
                      <div class="rounded-2 d-flex align-items-center justify-content-center media-item-wrapper">
                        <i class="fa-solid fa-video"></i>
                      </div>
                    </a>
                  </div>
                  <div *ngSwitchCase="MessageType.Audio" class="position-relative">
                    <a (click)="downloadFile(mediaMessage)" href="javascript:void(0);"
                       class="text-decoration-none">
                      <div class="rounded-2 d-flex align-items-center justify-content-center media-item-wrapper">
                        <i class="fa-solid fa-microphone"></i>
                      </div>
                    </a>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="mb-5">
              <div class="d-flex">
                <span class="fa-solid fa-folder me-3 fs--1"></span>
                <div class="flex-1">
                  <h6 class="fw-semi-bold pb-2 mb-0">
                    Documentos
                  </h6>
                </div>
              </div>
              <div class="mb-2 gallery-wrapper scrollbar" *ngIf="documentMessages?.length > 0">
                <div class="border-bottom d-flex align-items-center justify-content-between pt-2"
                     *ngFor="let documentMessage of documentMessages">
                  <a class="text-decoration-none d-flex align-items-center pb-3"
                     (click)="downloadFile(documentMessage)" href="javascript:void(0);">
                    <div class="btn-icon btn-icon-lg border border-500 rounded-3 flex-column me-2">
                      <span class="fs-0 mb-1 fa-solid fa-file-zipper"></span>
                      <p class="mb-0 fs--2 fw-bold lh-1">zip</p>
                    </div>
                    <div class="flex-1">
                      <h6 class="text-900 line-clamp-1">
                        {{ documentMessage.fileName }}
                      </h6>
                      <div class="d-flex align-items-center">
                        <p class="fs--2 mb-0 me-2 text-700 fw-semi-bold">
                          {{ (documentMessage.fileSize / 1048576).toFixed(2) }} MB
                        </p>
                        <p class="fs--2 mb-0 text-700 fw-semi-bold">
                          {{ documentMessage.sentTime | date: 'dd/MM/yyyy HH:mm:ss' }}
                        </p>
                      </div>
                    </div>
                  </a>
                  <span class="fa-regular fa-arrow-alt-circle-down cursor-pointer text-primary fs-0"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <a class="card setting-toggle"
       *ngIf="sideSettingsButton"
       [routerLink]="" role="button"
       (click)="openChatSettings()">
      <div class="card-body d-flex align-items-center px-2 py-1">
        <div class="position-relative rounded-start" style="height: 34px; width: 28px;">
          <div class="settings-popover">
            <span class="ripple">
              <span class="fa-spin position-absolute all-0 d-flex flex-center">
                <span class="icon-spin position-absolute all-0 d-flex flex-center">
                  <i class="fas fa-gear"></i>
                </span>
              </span>
            </span>
          </div>
        </div>
        <small class="text-uppercase text-700 fw-bold py-2 pe-2 ps-1 rounded-end">
          Opciones del chat
        </small>
      </div>
    </a>
  </div>
</div>

<ng-template #sideOffCanvas>
  <app-chat-settings [hidden]="!showChatSettings" [chat]="chat"></app-chat-settings>
  <app-chat-template-sender [hidden]="!showChatTemplateSender" [chat]="chat"></app-chat-template-sender>
  <app-chat-whatsapp-web-message-sender [hidden]="!showWhatsAppWebMessageSender"></app-chat-whatsapp-web-message-sender>
</ng-template>

<ng-template #emojiPopoverContent>
  <emoji-mart [isNative]="true" [i18n]="emojiMartI18n" (emojiClick)="selectEmoji($event)"></emoji-mart>
</ng-template>

<ng-template #popoverTitleTemplate>
  <div class="d-flex flex-column">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <span>Respuestas rápidas</span>
      <button type="button" class="btn-close btn-sm" aria-label="Close" (click)="closePopover()"></button>
    </div>
    <div class="w-100 my-2">
      <select class="form-select form-select-sm" id="groupSelect" (change)="filterByGroups($event)" [(ngModel)]="selectedGroups">
        <option value="all">Todos los grupos</option>
        <option *ngFor="let group of groups" [value]="group.name">{{ group.name }}</option>
      </select>
    </div>
  </div>
</ng-template>

<ng-template #quickRepliesPopoverContent>
  <div class="quick-replies-popover scrollbar">
    <ul class="list-group list-group-flush">
      <li class="list-group-item list-group-item-action text-dark"
          *ngIf="quickReplies && filteredQuickReplies.length === 0">
        Sin coincidencias
      </li>
      <li class="list-group-item list-group-item-action text-dark"
          *ngIf="quickReplies.length === 0">
        Sin respuestas rápidas
      </li>
      <li class="list-group-item list-group-item-action text-dark cursor-pointer"
          (click)="selectQuickReply(quickReply)"
          *ngFor="let quickReply of filteredQuickReplies">
        <div class="d-flex align-items-center justify-content-between">
          <strong class="text-truncate me-3 quick-reply-name-text">
            {{ quickReply.name }}
          </strong>
          <button type="button" class="btn btn-sm btn-primary"
                  (click)="sendQuickReply(quickReply, $event)">
            <span class="fa-solid fa-paper-plane"></span>
            Enviar
          </button>
        </div>
        <p class="text-truncate mt-2 mb-1" *ngIf="quickReply.textItem">
          {{ quickReply.textItem.content }}
        </p>
        <p class="text-primary mb-1" *ngIf="quickReply.mediaItems?.length > 1">
          {{ quickReply.mediaItems?.length }} archivos adjuntos
        </p>
        <p class="text-primary mb-1" *ngIf="quickReply.mediaItems?.length === 1">
          1 archivo adjunto
        </p>
        <div *ngIf="quickReply.groups.length > 0" class="mt-2">
          <span
              *ngFor="let group of quickReply.groups"
              role="button"
              class="badge bg-light text-dark me-1 mb-1"
              [ngStyle]="{
                  'border-color': '#007bff',
                  'color': '#007bff',
                  'background-color': 'rgba(0, 123, 255, 0.1)',
                  'border': '1px solid #007bff',
                  'font-size': '0.8rem',
                  'border-radius': '4px'
              }"
              [title]="group.name">
              {{ group.name }}
          </span>
      </div>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #popoverContent>
  <p class="mb-1">
    Los <strong class="text-primary">asesores</strong> son los encargados de atender a los contactos / clientes.
  </p>
</ng-template>

<ng-template #assignAdvisorModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      <i class="fa fa-user-headset"></i>
      Asignar asesor
    </h4>
    <button
      type="button"
      class="btn-close"
      (click)="modal.close()">
    </button>
  </div>
  <div class="modal-body">
    <div class="card-body">
      <div class="row">
        <app-advisors-input [chat]="chat" (advisorsEmitter)="setChatAdvisorChosen($event)"></app-advisors-input>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      (click)="modal.close()"
      type="button"
      class="btn btn-secondary">
      Cerrar
    </button>
    <button (click)="assignUserToChat()" type="button" class="btn btn-primary">
      Asignar
    </button>
  </div>
</ng-template>

<ng-template #chatThreadModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      <i class="fa-solid fa-bolt text-warning"></i>
      Hilo de chat
    </h4>
    <button
      type="button"
      class="btn-close"
      (click)="modal.close()">
    </button>
  </div>
  <div class="modal-body">
    <div class="card-body">
      <div class="row">
        <ngx-json-viewer [json]="chatThread" [expanded]="true"></ngx-json-viewer>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #chatResumeModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      <i class="fa-solid fa-sparkles text-purple"></i>
      Resumen del chat
    </h4>
    <button
      type="button"
      class="btn-close"
      (click)="modal.close()">
    </button>
  </div>
  <div class="modal-body">
    <div class="card-body">
      <div class="row">
        <div class="float-end">
          <p *ngIf="chat && chat.lastSummaryChatDate" class="fs--1 mb-0">
            <strong>Última fecha de resumen:</strong>
            {{ chat.lastSummaryChatDate | date: 'dd/MM/yyyy HH:mm:ss' }}
          </p>
          <p *ngIf="!chat || !chat.lastSummaryChatDate" class="fs--1 mb-0 text-danger">
            <strong>No se ha generado un resumen aún.</strong>
            Por favor, genera un resumen antes de continuar.
          </p>
        </div>
        <div class="col-12">
          <div class="form-floating">
            <textarea
              class="form-control custom-text-area scrollbar"
              id="resumeChatTextArea"
              placeholder="Escribe un mensaje aquí..."
              [formControl]="summaryChatFormControl"
              rows="50"
              [readonly]="true"></textarea>
            <label for="resumeChatTextArea" *ngIf="chat && chat.lastSummaryChatDate">
              Resumen
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      (click)="modal.close()"
      type="button"
      class="btn btn-secondary">
      Cerrar
    </button>
    <button
      (click)="summaryChat()"
      type="button"
      class="btn btn-indigo">
      <span class="fa-solid fa-sparkles text-purple"></span>
      Resumir
    </button>
  </div>
</ng-template>
