import { PaginatedSearchRequest } from '../../../common/paginated-search-request.dto';

export class GetFunnelStageChatsRequest extends PaginatedSearchRequest {

  shopId: number;
  funnelStageId: number;

  constructor(shopId: number, funnelStageId: number, page: number, size: number) {
    super();
    this.page = page;
    this.size = size;
    this.shopId = shopId;
    this.funnelStageId = funnelStageId;
  }

}