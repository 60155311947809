import { SmartAssistantOrchestratorContextInfo } from './context/smart-assistant-orchestrator-context-info.dto';

export class SmartAssistantOrchestratorInfo {

  id: number;
  createdAt: Date;
  name: string;
  enabled: boolean;
  smartAssistantOrchestratorContexts: SmartAssistantOrchestratorContextInfo[];

}