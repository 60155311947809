
export enum MessageStatus {

  Scheduled = 'scheduled',
  Pending = 'pending',
  Sent = 'sent',
  Delivered = 'delivered',
  Read = 'read',
  Failed = 'failed',

}