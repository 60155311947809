<div class="row" [class.transparent]="firstLoad" [class.not-transparent]="!firstLoad">
  <!-- Listado de plantillas -->
  <div class="col-xl-6 col-sm-12" *ngIf="!savingMessageTemplate">
    <div class="card shadow-none border border-300 whatsapp-message-templates-list">
      <div class="card-header p-4 border-bottom border-300 bg-soft px-3">
        <div class="row g-3 justify-content-between align-items-center">
          <div class="col-12 col-md-auto">
            <h4 class="text-900 mb-0">
              <i class="fab fa-whatsapp text-success"></i>
              <span class="text-dark">
                Plantillas
              </span>
            </h4>
          </div>
          <div class="col col-md-auto">
            <nav class="nav nav-underline justify-content-end doc-tab-nav align-items-center" role="tablist">
              <button type="button" class="btn btn-sm btn-phoenix-primary"
                      (click)="openSaveMessageTemplate()">
                <i class="fa fa-plus"></i>
              </button>
            </nav>
          </div>
        </div>
        <div class="row">
          <p class="text-700 lh-sm mt-1 my-0">
            Inicia chats con tus clientes de manera rápida con plantillas de mensaje.
          </p>
        </div>
      </div>
      <div class="card-body whatsapp-message-templates-card-body-list scrollbar p-0">
        <p class="m-3" *ngIf="messageTemplates?.length == 0">
          Sin plantillas de mensaje de WhatsApp, crea una nueva.
        </p>
        <ul class="list-group list-group-flush whatsapp-message-templates-list-ul scrollbar" *ngIf="messageTemplates?.length !== 0">
          <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center cursor-pointer"
              [class.active]="whatsAppMessageTemplate?.id === messageTemplate.id"
              *ngFor="let messageTemplate of messageTemplates"
              (click)="openSaveMessageTemplate(messageTemplate)">
            <span class="text-truncate">
              {{ messageTemplate.name }}
            </span>
            <div class="d-flex justify-content-end">
              <span class="badge badge-phoenix badge-phoenix-success"
                    *ngIf="messageTemplate.status === 'APPROVED'">
                Aprobado
              </span>
              <span class="badge badge-phoenix badge-phoenix-warning"
                    *ngIf="messageTemplate.status === 'PENDING'">
                En revisión
              </span>
              <span class="badge badge-phoenix badge-phoenix-danger"
                    *ngIf="messageTemplate.status === 'REJECTED'">
                Rechazado
              </span>
              <span class="badge badge-phoenix badge-phoenix-info ms-1"
                    *ngIf="messageTemplate.category === 'MARKETING'">
                Marketing
              </span>
              <span class="badge badge-phoenix badge-phoenix-info ms-1"
                    *ngIf="messageTemplate.category === 'UTILITY'">
                Utilidad
              </span>
              <span class="badge badge-phoenix badge-phoenix-info ms-1"
                    *ngIf="messageTemplate.category === 'AUTHENTICATION'">
                Autenticación
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="col-9" *ngIf="false">
    <pre class="scrollbar" style="max-height: 800px;">{{ messageTemplates | json }}</pre>
  </div>

  <div class="col-xl-12" *ngIf="savingMessageTemplate">
    <div class="row">
      <!-- Creación y edición de plantillas -->
      <div class="col-xl-9 col-sm-12"
           [class.transparent]="!whatsAppMessageTemplate || !savingMessageTemplate"
           [class.not-transparent]="whatsAppMessageTemplate || savingMessageTemplate">
        <div class="card card-body px-12 h-100 d-flex justify-content-center align-items-center"
             *ngIf="whatsAppMessageTemplate === undefined && !savingMessageTemplate">
          <h2 class="text-muted">
            ¡Hola! Da click en visualizar una plantilla de mensaje para iniciar.
          </h2>
        </div>
        <div class="card"
             *ngIf="savingMessageTemplate">
          <!-- Tag de información del template -->
          <div class="card-header d-flex align-items-center justify-content-between">
            <h5 class="mb-0">
              <i class="fab fa-whatsapp text-success"></i>
              {{ whatsAppMessageTemplate?.name || 'Nueva plantilla de mensajes' }}
            </h5>
            <button class="btn btn-sm btn-phoenix-primary" (click)="closeSaveMessageTemplate()">
              <i class="fa-duotone fa-turn-left"></i>
              Volver
            </button>
          </div>
          <div class="card-body message-template-save-container scrollbar">
            <pre class="scrollbar my-3" *ngIf="false">{{ messageTemplateForm.value | json }}</pre>
            <pre class="scrollbar my-3" *ngIf="false">{{ whatsAppMessageTemplate | json }}</pre>
            <pre class="scrollbar my-3" *ngIf="false">{{ WhatsAppMessageTemplateTypes | json }}</pre>
            <form
              [formGroup]="messageTemplateForm"
              (submit)="saveMessageTemplate()">

              <!-- Tipo y lenguaje de la plantilla de mensaje -->
              <div class="row">
                <div class="col-4">
                  <div class="form-floating">
                    <select id="messageTemplateCategory" class="form-select"
                            (change)="changeMessageTemplateCategory($event)"
                            formControlName="category">
                      <option [value]="whatsAppMessageTemplateType.value"
                              [disabled]="whatsAppMessageTemplateType.value === WhatsAppMessageTemplateType.Authentication"
                              *ngFor="let whatsAppMessageTemplateType of WhatsAppMessageTemplateTypes">
                        {{ whatsAppMessageTemplateType.description }}
                      </option>
                    </select>
                    <label for="messageTemplateCategory">
                      Categoría de plantilla
                    </label>
                  </div>
                </div>
                <div class="col-8">
                  <app-typeahead
                    [id]="'messageTemplateLanguage'"
                    [singleChoose]="true"
                    [keyId]="'code'"
                    [keyName]="'language'"
                    [placeHolder]="'Buscar lenguaje...'"
                    [disabled]="whatsAppMessageTemplate?.id !== undefined"
                    [elementsAll]="messageTemplateLanguages"
                    [elementChosen]="whatsAppMessageTemplate?.languageValue"
                    (elementChosenEvent)="selectMessageTemplateLanguage($event)">
                  </app-typeahead>
                </div>
              </div>

              <!-- Nombre de la plantilla de mensaje -->
              <div class="row mb-3">
                <div class="col-12">
                  <div class="form-floating">
                    <input
                      class="form-control"
                      id="name"
                      type="text"
                      placeholder="Asigna un nombre a la plantilla de mensaje."
                      formControlName="name"
                      [ngClass]="{
                        'is-invalid': (messageTemplateForm.get('name').touched && messageTemplateForm.get('name').invalid) || duplicatedName,
                        'is-valid': checkingDuplicatedName === 'after-checking' && !duplicatedName && messageTemplateForm.get('name').valid
                      }">
                    <label for="name">
                      Asigna un nombre a la plantilla de mensaje.
                    </label>
                    <div class="valid-feedback text-success" *ngIf="!sameName">
                      ¡Nombre disponible!
                    </div>
                    <div
                      *ngIf="messageTemplateForm.get('name').touched && messageTemplateForm.get('name').invalid"
                      class="invalid-feedback text-warning">
                      Ingrese un nombre para la plantilla de mensaje de WhatsApp, por favor.
                    </div>
                    <div
                      *ngIf="duplicatedName"
                      class="invalid-feedback text-warning">
                      La plantilla de mensaje de WhatsApp ya existe, ingrese otro nombre.
                    </div>
                  </div>
                </div>
              </div>

              <!-- Encabezado de la plantilla de mensaje -->
              <app-message-template-header *ngIf="whatsAppMessageTemplate"
                [whatsAppMessageTemplate]="whatsAppMessageTemplate"
                (onHeaderTextChange)="setHeaderText($event)">
              </app-message-template-header>

              <!-- Cuerpo de la plantilla de mensaje -->
              <app-message-template-body *ngIf="whatsAppMessageTemplate"
                [whatsAppMessageTemplate]="whatsAppMessageTemplate"
                (onBodyTextChange)="setBodyText($event)">
              </app-message-template-body>

              <!-- Pie de la plantilla de mensaje -->
              <app-message-template-footer *ngIf="whatsAppMessageTemplate"
                [whatsAppMessageTemplate]="whatsAppMessageTemplate"
                (onFooterTextChange)="setFooterText($event)">
              </app-message-template-footer>

              <!-- Botones de la plantilla de mensaje -->
              <app-message-template-buttons *ngIf="whatsAppMessageTemplate"
                [whatsAppMessageTemplate]="whatsAppMessageTemplate"
                [callToActionButtons]="callToActionButtons"
                [customButtons]="customButtons">
              </app-message-template-buttons>

              <!-- Botones de acciones del formulario -->
              <div class="row g-3 justify-content-end">
                <div class="col-auto">
                  <button
                    class="btn btn-secondary"
                    type="button"
                    (click)="closeSaveMessageTemplate()">
                    Cancelar
                  </button>
                </div>
                <div class="col-auto">
                  <button
                    type="button"
                    (click)="deleteMessageTemplate(whatsAppMessageTemplate)"
                    class="btn btn-danger">
                    <i class="fa fa-trash me-1"></i>
                    Eliminar
                  </button>
                </div>
                <div class="col-auto">
                  <button
                    type="submit"
                    class="btn btn-primary"
                    [disabled]="messageTemplateForm.invalid">
                    <i class="fa fa-save me-1"></i>
                    Guardar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- Previsualización de la plantilla -->
      <div class="col-xl-3 col-sm-12">
        <div class="card shadow-none border border-300 whatsapp-message-template-preview">
          <div class="card-body scrollbar p-1">
            <app-chat-preview-message-template
              [whatsAppMessageTemplate]="whatsAppMessageTemplate">
            </app-chat-preview-message-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
