<div *ngIf="message.customerMessage" class="
    mb-1 
    received-message-content 
    border rounded-2 
    p-3">
  <ng-container [ngTemplateOutlet]="documentTemplate"></ng-container>
  <div class="scrollbar mt-3 pe-2" *ngIf="message.fileMediaCaption">
    <p class="mb-0">
      {{ message.fileMediaCaption }}
    </p>
  </div>
  <span class="reaction-emoji" *ngIf="message.reactionEmoji">
    {{ message.reactionEmoji }}
  </span>
</div>

<div *ngIf="!message.customerMessage" class="
    mb-1 
    sent-message-content 
    light 
    bg-primary 
    rounded-2 
    p-3 
    text-white">
  <ng-container [ngTemplateOutlet]="documentTemplate"></ng-container>
  <span class="reaction-emoji" *ngIf="message.reactionEmoji">
    {{ message.reactionEmoji }}
  </span>
</div>

<ng-template #documentTemplate>
  <app-chat-context-message
    *ngIf="message.contextMessage"
    [message]="message.contextMessage"
    [chat]="chat">
  </app-chat-context-message>
  <div class="d-flex align-items-center justify-content-between">
    <a class="text-decoration-none d-flex align-items-center" 
      role="button" [routerLink]="" 
      (click)="downloadFile()">
      <div 
        class="btn-icon btn-icon-lg border border-300 rounded-3 flex-column me-2"
        [ngClass]="{
          'text-900': message.customerMessage,
          'text-700 bg-white': !message.customerMessage
        }">
        <span class="fs-0 mb-1 fa-solid" [ngClass]="fileIcon"></span>
        <p class="mb-0 fs--2 fw-bold lh-1">
          {{ fileExtension }}
        </p>
      </div>
      <div class="flex-1">
        <h6 
          class="fw-bold line-clamp-1" 
          [ngClass]="{
            'text-900': message.customerMessage,
            'text-200': !message.customerMessage
          }">
          {{ message.fileName }}
        </h6>
        <div class="d-flex align-items-center lh-1">
          <p class="fs--1 mb-0  fw-semi-bold"
            [ngClass]="{
              'text-900': message.customerMessage,
              'text-200': !message.customerMessage
            }">
            {{ (message.fileSize / 1048576).toFixed(2) }} MB
          </p>
        </div>
      </div>
    </a>
  </div>
</ng-template>