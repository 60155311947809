import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Socket, io } from "socket.io-client";
import { AuthService } from '../auth/auth.service';

export enum SocketNotificationTopic {

  // Chat
  MessageReceivedEventTopic = 'message-received-event',
  MessageStatusEventTopic = 'message-status-event',
  ChatRequiresAssistanceEventTopic = 'chat-requires-assistance-event',
  ChatUpdatedEventTopic = 'chat-updated-event',

  FunnelStageChangedEventTopic = 'funnel-stage-changed-event',
  FunnelStageAddedEventTopic = 'funnel-stage-added-event',
  FunnelStageRemovedEventTopic = 'funnel-stage-removed-event',
  FunnelAddedEventTopic = 'funnel-added-event',
  FunnelRemovedEventTopic = 'funnel-removed-event',

  // WhatsApp Web
  WhatsAppWebInstanceQRCodeEventTopic = 'whatsapp-web-instance-qr-code',
  WhatsAppWebInstanceStatusEventTopic = 'whatsapp-web-instance-status',

  // Test Smart Assistant
  TestSmartAssistantMessageReceivedEventTopic = 'test-smart-assistant-message-received-event',

  // Contacts Management
  WhatsAppWebExportTopic = 'whatsapp-web-export',
}

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  private socket: Socket = undefined;

  constructor(
    private authService: AuthService,
  ) {

  }

  public getSocket() {

    const user = this.authService.getUser();
    if (!user) return null;

    if (!this.socket || this.socket?.disconnected) {

      this.socket = io(environment.webSocketUrl, {
        query: { 
          shopId: user.shop.id
        }
      });

    }

    return this.socket;
  }

  public disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

}
