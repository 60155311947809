export interface GalleryMediaTypeContent {
  mediaTypes: string[];
}

export enum AppGalleryMediaType {
  Image = 'image',
  Video = 'video',
}

export function getGallerMediaTypeByContentType(mediaType: string): AppGalleryMediaType {
  for (const messageType in galleryMediaTypes) {
    if (galleryMediaTypes[messageType].mediaTypes.includes(mediaType)) {
      return messageType as AppGalleryMediaType;
    }
  }
  return AppGalleryMediaType.Image;
}

export const galleryMediaTypes: Record<AppGalleryMediaType, GalleryMediaTypeContent> = {
  video: {
    mediaTypes: [
      'video/mp4',
      'video/3gp',
    ],
  },
  image: {
    mediaTypes: [
      'image/jpeg',
      'image/png',
      'image/webp',
    ],
  },
};