import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { TokenRequest } from '../../models/auth/token-request.dto';
import { Observable } from 'rxjs';
import { TokenResponse } from '../../models/auth/token-response.dto';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AppReviewService {


  private apiUrl: string = `${environment.apiUrl}/app-review`;

  constructor(
    private http: HttpClient,
  ) {

  }

  saveAppReview(appReview: any): Observable<any> {
    const endpoint = `${this.apiUrl}/save-app-review`;
    return this.http.post<any>(endpoint, appReview);
  }

  updateAppReview(appReview: any): Observable<any> {
    const endpoint = `${this.apiUrl}/update-app-review`;
    return this.http.put<any>(endpoint, appReview);
  }

  deleteAppReview(appReviewId: number): Observable<any> {
    const endpoint = `${this.apiUrl}/delete-app-review/${appReviewId}`;
    return this.http.delete<any>(endpoint);
  }

  getAppReviewsByAppId(appId: number): Observable<any> {
    const endpoint = `${this.apiUrl}/get-reviews/${appId}`;
    return this.http.get<any>(endpoint);
  }

  getAppReviewById(appReviewId: number): Observable<any> {
    const endpoint = `${this.apiUrl}/app-review/${appReviewId}`;
    return this.http.get<any>(endpoint);
  }

  getAppReviews(): Observable<any> {
    const endpoint = `${this.apiUrl}/app-review`;
    return this.http.get<any>(endpoint);
  }

}
