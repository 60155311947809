
<div class="card">
  <div #chatScrollContainer class="card-body chat-content scrollbar position-relative p-3 p-sm-4">
    <div class="d-flex chat-message"
         *ngFor="let testMessage of testMessages">
      <app-test-smart-assistant-message-container
        [ngClass]="{
          'd-flex mb-3 flex-1': true,
          'justify-content-end': testMessage.type === SmartAssistantTopicItemActionType.ChatTag ? false : testMessage.type === SmartAssistantTopicItemActionType.HummanAssitantAttention ? false : (!testMessage?.message?.customerMessage),
        }"
        [testMessage]="testMessage"
        [chat]="chat">
      </app-test-smart-assistant-message-container>
    </div>
  </div>
  <div class="card-footer" *ngIf="!chat?.expired">
    <textarea
      class="form-control chat-textarea outline-none scrollbar border-0 ps-1 mb-2"
      #messageField
      placeHolder="Escribe un mensaje aquí..."
      [formControl]="textAreaFormControl"
      (keydown)="onKeydown($event)"
    ></textarea>
    <div class="d-flex justify-content-end align-items-end mt-2">
      <button class="btn btn-primary fs--2" type="button" (click)="sendMessage()">
        Enviar <span class="fa-solid fa-paper-plane ms-1"></span>
      </button>
    </div>
  </div>
</div>