import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  httpHeaders: HttpHeaders;
  apiUrl: string;

  constructor(protected http: HttpClient) {

    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.apiUrl = `${environment.apiUrl}/media`;
  }

  getObject(url: string): Observable<Blob> {

    const headers = new HttpHeaders({
      'Ignore-Authorization': 'true'
    });

    let blob = this.http.get<Blob>(url, {
      headers: headers,
      responseType: 'blob' as 'json'
    });

    return blob;
  }

  uploadMedia(signedUrl: string, file: any) {

    const headers = new HttpHeaders({
      'Content-Type': file.type,
      'Ignore-Authorization': 'true'
    });

    return this.http.put<any>(signedUrl, file, {
      headers: headers
    });
  }

}
