// Angular
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

// NgBootstrap
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

// Toastr
import { ToastrService } from 'ngx-toastr';

// RxJS
import {
  debounceTime,
  distinctUntilChanged, filter, map, merge, Observable,
  OperatorFunction, Subject,
  Subscription,
} from 'rxjs';

// Chat Models
import { ChatInfo } from '@app/models/chat/chat/chat-info.dto';

import { UserInfo } from '@app/models/account/user/user.info.dto';

// Services
import { AuthService } from '@app/services/auth/auth.service';
import { ChatTagInfo } from '@models/chat/tag/chat-tag-info.dto';

import 'moment/locale/es';
import { ChatSearchDangeRangeFieldType } from '@type/chat/chat-search-dange-range-field.type';
import { BoardInfo } from '@models/utilities/board/board-info.dto';
import { BoardColumnInfo } from '@models/utilities/board/column/board-column-info.dto';
import { KanbanService } from '@services/utilities/board.service';
import { Page } from '@models/common/page';
import { BoardSearchRequest } from '@models/utilities/board/board-search-request.dto';
import { HttpErrorResponse } from '@angular/common/http';
import { SaveBoardRequest } from '@models/utilities/board/save-board-request.dto';
import { SaveBoardColumnRequest } from '@models/utilities/board/column/save-board-column-request.dto';
import { UserSearchRequest } from '@models/account/user/user-search-request.dto';
import { UserService } from '@services/account/user.service';
import { ChatTagSearchRequest } from '@models/chat/tag/chat-tag-search-request.dto';
import { ChatTagService } from '@services/chat/chat-tag.service';
import { ChatTagSearchType } from '@type/chat/chat-tag-search.type';
import { getLastMessageTypeLabel } from '@app/utils/chat-utils';
import {
  ToggleBoardColumnCollapsedRequest,
} from '@app/models//utilities/board/column/toggle-board-column-collapsed-request.dto';
import { GetBoardColumnChatsRequest } from '@app/models//utilities/board/column/get-board-column-chats-request.dto';
import { EventEmitterService, NotificationTopic } from '@services/data/event-emitter.service';
import { ChatService } from '@services/chat/chat.service';
import { SpinnerService } from '@services/data/spinner.service';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.css'],
})
export class BoardComponent implements OnInit, AfterViewInit, OnDestroy {

  public readonly ChatSearchDangeRangeFieldType = ChatSearchDangeRangeFieldType;
  public readonly ChatTagSearchType = ChatTagSearchType;

  // Lists
  public boards: BoardInfo[] = [];

  // Selections
  public creatingBoard: boolean = false;
  public selectedBoard: BoardInfo;
  public selectedBoardColumn: BoardColumnInfo;

  // Forms
  public boardForm: FormGroup;
  public boardColumnForm: FormGroup;

  // Save Requests
  public saveBoardColumnRequest: SaveBoardColumnRequest;

  private currentUser: UserInfo;
  private componentSubscriptions: Subscription[] = [];

  public selectedChat: ChatInfo;
  public savingBoardColumn: boolean = false;

  public firstLoad: boolean = true;

  // Local data
  public users: UserInfo[] = [];
  private defaultUsersPageSize: number = -1;

  // Users
  public usersAll: UserInfo[] = [];
  public usersAvailable: UserInfo[] = [];
  public usersChosen: UserInfo[] = [];
  // Users

  // Chat Tags
  @ViewChild('instance', { static: true }) instance: any;
  public focus$ = new Subject<string>();
  public click$ = new Subject<string>();
  public formatResult = (value: ChatTagInfo) => value.name;
  public formatInput = (value: ChatTagInfo) => value.name;
  public tagsFormControl: FormControl;
  public chatTagsAll: ChatTagInfo[] = [];
  public chatTagsAvailable: ChatTagInfo[] = [];
  public chatTagsChosen: ChatTagInfo[] = [];
  // Chat Tags

  // Dates
  public currentDate: Date = new Date();
  public maxDate: Date = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
  private dateRangeInstance: any;
  public dateRangeValue: any = {};
  // Dates

  // OffCanvas
  @ViewChild('saveBoardOffCanvas')
  public saveBoardOffCanvas: TemplateRef<any>;

  @ViewChild('saveBoardColumnOffCanvas')
  public saveBoardColumnOffCanvas: TemplateRef<any>;

  @ViewChild('boardColumnChatModal')
  public boardColumnChatModal: TemplateRef<any>;

  // Modals
  @ViewChild('chatModal')
  private chatModal: TemplateRef<any>;

  constructor(
    private eventEmitterService: EventEmitterService,
    private authService: AuthService,
    private chatService: ChatService,
    private boardService: KanbanService,
    private userService: UserService,
    private chatTagService: ChatTagService,
    private offCanvasService: NgbOffcanvas,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private spinnerService: SpinnerService,
  ) {
    this.currentUser = this.authService.getUser();
  }

  ngOnInit() {
    this.initializeOnChatUpdatedEvent();
    this.initializeOnMessageReceivedEvent();
    this.initializeBoardColumnForm();
    this.initializeBoardForm();
    this.initializeBoardColumnSaveRequest();
    this.loadBoards();
    this.loadUsers();
    this.loadChatTags();
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.componentSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  private initializeOnChatUpdatedEvent() {

    const eventEmitter = this.eventEmitterService.getEventEmitter(NotificationTopic.ChatUpdated);
    const subscription = eventEmitter.subscribe((chat: ChatInfo) => this.manageChatUpdateEvent(chat));
    this.componentSubscriptions.push(subscription);

  }

  private initializeOnMessageReceivedEvent() {

    const eventEmitter = this.eventEmitterService.getEventEmitter(NotificationTopic.MessageReceived);

    const subscription = eventEmitter.subscribe((data: any) => {
      const { message, chat } = data;

      for (const boardColumn of this.selectedBoard.columns) {
        const chats = boardColumn.chats.content;
        const messageChat = chats.find(c => c.id === message.chatId);
        const messageChatIndex = chats.findIndex(c => c.id === message.chatId);

        if (messageChatIndex !== -1) {
          if (this.validateChat(messageChat, boardColumn)) {
            // Actualiza la información del último mensaje en la conversación encontrada
            messageChat.lastMessageContent = message.content;
            messageChat.lastMessageFileName = message.fileName;
            messageChat.lastMessageFileUrl = message.fileUrl;
            messageChat.lastMessageType = message.type;
            messageChat.lastMessageStatus = message.status;
            messageChat.lastMessageSentTime = message.sentTime;
            messageChat.lastMessageTypeLabel = getLastMessageTypeLabel(messageChat);

            // Mueve la conversación hacia arriba si no está en la parte superior
            if (messageChatIndex !== 0) {
              chats.splice(messageChatIndex, 1);
              chats.unshift(messageChat);
            }
          } else {
            chats.splice(messageChatIndex, 1); // Elimina el chat si no es válido
            boardColumn.chats.totalElements--;
          }
        } else {
          chat.isReceivingMessage = true;  // Marcar temporalmente el chat como que está recibiendo un mensaje
          if (this.validateChat(chat, boardColumn)) {
            chats.unshift(chat);
            boardColumn.chats.totalElements++;
          }
          chat.isReceivingMessage = false;  // Resetear el flag
        }
      }

    });

    this.componentSubscriptions.push(subscription);
  }

  private initializeBoardForm() {
    this.boardForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
    });
  }

  private initializeBoardColumnForm() {
    this.boardColumnForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      user: new FormControl(''),
    });
  }

  private initializeBoardColumnSaveRequest() {
    this.saveBoardColumnRequest = new SaveBoardColumnRequest();
    this.saveBoardColumnRequest.dateRangeFieldType = ChatSearchDangeRangeFieldType.LastMessageDate;
    this.saveBoardColumnRequest.chatTagSearchType = ChatTagSearchType.AtLeastOne;
  }

  private loadUsers() {

    const userSearchRequest = new UserSearchRequest(
      this.currentUser.shop.id, this.defaultUsersPageSize,
    );

    this.userService.searchUsers(userSearchRequest).subscribe({
      next: (page: Page<UserInfo>) => {
        this.usersAll = [...page.content];
        this.usersAvailable = page.content;
      },
      error: (error: any) => {
        console.error('Error en la carga de chats:', error);
      },
      complete: () => {
      },
    });
  }

  private loadChatTags(callback?: () => void) {

    const infiniteSize: number = -1;

    const searchRequest = new ChatTagSearchRequest(
      this.currentUser.shop.id,
      infiniteSize,
    );

    this.chatTagService.searchChatTags(searchRequest).subscribe({
      next: (chatTagsPage: Page<ChatTagInfo>) => {
        this.chatTagsAll = [...chatTagsPage.content];
        this.chatTagsAvailable = chatTagsPage.content;
        callback && callback();
      },
      error: (error: any) => {
        console.error('Error en la carga de broadcasts:', error);
      },
      complete: () => {

      },
    });

    this.initializeChatTagsChosenEvent();
  }

  private loadBoards() {

    const boardSearch = new BoardSearchRequest(
      this.currentUser.shop.id, -1,
    );

    this.boardService.searchBoards(boardSearch).subscribe({
      next: (page: Page<BoardInfo>) => {
        this.boards = page.content;
        if (this.firstLoad) this.firstLoad = false;
      },
      error: (error: any) => {
        console.error('Error en la carga de tags:', error);
      },
    });
  }

  private getBoardColumnChats(boardColumn: BoardColumnInfo, page?: number) {

    let pageNumber = page;

    if (page === undefined) {
      let { totalPages } = boardColumn.chats;
      pageNumber = boardColumn.chats.pageNumber;
      if ((pageNumber + 1) === totalPages) return;
      ++pageNumber;
    }

    const shopId = this.selectedBoard.shopId;

    const getBoardColumnChatsRequest = new GetBoardColumnChatsRequest(
      shopId, boardColumn.id, pageNumber, 20,
    );

    this.boardService.getBoardColumnChats(getBoardColumnChatsRequest).subscribe({
      next: (page: Page<ChatInfo>) => {
        if (pageNumber > 0) {
          const currentChats = [...boardColumn.chats.content];
          boardColumn.chats = page;
          boardColumn.chats.content = [...currentChats, ...page.content];
        } else {
          boardColumn.chats = page;
        }
        this.updateBoardColumnChats(boardColumn);
      },
      error: (error: any) => {
        console.error('Error en la carga de board board column chats:', error);
      },
      complete: () => {
      },
    });
  }

  public openSaveBoard(board?: BoardInfo) {

    if (board) {
      this.boardForm.get('name').setValue(board.name);
    } else {
      this.creatingBoard = true;
    }

    const offCanvasRef = this.offCanvasService.open(
      this.saveBoardOffCanvas, { position: 'end' },
    );

    if (this.selectedBoard && this.selectedBoard.id === board.id) return;

    offCanvasRef.hidden.subscribe(() => {
      this.initializeBoardForm();
      this.selectedBoardColumn = undefined;
      this.creatingBoard = false;
    });

  }

  public saveBoard() {

    const { name } = this.boardForm.value;

    let smartAssistantId: number = null;
    if (!this.creatingBoard) smartAssistantId = this.selectedBoard.id;

    const saveBoardRequest = new SaveBoardRequest(
      smartAssistantId, name.trim(), this.currentUser.shop.id,
    );

    this.boardService.saveBoard(saveBoardRequest).subscribe({
      next: (savedBoard: BoardInfo) => {

        this.selectedBoard = savedBoard;

        const boardIndex = this.boards.findIndex((kb: BoardInfo) => kb.id === savedBoard.id);
        if (boardIndex === -1) {
          this.boards.unshift(savedBoard);
        } else {
          this.boards[boardIndex] = savedBoard;
        }

        this.offCanvasService.dismiss();
        this.toastr.success('Board creado exitosamente');
      },
      error: (httpErrorResponse: HttpErrorResponse) => {
        const { error } = httpErrorResponse;
        const errorCode = error.code;
        if (errorCode === 'DUPLICATED_KANBAN_BOARD') {
          this.toastr.error('Ya existe un board con ese nombre, por favor elija otro.');
        } else {
          this.toastr.error('Error al crear el board');
        }
      },
      complete: () => {
      },
    });

  }

  public deleteBoard() {

    const board = this.selectedBoard;

    if (!confirm('¿Está seguro que desea eliminar este tablero?')) return;

    this.boardService.deleteBoard(board.id).subscribe({
      next: () => {

        this.selectedBoard = undefined;

        const boardIndex = this.boards.findIndex((kb: BoardInfo) => kb.id === board.id);
        this.boards.splice(boardIndex, 1);

        this.toastr.success('Board eliminado exitosamente');
        this.offCanvasService.dismiss();
      },
      error: (httpErrorResponse: HttpErrorResponse) => {
        const { error } = httpErrorResponse;
        const errorCode = error.code;
        console.log('errorCode', errorCode);
        this.toastr.error('Error al eliminar el board');
      },
      complete: () => {
      },
    });

  }

  public openBoard(board: BoardInfo) {

    if (this.selectedBoard && this.selectedBoard.id === board.id) return;

    this.getBoard(board.id);
  }

  private getBoard(id: number) {

    this.spinnerService.show('Cargando board...');

    this.boardService.getBoard(id).subscribe({
      next: (board: BoardInfo) => {

        this.selectedBoard = board;

        for (const boardColumn of board.columns) {
          this.updateBoardColumnChats(boardColumn);
        }

        this.setBoardCollapsable();

        this.spinnerService.hide();
      },
      error: (error: any) => {
        console.error('Error en la carga de board board:', error);
      },
      complete: () => {
      },
    });
  }

  manageChatUpdateEvent(chat: ChatInfo) {

    for (const boardColumn of this.selectedBoard.columns) {

      const chats = boardColumn.chats.content;
      const chatIndex = chats.findIndex(c => c.id === chat.id);

      if (chatIndex !== -1) {
        if (this.validateChat(chat, boardColumn)) {
          chats[chatIndex] = chat;
        } else {
          chats.splice(chatIndex, 1);
          boardColumn.chats.totalElements--;
        }
      } else {
        if (this.validateChat(chat, boardColumn)) {
          chats.unshift(chat);
          boardColumn.chats.totalElements++;
        }
      }
    }

  }

  private validateChat(chat: ChatInfo, boardColumn: BoardColumnInfo): any {
    const { chatTags, users, chatTagSearchType, dateRangeFieldType, toDate, fromDate } = boardColumn;
    chat.lastMessageTypeLabel = getLastMessageTypeLabel(chat);

    // Filtro de tags
    if (chatTags.length > 0 && chat?.chatTags.length > 0) {
      switch (chatTagSearchType) {
        // Verificar si al menos un tag coincide
        case ChatTagSearchType.AtLeastOne:
          return chat.chatTags.some(tag1 => chatTags.some(tag2 => tag1.id === tag2.id));

        // Verificar si todos los tags están presentes en `chatTags`
        case ChatTagSearchType.IncludeAll:
          return chatTags.every(tag1 => chat.chatTags.some(tag2 => tag1.id === tag2.id));

        // Verificar coincidencia exacta entre los tags en cantidad y contenido
        case ChatTagSearchType.IncludeAllStrict:
          return chatTags.length === chat.chatTags.length &&
            chatTags.every(tag1 => chat.chatTags.some(tag2 => tag1.id === tag2.id));


        // Caso por defecto si el tipo de búsqueda no coincide con ninguno de los casos
        default:
          return false;
      }
    }

    // Filtro de usuarios
    if (users.length > 0) {
      return users.some(user => user.id === chat.userId);
    }

    // Filtro de fecha o rango de fechas segun su tipo
    //Validar fromDate y toDate
    if (fromDate > 0 && toDate > 0) {
      if (dateRangeFieldType === ChatSearchDangeRangeFieldType.LastMessageDate) {
        return chat.lastMessageSentTime >= fromDate && chat.lastMessageSentTime <= toDate;
      } else if (dateRangeFieldType === ChatSearchDangeRangeFieldType.CreationDate) {
        return chat.createdAt >= fromDate && chat.createdAt <= toDate;
      }
    }

    if (boardColumn.notSeen ) {
      return !chat.seen || chat.isReceivingMessage;  // Devuelve true si el chat no ha sido visto
    }

    // Filtro de mensajes no respondidos
    if (boardColumn.unanswered) {
      return !chat.answered;  // Devuelve true si el chat no ha sido respondido
    }

    // Filtro de mensajes archivados
    if (boardColumn.archived) {
      return chat.archived;  // Devuelve true si el chat está archivado
    }

    return true;
  }


  updateBoardColumnChats(boardColumn: BoardColumnInfo) {
    for (const chat of boardColumn.chats.content) {
      chat.lastMessageTypeLabel = getLastMessageTypeLabel(chat);
    }
  }

  private setBoardCollapsable() {
    setTimeout(() => {
      (window as any).phoenix.initEverything();
    }, 1000);
  }

  public openSaveBoardColumn(boardColumn?: BoardColumnInfo) {

    this.saveBoardColumnRequest.boardId = this.selectedBoard.id;

    if (boardColumn) {
      this.selectedBoardColumn = boardColumn;
      this.setBoardColumnFormValues(boardColumn);
    }

    const offCanvasRef = this.offCanvasService.open(
      this.saveBoardColumnOffCanvas,
      {
        position: 'end',
      },
    );

    offCanvasRef.shown.subscribe(() => {

      const input = document.getElementById('boardColumnName');
      input.focus();

    });

    offCanvasRef.hidden.subscribe(() => {
      this.selectedBoardColumn = undefined;
      this.resetAll();
    });

  }

  private setBoardColumnFormValues(boardColumn: BoardColumnInfo) {

    this.boardColumnForm.get('name').setValue(boardColumn.name);
    this.boardColumnForm.get('user').setValue('');

    if (boardColumn.users?.length > 0)
      for (const user of boardColumn.users) {
        this.addUserChosen(user.id);
      }

    if (boardColumn.chatTags?.length > 0)
      for (const chatTag of boardColumn.chatTags) {
        this.addChatTagChosen(chatTag);
      }

    this.saveBoardColumnRequest.id = boardColumn.id;
    this.saveBoardColumnRequest.name = boardColumn.name;
    this.saveBoardColumnRequest.boardId = boardColumn.boardId;
    this.saveBoardColumnRequest.fromDate = boardColumn.fromDate;
    this.saveBoardColumnRequest.toDate = boardColumn.toDate;
    this.saveBoardColumnRequest.dateRangeFieldType = boardColumn.dateRangeFieldType;
    this.saveBoardColumnRequest.chatTagSearchType = boardColumn.chatTagSearchType;
    this.saveBoardColumnRequest.sortDirection = boardColumn.sortDirection;
    this.saveBoardColumnRequest.notSeen = boardColumn.notSeen;
    this.saveBoardColumnRequest.unanswered = boardColumn.unanswered;
    this.saveBoardColumnRequest.archived = boardColumn.archived;

  }

  public deleteBoardColumn(boardColumn: BoardColumnInfo) {

    if (!confirm('¿Está seguro que desea eliminar este tópico?')) return;

    this.boardService.deleteBoardColumn(boardColumn.id).subscribe({
      next: () => {

        const columnIndex = this.selectedBoard.columns.findIndex(c => c.id === boardColumn.id);
        this.selectedBoard.columns.splice(columnIndex, 1);

        this.toastr.success('Columna eliminada exitosamente');
      },
      error: (httpErrorResponse: HttpErrorResponse) => {
        const { error } = httpErrorResponse;
        const errorCode = error.code;
        console.log('errorCode', errorCode);
        this.toastr.error('Error al eliminar la columna');
      },
      complete: () => {
      },
    });

  }

  public refreshBoardColumnChats(boardColumn: BoardColumnInfo) {
    this.getBoardColumnChats(boardColumn, 0);
  }

  public toggleBoardColumnCollapse(boardColumn: BoardColumnInfo) {
    boardColumn.collapsed = !boardColumn.collapsed;
    const toggleBoardColumnCollapsedRequest = new ToggleBoardColumnCollapsedRequest(
      boardColumn.id, boardColumn.collapsed,
    );
    this.boardService.toggleBoardColumnCollapse(toggleBoardColumnCollapsedRequest).subscribe({
      next: () => {

      },
      error: (error: any) => {
        console.error('Error al colapsar columna', error);
      },
      complete: () => {
      },
    });
  }

  public openBoardColumnChat(event: any, boardColumn: BoardColumnInfo, chat: ChatInfo) {

    this.selectedChat = chat;

    const modalRef = this.modalService.open(this.chatModal, {
      size: 'xl',
      centered: true,
    });

    modalRef.hidden.subscribe(() => {

    });

  }

  public toggleNotSeen() {
    this.saveBoardColumnRequest.notSeen = !this.saveBoardColumnRequest.notSeen;
  }

  public toggleUnanswered() {
    this.saveBoardColumnRequest.unanswered = !this.saveBoardColumnRequest.unanswered;
  }

  public toggleArchived() {
    this.saveBoardColumnRequest.archived = !this.saveBoardColumnRequest.archived;
  }


  public saveBoardColumn() {

    if (this.boardColumnForm.valid) {

      this.savingBoardColumn = true;
      this.boardColumnForm.disable();

      const { name } = this.boardColumnForm.value;

      this.saveBoardColumnRequest.name = name.trim();

      // Updating broadcast
      if (this.selectedBoardColumn) {
        this.saveBoardColumnRequest.id = this.selectedBoardColumn.id;
      }

      this.boardService.saveBoardColumn(this.saveBoardColumnRequest)
        .subscribe({
          next: (savedBoardColumn: BoardColumnInfo) => {
            setTimeout(() => {

              this.updateBoardColumnChats(savedBoardColumn);

              if (this.selectedBoardColumn) {
                Object.assign(this.selectedBoardColumn, savedBoardColumn);
              } else {
                this.selectedBoard.columns.push(savedBoardColumn);
              }

              this.savingBoardColumn = false;
              this.offCanvasService.dismiss();
              this.resetAll();
              this.toastr.success('Board guardado con éxito');
              // this.setBoardCollapsable();
            }, 10);
          },
          error: (error: any) => {
            this.savingBoardColumn = false;
            const errorMessage = 'Error al guardar el board';
            this.toastr.error(errorMessage);
            // this.errorCode = '';
            console.error(errorMessage, error);
          },
          complete: () => {

          },
        });
    }

  }

  resetAll() {
    this.initializeBoardColumnForm();
    this.initializeBoardColumnSaveRequest();
    this.dateRangeValue = {};
    this.selectedBoardColumn = undefined;
    this.chatTagsAvailable = [...this.chatTagsAll];
    this.usersAvailable = [...this.usersAll];
    this.usersChosen = [];
    this.chatTagsChosen = [];
  }

  public onUserChosen(event: any) {
    const userId: number = Number(event.target.value);
    this.addUserChosen(userId);
  }

  private addUserChosen(userId: number) {

    const selectedUser: UserInfo = this.usersAvailable.find(u => u.id === userId);
    const userIndex: number = this.usersAvailable.indexOf(selectedUser);

    this.usersChosen.push(selectedUser);
    this.saveBoardColumnRequest.userIds.push(userId);

    this.usersAvailable.splice(userIndex, 1);
    this.usersAvailable.sort((u1, u2) => u2.id - u1.id);

    this.boardColumnForm.get('user').setValue('');
  }

  removeUserChosen(user: UserInfo) {

    const userChosenIndex = this.usersChosen.indexOf(user);
    const userChosenSaveRequestIndex = this.saveBoardColumnRequest.userIds.findIndex((id: number) => id === user.id);

    this.usersChosen.splice(userChosenIndex, 1);
    this.saveBoardColumnRequest.userIds.splice(userChosenSaveRequestIndex, 1);

    this.usersAvailable.push(user);
    this.usersAvailable.sort((u1, u2) => u2.id - u1.id);

    this.boardColumnForm.get('user').setValue('');
  }

  initializeChatTagsChosenEvent() {

    this.tagsFormControl = new FormControl('');

    this.tagsFormControl.valueChanges.subscribe({
      next: (selectedChatTag: ChatTagInfo) => {
        if (selectedChatTag) {
          this.addChatTagChosen(selectedChatTag);
        }
      },
    });
  }

  addChatTagChosen(chatTag: ChatTagInfo) {

    const chatTagChosenIndex = this.chatTagsAvailable.findIndex(ct => ct.id === chatTag.id);
    this.chatTagsChosen.push(chatTag);
    this.saveBoardColumnRequest.chatTagIds.push(chatTag.id);

    this.chatTagsAvailable.splice(chatTagChosenIndex, 1);
    this.chatTagsAvailable.sort((ct1, ct2) => ct2.id - ct1.id);

    this.tagsFormControl.setValue(null);
  }

  removeChatTag(chatTag: ChatTagInfo) {

    const chatTagChosenIndex = this.chatTagsChosen.indexOf(chatTag);
    const chatTagChosenSaveRequestIndex = this.saveBoardColumnRequest.chatTagIds.findIndex((id: number) => id === chatTag.id);

    this.chatTagsChosen.splice(chatTagChosenIndex, 1);
    this.saveBoardColumnRequest.chatTagIds.splice(chatTagChosenSaveRequestIndex, 1);

    this.chatTagsAvailable.push(chatTag);
    this.chatTagsAvailable.sort((ct1, ct2) => ct2.id - ct1.id);
  }

  searchChatTags: OperatorFunction<string, readonly ChatTagInfo[]> = (
    text$: Observable<string>,
  ) => {
    const debouncedText$ = text$.pipe(debounceTime(1), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance?.isPopupOpen()),
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term: string) => {
        if (term === '') return this.chatTagsAvailable;
        return this.chatTagsAvailable.filter((ct: ChatTagInfo) => ct.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10);
      }),
    );
  };

  public changeChatTagSearchType(chatTagSearchType: ChatTagSearchType) {
    this.saveBoardColumnRequest.chatTagSearchType = chatTagSearchType;
  }

  public dateRangeReady(event: any) {
    this.dateRangeInstance = event.instance;
    if (this.selectedBoardColumn) {
      const { fromDate, toDate } = this.selectedBoardColumn;
      if (fromDate) {
        const startDate = new Date(fromDate);
        this.saveBoardColumnRequest.fromDate = startDate.getTime();
        if (toDate) {
          const endDate = new Date(toDate);
          this.dateRangeInstance.setDate([startDate, endDate]);
          this.saveBoardColumnRequest.toDate = endDate.getTime();
        } else {
          const endDate = new Date(fromDate);
          this.dateRangeInstance.setDate([startDate, endDate]);
          this.saveBoardColumnRequest.toDate = endDate.getTime();
        }
      }
    }
  }

  public setSelectedRangeDate(value: any) {

    const { selectedDates } = value;
    const startDate = selectedDates[0];
    const endDate = selectedDates[1];

    if (!startDate || !endDate) return;

    if (endDate.getDate() !== this.currentDate.getDate()) {
      endDate.setHours(23, 59, 59, 0);
    } else {
      endDate.setHours(this.currentDate.getHours(), this.currentDate.getMinutes(), 0, 0);
    }

    this.dateRangeInstance.setDate([startDate, endDate]);

    this.saveBoardColumnRequest.fromDate = startDate.getTime();
    this.saveBoardColumnRequest.toDate = endDate.getTime();

    this.dateRangeValue = {
      from: startDate,
      to: endDate,
    };

  }

  public changeDateRangeFieldType(dateRangeFieldType: ChatSearchDangeRangeFieldType) {
    this.saveBoardColumnRequest.dateRangeFieldType = dateRangeFieldType;
  }

  public clearDateRange() {

    this.dateRangeValue = {};
    this.dateRangeInstance.setDate([]);

    this.saveBoardColumnRequest.fromDate = null;
    this.saveBoardColumnRequest.toDate = null;
    this.saveBoardColumnRequest.dateRangeFieldType = ChatSearchDangeRangeFieldType.LastMessageDate;
  }

  public onScrolled(boardColumn: BoardColumnInfo) {
    this.getBoardColumnChats(boardColumn);
  }

}
