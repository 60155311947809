import { WhatsAppMessageTemplateButtonType } from "./type/whatsapp-message-template-button.type";

export class WhatsAppMessageTemplateButton {

  type: WhatsAppMessageTemplateButtonType;
  text: string;
  phone_number: string;
  url: string;
  example: any;

  // Angular
  parameter: any;

}