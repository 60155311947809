import { SubscriptionType } from '../../type/subscription/subscription.type';

export class GetStripeCheckoutSessionRequest {

  shopId: number;
  subscriptionType: SubscriptionType;

  constructor(shopId: number, subscriptionType: SubscriptionType) {
    this.shopId = shopId;
    this.subscriptionType = subscriptionType;
  }

}