
import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageInfo } from '@models/chat/message/message-info.dto';
import { EventEmitterService } from '@services/data/event-emitter.service';
import { ChatInfo } from '@models/chat/chat/chat-info.dto';

export const regexLink: RegExp = /(((https?:\/\/)|(www\.))[-a-zA-Z0-9@:%.\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9_@:%\+.~#?&/=]*))/g;
export const linkReplacement: string = '<a href=\"$1\" target=\"_blank\" class=\"dark-url\">$1</a>';

@Component({
  selector: 'app-chat-interactive-message',
  templateUrl: './interactive-message.component.html',
  styleUrls: ['./interactive-message.component.css']
})
export class InteractiveMessageComponent implements OnInit, AfterViewInit, OnDestroy {

  public regexLink: RegExp = regexLink;
  public buttons: any[];

  @Input()
  public message: MessageInfo;

  @Input()
  public chat: ChatInfo;

  private componentSubscriptions: Subscription[] = [];

  constructor(
    private eventEmitterService: EventEmitterService,
  ) {
  }

  ngOnInit() {
    this.replaceLink();
    this.buttons = JSON.parse(this.message.interactiveButtons);
  }

  ngAfterViewInit() {
    
  }

  ngOnDestroy() {
    this.componentSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  public replaceLink() {
    if (!this.message.content) {
      this.message.content = ''
      return;
    }
    this.message.content = this.message.content.replace(this.regexLink, linkReplacement);
    if (this.message.customerMessage) {
      this.message.content = this.message.content.replaceAll('dark-url', '');
    }
  }
  
}
