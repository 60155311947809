
export class ChangeSmartAssistantOrchestratorStatusRequest {

  smartAssistantOrchestratorId: number;
  enabled: boolean;

  constructor(smartAssistantOrchestratorId: number, enabled: boolean) {
    this.smartAssistantOrchestratorId = smartAssistantOrchestratorId;
    this.enabled = enabled;
  }

}