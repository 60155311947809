import { Component } from '@angular/core';

@Component({
  selector: 'app-whatsapp-bubble',
  templateUrl: './whatsapp-bubble.component.html',
  styleUrls: ['./whatsapp-bubble.component.css']
})
export class WhatsAppBubbleComponent {

}
