
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Subscription } from 'rxjs';
import { MessageInfo } from '@app/models/chat/message/message-info.dto';
import { EventEmitterService } from '@app/services/data/event-emitter.service';
import { ChatInfo } from "../../../../../models/chat/chat/chat-info.dto";

@Component({
  selector: 'app-chat-video-message',
  templateUrl: './video-message.component.html',
  styleUrls: ['./video-message.component.css']
})
export class VideoMessageComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input()
  public message: MessageInfo;

  @Input()
  public chat: ChatInfo;

  @Output()
  public videoLoaded: EventEmitter<void> = new EventEmitter<void>();

  private componentSubscriptions: Subscription[] = [];

  constructor(
    private eventEmitterService: EventEmitterService,
  ) {

  }

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    
  }

  ngOnDestroy() {
    this.componentSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  public onVideoLoad() {
    this.videoLoaded.emit();
  }
  
}
