
const defaultValues = {
  responsive: true,
  language: {
    lengthMenu: 'Mostrar _MENU_ registros',
    info: '_START_ al _END_ de un total de _TOTAL_ registros',
    paginate: {
      first: "Primero",
      last: "Último",
      next: "Siguiente",
      previous: "Anterior"
    }
  },
  lengthMenu: [5, 10, 15],
  ordering: false,
  processing: false,
  serverSide: true,
  info: true,
  searching: false,
  lengthChange: true,
  columnDefs: [
    {
      targets: '_all',
      orderable: false,
      searchable: false
    }
  ]
};

export const TagsTableOptions = (pageLength: number) => {
  const options: any = {...defaultValues};
  options.pageLength = pageLength;
  options.lengthMenu = [5, 10, 15];
  return options;
}

export const ChatsTableOptions = (pageLength: number) => {
  const options: any = {...defaultValues};
  options.pageLength = pageLength;
  options.lengthMenu = [5, 10, 15];
  return options;
}
