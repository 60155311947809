import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Uppy, { UppyFile } from '@uppy/core';
import Dashboard, { DashboardOptions } from '@uppy/dashboard';
import { UppyLocaleValues } from '../../../constants/uppy-constants';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import AwsS3 from '@uppy/aws-s3';
import { GetSignedUrlRequest } from '../../../models/media/get-signed-url-request.dto';
import { lastValueFrom } from 'rxjs';
import Audio from '@uppy/audio';
import ScreenCapture from '@uppy/screen-capture';
import { AppCategoryType } from '../../../type/marketplace/app-category.type';
import { UserInfo } from '../../../models/account/user/user.info.dto';
import { AuthService } from '../../../services/auth/auth.service';
import { MessageService } from '../../../services/chat/message.service';
import { getMessageTypeByMediaType } from '../../../type/chat/message-content.type';
import { QuickReplyItemRequest } from '../../../models/chat/quick-reply/item/quick-reply-item-request.dto';
import { AppDetailGalleryRequest } from '../../../models/marketplace/app-detail-gallery-request.dto';
import { getGallerMediaTypeByContentType } from '../../../type/marketplace/app-gallery-media.type';
import { AppPluginRequest } from '../../../models/marketplace/app-plugin-request.dto';

@Component({
  selector: 'app-marketplace-management',
  templateUrl: './marketplace-management.component.html',
  styleUrls: ['./marketplace-management.component.css'],
})
export class MarketplaceManagementComponent implements OnInit {

  public showView: string;

  constructor() {
  }

  ngOnInit() {
  }

  public changeView(view: string) {
    this.showView = view;
  }

}
