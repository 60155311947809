import { SubscriptionType } from "../../type/subscription/subscription.type";

export class SignUpRequest {
  
  constructor(
    public name: string,
    public username: string,
    public password: string,
    public shopName: string,
    public subscriptionType: SubscriptionType,
  ) {

  }
  
}