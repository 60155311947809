import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  SmartAssistantTopicItemActionInfo,
} from '@models/utilities/smart-assistant/topic-item-action/smart-assistant-topic-item-action-info.dto';
import { EventEmitterService, NotificationTopic } from '@services/data/event-emitter.service';
import { getTypeByValue, getTypes } from '@app/utils/enum-utils';
import {
  SmartAssistantTopicItemActionType,
  SmartAssistantTopicItemActionTypeMetadata,
} from '@type/utilities/smart-assistant/smart-assistant-topic-item-action.type';
import { EnumInfo } from '@models/common/enum-info.dto';
import { QuickReplySearchRequest } from '@models/chat/quick-reply/quick-reply-search-request.dto';
import { Page } from '@models/common/page';
import { QuickReplyService } from '@services/chat/quick-reply.service';
import { AuthService } from '@services/auth/auth.service';
import { UserInfo } from '@models/account/user/user.info.dto';
import { QuickReplyInfo } from '@models/chat/quick-reply/quick-reply-info.dto';
import { isMediaMessage, MessageType } from '@type/chat/message-content.type';
import { Subscription } from 'rxjs';
import { normalizeText } from '@app/utils/chat-utils';

import { v4 as uuidv4 } from 'uuid';
import { ChatTagInfo } from '@models/chat/tag/chat-tag-info.dto';
import { ChatTagService } from '@services/chat/chat-tag.service';
import { ChatTagSearchRequest } from '@models/chat/tag/chat-tag-search-request.dto';

@Component({
  selector: 'app-smart-assistant-topic-item-action',
  templateUrl: './smart-assistant-topic-item-action.component.html',
  styleUrls: ['./smart-assistant-topic-item-action.component.css'],
})
export class SmartAssistantTopicItemActionComponent implements OnInit, OnDestroy {

  private user: UserInfo;

  public textAreaFormControl: FormControl = new FormControl('');

  public SmartAssistantTopicItemActionType = SmartAssistantTopicItemActionType;
  public SmartAssistantTopicItemActionTypes: EnumInfo[] = getTypes(SmartAssistantTopicItemActionType, SmartAssistantTopicItemActionTypeMetadata);

  @Input()
  public smartAssistantTopicItemAction: SmartAssistantTopicItemActionInfo;

  // Management Forms
  public smartAssistantTopicItemActionForm: FormGroup;

  // State
  public selectedSmartAssistantTopicItemActionType: SmartAssistantTopicItemActionType;
  public selectedQuickReply: QuickReplyInfo;
  public selectedChatTags: ChatTagInfo[] = [];
  public openedPopover: any = null;
  public loadingChatTags: boolean = true;

  // Lists
  public quickReplies: QuickReplyInfo[] = [];
  public filteredQuickReplies: QuickReplyInfo[] = [];
  public chatTags: ChatTagInfo[] = [];

  private componentSubscriptions: Subscription[] = [];

  constructor(
    private readonly eventEmitterService: EventEmitterService,
    private readonly quickReplyService: QuickReplyService,
    private readonly chatTagService: ChatTagService,
    private readonly authService: AuthService,
  ) {
    this.user = this.authService.getUser();
  }

  ngOnInit() {
    this.initializeSmartAssistantTopicItemActionForm();
    this.fillSmartAssistantTopicItemActionForm();
    this.loadQuickReplies();
    this.loadChatTags();
  }

  ngOnDestroy() {
    for (const subscription of this.componentSubscriptions)
      subscription.unsubscribe();
  }

  private loadQuickReplies() {
    const searchRequest = new QuickReplySearchRequest(this.user.shop.id, -1);
    const subscription = this.quickReplyService.searchQuickReplies(searchRequest).subscribe({
      next: (quickRepliesPage: Page<any>) => {
        this.quickReplies = quickRepliesPage.content;
        this.adjustQuickReplies(this.quickReplies);
      },
      error: (error) => {
        console.log(error);
      },
    });
    this.componentSubscriptions.push(subscription);
  }

  private adjustQuickReplies(quickReplies: QuickReplyInfo[]) {
    for (const quickReply of quickReplies) {
      quickReply.textItem = quickReply.items.find(i => i.type === MessageType.Text);
      quickReply.mediaItems = quickReply.items.filter(i => isMediaMessage(i.type));
    }
  }

  private loadChatTags() {

    const infiniteSize: number = -1;
    const searchRequest = new ChatTagSearchRequest(this.user.shop.id, infiniteSize);

    this.chatTagService.searchChatTags(searchRequest).subscribe({
      next: (chatTagsPage: Page<ChatTagInfo>) => {
        this.chatTags = chatTagsPage.content;
        this.loadingChatTags = false;
      },
      error: (error: any) => {
        console.error('Error en la carga de tags:', error);
      },
    });
  }

  public selectChatTags(chatTags: ChatTagInfo[]) {
    this.selectedChatTags = chatTags;
  }

  private initializeSmartAssistantTopicItemActionForm() {

    this.smartAssistantTopicItemActionForm = new FormGroup({
      message: new FormControl(''),
      httpCallUrl: new FormControl(''),
      httpCallInstructions: new FormControl(''),
      type: new FormControl(''),
    });

  }

  private fillSmartAssistantTopicItemActionForm() {

    if (this.smartAssistantTopicItemAction) {

      const {
        type,
        message,
        chatTags,
        quickReply,
        httpCallUrl,
        httpCallInstructions,
      } = this.smartAssistantTopicItemAction;

      this.selectedSmartAssistantTopicItemActionType = type;
      this.smartAssistantTopicItemActionForm.get('type').setValue(type);

      switch (type) {
        case SmartAssistantTopicItemActionType.Message:
        case SmartAssistantTopicItemActionType.SmartMessage:
          this.smartAssistantTopicItemActionForm.get('message').setValue(message);
          break;
        case SmartAssistantTopicItemActionType.QuickReply:
          this.selectedQuickReply = quickReply;
          break;
        case SmartAssistantTopicItemActionType.ChatTag:
          this.selectedChatTags = chatTags;
          break;
        case SmartAssistantTopicItemActionType.HttpCall:
          this.smartAssistantTopicItemActionForm.get('httpCallUrl').setValue(httpCallUrl);
          this.smartAssistantTopicItemActionForm.get('httpCallInstructions').setValue(httpCallInstructions);
          break;
        case SmartAssistantTopicItemActionType.HummanAssitantAttention:
          break;
      }

    }
  }

  public goToActionsList() {
    this.initializeSmartAssistantTopicItemActionForm();
    this.eventEmitterService.getEventEmitter(NotificationTopic.ShowSmartAssistantTopicItemActionsList).emit();
  }

  public changeSmartAssistantTopicItemActionType(event: any) {

    const type = getTypeByValue(SmartAssistantTopicItemActionType, event.target.value);
    console.log('type', type);
    this.selectedSmartAssistantTopicItemActionType = type;

    console.log('this.chatTags', this.chatTags);
    console.log('this.selectedChatTags', this.selectedChatTags);

    switch (type) {
      case SmartAssistantTopicItemActionType.Message:
        break;
      case SmartAssistantTopicItemActionType.QuickReply:
        break;
      case SmartAssistantTopicItemActionType.ChatTag:
        break;
      case SmartAssistantTopicItemActionType.HttpCall:
        break;
      case SmartAssistantTopicItemActionType.HummanAssitantAttention:
        break;
    }

  }

  public validateSmartAssistantTopicItemActionForm() {

    const { message, httpCallUrl, httpCallInstruction } = this.smartAssistantTopicItemActionForm.value;
    console.log('selectedSmartAssistantTopicItemActionType', this.selectedSmartAssistantTopicItemActionType);

    switch (this.selectedSmartAssistantTopicItemActionType) {
      case SmartAssistantTopicItemActionType.Message:
      case SmartAssistantTopicItemActionType.SmartMessage:
        if (message.trim() === '') return false;
        break;
      case SmartAssistantTopicItemActionType.HttpCall:
        if (httpCallUrl.trim() === '' && httpCallInstruction.trim() === '') return false;
        break;
      case SmartAssistantTopicItemActionType.QuickReply:
        if (!this.selectedQuickReply) return false;
        break;
      case SmartAssistantTopicItemActionType.ChatTag:
        break;
      case SmartAssistantTopicItemActionType.HummanAssitantAttention:
        break;
      default:
        return false;
    }

    return true;
  }

  public saveSmartAssistantTopicItemAction() {

    const smartAssistantTopicItemActionId = this.smartAssistantTopicItemAction ? this.smartAssistantTopicItemAction.id : null;

    if (!this.smartAssistantTopicItemAction) {

      const smartAssistantTopicItemActionInfo = new SmartAssistantTopicItemActionInfo();
      smartAssistantTopicItemActionInfo.id = smartAssistantTopicItemActionId;
      smartAssistantTopicItemActionInfo.temporalId = uuidv4();
      smartAssistantTopicItemActionInfo.type = this.selectedSmartAssistantTopicItemActionType;

      const { message, httpCallUrl, httpCallInstructions } = this.smartAssistantTopicItemActionForm.value;

      switch (this.selectedSmartAssistantTopicItemActionType) {
        case SmartAssistantTopicItemActionType.Message:
        case SmartAssistantTopicItemActionType.SmartMessage:
          smartAssistantTopicItemActionInfo.message = message;
          break;
        case SmartAssistantTopicItemActionType.HttpCall:
          smartAssistantTopicItemActionInfo.httpCallUrl = httpCallUrl;
          smartAssistantTopicItemActionInfo.httpCallInstructions = httpCallInstructions;
          break;
        case SmartAssistantTopicItemActionType.QuickReply:
          smartAssistantTopicItemActionInfo.quickReply = this.selectedQuickReply;
          break;
        case SmartAssistantTopicItemActionType.ChatTag:
          smartAssistantTopicItemActionInfo.chatTags = this.selectedChatTags;
          break;
        case SmartAssistantTopicItemActionType.HummanAssitantAttention:
          smartAssistantTopicItemActionInfo.humanAssistantAttention = true;
          break;
      }

      this.eventEmitterService.getEventEmitter(NotificationTopic.AddSmartAssistantTopicItemAction)
        .emit(smartAssistantTopicItemActionInfo);

    } else {

      this.smartAssistantTopicItemAction.type = this.selectedSmartAssistantTopicItemActionType;

      const { message, httpCallUrl, httpCallInstructions } = this.smartAssistantTopicItemActionForm.value;

      switch (this.selectedSmartAssistantTopicItemActionType) {
        case SmartAssistantTopicItemActionType.Message:
        case SmartAssistantTopicItemActionType.SmartMessage:
          this.smartAssistantTopicItemAction.message = message;
          break;
        case SmartAssistantTopicItemActionType.HttpCall:
          this.smartAssistantTopicItemAction.httpCallUrl = httpCallUrl;
          this.smartAssistantTopicItemAction.httpCallInstructions = httpCallInstructions;
          break;
        case SmartAssistantTopicItemActionType.QuickReply:
          this.smartAssistantTopicItemAction.quickReply = this.selectedQuickReply;
          break;
        case SmartAssistantTopicItemActionType.ChatTag:
          this.smartAssistantTopicItemAction.chatTags = this.selectedChatTags;
          break;
        case SmartAssistantTopicItemActionType.HummanAssitantAttention:
          break;
      }

      this.eventEmitterService.getEventEmitter(NotificationTopic.AddSmartAssistantTopicItemAction)
        .emit(this.smartAssistantTopicItemAction);
    }

  }

  public showQuickRepliesPopover(popover: any) {

    this.filteredQuickReplies = [...this.quickReplies];

    if (popover && !popover.isOpen()) {
      this.openedPopover = popover;
      popover.open();
    }
  }

  public onKeyup(event: any, popover?: any) {

    const textAreaContent = this.textAreaFormControl.value.trim();

    if (textAreaContent !== '') {

      const textToFilter = textAreaContent.toLowerCase();

      if (textToFilter) {
        this.filteredQuickReplies = this.quickReplies.filter((qr) => {
          return normalizeText(qr.name).includes(normalizeText(textToFilter));
        });
      } else {
        this.filteredQuickReplies = [...this.quickReplies];
      }

      if (popover && !popover.isOpen()) {
        this.openedPopover = popover;
        popover.open();
      }

    } else {
      this.openedPopover = null;
      if (popover)
        popover.close();
    }
  }

  public onKeydown(event: any) {

    let textAreaContent = this.textAreaFormControl.value;

    if (event.code === 'Enter') {

      textAreaContent = textAreaContent.trim();
      event.preventDefault();

      if (textAreaContent) {
        const textToFilter = textAreaContent.toLowerCase();
        const quickReply = this.quickReplies.find(qr => qr.name.toLowerCase() === textToFilter);
        if (quickReply) {
          this.selectedQuickReply = quickReply;
        }
      }
    }
  }

  public selectQuickReply(quickReply: QuickReplyInfo) {

    if (this.openedPopover) {
      this.openedPopover.close();
      this.openedPopover = null;
    }

    this.selectedQuickReply = quickReply;
  }

}
