import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { trialPeriodDays } from '@constants/subscription-constants';
import { AuthService } from '@services/auth/auth.service';

declare var AOS: any;

@Component({
  selector: 'app-landing-page-component',
  templateUrl: `./landing-page.component.html`,
  styleUrls: ['./landing-page.component.css'],
})
export class LandingPageComponent implements OnInit {

  public allLoaded: boolean = false;
  public allLoadedAfter: boolean = false;
  public trialPeriodDays: number = trialPeriodDays;

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {

  }

  ngOnInit() {

    if (this.authService.isAuthenticated()) {
      this.router.navigate(['/app/chat']).then(() => {  });
    }

    setTimeout(() => {
      this.allLoaded = true;
      setTimeout(() => {
        this.allLoadedAfter = true;
        setTimeout(() => {
          AOS.init({
            once: true
          });
        }, 20);
      }, 500);
    }, 500);
  }

}
