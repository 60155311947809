
export class SaveFunnelRequest {

  id: number;
  name: string;
  shopId: number;

  constructor(id: number, name: string, shopId: number) {
    this.id = id;
    this.name = name;
    this.shopId = shopId;
  }

}