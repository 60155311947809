
export class DeleteWhatsAppMessageTemplateRequest {

  shopId: number;
  messageTemplateId: string;
  messageTemplateName: string;

  constructor(shopId: number, messageTemplateId: string, messageTemplateName: string) {
    this.shopId = shopId;
    this.messageTemplateId = messageTemplateId;
    this.messageTemplateName = messageTemplateName;
  }

}