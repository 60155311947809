import { SmartAssistantTopicInfo } from "./topic/smart-assistant-topic-info.dto";

export class SmartAssistantBoard {

  topics: SmartAssistantTopicInfo[];

  constructor() {
    this.topics = [];
  }

}