import { Injectable } from '@angular/core';

export enum DataKey {
  MessageTemplates = 'message-templates',
  EmptyMessageTemplateRecipient = 'empty-message-template-recipient',
  ConfiguringBroadcast = 'configuring-broadcast',
  LastSubscriptionTypeSelected = 'last-subscription-type-selected',
  LastCountryCodeSelected = 'last-country-code-selected'
}

@Injectable({
  providedIn: 'root'
})
export class LocalDataService {

  constructor() {

  }

  public set(dataKey: DataKey, data: string) {
    localStorage.setItem(dataKey.toString(), data);
  }

  public get(dataKey: DataKey) {
    const data = localStorage.getItem(dataKey.toString());
    return data;
  }

  public remove(dataKey: DataKey) {
    localStorage.removeItem(dataKey.toString());
  }

}