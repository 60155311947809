
export class ChangeSmartAssistantOrchestratorContextDefaultStatusRequest {

  smartAssistantOrchestratorId: number;
  smartAssistantOrchestratorContextId: number;
  enabled: boolean;

  constructor(smartAssistantOrchestratorId: number, smartAssistantOrchestratorContextId: number, enabled: boolean) {
    this.smartAssistantOrchestratorId = smartAssistantOrchestratorId;
    this.smartAssistantOrchestratorContextId = smartAssistantOrchestratorContextId;
    this.enabled = enabled;
  }

}