

<a
  [href]="message.fileUrl"
  data-fancybox="gallery"
>
  <img
    class="rounded-2 fit-cover mt-1 img-message-content"
    [src]="message.fileUrl"
  >
</a>

