// Angular
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

// NgBootstrap
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

// Toastr
import { ToastrService } from 'ngx-toastr';

// RxJS
import {
 Subject,
  Subscription,
} from 'rxjs';

// Chat Models
import { ChatInfo } from '@app/models/chat/chat/chat-info.dto';

import { UserInfo } from '@app/models/account/user/user.info.dto';

// Services
import { AuthService } from '@app/services/auth/auth.service';
import { ChatTagInfo } from '@models/chat/tag/chat-tag-info.dto';

import 'moment/locale/es';
import { ChatSearchDangeRangeFieldType } from '@type/chat/chat-search-dange-range-field.type';
import { Page } from '@models/common/page';
import { HttpErrorResponse } from '@angular/common/http';
import { UserSearchRequest } from '@models/account/user/user-search-request.dto';
import { UserService } from '@services/account/user.service';
import { ChatTagService } from '@services/chat/chat-tag.service';
import { ChatTagSearchType } from '@type/chat/chat-tag-search.type';
import { getLastMessageTypeLabel } from '@app/utils/chat-utils';
import { EventEmitterService, NotificationTopic } from '@services/data/event-emitter.service';
import { ChatService } from '@services/chat/chat.service';
import { SpinnerService } from '@services/data/spinner.service';
import { FunnelService } from '@services/utilities/funnel.service';
import { FunnelInfo } from '@models/utilities/funnel/funnel-info.dto';
import { FunnelStageInfo } from '@models/utilities/funnel/stage/funnel-stage-info.dto';
import { SaveFunnelStageRequest } from '@models/utilities/funnel/stage/save-funnel-stage-request.dto';
import { FunnelSearchRequest } from '@models/utilities/funnel/funnel-search-request.dto';
import { GetFunnelStageChatsRequest } from '@models/utilities/funnel/stage/get-funnel-stage-chats-request.dto';
import { SaveFunnelRequest } from '@models/utilities/funnel/save-funnel-request.dto';
import {
  ToggleFunnelStageCollapsedRequest,
} from '@models/utilities/funnel/stage/toggle-funnel-stage-collapsed-request.dto';

@Component({
  selector: 'app-funnel',
  templateUrl: './funnel.component.html',
  styleUrls: ['./funnel.component.css'],
})
export class FunnelComponent implements OnInit, OnDestroy, AfterViewInit {

  public readonly ChatSearchDangeRangeFieldType = ChatSearchDangeRangeFieldType;
  public readonly ChatTagSearchType = ChatTagSearchType;

  // Lists
  public funnels: FunnelInfo[] = [];

  // Selections
  public creatingFunnel: boolean = false;
  public selectedFunnel: FunnelInfo;
  public selectedFunnelStage: FunnelStageInfo;

  // Forms
  public funnelForm: FormGroup;
  public funnelStageForm: FormGroup;

  // Save Requests
  public saveFunnelStageRequest: SaveFunnelStageRequest;

  private currentUser: UserInfo;
  private componentSubscriptions: Subscription[] = [];

  public selectedChat: ChatInfo;
  public savingFunnelStage: boolean = false;

  public firstLoad: boolean = true;

  // Local data
  public users: UserInfo[] = [];
  private defaultUsersPageSize: number = -1;

  // Users
  public usersAll: UserInfo[] = [];
  public usersAvailable: UserInfo[] = [];
  public usersChosen: UserInfo[] = [];
  // Users

  // Chat Tags
  @ViewChild('instance', { static: true }) instance: any;
  public focus$ = new Subject<string>();
  public click$ = new Subject<string>();
  public formatResult = (value: ChatTagInfo) => value.name;
  public formatInput = (value: ChatTagInfo) => value.name;
  public tagsFormControl: FormControl;
  public chatTagsAll: ChatTagInfo[] = [];
  public chatTagsAvailable: ChatTagInfo[] = [];
  public chatTagsChosen: ChatTagInfo[] = [];
  // Chat Tags

  // Dates
  public currentDate: Date = new Date();
  private dateRangeInstance: any;
  public dateRangeValue: any = {};
  // Dates

  // OffCanvas
  @ViewChild('saveFunnelOffCanvas')
  public saveFunnelOffCanvas: TemplateRef<any>;

  @ViewChild('saveFunnelStageOffCanvas')
  public saveFunnelStageOffCanvas: TemplateRef<any>;

  @ViewChild('funnelStageChatModal')
  public funnelStageChatModal: TemplateRef<any>;

  // Modals
  @ViewChild('chatModal')
  private chatModal: TemplateRef<any>;

  constructor(
    private eventEmitterService: EventEmitterService,
    private authService: AuthService,
    private chatService: ChatService,
    private funnelService: FunnelService,
    private userService: UserService,
    private offCanvasService: NgbOffcanvas,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private spinnerService: SpinnerService,
  ) {
    this.currentUser = this.authService.getUser();
  }

  ngOnInit() {
    this.initializeOnMessageReceivedEvent();
    this.initializeFunnelStageForm();
    this.initializeFunnelStageChangedSubscription();
    this.initializeFunnelForm();
    this.saveFunnelStageRequest = new SaveFunnelStageRequest();
    this.loadFunnels();
    this.loadUsers();
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.componentSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  initializeFunnelStageChangedSubscription() {
    const eventEmitter = this.eventEmitterService.getEventEmitter(NotificationTopic.FunnelStageChangedEventTopic);
    const subscription = eventEmitter.subscribe((chat: ChatInfo) => {
      console.log('chat', chat);
      this.manageChatUpdateStageEvent(chat);
    });
    this.componentSubscriptions.push(subscription);
  }

  manageChatUpdateStageEvent(chat: ChatInfo) {

    for (const funnelStage of this.selectedFunnel.stages) {

      const chats = funnelStage.chats.content;
      const chatIndex = chats.findIndex(c => c.id === chat.id);

      if (chatIndex !== -1) {
        if (chat.funnelStageId === funnelStage.id) {
          chats[chatIndex] = chat;
        }
        {
          chats.splice(chatIndex, 1);
          funnelStage.chats.totalElements--;
        }
      } else {
        if (chat.funnelStageId === funnelStage.id) {
          chats.unshift(chat);
          funnelStage.chats.totalElements++;
        }
      }
    }
  }


  private initializeOnMessageReceivedEvent() {

    const eventEmitter = this.eventEmitterService.getEventEmitter(NotificationTopic.MessageReceived);

    const subscription = eventEmitter.subscribe((data: any) => {

      const { message, chat } = data;

      for (const funnelStage of this.selectedFunnel.stages) {

        const chats = funnelStage.chats.content;

        // Buscar el chat actual en la lista de chats disponibles
        const messageChat = chats.find(c => c.id === message.chatId);
        const messageChatIndex = chats.findIndex(c => c.id === message.chatId);

        // Si la conversación se encontró en la lista de contactos actual del UI
        if (messageChat) {

          // Actualizar la información del último mensaje en la conversación encontrada
          messageChat.lastMessageContent = message.content;
          messageChat.lastMessageFileName = message.fileName;
          messageChat.lastMessageFileUrl = message.fileUrl;
          messageChat.lastMessageType = message.type;
          messageChat.lastMessageStatus = message.status;
          messageChat.lastMessageSentTime = message.sentTime;
          messageChat.lastMessageTypeLabel = getLastMessageTypeLabel(messageChat);

          // Si la conversación no está en la parte superior, moverla hacia arriba en la lista de chats
          if (messageChatIndex !== 0) {
            chats.splice(messageChatIndex, 1);
            chats.unshift(messageChat);
          }

        }
        // Si la conversación no está en el UI y se tiene que obtener del service
        else {

          this.chatService.getChatById(message.chatId)
            .subscribe((chat: ChatInfo) => {
              if (chat.funnelStageId === funnelStage.id) {
                chats.unshift(chat);
                funnelStage.chats.totalElements++;
              }
            });
        }

      }

    });

    this.componentSubscriptions.push(subscription);
  }

  private initializeFunnelForm() {
    this.funnelForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
    });
  }

  private initializeFunnelStageForm() {
    this.funnelStageForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      user: new FormControl(''),
    });
  }

  private loadUsers() {

    const userSearchRequest = new UserSearchRequest(
      this.currentUser.shop.id, this.defaultUsersPageSize,
    );

    this.userService.searchUsers(userSearchRequest).subscribe({
      next: (page: Page<UserInfo>) => {
        this.usersAll = [...page.content];
        this.usersAvailable = page.content;
      },
      error: (error: any) => {
        console.error('Error en la carga de chats:', error);
      },
      complete: () => {
      },
    });
  }

  private loadFunnels() {

    const funnelSearch = new FunnelSearchRequest(
      this.currentUser.shop.id, -1,
    );

    this.funnelService.searchFunnels(funnelSearch).subscribe({
      next: (page: Page<FunnelInfo>) => {
        this.funnels = page.content;
        if (this.firstLoad) this.firstLoad = false;
      },
      error: (error: HttpErrorResponse) => {
        console.error('Error en la carga de funnels:', error);
      },
      complete: () => {
      },
    });
  }

  private getFunnelStageChats(funnelStage: any, page?: number) {

    let pageNumber = page;

    if (page === undefined) {
      let { totalPages } = funnelStage.chats;
      pageNumber = funnelStage.chats.pageNumber;
      if ((pageNumber + 1) === totalPages) return;
      ++pageNumber;
    }

    const shopId = this.selectedFunnel.shopId;

    const getFunnelStageChatsRequest = new GetFunnelStageChatsRequest(
      shopId, funnelStage.id, pageNumber, 20,
    );

    this.funnelService.getFunnelStageChats(getFunnelStageChatsRequest).subscribe({
      next: (page: Page<ChatInfo>) => {
        if (pageNumber > 0) {
          const currentChats = [...funnelStage.chats.content];
          funnelStage.chats = page;
          funnelStage.chats.content = [...currentChats, ...page.content];
        } else {
          funnelStage.chats = page;
        }
        this.updateFunnelStageChats(funnelStage);
      },
      error: (error: HttpErrorResponse) => {
        console.error('Error en la carga de chats:', error);
      },
      complete: () => {
      },
    });
  }

  public openSaveFunnel(funnel?: FunnelInfo) {

    if (funnel) {
      this.funnelForm.get('name').setValue(funnel.name);
    } else {
      this.creatingFunnel = true;
      this.selectedFunnel = undefined;
      this.funnelForm.get('name').setValue('');
    }

    const offCanvasRef = this.offCanvasService.open(
      this.saveFunnelOffCanvas,
      {
        position: 'end',
      },
    );

    if (this.selectedFunnel && this.selectedFunnel.id === funnel.id) return;

    offCanvasRef.hidden.subscribe(() => {
      this.initializeFunnelForm();
      this.selectedFunnelStage = undefined;
      this.creatingFunnel = false;
    });

  }

  public saveFunnel() {

    const { name } = this.funnelForm.value;

    let funnelId: number = null;
    if (!this.creatingFunnel) funnelId = this.selectedFunnel.id;

    const saveFunnelRequest = new SaveFunnelRequest(
      funnelId, name.trim(), this.currentUser.shop.id,
    );

    this.funnelService.saveFunnel(saveFunnelRequest).subscribe({
      next: (savedFunnel: FunnelInfo) => {

        this.selectedFunnel = savedFunnel;
        const funnelIndex = this.funnels.findIndex(f => f.id === savedFunnel.id);
        if (funnelIndex === -1) {
          this.funnels.unshift(savedFunnel);
        } else {
          this.funnels[funnelIndex] = savedFunnel;
        }

        this.offCanvasService.dismiss();
        this.toastr.success(`Embudo ${ funnelId ? 'actualizado' : 'creado' } exitosamente`);
      },
      error: (httpErrorResponse: HttpErrorResponse) => {
        const { error } = httpErrorResponse;
        const errorCode = error.code;
        if (errorCode === 'FUNNEL_ALREADY_EXISTS') {
          this.toastr.error('Ya existe un embudo con ese nombre, por favor elija otro.');
        } else {
          this.toastr.error('Error al crear el embudo');
        }
      },
      complete: () => {
      },
    });

  }

  public deleteFunnel() {

    const funnel = this.selectedFunnel;

    if (!confirm('¿Está seguro que desea eliminar este embudo?')) return;

    this.funnelService.deleteFunnel(funnel.id).subscribe({
      next: () => {
        this.selectedFunnel = undefined;

        const funnelIndex = this.funnels.findIndex(f => f.id === funnel.id);
        this.funnels.splice(funnelIndex, 1);

        this.toastr.success('Embudo eliminado exitosamente');
        this.offCanvasService.dismiss();
      },
      error: (httpErrorResponse: HttpErrorResponse) => {
        this.toastr.error('Error al eliminar el embudo');
      },
      complete: () => {
      },
    });

  }

  public openFunnel(funnel: any) {
    if (this.selectedFunnel && this.selectedFunnel.id === funnel.id) return;
    this.getFunnel(funnel.id);
  }

  private getFunnel(id: number) {

    this.spinnerService.show('Cargando funnel...');

    this.funnelService.getFunnel(id).subscribe({
      next: (funnel: FunnelInfo) => {
        this.selectedFunnel = funnel;
        for (const funnelStage of funnel.stages) {
          this.updateFunnelStageChats(funnelStage);
        }
        this.setFunnelCollapsable();
        this.spinnerService.hide();
      },
      error: (httpErrorResponse: HttpErrorResponse) => {
        console.error('Error al cargar el embudo');
      },
      complete: () => {
        this.spinnerService.hide();
      },
    });

  }

  updateFunnelStageChats(funnelStage: any) {
    for (const chat of funnelStage.chats.content) {
      chat.lastMessageTypeLabel = getLastMessageTypeLabel(chat);
    }
  }

  private setFunnelCollapsable() {
    setTimeout(() => {
      (window as any).phoenix.initEverything();
    }, 1000);
  }

  public openSaveFunnelStage(funnelStage?: any) {

    this.saveFunnelStageRequest.funnelId = this.selectedFunnel.id;

    if (funnelStage) {
      this.selectedFunnelStage = funnelStage;
      this.setFunnelStageFormValues(funnelStage);
    }

    const offCanvasRef = this.offCanvasService.open(
      this.saveFunnelStageOffCanvas,
      {
        position: 'end',
      },
    );

    offCanvasRef.shown.subscribe(() => {

      const input = document.getElementById('funnelStageName');
      input.focus();

    });

    offCanvasRef.hidden.subscribe(() => {
      this.selectedFunnelStage = undefined;
      this.resetAll();
    });

  }

  private setFunnelStageFormValues(funnelStage: FunnelStageInfo) {

    this.funnelStageForm.get('name').setValue(funnelStage.name);
    this.funnelStageForm.get('user').setValue('');


    this.saveFunnelStageRequest.id = funnelStage.id;
    this.saveFunnelStageRequest.name = funnelStage.name;
    this.saveFunnelStageRequest.funnelId = funnelStage.funnelId;
    this.saveFunnelStageRequest.order = funnelStage.order;

  }

  public deleteFunnelStage(funnelStage: any) {

    if (!confirm('¿Está seguro que desea eliminar esta etapa?')) return;

    this.funnelService.deleteFunnelStage(funnelStage.id).subscribe({
      next: () => {

        const funnelStageIndex = this.selectedFunnel.stages.findIndex(fs => fs.id === funnelStage.id);
        this.selectedFunnel.stages.splice(funnelStageIndex, 1);

        this.toastr.success('Etapa eliminada exitosamente');
      },
      error: (httpErrorResponse: HttpErrorResponse) => {
        const { error } = httpErrorResponse;
        const errorCode = error.code;
        console.log('errorCode', errorCode);
        this.toastr.error('Error al eliminar la etapa');
      },
      complete: () => {
      },
    });


  }

  public refreshFunnelStageChats(funnelStage: any) {
    this.getFunnelStageChats(funnelStage, 0);
  }

  public toggleFunnelStageCollapse(funnelStage: any) {
    funnelStage.collapsed = !funnelStage.collapsed;

    const toggleBoardColumnCollapsedRequest = new ToggleFunnelStageCollapsedRequest(
      funnelStage.id, funnelStage.collapsed,
    );

    this.funnelService.toggleFunnelStageCollapse(toggleBoardColumnCollapsedRequest).subscribe({
      next: () => {

      },
      error: (error: any) => {
        console.error('Error al colapsar columna', error);
      },
      complete: () => {
      },
    });

  }

  public openFunnelStageChat(event: any, funnelStage: FunnelStageInfo, chat: ChatInfo) {

    this.selectedChat = chat;
    const modalRef = this.modalService.open(this.chatModal, {
      size: 'xl',
      centered: true,
    });

    modalRef.hidden.subscribe(() => {

    });

  }

  public saveFunnelStage() {

    if (this.funnelStageForm.valid) {

      this.savingFunnelStage = true;
      this.funnelStageForm.disable();

      const { name } = this.funnelStageForm.value;

      this.saveFunnelStageRequest.name = name.trim();

      // Updating broadcast
      if (this.selectedFunnelStage) {
        this.saveFunnelStageRequest.id = this.selectedFunnelStage.id;
      }

      this.funnelService.saveFunnelStage(this.saveFunnelStageRequest).subscribe({
        next: (savedFunnelStage: FunnelStageInfo) => {
          setTimeout(() => {
            this.updateFunnelStageChats(savedFunnelStage);

            if (this.selectedFunnelStage) {
              Object.assign(this.selectedFunnelStage, savedFunnelStage);
            } else {
              this.selectedFunnel.stages.push(savedFunnelStage);
            }

            this.savingFunnelStage = false;
            this.offCanvasService.dismiss();
            this.resetAll();
            this.toastr.success('Etapa guardada exitosamente');
          }, 10);
        },
        error: (error: any) => {
          this.savingFunnelStage = false;
          const errorMessage = 'Error al guardar la etapa';
          this.toastr.error(errorMessage);
          // this.errorCode = '';
          console.error(errorMessage, error);
        },
        complete: () => {
        },
      });

    }

  }

  resetAll() {
    this.initializeFunnelStageForm();
    this.saveFunnelStageRequest = new SaveFunnelStageRequest();
    this.dateRangeValue = {};
    this.selectedFunnelStage = undefined;
    this.chatTagsAvailable = [...this.chatTagsAll];
    this.usersAvailable = [...this.usersAll];
    this.usersChosen = [];
    this.chatTagsChosen = [];
  }

  public onScrolled(funnelStage: any) {
    this.getFunnelStageChats(funnelStage);
  }

}
