import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ChatInfo } from '@app/models/chat/chat/chat-info.dto';
import { EventEmitterService, NotificationTopic } from '@app/services/data/event-emitter.service';
import { ChannelType } from '@type/communication/channel.type';
import { formatTimestamp } from '@app/utils/date-utils';
import { MessageStatus } from '@type/chat/message-status.type';
import { getMessageStatusClassString } from '@app/utils/chat-utils';

@Component({
  selector: 'app-chat-list-item',
  templateUrl: './chat-list-item.component.html',
  styleUrls: ['./chat-list-item.component.css'],
})
export class ChatListItemComponent implements OnInit, OnDestroy {

  public MessageStatus = MessageStatus;
  public ChannelType = ChannelType;

  @Input()
  public chat: ChatInfo;

  public isMobile: boolean;
  public openedPopover: boolean = false;

  private componentSubscriptions: Subscription[] = [];

  constructor(
    private eventEmitterService: EventEmitterService,
  ) {

  }

  ngOnInit() {
    this.initializeCheckIfMobileSubscription();
  }

  ngOnDestroy() {
    this.componentSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  public initializeCheckIfMobileSubscription() {

    this.isMobile = window.innerWidth <= 992;

    const eventEmitter = this.eventEmitterService.getEventEmitter(NotificationTopic.IsMobile);

    const subscription = eventEmitter.subscribe((isMobile: boolean) => {
      this.isMobile = isMobile;
    });

    this.componentSubscriptions.push(subscription);
  }

  public openPopover(popover: any) {
    setTimeout(() => {
      popover.open();
    }, 0);
  }

  public closePopover(popover: any) {
    this.openedPopover = false;
    setTimeout(() => {
      if (this.openedPopover) {
        return;
      }
      popover.close();
    }, 0);
  }

  public activateOpenedPopover() {
    this.openedPopover = true;
  }

  protected readonly Object = Object;
}
