<div class="form-floating mb-2" *ngIf="floating">
  <ng-container *ngTemplateOutlet="phoneNumberTypeaheadTemplate"></ng-container>
  <label for="floatingInput">
    Código de país
  </label>
</div>

<ng-container *ngIf="!floating">
  <ng-container *ngTemplateOutlet="phoneNumberTypeaheadTemplate"></ng-container>
</ng-container>

<ng-template #phoneNumberTypeaheadTemplate>
  <input
    id="floatingInput"
    type="text"
    class="form-control"
    [formControl]="countryCodesFormControl"
    placeholder="Código de país"
    [editable]="false"
    #instance="ngbTypeahead"
    [ngbTypeahead]="searchCountryCodes"
    [resultTemplate]="rt"
    [resultFormatter]="formatResult"
    [inputFormatter]="formatInput"
    (focus)="focus$.next($any($event).target.value)"
    (click)="click$.next($any($event).target.value)"
    [ngClass]="{
      'is-valid': selectedCountryCode !== undefined
    }">
</ng-template>

<ng-template #rt let-r="result" let-t="term">
  <img [src]="'assets/img/flags/' + r.iso.toLowerCase() + '.svg'"
       class="me-1" style="width: 16px" />
  <ngb-highlight
    [result]="'+' + r.code + ' ' + r.country"
    [term]="t">
  </ngb-highlight>
</ng-template>