import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AppDetailGalleryService } from '../../../services/marketplace/app-detail-gallery.service';
import { AppPluginService } from '../../../services/marketplace/app-plugin.service';
import { AppReviewService } from '../../../services/marketplace/app-review.service';
import { AppPluginInfo } from '../../../models/marketplace/app-plugin-info.dto';
import { getStars } from '../marketplace-utils/review.utils';

@Component({
  selector: 'app-list',
  templateUrl: `./app-list.component.html`,
  styleUrls: ['./app-list.component.css'],
})
export class AppListComponent implements OnInit {

  public apps: AppPluginInfo[] = [];

  constructor(
    private offCanvasService: NgbOffcanvas,
    public router: Router,
    private appDetailGalleryService: AppDetailGalleryService,
    private appPluginService: AppPluginService,
    private appReviewService: AppReviewService,
  ) {
  }

  ngOnInit() {
    this.getApps();
  }

  public goToAppDetails(appId: number) {
    const url = `app/marketplace/app-detail/${appId}`;
    this.router.navigate([url]);
  }

  public getApps() {
    this.appPluginService.getAllAppPlugins().subscribe({
      next: (apps: AppPluginInfo[]) => {
        this.apps = apps;
      },
      error: (error) => {
        console.error(error);
      },
    });
  }


  protected readonly getStars = getStars;
}
