
export class ImportContactsRequest {

  shopId: number;
  chatTagIds: number[];
  importFileUrl: string;
  importFileKey: string;

  constructor(shopId: number, chatTagIds: number[], importFileUrl: string, importFileKey: string) {
    this.shopId = shopId;
    this.chatTagIds = chatTagIds;
    this.importFileUrl = importFileUrl;
    this.importFileKey = importFileKey;
  }

}
