<div class="kanban-content border rounded">
  <div class="kanban-header">
    <div class="row gx-0 justify-content-between justify-content-md-start">
      <div class="col-auto d-flex align-items-center">
        <h3 class="text-1000 my-1">
          <i class="fa-duotone fa-cards-blank text-primary"></i>
          Tarjetas contextuales
        </h3>
      </div>
      <div class="col-md-auto d-flex align-items-center ms-auto mt-2 mt-md-0">
        <ul class="nav w-100 fs--1 d-flex align-items-center">
          <li class="nav-item">
            <button type="button" class="btn btn-outline-success px-3" (click)="openTestSmartAssistant()">
              <i class="fa-duotone fa-flask text-success"></i>
              <span class="d-none d-sm-inline ms-1">
                Probar
              </span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="kanban-container scrollbar">
    <div class="row g-3">
      <div class="col-4" *ngIf="!addingSmartAssistantOrchestratorContext">
        <div class="card border border-light context-card hover-card hover-bg-300 d-flex justify-content-center align-items-center"
             [style]="{ 'pointer-events': availableCreateSmartAssistants.length > 0 ? 'auto' : 'none' }"
             (click)="showAddSmartAssistantOrchestratorContext()">
          <h4 *ngIf="availableCreateSmartAssistants.length > 0">
            <i class="fa fa-plus-circle"></i>
            Agregar contexto
          </h4>
          <h4 class="text-success text-wrap text-center" *ngIf="availableCreateSmartAssistants.length === 0">
            <i class="fa fa-box-full"></i>
            Todos los asistentes están configurados
          </h4>
        </div>
      </div>
      <div class="col-4" *ngIf="addingSmartAssistantOrchestratorContext">
        <div class="card border border-primary context-card">
          <div class="card-body">
            <form
              [formGroup]="createSmartAssistantOrchestratorContextFormGroup"
              (submit)="saveSmartAssistantOrchestratorContext()">
              <strong class="text-dark">
                Contexto de activación
              </strong>
              <textarea
                rows="3"
                class="form-control scrollbar mb-2"
                placeHolder="Contexto de activación del asistente"
                formControlName="context"
              ></textarea>
              <strong class="text-dark">
                Asistente a activar
              </strong>
              <select
                class="form-select"
                formControlName="smartAssistantId">
                <option selected="" [value]="''" disabled>
                  Seleccione un asistente a activar
                </option>
                <option [value]="smartAssistant.id" *ngFor="let smartAssistant of availableCreateSmartAssistants">
                  {{ smartAssistant.name }}
                </option>
              </select>
              <div class="d-flex justify-content-end mt-2">
                <button
                  type="button"
                  class="btn btn-sm btn-phoenix-danger mb-1 me-2"
                  (click)="cancelAddSmartAssistantOrchestratorContext()">
                  <i class="fa-duotone fa-cancel me-1"></i>
                  Cancelar
                </button>
                <button
                  [disabled]="createSmartAssistantOrchestratorContextFormGroup.invalid"
                  type="submit"
                  class="btn btn-sm btn-phoenix-primary mb-1">
                  <i class="fa-duotone fa-save me-1"></i>
                  Crear
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ng-container *ngIf="smartAssistantOrchestrator?.smartAssistantOrchestratorContexts?.length > 0">
        <div class="col-4" *ngFor="let smartAssistantOrchestratorContext of smartAssistantOrchestrator?.smartAssistantOrchestratorContexts">
          <div class="card border border-light context-card" *ngIf="!smartAssistantOrchestratorContext.editing">
            <div class="card-body position-relative">
              <strong class="text-dark">
                Contexto de activación
              </strong>
              <p class="card-text text-wrap card-context-text scrollbar">
                {{ smartAssistantOrchestratorContext.context }}
              </p>
              <strong class="text-dark">
                Asistente a activar
              </strong>
              <p class="text-warning">
                {{ smartAssistantOrchestratorContext.smartAssistant.name }}
              </p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="form-check form-switch position-absolute bottom-0 start-0 ms-4 mb-4">
                  <input type="checkbox" class="form-check-input"
                         [id]="smartAssistantOrchestratorContext.smartAssistant.id"
                         (change)="changeSmartAssistantOrchestratorContextDefaultStatus(smartAssistantOrchestratorContext)"
                         [checked]="smartAssistantOrchestratorContext.defaultEnabled">
                  <label class="form-check-label" [for]="smartAssistantOrchestratorContext.smartAssistant.id">
                    Por defecto
                  </label>
                </div>
                <div class="d-flex justify-content-end position-absolute bottom-0 end-0 me-4 mb-4">
                  <i
                    class="fa fa-trash edit-icon text-danger cursor-pointer me-2"
                    (click)="deleteSmartAssistantOrchestratorContext(smartAssistantOrchestratorContext.id)"></i>
                  <i
                    class="fa fa-edit edit-icon text-primary cursor-pointer"
                    (click)="showEditSmartAssistantOrchestratorContext(smartAssistantOrchestratorContext)">
                  </i>
                </div>
              </div>
            </div>
          </div>
          <div class="card border border-primary context-card" *ngIf="smartAssistantOrchestratorContext.editing">
            <div class="card-body">
              <form
                [formGroup]="updateSmartAssistantOrchestratorContextFormGroup"
                (submit)="saveSmartAssistantOrchestratorContext(smartAssistantOrchestratorContext)">
                <strong class="text-dark">
                  Contexto de activación
                </strong>
                <textarea
                  rows="3"
                  class="form-control scrollbar mb-2"
                  placeHolder="Contexto de activación del asistente"
                  formControlName="context"
                ></textarea>
                <strong class="text-dark">
                  Asistente a activar
                </strong>
                <select
                  class="form-select"
                  formControlName="smartAssistantId">
                  <option selected="" [value]="''" disabled>
                    Seleccione un asistente a activar
                  </option>
                  <option [value]="smartAssistant.id" *ngFor="let smartAssistant of availableUpdateSmartAssistants">
                    {{ smartAssistant.name }}
                  </option>
                </select>
                <div class="d-flex justify-content-end mt-2">
                  <button
                    type="submit"
                    class="btn btn-sm btn-phoenix-danger mb-1 me-2"
                    (click)="cancelEditSmartAssistantOrchestratorContext(smartAssistantOrchestratorContext)">
                    <i class="fa-duotone fa-cancel me-1"></i>
                    Cancelar
                  </button>
                  <button
                    [disabled]="updateSmartAssistantOrchestratorContextFormGroup.invalid"
                    type="submit"
                    class="btn btn-sm btn-phoenix-primary mb-1">
                    <i class="fa-duotone fa-save me-1"></i>
                    Guardar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>