
export class ArchiveChatRequest {

  chatIds: number[];
  archive: boolean;

  constructor(chatIds: number[], archive: boolean) {
    this.chatIds = chatIds;
    this.archive = archive;
  }

}
