import { EventEmitter, Injectable } from '@angular/core';

export enum NotificationTopic {

  IsMobile,
  ChatSelected,
  SameChatSelected,
  ShowSendTemplateMessage,
  ShowSendWhatsAppWebMessage,
  ShowSideChatList,
  ToggleSidebar,
  ToggleTheme,
  ChatArchive,
  SelectedChatTag,
  RemoveSelectedChatTag,
  ChatSeen,

  // Funnel
  FunnelStageChangedEventTopic,
  FunnelStageAddedEventTopic,
  FunnelStageRemovedEventTopic,
  FunnelAddedEventTopic,
  FunnelRemovedEventTopic,

  // Account
  BlockUI,

  // Chat
  ChatRequiresAssistance,
  MessageReceived,
  MessageStatus,
  MessageReply,
  MessageReaction,
  ShowMessageNote,
  MessageNoteUpdated,
  MessageHighlighted,
  RemoveMessageHighlight,
  DeleteMessageNote,
  GoToMessage,
  ChatUpdated,

  // WhatsApp Web
  WhatsAppWebInstanceQRCode,
  WhatsAppWebInstanceStatus,

  // Test Smart Assistant
  TestSmartAssistantMessageReceived,
  TestSmartAssistantTopicItemAdded,
  BroadcastMessageTemplateReady,

  // Contacts Management
  WhatsAppWebExport,
  ImportContactsCompleted,

  // Smart Assistants Management
  OpenSaveSmartAssistant,
  OpenSaveSmartAssistantOrchestrator,
  OpenTestSmartAssistant,
  ShowSmartAssistantTopicItemActionsList,
  AddSmartAssistantTopicItemAction,

  // Message Templates
  MessageTemplateButtonsChange,
}

function createEventEmitter(): EventEmitter<any> {
  return new EventEmitter<any>();
}

@Injectable({
  providedIn: 'root',
})
export class EventEmitterService {

  private eventEmitters: Map<NotificationTopic, EventEmitter<any>> =
    new Map<NotificationTopic, EventEmitter<any>>();

  constructor() {
    this.initializeEventEmitters();
  }

  private initializeEventEmitters() {
    for (const topic of Object.values(NotificationTopic))
      this.eventEmitters.set(NotificationTopic[topic], createEventEmitter());
  }

  public emit(topic: NotificationTopic, data?: any) {
    this.getEventEmitter(topic).next(data);
  }

  public getEventEmitter(topic: NotificationTopic): EventEmitter<any> {
    return this.eventEmitters.get(topic);
  }

}