import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, map, merge, Observable, OperatorFunction, Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { UserService } from '@services/account/user.service';
import { AuthService } from '@services/auth/auth.service';
import { ChatInfo } from '@models/chat/chat/chat-info.dto';
import { UserInfo } from '@models/account/user/user.info.dto';
import { UserSearchRequest } from '@models/account/user/user-search-request.dto';
import { Page } from '@models/common/page';

@Component({
  selector: 'app-advisors-input',
  templateUrl: './advisors-input.component.html',
  styleUrls: ['./advisors-input.component.css'],
})
export class AdvisorsInputComponent implements OnInit {

  @Input()
  public chat: ChatInfo;

  @Output() advisorsEmitter = new EventEmitter<UserInfo>();

  private user: UserInfo;

  constructor(
    private userService: UserService,
    private authService: AuthService,
  ) {
    this.user = this.authService.getUser();
  }

  ngOnInit(): void {
    this.loadChatAdvisors();
  }

  @ViewChild('users', { static: true }) users: any;
  public focusAdvisor$ = new Subject<string>();
  public clickAdvisor$ = new Subject<string>();
  public formatResultAdvisor = (value: UserInfo) => value.name;
  public formatInputAdvisor = (value: UserInfo) => value.name;
  public advisorsFormControl: FormControl;
  public chatAdvisorsAll: UserInfo[] = [];
  public chatAdvisorsAvailable: UserInfo[] = [];
  public chatAdvisorsChosen: UserInfo;

  searchChatAdvisors: OperatorFunction<string, readonly UserInfo[]> = (
    text$: Observable<string>,
  ) => {
    const debouncedText$ = text$.pipe(debounceTime(1), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickAdvisor$.pipe(
      filter(() => !this.users.isPopupOpen()),
    );
    const inputFocus$ = this.focusAdvisor$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ''
            ? this.chatAdvisorsAvailable
            : this.chatAdvisorsAvailable.filter(
              (ct) => ct.name.toLowerCase().indexOf(term.toLowerCase()) > -1,
            )
        ).slice(0, 10),
      ),
    );
  };

  loadChatAdvisors() {

    const noSize: number = -1;

    const searchRequest = new UserSearchRequest(
      this.user.shop.id,
      noSize,
    );

    this.userService.searchUsers(searchRequest).subscribe({
      next: (chatAdvisorsPage: Page<UserInfo>) => {

        this.chatAdvisorsAll = [...chatAdvisorsPage.content];
        this.chatAdvisorsAvailable = chatAdvisorsPage.content;

        if (this.chat && this.chat.userId) {
          this.chatAdvisorsChosen = this.chatAdvisorsAll.find((ct) => ct.id === this.chat.userId);
          this.chatAdvisorsAvailable = this.chatAdvisorsAll.filter((ct) => ct.id !== this.chat.userId);
        }
      },
      error: (error: any) => {
        console.error('Error en la carga de asesores:', error);
      },
      complete: () => {
      },
    });

    this.initializeChatAdvisorsChosenEvent();
  }

  initializeChatAdvisorsChosenEvent() {

    this.advisorsFormControl = new FormControl('');

    this.advisorsFormControl.valueChanges.subscribe((value: UserInfo) => {
      if (value) {
        // Remove the previously chosen advisor, if any
        if (this.chatAdvisorsChosen) {
          const previousAdvisor = this.chatAdvisorsChosen;
          this.chatAdvisorsAvailable.push(previousAdvisor);
          this.chatAdvisorsAvailable.sort((ct1, ct2) => ct2.id - ct1.id);
        }

        // Add the new chosen advisor
        this.chatAdvisorsChosen = value;
        this.advisorsEmitter.emit(this.chatAdvisorsChosen);
        this.advisorsFormControl.setValue(null);

        // Remove the new chosen advisor from available advisors
        const chatAdvisorChosenIndex = this.chatAdvisorsAvailable.indexOf(value);
        this.chatAdvisorsAvailable.splice(chatAdvisorChosenIndex, 1);
        this.chatAdvisorsAvailable.sort((ct1, ct2) => ct2.id - ct1.id);
      }
    });
  }


  removeChatAdvisor(chatAdvisor: UserInfo) {
    this.chatAdvisorsChosen = null;
    this.chatAdvisorsAvailable.push(chatAdvisor);
    this.chatAdvisorsAvailable.sort((ct1, ct2) => ct2.id - ct1.id);
  }
}
