import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AppPluginInfo } from '../../../../../models/marketplace/app-plugin-info.dto';

@Component({
  selector: 'app-list-populars',
  templateUrl: './app-list-populars.component.html',
  styleUrls: ['./app-list-populars.component.css']
})
export class AppListPopularsComponent {

  @Input() apps: AppPluginInfo[] = [];

  constructor(
    public router: Router,
  ) { }

  goToAppDetails(appId: number) {
    const url = `app/marketplace/app-detail/${appId}`;
    this.router.navigate([url]);
  }
}
