<main class="main" id="top">
  <div class="container-fluid bg-300 dark__bg-1200">
    <div class="bg-holder bg-auth-card-overlay">
    </div>
    <div class="row flex-center position-relative min-vh-100 g-0 py-5">
      <div class="col-12 col-sm-12 col-md-10 col-xl-8">
        <div class="card border border-200 auth-card">
          <div class="card-body pe-md-0">
            <div class="row gx-0 gy-7">
              <div class="col-auto py-7 dark__bg-1100 rounded-3 position-relative overflow-hidden auth-title-box"
                   *ngIf="!isMobile">
                <div class="bg-holder"
                     style="background-image: url(/assets/img/background/38.png);"></div>
                <div
                  class="position-relative px-4 px-lg-7 pt-7 pb-7 pb-sm-5 text-center text-md-start pb-lg-7 card-sign-up">
                  <img
                    [src]="currentTheme === 'dark' ? './assets/img/logo/ivana2.png' : './assets/img/logo/logo.png'"
                    src="./assets/img/logo/ivana2.png"
                    class="mb-4"
                    alt="ivana logo"
                    width="240" />
                  <p class="text-700">
                    Inicia sesión y empieza a mejorar tu experiencia con IVANA.
                  </p>
                  <ul class="list-unstyled mb-0 w-max-content w-md-auto mx-auto">
                    <li class="d-flex align-items-center">
                      <span
                        class="uil uil-check-circle text-success me-2"></span>
                      <span
                        class="text-700 fw-semi-bold">
                        Rápido
                      </span>
                    </li>
                    <li class="d-flex align-items-center">
                      <span
                        class="uil uil-check-circle text-success me-2"></span>
                      <span
                        class="text-700 fw-semi-bold">
                        Automatizado
                      </span>
                    </li>
                    <li class="d-flex align-items-center">
                      <span
                        class="uil uil-check-circle text-success me-2"></span>
                      <span class="text-700 fw-semi-bold">
                        Eficaz
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col py-7 mx-auto">
                <div class="auth-form-box" *ngIf="!resettingPassword">
                  <a
                    class="d-flex flex-center text-decoration-none mb-2"
                    [routerLink]="['/']">
                    <div class="d-flex align-items-center d-inline-block">
                      <img
                        [src]="currentTheme === 'dark' ? './assets/img/logo/logo-icon-white.png' : './assets/img/logo/logo-icon-multi-color.png'"
                        alt="phoenix"
                        width="45"
                      />
                    </div>
                  </a>
                  <div class="text-center">
                    <h3 class="text-1000">
                      Iniciar sesión
                    </h3>
                    <p class="text-700">
                      Accede a tu cuenta
                    </p>
                  </div>
                  <form
                    [formGroup]="signInForm"
                    (submit)="signIn()">
                    <div class="mb-3 text-start">
                      <label class="form-label" for="username">
                        E-mail o nombre de usuario
                      </label>
                      <input
                        class="form-control"
                        id="username"
                        type="text"
                        placeholder="admin123 ó nombre@ejemplo.com"
                        formControlName="username"
                        [ngClass]="{
                          'is-invalid': signInForm.get('username').touched && signInForm.get('username').invalid,
                          'is-valid': signInForm.get('username').valid
                        }"
                      />
                      <div *ngIf="signInForm.get('username').touched && signInForm.get('username').invalid"
                           class="invalid-feedback">
                        Ingresa una dirección de correo electrónico válida.
                      </div>
                    </div>
                    <div class="mb-3 text-start">
                      <label class="form-label" for="password">Contraseña</label>
                      <input
                        class="form-control form-icon-input"
                        id="password"
                        type="password"
                        placeholder="Contraseña"
                        formControlName="password"
                        [ngClass]="{
                          'is-invalid': signInForm.get('password').touched && signInForm.get('password').invalid,
                          'is-valid': signInForm.get('password').valid
                        }"
                      />
                      <div class="invalid-feedback"
                           *ngIf="signInForm.get('password').touched && signInForm.get('password').invalid">
                        La contraseña es requerida y debe tener al menos 8 caracteres.
                      </div>
                    </div>
                    <div class="mb-3 text-end">
                      <label role="button" class="form-label" for="username" (click)="changeView()">
                        ¿Olvidaste tu contraseña?
                      </label>
                    </div>
                    <button
                      class="btn btn-indigo w-100 mb-2"
                      type="submit"
                      [disabled]="signInForm.invalid || loading">
                      {{ loading ? 'Iniciando sesión...' : 'Iniciar sesión' }}
                    </button>
                    <div class="invalid-feedback d-block mt-0 mb-2" *ngIf="error">
                      {{ error }}
                    </div>
                    <div class="text-center">
                      <a class="fs--0 text-deep-blue fw-bold mt-1" [routerLink]="['/precios']">
                        ¿No tienes una cuenta? Regístrate aquí
                      </a>
                    </div>
                  </form>
                </div>
                <div class="auth-form-box" *ngIf="resettingPassword">
                  <ng-container *ngIf="!showConfirmationMessage">
                    <a
                      class="d-flex flex-center text-decoration-none mb-4"
                       [routerLink]="['/']">
                      <div class="d-flex align-items-center d-inline-block">
                        <img
                          [src]="currentTheme === 'dark' ? './assets/img/logo/logo-icon-white.png' : './assets/img/logo/logo-icon-multi-color.png'"
                          alt="phoenix"
                          width="45"
                        />
                      </div>
                    </a>
                    <div class="text-center mb-2">
                      <h3 class="text-1000">
                        Recuperar contraseña
                      </h3>
                      <p class="text-700">
                        Ingresa tu correo de recuperación
                      </p>
                    </div>
                    <form
                      [formGroup]="resetPasswordForm" (click)="resetPassword()">
                      <div class="mb-3 text-start">
                        <label class="form-label" for="email">E-mail o nombre de usuario</label>
                        <input
                          class="form-control"
                          id="email"
                          type="text"
                          placeholder="nombre@ejemplo.com"
                          formControlName="email"
                          [ngClass]="{
                            'is-invalid': resetPasswordForm.get('email').touched && resetPasswordForm.get('email').invalid,
                            'is-valid': resetPasswordForm.get('email').valid
                          }"
                        />
                        <div *ngIf="resetPasswordForm.get('email').touched && resetPasswordForm.get('email').invalid"
                             class="invalid-feedback">
                          Ingresa una dirección de correoloadinglectrónico válida.
                        </div>
                      </div>
                      <button
                        class="btn btn-indigo w-100 mb-4"
                        type="submit"
                        [disabled]="resetPasswordForm.invalid">
                        {{ loading ? 'Enviando solicitud...' : 'Enviar correo' }}
                      </button>
                      <div class="invalid-feedback d-block mt-0 mb-2" *ngIf="error">
                        {{ error }}
                      </div>
                    </form>
                    <div class="text-center">
                      <a class="fs--0 fw-bold mt-1 cursor-pointer" (click)="changeView()">
                        ¿Ya tienes una cuenta? Inicia sesión
                      </a>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="showConfirmationMessage">
                    <a
                      class="d-flex flex-center text-decoration-none mb-4"
                      href="../../../index.html">
                      <div class="d-flex align-items-center d-inline-block">
                        <img
                          [src]="currentTheme === 'dark' ? './assets/img/logo/logo-icon-white.png' : './assets/img/logo/logo-icon-multi-color.png'"
                          alt="phoenix"
                          width="45"
                        />
                      </div>
                    </a>
                    <div class="text-center">
                      <h3 class="text-1000 mb-3">
                        ¡Mensaje enviado exitosamente!
                      </h3>
                      <p class="text-700 mb-0">
                        El correo se ha enviado correctamente.
                      </p>
                      <small class="text-700">
                        Por favor, revise su bandeja de entrada.
                      </small>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
