import { Subject } from "rxjs";

export class DataTableContainer {

  trigger: Subject<any> = new Subject();
  options: DataTables.Settings = {};
  instance: DataTables.Api;
  callback: any;
  pageLength: number = 10;

  constructor(pageLength: number, options: DataTables.Settings) {
    this.pageLength = pageLength;
    this.options = options;
  }

}