
export enum WhatsAppMessageTemplateComponentType {

  Header = 'HEADER',
  Body = 'BODY',
  Footer = 'FOOTER',
  Buttons = 'BUTTONS',
  Button = 'BUTTON',

}
