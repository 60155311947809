import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
  Observable,
  of,
  OperatorFunction,
  Subject,
  Subscription,
  switchMap,
} from 'rxjs';
import { ChatInfo } from '@app/models/chat/chat/chat-info.dto';
import { ChatSearchRequest } from '@app/models/chat/chat/chat-search-request.dto';
import { ChatTagInfo } from '@app/models/chat/tag/chat-tag-info.dto';
import { ChatTagSearchRequest } from '@app/models/chat/tag/chat-tag-search-request.dto';
import { Page } from '@app/models/common/page';
import { UserInfo } from '@app/models/account/user/user.info.dto';
import { AuthService } from '@app/services/auth/auth.service';
import { ChatTagService } from '@app/services/chat/chat-tag.service';
import { ChatService } from '@app/services/chat/chat.service';
import { EventEmitterService, NotificationTopic } from '@app/services/data/event-emitter.service';
import { ChatTagSearchType } from '@app/type/chat/chat-tag-search.type';
import { getLastMessageTypeLabel, getMessageStatusClassString } from '@app/utils/chat-utils';
import { dateFormatter, formatTimestamp } from '@app/utils/date-utils';
import { ChatSearchDangeRangeFieldType } from '@type/chat/chat-search-dange-range-field.type';
import { UserSearchRequest } from '@app/models//account/user/user-search-request.dto';
import { UserService } from '@services/account/user.service';
import { UserRoleType } from '@type/account/user-role.type';
import { ShopInfo } from '@models/account/shop/shop-info.dto';
import { MessageStatus } from '@type/chat/message-status.type';
import { MessageInfo } from '@models/chat/message/message-info.dto';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MessageType } from '@type/chat/message-content.type';
import { WhatsAppConfigInfo } from '@models/config/whatsapp/whatsapp-config-info.dto';
import { WhatsAppConfigService } from '@services/config/whatsapp-config.service';

export enum ChatListTab {
  All = 'all',
  NotSeen = 'not-seen',
  Unanswered = 'unanswered',
}

@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.css'],
})
export class ChatListComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(CdkVirtualScrollViewport, {
    static: false,
  })
  viewport: CdkVirtualScrollViewport;

  protected readonly ChatTagSearchType = ChatTagSearchType;
  protected readonly UserRoleType = UserRoleType;

  public whatsAppConfig: WhatsAppConfigInfo;

  public ChatListTab = ChatListTab;
  public selectedTab: ChatListTab = ChatListTab.All;
  public selectedSearchType: ChatTagSearchType = ChatTagSearchType.AtLeastOne;

  @Input()
  public fromContactsComponent: boolean = false;

  private componentSubscriptions: Subscription[] = [];

  private readonly user: UserInfo;
  public readonly shop: ShopInfo;
  private chat: ChatInfo;
  public chats: ChatInfo[] = [];
  public totalChats: number = 0;
  public loadingChats: boolean = false;
  public filtering: boolean = false;
  public chatSearchRequest: ChatSearchRequest;

  // Chat Tags
  @ViewChild('instance', { static: true }) instance: any;
  public focus$ = new Subject<string>();
  public click$ = new Subject<string>();
  public formatResult = (value: ChatTagInfo) => value.name;
  public formatInput = (value: ChatTagInfo) => value.name;
  public tagsFormControl: FormControl;
  public chatTagsAll: ChatTagInfo[] = [];
  public chatTagsAvailable: ChatTagInfo[] = [];
  public chatTagsChosen: ChatTagInfo[] = [];

  //Chat Advisors
  @ViewChild('advisors', { static: true }) advisors: any;
  public focusAdvisor$ = new Subject<string>();
  public clickAdvisor$ = new Subject<string>();
  public formatResultAdvisor = (value: UserInfo) => value.name;
  public formatInputAdvisor = (value: UserInfo) => value.name;
  public advisorsFormControl: FormControl;
  public chatAdvisorsAll: UserInfo[] = [];
  public chatAdvisorsAvailable: UserInfo[] = [];
  public chatAdvisorsChosen: UserInfo[] = [];

  public isAdvisor: boolean;
  public chatSearchTermFormControl: FormControl;

  public currentDate: Date = new Date();
  public rangeValue: any = {};
  public formatDate = dateFormatter;
  public ChatSearchDangeRangeFieldType = ChatSearchDangeRangeFieldType;

  constructor(
    private chatService: ChatService,
    private chatTagService: ChatTagService,
    private userService: UserService,
    private authService: AuthService,
    private eventEmitterService: EventEmitterService,
    private whatsAppConfigService: WhatsAppConfigService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.user = this.authService.getUser();
    this.shop = this.user.shop;
    this.isAdvisor = this.user.role === UserRoleType.ChatAdvisor;
  }

  ngOnInit() {
    this.initializeChatSearchRequest();
    this.loadChats(false, () => this.checkChatRoute());
    this.loadChatTags();
    this.loadChatAdvisors();
    this.initializeChatSearchTermEvent();
    this.initializeOnChatRequiresAssistanceEvent();
    this.initializeOnMessageReceivedEvent();
    this.initializeOnMessageStatusSubscription();
    this.initializeOnArchiveChat();
    this.initializeOnSeenChat();
    this.checkWhatsAppConfig();
    this.initializeChatTagEventEmitter();
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {

    // Component subscriptions
    this.componentSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }


  public initializeChatTagEventEmitter() {
    const selectedTagEventEmitter = this.eventEmitterService.getEventEmitter(NotificationTopic.SelectedChatTag);
    const removeTagEventEmitter = this.eventEmitterService.getEventEmitter(NotificationTopic.RemoveSelectedChatTag);

    const selectedTagSubscription = selectedTagEventEmitter.subscribe((eventEmitterRequest: any) => {
      const { chatId, chatTag } = eventEmitterRequest;
      const chat = this.chats.find(c => c.id === chatId);
      if (chat) {
        chat.chatTags = chat.chatTags || [];
        chat.chatTags.unshift(chatTag);
      }
    });

    const removeTagSubscription = removeTagEventEmitter.subscribe((eventEmitterRequest: any) => {
      const { chatId, chatTag } = eventEmitterRequest;
      const chat = this.chats.find(c => c.id === chatId);
      if (chat) {
        const chatTagIndex = chat.chatTags.findIndex(ct => ct.id === chatTag.id);
        chat.chatTags.splice(chatTagIndex, 1);
      }
    });

    this.componentSubscriptions.push(selectedTagSubscription);
    this.componentSubscriptions.push(removeTagSubscription);
  }


  private checkWhatsAppConfig() {

    this.whatsAppConfigService.getConfig().subscribe({
      next: (whatsAppConfig: WhatsAppConfigInfo) => {
        console.log('WhatsApp config:', whatsAppConfig);
        this.whatsAppConfig = whatsAppConfig;
      },
      error: (error: any) => {
        console.error('Error fetching WhatsApp config:', error);
      },
    });

  }

  public shouldShowEmptyChats(): boolean {
    return !this.loadingChats && this.chats.length === 0;
  }

  public shouldShowConfig(): boolean {

    if (this.loadingChats) return false;
    if (this.whatsAppConfig === undefined) return true;
    if (this.whatsAppConfig?.wabaId) return false;
    if (!this.whatsAppConfig.whatsappWebInstanceStatus.status) return true;

    return false;
  }

  private initializeOnSeenChat() {

      const eventEmitter = this.eventEmitterService.getEventEmitter(NotificationTopic.ChatSeen);

      const subscription = eventEmitter.subscribe((data: any) => {

        const { chatId, seen } = data;

        const chat = this.chats.find(c => c.id === chatId);
        if (chat) chat.seen = seen;
      });

      this.componentSubscriptions.push(subscription);
  }

  private initializeOnArchiveChat() {

    const eventEmitter = this.eventEmitterService.getEventEmitter(NotificationTopic.ChatArchive);

    const subscription = eventEmitter.subscribe((data: any) => {

      const { chatId, archive } = data;

      const chatIndex = this.chats.findIndex(c => c.id === chatId);
      const chat = this.chats[chatIndex];
      if (chat) {
        this.chats.splice(chatIndex, 1);
      } else {
        if ((this.chatSearchRequest.archived && archive) || (!this.chatSearchRequest.archived && !archive)) {
          this.chatService.getChatById(chatId)
            .subscribe(chat => {
              chat.lastMessageTypeLabel = getLastMessageTypeLabel(chat);
              this.chats = [chat, ...this.chats];
            });
        }
      }

      if (this.totalChats > 0)
        this.totalChats--;
    });

    this.componentSubscriptions.push(subscription);
  }

  private initializeOnMessageReceivedEvent() {

    const eventEmitter = this.eventEmitterService.getEventEmitter(NotificationTopic.MessageReceived);

    const subscription = eventEmitter.subscribe((data: any) => {

      const { message, chat } = data;

      // Buscar la conversación actual en la lista de chats disponibles
      const chatIndex = this.chats.findIndex(c => c.id === message.chatId);
      const chatFound = this.chats[chatIndex];

      // Si la conversación se encontró en la lista de contactos actual del UI
      if (chatFound) {

        if (!chatFound.chatAlias) chatFound.chatName = chat.chatName;

        if (chatFound.userId !== this.user.id && this.user.role !== UserRoleType.Admin) return;

        this.updateChatReceivedMessage(chatFound, message);

        // Si la conversación no está en la parte superior, moverla hacia arriba en la lista de chats
        if (chatIndex !== 0) {
          // Elimina el elemento en chatIndex y guarda el resultado en una variable temporal
          const updatedChats = this.chats.slice();
          updatedChats.splice(chatIndex, 1);
          // Agrega chatFound al inicio del array actualizado
          updatedChats.unshift(chatFound);
          // Reasigna el array this.chats
          this.chats = updatedChats;
        }

        // Si el mensaje recibido es para la conversación abierta
        if (this.chat && chatFound.id === this.chat.id) {
          // Realizar alguna acción específica para el mensaje en la conversación abierta
          // Esto se deja vacío en el código proporcionado y puede requerir implementación adicional
        } else {

          if (message.customerMessage) {
            chatFound.seen = false;
            chatFound.answered = false;
            new Audio('assets/audio/complete_quest_requirement.mp3').play().then(() => {
            });
          }
        }
      }
      // Si la conversación no está en el UI y se tiene que obtener del service
      else {
        if (this.user.role === UserRoleType.Admin || chat.userId === this.user.id) {
          chat.lastMessageTypeLabel = getLastMessageTypeLabel(chat);
          this.updateChatReceivedMessage(chat, message);
          this.chats = [chat, ...this.chats];
        }
      }

    });

    this.componentSubscriptions.push(subscription);
  }

  private updateChatReceivedMessage(chat: ChatInfo, message: MessageInfo) {

    // Actualizar la información del último mensaje en la conversación encontrada
    chat.lastMessageIdentifier = message.identifier;
    chat.lastMessageContent = message.content;
    chat.lastMessageFileName = message.fileName;
    chat.lastMessageFileUrl = message.fileUrl;
    chat.lastMessageType = message.type;
    chat.lastMessageSentTime = formatTimestamp(message.sentTime);
    chat.lastMessageTypeLabel = getLastMessageTypeLabel(chat);

    if (message.customerMessage) {
      chat.lastMessageStatusClass = null;
    } else {
      chat.seen = true;
      chat.answered = true;
      if (!message.id) {
        chat.lastMessageStatus = MessageStatus.Sent;
        chat.lastMessageStatusClass = getMessageStatusClassString(chat.lastMessageStatus);
      } else {
        // chatFound.lastMessageStatus = message.status;
      }
    }


  }

  private initializeOnMessageStatusSubscription() {
    const eventEmitter = this.eventEmitterService.getEventEmitter(NotificationTopic.MessageStatus);

    const subscription = eventEmitter.subscribe((data: any) => {

      const message: MessageInfo = data as MessageInfo;
      const chat: ChatInfo = this.chats.find(c => c.id === message.chatId);

      const updateChat = (chat: ChatInfo) => {

        switch (message.type) {
          case MessageType.Text:
          case MessageType.Interactive:
            chat.lastMessageContent = message.content;
            break;
          case MessageType.Template:
            chat.lastMessageContent = message.messageTemplateBodyContent;
        }

        chat.lastMessageType = message.type;
        chat.lastMessageFileName = message.fileName;
        chat.lastMessageStatus = message.status;
        chat.lastMessageTypeLabel = getLastMessageTypeLabel(chat);
        chat.lastMessageStatusClass = getMessageStatusClassString(message.status);

        if (!message.customerMessage) {
          chat.seen = true;
          chat.answered = true;
        }
      };

      if (!chat) {
        this.chatService.getChatById(message.chatId).subscribe({
          next: (chat: ChatInfo) => {
            // Rechequeo si el chat ya está presente en la lista
            let existingChat = this.chats.find(c => c.id === message.chatId);
            if (!existingChat) {
              updateChat(chat);
              this.chats = [chat, ...this.chats];
            } else {
              updateChat(existingChat);
            }
          },
          error: (err) => {
            console.error(`Error fetching chat with id ${message.chatId}:`, err);
          },
        });
      } else {
        updateChat(chat);
      }
    });

    this.componentSubscriptions.push(subscription);
  }

  private initializeOnChatRequiresAssistanceEvent() {

    const eventEmitter = this.eventEmitterService.getEventEmitter(NotificationTopic.ChatRequiresAssistance);

    const subscription: Subscription = eventEmitter.subscribe((data: any) => {
      const { chatId, humanAssistantAttention } = data;
      const chat = this.chats.find(c => c.id === chatId);
      if (chat) chat.humanAssistantAttention = humanAssistantAttention;
    });

    this.componentSubscriptions.push(subscription);
  }

  checkChatRoute() {

    const params = this.route.snapshot.queryParamMap;

    const chatUuid: string = params.get('id');
    if (chatUuid) {
      const chatFound = this.chats.find(c => c.uuid === chatUuid);
      if (chatFound) {
        this.selectChat(chatFound);
      } else {
        this.getAndShowChat(chatUuid);
      }
    } else {
      if (!this.fromContactsComponent) {
        this.unselectChat();
      }
    }
  }

  getAndShowChat(chatUuid: string) {
    this.chatService.getChatByUuid(chatUuid).subscribe({
      next: (chat: ChatInfo) => {

        this.chat = chat;
        this.chat.seen = true;
        this.chat.selected = true;

        this.eventEmitterService.emit(NotificationTopic.ChatSelected, chat);
      },
      error: (error: any) => {
        console.error('Error en la carga de chat:', error);
      },
    });
  }

  unselectChat() {
    if (this.chat) this.chat.selected = false;
    this.chat = undefined;
    this.eventEmitterService.emit(NotificationTopic.ChatSelected, this.chat);
  }

  toggleFilter() {
    this.filtering = !this.filtering;
  }

  initializeChatSearchRequest() {
    this.chatSearchRequest = new ChatSearchRequest(
      this.user.shop.id, 30, this.user,
    );
  }

  initializeChatSearchTermEvent() {

    this.chatSearchTermFormControl = new FormControl('');

    this.chatSearchTermFormControl
      .valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((searchTerm: string) => {
        return of(searchTerm.trim());
      }),
    )
      .subscribe((searchTerm: string) => {
        this.chatSearchRequest.searchTerm = searchTerm;
        this.loadChats(true);
      });
  }

  private loadChats(filtering: boolean, callback?: () => void) {

    this.loadingChats = true;

    if (filtering) {
      this.chatSearchRequest.page = 0;
      this.chats = [];
    }

    this.chatService.searchChats(this.chatSearchRequest).subscribe({
      next: (chatsPage: Page<ChatInfo>) => {
        this.loadingChats = false;
        this.formatChats(chatsPage.content);
        if (!filtering)
          this.chats = [...this.chats, ...chatsPage.content];
        else
          this.chats = chatsPage.content;
        this.totalChats = chatsPage.totalElements;
        (window as any).phoenix.initEverything();
        callback && callback();
      },
      error: (error: any) => {
        console.error('Error en la carga de chats:', error);
      },
    });

  }

  onScrolled() {

    const scrollOffset = this.viewport.measureScrollOffset('bottom');
    if (scrollOffset <= 300) {
      if (this.loadingChats) return;
      this.chatSearchRequest.page++;
      this.loadChats(false);
    }
  }

  private formatChats(chats: ChatInfo[]) {
    for (const chat of chats) {
      chat.lastMessageTypeLabel = getLastMessageTypeLabel(chat);
      chat.lastMessageSentTime = formatTimestamp(chat.lastMessageSentTime);
      chat.lastMessageStatusClass = getMessageStatusClassString(chat.lastMessageStatus);
    }
  }

  loadChatTags() {
    const infiniteSize: number = -1;

    const searchRequest = new ChatTagSearchRequest(
      this.user.shop.id,
      infiniteSize,
    );

    this.chatTagService.searchChatTags(searchRequest).subscribe({
      next: (chatTagsPage: Page<ChatTagInfo>) => {
        this.chatTagsAvailable = chatTagsPage.content;
        this.chatTagsAll = [...chatTagsPage.content];
      },
      error: (error: any) => {
        console.error('Error en la carga de tags:', error);
      },
    });

    this.initializeChatTagsChosenEvent();
  }

  initializeChatTagsChosenEvent() {

    this.tagsFormControl = new FormControl('');

    this.tagsFormControl.valueChanges.subscribe((value: ChatTagInfo) => {
      if (value) {
        this.chatTagsChosen.push(value);
        this.tagsFormControl.setValue(null);

        const chatTagChosenIndex = this.chatTagsAvailable.indexOf(value);
        this.chatTagsAvailable.splice(chatTagChosenIndex, 1);
        this.chatTagsAvailable.sort((ct1, ct2) => ct2.id - ct1.id);
      }
    });
  }

  removeChatTag(chatTag: ChatTagInfo) {
    const chatTagChosenIndex = this.chatTagsChosen.indexOf(chatTag);
    this.chatTagsChosen.splice(chatTagChosenIndex, 1);

    this.chatTagsAvailable.push(chatTag);
    this.chatTagsAvailable.sort((ct1, ct2) => ct2.id - ct1.id);
  }

  selectChat(chat: ChatInfo) {

    const previousChat = this.chats.find((c: ChatInfo) => c.selected);
    if (previousChat) previousChat.selected = false;

    if (chat.selected) {
      this.eventEmitterService.emit(NotificationTopic.SameChatSelected);
      return;
    }

    this.chat = chat;
    this.chat.seen = true;
    this.chat.selected = true;

    this.eventEmitterService.emit(NotificationTopic.ChatSelected, chat);

    this.router.navigate(['app/chat'], {
      queryParams: {
        id: this.chat.uuid,
      },
    }).then(() => {

    });
  }

  /* Advisors */
  loadChatAdvisors() {
    const noSize: number = -1;

    const searchRequest = new UserSearchRequest(
      this.user.shop.id,
      noSize,
    );

    this.userService.searchUsers(searchRequest).subscribe({
      next: (chatAdvisorsPage: Page<UserInfo>) => {
        this.chatAdvisorsAvailable = chatAdvisorsPage.content;
        this.chatAdvisorsAll = chatAdvisorsPage.content;
      },
      error: (error: any) => {
        console.error('Error en la carga de asesores:', error);
      },
    });

    this.initializeChatAdvisorsChosenEvent();
  }

  private initializeChatAdvisorsChosenEvent() {

    this.advisorsFormControl = new FormControl('');

    this.advisorsFormControl.valueChanges.subscribe((value: UserInfo) => {
      if (value) {
        this.chatAdvisorsChosen.push(value);
        this.advisorsFormControl.setValue(null);

        const chatAdvisorChosenIndex = this.chatAdvisorsAvailable.indexOf(value);
        this.chatAdvisorsAvailable.splice(chatAdvisorChosenIndex, 1);
        this.chatAdvisorsAvailable.sort((ct1, ct2) => ct2.id - ct1.id);
      }
    });
  }

  public removeChatAdvisor(chatAdvisor: UserInfo) {
    const chatAdvisorChosenIndex = this.chatAdvisorsChosen.indexOf(chatAdvisor);
    this.chatAdvisorsChosen.splice(chatAdvisorChosenIndex, 1);

    this.chatAdvisorsAvailable.push(chatAdvisor);
    this.chatAdvisorsAvailable.sort((ct1, ct2) => ct2.id - ct1.id);
  }

  /* Filter Section */
  public searchChatTags: OperatorFunction<string, readonly ChatTagInfo[]> = (
    text$: Observable<string>,
  ) => {

    const debouncedText$ = text$.pipe(debounceTime(1), distinctUntilChanged());

    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen()),
    );

    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === '' ? this.chatTagsAvailable : this.chatTagsAvailable.filter(
            (ct) => ct.name.toLowerCase().indexOf(term.toLowerCase()) > -1,
          )
        ).slice(0, 10),
      ),
    );
  };

  public searchChatAdvisors: OperatorFunction<string, readonly UserInfo[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(1), distinctUntilChanged());

    const clicksWithClosedPopup$ = this.clickAdvisor$.pipe(
      filter(() => !this.advisors.isPopupOpen()),
    );

    const inputFocus$ = this.focusAdvisor$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ''
            ? this.chatAdvisorsAvailable
            : this.chatAdvisorsAvailable.filter(
              (ct) => ct.name.toLowerCase().indexOf(term.toLowerCase()) > -1,
            )
        ).slice(0, 10),
      ),
    );
  };

  public showOpenNewMessage() {
    if (this.whatsAppConfig?.wabaId) {
      this.eventEmitterService.emit(NotificationTopic.ShowSendTemplateMessage);
    } else {
      this.eventEmitterService.emit(NotificationTopic.ShowSendWhatsAppWebMessage);
    }
  }

  public sortChats() {
    let sortDirection: string;
    if (this.chatSearchRequest.sortDirection === 'DESC') {
      sortDirection = 'ASC';
    } else {
      sortDirection = 'DESC';
    }
    this.chatSearchRequest.sortDirection = sortDirection;
    this.chatSearchRequest.page = 0;
    this.loadChats(true);
  }

  public toggleNotSeen() {
    this.chatSearchRequest.notSeen = !this.chatSearchRequest.notSeen;
  }

  public toggleUnanswered() {
    this.chatSearchRequest.unanswered = !this.chatSearchRequest.unanswered;
  }

  public toggleArchived() {
    this.chatSearchRequest.archived = !this.chatSearchRequest.archived;
  }

  public filterAllChats() {
    this.selectedTab = ChatListTab.All;
    this.chatSearchRequest.page = 0;
    this.chatSearchRequest.notSeen = false;
    this.chatSearchRequest.unanswered = false;
    this.chatSearchRequest.archived = false;
    this.filterChats();
  }

  public filterNotSeenChats() {
    this.selectedTab = ChatListTab.NotSeen;
    this.chatSearchRequest.page = 0;
    this.chatSearchRequest.notSeen = true;
    this.chatSearchRequest.unanswered = false;
    this.chatSearchRequest.archived = false;
    this.filterChats();
  }

  public filterUnanswered() {
    this.selectedTab = ChatListTab.Unanswered;
    this.chatSearchRequest.page = 0;
    this.chatSearchRequest.unanswered = true;
    this.chatSearchRequest.notSeen = false;
    this.chatSearchRequest.archived = false;
    this.filterChats();
  }

  public filterByDate(value: any) {

    const { selectedDates } = value;
    const startDate = selectedDates[0];
    const endDate = selectedDates[1];

    endDate.setHours(this.currentDate.getHours(), this.currentDate.getMinutes(), 0, 0);

    if (!startDate || !endDate) return;

    this.chatSearchRequest.fromDate = startDate.getTime();
    this.chatSearchRequest.toDate = endDate.getTime();
  }

  public changeDateRangeFieldType(dateRangeFieldType: ChatSearchDangeRangeFieldType) {
    this.chatSearchRequest.dateRangeFieldType = dateRangeFieldType;
  }

  public clearDateRange() {
    this.rangeValue = {};
    this.chatSearchRequest.fromDate = null;
    this.chatSearchRequest.toDate = null;
    this.chatSearchRequest.dateRangeFieldType = ChatSearchDangeRangeFieldType.LastMessageDate;
  }

  public filterChats() {

    // Tags
    if (this.chatTagsChosen.length > 0) {
      this.chatSearchRequest.chatTagIds = this.chatTagsChosen.map((ct) => ct.id);
      this.chatSearchRequest.chatTagSearchType = this.selectedSearchType;
    }

    if (this.chatAdvisorsChosen.length > 0) {
      this.chatSearchRequest.userIds = this.chatAdvisorsChosen.map((ca) => ca.id);
    }

    this.loadChats(true, () => {
      if (this.chat) {
        const selectedChat = this.chats.find(c => c.id === this.chat.id);
        if (selectedChat) selectedChat.selected = true;
      }
    });

    this.filtering = false;
  }

  public selectSearchType(searchType: ChatTagSearchType) {
    this.selectedSearchType = searchType;
  }

  public clearChatFilter() {

    this.chatTagsAvailable.push(...this.chatTagsChosen);
    this.chatTagsAvailable.sort((ct1, ct2) => ct2.id - ct1.id);

    this.chatTagsChosen = [];

    this.clearDateRange();

    this.initializeChatSearchRequest();
    this.loadChats(true);
    this.filtering = false;
  }

}
