import { MessageStatus } from '@type/chat/message-status.type';
import { MessageType } from '@type/chat/message-content.type';
import { ChatInfo } from '@models/chat/chat/chat-info.dto';
import { Router, UrlTree } from '@angular/router';
import { MessageInfo } from '@models/chat/message/message-info.dto';

export const getMessageStatusClassString = (status: MessageStatus) => {
  switch (status) {
    case MessageStatus.Pending:
      return 'far fa-clock';
    case MessageStatus.Sent:
      return 'fa-solid fa-check';
    case MessageStatus.Delivered:
      return 'fa-solid fa-check-double';
    case MessageStatus.Read:
      return 'fa-solid fa-check-double text-info-500';
    case MessageStatus.Failed:
      return 'fa-solid fa-ban text-dark';
    default:
      return '';
  }
};

export const normalizeText = (text: string) => {

  // First, convert all the text to lowercase
  let normalizedText = text.toLowerCase();

  // Then, replace accented characters
  const accentsMap = {
    'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u',
    'Á': 'a', 'É': 'e', 'Í': 'i', 'Ó': 'o', 'Ú': 'u',
    'ñ': 'n', 'Ñ': 'n',
    // You can add more characters here if necessary
  };

  normalizedText = normalizedText.split('').map(char => accentsMap[char] || char).join('');

  return normalizedText;
};

export const getFormattedMessageTime = (timestamp: string) => {

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // Formato de 24 horas
  };

  const date = new Date(parseInt(timestamp, 10) * 1000);

  return date.toLocaleString('es-ES', options);
};

export const getFormattedFileSize = (size: number) => {
  if (size < 1024) {
    return `${size} B`;
  }
  if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)} KB`;
  }
  return `${(size / 1024 / 1024).toFixed(2)} MB`;
};

export const getLastMessageTypeLabel = (chat: ChatInfo) => {

  const { lastMessageType, lastMessageContent, lastMessageFileName } = chat;

  switch (lastMessageType) {
    case MessageType.Text:
    case MessageType.Template:
    case MessageType.Button:
    case MessageType.Interactive:
      return lastMessageContent;
    case MessageType.Image:
      return 'Foto';
    case MessageType.Sticker:
      return 'Sticker';
    case MessageType.Video:
      return 'Video';
    case MessageType.Audio:
      return 'Audio';
    case MessageType.Location:
      return 'Ubicación';
    case MessageType.Contact:
      return 'Contacto';
    case MessageType.Document:
      return lastMessageFileName;
    case MessageType.Reaction:
      return 'Reacción';
    case MessageType.Note:
      return 'Nota';
    default:
      return 'Sin mensajes';
  }

};

export const getMessageTypeLabel = (message: MessageInfo) => {

  const { type, content, fileName } = message;

  switch (type) {
    case MessageType.Text:
    case MessageType.Template:
    case MessageType.Button:
    case MessageType.Interactive:
      return content;
    case MessageType.Image:
      return 'Foto';
    case MessageType.Sticker:
      return 'Sticker';
    case MessageType.Video:
      return 'Video';
    case MessageType.Audio:
      return 'Audio';
    case MessageType.Location:
      return 'Ubicación';
    case MessageType.Contact:
      return 'Contacto';
    case MessageType.Document:
      return fileName;
    case MessageType.Reaction:
      return 'Reacción';
    case MessageType.Note:
      return 'Nota';
    default:
      return 'Sin mensajes';
  }

};

export const getChatUrl = (chat: ChatInfo, router: Router): string => {
  const chatUuid: string = chat.uuid;
  const url: string = `/app/chat`;
  const queryParams: any = { id: chatUuid };
  const urlTree: UrlTree = router.createUrlTree([url], { queryParams });
  return router.serializeUrl(urlTree);
}