<div class="ecommerce-homepage pt-5 mb-9">
  <section class="pb-8" id="home">
    <app-app-list-header></app-app-list-header>
  </section>
  <section class="container-md bg-white p-10">
    <app-list-populars [apps]="apps"></app-list-populars>
  </section>
  <section class="pb-14 overflow-x-hidden">
    <app-favorite-apps-slider [apps]="apps"></app-favorite-apps-slider>
  </section>


  <section class="py-0 px-xl-3">
    <div class="container-md p-10">
      <h3>Otras aplicaciones utiles</h3>
      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-4 g-4 mt-3">
        <div class="col" *ngFor="let app of apps">
          <div class="h-100">
            <div class=" position-relative text-decoration-none product-card h-100 d-flex align-items-start">
              <div class=" position-relative mb-3 me-3">
                <img class="img-size rounded" [src]="'./assets/img/placeholder/no_image_found-light.webp'" alt="" />
              </div>
              <div class="flex-grow-1">
                <a class="stretched-link" (click)="goToAppDetails(app.id)">
                  <h5 class="mb-2 lh-sm line-clamp-3 product-name">{{ app.name }}</h5>
                </a>
                <p class="mb-1 fs--1">
                  <span class="">{{ app.generalRating }}</span>
                  <span class="fa fa-star text-warning"></span>
                  <span class="text-500 fw-semi-bold ms-1">({{ app.totalReviews }})</span>
                  <span class="mx-2">•</span>
                  <span class="text-800 fw-semi-bold">Gratis</span>
                </p>
                <div class="d-flex align-items-center mb-1">
                  <p class="text-700 fw-semi-bold line-clamp-2">{{ app.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</div>