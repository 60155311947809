import { Component, OnInit } from "@angular/core";
import { loadStyles, removeStyles } from '@app/utils/page-utils';

@Component({
  selector: 'app-common-layout',
  template: `
      <app-preloader *ngIf="!stylesLoaded"></app-preloader>
      <router-outlet *ngIf="stylesLoaded"></router-outlet>
  `,
})
export class CommonLayoutComponent implements OnInit {

  public stylesLoaded: boolean = false;

  constructor(
  ) {

  }

  ngOnInit() {

    removeStyles('landing-page');

    this.loadStyles().then(() => {
      this.stylesLoaded = true;
    });
  }

  private loadStyles() {

    const styles: string[] = [
      '../../../assets/lib/phoenix/css/theme.css',
      '../../../assets/lib/phoenix/css/user.css',
    ];

    return loadStyles(styles, 'app');
  }

}
