<div *ngIf="message" class="mb-1 p-3 rounded-2" [ngClass]="{
    'received-message-content border': message.customerMessage, 
    'sent-message-content bg-primary': !message.customerMessage, 
  }">
  <app-chat-context-message
    *ngIf="message.contextMessage"
    [message]="message.contextMessage"
    [chat]="chat">
  </app-chat-context-message>
  <div class="card border-0" [ngClass]="{
      'text-white': message.customerMessage, 
    }" *ngIf="whatsAppMessageTemplate as template">
    <ng-container *ngIf="template.headerComponent as header">
      <img 
        *ngIf="header && header.format === WhatsAppMessageTemplateComponentHeaderType.Image"
        class="card-img-top"
        [src]="message.fileUrl">
    </ng-container>
    <div class="card-body">
      <ng-container *ngIf="template.headerComponent as header">
        <p *ngIf="header && header.format == WhatsAppMessageTemplateComponentHeaderType.Text">
          {{ message.messageTemplateHeaderContent }}
        </p>
      </ng-container>
      <pre 
        class="card-text mb-0"
        style="white-space: pre-wrap;">{{ message.messageTemplateBodyContent }}</pre>
      <p 
        class="mt-3 pb-1 mb-0 text-muted fs--1" 
        *ngIf="template.footerComponent as footer">
        {{ message.messageTemplateFooterContent }}
      </p>
    </div>
    <div class="card-footer py-2" *ngIf="template.buttonsComponent as buttons">
      <div class="d-grid">
        <ng-container *ngFor="let button of buttons.buttons; let last = last;">
          <button type="button" class="btn btn-phoenix-primary mb-1">
            <i class="fa fa-reply me-1"></i>
            {{ button.text }}
          </button>
        </ng-container>
      </div>
    </div>
  </div>
  <span class="reaction-emoji" *ngIf="message.reactionEmoji">
    {{ message.reactionEmoji }}
  </span>
</div>