import { DataTablesModule } from "angular-datatables";
import { ToastrModule } from "ngx-toastr";
import { PickerModule } from "@ctrl/ngx-emoji-mart";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { NgxSpinnerModule } from "ngx-spinner";
import { FlatpickrModule } from "angularx-flatpickr";
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { LightgalleryModule } from 'lightgallery/angular';
import { ScrollingModule } from '@angular/cdk/scrolling';

export const commonLibraryModules = [
  DataTablesModule,
  ToastrModule.forRoot(),
  PickerModule,
  ScrollingModule,
  InfiniteScrollModule,
  NgxSpinnerModule,
  FlatpickrModule.forRoot(),
  FullCalendarModule,
  NgxJsonViewerModule,
  LightgalleryModule,
];