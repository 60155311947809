import { Component, Input, OnInit } from '@angular/core';
import { SubscriptionService } from "../../../services/subscription/subscription.service";
import { SubscriptionTypeInfo } from "../../../models/subscription/subscription-type-info.dto";
import { SubscriptionType } from "../../../type/subscription/subscription.type";
import { Router } from "@angular/router";
import { trialPeriodDays } from "../../../constants/subscription-constants";

@Component({
  selector: "app-pricing-list-component",
  templateUrl: `./pricing-list.component.html`,
  styleUrls: ["./pricing-list.component.css"],
})
export class PricingListComponent implements OnInit {

  @Input()
  public delay: boolean = true;
  public trialPeriodDays: number = trialPeriodDays;
  public subscriptionTypes: SubscriptionTypeInfo[] = [];

  constructor(
    private subscriptionService: SubscriptionService,
    private router: Router,
  ) {

  }

  ngOnInit() {
    this.fillSubscriptionTypes();
  }

  public selectSubscriptionType(subscriptionType: SubscriptionTypeInfo) {
    this.router.navigate(['/app/sign-up', subscriptionType.type]).then(() => {

    });
  }

  private fillSubscriptionTypes() {
    this.subscriptionService.getSubscriptionTypes().subscribe({
      next: (subscriptionTypes: SubscriptionTypeInfo[]) => {
        this.subscriptionTypes = subscriptionTypes;
        this.updateSubscriptionTypes(subscriptionTypes);
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  private updateSubscriptionTypes(subscriptionTypes: SubscriptionTypeInfo[]) {
    for (let i = 0; i < subscriptionTypes.length; i++) {
      const subscriptionType = subscriptionTypes[i];
      if (this.delay) {
        subscriptionType.aosDelay = (i + 1) * 500;
      }
      if (subscriptionType.type === SubscriptionType.Advanced)
        subscriptionType.mostPopular = true;
    }
  }
}
