import { MessageType } from "@app/type/chat/message-content.type";
import { ChannelType } from "@app/type/communication/channel.type";
import { FileMetadata } from "../../media/file-metadata.dto";
import { SendMessageRecipientRequest, SendWhatsAppMessageRequest } from "@models/chat/message/whatsapp/whatsapp-message-requests.dto";
import { SendMessengerMessageRequest } from "@models/chat/message/messenger/messenger-message-requests.dto";

export class SendMessageRequest {

  // Custom attributes that we need to send a message
  shopId: number;
  identifier: string;
  channelType: ChannelType;
  recipient: SendMessageRecipientRequest;
  type: MessageType;
  fileMetadata: FileMetadata;
  userId?: number;
  senderId?: number;
  sentBy?: string;
  // If the message that we are trying to send is a whatsapp message
  sendWhatsAppMessageRequest: SendWhatsAppMessageRequest;
  // If the message that we are trying to send is a messenger message
  sendMessengerMessageRequest: SendMessengerMessageRequest;
  // There will be other types of messages here...

}

