<div class="card shadow-none border border-300 smart-assistant-orchestrator-list">
  <div class="card-header px-4 py-3 border-bottom border-300 bg-soft">
    <div class="row g-3 justify-content-between align-items-center">
      <div class="col-12 col-md">
        <h4 class="text-900 mb-0">
          <i class="fa-duotone fa-wand-magic-sparkles text-primary"></i>
          Orquestadores
        </h4>
      </div>
      <div class="col col-md-auto">
        <nav class="nav nav-underline justify-content-end doc-tab-nav align-items-center" role="tablist">
          <button type="button"
                  class="btn btn-sm btn-phoenix-primary"
                  (click)="openSaveSmartAssistantOrchestrator()">
            <i class="fa fa-plus"></i>
          </button>
        </nav>
      </div>
    </div>
    <div class="row">
      <p class="text-700 lh-sm-1 mt-1 my-0">
        Orquesta tus asistentes de manera eficiente.
      </p>
    </div>
  </div>
  <div class="card-body smart-assistants-card-body-list scrollbar p-0">
    <p class="m-3" *ngIf="smartAssistantOrchestrators?.length == 0">
      Sin orquestadores, crea uno nuevo.
    </p>
    <ul class="list-group list-group-flush" *ngIf="smartAssistantOrchestrators.length !== 0">
      <li class="list-group-item d-flex justify-content-between align-items-center"
          *ngFor="let smartAssistantOrchestrator of smartAssistantOrchestrators">
        <span class="text-truncate">
          {{ smartAssistantOrchestrator.name }}
        </span>
        <div class="d-flex justify-content-end">
          <div class="form-check form-switch">
            <input
              class="form-check-input" type="checkbox"
              (change)="changeSmartAssistantOrchestratorStatus(smartAssistantOrchestrator)"
              [checked]="smartAssistantOrchestrator.enabled" />
          </div>
          <div class="cursor-pointer" id="showIcon" (click)="openSaveSmartAssistantOrchestrator(smartAssistantOrchestrator)">
            <i class="fa-duotone fa-arrow-up-right-from-square text-primary ms-2"></i>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>