import { Component, ElementRef, AfterViewInit, OnInit } from '@angular/core'; // Importa AfterViewInit

import Swiper from 'swiper';
import { AppDetailGalleryService } from '../../../services/marketplace/app-detail-gallery.service';
import { AppReviewService } from '../../../services/marketplace/app-review.service';
import { AppPluginService } from '../../../services/marketplace/app-plugin.service';
import { ActivatedRoute } from '@angular/router';
import { AppPluginInfo } from '../../../models/marketplace/app-plugin-info.dto';
import { AppPluginRequest } from '../../../models/marketplace/app-plugin-request.dto';
import { AppReviewInfo } from '../../../models/marketplace/app-review-info.dto';
import { getStars } from '../marketplace-utils/review.utils';

@Component({
  selector: 'app-detail',
  templateUrl: `./app-detail.component.html`,
  styleUrls: ['./app-detail.component.css'],
})
export class AppDetailComponent implements OnInit, AfterViewInit {

  protected appId: number;

  public appPluginInfo: AppPluginInfo;
  public appReviews: AppReviewInfo[] = [];
  public stars = null;

  public apps: AppPluginRequest[] = [];

  constructor(
    private elementRef: ElementRef,
    private appDetailGalleryService: AppDetailGalleryService,
    private appReviewService: AppReviewService,
    private appPluginService: AppPluginService,
    private route: ActivatedRoute,
  ) {
    this.appId = this.route.snapshot.params['id'];

  }

  ngAfterViewInit() {
  }

  ngOnInit() {
    this.getAppDetails();
  }

  getAppDetails() {
    this.appPluginService.getAppById(this.appId).subscribe({
      next: (appPlugin) => {
        this.appPluginInfo = appPlugin;
        this.stars = getStars(this.appPluginInfo.generalRating);
        console.log(appPlugin);
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

}
