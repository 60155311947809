import { PaginatedSearchRequest } from '@models/common/paginated-search-request.dto';

export class SmartAssistantTopicSearchRequest extends PaginatedSearchRequest {

  smartAssistantId: number;

  constructor(smartAssistantId: number, size: number) {
    super();
    this.smartAssistantId = smartAssistantId;
    this.page = 0;
    this.size = size;
  }

}