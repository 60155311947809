<div class="mb-9">
  <div class="row g-3 mb-4">
    <div class="col-auto">
      <h2 class="mb-2">
        Usuarios
      </h2>
      <span class="text-700 lh-sm mb-0">
        Administra aquí tus usuarios, administradores y asesores de chat que pueden acceder al sistema.
        <span [ngbPopover]="userTypesInfoPopoverContent"
              triggers="mouseenter:mouseleave"
              popoverTitle="Información">
        <i class="fa fa-circle-info"></i>
        </span>
      </span>
    </div>
  </div>
  <div class="mb-4">
    <div class="row g-3">
      <div class="col-md-auto col-sm-12">
        <div class="search-box">
          <form
            class="position-relative"
            data-bs-toggle="search"
            data-bs-display="static">
            <input
              class="form-control search-input search"
              type="search"
              placeholder="Buscar usuarios"
              [formControl]="searchUserFormControl" />
            <span class="fas fa-search search-box-icon"></span>
          </form>
        </div>
      </div>
      <div class="col-md-auto col-sm-12" [hidden]="currentUser.role === UserRoleType.ChatAdvisor">
        <button
          class="btn btn-primary"
          (click)="openSaveUser()">
        <span class="fas fa-plus">
        </span>
          Crear usuario
        </button>
      </div>
    </div>
  </div>
  <div class="py-5 border-top" [class.transparent]="firstLoad" [class.not-transparent]="!firstLoad">
    <div class="row g-4">
      <div class="col-xl-2 col-lg-4 col-md-3 col-sm-12" *ngFor="let user of users">
        <div class="card h-100 shadow-sm">
          <!-- Profile Picture -->
          <div class="position-relative text-center">
            <img class="card-img-top img-fluid rounded-circle mx-auto mt-3"
                 [src]="user.profilePictureUrl || defaultProfilePictureUrl"
                 alt="Foto de perfil" style="width: 100px; height: 100px; object-fit: cover;">
          </div>

          <!-- Card Body -->
          <div class="card-body d-flex flex-column justify-content-between">
            <!-- User Info -->
            <div class="text-center">
              <h5 class="mb-1">{{ user.name }}</h5>
              <!-- Current User Profile Badge -->
              <span class="badge bg-primary text-uppercase fs--2 mb-2" *ngIf="isCurrentUser(user)">
                <i class="fa fa-user me-1"></i> Mi perfil
              </span>

              <p class="text-primary mb-2" [ngSwitch]="user.role">
                <span *ngSwitchCase="UserRoleType.Admin">Administrador</span>
                <span *ngSwitchCase="UserRoleType.ChatAdvisor">Asesor</span>
                <span *ngSwitchDefault>Rol desconocido</span>
              </p>
              <p class="text-muted fs--1 mb-3">
                <strong>Desde: </strong>{{ user.createdAt | date: 'dd/MM/yyyy' }}
              </p>
            </div>

            <!-- Action Buttons -->
            <div class="d-flex justify-content-between align-items-center mt-auto mx-auto">
              <!-- Admin Actions -->
              <ng-container *ngIf="isAdmin(currentUser)">
                <!-- Reassign Chats Button -->
                <span *ngIf="canReassignChats(user)" (click)="openReassignChatsModal(user)">
                  <i role="button" class="fa-regular fa-arrow-right-arrow-left text-primary me-2"></i>
                </span>

                <!-- Delete User Button -->
                <span *ngIf="canDeleteUser(user)" (click)="deleteUser(user)">
                  <i role="button" class="fa fa-trash text-danger me-2"></i>
                </span>
              </ng-container>

              <!-- Edit Profile Button for Chat Advisors -->
              <span *ngIf="isChatAdvisor(currentUser, user) || isAdmin(currentUser)" (click)="openSaveUser(user)">
                <i role="button" class="fa fa-edit text-primary"></i>
              </span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<ng-template #saveUserOffCanvas let-offcanvas>
  <div class="offcanvas-header align-items-start border-bottom flex-column">
    <div class="pt-1 w-100 d-flex justify-content-between align-items-start">
      <div>
        <h5 class="mb-2 me-2 lh-sm" *ngIf="!selectedUser">
          Crea un nuevo usuario
        </h5>
        <h5 class="mb-2 me-2 lh-sm" *ngIf="selectedUser">
          Editar usuario
        </h5>
        <p class="mb-0 fs--1 text-info-500" *ngIf="!selectedUser">
          Agrega los datos del usuario para que empiece a operar en el sistema.
        </p>
        <p class="mb-0 fs--1 text-info-500" *ngIf="selectedUser">
          Actualiza los datos del usuario.
        </p>
      </div>
      <button
        class="btn p-1 fw-bolder"
        type="button"
        (click)="offcanvas.close()">
        <i class="fas fa-xmark fs-2"></i>
      </button>
    </div>
  </div>
  <div class="offcanvas-body scrollbar">
    <form
      [formGroup]="userForm"
      (submit)="saveUser()">
      <div class="row g-3 pb-3">
        <input
          [hidden]="true"
          class="form-control"
          id="profilePictureInput"
          type="file"
          (change)="onProfilePictureChosen($event)"
          [ngClass]="{
            'is-invalid': profilePictureFile === undefined,
            'is-valid': profilePictureFile !== undefined
          }"
        />
        <div class="col-6">
          <img class="card-img-top rounded-2"
               [src]="profilePictureChanged ? profilePictureUrl : (selectedUser ? (selectedUser.profilePictureUrl || defaultProfilePictureUrl) : profilePictureUrl)"
               alt="...">
        </div>
        <div class="col-6">
          <div class="col mb-3">
            <label class="form-label ps-0 w-100">
              <i class="fa-duotone fa-image me-1"></i>Fotografía
            </label>
            <button type="button" class="btn btn-sm btn-secondary"
                    (click)="openChooseProfilePicture()"
                    [disabled]="savingUser">
              {{ selectedUser ? 'Cambiar imagen' : 'Examinar' }}
            </button>
          </div>
          <div class="col mb-2"
               *ngIf="selectedUser && (currentUser.role === UserRoleType.Admin || selectedUser.role === UserRoleType.ChatAdvisor)">
            <label class="form-label ps-0">
              <i class="fa-duotone fa-lock-keyhole me-1"></i>Contraseña
            </label>
            <br>
            <button type="button" class="btn btn-sm btn-secondary"
                    (click)="showChangePasswordInputs()"
                    [disabled]="savingUser">
              Cambiar contraseña
            </button>
          </div>
          <div class="col" *ngIf="selectedUser && users.length > 1 && isAdmin(currentUser)">
            <label class="form-label ps-0">
              <i class="fa-duotone fa-lock-keyhole me-1"></i>Estado
            </label>
            <br>
            <div class="form-check form-switch">
              <input class="form-check-input" id="flexSwitchCheckDefault" type="checkbox"
                     [formControl]="userStatusControl" (change)="changeUserStatus()"
                     [checked]="userStatusControl.value === UserStatusType.Active" />
              <label class="form-check-label" for="flexSwitchCheckDefault">
                {{ userStatusControl.value === true ? 'Activo' : 'Inactivo' }}
              </label>

            </div>
          </div>
          <div class="col" *ngIf="selectedUser && selectedUser.role === UserRoleType.Admin">
            <label class="form-label ps-0">
              <a class="fs--0 fw-bold mt-1 cursor-pointer"
                 data-bs-toggle="tooltip" data-bs-placement="left"
                 title="Obtener link de activación, permite cambiar la contraseña del usuario."
                 (click)="getActivationLink()">Obtener link de activación
              </a>

            </label>
          </div>

        </div>
      </div>
      <ng-container *ngIf="!showChangePassInputs">
        <div class="row pb-3">
          <div class="col-12">
            <select
              class="form-select"
              formControlName="role"
              (change)="onUserRoleChange($event)"
              [ngClass]="{
                'is-invalid': userForm.get('role')?.touched && userForm.get('role')?.invalid,
                'is-valid': userForm.get('role')?.touched && userForm.get('role')?.valid
              }">
              <option selected [value]="''" disabled>
                Tipo de usuario
              </option>
              <ng-container *ngFor="let userRoleType of userRoleTypes">
                <option *ngIf="userRoleType.value !== UserRoleType.SystemAdmin" [value]="userRoleType.value">
                  {{ UserRoleTypeMetadata[userRoleType.value]?.description }}
                </option>
              </ng-container>
            </select>
          </div>
        </div>

        <!-- Campos visibles según el tipo de usuario seleccionado -->
        <div class="row pb-3" *ngIf="selectedUserRole === UserRoleType.Admin || selectedUserRole === UserRoleType.ChatAdvisor">
          <div class="col-12">
            <div class="form-floating">
              <input
                class="form-control"
                id="name"
                type="text"
                placeholder="Nombre completo"
                formControlName="name"
                [ngClass]="{'is-invalid': userForm.get('name')?.touched && userForm.get('name')?.invalid,
                'is-valid': userForm.get('name')?.touched && userForm.get('name')?.valid}">
              <label for="name">Nombre completo</label>
              <div *ngIf="userForm.get('name')?.touched && userForm.get('name')?.invalid" class="invalid-feedback">
                Ingrese el nombre completo, por favor.
              </div>
            </div>
          </div>
        </div>
        <!-- Campos adicionales específicos para "Asesor de chat" -->
        <div class="row pb-3" *ngIf="selectedUserRole">
          <div class="col-12">
            <div class="form-floating">
              <input
                class="form-control"
                id="username"
                type="text"
                placeholder="Nombre de usuario"
                formControlName="username"
                [ngClass]="{'is-invalid': (userForm.get('username')?.touched && userForm.get('username')?.invalid) || duplicatedName,
                'is-valid': !duplicatedName && userForm.get('username')?.touched && userForm.get('username')?.valid}">
              <label for="username">Nombre de usuario</label>
              <div *ngIf="duplicatedName" class="invalid-feedback">
                El usuario ya existe, ingrese otro nombre de usuario.
              </div>
            </div>
          </div>
        </div>


        <div class="row g-3 justify-content-end">
          <div class="col-auto">
            <button
              type="button"
              class="btn btn-phoenix-primary"
              [disabled]="savingUser"
              (click)="offcanvas.close()">
              Cancelar
            </button>
          </div>
          <div class="col-auto">
            <button
              class="btn btn-primary"
              *ngIf="!savingUser"
              [disabled]="userForm.invalid">
              Guardar
            </button>
            <button
              class="btn btn-primary"
              *ngIf="savingUser"
              disabled>
              <span class="spinner-border spinner-border-sm"></span>
              Guardando...
            </button>
          </div>
        </div>
      </ng-container>

    </form>
    <form (submit)="changePassword()" [formGroup]="changePasswordForm">
      <ng-container *ngIf="showChangePassInputs">
        <div class="row pb-3">
          <div class="col-12">
            <div class="form-floating" *ngIf="selectedUser.role === UserRoleType.Admin">
              <input
                class="form-control form-icon-input"
                id="password"
                type="password"
                placeholder="Contraseña"
                formControlName="password"
                [ngClass]="{
                'is-invalid': changePasswordForm.get('password').touched && changePasswordForm.get('password').invalid,
                'is-valid': changePasswordForm.get('password').valid
              }"
              />
              <label for="password">
                Contraseña actual
              </label>
              <div class="invalid-feedback"
                   *ngIf="changePasswordForm.get('password').touched && changePasswordForm.get('password').invalid">
                La contraseña es requerida y debe tener al menos 8 caracteres.
              </div>
            </div>
          </div>
        </div>
        <div class="row pb-3">
          <div class="col-12">
            <div class="form-floating">
              <input
                class="form-control form-icon-input"
                id="newPassword"
                type="password"
                placeholder="Nueva contraseña"
                formControlName="newPassword"
                [ngClass]="{
                'is-invalid': changePasswordForm.get('newPassword').touched && changePasswordForm.get('newPassword').invalid,
                'is-valid': changePasswordForm.get('newPassword').valid
              }"
              />
              <label for="password">
                Nueva contraseña
              </label>
              <div class="invalid-feedback"
                   *ngIf="changePasswordForm.get('newPassword').touched && changePasswordForm.get('newPassword').invalid">
                La contraseña es requerida y debe tener al menos 8 caracteres.
              </div>
            </div>
          </div>
        </div>
        <div class="row pb-3">
          <div class="col-12">
            <div class="form-floating">
              <input
                class="form-control form-icon-input"
                id="confirmNewPassword"
                type="password"
                placeholder="Confirmar nueva contraseña"
                formControlName="confirmNewPassword"
                [ngClass]="{
                'is-invalid': changePasswordForm.get('confirmNewPassword').touched && changePasswordForm.get('confirmNewPassword').invalid,
                'is-valid': changePasswordForm.get('confirmNewPassword').valid
              }"
              />
              <label for="password">
                Confimar nueva contraseña
              </label>
              <div class="invalid-feedback"
                   *ngIf="changePasswordForm.get('confirmNewPassword').hasError('passwordMismatch')">
                Las contraseñas no coinciden.
              </div>
              <div class="invalid-feedback"
                   *ngIf="changePasswordForm.get('confirmNewPassword').touched && changePasswordForm.get('confirmNewPassword').invalid">
                La contraseña es requerida y debe tener al menos 8 caracteres.
              </div>
            </div>
          </div>
        </div>
        <div class="row g-3 justify-content-end">
          <div class="col-auto">
            <button
              type="button"
              class="btn btn-phoenix-primary"
              [disabled]="savingUser"
              (click)="offcanvas.close()">
              Cancelar
            </button>
          </div>
          <div class="col-auto">
            <button
              class="btn btn-primary"
              *ngIf="!savingUser"
              [disabled]="changePasswordForm.invalid">
              Guardar
            </button>
            <button
              class="btn btn-primary"
              *ngIf="savingUser"
              disabled>
              <span class="spinner-border spinner-border-sm"></span>
              Guardando...
            </button>
          </div>
        </div>
      </ng-container>
    </form>
  </div>
</ng-template>

<ng-template #userTypesInfoPopoverContent>
  <p class="mb-1">
    Los <strong class="text-primary">administradores</strong> son quienes tiene acceso a todas las funcionalidades del
    sistema.
  </p>
  <p class="mb-0">
    Los <strong class="text-primary">asesores</strong> de chat solo pueden acceder a la sección de chat y otras pequeñas
    funcionalidades.
  </p>
</ng-template>

<ng-template #reassignUserChatsModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      Reasignar chats de <b>{{ selectedUser.name }}</b>
    </h4>
    <button
      type="button"
      class="btn-close"
      (click)="modal.close()">
    </button>
  </div>
  <div class="modal-body">
    <div class="row g-3">
      <div class="col-12">
        <div class="form-floating">
          <select class="form-select" id="newUser" [formControl]="reassignToUserControl">
            <option selected="" [value]="''" disabled>
              Selecciona un usuario
            </option>
            <option [value]="user.id" *ngFor="let user of availableUsers">
              {{ user.name }}
            </option>
          </select>
          <label for="newUser">
            Nuevo usuario
          </label>
        </div>
      </div>
      <div class="col-12">
        <button type="button" class="btn btn-primary float-end"
                [disabled]="!selectedUser || !reassignToUserControl.value" (click)="reassignUserChats(isDeletingUser)">
          Reasignar
        </button>
      </div>
    </div>
  </div>
</ng-template>