import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './layouts/app/main-layout.component';
import { SignInComponent } from './components/account/sign-in/sign-in.component';
import { SignUpComponent } from './components/account/sign-up/sign-up.component';
import { AuthGuard } from './guard/auth.guard';
import { ChatComponent } from './components/chat/main/chat.component';
import { ChatTagsComponent } from './components/admin/chat-tags/chat-tags.component';
import { ContactsComponent } from './components/admin/contacts/contacts.component';
import { UsersConfigComponent } from './components/account/config/users/users-config.component';
import { BroadcastComponent } from './components/utilities/broadcast/broadcast.component';
import { SmartAssistantsComponent } from './components/utilities/smart-assistants/smart-assistants.component';
import { QuickRepliesComponent } from './components/admin/quick-replies/quick-replies.component';
import { BoardComponent } from './components/admin/board/board.component';
import { MessageTemplatesComponent } from './components/admin/message-templates/message-templates.component';
import { ResetPasswordComponent } from './components/account/reset-password/reset-password.component';
import { TeamsComponent } from './components/account/config/teams/teams.component';
import { CommonLayoutComponent } from './layouts/common/common-layout.component';
import { SubscriptionConfigComponent } from './components/account/config/subscription/subscription-config.component';
import { ActivateUserComponent } from './components/account/config/activate-user/activate-user.component';
import { LandingPageLayoutComponent } from './layouts/landing-page/landing-page-layout.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { PricingPageComponent } from './components/landing-page/pricing-page/pricing-page.component';
import { MarketplaceManagementComponent, } from './components/management/marketplace-management/marketplace-management.component';
import { IntegrationsConfigComponent } from '@app/components/account/config/integrations/components/integrations-config.component';
import { ChatMonitorComponent } from "@app/components/chat/chat-monitor/chat-monitor.component";
import { QuickReplyGroupsComponent } from './components/admin/quick-replies/quick-reply-groups/quick-reply-groups.component';
import { FunnelComponent } from '@app/components/funnel/funnel.component';

const routes: Routes = [
  // {
  //   path: '',
  //   pathMatch: 'full',
  //   redirectTo: 'app/chat'
  // },
  {
    path: 'app',
    component: CommonLayoutComponent,
    children: [
      {
        path: '',
        component: MainLayoutComponent,
        canActivate: [AuthGuard],
        children: [
          { path: 'chat', component: ChatComponent },
          { path: 'chat-monitor', component: ChatMonitorComponent },
          { path: 'board', component: BoardComponent },
          { path: 'funnel', component: FunnelComponent },
          { path: 'tags', component: ChatTagsComponent },
          { path: 'contacts', component: ContactsComponent },
          { path: 'quick-replies', component: QuickRepliesComponent },
          { path: 'quick-replies-groups', component: QuickReplyGroupsComponent },
          { path: 'message-templates', component: MessageTemplatesComponent },
          { path: 'broadcast', component: BroadcastComponent },
          { path: 'smart-assistants', component: SmartAssistantsComponent },
          { path: 'config/users', component: UsersConfigComponent },
          { path: 'config/users-groups', component: TeamsComponent },
          { path: 'config/integrations', component: IntegrationsConfigComponent },
          { path: 'config/subscription', component: SubscriptionConfigComponent },
          {
            path: 'marketplace',
            loadChildren: () => import('./components/marketplace/marketplace.module').then(m => m.MarketplaceModule),
          },
          // {
          //   path: 'admin',
          //   component: MarketplaceManagementComponent,
          //   children: [
          //     { path: 'marketplace', component: MarketplaceManagementComponent },
          //     { path: 'precios', component: PricingPageComponent },
          //   ],
          // },
        ],
      },
      { path: 'activate-user', component: ActivateUserComponent },
      { path: 'sign-in', component: SignInComponent },
      { path: 'sign-up/:subscriptionType', component: SignUpComponent },
      { path: 'reset-password', component: ResetPasswordComponent },
    ],
  },
  {
    path: 'admin',
    component: CommonLayoutComponent,
    children: [
      {
        path: '',
        component: MainLayoutComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'marketplace',
            component: MarketplaceManagementComponent,
            // children: [
            //   { path: 'marketplace', component: MarketplaceManagementComponent },
            // ],
          },
        ],
      },
    ],
  },
  {
    path: '',
    component: LandingPageLayoutComponent,
    children: [
      { path: '', component: LandingPageComponent },
      { path: 'precios', component: PricingPageComponent },
    ],
  },
  { path: '**', redirectTo: 'app/chat' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
