import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { SocketNotificationTopic, SocketService } from '@services/socket/socket.service';
import * as feather from 'feather-icons';
import { EventEmitterService, NotificationTopic } from '@services/data/event-emitter.service';
import { Subscription } from 'rxjs';
import { SpinnerService } from '@services/data/spinner.service';
import { NotificationService } from '@services/notification/notificacion.service';
import { Router } from '@angular/router';
import { UserInfo } from '@models/account/user/user.info.dto';
import { AuthService } from '@services/auth/auth.service';
import { UserRoleType } from '@type/account/user-role.type';
import { getChatUrl, getMessageTypeLabel } from '@app/utils/chat-utils';
import { ChatService } from '@services/chat/chat.service';
import { ChatInfo } from '@models/chat/chat/chat-info.dto';
import { UserService } from '@services/account/user.service';

@Component({
  selector: 'app-main-layout',
  template: `
    <div class="main" id="top">
      <nav class="navbar navbar-vertical navbar-expand-lg">
        <app-sidebar></app-sidebar>
      </nav>
      <nav
        class="navbar navbar-top fixed-top navbar-expand-lg">
        <app-header></app-header>
      </nav>
      <div class="content">
        <router-outlet></router-outlet>
      </div>
      <ngx-spinner [fullScreen]="true" [type]="spinnerType" size="medium" style="color: white;">
        <p class="loading-spinner">{{ spinnerMessage ? spinnerMessage : '' }}</p>
      </ngx-spinner>
      <!-- Contenedor principal que bloquea la interacción -->
      <div appBlockUi class="block-ui-container d-flex justify-content-center align-items-center" *ngIf="blockUI">
        <!-- Mensaje de advertencia -->
        <div class="card border border-warning p-5 mx-4">
          <div class="card-body">
            <h2 class="mb-1">
              Su suscripción ha expirado
            </h2>
            <p>
              Reanude su suscripción para seguir disfrutando de IVANA.
            </p>
            <a [routerLink]="['/app/config/subscription']" class="btn btn-phoenix-primary float-end">
              Ir a pagar
            </a>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./main-layout.component.css'],
})
export class MainLayoutComponent implements OnInit, AfterViewInit, OnDestroy, AfterContentChecked {

  public spinnerMessage: string = '';
  public spinnerType: string = 'square-spin';
  private spinnerSubscription: Subscription;
  public isMobile: boolean;
  private user: UserInfo;
  public blockUI: boolean = false;

  constructor(
    private socketService: SocketService,
    private eventEmitterService: EventEmitterService,
    private spinnerService: SpinnerService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private chatService: ChatService,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) {
    this.user = this.authService.getUser();
  }

  ngOnInit() {

    this.initializeWebSocketEvents();
    this.initializeSpinnerEvent();
    this.initializeBlockUIEvent();

    this.isMobile = window.innerWidth <= 992;
  }

  ngAfterViewInit() {
    this.notificationService.requestPermission();
    feather.replace({
      width: '16px',
      height: '16px',
    });
    (window as any).phoenix.initEverything();
  }

  ngAfterContentChecked() {
    this.spinnerType = 'ball-scale-multiple';
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.spinnerSubscription.unsubscribe();
  }

  private initializeBlockUIEvent() {

    this.eventEmitterService.getEventEmitter(NotificationTopic.BlockUI).subscribe({
      next: (blockUI: boolean) => {
        this.blockUI = blockUI;
        if (this.blockUI === undefined)
          this.checkSubscription();
      },
    });

    this.checkBlockUI();
  }

  private checkBlockUI() {
    this.blockUI = !this.user.shop.trialPeriod && !this.user.shop.subscriptionActive;
  }

  private initializeWebSocketEvents() {

    const socket = this.socketService.getSocket();

    if (socket) {

      socket.on(SocketNotificationTopic.MessageReceivedEventTopic, (data) => {
        this.eventEmitterService.emit(NotificationTopic.MessageReceived, data);
        this.processMessageReceivedNotification(data);
      });

      socket.on(SocketNotificationTopic.MessageStatusEventTopic, (data) => {
        this.eventEmitterService.emit(NotificationTopic.MessageStatus, data);
      });

      socket.on(SocketNotificationTopic.WhatsAppWebInstanceQRCodeEventTopic, (data) => {
        this.eventEmitterService.emit(NotificationTopic.WhatsAppWebInstanceQRCode, data);
      });

      socket.on(SocketNotificationTopic.WhatsAppWebInstanceStatusEventTopic, (data) => {
        this.eventEmitterService.emit(NotificationTopic.WhatsAppWebInstanceStatus, data);
      });

      socket.on(SocketNotificationTopic.ChatRequiresAssistanceEventTopic, (data) => {
        this.eventEmitterService.emit(NotificationTopic.ChatRequiresAssistance, data);
        this.processChatRequiresAssistanceNotification(data);
      });

      socket.on(SocketNotificationTopic.TestSmartAssistantMessageReceivedEventTopic, (data) => {
        this.eventEmitterService.emit(NotificationTopic.TestSmartAssistantMessageReceived, data);
      });

      socket.on(SocketNotificationTopic.WhatsAppWebExportTopic, (data) => {
        this.eventEmitterService.emit(NotificationTopic.WhatsAppWebExport, data);
      });

      socket.on(SocketNotificationTopic.ChatUpdatedEventTopic, (data) => {
        this.eventEmitterService.emit(NotificationTopic.ChatUpdated, data);
      });

      socket.on(SocketNotificationTopic.FunnelStageChangedEventTopic, (data) => {
        this.eventEmitterService.emit(NotificationTopic.FunnelStageChangedEventTopic, data);
      });
      socket.on(SocketNotificationTopic.FunnelStageAddedEventTopic, (data) => {
        this.eventEmitterService.emit(NotificationTopic.FunnelStageAddedEventTopic, data);
      });
      socket.on(SocketNotificationTopic.FunnelStageRemovedEventTopic, (data) => {
        this.eventEmitterService.emit(NotificationTopic.FunnelStageRemovedEventTopic, data);
      });
      socket.on(SocketNotificationTopic.FunnelAddedEventTopic, (data) => {
        this.eventEmitterService.emit(NotificationTopic.FunnelAddedEventTopic, data);
      });
      socket.on(SocketNotificationTopic.FunnelRemovedEventTopic, (data) => {
        this.eventEmitterService.emit(NotificationTopic.FunnelRemovedEventTopic, data);
      });
    }
  }

  private initializeSpinnerEvent() {
    this.spinnerSubscription = this.spinnerService.spinnerSubject
      .subscribe((spinnerData: any) => {
        this.spinnerMessage = spinnerData.message;
        this.spinnerType = spinnerData.type;
      });
  }

  @HostListener('window:resize', ['$event'])
  public onWindowResize() {
    const isMobile = window.innerWidth <= 992;
    if (isMobile !== this.isMobile) {
      this.isMobile = isMobile;
      this.eventEmitterService.emit(NotificationTopic.IsMobile, isMobile);
    }
  }

  private processMessageReceivedNotification(data: any) {

    const { message, chat } = data;

    if (!message.customerMessage) return;

    if (this.user.role === UserRoleType.Admin || chat.userId === this.user.id) {

      const chatUrl = getChatUrl(chat, this.router);
      const title = `Nuevo mensaje de ${message.phoneNumber}`;
      const body = getMessageTypeLabel(message);

      this.notificationService.showNotification(title, chatUrl, { body });
    }
  }

  private processChatRequiresAssistanceNotification(data: any) {

    const { chatId, humanAssistantAttention } = data;

    if (humanAssistantAttention) {

      this.chatService.getChatById(chatId).subscribe({
        next: (chat: ChatInfo) => {

          const chatUrl: string = getChatUrl(chat, this.router);
          const title: string = `Nuevo mensaje de ${chat.chatPhoneNumber}`;
          this.notificationService.showNotification(`El chat ${chat.chatName} requiere asistencia humana`, chatUrl, { body: title });

          new Audio('assets/audio/complete_quest_requirement.mp3').play().then(() => {});
        },
      });

    }

  }

  private checkSubscription() {

    this.spinnerService.show('Espere...');

    this.userService.getUserInfo().subscribe({
      next: (user: UserInfo) => {
        this.authService.saveUser(user);
        this.user = user;
        this.checkBlockUI();
        this.spinnerService.hide();
      },
      error: (error) => {
        console.error(error);
      },
    });

  }

}
