import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserInfo } from '@app/models/account/user/user.info.dto';
import { AuthService } from '@app/services/auth/auth.service';
import { UserService } from '../../../../services/account/user.service';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Page } from '../../../../models/common/page';
import { UserSearchRequest } from '../../../../models/account/user/user-search-request.dto';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map, merge,
  Observable,
  of,
  OperatorFunction, Subject,
  switchMap,
} from 'rxjs';
import { TeamInfo } from '../../../../models/account/team/team.info.dto';
import { TeamService } from '../../../../services/account/team.service';
import { SaveTeamRequest } from '../../../../models/account/team/save-team-request.dto';
import { TeamSearchRequest } from '../../../../models/account/team/team-search-request.dto';
import { CheckTeamNameExistenceRequest } from "../../../../models/account/team/check-team-name-existence-request.dto";
import { ChangeDefaultTeamRequest } from "../../../../models/account/team/change-default-team-request.dto";
import { UserStatusType } from '../../../../type/account/user-status.type';
import { SpinnerService } from '../../../../services/data/spinner.service';

@Component({
  selector: 'app-teams-config',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.css'],
})
export class TeamsComponent implements OnInit {

  public currentUser: UserInfo;

  public defaultTeamChecked: boolean = false;
  public selectedTeam: TeamInfo;
  public teamForm: FormGroup;
  public savingTeam: boolean = false;
  public searchTeamFormControl: FormControl = new FormControl('');
  private searchRequest: TeamSearchRequest;
  private defaultPageSize: number = -1;

  @ViewChild('saveTeamOffCanvas')
  public saveTeamOffCanvas: TemplateRef<any>;

  //Advisors
  @ViewChild('advisors', { static: false }) advisors: any;
  public focusAdvisor$ = new Subject<string>();
  public clickAdvisor$ = new Subject<string>();
  public chatAdvisorsAll: UserInfo[] = [];
  public chatAdvisorsAvailable: UserInfo[] = [];
  public chatAdvisorsChosen: UserInfo[] = [];
  public formatResultAdvisor = (value: UserInfo) => value.name;
  public formatInputAdvisor = (value: UserInfo) => value.name;

  //Teams
  public teams: TeamInfo[] = [];
  public firstLoad: boolean = true;

  constructor(
    private userService: UserService,
    private teamService: TeamService,
    private authService: AuthService,
    private toastr: ToastrService,
    private spinnerService: SpinnerService,
    private offCanvasService: NgbOffcanvas,
  ) {
    this.currentUser = this.authService.getUser();
  }

  ngOnInit() {
    this.initializeTeamSearchRequest();
    this.initializeForm();
    this.loadChatAdvisors();
    this.initializeSearchUserFormControl();
    this.loadTeams();
    this.initializeTeamNameValidator();
  }

  initializeForm() {
    this.teamForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      advisors: new FormControl(),
    });
  }

  initializeTeamSearchRequest() {
    this.searchRequest = new UserSearchRequest(
      this.currentUser.shop.id,
      this.defaultPageSize,
    );
  }

  public duplicatedName: boolean = false;
  public sameName: boolean = false;
  public checkingDuplicatedName: string = 'before';

  initializeTeamNameValidator() {
    this.teamForm.get('name')
      .valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((query) => {
        return of(query.trim());
      }),
    )
      .subscribe((name: string) => {
        this.checkTeamNameExistence(name);
      });
  }

  checkTeamNameExistence(name: string) {
    if (!name || name.length === 0) {
      this.duplicatedName = false;
      return;
    }

    if (this.selectedTeam && this.selectedTeam.name === name) {
      this.duplicatedName = false;
      this.sameName = true;
      return;
    }

    this.sameName = false;

    this.checkingDuplicatedName = 'checking';
    const afterChecking = 'after-checking';

    const checkTeamNameExistenceRequest =
      new CheckTeamNameExistenceRequest(this.currentUser.shop.id, name);

    this.teamService
      .checkTeamNameExistence(checkTeamNameExistenceRequest)
      .subscribe({
        next: () => {
          this.duplicatedName = false;
          this.checkingDuplicatedName = afterChecking;
        },
        error: () => {
          this.duplicatedName = true;
          this.checkingDuplicatedName = afterChecking;
        },
        complete: () => {
        },
      });

  }

  private initializeSearchUserFormControl() {
    this.searchTeamFormControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((query) => {
          return of(query.trim());
        }),
      )
      .subscribe((searchTerm: string) => {
        this.searchRequest.searchTerm = searchTerm;
        this.loadTeams();
      });
  }

  public loadTeams() {

    this.spinnerService.show('Cargando usuarios...');

    this.teamService.searchTeams(this.searchRequest).subscribe({
      next: (page: Page<TeamInfo>) => {
        this.teams = page.content;
        this.spinnerService.hide();
        if (this.firstLoad) this.firstLoad = false;
      },
      error: (error: any) => {
        console.error('Error en la carga de teams:', error);
      },
    });
  }

  public saveTeam() {

    if (this.teamForm.invalid && this.chatAdvisorsChosen.length === 0) {
      this.teamForm.markAllAsTouched();
      return;
    }

    this.savingTeam = true;

    const saveTeamRequest = new SaveTeamRequest()
    saveTeamRequest.shopId = this.currentUser.shop.id;
    saveTeamRequest.name = this.teamForm.get('name').value;
    saveTeamRequest.userIds = JSON.stringify(this.chatAdvisorsChosen.map((ct) => ct.id));
    saveTeamRequest.defaultTeam = this.defaultTeamChecked;

    if (this.selectedTeam) {
      saveTeamRequest.id = this.selectedTeam.id;
      if (this.selectedTeam.defaultTeam) saveTeamRequest.defaultTeam = true;
    }

    this.teamService.saveTeam(saveTeamRequest).subscribe({
      next: (teamInfo: TeamInfo) => {

        this.savingTeam = false;

        if (this.defaultTeamChecked) {
          const defaultTeam = this.teams.find(t => t.defaultTeam);
          if (defaultTeam) defaultTeam.defaultTeam = false;
          this.defaultTeamChecked = false;
        }

        this.offCanvasService.dismiss();

        if (!this.selectedTeam) this.teams.unshift(teamInfo);
        if (this.selectedTeam) {
          this.selectedTeam.name = teamInfo.name;
          this.selectedTeam.users = teamInfo.users;
          this.selectedTeam.defaultTeam = teamInfo.defaultTeam;
        }

        if (this.selectedTeam) {
          this.toastr.success('Team actualizado con éxito');
        } else {
          this.toastr.success('Team creado con éxito');
        }
      },
      error: () => {
        this.savingTeam = false;
        this.toastr.error('Error al crear el team');
      },
    });

  }

  public openSaveTeam(team?: TeamInfo) {

    if (team) {
      this.selectedTeam = team;
      this.setTeamFormValues(team);
    }

    const offCanvasRef = this.offCanvasService.open(
      this.saveTeamOffCanvas,
      {
        position: 'end',
      },
    );

    offCanvasRef.hidden.subscribe(() => {
      this.selectedTeam = undefined;
      this.teamForm.get('name').setValue('');
      this.chatAdvisorsChosen = [];
      this.chatAdvisorsAvailable = this.chatAdvisorsAll;
    });
  }

  private setTeamFormValues(team: TeamInfo) {
    this.teamForm.get('name').setValue(team.name);
    this.chatAdvisorsChosen = [...team.users];
    this.chatAdvisorsAvailable = this.chatAdvisorsAll.filter(
      (ct) => !team.users.some((ctChosen) => ctChosen.id === ct.id),
    );
  }

  loadChatAdvisors() {

    const noSize: number = -1;

    const searchRequest = new UserSearchRequest(
      this.currentUser.shop.id,
      noSize,
    );

    this.userService.searchUsers(searchRequest).subscribe({
      next: (chatAdvisorsPage: Page<UserInfo>) => {
        this.chatAdvisorsAvailable = chatAdvisorsPage.content.slice();
        this.chatAdvisorsAll = chatAdvisorsPage.content.slice();
      },
      error: (error: any) => {
        console.error('Error en la carga de asesores:', error);
      },
      complete: () => {
      },
    });

    this.initializeChatAdvisorsChosenEvent();
  }

  initializeChatAdvisorsChosenEvent() {

    const advisorsFormControl = this.teamForm.get('advisors');
    advisorsFormControl.valueChanges.subscribe((value: UserInfo) => {
      if (value) {
        this.chatAdvisorsChosen.push(value);
        advisorsFormControl.setValue(null);
        const chatAdvisorChosenIndex = this.chatAdvisorsAvailable.indexOf(value);
        this.chatAdvisorsAvailable.splice(chatAdvisorChosenIndex, 1);
        this.chatAdvisorsAvailable.sort((ct1, ct2) => ct2.id - ct1.id);
      }
    });
  }

  removeChatAdvisor(chatAdvisor: UserInfo) {

    const chatAdvisorChosenIndex = this.chatAdvisorsChosen.indexOf(chatAdvisor);
    this.chatAdvisorsChosen.splice(chatAdvisorChosenIndex, 1);

    this.chatAdvisorsAvailable.push(chatAdvisor);
    this.chatAdvisorsAvailable.sort((ct1, ct2) => ct2.id - ct1.id);
  }

  searchChatAdvisors: OperatorFunction<string, readonly UserInfo[]> = (
    text$: Observable<string>,
  ) => {
    const debouncedText$ = text$.pipe(debounceTime(1), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickAdvisor$.pipe(
      filter(() => !this.advisors?.isPopupOpen()),
    );
    const inputFocus$ = this.focusAdvisor$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ''
            ? this.chatAdvisorsAvailable
            : this.chatAdvisorsAvailable.filter(
              (ct) => ct.name.toLowerCase().indexOf(term.toLowerCase()) > -1,
            )
        ).slice(0, 10),
      ),
    );
  };

  public deleteTeam(team: TeamInfo) {

    if (!confirm('¿Está seguro que desea eliminar este team?')) return;

    if (team.defaultTeam) {
      this.toastr.error('No se puede eliminar el team por defecto');
      return;
    }

    this.teamService.deleteTeam(team.id)
      .subscribe({
        next: () => {
          const index = this.teams.findIndex(u => u.id === team.id);
          this.teams.splice(index, 1);
          this.toastr.success('Team eliminado con éxito');
        },
        error: (error: any) => {
          const errorMessage = 'Error al eliminar el team';
          this.toastr.error(errorMessage);
          console.error(errorMessage, error);
        },
        complete: () => {

        },
      });
  }

  public setDefaultTeam(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    this.defaultTeamChecked = checkbox.checked;
  }

  public changeDefaultTeam(team: TeamInfo) {

    if (!confirm('¿Está seguro que desea cambiar el team por defecto?')) {
      return;
    }

    const changeDefaultTeamRequest = new ChangeDefaultTeamRequest(this.currentUser.shop.id, team.id);

    this.teamService.changeDefaultTeam(changeDefaultTeamRequest)
      .subscribe({
        next: () => {
          const defaultTeam = this.teams.find(t => t.defaultTeam);
          if (defaultTeam) defaultTeam.defaultTeam = false;

          this.teams.forEach(t => t.defaultTeam = t.id === team.id);
          this.toastr.success('Team por defecto cambiado con éxito');
        },
        error: (error: any) => {
          const errorMessage = 'Error al cambiar el team por defecto';
          this.toastr.error(errorMessage);
          console.error(errorMessage, error);
        },
        complete: () => {

        },
      });
  }

  protected readonly UserStatusType = UserStatusType;
}
