import { ChannelType } from "@app/type/communication/channel.type";
import { MessageType } from '@type/chat/message-content.type';
import { ChatTagInfo } from "../tag/chat-tag-info.dto";
import { UserInfo } from "../../account/user/user.info.dto";
import { MessageStatus } from "@type/chat/message-status.type";

export class ChatInfo {

  id: number;
  createdAt: any;
  uuid: string;
  chatName: string;
  chatAlias: string;
  chatPhoneNumber: string;
  chatMessengerContactId: string;
  profilePictureUrl: string;
  smartAssistantEnabled: boolean;
  smartAssistantUsed: boolean;
  humanAssistantAttention: boolean;
  color: string;
  note: string;
  expired: boolean;
  archived: boolean;
  blocked: boolean;
  synchronized: boolean;
  channel: ChannelType;
  userId: number;
  seen: boolean;
  answered: boolean;
  funnelStageId: number;

  isReceivingMessage: boolean;

  lastMessageIdentifier: string;
  lastMessageContent: string;
  lastMessageStatus: MessageStatus;
  lastMessageFileUrl: string;
  lastMessageFileName: string;
  lastMessageType: MessageType;
  lastMessageSentTime: any;

  contactExtraPhoneNumber: string;
  contactEmail: string;
  contactStreet: string;
  contactColony: string;
  contactCountry: string;
  contactState: string;
  contactCity: string;
  contactAddressReference: string;

  chatTags: ChatTagInfo[];
  user: UserInfo;

  // angular attributes
  selected: boolean = false;
  checked: boolean = false;
  lastMessageTypeLabel: string;
  lastMessageStatusClass: string;

  chatSummary: string;
  lastSummaryChatDate: Date;

}