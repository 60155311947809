import { AfterViewInit, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SmartAssistantTopicItemInfo } from '@models/utilities/smart-assistant/topic-item/smart-assistant-topic-item-info.dto';
import { SmartAssistantTopicInfo } from '@models/utilities/smart-assistant/topic/smart-assistant-topic-info.dto';
import { SmartAssistantInfo } from '@models/utilities/smart-assistant/smart-assistant-info.dto';
import { EventEmitterService, NotificationTopic } from '@services/data/event-emitter.service';
import { SmartAssistantService } from '@services/utilities/smart-assistant/smart-assistant.service';
import { SmartAssistantTopicItemActionType, SmartAssistantTopicItemActionTypeMetadata } from '@type/utilities/smart-assistant/smart-assistant-topic-item-action.type';
import { SmartAssistantTopicItemActionInfo } from "@models/utilities/smart-assistant/topic-item-action/smart-assistant-topic-item-action-info.dto";
import { SaveSmartAssistantTopicItemRequest } from "@models/utilities/smart-assistant/topic-item/save-smart-assistant-topic-item-request.dto";
import { Subscription } from "rxjs";
import { EnumInfo } from '@models/common/enum-info.dto';
import { getTypes } from '@app/utils/enum-utils';
import {
  SaveSmartAssistantTopicItemActionRequest
} from '@models/utilities/smart-assistant/topic-item-action/save-smart-assistant-topic-item-action-request.dto';

@Component({
  selector: 'app-smart-assistants-topic-item',
  templateUrl: './smart-assistants-topic-item.component.html',
  styleUrls: ['./smart-assistants-topic-item.component.css'],
})
export class SmartAssistantsTopicItemComponent implements OnInit, AfterViewInit, OnDestroy {

  // Enums
  public SmartAssistantTopicItemActionType = SmartAssistantTopicItemActionType;
  public SmartAssistantTopicItemActionTypes: EnumInfo[] = getTypes(SmartAssistantTopicItemActionType, SmartAssistantTopicItemActionTypeMetadata);

  public saving: boolean = false;
  public showActions: boolean = false;
  public showActionList: boolean = true;
  public showActionForm: boolean = false;

  @Input()
  public smartAssistantTopicItem: SmartAssistantTopicItemInfo;

  @Input()
  public smartAssistantTopic: SmartAssistantTopicInfo;

  @Input()
  public smartAssistant: SmartAssistantInfo;

  // Selections
  public selectedSmartAssistantTopicItemAction: SmartAssistantTopicItemActionInfo;

  // Management Forms
  public smartAssistantTopicItemForm: FormGroup;

  // Management
  public smartAssistantTopicItemActions: SmartAssistantTopicItemActionInfo[] = [];

  private componentSubscriptions: Subscription[] = [];

  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService,
    private eventEmitterService: EventEmitterService,
    private smartAssistantService: SmartAssistantService,
  ) {

  }

  ngOnInit() {
    this.initializeAddSmartAssistantTopicItemActionEvent();
    this.initializeShowSmartAssistantTopicItemActionsListEvent();
    this.initializeSmartAssistantTopicItem();
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    for (const subscription of this.componentSubscriptions)
      subscription.unsubscribe();
  }

  private initializeShowSmartAssistantTopicItemActionsListEvent() {

    const subscription = this.eventEmitterService.getEventEmitter(NotificationTopic.ShowSmartAssistantTopicItemActionsList).subscribe({
      next: () => {
        this.showActionList = true;
        this.showActionForm = false;
      }
    });

    this.componentSubscriptions.push(subscription);
  }

  private initializeAddSmartAssistantTopicItemActionEvent() {

    const subscription = this.eventEmitterService.getEventEmitter(NotificationTopic.AddSmartAssistantTopicItemAction).subscribe({
      next: (smartAssistantTopicItemActionInfo: SmartAssistantTopicItemActionInfo) => {
        console.log(smartAssistantTopicItemActionInfo);
        this.selectedSmartAssistantTopicItemAction = undefined;

        const { temporalId } = smartAssistantTopicItemActionInfo;
        this.adaptSmartAssistantTopicItemActions([smartAssistantTopicItemActionInfo]);

        const existingActionIndex = this.smartAssistantTopicItemActions.findIndex(a => a.temporalId === temporalId);

        if (existingActionIndex >= 0) {
          this.smartAssistantTopicItemActions[existingActionIndex] = smartAssistantTopicItemActionInfo;
        } else {
          this.smartAssistantTopicItemActions.push(smartAssistantTopicItemActionInfo);
        }

        this.showActionList = true;
        this.showActionForm = false;
      }
    });

    this.componentSubscriptions.push(subscription);
  }

  private initializeSmartAssistantTopicItem() {

    this.smartAssistantTopicItemForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      contentOrContext: new FormControl('', [Validators.required]),
    });

    if (this.smartAssistantTopicItem) {

      const {
        name,
        content,
        context,
        sendActions,
        actions
      } = this.smartAssistantTopicItem;

      this.smartAssistantTopicItemForm.get('name').setValue(name);

      if (this.smartAssistantTopicItem.content) {
        this.smartAssistantTopicItemForm.get('contentOrContext').setValue(content);
      } else if (this.smartAssistantTopicItem.context) {
        this.smartAssistantTopicItemForm.get('contentOrContext').setValue(context);
      }

      if (actions?.length > 0) {

        this.setShowActions(sendActions);

        this.smartAssistantTopicItemActions = actions;
        this.adaptSmartAssistantTopicItemActions(actions);
      }

    }

  }

  private adaptSmartAssistantTopicItemActions(actions: SmartAssistantTopicItemActionInfo[]) {
    for (const action of actions) {
      action.typeDescription = this.SmartAssistantTopicItemActionTypes
        .find((t) => t.value === action.type).description;
    }
  }

  public cancelSave() {
    if (this.smartAssistantTopicItemActions.length > 0) {
      for (const smartAssistantTopicItemAction of this.smartAssistantTopicItemActions) {
        smartAssistantTopicItemAction.removed = false;
      }
    }
    this.modalService.dismissAll();
  }

  public saveSmartAssistantTopicItem() {

    this.saving = true;
    const { name, contentOrContext } = this.smartAssistantTopicItemForm.value;

    const smartAssistantTopicId: number = this.smartAssistantTopic.id;
    let smartAssistantTopicItemId: number = this.smartAssistantTopicItem ? this.smartAssistantTopicItem.id : null;

    const saveSmartAssistantTopicItemRequest = new SaveSmartAssistantTopicItemRequest(
      smartAssistantTopicItemId, name, smartAssistantTopicId,
    );

    if (this.smartAssistantTopicItemActions.length > 0) {
      saveSmartAssistantTopicItemRequest.context = contentOrContext;
    } else {
      saveSmartAssistantTopicItemRequest.content = contentOrContext;
    }

    if (smartAssistantTopicItemId) {
      if (this.smartAssistantTopicItemActions.length > 0)
        saveSmartAssistantTopicItemRequest.sendActions = this.showActions;
    } else {
      saveSmartAssistantTopicItemRequest.sendActions = true;
    }

    if (this.smartAssistantTopicItemActions?.length > 0) {

      for (const smartAssistantItemAction of this.smartAssistantTopicItemActions) {

        const saveSmartAssistantTopicItemActionRequest = new SaveSmartAssistantTopicItemActionRequest(smartAssistantItemAction.type);
        saveSmartAssistantTopicItemActionRequest.id = smartAssistantItemAction.id;
        saveSmartAssistantTopicItemActionRequest.removed = smartAssistantItemAction.removed;
        saveSmartAssistantTopicItemActionRequest.message = smartAssistantItemAction.message;
        saveSmartAssistantTopicItemActionRequest.httpCallUrl = smartAssistantItemAction.httpCallUrl;
        saveSmartAssistantTopicItemActionRequest.httpCallInstructions = smartAssistantItemAction.httpCallInstructions;
        saveSmartAssistantTopicItemActionRequest.quickReplyId = smartAssistantItemAction.quickReply?.id;
        saveSmartAssistantTopicItemActionRequest.chatTagIds = smartAssistantItemAction.chatTags?.map(t => t.id);

        saveSmartAssistantTopicItemRequest.actions.push(saveSmartAssistantTopicItemActionRequest);
      }
    }

    this.smartAssistantService.saveSmartAssistantTopicItem(saveSmartAssistantTopicItemRequest).subscribe({
      next: () => {
        this.eventEmitterService.emit(NotificationTopic.TestSmartAssistantTopicItemAdded);
        this.modalService.dismissAll();
        this.toastr.success('Tarjeta creada exitosamente');
      },
      error: (httpErrorResponse: HttpErrorResponse) => {
        const { error } = httpErrorResponse;
        console.log(error);
      },
      complete: () => {
      },
    });
  }

  public toggleShowActions(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    this.setShowActions(checkbox.checked);
  }

  private setShowActions(checked: boolean) {
    this.showActions = checked;
    const classList = document.querySelector('.modal-dialog').classList;
    if (this.showActions && !classList.contains('modal-lg')) {
      classList.add('modal-lg');
    } else {
      if (classList.contains('modal-lg')) {
        classList.remove('modal-lg');
      }
    }
  }

  public showAddSmartAssistantTopicItemAction() {
    this.selectedSmartAssistantTopicItemAction = undefined;
    this.showActionForm = true;
    this.showActionList = false;
  }

  public showEditSmartAssistantTopicItemAction(smartAssistantTopicItemAction: SmartAssistantTopicItemActionInfo) {
    this.selectedSmartAssistantTopicItemAction = smartAssistantTopicItemAction;
    this.showActionForm = true;
    this.showActionList = false;
  }

  public deleteSmartAssistantTopicItemAction(smartAssistantTopicItemAction: SmartAssistantTopicItemActionInfo) {

    if (!confirm('¿Está seguro que desea eliminar esta acción?')) return;

    let actionIndexToDelete: number;

    if (smartAssistantTopicItemAction.temporalId) {
      actionIndexToDelete = this.smartAssistantTopicItemActions
        .findIndex(a => a.temporalId === smartAssistantTopicItemAction.temporalId);
    } else {
      actionIndexToDelete = this.smartAssistantTopicItemActions
        .findIndex(a => a.id === smartAssistantTopicItemAction.id);
    }

    const actionToDelete: SmartAssistantTopicItemActionInfo = this.smartAssistantTopicItemActions[actionIndexToDelete];

    if (this.smartAssistantTopicItem) {
      actionToDelete.removed = true;
    } else {
      this.smartAssistantTopicItemActions.splice(actionIndexToDelete, 1);
    }

  }

}
