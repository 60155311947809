
<div class="mb-1 p-3 rounded-2 border-4 border-start" *ngIf="message" [ngClass]="{
  'border-success': message.customerMessage,
  'border-primary': !message.customerMessage,
}">
  <p class="my-0" *ngIf="message.customerMessage">
    <strong>
      {{ chat?.chatName }}:
    </strong>
  </p>
  <p class="my-0" *ngIf="!message.customerMessage">
    <strong>
      Tu:
    </strong>
  </p>
  <!-- Message Type Components -->
  <ng-container *ngIf="message.type === MessageType.Text">
    <app-chat-context-text-message
      [class]="'d-flex chat-message-content max-width-100 me-2'"
      [class.received]="message.customerMessage"
      [chat]="chat"
      [message]="message">
    </app-chat-context-text-message>
  </ng-container>
  <ng-container *ngIf="message.type === MessageType.Interactive">
    <app-chat-context-interactive-message
      [class]="'d-flex chat-message-content max-width-100 me-2'"
      [class.received]="message.customerMessage"
      [chat]="chat"
      [message]="message">
    </app-chat-context-interactive-message>
  </ng-container>
  <ng-container *ngIf="message.type === MessageType.Template">
    <app-chat-context-template-message
      [class]="'chat-message-content me-2'"
      [class.received]="message.customerMessage"
      [chat]="chat"
      [message]="message">
    </app-chat-context-template-message>
  </ng-container>
  <ng-container *ngIf="message.type === MessageType.Image">
    <app-chat-context-image-message
      [class]="'chat-message-content me-2'"
      [class.received]="message.customerMessage"
      [chat]="chat"
      [message]="message">
    </app-chat-context-image-message>
  </ng-container>
  <ng-container *ngIf="message.type === MessageType.Sticker">
    <app-chat-context-sticker-message
      [class]="'chat-message-content me-2'"
      [class.received]="message.customerMessage"
      [chat]="chat"
      [message]="message">
    </app-chat-context-sticker-message>
  </ng-container>
  <ng-container *ngIf="message.type === MessageType.Video">
    <app-chat-video-message
      [class]="'chat-message-content me-2'"
      [class.received]="message.customerMessage"
      [chat]="chat"
      [message]="message">
    </app-chat-video-message>
  </ng-container>
  <ng-container *ngIf="message.type === MessageType.Audio">
    <app-chat-context-audio-message
      [class]="'chat-message-content me-2'"
      [class.received]="message.customerMessage"
      [message]="message"
      [chat]="chat">
    </app-chat-context-audio-message>
  </ng-container>

  <ng-container *ngIf="message.type === MessageType.Document">
    <app-chat-context-document-message
      [class]="'chat-message-content me-2'"
      [class.received]="message.customerMessage"
      [chat]="chat"
      [message]="message">
    </app-chat-context-document-message>
  </ng-container>
</div>

<!-- Message Sent Time -->
<div class="d-flex mb-2" *ngIf="message" [ngClass]="message.customerMessage ? '' : 'justify-content-end text-end'">
  <p class="mb-0 fs--2 text-900 fw-semi-bold text-dark">
    {{ message.sentTime | date: 'dd/MM/yyyy HH:mm:ss' }}
  </p>
</div>
