<div class="card shadow-none border border-300 smart-assistants-list">
  <div class="card-header px-4 py-3 border-bottom border-300 bg-soft">
    <div class="row g-3 justify-content-between align-items-center">
      <div class="col-12 col-md d-flex justify-content-between align-items-center">
        <h4 class="text-900 text-truncate mb-0 pe-2">
          <i class="fa-duotone fa-wand-magic-sparkles text-primary"></i>
          <ng-container *ngIf="smartAssistantOrchestratorForm.get('name').value">
            {{ smartAssistantOrchestratorForm.get('name').value }}
          </ng-container>
          <ng-container *ngIf="!smartAssistantOrchestratorForm.get('name').value && !smartAssistantOrchestrator?.id">
            <span class="text-primary">
              Nuevo orquestador
            </span>
          </ng-container>
        </h4>
        <button type="button" class="btn btn-sm btn-phoenix-primary" (click)="showSmartAssistantOrchestratorList()">
          <i class="fa fa-arrow-left"></i>
        </button>
      </div>
    </div>
    <div class="row">
      <p class="text-700 lh-sm mt-1 my-0">
        Configura tu orquestador.
      </p>
    </div>
  </div>
  <div class="card-body smart-assistants-card-body-list scrollbar p-3">
    <form
      [formGroup]="smartAssistantOrchestratorForm"
      (submit)="saveSmartAssistantOrchestrator()">
      <div class="row mb-2">
        <h5 class="text-dark">
          Datos del orquestador
        </h5>
      </div>
      <!-- Nombre del orquestador -->
      <div class="row mb-3">
        <div class="col-12">
          <div class="form-floating">
            <input
              class="form-control"
              id="createSmartAssistantName"
              type="text"
              placeholder="Nombre del orquestador"
              formControlName="name"
              [ngClass]="{
              'is-invalid': smartAssistantOrchestratorForm.get('name').touched && smartAssistantOrchestratorForm.get('name').invalid,
              'is-valid': smartAssistantOrchestratorForm.get('name').touched && smartAssistantOrchestratorForm.get('name').valid
            }">
            <label for="createSmartAssistantName">
              Nombre del orquestador
            </label>
            <div
              *ngIf="smartAssistantOrchestratorForm.get('name').touched && smartAssistantOrchestratorForm.get('name').invalid"
              class="invalid-feedback text-warning">
              Ingrese un nombre para el orquestador, por favor.
            </div>
          </div>
        </div>
      </div>
      <!-- Botones -->
      <div class="row mb-3 g-2 justify-content-end">
        <div class="col-auto" *ngIf="smartAssistantOrchestrator?.id !== undefined">
          <button
            type="button"
            class="btn btn-sm btn-phoenix-danger"
            (click)="deleteSmartAssistantOrchestrator()">
            <i class="fa fa-trash me-1"></i>
            Eliminar
          </button>
        </div>
        <div class="col-auto">
          <button
            class="btn btn-sm btn-phoenix-primary"
            [disabled]="smartAssistantOrchestratorForm.invalid">
            <i class="fa fa-save me-1"></i>
            Guardar
          </button>
        </div>
      </div>
      <!-- Sincronizar -->
      <div class="row g-2 justify-content-end" *ngIf="false">
        <div class="col-auto">
          <button
            class="btn btn-sm btn-phoenix-warning"
            [disabled]="smartAssistantOrchestratorForm.invalid">
            <i class="fa fa-bolt me-1"></i>
            Sincronizar
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<ng-template #showSmartAssistantInstructionsModal let-modal>
  <div class="modal-body scrollbar p-3">
    <form
      [formGroup]="smartAssistantOrchestratorForm">
      <textarea
        class="form-control scrollbar"
        rows="30"
        placeholder="Instrucciones del orquestador"
        formControlName="name"
        [ngClass]="{
          'is-invalid': smartAssistantOrchestratorForm.get('name').touched && smartAssistantOrchestratorForm.get('name').invalid,
        }"></textarea>
    </form>
  </div>
</ng-template>
