import { AfterViewInit, Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, map, merge, Observable, OperatorFunction, Subject } from "rxjs";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-typeahead",
  template: `
    <div class="form-floating mb-2">
      <input
        [id]="id"
        type="text"
        class="form-control"
        [formControl]="formControl"
        [placeholder]="placeHolder"
        [editable]="false"
        [attr.disabled]="disabled"
        #instance="ngbTypeahead"
        [ngbTypeahead]="searchElements"
        [resultFormatter]="formatResult"
        [inputFormatter]="formatInput"
        (focus)="focusSubject.next($any($event).target.value)"
        (click)="clickSubject.next($any($event).target.value)">
      <label [for]="id">
        {{ elementsAvailable.length > 0 ? placeHolder : noElementsPlaceHolder }}
      </label>
    </div>
    <div class="mb-1" *ngIf="elementsChosen?.length > 0">
      <span
        *ngFor="let element of elementsChosen"
        (click)="removeElement(element)"
        role="button"
        class="badge badge-phoenix fs--2 me-1"
        [class.badge-phoenix-info]="!element?.['color']"
        [ngStyle]="{
          'color': element?.['color'] ? element?.['color'] : '',
          'border': element?.['color'] ? '1px solid ' + element?.['color'] : ''
        }">
        {{ element['name'] }}
        <i class="fas fa-xmark tag-xmark-icon badge-icon"></i>
      </span>
    </div>
  `,
  styles: [],
})
export class TypeaheadComponent<T> implements OnInit, AfterViewInit {

  @Input() public id: string;
  @Input() public keyId: string = "id";
  @Input() public keyName: string = "name";

  @Input()
  public singleChoose: boolean = false;

  @Input()
  public disabled: boolean = false;

  @Input()
  public placeHolder: string = "Buscar...";

  @Input()
  public noElementsPlaceHolder: string = "No hay elementos disponibles";

  @Input()
  public elementsAll: T[] = [];

  @Input()
  public elementChosen: T = undefined;

  @Input()
  public initialElementsChosen: T[] = [];

  @Output()
  public elementChosenEvent: Subject<T> = new Subject<T>();

  @Output()
  public elementsChosenEvent: Subject<T[]> = new Subject<T[]>();

  @Output()
  public elementRemovedEvent: Subject<T> = new Subject<T>();

  @ViewChild("instance", { static: true }) instance: any;
  public focusSubject: Subject<string> = new Subject<string>();
  public clickSubject: Subject<string> = new Subject<string>();
  public formatResult = (value: T) => value[this.keyName];
  public formatInput = (value: T) => value[this.keyName];

  public formControl: FormControl;
  public elementsAvailable: T[] = [];
  public elementsChosen: T[] = [];

  constructor() {

  }

  ngOnInit() {

    this.elementsAvailable = [...this.elementsAll];
    this.initializeElementChosenEvent();

    if (this.elementChosen) {
      this.formControl.setValue(this.elementChosen, { emitEvent: false });
      this.addElement(this.elementChosen);
    }

    if (this.initialElementsChosen?.length > 0) {
      for (const element of this.initialElementsChosen) {
        this.addElement(element);
      }
    }

  }

  ngAfterViewInit() {
    if (this.disabled) {
      document.getElementById(this.id).setAttribute("disabled", "true");
    }
  }

  /* Filter Section */
  public searchElements: OperatorFunction<string, readonly T[]> = (text: Observable<string>) => {

    const debouncedText = text.pipe(debounceTime(1), distinctUntilChanged());
    const clicksWithClosedPopup = this.clickSubject.pipe(
      filter(() => !this.instance.isPopupOpen()),
    );

    const focusSubject = this.focusSubject;

    return merge(debouncedText, focusSubject, clicksWithClosedPopup).pipe(
      map((term: string) => {

        let filteredElements: T[];

        if (term === "") {
          filteredElements = this.elementsAvailable;
        } else {
          const filtered = this.elementsAvailable.filter(
            (e) => e[this.keyName].toLowerCase().indexOf(term.toLowerCase()) > -1,
          );
          if (this.singleChoose && this.elementChosen) {
            if (this.elementChosen[this.keyName] === term) {
              filteredElements = this.elementsAvailable;
            } else {
              filteredElements = filtered;
            }
          } else {
            filteredElements = filtered;
          }
        }

        return filteredElements.slice(0, 10);
      }),
    );
  };

  private initializeElementChosenEvent() {

    this.formControl = new FormControl("");

    this.formControl.valueChanges.subscribe((element: T) => {
      if (element) this.addElement(element);
    });
  }

  public addElement(element: T) {

    if (!this.singleChoose) {

      this.elementsChosen.push(element);
      this.formControl.setValue(null);

      const chatTagChosenIndex = this.elementsAvailable.findIndex((e) => e[this.keyId] === element[this.keyId]);

      this.elementsAvailable.splice(chatTagChosenIndex, 1);
      this.elementsAvailable.sort((ct1, ct2) => ct2[this.keyId] - ct1[this.keyId]);

      this.elementsChosenEvent.next(this.elementsChosen);

    } else {

      this.elementChosen = element;
      this.elementChosenEvent.next(this.elementChosen);
    }

  }

  public removeElement(element: T) {

    const elementChosenIndex = this.elementsChosen.findIndex(
      (e) => e[this.keyId] === element[this.keyId],
    );

    this.elementsChosen.splice(elementChosenIndex, 1);

    this.elementsAvailable.push(element);
    this.elementsAvailable.sort((e1, e2) => e2[this.keyId] - e1[this.keyId]);

    this.elementRemovedEvent.next(element);
  }

}