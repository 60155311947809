import { SmartAssistantTopicItemInfo } from "../topic-item/smart-assistant-topic-item-info.dto";

export class SmartAssistantTopicInfo {

  id: number;
  createdAt: any;
  name: string;
  items: SmartAssistantTopicItemInfo[] = [];

  constructor(id: number, name: string, items: SmartAssistantTopicItemInfo[]) {
    this.id = id;
    this.name = name;
    this.items = items;
  }

}