import { MessageInfo } from '@models/chat/message/message-info.dto';
import { SmartAssistantTopicItemActionType } from '@type/utilities/smart-assistant/smart-assistant-topic-item-action.type';
import { ChatTagInfo } from '@models/chat/tag/chat-tag-info.dto';

export class TestSmartAssistantMessageContainer {

  message: MessageInfo;
  httpCallUrl: string;
  chatTags: ChatTagInfo[] = [];
  type: SmartAssistantTopicItemActionType;

  constructor(type: SmartAssistantTopicItemActionType) {
    this.type = type;
  }


}