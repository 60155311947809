
<div *ngIf="message" class="mb-1 p-3 rounded-2 position-relative" [ngClass]="{
  'received-message-content border': message.customerMessage,
  'sent-message-content bg-primary light text-white': !message.customerMessage,
}">
  <app-chat-context-message
    *ngIf="message.contextMessage"
    [message]="message.contextMessage"
    [chat]="chat">
  </app-chat-context-message>

  <ng-container *ngIf="message.referralSourceUrl">
    <p class="mb-0 text-dark">
      <b>Origen del mensaje</b>
    </p>
    <a [href]="message.referralSourceUrl">
      {{ message.referralSourceUrl }}
    </a>
    <p class="text-truncate mb-2 referral-body" style="max-width: 400px">
      {{ message.referralBody }}
    </p>
  </ng-container>

  <p
    [innerHTML]="message.content"
    class="mb-0 message-content"
    [class.dark-url]="message.customerMessage">
    {{ message.content }}
  </p>
  <span class="reaction-emoji" *ngIf="message.reactionEmoji">
    {{ message.reactionEmoji }}
  </span>
</div>