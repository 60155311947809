
export enum WhatsAppMessageTemplateType {
  
  Marketing = 'marketing',
  Utility = 'utility',
  Authentication = 'authentication',

}

export const WhatsAppMessageTemplateTypeMetadata = {
  [WhatsAppMessageTemplateType.Marketing]: {
    description: 'Marketing'
  },
  [WhatsAppMessageTemplateType.Utility]: {
    description: 'Utilidad'
  },
  [WhatsAppMessageTemplateType.Authentication]: {
    description: 'Autenticación'
  },
};