<ul class="nav nav-underline mt-6" id="myTab" role="tablist">
  <li class="nav-item" *ngIf="shouldShowWhatsAppWeb()">
    <a class="nav-link text-success" [class.active]="activeTab === 'whatsapp-web'"
       id="qr-tab"
       data-bs-toggle="tab"
       href="#tab-whatsapp-web"
       role="tab"
       (click)="setActiveTab('whatsapp-web')">
      <i class="fa fa-qrcode"></i>
      WhatsApp Web
    </a>
  </li>
  <li class="nav-item" *ngIf="shouldShowWhatsAppCloud()">
    <a class="nav-link text-info" [class.active]="activeTab === 'quick-cloud'"
       id="quick-cloud-tab"
       data-bs-toggle="tab"
       href="#tab-quick-cloud"
       role="tab"
       (click)="setActiveTab('quick-cloud')">
      <i class="fa fa-cloud"></i>
      WhatsApp Cloud <b>(Rápida)</b>
    </a>
  </li>
  <li class="nav-item" *ngIf="shouldShowManual()">
    <a class="nav-link text-dark" [class.active]="activeTab === 'manual'"
       id="manual-tab"
       data-bs-toggle="tab"
       href="#tab-manual"
       role="tab"
       (click)="setActiveTab('manual')">
      <i class="fa fa-pencil"></i>
      WhatsApp Cloud <b>(Manual)</b>
    </a>
  </li>
</ul>
<div class="tab-content mt-3">
  <div class="tab-pane fade" id="tab-whatsapp-web" role="tabpanel"
      [ngClass]="{'show active': activeTab === 'whatsapp-web'}"
      *ngIf="shouldShowWhatsAppWeb() || isWhatsAppWebConnected()">
    <div class="card mb-3">
      <div class="card-body text-center">
        <h4 class="card-title mb-6">
          <i class="fa fa-qrcode"></i>
          Conexión a <b class="text-success">WhatsApp</b> por QR
        </h4>
        <ng-container *ngTemplateOutlet="whatsAppWebQrCode"></ng-container>
      </div>
    </div>
  </div>
  <div class="tab-pane fade" id="tab-quick-cloud" role="tabpanel"
      [ngClass]="{'show active': activeTab === 'quick-cloud'}"
      *ngIf="shouldShowWhatsAppCloud() || isWhatsAppCloudConnected()">
    <div class="card mb-3">
      <div class="card-body">
        <h4 class="card-title mb-3">
          <i class="fa fa-cloud"></i>
          Conexión rápida a WhatsApp Cloud API
        </h4>
        <ng-container *ngTemplateOutlet="whatsAppCloudApiQuick"></ng-container>
      </div>
    </div>
  </div>
  <div class="tab-pane fade" id="tab-manual" role="tabpanel"
       [ngClass]="{'show active': activeTab === 'manual'}"
       *ngIf="shouldShowManual()">
    <div *ngIf="errorCode === 'WHATSAPP_CONFIG_NOT_FOUND'"
         class="alert alert-outline-warning d-flex align-items-center" role="alert">
      <span class="fas fa-info-circle text-warning fs-3 me-3"></span>
      <p class="mb-0 flex-1">Para comenzar, configura tu canal de WhatsApp Cloud</p>
      <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
    <div class="card mb-3">
      <div class="card-body">
        <h4 class="card-title mb-3">
          WhatsApp Cloud
        </h4>
        <ng-container *ngTemplateOutlet="whatsAppCloudApiManual"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #whatsAppWebQrCode>
  <div class="row">
    <div class="col-md-12">
      <ng-container *ngIf="shouldShowQRCode()">
        <qrcode
          class="mt-0 mb-4"
          [qrdata]="whatsappWebInstanceQRCode?.code || whatsappWebInstanceStatus?.lastCode"
          [width]="280"
          [errorCorrectionLevel]="'M'">
        </qrcode>
      </ng-container>
      <button
        type="button"
        class="btn btn-outline-danger btn-md"
        (click)="disconnect(ChannelType.WhatsAppWeb)"
        *ngIf="isWhatsAppWebConnected()">
        <i class="fa fa-cancel me-1"></i>
        Desconectar
      </button>
      <button
        type="button"
        class="btn btn-outline-indigo btn-md"
        (click)="getConfig()"
        *ngIf="!loading && whatsappWebInstanceStatus?.status === WhatsappWebStatusType.DISCONNECTED">
        <i class="fa fa-arrows-rotate-reverse me-1"></i>
        Reconectar
      </button>
      <ng-container *ngIf="whatsappWebInstanceStatus === undefined && whatsappWebInstanceQRCode === undefined && !loading">
        <p class="text-warning mb-0">
          No se ha podido obtener un código QR.
        </p>
      </ng-container>
      <ng-container *ngIf="loading">
        <div class="d-flex align-items-center justify-content-center">
          <div class="spinner-grow spinner-grow-sm text-primary me-2" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <p class="text-primary mb-0">
            Por favor, espere...
          </p>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="form-group mt-3 text-muted">
    La conexión a WhatsApp es completamente segura y encriptada.
  </div>
</ng-template>

<ng-template #whatsAppCloudApiQuick>
  <div class="row">
    <div class="col-md-12">
      <button
        type="button"
        class="btn btn-outline-success btn-md"
        (click)="signIn()"
        *ngIf="!isWhatsAppWebConnected() && !whatsAppConfig?.wabaId">
        <i class="fa-brands fa-whatsapp me-1"></i>
        Conectar ahora
      </button>
      <button
        type="button"
        class="btn btn-outline-warning btn-md"
        (click)="disconnect(ChannelType.WhatsApp)"
        *ngIf="whatsAppConfig !== undefined && whatsAppConfig?.configurationType === WhatsAppConfigurationType.Automatic">
        <i class="fa fa-cancel me-1"></i>
        Desconectar
      </button>
      <button
        type="button"
        class="btn btn-outline-success btn-md"
        disabled
        *ngIf="whatsAppConfig !== undefined && whatsAppConfig?.configurationType === WhatsAppConfigurationType.Manual">
        <i class="fa-brands fa-whatsapp me-1"></i>
        Conectar ahora
      </button>
      <div class="form-group mt-3 text-muted">
        La conexión a WhatsApp es completamente segura y encriptada.
      </div>
    </div>
  </div>
</ng-template>

<ng-template #whatsAppCloudApiManual>
  <div class="row gx-3">
    <div class="col-12 col-sm-12 col-xl-12">
      <form
        [formGroup]="whatsAppConfigForm"
        (submit)="saveManualConfig()">
        <div class="mb-3">
          <div class="form-floating">
            <input
              class="form-control input-only-numbers"
              id="phoneNumber"
              type="number"
              placeholder="+52123456123"
              formControlName="phoneNumber"
              [ngClass]="{
                      'is-invalid': whatsAppConfigForm.get('phoneNumber').touched && whatsAppConfigForm.get('phoneNumber').invalid,
                      'is-valid': whatsAppConfigForm.get('phoneNumber').touched && whatsAppConfigForm.get('phoneNumber').valid
                    }"
            />
            <label for="phoneNumber">
              Número de WhatsApp
            </label>
            <div *ngIf="whatsAppConfigForm.get('phoneNumber').touched && whatsAppConfigForm.get('phoneNumber').invalid" class="invalid-feedback">
              Ingresa un número de WhatsApp válido.
            </div>
          </div>
        </div>
        <div class="mb-3">
          <div class="form-floating">
            <input
              class="form-control input-only-numbers"
              id="phoneNumberId"
              type="number"
              placeholder="123456789123456"
              formControlName="phoneNumberId"
              [ngClass]="{
                      'is-invalid': whatsAppConfigForm.get('phoneNumberId').touched && whatsAppConfigForm.get('phoneNumberId').invalid,
                      'is-valid': whatsAppConfigForm.get('phoneNumberId').touched && whatsAppConfigForm.get('phoneNumberId').valid
                    }"
            />
            <label for="phoneNumberId">
              WhatsApp Phone Number Id
            </label>
            <div *ngIf="whatsAppConfigForm.get('phoneNumberId').touched && whatsAppConfigForm.get('phoneNumberId').invalid" class="invalid-feedback">
              Ingresa un WhatsApp Phone Number Id válido.
            </div>
          </div>
        </div>
        <div class="mb-3">
          <div class="form-floating">
            <input
              class="form-control"
              id="accessToken"
              type="text"
              placeholder="Token de acceso"
              formControlName="accessToken"
              [ngClass]="{
                      'is-invalid': whatsAppConfigForm.get('accessToken').touched && whatsAppConfigForm.get('accessToken').invalid,
                      'is-valid': whatsAppConfigForm.get('accessToken').touched && whatsAppConfigForm.get('accessToken').valid
                    }"
            />
            <label for="accessToken">Token de acceso</label>
            <div *ngIf="whatsAppConfigForm.get('accessToken').touched && whatsAppConfigForm.get('accessToken').invalid" class="invalid-feedback">
              Ingresa un Token de acceso
            </div>
          </div>
        </div>
        <div class="mb-3">
          <div class="form-floating">
            <input
              class="form-control input-only-numbers"
              id="wabaId"
              type="number"
              placeholder="Your WABA ID"
              formControlName="wabaId"
              [ngClass]="{
                      'is-invalid': whatsAppConfigForm.get('wabaId').touched && whatsAppConfigForm.get('wabaId').invalid,
                      'is-valid': whatsAppConfigForm.get('wabaId').touched && whatsAppConfigForm.get('wabaId').valid
                    }"
            />
            <label for="wabaId">WABA ID</label>
            <div *ngIf="whatsAppConfigForm.get('wabaId').touched && whatsAppConfigForm.get('wabaId').invalid" class="invalid-feedback">
              Ingresa un WABA ID válido
            </div>
          </div>
        </div>
        <div class="mb-3">
          <div class="form-floating">
            <input
              class="form-control input-only-numbers"
              id="appId"
              type="number"
              placeholder="Your App ID"
              formControlName="appId"
              [ngClass]="{
                      'is-invalid': whatsAppConfigForm.get('appId').touched && whatsAppConfigForm.get('appId').invalid,
                      'is-valid': whatsAppConfigForm.get('appId').touched && whatsAppConfigForm.get('appId').valid
                    }"
            />
            <label for="appId">App ID</label>
            <div *ngIf="whatsAppConfigForm.get('appId').touched && whatsAppConfigForm.get('appId').invalid" class="invalid-feedback">
              Ingresa un App ID válido
            </div>
          </div>
        </div>
        <div class="text-end">
          <div>
            <button
              type="button"
              class="btn btn-outline-warning me-2"
              (click)="disconnectManual()"
              [disabled]="whatsAppConfig?.configurationType !== WhatsAppConfigurationType.Manual">
              Desconectar
            </button>
            <button
              type="button"
              class="btn btn-phoenix-danger me-2"
              (click)="cancelSave()"
              [disabled]="whatsAppConfig?.configurationType === WhatsAppConfigurationType.Automatic">
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-phoenix-primary"
              [disabled]="whatsAppConfigForm.invalid || whatsAppConfig?.configurationType === WhatsAppConfigurationType.Automatic">
              Guardar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>