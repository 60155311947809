<div class="row">
  <div class="col-xl-3 col-lg-4 col-sm-3">
    <div class="card shadow-none border border-300 adjust-content-size">
      <div class="card-header p-4 border-bottom border-300 bg-soft">
        <div class="row g-3 justify-content-between align-items-center">
          <div class="col-12 col-md">
            <h4 class="text-900 mb-0">
              <i class="fas fa-table text-success"></i>
              Tableros
            </h4>
          </div>
          <div class="col col-md-auto">
            <nav class="nav nav-underline justify-content-end doc-tab-nav align-items-center" role="tablist">
              <button type="button" class="btn btn-sm btn-phoenix-primary">
                <i class="fa fa-plus"></i>
              </button>
            </nav>
          </div>
        </div>
        <div class="row">
          <p class="text-700 lh-sm mt-1 mb-0">
            Organiza tus chats en tableros.
          </p>
        </div>
      </div>
      <div class="card-body board-boards-card-body-list scrollbar p-0">
        <ul class="list-group list-group-flush">
          <li class="list-group-item d-flex justify-content-between align-items-center cursor-pointer"
              (click)="changeView( 'create-marketplace-app')">
            Registrar una nueva aplicación
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-xl-9 col-lg-8 col-sm-9">
    <div class="card card-body h-100 d-flex">
      <ng-container *ngIf="showView === 'create-marketplace-app'">
        <app-create-marketplace-app></app-create-marketplace-app>
      </ng-container>
    </div>
  </div>
</div>


