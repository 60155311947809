<div class="row"
     [class.transparent]="firstLoad"
     [class.not-transparent]="!firstLoad">

  <div class="col-xl-3 col-lg-4 col-sm-3">
    <div class="card shadow-none border border-300 kanban-funnels-list">
      <div class="card-header px-4 py-3 border-bottom border-300 bg-soft">
        <div class="row g-3 justify-content-between align-items-center">
          <div class="col-12 col-md">
            <h4 class="text-900 mb-0">
              <i class="fas fa-filters text-success"></i>
              Embudos
            </h4>
          </div>
          <div class="col col-md-auto">
            <nav class="nav nav-underline justify-content-end doc-tab-nav align-items-center" role="tablist">
              <button type="button" class="btn btn-sm btn-phoenix-primary"
                      (click)="openSaveFunnel()">
                <i class="fa fa-plus"></i>
              </button>
            </nav>
          </div>
        </div>
        <div class="row">
          <p class="text-700 lh-sm mt-1 my-0">
            Organiza tus chats en embudos.
          </p>
        </div>
      </div>
      <div class="card-body kanban-funnels-card-body-list scrollbar p-0">
        <p class="m-3" *ngIf="funnels?.length == 0">
          Sin embudos, crea uno nuevo.
        </p>
        <ul class="list-group list-group-flush" *ngIf="funnels?.length !== 0">
          <li class="list-group-item d-flex justify-content-between align-items-center"
              *ngFor="let funnel of funnels">
            {{ funnel.name }}
            <div class="d-flex justify-content-end">
              <div class="cursor-pointer" (click)="openSaveFunnel(funnel)">
                <i class="fa text-dark fa-edit" title="Editar"></i>
              </div>
              <div class="cursor-pointer" (click)="openFunnel(funnel)">
                <i class="fa-duotone fa-arrow-up-right-from-square text-primary ms-2" title="Visualizar"></i>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-xl-9 col-lg-8 col-sm-9">
    <div class="kanban-content border rounded" *ngIf="selectedFunnel !== undefined">
      <div class="kanban-header">
        <div class="row gx-0 justify-content-between justify-content-md-start">
          <div class="col-auto d-flex align-items-center">
            <h3 class="text-1000 my-1">
              <i class="fa-solid fa-square-kanban text-warning"></i>
              {{ selectedFunnel?.name }}
            </h3>
          </div>
          <div class="col-md-auto d-flex align-items-center ms-auto mt-2 mt-md-0">
            <ul class="nav w-100 fs--1 d-flex align-items-center">
              <li class="nav-item">
                <a class="nav-link d-flex align-items-center text-900 ps-0 pe-2 px-xl-3 fw-bold"
                   href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#searchBoxModal">
                  <i class="me-1 fas fa-search"></i>
                  <span class="d-none d-xxl-inline">
                Buscar
              </span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link d-flex align-items-center text-900 px-2 px-xl-3 fw-bold" href="javascript:void(0);">
                  <i class="me-1 fas fa-filter"></i>
                  <span class="d-none d-xxl-inline">
                Filtrar
              </span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link d-flex align-items-center text-900 px-2 px-xl-3 fw-bold" href="javascript:void(0);">
                  <span class="me-1 fa-solid fa-right-left"></span>
                  <span class="d-none d-xxl-inline">
                Exportar / Importar
              </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="kanban-container smart-assistant-kanban scrollbar" data-kanban-container="data-kanban-container">
        <div class="kanban-column smart-topic"
             [class.collapsed]="funnelStage.collapsed"
             [attr.kanban-column-id]="funnelStage.id"
             *ngFor="let funnelStage of selectedFunnel.stages">

          <div class="kanban-column-header px-4 hover-actions-trigger">
            <div class="d-flex justify-content-between align-items-center border-bottom border-3 py-3 border-300">
              <h5 class="mb-0 kanban-column-title text-truncate" style="max-width: 80%"
                  data-bs-toggle="tooltip" data-bs-placement="top" [title]="funnelStage.name">
                <span class="smart-topic-name">
                  {{ funnelStage.name }}
                </span>
                <span class="kanban-title-badge">
                  {{ funnelStage.chats.totalElements }}
                </span>
              </h5>
              <div class="d-flex justify-content-end align-content-center">
                <div class="hover-actions-trigger">
                  <i class="fa fa-ellipsis-h cursor-pointer kanban-header-dropdown-btn me-3"
                     data-boundary="viewport"
                     data-bs-toggle="dropdown"></i>
                  <div class="dropdown-menu dropdown-menu-end py-2">
                    <a class="dropdown-item d-flex flex-between-center border-1" role="button"
                       (click)="openSaveFunnelStage(funnelStage)">
                    <span>
                      <i class="fa fa-edit me-2 text-primary"></i>
                      Editar
                    </span>
                    </a>
                    <a class="dropdown-item d-flex flex-between-center border-1" role="button"
                       (click)="deleteFunnelStage(funnelStage)">
                    <span>
                      <i class="fa fa-trash me-2 text-danger"></i>
                      Eliminar
                    </span>
                    </a>
                  </div>
                </div>
                <i class="fa fa-arrows-rotate text-primary fs--1 pt-1 me-2 ms-auto kanban-collapse-icon"
                   *ngIf="!funnelStage.collapsed"
                   (click)="refreshFunnelStageChats(funnelStage)">
                </i>
                <span class="uil uil-left-arrow-to-left text-warning fs-0 ms-auto kanban-collapse-icon"
                      (click)="toggleFunnelStageCollapse(funnelStage)">
                </span>
                <span class="uil uil-arrow-from-right fs-0 ms-auto kanban-collapse-icon"
                      (click)="toggleFunnelStageCollapse(funnelStage)">
                </span>
              </div>
            </div>
          </div>
          <div class="kanban-items-container smart-topic-items scrollbar"
               [id]="'kc' + funnelStage.id">
            <ng-container
              infiniteScroll
              [infiniteScrollDistance]="3"
              [infiniteScrollThrottle]="50"
              [infiniteScrollContainer]="'#kc' + funnelStage.id"
              [fromRoot]="true"
              (scrolled)="onScrolled(funnelStage)">
              <ng-container *ngFor="let chat of funnelStage.chats.content">
                <div class="sortable-item-wrapper smart-topic-item border-bottom px-2 py-2"
                     [attr.kanban-column-chat-id]="chat.id">
                  <div class="card sortable-item hover-actions-trigger">
                    <div class="card-body py-1 px-1"
                         (click)="openFunnelStageChat($event, funnelStage, chat)">
                      <div class="d-flex align-items-center justify-content-center chat-list-item p-2">
                        <div class="
                        avatar avatar-xl
                        status-online
                        position-relative
                        me-2">
                          <div
                            class="avatar-name rounded-circle"
                            [ngStyle]="{'color': chat.color}">
                            <span>{{ chat.chatName[0] }}</span>
                          </div>
                        </div>
                        <div class="flex-1 d-xl-block">
                          <div class="d-flex justify-content-between align-items-center">
                            <h5 class="text-900 fw-normal name text-truncate chat-name">
                              {{ chat.chatName }}
                            </h5>
                            <p class="fs--2 text-800 mb-0">
                              {{ chat.lastMessageSentTime | date: 'dd/MM/yyyy HH:mm:ss' }}
                            </p>
                          </div>
                          <div class="d-flex justify-content-between">
                            <p class="fs--1 mb-0 text-600 message text-truncate last-message-label text-truncate">
                              {{ chat.lastMessageTypeLabel }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="kanban-column position-relative bg-transparent">
          <div class="d-flex h-100 flex-center fw-bold hover-bg-100">
            <a class="text-decoration-none stretched-link text-800" role="button" (click)="openSaveFunnelStage()">
              <div class="circle-btn bg-200 mx-auto mb-2">
                <span class="fas fa-plus" data-fa-transform="shrink-2"></span>
              </div>
              <span>
                Agregar nueva etapa
              </span>
            </a>
          </div>
        </div>

        <div class="card card-body h-100 d-flex justify-content-center align-items-center"
             *ngIf="selectedFunnel === undefined">
          <h2 class="text-muted">
            ¡Hola! Da click en visualizar un embudo para iniciar.
          </h2>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #saveFunnelOffCanvas let-offcanvas>
  <div class="offcanvas-header align-items-start border-bottom flex-stage">
    <div class="pt-1 w-100 d-flex justify-content-between align-items-start">
      <div>
        <h4 class="mb-2 me-2 lh-sm">
          {{ selectedFunnel ? 'Editar embudo' : 'Personaliza tu embudo' }}
        </h4>
        <p class="mb-0 fs--1 text-info-500">
          Elige el nombre del embudo.
        </p>
      </div>
      <button
        class="btn p-1 fw-bolder"
        type="button"
        (click)="offcanvas.close()">
        <i class="fas fa-xmark fs-2"></i>
      </button>
    </div>
  </div>
  <div class="offcanvas-body">
    <form
      [formGroup]="funnelForm"
      (submit)="saveFunnel()">
      <div class="row mb-2">
        <h5 class="text-dark">
          Datos del embudo
        </h5>
      </div>
      <!-- Nombre del embudo -->
      <div class="row mb-3">
        <div class="col-12">
          <div class="form-floating">
            <input
              class="form-control"
              id="funnelName"
              type="text"
              placeholder="Nombre del embudo"
              formControlName="name"
              [ngClass]="{
                'is-invalid': funnelForm.get('name').touched && funnelForm.get('name').invalid,
                'is-valid': funnelForm.get('name').touched && funnelForm.get('name').valid
              }">
            <label for="funnelName">
              Nombre del embudo
            </label>
            <div
              *ngIf="funnelForm.get('name').touched && funnelForm.get('name').invalid"
              class="invalid-feedback text-warning">
              Ingrese un nombre para el funnel, por favor.
            </div>
          </div>
        </div>
      </div>
      <!-- Botones -->
      <div class="row mb-3 g-2 justify-content-end">
        <div class="col-auto">
          <button
            type="button"
            class="btn btn-sm btn-phoenix-secondary"
            (click)="offcanvas.close()">
            Cancelar
          </button>
        </div>
        <div class="col-auto" *ngIf="!creatingFunnel">
          <button
            type="button"
            class="btn btn-sm btn-phoenix-danger"
            (click)="deleteFunnel()">
            <i class="fa fa-trash me-1"></i>
            Eliminar
          </button>
        </div>
        <div class="col-auto">
          <button
            class="btn btn-sm btn-phoenix-primary"
            [disabled]="funnelForm.invalid">
            <i class="fa fa-save me-1"></i>
            Guardar
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #saveFunnelStageOffCanvas let-offcanvas>
  <div class="offcanvas-header align-items-start border-bottom flex-stage">
    <div class="pt-1 w-100 d-flex justify-content-between align-items-start">
      <div>
        <h4 class="mb-2 me-2 lh-sm">
          {{ selectedFunnelStage ? 'Editar stagea' : 'Crea una stagea' }}
        </h4>
        <p class="mb-0 fs--1 text-info-500">
          Elige el nombre de la stagea. Las stageas definen los chats que se mostrarán en el funnel.
        </p>
      </div>
      <button
        class="btn p-1 fw-bolder"
        type="button"
        (click)="offcanvas.close()">
        <i class="fas fa-xmark fs-2"></i>
      </button>
    </div>
  </div>
  <div class="offcanvas-body">
    <form
      [formGroup]="funnelStageForm"
      (submit)="saveFunnelStage()">
      <!-- Nombre de la stagea -->
      <div class="row mb-3">
        <div class="col-12">
          <div class="form-floating">
            <input
              class="form-control"
              id="funnelStageName"
              type="text"
              placeholder="Nombre de la stagea"
              formControlName="name"
              [ngClass]="{
                'is-invalid': funnelStageForm.get('name').touched && funnelStageForm.get('name').invalid,
                'is-valid': funnelStageForm.get('name').touched && funnelStageForm.get('name').valid
              }">
            <label for="funnelStageName">
              Nombre de la stagea
            </label>
            <div
              *ngIf="funnelStageForm.get('name').touched && funnelStageForm.get('name').invalid"
              class="invalid-feedback text-warning"
            >
              Ingrese un nombre para la stagea, por favor.
            </div>
          </div>
        </div>
      </div>

      <hr>

      <!-- Botones -->
      <div class="row g-3 justify-content-end">
        <div class="col-auto">
          <button
            type="button"
            class="btn btn-phoenix-primary"
            (click)="offcanvas.close()">
            Cancelar
          </button>
        </div>
        <div class="col-auto">
          <button
            class="btn btn-primary"
            [disabled]="funnelStageForm.invalid">
            Guardar
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #funnelStageChatModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">
      Agregar nueva tarjeta
    </h5>
  </div>
  <div class="modal-body">
    <h1>cHAT</h1>
  </div>
</ng-template>

<ng-template #advisorsInfoPopoverContent>
  <p class="mb-1">
    Si un chat está asignado a un <strong class="text-primary">asesor</strong> seleccionado, el chat será filtrado
    por
    este.
  </p>
</ng-template>

<ng-template #chatTagsInfoPopoverContent>
  <p class="mb-1">
    Elija las etiquetas de los chats que desea filtrar y a continuación, el <b class="text-primary">tipo de
    filtro</b>
    que desea aplicar.
  </p>
</ng-template>

<ng-template #chatTagSearchTypeInfoPopoverContent>
  <p class="mb-1">
    <b class="text-primary">Al menos una:</b> Selecciona los chats que tengan al menos una de las etiquetas
    seleccionadas. <br><br>
    <b class="text-primary">Todas:</b> Selecciona los chats que tengan todas las etiquetas seleccionadas. <br><br>
    <b class="text-primary">Sólo seleccionadas:</b> Selecciona los chats que tengan exactamente las etiquetas
    seleccionadas.
  </p>
</ng-template>

<ng-template #dateRangePickerInfoPopoverContent>
  <p class="mb-1">
    Se tomarán en cuenta los chats que se encuentren dentro del rango de fechas seleccionado. <br> <br>
    <b class="text-primary">Nota:</b> si desea filtrar por una fecha específica, seleccione el mismo día en ambas
    fechas.
  </p>
</ng-template>

<ng-template #chatModal>
  <div class="modal-body">
    <app-chat
      [chat]="selectedChat"
      [showSideChatList]="false"
      [fromContactsComponent]="true">
    </app-chat>
  </div>
</ng-template>