import { FormGroup } from "@angular/forms";
import { WhatsAppMessageTemplateComponentHeaderType } from "./type/whatsapp-message-template-component-header.type";
import { WhatsAppMessageTemplateComponentType } from "./type/whatsapp-message-template-component.type";
import { WhatsAppMessageTemplateButton } from "./whatsapp-message-template-button.dto";
import { WhatsAppMessageTemplateExample } from "./whatsapp-message-template-example.dto";

export class WhatsAppMessageTemplateComponentInfo {

  type: WhatsAppMessageTemplateComponentType;
  format: WhatsAppMessageTemplateComponentHeaderType;
  text: string;
  buttons: WhatsAppMessageTemplateButton[];
  example: WhatsAppMessageTemplateExample;

  // Angular
  originalParametersText: string;
  parametersText: string;
  parameters: any[];

}