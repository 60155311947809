<div class="navbar-container">
  <nav class="navbar navbar-expand-lg justify-content-between navbar-light border-bottom-0 bg-white" data-sticky="top">
    <div class="container">
      <div class="col col-sm-6 col-lg-4 flex-fill px-0 d-flex justify-content-between">
        <a class="navbar-brand mr-0 fade-page" [routerLink]="['/']">
          <img src="./assets/img/logo/logo.png" alt="Ivana">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse" aria-expanded="false" aria-label="Toggle navigation">
          <img class="icon navbar-toggler-open" src="./assets/lib/leap/img/icons/interface/menu.svg" alt="menu interface icon" data-inject-svg />
          <img class="icon navbar-toggler-close" src="./assets/lib/leap/img/icons/interface/cross.svg" alt="cross interface icon" data-inject-svg />
        </button>
      </div>
      <div class="col col-sm-6 col-lg-8 collapse navbar-collapse px-0 px-lg-2 flex-fill justify-content-end">
        <div class="py-2 py-lg-0">
          <ul class="navbar-nav d-flex align-items-center">
            <li class="nav-item">
              <a [routerLink]="['/precios']" class="nav-link normal-link">
                Precios
              </a>
            </li>
            <li class="nav-item">
              <a href="#" class="nav-link normal-link">
                Características
              </a>
            </li>
            <li class="nav-item py-1">
              <a target="_blank" href="https://wa.me/525584214696?text=Hola, me gustaría conocer más sobre IVANA!"
                 class="btn btn-light text-grey-color ml-lg-3">
                <i class="fa fa-message-smile text-deep-blue"></i>
                Contáctanos
              </a>
            </li>
            <li class="nav-item py-1">
              <a [routerLink]="['app/sign-in']"
                 class="btn btn-deep-blue ml-lg-3">
                Iniciar sesión
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</div>