import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ShopInfo } from '@models/account/shop/shop-info.dto';
import { GetSignedUrlRequest } from '@models/media/get-signed-url-request.dto';
import { debounceTime, distinctUntilChanged, firstValueFrom, of, switchMap } from 'rxjs';
import { ImportContactsRequest } from '@models/chat/chat/import-contacts-request.dto';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ChatTagInfo } from '@models/chat/tag/chat-tag-info.dto';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ChatService } from '@services/chat/chat.service';
import { MediaService } from '@services/media/media.service';
import { ChatTagSearchRequest } from '@models/chat/tag/chat-tag-search-request.dto';
import { Page } from '@models/common/page';
import { ChatTagService } from '@services/chat/chat-tag.service';
import { UserInfo } from '@models/account/user/user.info.dto';
import { ToastrService } from 'ngx-toastr';
import { ChatTagRequest } from '@models/chat/tag/chat-tag-request.dto';
import { HttpErrorResponse } from '@angular/common/http';
import { CheckChatTagNameExistenceRequest } from '@models/chat/tag/check-chat-tag-name-existence-request.dto';
import { AuthService } from '@services/auth/auth.service';
import { EventEmitterService, NotificationTopic } from '@services/data/event-emitter.service';
import { SpinnerService } from '@services/data/spinner.service';

@Component({
  selector: 'app-import-contacts',
  templateUrl: './import-contacts.component.html',
  styleUrls: ['./import-contacts.component.css']
})
export class ImportContactsComponent implements OnInit {

  @ViewChild('importContactsResultModal')
  private importContactsResultModal: TemplateRef<any>;

  public importContactsResult: any = null;

  private readonly user: UserInfo;

  // Chat Tags
  public chatTags: ChatTagInfo[] = [];
  public selectedChatTags: ChatTagInfo[] = [];
  public loadingChatTags: boolean = true;

  // Chat Tags Form
  public creatingChatTag: boolean = false;
  public chatTagsForm: FormGroup;
  public duplicatedName: boolean = false;
  public sameName: boolean = false;
  public checkingDuplicatedName: string = 'before';
  public defaultColor: string = '#6E7891';

  // Import file form
  public fileImport: File;
  public importTemplateUrl: string = 'https://docs.google.com/spreadsheets/d/1wpNQjhhAFky1brKm_FJp2uZgRhYAeX8bCI0pT5NRdD4/edit?usp=sharing';

  constructor(
    private readonly authService: AuthService,
    private readonly offCanvasService: NgbOffcanvas,
    private readonly modalService: NgbModal,
    private readonly eventEmitterService: EventEmitterService,
    private readonly chatService: ChatService,
    private readonly chatTagService: ChatTagService,
    private readonly mediaService: MediaService,
    private readonly toastr: ToastrService,
    private readonly spinnerService: SpinnerService,
  ) {
    this.user = this.authService.getUser();
  }

  ngOnInit() {
    this.loadChatTags();
    this.initializeChatTagForm();
    this.initializeChatTagNameValidator();
  }

  private initializeChatTagForm() {

    this.chatTagsForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      color: new FormControl(this.defaultColor, [Validators.required]),
    });

    this.checkingDuplicatedName = 'before';
    this.duplicatedName = false;
    this.sameName = false;
  }

  private initializeChatTagNameValidator() {
    this.chatTagsForm
      .get('name')
      .valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((query) => {
        return of(query.trim());
      }),
    )
      .subscribe((name: string) => {
        this.checkChatTagNameExistence(name);
      });
  }

  private checkChatTagNameExistence(name: string) {

    if (!name || name.length === 0) {
      this.duplicatedName = false;
      return;
    }

    this.sameName = false;

    this.checkingDuplicatedName = 'checking';
    const afterChecking = 'after-checking';

    const checkChatTagNameExistenceRequest = new CheckChatTagNameExistenceRequest(this.user.shop.id, name);

    this.chatTagService
      .checkChatTagNameExistence(checkChatTagNameExistenceRequest)
      .subscribe({
        next: () => {
          this.duplicatedName = false;
          this.checkingDuplicatedName = afterChecking;
        },
        error: () => {
          this.duplicatedName = true;
          this.checkingDuplicatedName = afterChecking;
        },
        complete: () => {
        },
      });
  }

  private loadChatTags() {

    const infiniteSize: number = -1;
    const searchRequest = new ChatTagSearchRequest(this.user.shop.id, infiniteSize,);

    this.chatTagService.searchChatTags(searchRequest).subscribe({
      next: (chatTagsPage: Page<ChatTagInfo>) => {
        this.chatTags = chatTagsPage.content;
        this.loadingChatTags = false;
      },
      error: (error: any) => {
        console.error('Error en la carga de tags:', error);
      },
    });
  }

  public selectChatTags(chatTags: ChatTagInfo[]) {
    this.selectedChatTags = chatTags;
  }

  private resetChatTags() {
    this.selectedChatTags = [];
  }

  public saveChatTag() {

    const { name, color } = this.chatTagsForm.value;

    let chatTagId: number = null;

    const chatTagRequest = new ChatTagRequest(
      this.user.shop.id, name, color, chatTagId,
    );

    this.chatTagService.saveChatTag(chatTagRequest).subscribe({
      next: () => {
        this.creatingChatTag = false;
        this.initializeChatTagForm();
        this.loadChatTags();
        this.toastr.success('Etiqueta creada exitosamente');
      },
      error: (httpErrorResponse: HttpErrorResponse) => {
        const { error } = httpErrorResponse;
        const errorCode = error.code;

        if (errorCode === 'DUPLICATED_CHAT_LABEL') {
          this.duplicatedName = true;
        }
      },
      complete: () => {
      },
    });
  }

  public onImportFileChosen(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.fileImport = input.files[0];
    }
  }

  private async uploadImportFile(file: File, shop: ShopInfo) {

    const prefix = `${shop.uuid}`;
    const getSignedUrlRequest = new GetSignedUrlRequest(prefix, file.name, file.type);
    const getSignedUrlResponse = await firstValueFrom(this.chatService.generateImportContactsSignedUrl(getSignedUrlRequest));
    const { publicUrl, signedUrl } = getSignedUrlResponse;

    await firstValueFrom(this.mediaService.uploadMedia(signedUrl, file));

    return {
      key: getSignedUrlResponse.key,
      publicUrl: publicUrl,
    };
  }

  public showImportTemplate() {
    window.open(this.importTemplateUrl, '_blank');
  }

  public async importContacts() {

    const chatTagIds = this.selectedChatTags.map(ct => ct.id);

    let beforeActionMessage: string = `Está seguro que desea importar el archivo de contactos?`;
    let afterActionMessage: string = `Archivo importado exitosamente`;

    if (!confirm(beforeActionMessage)) return;

    this.spinnerService.show('Importando contactos...');

    const { publicUrl, key } = await this.uploadImportFile(this.fileImport, this.user.shop);

    const importContactsRequest = new ImportContactsRequest(
      this.user.shop.id, chatTagIds, publicUrl, key,
    );

    this.chatService.importContacts(importContactsRequest).subscribe({
      next: (data: any) => {
        this.importContactsResult = data;
        this.modalService.open(this.importContactsResultModal, { size: 'xl' });
        this.eventEmitterService.emit(NotificationTopic.ImportContactsCompleted);
        this.resetChatTags();
        this.offCanvasService.dismiss();
        this.spinnerService.hide();
        this.toastr.success(afterActionMessage);
      },
      error: (error: any) => {
        console.error('Error al asignar etiquetas:', error);
        this.spinnerService.hide();
        this.toastr.error('Error al asignar etiquetas');
      },
    });

  }

  public close() {
    this.offCanvasService.dismiss();
  }

  public toggleShowCreateChatTag() {
    this.creatingChatTag = !this.creatingChatTag;
  }

}
