import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SignUpRequest } from '@app/models/account/sign-up-request.dto';
import { TokenResponse } from '@app/models/auth/token-response.dto';
import { UserService } from '@app/services/account/user.service';
import { AuthService } from '@app/services/auth/auth.service';
import { getTypeByValue, getTypes } from '@app/utils/enum-utils';
import { SubscriptionType, SubscriptionTypeMetadata } from '@type/subscription/subscription.type';
import { EnumInfo } from '@models/common/enum-info.dto';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
  
  public signUpForm: FormGroup;
  public submitted: boolean = false;
  public loading: boolean = false;
  public error: string = '';
  public currentTheme: string = 'light';

  public subscriptionType: SubscriptionType;
  public subscriptionTypeDescriptions: EnumInfo[] = getTypes(SubscriptionType, SubscriptionTypeMetadata);
  public subscriptionTypeDescription: EnumInfo = null;

  public isMobile: boolean = false;

  constructor(
    private readonly userService: UserService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {

  }

  ngOnInit() {

    this.initializeForm();
    this.checkTheme();
    this.checkSubscriptionType();

    this.isMobile = window.innerWidth <= 992;
  }

  private checkSubscriptionType() {
    const subscriptionTypeValue = this.route.snapshot.params['subscriptionType'];
    if (!subscriptionTypeValue) {
      this.router.navigate(['/precios']).then(() => {});
      return;
    }
    this.subscriptionType = getTypeByValue(SubscriptionType, subscriptionTypeValue);
    this.subscriptionTypeDescription = this.subscriptionTypeDescriptions.find(type => type.value === this.subscriptionType);
  }

  private checkTheme() {
    const theme = window.localStorage.getItem('phoenixTheme');
    this.currentTheme = theme ? theme : 'light';
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    const isMobile = window.innerWidth <= 992;
    if (isMobile !== this.isMobile) {
      this.isMobile = isMobile;
    }
  }

  initializeForm() {

    this.signUpForm = new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      confirmPassword: new FormControl('', Validators.required),
      shopName: new FormControl('', Validators.required),
      termsService: new FormControl(false, Validators.requiredTrue)
    }, {
      validators: this.passwordMatchValidator
    });

  }

  passwordMatchValidator: ValidatorFn = (abstractControl: any): ValidationErrors | null => {

    const { controls } = abstractControl;
    const password = controls.password;
    const confirmPassword = controls.confirmPassword;

    if (password.value !== confirmPassword.value) {
      confirmPassword.setErrors({ passwordMismatch: true });
    } 
    
    return null;
  }

  public signUp() {

    this.submitted = true;
    this.loading = true;

    const { 
      name,
      email,
      password,
      shopName
    } = this.signUpForm.controls;

    if (!this.signUpForm.valid) {
      this.signUpForm.markAllAsTouched();
      return;
    }

    const signUpRequest = new SignUpRequest(
      name.value, email.value, password.value, shopName.value, this.subscriptionType
    );

    this.userService.signUp(signUpRequest).subscribe({
      next: (tokenResponse: TokenResponse) => {
        this.loading = false;
        this.processSignUp(tokenResponse);
      },
      error: (error: any) => {
        this.loading = false;

        const code = error.error.code;

        if (code === 'USERNAME_ALREADY_EXISTS') {
          this.error = 'El email ya se encuentra registrado';
        } else {
          this.error = 'Error en el registro';
        }

        console.error('Error en el registro:', error);
      }
    });

  }

  processSignUp(tokenResponse: TokenResponse) {

    this.authService.saveAuthentication(tokenResponse);

    this.userService.getUserInfo().subscribe({
      next: (user: any) => {
        this.authService.saveUser(user);
        this.router.navigate(['/app/chat']).then(() => {});
      },
      error: (error: any) => {
        console.error('Error al obtener la información del usuario:', error);
      }
    });
  }

}
