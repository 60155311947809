import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppPluginRequest } from '../../models/marketplace/app-plugin-request.dto';
import { SearchAppPluginRequest } from '../../models/marketplace/search-app-plugin-request.dto';

@Injectable({
  providedIn: 'root',
})
export class AppPluginService {

  private apiUrl: string = `${environment.apiUrl}/app-plugin`;

  constructor(
    private http: HttpClient,
  ) {

  }


  createAppPlugin(appPlugin: AppPluginRequest): Observable<any> {
    const endpoint = `${this.apiUrl}/create-app-plugin`;
    return this.http.post<any>(endpoint, appPlugin);
  }

  updateAppPlugin(appPlugin: AppPluginRequest): Observable<any> {
    const endpoint = `${this.apiUrl}/update-app-plugin`;
    return this.http.put<any>(endpoint, appPlugin);
  }

  deleteAppPlugin(appPluginId: number): Observable<any> {
    const endpoint = `${this.apiUrl}/delete-app-plugin/${appPluginId}`;
    return this.http.delete<any>(endpoint);
  }

  searchAppPlugins(appPluginSearch: SearchAppPluginRequest): Observable<any> {
    const endpoint = `${this.apiUrl}/search-apps`;
    return this.http.post<any>(endpoint, appPluginSearch);
  }

  getAllAppPlugins(): Observable<any> {
    const endpoint = `${this.apiUrl}/get-apps`;
    return this.http.get<any>(endpoint);
  }

  getAppById(appPluginId: number): Observable<any> {
    const endpoint = `${this.apiUrl}/get-app/${appPluginId}`;
    return this.http.get<any>(endpoint);
  }


}
