
export enum SmartAssistantTopicItemActionType {

  Message = 'message',
  SmartMessage = 'smart-message',
  QuickReply = 'quick-reply',
  ChatTag = 'chat-tag',
  HummanAssitantAttention = 'human-assistant-attention',
  HttpCall = 'http-call',
  // ProductCollection = 'product-collection',
  // Product = 'product',

}

export const SmartAssistantTopicItemActionTypeMetadata = {
  [SmartAssistantTopicItemActionType.Message]: {
    description: 'Mensaje' // responde con un texto relacionado con el card.
  },
  [SmartAssistantTopicItemActionType.SmartMessage]: {
    description: 'Mensaje inteligente' // responde con un texto inteligente relacionado con el card.
  },
  [SmartAssistantTopicItemActionType.QuickReply]: {
    description: 'Respuesta rápida' // responde con una respuesta rápida.
  },
  [SmartAssistantTopicItemActionType.ChatTag]: {
    description: 'Etiqueta de chat' // agrega una o más etiquetas a un chat.
  },
  [SmartAssistantTopicItemActionType.HummanAssitantAttention]: {
    description: 'Atención personalizada' // marca el chat para ser atendido por un asistente humano.
  },
  [SmartAssistantTopicItemActionType.HttpCall]: {
    description: 'Llamada HTTP' // realiza una llamada HTTP.
  },
}