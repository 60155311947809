import { EnumInfo } from "../models/common/enum-info.dto";

export const getTypes = <T, M>(enumType: T, metadata: M) => {

  const types: EnumInfo[] = [];

  for (const [key, value] of Object.entries(enumType)) {
    types.push({
      key,
      value,
      description: metadata[value as any]?.description,
    });
  }

  return types;
}

export const getTypeByValue = <T, M>(enumType: T, value: any) => {
  if (!value) return null;
  return Object.values(enumType).find(type => type === value) || null;
}