import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Uppy, { UppyFile } from '@uppy/core';
import Dashboard, { DashboardOptions } from '@uppy/dashboard';
import { AppCategoryType } from '../../../../../type/marketplace/app-category.type';
import { UserInfo } from '../../../../../models/account/user/user.info.dto';
import { AuthService } from '../../../../../services/auth/auth.service';
import { MessageService } from '../../../../../services/chat/message.service';
import { AppDetailGalleryRequest } from '../../../../../models/marketplace/app-detail-gallery-request.dto';
import { AppPluginRequest } from '../../../../../models/marketplace/app-plugin-request.dto';
import { getGallerMediaTypeByContentType } from '../../../../../type/marketplace/app-gallery-media.type';
import { UppyLocaleValues } from '../../../../../constants/uppy-constants';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import AwsS3 from '@uppy/aws-s3';
import { GetSignedUrlRequest } from '../../../../../models/media/get-signed-url-request.dto';
import { lastValueFrom } from 'rxjs';
import Audio from '@uppy/audio';
import ScreenCapture from '@uppy/screen-capture';

@Component({
  selector: 'app-create-marketplace-app',
  templateUrl: './create-marketplace-app.component.html',
  styleUrls: ['./create-marketplace-app.component.css'],
})
export class CreateMarketplaceAppComponent {

  public appRequestForm: FormGroup;
  public uppy: Uppy = new Uppy();
  public uppyDashboardProps: DashboardOptions;
  public appCategories: string[] = Object.values(AppCategoryType);
  private user: UserInfo;
  public showPriceInput = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private messageService: MessageService,
  ) {
    this.user = this.authService.getUser();
  }

  ngOnInit(): void {
    this.appRequestForm = this.formBuilder.group({
      name: ['', Validators.required],
      icon_url: ['', Validators.required],
      developer: ['', Validators.required],
      description: ['', Validators.required],
      details: ['', Validators.required],
      appPayment: ['', Validators.required],
      categories: ['', Validators.required],
      price: ['', Validators.required],
    });

    this.appRequestForm.get('appPayment').valueChanges.subscribe(value => {
      this.showPriceInput = value === 'paid';
      if (!this.showPriceInput) {
        this.appRequestForm.get('price').reset();
      }
    });
    this.initializeUppy();
  }

  async submitForm() {
    console.log(this.appRequestForm.value);
    const {
      name,
      icon_url,
      developer,
      description,
      details,
      appPayment,
      categories,
      price,
    } = this.appRequestForm.controls;
    const allUploadResponse = await this.uppy.upload();

    const files: AppDetailGalleryRequest[] = [];

    const appRequest: AppPluginRequest = {
      name: name.value,
      icon_url: icon_url.value,
      developer: developer.value,
      description: description.value,
      details: details.value,
      appPayment: appPayment.value,
      categories: categories.value,
      price: price.value,

    };


    for (const uploadedUppyFile of allUploadResponse.successful) {

      const { uploadURL: fileUrl, name, type, size, fileKey } = uploadedUppyFile as any;
      const messageType = getGallerMediaTypeByContentType(type);

      const appGalleryRequest = new AppDetailGalleryRequest();
      appGalleryRequest.fileKey = fileKey;
      appGalleryRequest.fileUrl = fileUrl;
      appGalleryRequest.fileName = name;
      appGalleryRequest.fileContentType = type;
      appGalleryRequest.fileSize = size;
      appGalleryRequest.type = messageType;

      files.push(appGalleryRequest);
    }


    console.log(appRequest);

  }

  onFileSelected(event) {
    const file = event.target.files[0];
    if (file) {
      // Convertir el archivo a una URL de objeto
      const reader = new FileReader();
      reader.onload = () => {
        this.appRequestForm.patchValue({
          icon_url: reader.result as string,
        });
      };
      reader.readAsDataURL(file);
    }
  }

  initializeUppy() {

    this.uppy = new Uppy({
      debug: false,
      autoProceed: false,
      locale: UppyLocaleValues,
    });

    this.uppy.on('files-added', (files: UppyFile[]) => {
      // this.validateUppyFiles(files);
      if (this.uppy.getFiles().length > 0) {
        setTimeout(() =>
          document.querySelector('.uppy-Dashboard-files').classList.add('scrollbar'), 1);
      }
    });

    this.uppy.on('complete', (result: any) => {
      this.uppy.setState({
        files: {},
        currentUploads: {},
        totalProgress: 0,
      });
    });

    this.uppy.on('thumbnail:generated', (file, preview) => {
      console.log('thumbnail:generated preview', preview);
    });

    this.uppyDashboardProps = {
      width: '100%',
      height: 260,
      hideUploadButton: true,
    };

    this.uppy.use(ThumbnailGenerator);

    this.uppy.use(AwsS3, {
      id: 'uppy-aws-s3',
      getUploadParameters: async (uppyFile: UppyFile) => {

        const prefix = this.user.shop.uuid;
        const getSignedUrlRequest = new GetSignedUrlRequest(prefix, uppyFile.name, uppyFile.type);
        const response = await lastValueFrom(this.messageService.getWhatsAppSignedFileUrl(getSignedUrlRequest));

        (uppyFile as any).fileKey = response.key;

        return {
          method: 'PUT',
          url: response.signedUrl,
          headers: {
            'Content-Type': uppyFile.type,
          },
        };
      },
    });

    this.uppy.use(Dashboard, this.uppyDashboardProps);

    this.uppy.use(Audio, { target: Dashboard });
    this.uppy.use(ScreenCapture, {
      target: Dashboard,
      displayMediaConstraints: {
        video: {
          frameRate: 30,
        },
      },
    });

    const theme = localStorage.getItem('phoenixTheme');

    if (theme === 'dark' || theme === 'light') {
      this.uppyDashboardProps.theme = theme;
    }
  }

  translateCategory(category: string): string {
    switch (category) {
      case AppCategoryType.Communication:
        return 'Comunicación';
      case AppCategoryType.Productivity:
        return 'Productividad';
      case AppCategoryType.Social:
        return 'Social';
      case AppCategoryType.Finance:
        return 'Finanzas';
      case AppCategoryType.Education:
        return 'Educación';
      case AppCategoryType.Entertainment:
        return 'Entretenimiento';
      case AppCategoryType.Health:
        return 'Salud';
      case AppCategoryType.Travel:
        return 'Viajes';
      case AppCategoryType.Shopping:
        return 'Compras';
      case AppCategoryType.Other:
        return 'Otra categoría';
      default:
        return '';
    }
  }

}
