
export enum WhatsappWebStatusType {

  DISCONNECTED = 'DISCONNECTED',

  CONFLICT = 'CONFLICT',
  CONNECTED = 'CONNECTED',
  DEPRECATED_VERSION = 'DEPRECATED_VERSION',
  OPENING = 'OPENING',
  PAIRING = 'PAIRING',
  PROXYBLOCK = 'PROXYBLOCK',
  SMB_TOS_BLOCK = 'SMB_TOS_BLOCK',
  TIMEOUT = 'TIMEOUT',
  TOS_BLOCK = 'TOS_BLOCK',
  UNLAUNCHED = 'UNLAUNCHED',
  UNPAIRED = 'UNPAIRED',
  UNPAIRED_IDLE = 'UNPAIRED_IDLE',

}