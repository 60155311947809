import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, } from "@angular/core";
import { NgbModal, NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import AwsS3 from "@uppy/aws-s3";
import Uppy, { UppyFile } from "@uppy/core";
import { DashboardOptions } from "@uppy/dashboard";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom, Subscription, } from "rxjs";
import { UppyLocaleValues } from "@app/constants/uppy-constants";
import { ChatInfo } from "@app/models/chat/chat/chat-info.dto";
import { AddWhatsAppMessageTemplateAttachmentRequest } from "@app/models/chat/whatsapp-message-template/internal/add-whatsapp-message-template-attachment-request.dto";
import { WhatsAppMessageTemplateAttachmentInfo } from "@app/models/chat/whatsapp-message-template/internal/whatsapp-message-template-attachment-info.dto";
import { WhatsAppMessageTemplateButtonType } from "@app/models/chat/whatsapp-message-template/type/whatsapp-message-template-button.type";
import { WhatsAppMessageTemplateComponentHeaderType } from "@app/models/chat/whatsapp-message-template/type/whatsapp-message-template-component-header.type";
import { getParameterTypeByTemplateComponentHeaderType, WhatsAppMessageTemplateComponentParameterType, } from "@app/models/chat/whatsapp-message-template/type/whatsapp-message-template-component-parameter.type";
import { WhatsAppMessageTemplateComponentType } from "@app/models/chat/whatsapp-message-template/type/whatsapp-message-template-component.type";
import { WhatsAppMessageTemplateButton } from "@app/models/chat/whatsapp-message-template/whatsapp-message-template-button.dto";
import { WhatsAppMessageTemplateComponentInfo } from "@app/models/chat/whatsapp-message-template/whatsapp-message-template-component-info.dto";
import { WhatsAppMessageTemplateInfo } from "@app/models/chat/whatsapp-message-template/whatsapp-message-template-info.dto";
import { GetSignedUrlRequest } from "@app/models/media/get-signed-url-request.dto";
import { UserInfo } from "@app/models/account/user/user.info.dto";
import { AuthService } from "@app/services/auth/auth.service";
import { MessageTemplateService } from "@app/services/chat/message-template.service";
import { MessageService } from "@app/services/chat/message.service";
import { EventEmitterService, NotificationTopic, } from "@app/services/data/event-emitter.service";
import { DataKey, LocalDataService, } from "@app/services/data/local-data.service";
import { getMessageTypeByMediaType, MessageType, messageTypes, } from "@app/type/chat/message-content.type";

import { v4 as uuidv4 } from "uuid";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ChannelType } from "@type/communication/channel.type";
import { validateOnlyNumbers } from "@app/utils/phone-utils";
import { Router } from "@angular/router";
import {
  SendWhatsAppMediaTemplateRequest,
  SendMessageRecipientRequest,
  SendWhatsAppMessageRequest,
  SendWhatsAppMessageTemplateComponentParameterRequest,
  SendWhatsAppMessageTemplateComponentRequest,
  SendWhatsAppMessageTemplateLanguageRequest,
  SendWhatsAppMessageTemplateRequest
} from "@models/chat/message/whatsapp/whatsapp-message-requests.dto";
import { SendMessageRequest } from "@models/chat/message/send-message-request.dto";

@Component({
  selector: "app-chat-template-sender",
  templateUrl: "./chat-template-sender.component.html",
  styleUrls: ["./chat-template-sender.component.css"],
})
export class ChatTemplateSender implements OnInit, OnDestroy {

  // enums
  public WhatsAppMessageTemplateComponentHeaderType = WhatsAppMessageTemplateComponentHeaderType;
  private mediaHeaderTypes: WhatsAppMessageTemplateComponentHeaderType[];

  private user: UserInfo;
  private componentSubscriptions: Subscription[] = [];

  @Input()
  public chat: ChatInfo;

  public allWhatsAppMessageTemplates: WhatsAppMessageTemplateInfo[] = [];
  public whatsAppMessageTemplates: WhatsAppMessageTemplateInfo[] = [];
  public selectedWhatsAppMessageTemplate: WhatsAppMessageTemplateInfo;
  public selectedHeaderAttachment: WhatsAppMessageTemplateAttachmentInfo;

  public showMessageTemplates: boolean = true;
  public showSelectedMessageTemplate: boolean = false;
  public showFillParameters: boolean = false;
  public showFillRecipientParameters: boolean = false;

  // Recipient params
  public emptyMessageTemplateRecipient: boolean = true;

  // Broadcast param indicator
  public configuringBroadcast: boolean = false;

  @ViewChild("messageTemplateAttachmentsModal")
  private messageTemplateAttachmentsModal: TemplateRef<any>;

  // Uppy
  public uppy: Uppy;
  public uppyDashboardProps: DashboardOptions;

  public selectedCountryCode: any;

  public recipientForm: FormGroup;
  public sendingMessageTemplate: boolean = false;
  public templateNameTerm: string = '';

  constructor(
    private offCanvasService: NgbOffcanvas,
    private modalService: NgbModal,
    private eventEmitterService: EventEmitterService,
    private toastr: ToastrService,
    private messageService: MessageService,
    private messageTemplateService: MessageTemplateService,
    private localDataService: LocalDataService,
    private authService: AuthService,
    private router: Router,
  ) {
    this.user = this.authService.getUser();
    this.mediaHeaderTypes = this.fillMediaHeaderTypes();
  }

  ngOnInit() {
    this.loadMessageTemplates();
    this.initializeUppy();
    this.initializeCheckIfEmptyMessageTemplateRecipient();
    this.initializeCheckIfConfiguringBroadcast();
    this.initializeRecipientForm();
  }

  ngOnDestroy() {
    this.componentSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  fillMediaHeaderTypes() {
    const { Image, Video, Document } =
      WhatsAppMessageTemplateComponentHeaderType;
    return [Image, Video, Document];
  }

  initializeUppy() {
    UppyLocaleValues.strings.uploadXFiles = {
      0: "Subir %{smart_count} archivo",
      1: "Subir %{smart_count} archivos",
    };

    this.uppy = new Uppy({
      debug: false,
      autoProceed: false,
      locale: UppyLocaleValues,
      restrictions: {
        maxNumberOfFiles: 1,
      },
    });

    this.uppy.on("files-added", (files: UppyFile[]) => {
      this.validateUppyFiles(files);
    });

    this.uppy.on("upload-success", (file: UppyFile, uploadResponse: any) => {
      this.saveMessageTemplateAttachment(file, uploadResponse);
    });

    this.uppy.on("complete", () => {
      setTimeout(() => {
        this.uppy.setState({
          files: {},
          currentUploads: {},
          totalProgress: 0,
        });
      }, 1000);
    });

    this.uppyDashboardProps = {
      width: "100%",
      height: "100",
    };

    this.uppy.use(AwsS3, {
      id: "uppy-aws-s3",
      getUploadParameters: async (uppyFile: UppyFile) => {
        const prefix = this.user.shop.uuid;
        const getSignedUrlRequest = new GetSignedUrlRequest(
          prefix,
          uppyFile.name,
          uppyFile.type,
        );
        const response = await lastValueFrom(
          this.messageService.getWhatsAppSignedFileUrl(getSignedUrlRequest),
        );

        (uppyFile as any).fileKey = response.key;

        return {
          method: "PUT",
          url: response.signedUrl,
          headers: {
            "Content-Type": uppyFile.type,
          },
        };
      },
    });

    const theme = localStorage.getItem("phoenixTheme");

    if (theme === "dark" || theme === "light") {
      this.uppyDashboardProps.theme = theme;
    }
  }

  initializeCheckIfEmptyMessageTemplateRecipient() {
    const emptyMessageTemplateRecipient = this.localDataService.get(
      DataKey.EmptyMessageTemplateRecipient,
    );
    this.emptyMessageTemplateRecipient = emptyMessageTemplateRecipient === "true";
  }

  initializeCheckIfConfiguringBroadcast() {
    const configuringBroadcast = this.localDataService.get(DataKey.ConfiguringBroadcast,);
    this.configuringBroadcast = configuringBroadcast === "true";
  }

  validateUppyFiles(uppyFiles: UppyFile[]) {

    const invalidFiles: UppyFile[] = [];
    for (const uppyFile of uppyFiles) {
      const { type, size } = uppyFile;
      const { sizeLimit } = messageTypes[getMessageTypeByMediaType(type)];
      if (size > sizeLimit) {
        invalidFiles.push(uppyFile);
        this.uppy.removeFile(uppyFile.id);
      }
    }

    for (const invalidFile of invalidFiles) {
      this.toastr.warning(
        `${(invalidFile.size / 1048576).toFixed(2)} MB`,
        `${invalidFile.name}`,
      );
    }

    if (invalidFiles.length > 0) {
      let message =
        "Los siguientes archivos no pudieron ser agregados debido a que superan el límite de tamaño";
      this.toastr.info(message);
    }
  }

  public setSelectedCountryCode(selectedCountryCode: any) {
    this.selectedCountryCode = selectedCountryCode;
  }

  public validatePhoneNumber(event: any) {
    validateOnlyNumbers(event);
  }

  saveMessageTemplateAttachment(uppyFile: UppyFile, uploadResponse: any) {

    const { name, type, size } = uppyFile;

    const addAttachmentRequest = new AddWhatsAppMessageTemplateAttachmentRequest();

    addAttachmentRequest.shopId = this.user.shop.id;
    addAttachmentRequest.messageTemplateId = this.selectedWhatsAppMessageTemplate.id;
    addAttachmentRequest.messageTemplateName = this.selectedWhatsAppMessageTemplate.name;
    addAttachmentRequest.fileKey = (uppyFile as any).fileKey;
    addAttachmentRequest.fileName = name;
    addAttachmentRequest.fileContentType = type;
    addAttachmentRequest.fileSize = size;
    addAttachmentRequest.fileUrl = uploadResponse.uploadURL;

    this.messageTemplateService
      .addWhatsAppMessageTemplateAttachment(addAttachmentRequest)
      .subscribe({
        next: (
          whatsAppMessageTemplateAttachmentInfo: WhatsAppMessageTemplateAttachmentInfo,
        ) => {
          this.selectedWhatsAppMessageTemplate.attachments.push(
            whatsAppMessageTemplateAttachmentInfo,
          );
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  searchMessageTemplates() {
    if (!this.templateNameTerm) {
      this.whatsAppMessageTemplates = [...this.allWhatsAppMessageTemplates];
      return;
    }
    this.whatsAppMessageTemplates = this.allWhatsAppMessageTemplates.filter((wmt) => {
      const { name } = wmt;
      return name.toLowerCase().includes(this.templateNameTerm.toLowerCase());
    });
  }

  loadMessageTemplates() {
    let whatsAppMessageTemplates: any = this.localDataService.get(DataKey.MessageTemplates);
    whatsAppMessageTemplates = whatsAppMessageTemplates ? JSON.parse(whatsAppMessageTemplates) : [];
    this.processTemplates(whatsAppMessageTemplates);
    this.allWhatsAppMessageTemplates = [...whatsAppMessageTemplates];
    this.whatsAppMessageTemplates = whatsAppMessageTemplates;
  }

  goToMessageTemplates() {
    this.router.navigate(["/app/message-templates"]);
    this.offCanvasService.dismiss();
  }

  processTemplates(whatsAppMessageTemplates: WhatsAppMessageTemplateInfo[]) {
    for (const whatsAppMessageTemplate of whatsAppMessageTemplates) {
      const { components } = whatsAppMessageTemplate;

      const headerComponent = components.find(
        (c) => c.type === WhatsAppMessageTemplateComponentType.Header,
      );
      const bodyComponent = components.find(
        (c) => c.type === WhatsAppMessageTemplateComponentType.Body,
      );
      const footerComponent = components.find(
        (c) => c.type === WhatsAppMessageTemplateComponentType.Footer,
      );
      const buttonsComponent = components.find(
        (c) => c.type === WhatsAppMessageTemplateComponentType.Buttons,
      );

      whatsAppMessageTemplate.headerComponent = headerComponent;
      whatsAppMessageTemplate.bodyComponent = bodyComponent;
      whatsAppMessageTemplate.footerComponent = footerComponent;
      whatsAppMessageTemplate.buttonsComponent = buttonsComponent;

      let parametersCount: number = 0;

      bodyComponent.parameters = this.getParameters(bodyComponent.text);
      bodyComponent.parametersText = this.getParametersText(bodyComponent.text);
      bodyComponent.originalParametersText = bodyComponent.parametersText;

      parametersCount += bodyComponent.parameters.length;

      if (headerComponent) {
        headerComponent.parameters = this.getParameters(headerComponent.text);
        headerComponent.parametersText = this.getParametersText(
          headerComponent.text,
        );
        headerComponent.originalParametersText = headerComponent.parametersText;

        if (this.mediaHeaderTypes.includes(headerComponent.format)) {
          parametersCount++;
        }

        parametersCount += headerComponent.parameters.length;
      }

      if (buttonsComponent) {
        for (const button of buttonsComponent.buttons) {
          if (button.url) {
            const parameters = this.getParameters(button.url);
            if (parameters) {
              button.parameter = parameters[0];
              parametersCount++;
            }
          }
        }
      }

      whatsAppMessageTemplate.parametersCount = parametersCount;
    }
  }

  getParametersText(content: string) {
    const regex = /{{(\d+)}}/g;

    let matches = [];
    let match: any[];

    while ((match = regex.exec(content)) !== null) {
      matches.push(match[1]);
    }

    for (const param of matches) {
      content = content.replace(
        `{{${param}}}`,
        `<span id="param${param}">{{${param}}}</span>`,
      );
    }

    return content;
  }

  getParameters(content: string) {
    const regex = /{{(\d+)}}/g;

    let matches = [];
    let match: any[];

    while ((match = regex.exec(content)) !== null) {
      matches.push(match[1]);
    }

    return matches.map((m) => {
      return {
        id: m,
        name: `{{${m}}}`,
        value: "",
      };
    });
  }

  onWriteParameter(
    event: any,
    param: any,
    component: WhatsAppMessageTemplateComponentInfo,
  ) {
    param.value = event.target.value;

    component.parametersText = component.originalParametersText;
    for (let param of component.parameters) {
      const { name, value } = param;
      if (param.value) {
        component.parametersText = component.parametersText.replace(
          name,
          value,
        );
      }
    }

    this.selectedWhatsAppMessageTemplate.validParameters = this.checkTemplateParameters();
  }

  onWriteButtonParameter(event: any, button: WhatsAppMessageTemplateButton) {
    const inputValue = event.target.value;
    button.url.replace(button.parameter.name, inputValue);
    button.parameter.value = inputValue;
    this.selectedWhatsAppMessageTemplate.validParameters =
      this.checkTemplateParameters();
  }

  checkTemplateParameters() {
    const {
      headerComponent,
      bodyComponent,
      footerComponent,
      buttonsComponent,
    } = this.selectedWhatsAppMessageTemplate;

    if (headerComponent) {
      if (this.mediaHeaderTypes.includes(headerComponent.format)) {
        if (!this.selectedHeaderAttachment) {
          return false;
        }
      } else {
        if (!this.validateComponentParameters(headerComponent.parameters)) {
          return false;
        }
      }
    }

    if (!this.validateComponentParameters(bodyComponent.parameters))
      return false;

    if (
      footerComponent &&
      !this.validateComponentParameters(footerComponent.parameters)
    )
      return false;

    if (buttonsComponent) {
      for (const button of buttonsComponent.buttons) {
        if (button.url && !this.validateComponentParameters([button.parameter]))
          return false;
      }
    }

    return true;
  }

  validateComponentParameters(parameters: any[]) {
    if (!parameters || parameters.length === 0) return true;
    for (const parameter of parameters) {
      if (parameter === undefined) return true;
      if (!parameter.value) return false;
    }
    return true;
  }

  scrollToParam(parameterId: string) {
    const element = document.getElementById(`param${parameterId}`);
    const preElement: any = document.querySelector(
      ".card-text-fill-parameters",
    );
    preElement.scrollTop =
      element.offsetTop -
      preElement.offsetHeight / 2 +
      element.offsetHeight / 2;
  }

  openTemplate(
    ngbCollapse: any,
    whatsAppMessageTemplate: WhatsAppMessageTemplateInfo,
  ) {
    whatsAppMessageTemplate.opened = !whatsAppMessageTemplate.opened;
    console.log(
      "whatsAppMessageTemplate.opened",
      whatsAppMessageTemplate.opened,
    );
    // ngbCollapse.toggle();
  }

  selectTemplate(whatsAppMessageTemplate: WhatsAppMessageTemplateInfo) {
    
    this.selectedWhatsAppMessageTemplate = whatsAppMessageTemplate;
    this.selectedWhatsAppMessageTemplate.opened = true;
    this.selectedWhatsAppMessageTemplate.validParameters = false;

    this.showFillRecipientParameters = false;
    this.showMessageTemplates = false;
    this.showFillParameters = false;
    this.showSelectedMessageTemplate = true;
  }

  showMessageTemplateAttachments() {
    const modalRef = this.modalService.open(
      this.messageTemplateAttachmentsModal,
      {
        modalDialogClass: "message-template-attachments-modal",
        backdrop: "static",
        size: "lg",
        centered: true,
      },
    );

    modalRef.hidden.subscribe(() => {});
  }

  selectAttachment(attachment: WhatsAppMessageTemplateAttachmentInfo) {
    this.selectedHeaderAttachment = attachment;
    this.selectedWhatsAppMessageTemplate.validParameters = this.checkTemplateParameters();
  }

  cancelAttachmentSelection() {
    this.selectedHeaderAttachment = undefined;
    this.selectedWhatsAppMessageTemplate.validParameters = this.checkTemplateParameters();
    this.modalService.dismissAll();
  }

  goToFillParameters(whatsAppMessageTemplate?: WhatsAppMessageTemplateInfo) {
    if (whatsAppMessageTemplate) {
      this.selectTemplate(whatsAppMessageTemplate);
    }
    this.selectedWhatsAppMessageTemplate.validParameters = this.checkTemplateParameters();
    this.showMessageTemplates = false;
    this.showSelectedMessageTemplate = false;
    this.showFillParameters = true;
  }

  goToShowMessageTemplates() {
    this.selectedWhatsAppMessageTemplate.opened = false;
    this.selectedWhatsAppMessageTemplate = undefined;

    this.showSelectedMessageTemplate = false;
    this.showFillParameters = false;
    this.showMessageTemplates = true;
  }

  goToFillRecipient(whatsAppMessageTemplate?: WhatsAppMessageTemplateInfo) {

    if (!this.selectedWhatsAppMessageTemplate && whatsAppMessageTemplate === undefined) {
      this.selectTemplate(whatsAppMessageTemplate);
    }

    if (this.configuringBroadcast) {
      const sendMessageRequest: SendMessageRequest = this.getSendMessageRequest();
      this.eventEmitterService.emit(NotificationTopic.BroadcastMessageTemplateReady, sendMessageRequest);
      return;
    }

    this.showSelectedMessageTemplate = false;
    this.showFillParameters = false;
    this.showMessageTemplates = false;
    this.showFillRecipientParameters = true;
  }

  sendMessageTemplate(messageTemplate?: WhatsAppMessageTemplateInfo) {

    if (messageTemplate) {
      this.selectedWhatsAppMessageTemplate = messageTemplate;
    }

    this.sendingMessageTemplate = true;
    const sendMessageRequest = this.getSendMessageRequest();

    this.messageService.sendMessage(sendMessageRequest).subscribe({
      next: () => {
        this.sendingMessageTemplate = false;
        this.toastr.success("Mensaje enviado correctamente");
        this.close();
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  private getSendMessageRequest(): SendMessageRequest {

    const { name, language } = this.selectedWhatsAppMessageTemplate;

    const sendWhatsAppMessageTemplateLanguageRequest = new SendWhatsAppMessageTemplateLanguageRequest();
    sendWhatsAppMessageTemplateLanguageRequest.code = language;

    const sendWhatsAppMessageTemplateRequest = new SendWhatsAppMessageTemplateRequest();
    sendWhatsAppMessageTemplateRequest.name = name;
    sendWhatsAppMessageTemplateRequest.language = sendWhatsAppMessageTemplateLanguageRequest;
    sendWhatsAppMessageTemplateRequest.components = this.getMessageTemplateComponents();

    const sendWhatsAppMessageRequest = new SendWhatsAppMessageRequest();
    sendWhatsAppMessageRequest.template = sendWhatsAppMessageTemplateRequest;

    const sendMessageRecipientRequest = new SendMessageRecipientRequest();

    if (!this.configuringBroadcast) {
      if (!this.emptyMessageTemplateRecipient) {
        const { chatName, chatPhoneNumber } = this.recipientForm.controls;
        sendMessageRecipientRequest.chatPhoneNumber = `${this.selectedCountryCode.code}${chatPhoneNumber.value.trim()}`;
        if (!chatName.value) {
          sendMessageRecipientRequest.chatName = sendMessageRecipientRequest.chatPhoneNumber;
        } else {
          sendMessageRecipientRequest.chatName = chatName.value;
        }
      } else if (this.chat) {
        sendMessageRecipientRequest.chatId = this.chat.id;
        sendMessageRecipientRequest.chatName = this.chat.chatName;
        sendMessageRecipientRequest.chatPhoneNumber = this.chat.chatPhoneNumber;
      }
    }

    const sendMessageRequest: SendMessageRequest = new SendMessageRequest();

    sendMessageRequest.shopId = this.user.shop.id;
    sendMessageRequest.identifier = uuidv4();
    sendMessageRequest.recipient = sendMessageRecipientRequest;
    sendMessageRequest.type = MessageType.Template;
    sendMessageRequest.sendWhatsAppMessageRequest = sendWhatsAppMessageRequest;
    sendMessageRequest.channelType = this.chat ? this.chat.channel: ChannelType.WhatsApp;
    sendMessageRequest.userId = this.user.id;

    return sendMessageRequest;
  }

  getMessageTemplateComponents() {
    const {
      headerComponent,
      bodyComponent,
      footerComponent,
      buttonsComponent,
    } = this.selectedWhatsAppMessageTemplate;

    const components: SendWhatsAppMessageTemplateComponentRequest[] = [];

    if (headerComponent) {
      const headerComponentRequest: SendWhatsAppMessageTemplateComponentRequest =
        {
          type: WhatsAppMessageTemplateComponentType.Header,
          parameters: [],
        };

      if (this.mediaHeaderTypes.includes(headerComponent.format)) {
        const parameterRequest = new SendWhatsAppMessageTemplateComponentParameterRequest();
        const parameterType = getParameterTypeByTemplateComponentHeaderType(headerComponent.format,);

        const sendWhatsAppMediaTemplateRequest = new SendWhatsAppMediaTemplateRequest();
        sendWhatsAppMediaTemplateRequest.link = this.selectedHeaderAttachment.fileUrl;
        parameterRequest.type = parameterType;
        parameterRequest.attachment = this.selectedHeaderAttachment;
        parameterRequest[parameterType] = sendWhatsAppMediaTemplateRequest;

        headerComponentRequest.parameters.push(parameterRequest);
      } else if (headerComponent.format === WhatsAppMessageTemplateComponentHeaderType.Text) {
        if (headerComponent.parameters) {
          const parametersRequest = headerComponent.parameters.map((p) => {
            const parameterRequest = new SendWhatsAppMessageTemplateComponentParameterRequest();
            parameterRequest.type = WhatsAppMessageTemplateComponentParameterType.Text;
            parameterRequest[parameterRequest.type] = p.value;
            return parameterRequest;
          });

          headerComponentRequest.parameters = parametersRequest;
        }
      }

      components.push(headerComponentRequest);
    }

    const parametersRequest = bodyComponent.parameters.map((p) => {
      const parameterRequest = new SendWhatsAppMessageTemplateComponentParameterRequest();
      parameterRequest.type = WhatsAppMessageTemplateComponentParameterType.Text;
      parameterRequest[parameterRequest.type] = p.value;
      return parameterRequest;
    });

    const bodyComponentRequest: SendWhatsAppMessageTemplateComponentRequest = {
      type: WhatsAppMessageTemplateComponentType.Body,
      parameters: parametersRequest,
    };

    components.push(bodyComponentRequest);

    if (buttonsComponent) {
      for (let index = 0; index < buttonsComponent.buttons.length; index++) {
        const button = buttonsComponent.buttons[index];

        if (button.parameter) {
          const parameterRequest =
            new SendWhatsAppMessageTemplateComponentParameterRequest();
          parameterRequest.text = button.parameter.value;

          switch (button.type) {
            case WhatsAppMessageTemplateButtonType.Url:
              parameterRequest.type = WhatsAppMessageTemplateComponentParameterType.Text;
              break;
            case WhatsAppMessageTemplateButtonType.QuickReply:
              parameterRequest.type = WhatsAppMessageTemplateComponentParameterType.Payload;
              break;
            case WhatsAppMessageTemplateButtonType.PhoneNumber:
              break;
          }

          const buttonComponentRequest: SendWhatsAppMessageTemplateComponentRequest =
            {
              type: WhatsAppMessageTemplateComponentType.Button,
              parameters: [parameterRequest],
              // buttons
              sub_type: button.type.toLowerCase(),
              index: index,
            };

          components.push(buttonComponentRequest);
        }
      }
    }

    return components;
  }

  close() {
    this.offCanvasService.dismiss();
  }

  initializeRecipientForm() {
    this.recipientForm = new FormGroup({
      chatName: new FormControl(""),
      chatPhoneNumber: new FormControl("", [Validators.required]),
    });
  }

}
