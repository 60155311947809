import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { UppyAngularDashboardModule } from '@uppy/angular';

import { AppComponent } from './app.component';
import { NgOptimizedImage, registerLocaleData } from "@angular/common";
import es from '@angular/common/locales/es';
import { ScrollToBottomDirective } from './components/directives/scroll-to-bottom.directive';

import { chatComponents } from './module-imports/components/app-chat-imports';
import { layoutComponents } from './module-imports/components/layout-imports';
import { accountComponents } from './module-imports/components/account-imports';
import { configComponents } from './module-imports/components/config-imports';
import { ngbModules } from './module-imports/modules/ngb-imports';
import { commonLibraryModules } from './module-imports/modules/common-library-imports';
import { SafeHtmlPipe } from './components/pipes/safe-html.pipe';

import flatpickr from 'flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es.js';
import { utilitiesComponents } from "./module-imports/components/utilities-imports";
import { commonImports } from "./module-imports/components/common-imports";
import { landingPageComponents } from "./module-imports/components/landing-page-imports";
import { QRCodeModule } from "angularx-qrcode";
import { LandingPageHeaderComponent } from './components/landing-page/header/landing-page-header.component';
import { MarketplaceModule } from './components/marketplace/marketplace.module';
import { managementImports } from './module-imports/components/management-imports';
import { CreateMarketplaceAppComponent } from './components/management/marketplace-management/components/create-marketplace-app/create-marketplace-app.component';
import { AuthInterceptor } from '@services/interceptor/auth.interceptor';
import { smartAssistantsComponents } from './module-imports/components/smart-assistants-imports';
import { BlockUiDirective } from '@app/components/directives/block-ui.directive';
import { AdvisorsInputComponent } from '@app/components/utilities/advisors-input/advisors-input.component';
import { QuickReplyGroupsComponent } from './components/admin/quick-replies/quick-reply-groups/quick-reply-groups.component';

registerLocaleData(es);

const uiComponents = [

];

@NgModule({
  declarations: [
    ...layoutComponents,
    ...landingPageComponents,
    ...accountComponents,
    ...chatComponents,
    ...utilitiesComponents,
    ...smartAssistantsComponents,
    ...configComponents,
    ...commonImports,
    ...managementImports,
    ...uiComponents,
    ScrollToBottomDirective,
    BlockUiDirective,
    SafeHtmlPipe,
    AdvisorsInputComponent,
    LandingPageHeaderComponent,
    CreateMarketplaceAppComponent,
    QuickReplyGroupsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    UppyAngularDashboardModule,
    AppRoutingModule,
    FormsModule,
    MarketplaceModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgOptimizedImage,
    QRCodeModule,
    ...ngbModules,
    ...commonLibraryModules,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID, useValue: 'es'
    }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
  constructor() {
    flatpickr.localize(Spanish);
  }
}
