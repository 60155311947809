
import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageInfo } from '@app/models/chat/message/message-info.dto';
import { EventEmitterService } from '@app/services/data/event-emitter.service';
import { ChatInfo } from "../../../../../../models/chat/chat/chat-info.dto";

@Component({
  selector: 'app-chat-context-text-message',
  templateUrl: './context-text-message.component.html',
  styleUrls: ['./context-text-message.component.css']
})
export class ContextTextMessageComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() 
  public message: MessageInfo;

  @Input()
  public chat: ChatInfo;

  private componentSubscriptions: Subscription[] = [];

  constructor(
    private eventEmitterService: EventEmitterService,
  ) {

  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    
  }

  ngOnDestroy() {
    this.componentSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
  
}
