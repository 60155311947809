import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { Page } from '@app/models/common/page';

import { QuickReplyRequest } from "@models/chat/quick-reply/quick-reply-request.dto";
import { QuickReplySearchRequest } from "@models/chat/quick-reply/quick-reply-search-request.dto";
import { QuickReplyInfo } from "@models/chat/quick-reply/quick-reply-info.dto";
import { CheckQuickReplyNameExistenceRequest } from "@models/chat/quick-reply/check-quick-reply-name-existence-request.dto";
import { QuickReplyGroupInfo } from "@models/chat/quick-reply/group/quick-reply-group-info.dto";
import { QuickReplyGroupRequest } from "@models/chat/quick-reply/group/create-quick-reply-group-request.dto";

@Injectable({
  providedIn: 'root'
})
export class QuickReplyService {

  private apiUrl: string = `${environment.apiUrl}/quick-replies`;
  private apiUrlGroup: string = `${environment.apiUrl}/quick-replies-group`

  constructor(
    private http: HttpClient,
  ) {}

  checkQuickReplyNameExistence(checkQuickReplyNameExistence: CheckQuickReplyNameExistenceRequest): Observable<any> {
    const endpoint = `${this.apiUrl}/check-quick-reply-name-existence`;
    return this.http.post<any>(endpoint, checkQuickReplyNameExistence);
  }

  searchQuickReplies(quickReplySearch: QuickReplySearchRequest): Observable<Page<QuickReplyInfo>> {
    const endpoint = `${this.apiUrl}/search-quick-replies`;
    return this.http.post<Page<QuickReplyInfo>>(endpoint, quickReplySearch);
  }

  getQuickReply(id: number): Observable<QuickReplyInfo> {
    const endpoint = `${this.apiUrl}/get-quick-reply/${id}`;
    return this.http.get<QuickReplyInfo>(endpoint);
  }

  saveQuickReply(quickReply: QuickReplyRequest): Observable<QuickReplyInfo> {
    const endpoint = `${this.apiUrl}/save-quick-reply`;
    return this.http.post<QuickReplyInfo>(endpoint, quickReply);
  }

  deleteQuickReply(id: number) {
    const endpoint = `${this.apiUrl}/delete-quick-reply/${id}`;
    return this.http.delete<any>(endpoint);
  }

  deleteQuickReplyItem(id: number) {
    const endpoint = `${this.apiUrl}/delete-quick-reply-item/${id}`;
    return this.http.delete<any>(endpoint);
  }

  searchQuickReplyByGroupId(id: number){
    const endpoint = `${this.apiUrlGroup}/search-quick-replies-by-group-id/${id}`;
    return this.http.post<QuickReplyInfo[]>(endpoint, {});
  }

  getQuickReplyGroups(): Observable<QuickReplyGroupInfo[]> {
    const endpoint = `${this.apiUrlGroup}/quick-reply-groups`;
    return this.http.get<any[]>(endpoint);
  }

  addQuickReplyGroup(quickReplyId: number, groupId: number): Observable<any> {
    const endpoint = `${this.apiUrlGroup}/assign-quick-reply-group/${quickReplyId}/${groupId}`;
    return this.http.post<any>(endpoint, {});
  }

  deleteQuickReplyGroup(id: number): Observable<any> {
    const endpoint = `${this.apiUrlGroup}/delete-quick-reply-group/${id}`;
    return this.http.delete<any>(endpoint);
  }

  saveQuickReplyGroup(quickReplyGroupRequest: QuickReplyGroupRequest): Observable<QuickReplyGroupInfo> {
    const endpoint = `${this.apiUrlGroup}`;
    return this.http.post<QuickReplyGroupInfo>(endpoint, quickReplyGroupRequest);
  }

  removeQuickReplyFromGroup(quickReplyId: number, groupId: number): Observable<any> {
    const endpoint = `${this.apiUrlGroup}/remove-quick-reply-group/${quickReplyId}/${groupId}`;
    return this.http.delete<any>(endpoint, {});
  }

}