import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SmartAssistantInfo } from '@models/utilities/smart-assistant/smart-assistant-info.dto';
import { SaveSmartAssistantRequest } from '@models/utilities/smart-assistant/save-smart-assistant-request.dto';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { SmartAssistantService } from '@services/utilities/smart-assistant/smart-assistant.service';
import { AuthService } from '@services/auth/auth.service';
import { UserInfo } from '@models/account/user/user.info.dto';
import { EventEmitterService, NotificationTopic } from '@services/data/event-emitter.service';
import { SpinnerService } from '@services/data/spinner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: "app-smart-assistant-info",
  templateUrl: "./smart-assistant-info.component.html",
  styleUrls: ["./smart-assistant-info.component.css"],
})
export class SmartAssistantInfoComponent implements OnInit {

  public user: UserInfo;

  public smartAssistant: SmartAssistantInfo;
  public smartAssistantForm: FormGroup;

  // Modals
  @ViewChild('showSmartAssistantInstructionsModal')
  private showSmartAssistantInstructionsModal: TemplateRef<any>;

  constructor(
    private readonly eventEmitterService: EventEmitterService,
    private readonly toastr: ToastrService,
    private readonly spinnerService: SpinnerService,
    private readonly smartAssistantService: SmartAssistantService,
    private readonly authService: AuthService,
    private readonly modalService: NgbModal,
  ) {
    this.user = this.authService.getUser();
  }

  ngOnInit() {
    this.initializeSmartAssistantSelectedEvent();
    this.initializeSmartAssistantForm();
  }

  private initializeSmartAssistantSelectedEvent() {

    this.eventEmitterService.getEventEmitter(NotificationTopic.OpenSaveSmartAssistant).subscribe({
      next: (smartAssistant: SmartAssistantInfo) => {
        this.smartAssistant = smartAssistant;
        this.setSmartAssistantInfo(smartAssistant);
      },
    });

  }

  private initializeSmartAssistantForm() {
    this.smartAssistantForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      instructions: new FormControl('', [Validators.required]),
      waitTimeInterval: new FormControl(10, [Validators.required]),
    });
  }

  private setSmartAssistantInfo(smartAssistant: SmartAssistantInfo) {

    if (!smartAssistant) return;

    this.smartAssistant = smartAssistant;

    this.smartAssistantForm.get('name').setValue(smartAssistant.name);
    this.smartAssistantForm.get('instructions').setValue(smartAssistant.instructions);
    this.smartAssistantForm.get('waitTimeInterval').setValue(smartAssistant.waitTimeInterval !== undefined ? smartAssistant.waitTimeInterval : 10);
  }

  public showSmartAssistantInstructions() {
    const modalRef = this.modalService.open(this.showSmartAssistantInstructionsModal, {
      size: 'xl',
      centered: true,
      scrollable: true,
    });
    modalRef.dismissed.subscribe(() => {
      this.smartAssistantForm.get("instructions").setValue(this.smartAssistantForm.get("instructions").value);
    });
  }

  public saveSmartAssistant() {

    const { name, instructions, waitTimeInterval } = this.smartAssistantForm.value;

    let smartAssistantId: number = this.smartAssistant ? this.smartAssistant.id : null;

    const saveSmartAssistantRequest = new SaveSmartAssistantRequest(
      smartAssistantId, name, instructions, waitTimeInterval, this.user.shop.id,
    );

    this.spinnerService.show('Guardando asistente...')

    this.smartAssistantService.saveSmartAssistant(saveSmartAssistantRequest).subscribe({
      next: (smartAssistant: SmartAssistantInfo) => {
        this.eventEmitterService.emit(NotificationTopic.OpenSaveSmartAssistant, smartAssistant);
        this.toastr.success('Asistente creado exitosamente');
        this.spinnerService.hide();
      },
      error: (httpErrorResponse: HttpErrorResponse) => {
        const { error } = httpErrorResponse;
        this.toastr.error('Error al crear el asistente');
      },
      complete: () => {
      },
    });

  }

  public deleteSmartAssistant() {

    const smartAssistant = this.smartAssistant;

    if (!confirm('¿Está seguro que desea eliminar este asistente?')) return;

    this.spinnerService.show('Eliminando asistente...');

    this.smartAssistantService.deleteSmartAssistant(smartAssistant.id).subscribe({
      next: () => {
        this.eventEmitterService.emit(NotificationTopic.OpenSaveSmartAssistant, undefined);
        this.toastr.success('Asistente eliminado exitosamente');
        this.spinnerService.hide();
        this.showSmartAssistantList();
      },
      error: (httpErrorResponse: HttpErrorResponse) => {
        const { error } = httpErrorResponse;

      },
      complete: () => {
      },
    });
  }

  public showSmartAssistantList() {
    this.initializeSmartAssistantForm();
    this.eventEmitterService.emit(NotificationTopic.OpenSaveSmartAssistant, undefined);
  }

}
