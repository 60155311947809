import { Component, ElementRef, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { QuickReplyService } from "@services/chat/quick-reply.service";
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Page } from "@models/common/page";
import { QuickReplyInfo } from "@models/chat/quick-reply/quick-reply-info.dto";
import { QuickReplySearchRequest } from "@models/chat/quick-reply/quick-reply-search-request.dto";
import { UserInfo } from "@models/account/user/user.info.dto";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from "@services/auth/auth.service";
import { QuickReplyGroupInfo } from "@models/chat/quick-reply/group/quick-reply-group-info.dto";
import { QuickReplyGroupRequest } from "@models/chat/quick-reply/group/create-quick-reply-group-request.dto";

@Component({
  selector: 'app-quick-replies-groups',
  templateUrl: './quick-reply-groups.component.html',
  styleUrls: ['./quick-reply-groups.component.css'],
})
export class QuickReplyGroupsComponent implements OnInit {  

  @Output() quickReplyRemoved = new EventEmitter<number>();
  @ViewChild('deleteGroupModal') deleteGroupModal: TemplateRef<any>;
  @ViewChild('editGroupModal') editGroupModal: TemplateRef<any>;
  @ViewChild('removeQuickReplyModal') removeQuickReplyModal: TemplateRef<any>;

  private searchRequest: QuickReplySearchRequest;
  private user: UserInfo;
  public editingGroupId: number | null = null;
  public editedGroupName: string = '';

  public selectedGroup: QuickReplyGroupInfo | null = null;
  public groupQuickReplies: QuickReplyInfo[] = [];
  public groups: QuickReplyGroupInfo[] = [];
  public quickReplies: QuickReplyInfo[] = [];
  public availableQuickReplies: QuickReplyInfo[] = [];
  public showNewGroupForm = false;
  public newGroupName = '';
  public duplicatedName = false;
  public quickReplyGroupForm: FormGroup;

  public selectedQuickReply: QuickReplyInfo;

  constructor(
    private quickReplyService: QuickReplyService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private authService: AuthService,
  ) {
    this.user = this.authService.getUser();
  }

  ngOnInit() {
    this.loadQuickReplies();
    this.loadCategories();
    this.initializeQuickReplySearchRequest();
    
    this.quickReplyGroupForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(150)]]
    });
  }

  private initializeQuickReplySearchRequest() {
    this.searchRequest = new QuickReplySearchRequest(this.user.shop.id, -1);
  }

  private loadQuickReplies() {

    this.searchRequest = new QuickReplySearchRequest(this.user.shop.id, -1);
    this.searchRequest.page = 0;
  
    this.quickReplyService.searchQuickReplies(this.searchRequest).subscribe({
      next: (page: Page<QuickReplyInfo>) => {
        this.quickReplies = page.content;
        for (const quickReply of this.quickReplies) {
          quickReply.textItem = quickReply.items.find(item => item.type === 'text') || null;
        }
      },
      error: (error) => {
        console.error('Error al cargar las respuestas rápidas:', error);
      }
    });
  }
  
  private loadCategories() {
    this.quickReplyService.getQuickReplyGroups().subscribe({
      next: (groups: QuickReplyGroupInfo[]) => {
        this.groups = groups;
      },
      error: (error) => {
        console.error('Error al cargar las categorías:', error);
        this.toastr.error('Error al cargar las categorías');
      }
    });
  }
  

  public deleteGroup(group: QuickReplyGroupInfo): void {

    this.selectedGroup = group;

    const modalRef = this.modalService.open(this.deleteGroupModal, {
      centered: true
    });

    modalRef.result.then((result) => {
      if (result === 'confirm') {
        this.quickReplyService.deleteQuickReplyGroup(group.id).subscribe({
          next: () => {
            this.groups = this.groups.filter(cat => cat.id !== group.id);
            this.toastr.success('Grupo eliminado con éxito');
          },
          error: (error) => {
            console.error('Error al eliminar la categoría:', error);
            this.toastr.error('Error al eliminar la categoría');
          }
        });
      }
    });
  }
  
  public toggleNewGroupForm() {
    this.showNewGroupForm = !this.showNewGroupForm;
    if (!this.showNewGroupForm) {
      this.resetNewGroupForm();
    }
  }

  public resetNewGroupForm() {
    this.newGroupName = '';
    this.duplicatedName = false;
    this.quickReplyGroupForm.reset();
  }

  public saveGroup() {

    const groupName = this.quickReplyGroupForm.get('name')?.value.trim();
    const quickReplyGroupRequest = new QuickReplyGroupRequest(this.selectedGroup?.id, groupName);

    this.quickReplyService.saveQuickReplyGroup(quickReplyGroupRequest).subscribe({
      next: (quickReplyGroup: QuickReplyGroupInfo) => {
        this.groups.unshift(quickReplyGroup);
        this.toastr.success('Grupo creado con éxito');
        this.resetNewGroupForm();
        this.toggleNewGroupForm();
      },
      error: (error) => {
        if (error.status === 400) {
          this.duplicatedName = true;
        } else {
          console.error('Error al crear la categoría:', error);
          this.toastr.error('Error al crear la categoría');
        }
      }
    });
  }

  public addQuickReplyToGroup(quickReply: QuickReplyInfo, group: QuickReplyGroupInfo): void {

    let availableQuickReplyIndex = this.availableQuickReplies.findIndex(qr => qr.id === quickReply.id);
    if (availableQuickReplyIndex !== -1) {
      this.availableQuickReplies.splice(availableQuickReplyIndex, 1);
    }

    this.quickReplyService.addQuickReplyGroup(quickReply.id, group.id).subscribe({
      next: () => {
        group.quickReplies.push(quickReply);
        this.toastr.success('Respuesta rápida agregada');
      },
      error: (error) => {
        console.error('Error al asignar la quick reply a la categoría:', error);
        this.toastr.error('Error al asignar la quick reply a la categoría');
      }
    });
  }

  public openEditGroup(group: QuickReplyGroupInfo) {

    this.selectedGroup = group;

    const availableQuickReplies: QuickReplyInfo[] = [...this.quickReplies];

    for (const quickReply of this.selectedGroup.quickReplies) {
      let availableQuickReplyIndex = availableQuickReplies.findIndex(qr => qr.id === quickReply.id);
      if (availableQuickReplyIndex !== -1) {
        availableQuickReplies.splice(availableQuickReplyIndex, 1);
      }
    }

    this.availableQuickReplies = availableQuickReplies;

    this.modalService.open(this.editGroupModal, {
      centered: true
    });
  }

  public saveGroupName(quickReplyGroupInfo: QuickReplyGroupInfo) {

    const gropName: string = this.editedGroupName.trim();

    if (gropName !== quickReplyGroupInfo.name) {

      const quickReplyGroupRequest = new QuickReplyGroupRequest(quickReplyGroupInfo.id, gropName);

      this.quickReplyService.saveQuickReplyGroup(quickReplyGroupRequest).subscribe({
        next: (quickReplyGroup: QuickReplyGroupInfo) => {
          quickReplyGroupInfo.name = quickReplyGroup.name;
          this.toastr.success('Nombre actualizado correctamente');
        },
        error: (error) => {
          if (error.status === 409) {
            this.toastr.error('Ya existe un grupo con este nombre');
          } else {
            console.error('Error al actualizar el nombre del grupo:', error);
            this.toastr.error('Error al actualizar el nombre del grupo');
          }
        }
      });
    }

    this.editingGroupId = null;
    this.selectedGroup = null;
  }

  public editGroup(group: QuickReplyGroupInfo) {
    this.selectedGroup = group;
    if (this.editingGroupId === group.id) {
      this.saveGroupName(group);
    } else {
      this.editingGroupId = group.id;
      this.editedGroupName = group.name;
    }
  }

  public cancelEdit() {
    this.editingGroupId = null;
    this.editedGroupName = '';
    this.selectedGroup = null;
  }

  public removeQuickReplyFromGroup(quickReply: QuickReplyInfo, group: QuickReplyGroupInfo) {

    this.selectedQuickReply = quickReply;

    const modalRef = this.modalService.open(this.removeQuickReplyModal, {
      centered: true
    });

    modalRef.result.then((result) => {

      if (result === 'confirm') {

        this.quickReplyService.removeQuickReplyFromGroup(quickReply.id, group.id).subscribe({
          next: () => {
            const quickReplyIndex = group.quickReplies.findIndex(qr => qr.id === quickReply.id);
            if (quickReplyIndex !== -1) {
              group.quickReplies.splice(quickReplyIndex, 1);
            }
            this.toastr.success('Respuesta rápida removida del grupo');
          },
          error: (error) => {
            console.error('Error al eliminar la respuesta rápida:', error);
            this.toastr.error('Error al eliminar la respuesta rápida');
          }
        });
      }
    });

  }

}