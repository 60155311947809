<div class="w-100 w-xxl-75" *ngIf="message">
  <div class="d-flex hover-actions-trigger" [ngClass]="{
    'flex-end-center': !message.customerMessage
  }">

    <!-- Customer Avatar and Name -->
    <ng-container *ngIf="message.customerMessage">
      <div class="avatar avatar-m me-3 flex-shrink-0">
        <div *ngIf="!chat.profilePictureUrl"
             class="avatar-name rounded-circle"
             [ngStyle]="{'color': chat.color}">
          <span>{{ chat.chatName[0] }}</span>
        </div>
        <img *ngIf="chat.profilePictureUrl"
             [src]="chat.profilePictureUrl"
             class="rounded-circle border border-2 border-white">
      </div>
    </ng-container>

    <!-- Message Actions -->
    <ng-container *ngIf="!message.customerMessage">
      <!-- Mobile Actions -->
      <ng-container *ngTemplateOutlet="mobileActions"></ng-container>
      <!-- Desktop Actions -->
      <ng-container *ngTemplateOutlet="desktopActions"></ng-container>
    </ng-container>

    <!-- Message Type Components -->
    <ng-container *ngIf="message.type === MessageType.Text">
      <app-chat-text-message
        [class]="'chat-message-content me-2'"
        [class.received]="message.customerMessage"
        [chat]="chat"
        [message]="message">
      </app-chat-text-message>
    </ng-container>

    <ng-container *ngIf="message.type === MessageType.Interactive">
      <app-chat-interactive-message
        [class]="'chat-message-content me-2'"
        [class.received]="message.customerMessage"
        [chat]="chat"
        [message]="message">
      </app-chat-interactive-message>
    </ng-container>

    <ng-container *ngIf="message.type === MessageType.Note">
      <app-chat-note-message
        [class]="'chat-message-content me-2'"
        [class.received]="message.customerMessage"
        [chat]="chat"
        [message]="message">
      </app-chat-note-message>

    </ng-container>
    <ng-container *ngIf="message.type === MessageType.Template">
      <app-chat-template-message
        [class]="'chat-template-message-content me-2'"
        [class.received]="message.customerMessage"
        [message]="message">
      </app-chat-template-message>
    </ng-container>
    <ng-container *ngIf="message.type === MessageType.Button">
      <app-chat-button-message
        [class]="'chat-message-content me-2'"
        [class.received]="message.customerMessage"
        [chat]="chat"
        [message]="message">
      </app-chat-button-message>
    </ng-container>
    <ng-container *ngIf="message.type === MessageType.Image">
      <app-chat-image-message
        (imageLoaded)="setMessageReady()"
        [class]="'chat-message-content me-2'"
        [class.received]="message.customerMessage"
        [chat]="chat"
        [message]="message">
      </app-chat-image-message>
    </ng-container>
    <ng-container *ngIf="message.type === MessageType.Sticker">
      <app-chat-sticker-message
        [class]="'chat-message-content me-2'"
        [class.received]="message.customerMessage"
        [chat]="chat"
        [message]="message">
      </app-chat-sticker-message>
    </ng-container>
    <ng-container *ngIf="message.type === MessageType.Video">
      <app-chat-video-message
        (videoLoaded)="setMessageReady()"
        [class]="'chat-message-content me-2'"
        [class.received]="message.customerMessage"
        [message]="message">
      </app-chat-video-message>
    </ng-container>
    <ng-container *ngIf="message.type === MessageType.Audio">
      <app-chat-audio-message
        [class]="'chat-message-content me-2'"
        [class.received]="message.customerMessage"
        [message]="message">
      </app-chat-audio-message>
    </ng-container>
    <ng-container *ngIf="message.type === MessageType.Document || message.type === MessageType.File">
      <app-chat-document-message
        [class]="'chat-message-content me-2'"
        [class.received]="message.customerMessage"
        [chat]="chat"
        [message]="message">
      </app-chat-document-message>
    </ng-container>
    <ng-container *ngIf="message.type === MessageType.Location">
      <app-chat-location-message
        [class]="'chat-message-content me-2'"
        [class.received]="message.customerMessage"
        [message]="message">
      </app-chat-location-message>
    </ng-container>
    <ng-container *ngIf="message.type === MessageType.Contact">
      <app-chat-contacts-message
        [class]="'chat-message-content me-2'"
        [class.received]="message.customerMessage"
        [message]="message">
      </app-chat-contacts-message>
    </ng-container>
    <!-- Message Actions -->
    <ng-container *ngIf="message.customerMessage">
      <!-- Mobile Actions -->
      <ng-container *ngTemplateOutlet="mobileActions"></ng-container>
      <!-- Desktop Actions -->
      <ng-container *ngTemplateOutlet="desktopActions"></ng-container>
    </ng-container>

  </div>

  <!-- Message Sent Time -->
  <div class="d-flex flex-column">
    <div class="d-flex" [ngClass]="message.customerMessage ? 'ms-7' : 'justify-content-end text-end'">
      <p class="mb-0 fs--2 text-900 fw-semi-bold">
        {{ message.sentTime | date: 'dd/MM/yyyy HH:mm:ss' }}
      </p>
      <ng-container *ngIf="!message.customerMessage && message.type !== MessageType.Note">
        <div *ngFor="let status of Object.values(MessageStatus)">
          <div *ngIf="message.status === status">
            <i class="fa ms-2 fs--1" [ngClass]="message.statusClass"></i>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="d-flex"
         *ngIf="!message.customerMessage && message.smart"
         [ngClass]="message.customerMessage ? 'ms-7' : 'justify-content-end text-end'">
      <p class="mb-0 fs--1 text-900 fw-semi-bold">
        <i class="fa-solid fa-sparkles text-warning"></i>
        Enviado por IVANA
      </p>
    </div>

    <div class="d-flex"
         *ngIf="!message.customerMessage && message.senderId && !message.smart"
         [ngClass]="message.customerMessage ? 'ms-7' : 'justify-content-end text-end'">
      <p class="mb-0 fs--1 text-900 fw-semi-bold" *ngIf="message.sentBy">
        <i class="fa fa-user-headset text-warning"></i>
        Enviado por {{ message.sentBy }}
      </p>
    </div>
  </div>

</div>

<!-- Mobile Actions for Customer -->
<ng-template #mobileActions>
  <div class="d-sm-none hover-actions align-self-center me-2"
       [ngClass]="{
      'end-0': message.customerMessage,
      'start-0': !message.customerMessage
    }">
    <div class="bg-white rounded-pill d-flex align-items-center border border-300 px-2 actions">
      <button class="btn p-2" type="button"><span class="fa-solid fa-reply text-primary"></span></button>
      <button class="btn p-2" type="button"><span class="fa-solid fa-trash text-primary"></span></button>
      <button class="btn p-2" type="button"><span class="fa-solid fa-share text-primary"></span></button>
      <button class="btn p-2" type="button"><span class="fa-solid fa-face-smile text-primary"></span></button>
    </div>
  </div>
</ng-template>

<!-- Desktop Actions for Customer -->
<ng-template #desktopActions>
  <div class="d-none d-sm-flex">
    <div class="position-relative align-self-center" [class.me-2]="message.customerMessage">
      <button type="button" class="btn p-2 fs--2" (click)="highlightMessage(message)">
        <span [ngClass]="message.highlighted ? 'fa-solid fa-star text-warning' : 'fa-regular fa-star'"></span>
      </button>
      <button type="button" class="btn p-2 fs--2" (click)="emitReplyEvent()">
        <span class="fa-solid fa-reply" [class.text-primary]="!message.customerMessage"></span>
      </button>
      <button type="button" class="btn p-2 fs--2" (click)="openEmojiPopover()">
        <span class="fa-solid fa-face-smile" [class.text-primary]="!message.customerMessage"
              [autoClose]="'outside'" ngbPopover="Este es un popover personalizado" [container]="'body'"
              [ngbPopover]="emojiPopoverReactMessageContent" #reactPopover="ngbPopover"></span>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #emojiPopoverReactMessageContent>
  <emoji-mart [isNative]="true" [i18n]="emojiMartI18n" (emojiClick)="selectEmoji($event)"></emoji-mart>
</ng-template>

