// Angular
import {
  AfterViewInit,
  Component, Input,
  OnDestroy,
  OnInit, Output, TemplateRef, ViewChild
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

// RxJS
import { Subject, Subscription } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  WhatsAppMessageTemplateInfo
} from "../../../../../models/chat/whatsapp-message-template/whatsapp-message-template-info.dto";

@Component({
  selector: "app-message-template-body",
  templateUrl: "./message-template-body.component.html",
  styleUrls: ["./message-template-body.component.css"],
})
export class MessageTemplateBodyComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input()
  public whatsAppMessageTemplate: WhatsAppMessageTemplateInfo;

  @Output()
  public onBodyTextChange: Subject<string> = new Subject<string>();

  // Component Data
  private componentSubscriptions: Subscription[] = [];
  // Component Data

  // Forms
  public messageTemplateBodyForm: FormGroup;
  // Forms

  // Modals
  @ViewChild('showMessageTemplateBodyModal')
  private showMessageTemplateBodyModal: TemplateRef<any>;

  constructor(
    private modalService: NgbModal,
  ) {

  }

  ngOnInit() {
    this.initializeForm();
    this.initializeBody();
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.componentSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  private initializeForm() {

    this.messageTemplateBodyForm = new FormGroup({
      bodyText: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(1024),
      ]),
    });

    this.messageTemplateBodyForm.get('bodyText').valueChanges
      .subscribe( {
        next: (value: string) => {
          this.onBodyTextChange.next(value);
        }
      });

  }

  private initializeBody() {
    if (this.whatsAppMessageTemplate.bodyComponent) {
      this.messageTemplateBodyForm.get("bodyText").setValue(this.whatsAppMessageTemplate.bodyComponent.text);
    }
  }

  public showMessageTemplateBody() {
    const modalRef = this.modalService.open(this.showMessageTemplateBodyModal, {
      size: 'lg',
      centered: true,
      scrollable: true,
    });
    modalRef.dismissed.subscribe(() => {
      this.messageTemplateBodyForm.get("bodyText").setValue(this.messageTemplateBodyForm.get("bodyText").value);
    });
  }

}
