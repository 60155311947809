import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: 'app-pricing-page-component',
  templateUrl: `./pricing-page.component.html`,
  styleUrls: ['./pricing-page.component.css'],
})
export class PricingPageComponent implements OnInit {

  public allLoaded: boolean = false;
  public allLoadedAfter: boolean = false;

  constructor(
    private router: Router,
  ) {

  }

  ngOnInit() {
    setTimeout(() => {
      this.allLoaded = true;
      setTimeout(() => {
        this.allLoadedAfter = true;
      }, 500);
    } , 500);
  }

}
