import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import {
  SendTestSmartAssistantMessageRequest
} from "../../../models/utilities/smart-assistant/test/send-test-smart-assistant-message-request.dto";
import {
  SendTestSmartAssistantMessageResponse
} from "../../../models/utilities/smart-assistant/test/send-test-smart-assistant-message-response.dto";

@Injectable({
  providedIn: 'root'
})
export class TestSmartAssistantService {

  private apiUrl: string = `${environment.apiUrl}/test-smart-assistants`;

  constructor(
    private http: HttpClient,
  ) {

  }

  sendTestSmartAssistantMessage(sendTestSmartAssistantMessageRequest: SendTestSmartAssistantMessageRequest): Observable<SendTestSmartAssistantMessageResponse> {
    const endpoint = `${this.apiUrl}/send-test-smart-assistant-message`;
    return this.http.post<SendTestSmartAssistantMessageResponse>(endpoint, sendTestSmartAssistantMessageRequest);
  }

}