import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { Page } from '@app/models/common/page';
import { MessageInfo } from '@app/models/chat/message/message-info.dto';
import { MessageSearchRequest } from '@app/models/chat/message/message-search-request.dto';
import { SendMessageRequest } from '@app/models/chat/message/send-message-request.dto';
import { GetSignedUrlRequest } from '@app/models/media/get-signed-url-request.dto';
import { GetSignedUrlResponse } from '@app/models/media/get-signed-url-response.dto';
import { UpdateMessageNoteRequest } from '../../models/chat/message/update-message-note-request.dto';
import { HighlightMessageRequest } from '../../models/chat/message/highlight-message-request.dto';

@Injectable({
  providedIn: 'root',
})
export class MessageService {

  private apiUrl: string = `${environment.apiUrl}/messages`;

  constructor(
    private http: HttpClient,
  ) {

  }

  searchMessages(messageSearch: MessageSearchRequest): Observable<Page<MessageInfo>> {
    const endpoint = `${this.apiUrl}/search-messages`;
    return this.http.post<Page<MessageInfo>>(endpoint, messageSearch);
  }

  getMessage(id: number) {
    const endpoint = `${this.apiUrl}/get-message/${id}`;
    return this.http.get<MessageInfo>(endpoint);
  }

  sendMessage(sendMessageRequest: SendMessageRequest): Observable<MessageInfo> {
    const endpoint = `${this.apiUrl}/send-message`;
    return this.http.post<MessageInfo>(endpoint, sendMessageRequest);
  }

  updateMessageNote(updateMessageNoteRequest: UpdateMessageNoteRequest): Observable<MessageInfo> {
    const endpoint = `${this.apiUrl}/update-message-note`;
    return this.http.post<MessageInfo>(endpoint, updateMessageNoteRequest);
  }

  deleteMessageNote(messageNoteId: number) {
    const endpoint = `${this.apiUrl}/delete-message-note/${messageNoteId}`;
    return this.http.delete<any>(endpoint, {});
  }

  getMessageNotes(chatId: number): Observable<MessageInfo[]> {
    const endpoint = `${this.apiUrl}/get-message-notes/${chatId}`;
    return this.http.get<MessageInfo[]>(endpoint);
  }

  getWhatsAppSignedFileUrl(getSignedUrlRequest: GetSignedUrlRequest): Observable<GetSignedUrlResponse> {
    const endpoint = `${this.apiUrl}/media/whatsapp/get-signed-url`;
    return this.http.post<GetSignedUrlResponse>(endpoint, getSignedUrlRequest);
  }

  getHighlightedMessages(chatId: number): Observable<any> {
    const endpoint = `${this.apiUrl}/get-highlighted-messages/${chatId}`;
    return this.http.get<any>(endpoint);
  }

  highlightMessage(highlightRequest: HighlightMessageRequest): Observable<any> {
    const endpoint = `${this.apiUrl}/highlight-message`;
    return this.http.post<any>(endpoint, highlightRequest);
  }

  getSelectedMessagePage(messageSearch: MessageSearchRequest): Observable<Page<MessageInfo>> {
    const endpoint = `${this.apiUrl}/selected-message-page`;
    return this.http.post<Page<MessageInfo>>(endpoint, messageSearch);
  }
}