
export class ChangeSmartAssistantStatusRequest {

  smartAssistantId: number;
  enabled: boolean;

  constructor(smartAssistantId: number, enabled: boolean) {
    this.smartAssistantId = smartAssistantId;
    this.enabled = enabled;
  }

}