
export class ChatTagRequest {

  id: number;
  shopId: number;
  name: string;
  color: string;

  constructor(
    shopId: number,
    name: string,
    backgroundColor: string,
    id?: number,
  ) {
    this.id = id;
    this.shopId = shopId;
    this.name = name;
    this.color = backgroundColor;
  }

}