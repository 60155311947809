
<div *ngIf="message" class="mb-1 p-3 rounded-2" [ngClass]="{
  'received-message-content border': message.customerMessage,
  'sent-message-content bg-primary light text-white': !message.customerMessage,
}">
  <app-chat-context-message
    *ngIf="message.contextMessage"
    [message]="message.contextMessage"
    [chat]="chat">
  </app-chat-context-message>
  <p class="mb-0">
    {{ message.content }}
  </p>
  <span class="reaction-emoji" *ngIf="message.reactionEmoji">
    {{ message.reactionEmoji }}
  </span>
</div>