import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserInfo } from '@models/account/user/user.info.dto';
import { AuthService } from '@services/auth/auth.service';
import { SpinnerService } from '@services/data/spinner.service';
import { MessengerConfigService } from '@services/config/messenger-config.service';
import { MessengerConfigInfo } from '@models/config/messenger/messenger-config-info.dto';
import { environment } from '@env/environment';
import { SaveMessengerConfigRequest } from '@models/config/messenger/save-messenger-config-request.dto';

declare var FB: any;

@Component({
  selector: 'app-messenger-config',
  templateUrl: './messenger-config.component.html',
  styleUrls: ['./messenger-config.component.css']
})
export class MessengerConfigComponent implements OnInit {

  public messengerConfigs: MessengerConfigInfo[];
  private user: UserInfo;

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private messengerConfigService: MessengerConfigService,
    private spinnerService: SpinnerService,
  ) {
    this.user = this.authService.getUser();
    this.initializeFacebookService();
  }

  ngOnInit() {
    this.getMessengerConfigurations();
  }

  private initializeFacebookService() {

    FB.init({
      appId: environment.facebookAppId,
      xfbml: true,
      version: 'v20.0',
    });

  }

  public signIn() {

    this.spinnerService.show('Conectando con Facebook Messenger...');

    const scopes: string[] = [
      'pages_show_list',
      'pages_messaging',
      'pages_manage_metadata',
      'business_management',
    ];

    const loginOptions: any = {
      config_id: environment.facebookConfigId,
      scope: scopes.join(','),
      return_scopes: true,
      enable_profile_selector: true
    };

    FB.login((response: any) => {

      const { authResponse } = response;

      if (authResponse) {

        const userId = authResponse.userID;
        const accessToken = authResponse.accessToken;

        const saveMessengerConfigRequest = new SaveMessengerConfigRequest(
          this.user.shop.id, userId, accessToken
        );

        this.messengerConfigService.saveConfig(saveMessengerConfigRequest).subscribe({
          next: () => {
            this.getMessengerConfigurations();
            this.spinnerService.hide();
            this.toastr.success('Conexión con Facebook Messenger establecida correctamente.');
          },
          error: () => {
            this.spinnerService.hide();
            this.toastr.error('Error al establecer conexión con Facebook Messenger.');
          }
        });

      } else {
        this.spinnerService.hide();
        console.log('El usuario no ha autorizado la conexión con Facebook Messenger');
      }

    }, loginOptions);

  }

  public disconnect() {

    this.spinnerService.show('Desconectando con Facebook Messenger...');

    this.messengerConfigService.removeConfig()
      .subscribe(() => {
        this.messengerConfigs = [];
        this.spinnerService.hide();
      });

  }

  private getMessengerConfigurations() {
    this.messengerConfigService.getConfig().subscribe(messengerConfig => {
      this.messengerConfigs = messengerConfig;
    });
  }

}
