import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Page } from '../../models/common/page';

import { SaveFunnelStageRequest } from '../../models/utilities/funnel/stage/save-funnel-stage-request.dto';
import { FunnelStageInfo } from '../../models/utilities/funnel/stage/funnel-stage-info.dto';
import { FunnelInfo } from '../../models/utilities/funnel/funnel-info.dto';
import { SaveFunnelRequest } from '../../models/utilities/funnel/save-funnel-request.dto';
import { FunnelSearchRequest } from '../../models/utilities/funnel/funnel-search-request.dto';
import { GetFunnelStageChatsRequest } from '../../models/utilities/funnel/stage/get-funnel-stage-chats-request.dto';
import { ChatInfo } from '../../models/chat/chat/chat-info.dto';
import {
  ToggleFunnelStageCollapsedRequest
} from '../../models/utilities/funnel/stage/toggle-funnel-stage-collapsed-request.dto';
import { UpdateFunnelStageRequest } from '@models/utilities/funnel/stage/update-funnel-stage-request.dto';


@Injectable({
  providedIn: 'root',
})
export class FunnelService {

  private apiUrl: string = `${environment.apiUrl}/funnel`;

  constructor(
    private http: HttpClient,
  ) {

  }

  searchFunnels(funnelSearch: FunnelSearchRequest): Observable<Page<FunnelInfo>> {
    const endpoint = `${this.apiUrl}/search-funnel-funnels`;
    return this.http.post<Page<FunnelInfo>>(endpoint, funnelSearch);
  }

  getFunnel(id: number): Observable<FunnelInfo> {
    const endpoint = `${this.apiUrl}/get-funnel/${id}`;
    return this.http.get<FunnelInfo>(endpoint);
  }

  saveFunnel(saveFunnelRequest: SaveFunnelRequest): Observable<FunnelInfo> {
    const endpoint = `${this.apiUrl}/save-funnel`;
    return this.http.post<FunnelInfo>(endpoint, saveFunnelRequest);
  }

  deleteFunnel(id: number): Observable<void> {
    const endpoint = `${this.apiUrl}/delete-funnel/${id}`;
    return this.http.delete<void>(endpoint);
  }

  saveFunnelStage(saveFunnelStageRequest: SaveFunnelStageRequest): Observable<FunnelStageInfo> {
    const endpoint = `${this.apiUrl}/save-funnel-stage`;
    return this.http.post<FunnelStageInfo>(endpoint, saveFunnelStageRequest);
  }

  getFunnelStageChats(getFunnelStageChatsRequest: GetFunnelStageChatsRequest): Observable<Page<ChatInfo>> {
    const endpoint = `${this.apiUrl}/get-funnel-stage-chats`;
    return this.http.post<Page<ChatInfo>>(endpoint, getFunnelStageChatsRequest);
  }

  updateChatFunnelStage(updateChatFunnelStageRequest: UpdateFunnelStageRequest): Observable<ChatInfo> {
    const endpoint = `${this.apiUrl}/update-chat-funnel-stage`;
    return this.http.post<ChatInfo>(endpoint, updateChatFunnelStageRequest);
  }

  toggleFunnelStageCollapse(toggleFunnelStageCollapsedRequest: ToggleFunnelStageCollapsedRequest): Observable<void> {
    const endpoint = `${this.apiUrl}/toggle-funnel-stage-collapse`;
    return this.http.post<void>(endpoint, toggleFunnelStageCollapsedRequest);
  }

  deleteFunnelStage(id: number): Observable<void> {
    const endpoint = `${this.apiUrl}/delete-funnel-stage/${id}`;
    return this.http.delete<void>(endpoint);
  }

}