import { PaginatedSearchRequest } from '../../../common/paginated-search-request.dto';

export class GetBoardColumnChatsRequest extends PaginatedSearchRequest {

  shopId: number;
  boardColumnId: number;

  constructor(shopId: number, boardColumnId: number, page: number, size: number) {
    super();
    this.page = page;
    this.size = size;
    this.shopId = shopId;
    this.boardColumnId = boardColumnId;
  }

}