
export class SaveSmartAssistantOrchestratorContextRequest {

  id: number;
  smartAssistantOrchestratorId: number;
  context: string;
  smartAssistantId: number;

  constructor(smartAssistantOrchestratorId: number, context: string, smartAssistantId: number) {
    this.smartAssistantOrchestratorId = smartAssistantOrchestratorId;
    this.context = context;
    this.smartAssistantId = smartAssistantId;
  }

}