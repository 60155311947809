<main class="main" id="top">
  <div class="container-fluid bg-300 dark__bg-1200">
    <div class="bg-holder bg-auth-card-overlay"
         style="background-image: url(https://prium.github.io/phoenix/v1.13.0/assets/img/bg/37.png);">
    </div>
    <div class="row flex-center position-relative min-vh-100 g-0 py-5">
      <div class="col-12 col-sm-12 col-md-10 col-xl-8">
        <div class="card border border-200 auth-card">
          <div class="card-body pe-md-0">
            <div class="row gx-0 gy-7">
              <div class="col-auto dark__bg-1100 rounded-3 position-relative overflow-hidden auth-title-box"
                   *ngIf="!isMobile">
                <div class="bg-holder"
                     style="background-image:url(https://prium.github.io/phoenix/v1.13.0/assets/img/bg/38.png);"></div>
                <div
                  class="position-relative px-4 px-lg-7 pt-7 pb-7 pb-sm-5 text-center text-md-start pb-lg-7 card-sign-up">
                  <img
                    [src]="currentTheme === 'dark' ? './assets/img/logo/ivana2.png' : './assets/img/logo/logo.png'"
                    src="./assets/img/logo/ivana2.png"
                    class="mb-4"
                    alt="ivana logo"
                    width="240"
                  />
                  <p class="text-700">
                    Regístrate y empieza a mejorar tu experiencia con IVANA.
                  </p>
                  <ul class="list-unstyled mb-0 w-max-content w-md-auto mx-auto">
                    <li class="d-flex align-items-center">
                      <span
                        class="uil uil-check-circle text-success me-2"></span>
                      <span
                        class="text-700 fw-semi-bold">
                        Rápido
                      </span>
                    </li>
                    <li class="d-flex align-items-center">
                      <span
                        class="uil uil-check-circle text-success me-2"></span>
                      <span
                        class="text-700 fw-semi-bold">
                        Simple
                      </span>
                    </li>
                    <li class="d-flex align-items-center">
                      <span
                        class="uil uil-check-circle text-success me-2"></span>
                      <span class="text-700 fw-semi-bold">
                        Eficaz
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col py-7 mx-auto">
                <div class="auth-form-box">
                  <a
                    class="d-flex flex-center text-decoration-none mb-2"
                    [routerLink]="['/precios']">
                    <div class="d-flex align-items-center d-inline-block">
                      <img
                        [src]="currentTheme === 'dark' ? './assets/img/logo/logo-icon-white.png' : './assets/img/logo/logo-icon-multi-color.png'"
                        alt="phoenix"
                        width="45"
                      />
                    </div>
                  </a>
                  <div class="text-center mb-2">
                    <h3 class="text-1000">
                      Crea tu cuenta
                    </h3>
                    <p class="text-700">
                      Crea tu cuenta fácilmente
                    </p>
                  </div>
                  <div class="text-center mb-2">
                    <h5 class="text-1000">
                      Plan: <b class="text-indigo">{{ subscriptionTypeDescription.description }}</b>
                    </h5>
                  </div>
                  <form
                    [formGroup]="signUpForm"
                    (submit)="signUp()">
                    <div class="mb-3 text-start">
                      <label class="form-label" for="name">
                        Nombre
                      </label>
                      <input
                        class="form-control is-invalid"
                        id="name"
                        type="text"
                        placeholder="Nombre"
                        formControlName="name"
                        [ngClass]="{
                          'is-invalid': signUpForm.get('name').touched && signUpForm.get('name').errors,
                          'is-valid': signUpForm.get('name').valid
                        }"
                      />
                      <div class="valid-feedback" *ngIf="false">
                        Todo en orden
                      </div>
                      <div *ngIf="signUpForm.get('name').touched && signUpForm.get('name').errors"
                           class="invalid-feedback">
                        El nombre es requerido
                      </div>
                    </div>
                    <div class="mb-3 text-start">
                      <label class="form-label" for="email">
                        Dirección de correo electrónico
                      </label>
                      <input
                        class="form-control"
                        id="email"
                        type="email"
                        placeholder="nombre@ejemplo.com"
                        formControlName="email"
                        [ngClass]="{
                          'is-invalid': signUpForm.get('email').touched && signUpForm.get('email').invalid,
                          'is-valid': signUpForm.get('email').valid
                        }"
                      />
                      <div class="valid-feedback" *ngIf="false">
                        Todo en orden
                      </div>
                      <div *ngIf="signUpForm.get('email').touched && signUpForm.get('email').invalid" class="invalid-feedback">
                        Ingresa una dirección de correo electrónico válida.
                      </div>
                    </div>
                    <div class="row g-3 mb-3">
                      <div class="col-md-6">
                        <label class="form-label" for="password">Contraseña</label>
                        <input
                          class="form-control form-icon-input"
                          id="password"
                          type="password"
                          placeholder="Contraseña"
                          formControlName="password"
                          [ngClass]="{
                            'is-invalid': signUpForm.get('password').touched && signUpForm.get('password').invalid,
                            'is-valid': signUpForm.get('password').valid
                          }"
                        />
                        <div class="invalid-feedback" *ngIf="signUpForm.get('password').touched && signUpForm.get('password').invalid">
                          La contraseña es requerida y debe tener al menos 6 caracteres.
                        </div>
                      </div>
                      <div class="col-md-6">
                        <label class="form-label" for="confirmPassword">
                          Confirmar Contraseña
                        </label>
                        <input
                          class="form-control form-icon-input"
                          id="confirmPassword"
                          type="password"
                          placeholder="Confirmar Contraseña"
                          formControlName="confirmPassword"
                          [ngClass]="{
                            'is-invalid': signUpForm.get('confirmPassword').touched && signUpForm.get('confirmPassword').invalid,
                            'is-valid': signUpForm.get('confirmPassword').valid
                          }"
                        />
                        <div class="invalid-feedback"
                             *ngIf="signUpForm.get('confirmPassword').touched && signUpForm.get('confirmPassword').hasError('required')">
                          Confirma tu contraseña, debe coincidir con la contraseña anterior.
                        </div>
                        <div class="invalid-feedback"
                             *ngIf="signUpForm.get('confirmPassword').hasError('passwordMismatch')">
                          Las contraseñas no coinciden.
                        </div>
                      </div>
                    </div>
                    <div class="mb-3 text-start">
                      <label class="form-label" for="shopName">
                        Nombre de la empresa
                      </label>
                      <input
                        class="form-control is-invalid"
                        id="shopName"
                        type="text"
                        placeholder="Nombre de la empresa"
                        formControlName="shopName"
                        [ngClass]="{
                          'is-invalid': signUpForm.get('shopName').touched && signUpForm.get('shopName').errors,
                          'is-valid': signUpForm.get('shopName').valid
                        }"
                      />
                      <div class="valid-feedback" *ngIf="false">
                        Todo en orden
                      </div>
                      <div *ngIf="signUpForm.get('shopName').touched && signUpForm.get('shopName').errors"
                           class="invalid-feedback">
                        El nombre de la empresa es requerido
                      </div>
                    </div>
                    <div class="form-check mb-3">
                      <input
                        class="form-check-input"
                        id="termsService"
                        type="checkbox"
                        formControlName="termsService"
                        [ngClass]="{
                          'is-invalid': signUpForm.get('termsService').invalid && submitted
                        }"/>
                      <label class="form-label fs--1 text-none" for="termsService">
                        Acepto los
                        <a [routerLink]="['']">términos</a> y la
                        <a [routerLink]="['']">política de privacidad</a>
                      </label>
                      <div
                        class="invalid-feedback mt-0"
                        *ngIf="signUpForm.get('termsService').invalid">
                        Debes aceptar los términos y condiciones.
                      </div>
                    </div>
                    <button
                      class="btn btn-indigo w-100 mb-2"
                      type="submit"
                      [disabled]="signUpForm.invalid">
                      {{ loading ? 'Creando cuenta...' : 'Crear cuenta' }}
                    </button>
                    <div class="invalid-feedback d-block mt-0 mb-2" *ngIf="error">
                      {{ error }}
                    </div>
                    <div class="text-center">
                      <a class="fs--0 fw-bold mt-2" [routerLink]="['/sign-in']">
                        Iniciar sesión en una cuenta existente
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>