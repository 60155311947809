<div class="d-flex flex-column justify-content-between h-100">
  <div>
    <div class="d-flex align-items-center mb-4">
      <div class="avatar avatar-4xl ">
        <img class="rounded" src="./assets/img/placeholder/no_image_found-light.webp" alt="" />
      </div>
      <h3 class="mb-3 lh-sm ms-3">{{ appPluginInfo.name }} </h3>
    </div>

    <div class="mb-3">
      <h4>{{ appPluginInfo.description }}</h4>
    </div>
  </div>
  <div>
    <div class="mb-3">
      <div class="d-flex">
        <div class="p-2 mx-1 px-3">
          <span>Calificación ({{ appPluginInfo.generalRating }})</span>
          <div class="d-flex flex-wrap">
            <div class="me-2">
              <span *ngFor="let star of stars" [class]="star"></span>
            </div>
          </div>
        </div>
        <div class="p-2 mx-1 px-3 border-x border-2">
          <span>Reseñas</span>
          <div>
            <a
              class="fw-black text-black fw-medium text-decoration-none border-bottom border-2 border-black"
            >{{ appPluginInfo.totalReviews }}</a>
          </div>
        </div>
        <div class="p-2 mx-1 px-3">
          <span>Desarrollador</span>
          <div>
            <a
              class="fw-black text-black fw-medium text-decoration-none border-bottom border-2 border-black">{{
                appPluginInfo.developer
              }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-3">
      <div class="d-flex">
        <button class="btn btn-lg btn-primary rounded-pill w-100 fs--1 fs-sm-1">
          Instalar
        </button>
      </div>
    </div>
  </div>

</div>