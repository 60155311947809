import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserInfo } from '@models/account/user/user.info.dto';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-integrations-config',
  templateUrl: './integrations-config.component.html',
  styleUrls: ['./integrations-config.component.css']
})
export class IntegrationsConfigComponent implements OnInit {

  public whatsAppConfigForm: FormGroup;
  public loading: boolean = false;
  public errorCode: string = '';

  private user: UserInfo;

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
  ) { 
    this.user = this.authService.getUser();
  }

  ngOnInit() {

  }

}
