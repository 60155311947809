import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Page } from "../../models/common/page";

import { SaveBoardColumnRequest } from "../../models/utilities/board/column/save-board-column-request.dto";
import { BoardColumnInfo } from "../../models/utilities/board/column/board-column-info.dto";
import { BoardInfo } from "../../models/utilities/board/board-info.dto";
import { SaveBoardRequest } from "../../models/utilities/board/save-board-request.dto";
import { BoardSearchRequest } from "../../models/utilities/board/board-search-request.dto";
import {
  ToggleBoardColumnCollapsedRequest
} from '@app/models/utilities/board/column/toggle-board-column-collapsed-request.dto';
import { ChatInfo } from '@app/models//chat/chat/chat-info.dto';
import {
  GetBoardColumnChatsRequest
} from '@app/models//utilities/board/column/get-board-column-chats-request.dto';

@Injectable({
  providedIn: 'root'
})
export class KanbanService {

  private apiUrl: string = `${environment.apiUrl}/board`;

  constructor(
    private http: HttpClient,
  ) {

  }

  searchBoards(boardSearch: BoardSearchRequest): Observable<Page<BoardInfo>> {
    const endpoint = `${this.apiUrl}/search-boards`;
    return this.http.post<Page<BoardInfo>>(endpoint, boardSearch);
  }

  getBoard(id: number): Observable<BoardInfo> {
    const endpoint = `${this.apiUrl}/get-board/${id}`;
    return this.http.get<BoardInfo>(endpoint);
  }

  saveBoard(saveBoardRequest: SaveBoardRequest): Observable<BoardInfo> {
    const endpoint = `${this.apiUrl}/save-board`;
    return this.http.post<BoardInfo>(endpoint, saveBoardRequest);
  }

  deleteBoard(id: number): Observable<void> {
    const endpoint = `${this.apiUrl}/delete-board/${id}`;
    return this.http.delete<void>(endpoint);
  }

  saveBoardColumn(saveBoardColumnRequest: SaveBoardColumnRequest): Observable<BoardColumnInfo> {
    const endpoint = `${this.apiUrl}/save-board-column`;
    return this.http.post<BoardColumnInfo>(endpoint, saveBoardColumnRequest);
  }

  getBoardColumnChats(getBoardColumnChatsRequest: GetBoardColumnChatsRequest): Observable<Page<ChatInfo>> {
    const endpoint = `${this.apiUrl}/get-board-column-chats`;
    return this.http.post<Page<ChatInfo>>(endpoint, getBoardColumnChatsRequest);
  }

  toggleBoardColumnCollapse(toggleBoardColumnCollapsedRequest: ToggleBoardColumnCollapsedRequest): Observable<void> {
    const endpoint = `${this.apiUrl}/toggle-board-column-collapse`;
    return this.http.post<void>(endpoint, toggleBoardColumnCollapsedRequest);
  }

  deleteBoardColumn(id: number): Observable<void> {
    const endpoint = `${this.apiUrl}/delete-board-column/${id}`;
    return this.http.delete<void>(endpoint);
  }

}