import { Component, OnInit } from '@angular/core';
import { Page } from '@models/common/page';
import { UserInfo } from '@models/account/user/user.info.dto';
import { AuthService } from '@services/auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { EventEmitterService, NotificationTopic } from '@services/data/event-emitter.service';
import { SmartAssistantOrchestratorInfo } from "@models/utilities/smart-assistant/orchestrator/smart-assistant-orchestrator-info.dto";
import { SmartAssistantOrchestratorService } from "@services/utilities/smart-assistant/smart-assistant-orchestrator.service";
import { SmartAssistantOrchestratorSearchRequest } from "@models/utilities/smart-assistant/orchestrator/smart-assistant-orchestrator-search-request.dto";
import { ChangeSmartAssistantOrchestratorStatusRequest } from "@models/utilities/smart-assistant/orchestrator/change-smart-assistant-orchestrator-status-request.dto";

@Component({
  selector: 'app-smart-assistant-orchestrators-list',
  templateUrl: './smart-assistant-orchestrators-list.component.html',
  styleUrls: ['./smart-assistant-orchestrators-list.component.css']
})
export class SmartAssistantOrchestratorsListComponent implements OnInit {

  private user: UserInfo;
  public smartAssistantOrchestrators: SmartAssistantOrchestratorInfo[] = [];

  constructor(
    private readonly authService: AuthService,
    private readonly smartAssistantOrchestratorService: SmartAssistantOrchestratorService,
    private readonly toastr: ToastrService,
    private readonly eventEmitterService: EventEmitterService,
    private readonly toastrService: ToastrService,
  ) {
    this.user = this.authService.getUser();
  }

  ngOnInit() {
    this.initializeSmartAssistantSelectedEvent();
    this.loadSmartAssistantOrchestrators();
  }

  private initializeSmartAssistantSelectedEvent() {

    this.eventEmitterService.getEventEmitter(NotificationTopic.OpenSaveSmartAssistantOrchestrator).subscribe({
      next: (smartAssistantOrchestrator: SmartAssistantOrchestratorInfo) => {
        if (!smartAssistantOrchestrator)
          this.loadSmartAssistantOrchestrators();
      },
    });

  }

  private loadSmartAssistantOrchestrators() {

    const smartAssistantOrchestratorSearch = new SmartAssistantOrchestratorSearchRequest(
      this.user.shop.id, -1,
    );

    this.smartAssistantOrchestratorService.searchSmartAssistantOrchestrators(smartAssistantOrchestratorSearch).subscribe({
      next: (page: Page<SmartAssistantOrchestratorInfo>) => {
        this.smartAssistantOrchestrators = page.content;
      },
      error: (error: any) => {
        console.error('error:', error);
        this.toastr.error('Error en la carga de orquestadores de orquestadores');
      },
    });
  }

  public changeSmartAssistantOrchestratorStatus(smartAssistantOrchestrator: SmartAssistantOrchestratorInfo) {

    const changeSmartAssistantOrchestratorStatusRequest = new ChangeSmartAssistantOrchestratorStatusRequest(
      smartAssistantOrchestrator.id, !smartAssistantOrchestrator.enabled,
    );

    this.smartAssistantOrchestratorService.changeSmartAssistantOrchestratorStatus(changeSmartAssistantOrchestratorStatusRequest)
      .subscribe({
        next: () => {
          this.loadSmartAssistantOrchestrators();
          this.toastr.success('Orquestador actualizado exitosamente');
        },
        error: (httpErrorResponse: HttpErrorResponse) => {
          const { error } = httpErrorResponse;
          console.log('error', error);
          this.toastrService.error('Error al actualizar el orquestador');
        },
        complete: () => {
        },
      });

  }

  public openSaveSmartAssistantOrchestrator(smartAssistantOrchestrator?: SmartAssistantOrchestratorInfo) {
    if (!smartAssistantOrchestrator) {
      smartAssistantOrchestrator = new SmartAssistantOrchestratorInfo();
    }
    this.eventEmitterService.emit(NotificationTopic.OpenSaveSmartAssistantOrchestrator, smartAssistantOrchestrator);
  }

}
