<main class="main" id="top">
  <div class="container-fluid bg-300 dark__bg-1200">
    <div class="bg-holder bg-auth-card-overlay"
         style="background-image: url(/assets/img/background/37.png);">
    </div>
    <div class="row flex-center position-relative min-vh-100 g-0 py-5">
      <div class="col-11 col-sm-10 col-xl-8">
        <div class="card border border-200 auth-card">
          <div class="card-body pe-md-0">
            <div class="row gx-0 gy-7">
              <div class="col-auto py-7 dark__bg-1100 rounded-3 position-relative overflow-hidden auth-title-box">
                <div class="bg-holder"
                     style="background-image: url(/assets/img/background/38.png);"></div>
                <div
                  class="position-relative px-4 px-lg-7 pt-7 pb-7 pb-sm-5 text-center text-md-start pb-lg-7 card-sign-up">
                  <img
                    [src]="currentTheme === 'dark' ? './assets/img/logo/ivana2.png' : './assets/img/logo/logo.png'"
                    src="./assets/img/logo/ivana2.png"
                    class="mb-4"
                    alt="ivana logo"
                    width="240"
                  />
                  <p class="text-700">
                    Inicia sesión y empieza a mejorar tu experiencia con IVANA.
                  </p>
                  <ul class="list-unstyled mb-0 w-max-content w-md-auto mx-auto">
                    <li class="d-flex align-items-center">
                      <span
                        class="uil uil-check-circle text-success me-2"></span>
                      <span
                        class="text-700 fw-semi-bold">
                        Rápido
                      </span>
                    </li>
                    <li class="d-flex align-items-center">
                      <span
                        class="uil uil-check-circle text-success me-2"></span>
                      <span
                        class="text-700 fw-semi-bold">
                        Simple
                      </span>
                    </li>
                    <li class="d-flex align-items-center">
                      <span
                        class="uil uil-check-circle text-success me-2"></span>
                      <span class="text-700 fw-semi-bold">
                        Eficaz
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col py-7 mx-auto my-auto">
                <div class="auth-form-box">
                  <ng-container *ngIf="!showConfirmationMessage">
                    <a
                      class="d-flex flex-center text-decoration-none mb-2"
                      [routerLink]="['/']">
                      <div class="d-flex align-items-center d-inline-block">
                        <img
                          [src]="currentTheme === 'dark' ? './assets/img/logo/logo-icon-white.png' : './assets/img/logo/logo-icon-multi-color.png'"
                          alt="phoenix"
                          width="45"
                        />
                      </div>
                    </a>
                    <div class="text-center">
                      <h3 class="text-1000">
                        Activa tu cuenta
                      </h3>
                      <p class="text-700">
                        Ingresa una nueva contraseña para tu cuenta.
                      </p>
                    </div>
                    <form
                      [formGroup]="activeUserForm"
                      (submit)="activeUser()">
                      <div class="mb-3 text-start">
                        <label class="form-label" for="password">Contraseña</label>
                        <input
                          class="form-control form-icon-input"
                          id="password"
                          type="password"
                          placeholder="Contraseña"
                          formControlName="password"
                          [ngClass]="{
                            'is-invalid': activeUserForm.get('password').touched && activeUserForm.get('password').invalid,
                            'is-valid': activeUserForm.get('password').valid
                          }"
                        />
                        <div class="invalid-feedback"
                             *ngIf="activeUserForm.get('password').touched && activeUserForm.get('password').invalid">
                          La contraseña es requerida y debe tener al menos 8 caracteres.
                        </div>
                      </div>
                      <div class="mb-3 text-start">
                        <label class="form-label" for="password">Repetir contraseña</label>
                        <input
                          class="form-control form-icon-input"
                          id="confirmPassword"
                          type="password"
                          placeholder="Confirmar contraseña"
                          formControlName="confirmPassword"
                          [ngClass]="{
                          'is-invalid': activeUserForm.get('confirmPassword').touched && activeUserForm.get('confirmPassword').invalid,
                          'is-valid': activeUserForm.get('confirmPassword').valid
                        }"
                        />
                        <div class="invalid-feedback"
                             *ngIf="activeUserForm.get('password').touched && activeUserForm.get('password').invalid">
                          La contraseña es requerida y debe tener al menos 8 caracteres.
                        </div>
                      </div>
                      <button
                        class="btn btn-indigo w-100 mb-2"
                        type="submit"
                        [disabled]="activeUserForm.invalid">
                        {{ loading ? 'Activando...' : 'Activar' }}
                      </button>
                      <div class="invalid-feedback d-block mt-0 mb-2" *ngIf="error">
                        {{ error }}
                      </div>
                    </form>
                  </ng-container>
                </div>
                <div class="auth-form-box">
                  <ng-container *ngIf="showConfirmationMessage">
                    <a
                      class="d-flex flex-center text-decoration-none mb-4"
                      href="../../../index.html">
                      <div class="d-flex align-items-center d-inline-block">
                        <img
                          [src]="currentTheme === 'dark' ? './assets/img/logo/logo-icon-white.png' : './assets/img/logo/logo-icon-multi-color.png'"
                          alt="phoenix"
                          width="45"
                        />
                      </div>
                    </a>
                    <div class="text-center mb-2">
                      <h3 class="text-1000">
                        ¡Cuenta activada correctamente!
                      </h3>
                      <p class="text-700">
                        La contraseña se ha cambiado correctamente.
                      </p>
                      <p class="text-700">
                        Ahora puedes <a class="fs--0 fw-bold mt-1 cursor-pointer" (click)="changeView()"> iniciar sesión.</a>
                      </p>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
