<div class="chat d-flex phoenix-offcanvas-container pt-1 mt-n1">
  <div
    [hidden]="fromContactsComponent"
    [class.show]="showSideChatList"
    class="card chat-sidebar me-3 phoenix-offcanvas phoenix-offcanvas-start"
    [class.p-2]="isMobile"
    [class.p-1]="!isMobile"
    id="chat-sidebar">
    <app-chat-list
      [fromContactsComponent]="fromContactsComponent">
    </app-chat-list>
  </div>
  <div [hidden]="chat !== undefined"
    class="chat-content flex-1">
    <div class="card card-body h-100 d-flex justify-content-center align-items-center">
      <h2 class="text-muted shimmer">¡Hola! Da click en un chat para iniciar.</h2>
    </div>
  </div>
  <div [hidden]="chat === undefined"
    class="chat-content tab-content flex-1 w-100">
    <app-chat-content [chat]="chat" [funnels]="funnels"></app-chat-content>
  </div>
  <div
    class="phoenix-offcanvas-backdrop d-lg-none top-0"
    data-phoenix-backdrop="data-phoenix-backdrop">
  </div>
  <div class="modal fade"
       id="chatSearchBoxModal"
       tabindex="-1"
       data-bs-backdrop="true"
       data-phoenix-modal="data-phoenix-modal"
       style="--phoenix-backdrop-opacity: 1;">
    <div class="modal-dialog">
      <div class="modal-content mt-15">
        <div class="modal-body p-0">
          <div class="chat-search-box">
            <div class="form-icon-container">
              <input class="form-control py-3 form-icon-input rounded-1"
                     type="text" autofocus="autofocus"
                     placeholder="Search People, Groups and Messages" />
              <span class="fa-solid fa-magnifying-glass fs--1 form-icon"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>