import { MessageType } from '@type/chat/message-content.type';

export class QuickReplyItemRequest {

  id: number;
  deleted: boolean;
  content: string;
  type: MessageType;
  fileKey: string;
  fileUrl: string;
  fileName: string;
  fileContentType: string;
  fileSize: number;

  constructor(id: number, type: MessageType) {
    this.id = id;
    this.type = type;
  }

}
