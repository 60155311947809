import { WhatsAppMessageTemplateRequest } from "./whatsapp-message-template-request.dto";
import { FileMetadata } from '../../media/file-metadata.dto';

export class UpdateWhatsAppMessageTemplateRequest {

  shopId: number;
  messageTemplateId: string;
  messageTemplate: WhatsAppMessageTemplateRequest;
  fileMetadata: FileMetadata;

}