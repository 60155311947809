
export class RemoveChatTagRequest {

  chatTagId: number;
  chatId: number;

  constructor(
    chatTagId: number,
    chatId: number,
  ) {
    this.chatTagId = chatTagId;
    this.chatId = chatId;
  }

}
