import { QuickReplyItemRequest } from './item/quick-reply-item-request.dto';
import { QuickReplyButtonInfo } from '@models/chat/quick-reply/item/quick-reply-button-info.dto';

export class QuickReplyRequest {

  id: number;
  shopId: number;
  name: string;
  items: QuickReplyItemRequest[];
  buttons: QuickReplyButtonInfo[];

  constructor(shopId: number, id: number, name: string, items: QuickReplyItemRequest[], buttons: QuickReplyButtonInfo[]) {
    this.shopId = shopId;
    this.id = id;
    this.name = name;
    this.items = items;
  }

}
