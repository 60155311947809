import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { Subscription } from 'rxjs';
import { ChatInfo } from '@app/models/chat/chat/chat-info.dto';
import { MessageInfo } from '@app/models/chat/message/message-info.dto';
import { EventEmitterService, NotificationTopic } from '@app/services/data/event-emitter.service';
import { MessageType } from '@app/type/chat/message-content.type';
import { MessageStatus } from '@type/chat/message-status.type';
import { emojiMartI18n } from '@constants/emoji-mart-constants';
import { MessageService } from '@services/chat/message.service';
import { HighlightMessageRequest } from '@models/chat/message/highlight-message-request.dto';

@Component({
  selector: 'app-chat-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css'],
})
export class MessageComponent implements OnInit, AfterViewInit, OnDestroy {

  public MessageType = MessageType;
  public MessageStatus = MessageStatus;

  @Output()
  public messageReady: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  public message: MessageInfo;

  @Input()
  public chat: ChatInfo;

  @ViewChild('reactPopover')
  private popover: any;

  private componentSubscriptions: Subscription[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private eventEmitterService: EventEmitterService,
    private messageService: MessageService,
  ) {

  }

  ngOnInit() {
    this.initializeMessageHighlightedSubscription();
    if (
      this.message.type !== MessageType.Audio && this.message.type !== MessageType.Video) {
      this.setMessageReady();
    }
  }

  public setMessageReady() {
    this.messageReady.emit();
  }

  private initializeMessageHighlightedSubscription() {

    const eventEmitter = this.eventEmitterService.getEventEmitter(
      NotificationTopic.RemoveMessageHighlight,
    );

    const subscription = eventEmitter.subscribe((message: MessageInfo) => {
      if (message.id === this.message.id) {
        this.message.highlighted = message.highlighted;
      }
    });

    this.componentSubscriptions.push(subscription);
  }

  ngAfterViewInit() {

  }

  public emitReplyEvent() {
    this.eventEmitterService.emit(
      NotificationTopic.MessageReply,
      this.message,
    );
  }

  private emitReactionEvent(emoji: string) {
    const request = {
      messageInfo: this.message,
      emoji: emoji,
    };
    this.eventEmitterService.emit(
      NotificationTopic.MessageReaction,
      request,
    );
  }

  public selectEmoji(event: any) {
    this.emitReactionEvent(event.emoji.native);
    this.popover.close();
  }

  openEmojiPopover() {
    console.log('popover open');
    //this.popover.open();
    this.cdr.detectChanges();
  }


  ngOnDestroy() {
    this.componentSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  protected readonly Object = Object;
  protected readonly emojiMartI18n = emojiMartI18n;

  //HIGHTLIGHT MESSAGE

  public highlightMessage(message: MessageInfo) {

    const request: HighlightMessageRequest = {
      messageId: message.id,
      highlighted: !message.highlighted,
    };

    this.messageService.highlightMessage(request).subscribe({
      next: (response: MessageInfo) => {
        message.highlighted = !message.highlighted;
        this.eventEmitterService.emit(NotificationTopic.MessageHighlighted, message);
      },
      error: (error) => {
        console.error(error);
      },
    });


  }
}
