// Angular
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

// Toastr
import { ToastrService } from "ngx-toastr";

// RxJS
import {
  debounceTime,
  distinctUntilChanged,
  firstValueFrom,
  of,
  Subscription,
  switchMap,
} from "rxjs";

// Message Template Models
import { WhatsAppMessageTemplateInfo } from "@app/models/chat/whatsapp-message-template/whatsapp-message-template-info.dto";
import { SearchWhatsAppMessageTemplateRequest } from "@app/models/chat/whatsapp-message-template/search-whatsapp-message-template-request.dto";

import { UserInfo } from "@app/models/account/user/user.info.dto";

// Services
import { AuthService } from "@app/services/auth/auth.service";
import { MessageTemplateService } from "@app/services/chat/message-template.service";

import {
  MessageType,
  MessageTypeMetadata,
} from '@type/chat/message-content.type';

import { getTypeByValue, getTypes } from '@app/utils/enum-utils';
import { Fancybox } from "@fancyapps/ui";
import { SpinnerService } from '@services/data/spinner.service';

import { DeleteWhatsAppMessageTemplateRequest } from "@app/models/chat/whatsapp-message-template/delete-whatsapp-message-template-request.dto";

import {
  WhatsAppMessageTemplateType,
  WhatsAppMessageTemplateTypeMetadata,
} from "@app/models/chat/whatsapp-message-template/type/whatsapp-message-template.type";

import { messageTemplateLanguages } from "@app/constants/message-template-languages-constants";
import { WhatsappMessageTemplateLanguage } from "@app/models/chat/whatsapp-message-template/type/whatsapp-message-template-language.dto";
import { WhatsAppMessageTemplateComponentType } from "@app/models/chat/whatsapp-message-template/type/whatsapp-message-template-component.type";
import { WhatsAppMessageTemplateComponentInfo } from "@app/models/chat/whatsapp-message-template/whatsapp-message-template-component-info.dto";
import { WhatsAppMessageTemplateComponentHeaderType } from "@app/models/chat/whatsapp-message-template/type/whatsapp-message-template-component-header.type";
import { CallToActionType } from "@app/models/chat/whatsapp-message-template/ui/type/call-to-action.type";
import { WhatsAppMessageTemplateButton } from "@app/models/chat/whatsapp-message-template/whatsapp-message-template-button.dto";
import { CustomButton } from "@app/models/chat/whatsapp-message-template/ui/custom-button.dto";
import { CallToActionButton } from "@app/models/chat/whatsapp-message-template/ui/call-to-action-button.dto";
import {
  EventEmitterService,
  NotificationTopic,
} from "@app/services/data/event-emitter.service";
import { WhatsAppMessageTemplateButtonType } from "../../../models/chat/whatsapp-message-template/type/whatsapp-message-template-button.type";
import { CallToActionUrlType } from "../../../models/chat/whatsapp-message-template/ui/type/call-to-action-url.type";
import { CreateWhatsAppMessageTemplateRequest } from "../../../models/chat/whatsapp-message-template/create-whatsapp-message-template-request.dto";
import { WhatsAppMessageTemplateRequest } from "../../../models/chat/whatsapp-message-template/whatsapp-message-template-request.dto";
import { WhatsAppMessageTemplateComponentRequest } from "../../../models/chat/whatsapp-message-template/whatsapp-message-template-component-request.dto";
import { FileMetadata } from "../../../models/media/file-metadata.dto";
import { ShopInfo } from "../../../models/account/shop/shop-info.dto";
import { GetSignedUrlRequest } from "../../../models/media/get-signed-url-request.dto";
import { MediaService } from "../../../services/media/media.service";
import { HttpErrorResponse } from "@angular/common/http";
import {
  UpdateWhatsAppMessageTemplateRequest
} from '../../../models/chat/whatsapp-message-template/update-whatsapp-message-template-request.dto';

@Component({
  selector: "app-message-templates",
  templateUrl: "./message-templates.component.html",
  styleUrls: ["./message-templates.component.css"],
})
export class MessageTemplatesComponent implements OnInit, AfterViewInit, OnDestroy {

  // Enums
  public WhatsAppMessageTemplateType = WhatsAppMessageTemplateType;
  public WhatsAppMessageTemplateTypes = getTypes(WhatsAppMessageTemplateType, WhatsAppMessageTemplateTypeMetadata);

  public MessageType = MessageType;
  public MessageTypes = getTypes(MessageType, MessageTypeMetadata);
  // Enums

  // Form & Validation
  public messageTemplateForm: FormGroup;
  public duplicatedName: boolean = false;
  public sameName: boolean = false;
  public checkingDuplicatedName: string = 'before';
  // Form & Validation

  // Component Data
  private user: UserInfo;
  private componentSubscriptions: Subscription[] = [];
  // Component Data

  // State & Data
  // public messageTemplatePreview: MessageInfo;
  public whatsAppMessageTemplate: WhatsAppMessageTemplateInfo;

  // Buttons
  public callToActionButtons: CallToActionButton[] = [];
  public customButtons: CustomButton[] = [];

  public selectedMessageTemplateLanguage: WhatsappMessageTemplateLanguage;
  public savingMessageTemplate: boolean = false;
  public firstLoad: boolean = true;
  public loadingMessageTemplates: boolean = false;
  public messageTemplates: WhatsAppMessageTemplateInfo[] = [];
  private searchRequest: SearchWhatsAppMessageTemplateRequest;
  public searchMessageTemplateFormControl: FormControl = new FormControl('');

  public messageTemplateLanguages = messageTemplateLanguages;
  // State & Data

  // Modals

  constructor(
    private elRef: ElementRef,
    private toastr: ToastrService,
    private messageTemplateService: MessageTemplateService,
    private mediaService: MediaService,
    private authService: AuthService,
    private spinnerService: SpinnerService,
    private eventEmitterService: EventEmitterService,
  ) {
    this.user = this.authService.getUser();
  }

  ngOnInit() {
    this.initializeMessageTemplateSearchRequest();
    this.initializeForm();
    this.initializeSearchMessageTemplateFormControl();
    this.initializeTemplateButtonsChangeEvent();
    this.loadMessageTemplates();
  }

  ngAfterViewInit() {

    Fancybox.bind(this.elRef.nativeElement, "[data-fancybox]", {
      // Custom options
    });

  }

  ngOnDestroy() {
    this.componentSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  private initializeForm() {

    this.messageTemplateForm = new FormGroup({
      category: new FormControl(WhatsAppMessageTemplateType.Marketing, [Validators.required]),
      name: new FormControl("", [Validators.required]),
    });

    this.whatsAppMessageTemplate = undefined;

    this.callToActionButtons = [];
    this.customButtons = [];

    this.checkingDuplicatedName = 'before';
    this.duplicatedName = false;
    this.sameName = false;

    this.initializeMessageTemplateNameValidator();
  }

  private initializeMessageTemplateSearchRequest() {
    this.searchRequest = new SearchWhatsAppMessageTemplateRequest(this.user.shop.id);
  }

  private initializeMessageTemplateNameValidator() {

    this.messageTemplateForm.get("name").valueChanges.pipe(
      switchMap((query) => {

        const formattedText = query
          .toLowerCase()
          .replace(/[áàä]/g, 'a')
          .replace(/[éèë]/g, 'e')
          .replace(/[íìï]/g, 'i')
          .replace(/[óòö]/g, 'o')
          .replace(/[úùü]/g, 'u')
          .replace(/[ñ]/g, 'n')
          .replace(/[^\w\s]/g, '')
          .replace(/\s+/g, '_');

        this.messageTemplateForm.get('name').setValue(formattedText, { emitEvent: false });

        return of(formattedText);
      }),
      debounceTime(0),
      distinctUntilChanged(),
    ).subscribe((name: string) => {
      this.checkMessageTemplateNameExistence(name);
    });
  }

  private initializeSearchMessageTemplateFormControl() {
    this.searchMessageTemplateFormControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((query) => {
          return of(query.trim());
        })
      )
      .subscribe((searchTerm: string) => {
        this.searchRequest.searchTerm = searchTerm;
        this.loadMessageTemplates();
      });
  }

  private initializeTemplateButtonsChangeEvent() {

    const subscription = this.eventEmitterService.getEventEmitter(NotificationTopic.MessageTemplateButtonsChange)
      .subscribe(() => {
        this.setButtons();
      });

    this.componentSubscriptions.push(subscription);
  }

  private loadMessageTemplates() {

    this.loadingMessageTemplates = true;

    this.spinnerService.show('Cargando plantillas de mensaje...');

    this.messageTemplateService.searchWhatsAppMessageTemplates(this.searchRequest).subscribe({
      next: (messageTemplates: WhatsAppMessageTemplateInfo[]) => {
        this.messageTemplates = messageTemplates;
        this.loadingMessageTemplates = false;
        if (this.firstLoad) this.firstLoad = false;
        this.spinnerService.hide();
      },
      error: (error: any) => {
        this.spinnerService.hide();
        this.firstLoad = false;
        console.error("Error en la carga de message templates:", error);
      },
      complete: () => {},
    });
  }

  public changeMessageTemplateCategory(event: any) {

    const messageTemplateType = getTypeByValue(WhatsAppMessageTemplateType, event.target.value);
    this.whatsAppMessageTemplate.category = messageTemplateType;

    switch (messageTemplateType) {
      case WhatsAppMessageTemplateType.Marketing:
        break;
      case WhatsAppMessageTemplateType.Utility:
        break;
      case WhatsAppMessageTemplateType.Authentication:
        break;
    }
  }

  public selectMessageTemplateLanguage(language: WhatsappMessageTemplateLanguage) {
    this.selectedMessageTemplateLanguage = language;
    this.whatsAppMessageTemplate.language = language.code;
  }

  public openSaveMessageTemplate(messageTemplate?: WhatsAppMessageTemplateInfo) {

    this.savingMessageTemplate = true;

    if (this.whatsAppMessageTemplate && this.whatsAppMessageTemplate.id === messageTemplate?.id)
      return;

    this.resetSaveMessageTemplate();

    if (messageTemplate) {

      this.updateSelectedMessageTemplate(messageTemplate);

      this.whatsAppMessageTemplate = messageTemplate;

      this.messageTemplateForm.get("name").setValue(messageTemplate.name);
      this.messageTemplateForm.get("category").setValue(messageTemplate.category.toLowerCase());

      this.messageTemplateForm.get("name").disable();
      this.messageTemplateForm.get("category").disable();

    } else {

      this.whatsAppMessageTemplate = new WhatsAppMessageTemplateInfo();
      this.whatsAppMessageTemplate.category = WhatsAppMessageTemplateType.Marketing;
      this.messageTemplateForm.get("name").setValue("");
    }

  }

  public closeSaveMessageTemplate() {
    this.loadMessageTemplates();
    this.initializeForm();
    this.savingMessageTemplate = false;
  }

  public resetSaveMessageTemplate() {
    this.initializeForm();
    this.savingMessageTemplate = true;
  }

  public deleteMessageTemplate(messageTemplate: WhatsAppMessageTemplateInfo) {

    if (confirm("¿Está seguro que desea eliminar la plantilla de mensaje?")) {

      this.savingMessageTemplate = false;

      const deleteWhatsAppMessageTemplateRequest = new DeleteWhatsAppMessageTemplateRequest(
        this.user.shop.id, messageTemplate.id, messageTemplate.name
      );

      this.messageTemplateService.deleteWhatsAppMessageTemplate(
        deleteWhatsAppMessageTemplateRequest
      ).subscribe({
        next: () => {
          this.loadMessageTemplates();
          this.toastr.success("Plantilla de mensaje eliminada exitosamente");
        },
        error: (error: any) => {
          console.error("Error al eliminar la plantilla de mensaje:", error);
          this.toastr.error("Error al eliminar la plantilla de mensaje");
        },
        complete: () => {},
      });

    }

  }

  public checkMessageTemplateNameExistence(name: string) {

    if (!name || name.length === 0) {
      this.duplicatedName = false;
      this.setNameText('');
      return;
    }

    if (this.whatsAppMessageTemplate && this.whatsAppMessageTemplate.name === name) {
      this.duplicatedName = false;
      this.sameName = true;
      return;
    }

    this.sameName = false;

    this.checkingDuplicatedName = "checking";
    const afterChecking = "after-checking";

    const messageTemplate = this.messageTemplates.find(template => template.name === name);

    if (messageTemplate) {
      this.duplicatedName = true;
      this.checkingDuplicatedName = afterChecking;
    } else {
      this.duplicatedName = false;
      this.checkingDuplicatedName = afterChecking;
      this.setNameText(name);
    }

  }

  public async saveMessageTemplate() {
    if (!this.whatsAppMessageTemplate.id) {
      await this.createMessageTemplate();
    } else {
      await this.updateMessageTemplate();
    }
  }

  private async createMessageTemplate() {

    const {
      name,
      language,
      headerComponent,
      bodyComponent,
      footerComponent,
      buttonsComponent,
      category,
      file,
    } = this.whatsAppMessageTemplate;

    const createWhatsAppMessageTemplateRequest = new CreateWhatsAppMessageTemplateRequest();
    createWhatsAppMessageTemplateRequest.shopId = this.user.shop.id;

    const whatsAppMessageTemplateRequest = new WhatsAppMessageTemplateRequest();

    whatsAppMessageTemplateRequest.name = name;
    whatsAppMessageTemplateRequest.language = language;
    whatsAppMessageTemplateRequest.category = category;
    whatsAppMessageTemplateRequest.components = [];

    if (headerComponent) {

      const whatsAppMessageTemplateComponentRequest = new WhatsAppMessageTemplateComponentRequest();
      whatsAppMessageTemplateComponentRequest.type = WhatsAppMessageTemplateComponentType.Header;
      whatsAppMessageTemplateComponentRequest.format = headerComponent.format;

      switch (headerComponent.format) {
        case WhatsAppMessageTemplateComponentHeaderType.Text:
          whatsAppMessageTemplateComponentRequest.text = headerComponent.text;
          break;
        case WhatsAppMessageTemplateComponentHeaderType.Image:
        case WhatsAppMessageTemplateComponentHeaderType.Video:
        case WhatsAppMessageTemplateComponentHeaderType.Document:

          const fileMetadata = new FileMetadata();
          const getSignedUrlResponse = await this.uploadHeaderFile(file, this.user.shop);

          fileMetadata.uuid = getSignedUrlResponse.uuid;
          fileMetadata.fileUrl = getSignedUrlResponse.publicUrl;
          fileMetadata.fileKey = getSignedUrlResponse.key;
          fileMetadata.fileName = file.name;
          fileMetadata.fileSize = file.size;
          fileMetadata.fileContentType = file.type;

          createWhatsAppMessageTemplateRequest.fileMetadata = fileMetadata;

          headerComponent.example.header_handle = [fileMetadata.fileUrl];

          break;
        case WhatsAppMessageTemplateComponentHeaderType.Location:
          break;
      }
      whatsAppMessageTemplateRequest.components.push(headerComponent);
    }

    if (bodyComponent) {
      const whatsAppMessageTemplateComponentRequest = new WhatsAppMessageTemplateComponentRequest();
      whatsAppMessageTemplateComponentRequest.type = WhatsAppMessageTemplateComponentType.Body;
      whatsAppMessageTemplateComponentRequest.text = bodyComponent.text;
      whatsAppMessageTemplateRequest.components.push(bodyComponent);
    }

    if (footerComponent) {
      const whatsAppMessageTemplateComponentRequest = new WhatsAppMessageTemplateComponentRequest();
      whatsAppMessageTemplateComponentRequest.type = WhatsAppMessageTemplateComponentType.Footer;
      whatsAppMessageTemplateComponentRequest.text = footerComponent.text;
      whatsAppMessageTemplateRequest.components.push(footerComponent);
    }

    if (buttonsComponent) {
      const whatsAppMessageTemplateComponentRequest = new WhatsAppMessageTemplateComponentRequest();
      whatsAppMessageTemplateComponentRequest.type = WhatsAppMessageTemplateComponentType.Buttons;
      whatsAppMessageTemplateComponentRequest.buttons = buttonsComponent.buttons;
      whatsAppMessageTemplateRequest.components.push(buttonsComponent);
    }

    createWhatsAppMessageTemplateRequest.messageTemplate = whatsAppMessageTemplateRequest;

    this.messageTemplateService.createWhatsAppWhatsAppMessageTemplate(createWhatsAppMessageTemplateRequest)
      .subscribe({
        next: () => {
          this.closeSaveMessageTemplate();
          this.toastr.success("Plantilla de mensaje creada exitosamente");
        },
        error: (httpErrorResponse: HttpErrorResponse) => {
          const { error } = httpErrorResponse;
          const errorCode = error.code;

          if (errorCode === "DUPLICATED_MESSAGE_TEMPLATE") {
            this.duplicatedName = true;
          }
        },
        complete: () => {},
      });

  }

  private async updateMessageTemplate() {

    const {
      name,
      language,
      headerComponent,
      bodyComponent,
      footerComponent,
      buttonsComponent,
      category,
      file,
      attachments,
    } = this.whatsAppMessageTemplate;

    const updateWhatsAppMessageTemplateRequest = new UpdateWhatsAppMessageTemplateRequest();
    updateWhatsAppMessageTemplateRequest.messageTemplateId = this.whatsAppMessageTemplate.id;
    updateWhatsAppMessageTemplateRequest.shopId = this.user.shop.id;

    const whatsAppMessageTemplateRequest = new WhatsAppMessageTemplateRequest();

    whatsAppMessageTemplateRequest.name = name;
    whatsAppMessageTemplateRequest.language = language;
    whatsAppMessageTemplateRequest.category = category;
    whatsAppMessageTemplateRequest.components = [];

    if (headerComponent) {

      const whatsAppMessageTemplateComponentRequest = new WhatsAppMessageTemplateComponentRequest();
      whatsAppMessageTemplateComponentRequest.type = WhatsAppMessageTemplateComponentType.Header;
      whatsAppMessageTemplateComponentRequest.format = headerComponent.format;

      switch (headerComponent.format) {
        case WhatsAppMessageTemplateComponentHeaderType.Text:
          whatsAppMessageTemplateComponentRequest.text = headerComponent.text;
          break;
        case WhatsAppMessageTemplateComponentHeaderType.Image:
        case WhatsAppMessageTemplateComponentHeaderType.Video:
        case WhatsAppMessageTemplateComponentHeaderType.Document:

          const fileMetadata = new FileMetadata();

          if (file) {

            const getSignedUrlResponse = await this.uploadHeaderFile(file, this.user.shop);

            fileMetadata.uuid = getSignedUrlResponse.uuid;
            fileMetadata.fileUrl = getSignedUrlResponse.publicUrl;
            fileMetadata.fileKey = getSignedUrlResponse.key;
            fileMetadata.fileName = file.name;
            fileMetadata.fileSize = file.size;
            fileMetadata.fileContentType = file.type;

          } else {

            const attachment = attachments[0];

            fileMetadata.fileUrl = attachment.fileUrl;
            fileMetadata.fileKey = attachment.fileKey;
            fileMetadata.fileName = attachment.fileName;
            fileMetadata.fileSize = attachment.fileSize;
            fileMetadata.fileContentType = attachment.fileContentType;
          }

          updateWhatsAppMessageTemplateRequest.fileMetadata = fileMetadata;

          if (!headerComponent.example) headerComponent.example = { header_handle: [] };

          headerComponent.example.header_handle = [fileMetadata.fileUrl];

          break;
        case WhatsAppMessageTemplateComponentHeaderType.Location:
          break;
      }
      whatsAppMessageTemplateRequest.components.push(headerComponent);
    }

    if (bodyComponent) {
      const whatsAppMessageTemplateComponentRequest = new WhatsAppMessageTemplateComponentRequest();
      whatsAppMessageTemplateComponentRequest.type = WhatsAppMessageTemplateComponentType.Body;
      whatsAppMessageTemplateComponentRequest.text = bodyComponent.text;
      whatsAppMessageTemplateRequest.components.push(bodyComponent);
    }

    if (footerComponent) {
      const whatsAppMessageTemplateComponentRequest = new WhatsAppMessageTemplateComponentRequest();
      whatsAppMessageTemplateComponentRequest.type = WhatsAppMessageTemplateComponentType.Footer;
      whatsAppMessageTemplateComponentRequest.text = footerComponent.text;
      whatsAppMessageTemplateRequest.components.push(footerComponent);
    }

    if (buttonsComponent) {
      const whatsAppMessageTemplateComponentRequest = new WhatsAppMessageTemplateComponentRequest();
      whatsAppMessageTemplateComponentRequest.type = WhatsAppMessageTemplateComponentType.Buttons;
      whatsAppMessageTemplateComponentRequest.buttons = buttonsComponent.buttons;
      whatsAppMessageTemplateRequest.components.push(buttonsComponent);
    }

    updateWhatsAppMessageTemplateRequest.messageTemplate = whatsAppMessageTemplateRequest;

    this.messageTemplateService.updateWhatsAppWhatsAppMessageTemplate(updateWhatsAppMessageTemplateRequest)
      .subscribe({
        next: () => {
          this.closeSaveMessageTemplate();
          this.toastr.success("Plantilla de mensaje actualizada exitosamente");
        },
        error: (httpErrorResponse: HttpErrorResponse) => {
          const { error } = httpErrorResponse;
          const errorCode = error.code;
          if (errorCode === "TOO_EARLY_TO_RE_EDIT") {
            this.toastr.info("El periodo de espera es de 24 horas.");
            this.toastr.error("No se puede editar la plantilla de mensaje en este momento, la editó hace poco.");
          }
        },
        complete: () => {},
      });

  }

  public async uploadHeaderFile(file: File, shop: ShopInfo) {

    const prefix = `${shop.uuid}`;
    const getSignedUrlRequest = new GetSignedUrlRequest(prefix, file.name, file.type);
    const getSignedUrlResponse = await firstValueFrom(this.messageTemplateService.generateMessageTemplateSignedUrl(getSignedUrlRequest));
    const { signedUrl } = getSignedUrlResponse;

    await firstValueFrom(this.mediaService.uploadMedia(signedUrl, file));

    return getSignedUrlResponse;
  }

  public setNameText(text: string) {
    this.whatsAppMessageTemplate.name = text;
    if (text.length === 0) this.whatsAppMessageTemplate.name = undefined;
  }

  public setHeaderText(text: string) {

    let { headerComponent } = this.whatsAppMessageTemplate;

    if (!headerComponent) {

      headerComponent = new WhatsAppMessageTemplateComponentInfo();
      headerComponent.type = WhatsAppMessageTemplateComponentType.Header;
      headerComponent.format = WhatsAppMessageTemplateComponentHeaderType.Text;

      this.whatsAppMessageTemplate.headerComponent = headerComponent;
    }

    headerComponent.text = text;

    if (text.length === 0)  {
      this.whatsAppMessageTemplate.headerComponent = undefined;
      return;
    }

    // this.messageTemplatePreview.messageTemplateHeaderContent = text;
  }

  public setBodyText(text: string) {

    let { bodyComponent } = this.whatsAppMessageTemplate;

    if (!bodyComponent) {

      bodyComponent = new WhatsAppMessageTemplateComponentInfo();
      bodyComponent.type = WhatsAppMessageTemplateComponentType.Body;

      this.whatsAppMessageTemplate.bodyComponent = bodyComponent;
    }

    bodyComponent.text = text;

    if (text.length === 0)  {
      this.whatsAppMessageTemplate.bodyComponent = undefined;
      return;
    }

  }

  public setFooterText(text: string) {

    let { footerComponent } = this.whatsAppMessageTemplate;

    if (!footerComponent) {
      footerComponent = new WhatsAppMessageTemplateComponentInfo();
      footerComponent.type = WhatsAppMessageTemplateComponentType.Footer;
      this.whatsAppMessageTemplate.footerComponent = footerComponent;
    }

    footerComponent.text = text;

    if (text.length === 0)  {
      this.whatsAppMessageTemplate.footerComponent = undefined;
      return;
    }
  }

  public setButtons() {

    const buttonsComponent = new WhatsAppMessageTemplateComponentInfo();

    buttonsComponent.buttons = [];
    buttonsComponent.type = WhatsAppMessageTemplateComponentType.Buttons;

    for (const callToActionButton of this.callToActionButtons) {

      const whatsAppMessageTemplateButton = new WhatsAppMessageTemplateButton();
      whatsAppMessageTemplateButton.text = callToActionButton.text;

      switch (callToActionButton.type) {
        case CallToActionType.GoToWebSite:
          whatsAppMessageTemplateButton.type = WhatsAppMessageTemplateButtonType.Url;
          whatsAppMessageTemplateButton.url = callToActionButton.websiteUrl;
          if (callToActionButton.urlType === CallToActionUrlType.Dynamic) {
            whatsAppMessageTemplateButton.example = callToActionButton.example;
          }
          break;
        case CallToActionType.CallToPhoneNumber:
          whatsAppMessageTemplateButton.type = WhatsAppMessageTemplateButtonType.PhoneNumber;
          whatsAppMessageTemplateButton.phone_number = callToActionButton.countryCallingCode + callToActionButton.phoneNumber;
          break;
        case CallToActionType.CallToWhatsAppNumber:
          // this is not included in whatsapp business management api
          whatsAppMessageTemplateButton.type = WhatsAppMessageTemplateButtonType.PhoneNumber;
          break;
        case CallToActionType.CopyOfferCode:
          whatsAppMessageTemplateButton.type = WhatsAppMessageTemplateButtonType.CopyCode;
          whatsAppMessageTemplateButton.example = callToActionButton.offerCode;
          break;
      }

      buttonsComponent.buttons.push(whatsAppMessageTemplateButton);
    }

    for (const customButton of this.customButtons) {

      const whatsAppMessageTemplateButton = new WhatsAppMessageTemplateButton();
      whatsAppMessageTemplateButton.text = customButton.text;
      whatsAppMessageTemplateButton.type = WhatsAppMessageTemplateButtonType.QuickReply;

      buttonsComponent.buttons.push(whatsAppMessageTemplateButton);
    }

    this.whatsAppMessageTemplate.buttonsComponent = buttonsComponent;
  }

  private updateSelectedMessageTemplate(messageTemplate: WhatsAppMessageTemplateInfo) {

    const headerComponent = messageTemplate.components.find(c => c.type === WhatsAppMessageTemplateComponentType.Header);
    const bodyComponent = messageTemplate.components.find(c => c.type === WhatsAppMessageTemplateComponentType.Body);
    const footerComponent = messageTemplate.components.find(c => c.type === WhatsAppMessageTemplateComponentType.Footer);
    const buttonsComponent = messageTemplate.components.find(c => c.type === WhatsAppMessageTemplateComponentType.Buttons);

    if (headerComponent) {
      messageTemplate.headerComponent = headerComponent;
    }

    if (bodyComponent) {
      messageTemplate.bodyComponent = bodyComponent;
    }

    if (footerComponent) {
      messageTemplate.footerComponent = footerComponent;
    }

    if (buttonsComponent) {
      messageTemplate.buttonsComponent = buttonsComponent;
    }

    messageTemplate.languageValue = this.messageTemplateLanguages.find(language => language.code === messageTemplate.language);
    messageTemplate.category = messageTemplate.category.toLowerCase();
    // console.log(JSON.stringify(messageTemplate, null, 2));
  }

}
