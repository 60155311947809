
export class ChangeChatSmartAssistantStatusRequest {

  chatId: number;
  smartAssistantStatus: boolean;

  constructor(chatId: number, smartAssistantStatus: boolean) {
    this.chatId = chatId;
    this.smartAssistantStatus = smartAssistantStatus;
  }

}