import { Component } from "@angular/core";

@Component({
  selector: "app-preloader",
  template: `
    <div class="preloader-container" [class.hidden]="hidden">
      <div class="preloader"></div>
    </div>
  `,
  styleUrls: ["./preloader.component.css"],
})
export class PreloaderComponent {

  public hidden: boolean = false;

  constructor() {

  }

}