import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MessengerConfigInfo } from '@models/config/messenger/messenger-config-info.dto';
import { SaveMessengerConfigRequest } from '@models/config/messenger/save-messenger-config-request.dto';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class MessengerConfigService {

  private apiUrl: string = `${environment.apiUrl}/config/messenger`;

  constructor(
    private http: HttpClient,
  ) {

  }

  getConfig(): Observable<MessengerConfigInfo[]> {
    const endpoint = `${this.apiUrl}/get`;
    return this.http.get<MessengerConfigInfo[]>(endpoint);
  }

  saveConfig(saveMessengerConfigRequest: SaveMessengerConfigRequest): Observable<any> {
    const endpoint = `${this.apiUrl}/save`;
    return this.http.post<any>(endpoint, saveMessengerConfigRequest);
  }

  removeConfig(): Observable<any> {
    const endpoint = `${this.apiUrl}/remove`;
    return this.http.delete<any>(endpoint);
  }

}