<form
  [formGroup]="messageTemplateFooterForm">
  <div class="row mb-3 border-bottom border-dashed border-300">
    <div class="col-12">
      <div class="row mb-1">
        <div class="col">
          <h4>
            Pie de página
            <span
              role="button"
              class="badge badge-phoenix badge-phoenix-info fs--2">
              Opcional
            </span>
          </h4>
          <p class="mb-1 fs--1">
            Agrega una breve línea de texto en la parte inferior de la plantilla de mensaje.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-floating">
            <input
              class="form-control"
              id="footerText"
              type="text"
              maxlength="60"
              placeholder="Pie de la plantilla de mensaje"
              formControlName="footerText"
              [ngClass]="{
                'is-invalid': (messageTemplateFooterForm.get('footerText').touched && messageTemplateFooterForm.get('footerText').invalid)
              }">
            <label for="footerText">
              Escribir un texto en Spanish (MEX)
            </label>
            <div
              *ngIf="messageTemplateFooterForm.get('footerText').touched && messageTemplateFooterForm.get('footerText').invalid"
              class="invalid-feedback text-warning">
              Ingrese el texto del pie de la plantilla de mensaje, por favor.
            </div>
          </div>
          <p class="fs--1 pt-1">
            {{ messageTemplateFooterForm.get('footerText')?.value.length }} de 60 caracteres
          </p>
        </div>
      </div>
    </div>
  </div>
</form>
