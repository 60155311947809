import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { WhatsAppConfigInfo } from '@models/config/whatsapp/whatsapp-config-info.dto';
import { UserInfo } from '@models/account/user/user.info.dto';
import { WhatsAppConfigService } from '@services/config/whatsapp-config.service';
import { AuthService } from '@services/auth/auth.service';
import { SaveWhatsAppConfigRequest } from '@models/config/whatsapp/save-whatsapp-config-request.dto';
import { environment } from '@env/environment';
import { SpinnerService } from '@services/data/spinner.service';
import { SaveAutomaticWhatsAppConfigRequest } from '@models/config/whatsapp/save-automatic-whatsapp-config-request.dto';
import { WhatsappConfigurationType } from '@type/communication/whatsapp-configuration.type';
import { WhatsappWebInstanceStatusInfo } from '@models/chat/whatsapp-web/instance/whatsapp-web-instance-status-info.dto';
import { WhatsappWebStatusType } from '@type/chat/whatsapp-web/whatsapp-web-status.type';
import { EventEmitterService, NotificationTopic } from '@services/data/event-emitter.service';
import { Subscription } from 'rxjs';
import { WhatsappWebInstanceQRCodeInfo } from '@models/chat/whatsapp-web/instance/whatsapp-web-instance-qr-code-info.dto';
import { ChannelType } from '@type/communication/channel.type';

declare var FB: any;

@Component({
  selector: 'app-whatsapp-config',
  templateUrl: './whatsapp-config.component.html',
  styleUrls: ['./whatsapp-config.component.css'],
})
export class WhatsAppConfigComponent implements OnInit, OnDestroy {

  public ChannelType = ChannelType;
  public WhatsAppConfigurationType = WhatsappConfigurationType;
  public WhatsappWebStatusType = WhatsappWebStatusType;

  public whatsAppConfigForm: FormGroup;
  public loading: boolean = false;
  public errorCode: string = '';

  private whatsAppAuthCode: string;
  private wabaId: string;
  private phoneNumberId: string;

  public whatsAppConfig: WhatsAppConfigInfo;

  public whatsappWebInstanceStatus: WhatsappWebInstanceStatusInfo;
  public whatsappWebInstanceQRCode: WhatsappWebInstanceQRCodeInfo;
  public disconnected: boolean = false;

  private user: UserInfo;
  private sessionInfoListener: any;

  private readonly componentSubscriptions: Subscription[] = [];

  public activeTab: 'whatsapp-web' | 'quick-cloud' | 'manual' = 'whatsapp-web';

  constructor(
    private whatsAppConfigService: WhatsAppConfigService,
    private eventEmitterService: EventEmitterService,
    private spinnerService: SpinnerService,
    private authService: AuthService,
    private toastr: ToastrService,
  ) {
    this.user = this.authService.getUser();
    this.initializeFacebookService();
  }

  ngOnInit() {
    this.initializeForm();
    this.getConfig();
    this.initializeOnWhatsAppWebInstanceQRCodeSubscription();
    this.initializeOnWhatsAppWebInstanceStatusSubscription();
  }

  private setInitialActiveTab() {
    if (this.isWhatsAppWebConnected()) {
      this.activeTab = 'whatsapp-web';
    } else if (this.isWhatsAppCloudConnected()) {
      this.activeTab = 'quick-cloud';
    } else if (this.shouldShowManual()) {
      if (!this.whatsAppConfig?.wabaId) {
        this.activeTab = 'whatsapp-web';
        return;
      }
      this.activeTab = 'manual';
    } else {
      this.activeTab = 'whatsapp-web';
    }
  }

  public setActiveTab(tab: 'whatsapp-web' | 'quick-cloud' | 'manual') {
    this.activeTab = tab;
  }
  
  ngOnDestroy() {
    if (this.sessionInfoListener) {
      window.removeEventListener('message', this.sessionInfoListener);
    }
    for (const subscription of this.componentSubscriptions) {
      subscription.unsubscribe();
    }
  }

  private initializeForm() {

    this.whatsAppConfigForm = new FormGroup({
      phoneNumber: new FormControl('', [Validators.required]),
      phoneNumberId: new FormControl('', [Validators.required]),
      accessToken: new FormControl('', [Validators.required]),
      wabaId: new FormControl('', [Validators.required]),
      appId: new FormControl('', [Validators.required]),
    });

  }

  private initializeOnWhatsAppWebInstanceQRCodeSubscription() {

    const eventEmitter = this.eventEmitterService.getEventEmitter(NotificationTopic.WhatsAppWebInstanceQRCode);

    const subscription = eventEmitter.subscribe((data: any) => {

      const whatsappWebInstanceQRCode: WhatsappWebInstanceQRCodeInfo = data as WhatsappWebInstanceQRCodeInfo;
      this.whatsappWebInstanceQRCode = whatsappWebInstanceQRCode;

    });

    this.componentSubscriptions.push(subscription);
  }

  private initializeOnWhatsAppWebInstanceStatusSubscription() {

    const eventEmitter = this.eventEmitterService.getEventEmitter(NotificationTopic.WhatsAppWebInstanceStatus);

    const subscription = eventEmitter.subscribe((data: any) => {

      const whatsappWebInstanceStatus: WhatsappWebInstanceStatusInfo = data as WhatsappWebInstanceStatusInfo;
      this.whatsappWebInstanceStatus = whatsappWebInstanceStatus;

      if (whatsappWebInstanceStatus.status === null || whatsappWebInstanceStatus.status === WhatsappWebStatusType.DISCONNECTED) {
        this.whatsappWebInstanceQRCode = undefined;
        this.disconnected = true;
      }

    });

    this.componentSubscriptions.push(subscription);
  }

  private initializeFacebookService() {

    FB.init({
      appId: environment.facebookAppId,
      cookie: true,
      xfbml: true,
      version: 'v20.0',
    });

    this.sessionInfoListener = (event: MessageEvent) => {

      if (event.origin == null) {
        return;
      }

      // Make sure the data is coming from facebook.com
      if (!event.origin.endsWith('facebook.com')) {
        return;
      }

      try {
        const data = JSON.parse(event.data);
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          // if user finishes the Embedded Signup flow
          if (data.event === 'FINISH') {
            const { phone_number_id, waba_id } = data.data;
            this.phoneNumberId = phone_number_id;
            this.wabaId = waba_id;
            console.log('Phone number ID ', phone_number_id, ' WhatsApp business account ID ', waba_id);
            this.tryToSaveAutomaticConfig();
          }
          // if user reports an error during the Embedded Signup flow
          else if (data.event === 'ERROR') {
            const { error_message } = data.data;
            console.error('error ', error_message);
          }
          // if user cancels the Embedded Signup flow
          else {
            const { current_step } = data.data;
            console.warn('Cancel at ', current_step);
          }
        }
      } catch {
        // Don’t parse info that’s not a JSON
        console.log('Non JSON Response', event.data);
      }
    };

    window.addEventListener('message', this.sessionInfoListener);

  }

  public signIn() {

    this.spinnerService.show('Conectando con WhatsApp...');

    const loginOptions: any = {
      config_id: environment.whatsAppConfigId,
      response_type: 'code',
      override_default_response_type: true,
      extras: {
        feature: 'whatsapp_embedded_signup',
        sessionInfoVersion: 3,
        setup: {},
      },
    };

    FB.login((response: any) => {

      this.spinnerService.hide();

      const { authResponse } = response;

      if (authResponse && authResponse?.code) {

        this.whatsAppAuthCode = authResponse?.code;
        this.tryToSaveAutomaticConfig();

      } else {
        console.log('El usuario no ha autorizado la conexión con Facebook Messenger');
      }

    }, loginOptions);

  }

  public disconnect(channel: ChannelType) {

    const channelDescription: string = channel === ChannelType.WhatsAppWeb ? 'WhatsApp Web' : 'WhatsApp Cloud API';

    if (confirm(`¿Estás seguro de que deseas desconectar ${channelDescription}?`)) {

      this.spinnerService.show('Desconectando WhatsApp...');

      this.whatsAppConfigService.disconnect(channel)
        .subscribe({
          next: () => {
            this.whatsAppConfigForm.reset();
            this.whatsAppConfigForm.enable();
            this.whatsAppConfig = undefined;
            this.spinnerService.hide();
            this.toastr.success('WhatsApp desconectado con éxito');
          },
          error: (error: any) => {
            this.spinnerService.hide();
            this.toastr.error('Error al desconectar WhatsApp');
            console.error('Error al desconectar WhatsApp:', error);
          },
        });

    }

  }

  public tryToSaveAutomaticConfig() {

    if (this.whatsAppAuthCode && this.phoneNumberId && this.wabaId) {

      this.spinnerService.show('Guardando configuración...');

      const saveAutomaticWhatsAppConfigRequest = new SaveAutomaticWhatsAppConfigRequest(
        this.user.shop.id,
        this.phoneNumberId,
        this.whatsAppAuthCode,
        this.wabaId,
      );

      this.whatsAppConfigService.saveAutomaticConfig(saveAutomaticWhatsAppConfigRequest)
        .subscribe({
          next: (whatsAppConfig: WhatsAppConfigInfo) => {
            this.whatsAppConfigForm.patchValue(whatsAppConfig);
            this.whatsAppConfig = whatsAppConfig;
            this.loading = false;
            this.toastr.success('Configuración guardada con éxito');
            this.spinnerService.hide();
          },
          error: (error: any) => {
            this.loading = false;
            this.toastr.error('Error al guardar la configuración');
            this.spinnerService.hide();
            this.errorCode = '';
            console.error('Error en el guardado de la configuración:', error);
          },
        });

    }

  }

  public getConfig() {

    this.loading = true;
    this.disconnected = false;

    this.whatsAppConfigService.getConfig()
      .subscribe({
        next: (whatsAppConfig: WhatsAppConfigInfo) => {

          this.loading = false;

          this.whatsappWebInstanceStatus = whatsAppConfig.whatsappWebInstanceStatus;

          this.whatsAppConfigForm.patchValue(whatsAppConfig);
          this.whatsAppConfig = whatsAppConfig;

          this.setInitialActiveTab();

          console.log('whatsAppConfig', whatsAppConfig);
          console.log('whatsappWebInstanceStatus', whatsAppConfig?.whatsappWebInstanceStatus);

          if (whatsAppConfig.configurationType === WhatsappConfigurationType.Automatic)
            this.whatsAppConfigForm.disable();
        },
        error: (error: any) => {
          this.loading = false;
          this.errorCode = error.error.code;
          console.error('Error en la carga de la configuración:', error);
        },
      });
  }

  public disconnectManual() {

    if (confirm('¿Estás seguro de que deseas desconectar WhatsApp Cloud?')) {

      this.spinnerService.show('Desconectando WhatsApp...');

      this.whatsAppConfigService.disconnectManual()
        .subscribe({
          next: () => {
            this.whatsAppConfigForm.reset();
            this.whatsAppConfigForm.enable();
            this.whatsAppConfig = undefined;
            this.spinnerService.hide();
            this.toastr.success('WhatsApp desconectado con éxito');
          },
          error: (error: any) => {
            this.spinnerService.hide();
            this.toastr.error('Error al desconectar WhatsApp');
            console.error('Error al desconectar WhatsApp:', error);
          },
        });
    }
  }

  public cancelSave() {
    this.whatsAppConfigForm.patchValue(this.whatsAppConfig);
  }

  public saveManualConfig() {

    const shopId = this.user.shop.id;

    const {
      phoneNumber,
      phoneNumberId,
      accessToken,
      wabaId,
      appId,
    } = this.whatsAppConfigForm.value;

    if (this.whatsAppConfigForm.valid) {

      this.loading = true;

      const saveWhatsAppConfigRequest = new SaveWhatsAppConfigRequest(
        shopId,
        phoneNumber,
        phoneNumberId,
        accessToken.trim(),
        wabaId,
        appId,
      );

      if (this.whatsAppConfig?.id) {
        saveWhatsAppConfigRequest.id = this.whatsAppConfig.id;
      }

      this.whatsAppConfigService.saveConfig(saveWhatsAppConfigRequest)
        .subscribe({
          next: (whatsAppConfig: WhatsAppConfigInfo) => {
            this.whatsAppConfigForm.patchValue(whatsAppConfig);
            this.whatsAppConfig = whatsAppConfig;
            this.loading = false;
            this.toastr.success('Configuración guardada con éxito');
          },
          error: (error: any) => {
            this.loading = false;
            this.toastr.error('Error al guardar la configuración');
            this.errorCode = '';
            console.error('Error en el guardado de la configuración:', error);
          },
        });
    }

  }

  public shouldShowQRCode(): boolean {

    if (this.disconnected) return false;
    if (this.whatsAppConfig?.wabaId) return false;
    
    if (this.whatsappWebInstanceStatus === undefined) return true;
    if (this.whatsappWebInstanceStatus.status === null) return true;
    if (this.whatsappWebInstanceStatus.status === WhatsappWebStatusType.DISCONNECTED) return true;
    
    return false;
  }
  public shouldShowWhatsAppCloud(): boolean {
    return !this.isWhatsAppWebConnected();
  }

  public shouldShowWhatsAppWeb(): boolean {
    return !this.whatsAppConfig?.wabaId && !this.isWhatsAppWebConnected();
  }

  public shouldShowManual(): boolean {

    if (this.isWhatsAppWebConnected()) {
      return false;
    }

    if (!this.whatsAppConfig?.wabaId) {
      return true;
    }

    return this.whatsAppConfig?.configurationType === WhatsappConfigurationType.Manual;
  }

  public isWhatsAppWebConnected(): boolean {
    return this.whatsappWebInstanceStatus?.status === WhatsappWebStatusType.CONNECTED;
  }

  public isWhatsAppCloudConnected(): boolean {
    return !!this.whatsAppConfig?.wabaId;
  }

}