import { Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { SmartAssistantInfo } from '@models/utilities/smart-assistant/smart-assistant-info.dto';
import { SmartAssistantTopicInfo } from '@models/utilities/smart-assistant/topic/smart-assistant-topic-info.dto';
import {
  SmartAssistantTopicSearchRequest
} from '@models/utilities/smart-assistant/topic/smart-assistant-topic-search-request.dto';
import { Page } from '@models/common/page';
import Sortable from 'sortablejs';
import { SmartAssistantService } from '@services/utilities/smart-assistant/smart-assistant.service';
import { SmartAssistantBoard } from '@models/utilities/smart-assistant/smart-assistant-board.dto';
import {
  SmartAssistantTopicItemInfo
} from '@models/utilities/smart-assistant/topic-item/smart-assistant-topic-item-info.dto';
import {
  UpdateSmartAssistantTopicItemRequest,
  UpdateSmartAssistantTopicItemsRequest,
} from '@models/utilities/smart-assistant/topic-item/update-smart-assistant-topic-items-request.dto';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import {
  DeleteSmartAssistantTopicItemRequest
} from '@models/utilities/smart-assistant/topic-item/delete-smart-assistant-topic-item-request.dto';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  SaveSmartAssistantTopicRequest
} from '@models/utilities/smart-assistant/topic/save-smart-assistant-topic-request.dto';
import { EventEmitterService, NotificationTopic } from '@services/data/event-emitter.service';
import { Subscription } from "rxjs";

@Component({
  selector: "app-smart-assistants-board",
  templateUrl: "./smart-assistants-board.component.html",
  styleUrls: ["./smart-assistants-board.component.css"],
})
export class SmartAssistantsBoardComponent implements OnInit, OnDestroy {

  @Input()
  public smartAssistant: SmartAssistantInfo;
  public smartAssistantTopics: SmartAssistantTopicInfo[] = [];

  // State
  public smartAssistantTopic: SmartAssistantTopicInfo;
  public smartAssistantTopicItem: SmartAssistantTopicItemInfo;

  private lastBoardSnapshot: SmartAssistantBoard;

  // Forms
  public smartAssistantTopicForm: FormGroup;

  // Modals
  @ViewChild('saveSmartAssistantTopicItemModal')
  private saveSmartAssistantTopicItemModal: TemplateRef<any>;

  // OffCanvas
  @ViewChild('saveSmartAssistantTopicOffCanvas')
  public saveSmartAssistantTopicOffCanvas: TemplateRef<any>;

  private componentSubscriptions: Subscription[] = [];

  constructor(
    private readonly eventEmitterService: EventEmitterService,
    private readonly smartAssistantService: SmartAssistantService,
    private readonly toastr: ToastrService,
    private readonly modalService: NgbModal,
    private readonly offCanvasService: NgbOffcanvas,
    private readonly elementRef: ElementRef,
  ) {

  }

  ngOnInit() {
    this.initializeSmartAssistantSelectedEvent();
    this.initializeSmartAssistantTopicForm();
    this.initializeSmartAssistantTopicItemAddedEvent();
  }

  ngOnDestroy() {
    for (const subscription of this.componentSubscriptions)
      subscription.unsubscribe();
  }

  private initializeSmartAssistantSelectedEvent() {

    const subscription = this.eventEmitterService.getEventEmitter(NotificationTopic.OpenSaveSmartAssistant).subscribe({
      next: (smartAssistant: SmartAssistantInfo) => {
        this.smartAssistant = smartAssistant;
        if (this.smartAssistant?.id)
          this.loadSmartAssistantTopics();
        else
          this.smartAssistantTopics = [];
      },
    });

    this.componentSubscriptions.push(subscription);
  }

  private initializeSmartAssistantTopicForm() {
    this.smartAssistantTopicForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
    });
  }

  private initializeSmartAssistantTopicItemAddedEvent() {
    const eventEmitter = this.eventEmitterService.getEventEmitter(NotificationTopic.TestSmartAssistantTopicItemAdded);
    const subscription = eventEmitter.subscribe(() => {
      this.loadSmartAssistantTopics();
    });
    this.componentSubscriptions.push(subscription);
  }

  private loadSmartAssistantTopics() {

    this.smartAssistantTopics = [];

    const smartAssistantTopicSearch = new SmartAssistantTopicSearchRequest(this.smartAssistant.id, -1);

    this.smartAssistantService.searchSmartAssistantTopics(smartAssistantTopicSearch).subscribe({
      next: (page: Page<SmartAssistantTopicInfo>) => {

        this.smartAssistantTopics = page.content;
        (window as any).phoenix.initEverything();

        setTimeout(() => {

          const kanbanItemsSortableContainer = this.elementRef.nativeElement.querySelector('.kanban-items-sortable-container');

          new Sortable(kanbanItemsSortableContainer, {
            group: 'columns',
            forceFallback: true,
            animation: 150,
            onStart: (event) => {
              console.log('Inicio de arrastre:', event);
            },
            onEnd: (event) => {
              console.log('Fin de arrastre:', event);
            },
          });

          const kanbanColumnItems = this.elementRef.nativeElement.querySelectorAll('.smart-topic-items');

          for (const kanbanColumnItem of kanbanColumnItems) {

            new Sortable(kanbanColumnItem, {
              group: 'column-items',
              forceFallback: true,
              animation: 150,
              onStart: (event) => {
                this.lastBoardSnapshot = this.getBoardSnapshot();
              },
              onEnd: (event) => {
                const updateSmartAssistantTopicItemsRequest = this.getBoardDifferences(this.lastBoardSnapshot, this.getBoardSnapshot());
                updateSmartAssistantTopicItemsRequest.smartAssistantId = this.smartAssistant.id;
                this.updateSmartAssistantTopicItems(updateSmartAssistantTopicItemsRequest);
              },
            });
          }

        }, 50);

      },
      error: (error: any) => {
        console.error('Error en la carga de smart assistants:', error);
      },
      complete: () => {
      },
    });
  }

  public openSaveSmartAssistantTopic(smartAssistantTopic?: SmartAssistantTopicInfo) {

    if (smartAssistantTopic) {
      this.smartAssistantTopic = smartAssistantTopic;
      this.smartAssistantTopicForm.get('name').setValue(smartAssistantTopic.name);
    }

    const offCanvasRef = this.offCanvasService.open(this.saveSmartAssistantTopicOffCanvas, {
      position: 'end',
    });

    offCanvasRef.hidden.subscribe(() => {
      this.initializeSmartAssistantTopicForm();
      this.smartAssistantTopic = undefined;
    });

  }

  public updateSmartAssistantTopicItems(updateSmartAssistantTopicItemsRequest: UpdateSmartAssistantTopicItemsRequest) {

    this.smartAssistantService.updateSmartAssistantTopicItems(updateSmartAssistantTopicItemsRequest).subscribe({
      next: () => {
        this.toastr.success('Tópico actualizado exitosamente');
      },
      error: (error: any) => {
        console.error('Error en la carga de smart assistants:', error);
      },
    });

  }

  public saveSmartAssistantTopic() {

    const { name } = this.smartAssistantTopicForm.value;

    const smartAssistantId: number = this.smartAssistant.id;
    let smartAssistantTopicId: number = this.smartAssistantTopic ? this.smartAssistantTopic.id : null;

    const saveSmartAssistantRequest = new SaveSmartAssistantTopicRequest(
      smartAssistantTopicId,
      name,
      smartAssistantId,
    );

    this.smartAssistantService.saveSmartAssistantTopic(saveSmartAssistantRequest).subscribe({
      next: () => {
        this.loadSmartAssistantTopics();
        this.offCanvasService.dismiss();
        if (!smartAssistantTopicId) {
          this.toastr.success('Tópico creado exitosamente');
        } else {
          this.toastr.success('Tópico actualizado exitosamente');
        }
      },
      error: (httpErrorResponse: HttpErrorResponse) => {
        const { error } = httpErrorResponse;
        this.toastr.error(error.message);
      },
      complete: () => {
      },
    });

  }

  public deleteSmartAssistantTopic(smartAssistantTopic: SmartAssistantTopicInfo) {

    if (!confirm('¿Está seguro que desea eliminar este tópico?')) return;

    this.smartAssistantService.deleteSmartAssistantTopic(smartAssistantTopic.id).subscribe({
      next: () => {
        this.loadSmartAssistantTopics();
        this.toastr.success('Tópico eliminado exitosamente');
      },
      error: (httpErrorResponse: HttpErrorResponse) => {
        const { error } = httpErrorResponse;
        this.toastr.error(error.message);
      },
      complete: () => {
      },
    });
  }

  public openSaveSmartAssistantTopicItem(event: any, smartAssistantTopic?: SmartAssistantTopicInfo, smartAssistantTopicItem?: SmartAssistantTopicItemInfo) {

    if (this.smartAssistantTopicItem) return;

    if (!event.target.classList.contains('smart-topic-item-save')) return;

    this.smartAssistantTopic = smartAssistantTopic;
    this.smartAssistantTopicItem = smartAssistantTopicItem;

    const modalRef = this.modalService.open(this.saveSmartAssistantTopicItemModal, {
      backdrop: 'static',
      centered: true,
    });

    const modalContent: any = document.querySelector('.modal-content');
    modalContent.style.backgroundColor = 'transparent';

    modalRef.hidden.subscribe(() => {
      this.smartAssistantTopic = undefined;
      this.smartAssistantTopicItem = undefined;
    });

  }

  public deleteSmartAssistantTopicItem(smartAssistantTopic: SmartAssistantTopicInfo, smartAssistantTopicItem: SmartAssistantTopicItemInfo) {

    if (!confirm('¿Está seguro que desea eliminar esta tarjeta?')) return;

    const deleteSmartAssistantTopicItemRequest = new DeleteSmartAssistantTopicItemRequest(
      smartAssistantTopicItem.id, this.smartAssistant.id,
    );

    this.smartAssistantService.deleteSmartAssistantTopicItem(deleteSmartAssistantTopicItemRequest).subscribe({
      next: () => {
        smartAssistantTopic.items = smartAssistantTopic.items.filter(item => item.id !== smartAssistantTopicItem.id);
        this.loadSmartAssistantTopics();
        this.toastr.success('Tarjeta eliminada exitosamente');
      },
      error: (httpErrorResponse: HttpErrorResponse) => {
        const { error } = httpErrorResponse;
        this.toastr.error(error.message);
      },
      complete: () => {
      },
    });
  }

  public getBoardSnapshot(): SmartAssistantBoard {

    const smartAssistantBoard = new SmartAssistantBoard();
    const topics: any = document.querySelectorAll('.smart-topic');

    for (const topic of topics) {

      const topicId = Number(topic.getAttribute('smart-topic-id'));
      const topicName = topic.querySelector('.smart-topic-name')?.textContent?.trim();
      const topicItems = topic.querySelectorAll('.smart-topic-item');

      const items: SmartAssistantTopicItemInfo[] = [];

      for (const item of topicItems) {

        if (item.classList.contains('sortable-fallback')) continue;

        const itemId = Number(item.getAttribute('smart-topic-item-id'));
        const itemPosition = Number(item.getAttribute('smart-topic-item-position'));
        const itemDate = item.querySelector('.smart-topic-item-date')?.textContent?.trim();
        const itemName = item.querySelector('.smart-topic-item-name b')?.textContent?.trim();
        const itemContent = item.querySelector('.smart-topic-item-content')?.textContent?.trim();

        items.push(new SmartAssistantTopicItemInfo(itemId, itemPosition, itemName, itemContent, itemDate));
      }

      smartAssistantBoard.topics.push(new SmartAssistantTopicInfo(topicId, topicName, items));
    }

    return smartAssistantBoard;
  }

  public getBoardDifferences(lastBoard: SmartAssistantBoard, currentBoard: SmartAssistantBoard) {

    const updateSmartAssistantTopicItemsRequest = new UpdateSmartAssistantTopicItemsRequest();

    for (let i = 0; i < lastBoard.topics.length; i++) {

      const lastTopic = lastBoard.topics[i];
      const currentTopic = currentBoard.topics[i];

      for (let j = 0; j < lastTopic.items.length; j++) {

        let lastTopicItem = lastTopic.items[j];
        let currenTopicItem = currentTopic.items[j];

        if (lastTopicItem.id !== currenTopicItem.id) {

          const updateSmartAssistantTopicItemRequest = new UpdateSmartAssistantTopicItemRequest(
            currenTopicItem.id, lastTopicItem.topicPosition
          );

          updateSmartAssistantTopicItemsRequest.changes.push(updateSmartAssistantTopicItemRequest);

          const topic = this.smartAssistantTopics
            .find(topic => topic.id === currentTopic.id);

          const topicItem = topic.items
            .find(item => item.id === currenTopicItem.id);

          topicItem.topicPosition = lastTopicItem.topicPosition;
        }

      }
    }

    return updateSmartAssistantTopicItemsRequest;
  }

  public openTestSmartAssistant(smartAssistant: SmartAssistantInfo) {
    this.eventEmitterService.emit(NotificationTopic.OpenTestSmartAssistant, {
      smartAssistant,
    });
  }

}
