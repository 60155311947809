import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CreateWhatsAppMessageTemplateRequest } from '@app/models/chat/whatsapp-message-template/create-whatsapp-message-template-request.dto';
import { DeleteWhatsAppMessageTemplateRequest } from '@app/models/chat/whatsapp-message-template/delete-whatsapp-message-template-request.dto';
import { AddWhatsAppMessageTemplateAttachmentRequest } from '@app/models/chat/whatsapp-message-template/internal/add-whatsapp-message-template-attachment-request.dto';
import { WhatsAppMessageTemplateAttachmentInfo } from '@app/models/chat/whatsapp-message-template/internal/whatsapp-message-template-attachment-info.dto';
import { SearchWhatsAppMessageTemplateRequest } from '@app/models/chat/whatsapp-message-template/search-whatsapp-message-template-request.dto';
import { UpdateWhatsAppMessageTemplateRequest } from '@app/models/chat/whatsapp-message-template/update-whatsapp-message-template-request.dto';
import { WhatsAppMessageTemplateInfo } from '@app/models/chat/whatsapp-message-template/whatsapp-message-template-info.dto';
import { environment } from '@env/environment';
import { GetSignedUrlRequest } from "../../models/media/get-signed-url-request.dto";
import { GetSignedUrlResponse } from "../../models/media/get-signed-url-response.dto";

@Injectable({
  providedIn: 'root'
})
export class MessageTemplateService {

  httpHeaders: HttpHeaders;
  apiUrl: string;

  constructor(protected http: HttpClient) {

    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.apiUrl = `${environment.apiUrl}/message-templates`;
  }

  searchWhatsAppMessageTemplates(searchWhatsAppMessageTemplateRequest: SearchWhatsAppMessageTemplateRequest): Observable<WhatsAppMessageTemplateInfo[]> {
    const endpoint = `${this.apiUrl}/search-message-templates`;
    return this.http.post<WhatsAppMessageTemplateInfo[]>(endpoint, searchWhatsAppMessageTemplateRequest);
  }

  generateMessageTemplateSignedUrl(getSignedUrlRequest: GetSignedUrlRequest): Observable<GetSignedUrlResponse> {
    const endpoint = `${this.apiUrl}/generate-message-template-signed-url`;
    return this.http.post<GetSignedUrlResponse>(endpoint, getSignedUrlRequest);
  }

  createWhatsAppWhatsAppMessageTemplate(createMessageTemplateRequest: CreateWhatsAppMessageTemplateRequest) {
    const endpoint = `${this.apiUrl}/create-message-template`;
    return this.http.post<any>(endpoint, createMessageTemplateRequest);
  }

  updateWhatsAppWhatsAppMessageTemplate(updateWhatsAppMessageTemplateRequest: UpdateWhatsAppMessageTemplateRequest) {
    const endpoint = `${this.apiUrl}/update-message-template`;
    return this.http.put<any>(endpoint, updateWhatsAppMessageTemplateRequest);
  }

  deleteWhatsAppMessageTemplate(deleteWhatsAppMessageTemplateRequest: DeleteWhatsAppMessageTemplateRequest) {
    const endpoint = `${this.apiUrl}/delete-message-template`;
    return this.http.post<any>(endpoint, deleteWhatsAppMessageTemplateRequest);
  }

  addWhatsAppMessageTemplateAttachment(addWhatsAppMessageTemplateAttachmentRequest: AddWhatsAppMessageTemplateAttachmentRequest): Observable<WhatsAppMessageTemplateAttachmentInfo> {
    const endpoint = `${this.apiUrl}/add-message-template-attachment`;
    return this.http.post<WhatsAppMessageTemplateAttachmentInfo>(endpoint, addWhatsAppMessageTemplateAttachmentRequest);
  }

}
