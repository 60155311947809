
import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageInfo } from '@app/models/chat/message/message-info.dto';
import { EventEmitterService } from '@app/services/data/event-emitter.service';
import { MediaService } from '@app/services/media/media.service';

import saveAs from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { ChatInfo } from "../../../../../../models/chat/chat/chat-info.dto";

@Component({
  selector: 'app-chat-context-document-message',
  templateUrl: './context-document-message.component.html',
  styleUrls: ['./context-document-message.component.css']
})
export class ContextDocumentMessageComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input()
  public message: MessageInfo;

  @Input()
  public chat: ChatInfo;

  private componentSubscriptions: Subscription[] = [];

  public fileExtension: string;
  public fileIcon: string;

  constructor(
    private eventEmitterService: EventEmitterService,
    private mediaService: MediaService,
    private toastr: ToastrService,
  ) {

  }

  ngOnInit() {
    
    this.fileExtension = this.message.fileName.split('.').pop();

    if (this.fileExtension === 'pdf') {
      this.fileIcon = 'fa-file-pdf text-danger';
    } else if (this.fileExtension === 'doc' || this.fileExtension === 'docx') {
      this.fileIcon = 'fa-file-word text-primary';
    } else if (this.fileExtension === 'xls' || this.fileExtension === 'xlsx') {
      this.fileIcon = 'fa-file-excel text-success';
    } else if (this.fileExtension === 'ppt' || this.fileExtension === 'pptx') {
      this.fileIcon = 'fa-file-powerpoint text-warning';
    } else if (this.fileExtension === 'txt') {
      this.fileIcon = 'fa-file-alt';
    } else if (this.fileExtension === 'zip' || this.fileExtension === 'rar') {
      this.fileIcon = 'fa-file-archive text-white';
    } else {
      this.fileIcon = 'fa-file text-white';
    }
  }

  ngAfterViewInit() {
    
  }

  ngOnDestroy() {
    this.componentSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  downloadFile() {

    this.mediaService.getObject(this.message.fileUrl).subscribe({
      next: (blob: any) => {
        saveAs(blob, this.message.fileName);
        this.toastr.success('Archivo descargado.');
        setTimeout(() => this.toastr.clear(), 1500);
      },
      error: err => console.error(err),
    });

  }
  
}
