import { Component, OnInit, ViewChild } from '@angular/core';
import { UserInfo } from '@models/account/user/user.info.dto';
import { DataKey } from '@services/data/local-data.service';
import { SocketService } from '@services/socket/socket.service';
import { ShopInfo } from '@models/account/shop/shop-info.dto';
import { AuthService } from '@services/auth/auth.service';
import { Router } from '@angular/router';
import { NgbCollapse, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitterService, NotificationTopic } from '@services/data/event-emitter.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  @ViewChild(NgbCollapse) ngbCollapse: NgbCollapse;
  public isCollapsed: boolean = true;
  public isMobile: boolean;
  public currentTheme: string = 'light';
  public defaultProfilePictureUrl: string = 'assets/img/placeholder/no_image_found-dark.webp';
  public profilePictureUrl: string = this.defaultProfilePictureUrl;
  public user: UserInfo;
  public shop: ShopInfo;

  constructor(
    private authService: AuthService,
    private socketService: SocketService,
    private eventEmitterService: EventEmitterService,
    public router: Router,
    private offCanvasService: NgbOffcanvas
  ) {
    this.user = this.authService.getUser();
    this.shop = this.user.shop;
    this.profilePictureUrl = this.user.profilePictureUrl || this.defaultProfilePictureUrl;
  }

  ngOnInit() {

    this.checkTheme();

    /* IMPORTANT: Don't remove this code
    const navbarTopStyle = (window as any).config.config.phoenixNavbarTopStyle;
    const navbarTop = document.querySelector('.navbar-top');
    
    if (navbarTopStyle === 'darker' && navbarTop) {
      navbarTop.classList.add('navbar-darker');
    }

    const navbarVerticalStyle = (window as any).config.config.phoenixNavbarVerticalStyle;
    const navbarVertical = document.querySelector('.navbar-vertical');
    
    if (navbarVertical && navbarVerticalStyle === 'darker') {
      navbarVertical.classList.add('navbar-darker');
    }
    */
  }

  private checkTheme() {
    const theme = window.localStorage.getItem('phoenixTheme');
    this.currentTheme = theme ? theme : 'dark';
  }

  public toggleSidebar() {
    this.eventEmitterService.emit(NotificationTopic.ToggleSidebar);
  }

  public toggleTheme() {
    if (this.currentTheme === 'light') {
      this.currentTheme = 'dark';
    } else {
      this.currentTheme = 'light';
    }
    this.eventEmitterService.emit(NotificationTopic.ToggleTheme);
  }

  public signOut() {

    setTimeout(() => {

      localStorage.removeItem(DataKey.MessageTemplates);
      this.authService.signOut(true);

      this.socketService.disconnect();

    }, 100);

  }
  goToLink(url: string) {

    this.offCanvasService.hasOpenOffcanvas() && this.offCanvasService.dismiss();

    this.router.navigate([url]).then(() => {  });
    
  }

}
