
export enum UserRoleType {
  SystemAdmin = 'system-admin',
  Admin = 'admin',
  ChatAdvisor = 'chat-advisor',
}

export const UserRoleTypeMetadata = {
  [UserRoleType.SystemAdmin]: {
    description: 'Administrador de sistema'
  },
  [UserRoleType.Admin]: {
    description: 'Administrador'
  },
  [UserRoleType.ChatAdvisor]: {
    description: 'Asesor de chat'
  },
};
