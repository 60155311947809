
export class AssignChatAdvisorRequest {

  chatIds: number[];
  userId: number;

  constructor(chatIds: number[], userId: number) {
    this.chatIds = chatIds;
    this.userId = userId;
  }

}
