import { MessageType } from "@app/type/chat/message-content.type";
import { WhatsAppMessageTemplateComponentHeaderType } from "./whatsapp-message-template-component-header.type";

export enum WhatsAppMessageTemplateComponentParameterType {

  Payload = 'payload',
  Text = 'text',
  CouponCode = 'coupon_code',
  DateTime = 'date_time',
  Currency = 'currency',
  Image = 'image',
  Document = 'document',
  Video = 'video',

}

export function getParameterTypeByMessageType(messageType: MessageType): WhatsAppMessageTemplateComponentParameterType {
  const parameterType = Object.values(WhatsAppMessageTemplateComponentParameterType)
      .find(type => type.toLowerCase() === messageType.toLowerCase());
  return parameterType;
}

export function getParameterTypeByTemplateComponentHeaderType(templateComponentHeaderType: WhatsAppMessageTemplateComponentHeaderType): WhatsAppMessageTemplateComponentParameterType {
  const parameterType = Object.values(WhatsAppMessageTemplateComponentParameterType)
      .find(type => type.toLowerCase() === templateComponentHeaderType.toLowerCase());
  return parameterType;
}
