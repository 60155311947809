
export class SaveSmartAssistantTopicRequest {

  id: number;
  name: string;
  smartAssistantId: number;

  constructor(
    id: number,
    name: string,
    smartAssistantId: number,
  ) {
    this.id = id;
    this.name = name;
    this.smartAssistantId = smartAssistantId;
  }

}