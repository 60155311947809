
export class SaveAutomaticWhatsAppConfigRequest {

  constructor(
    public shopId: number,
    public phoneNumberId: string,
    public code: string,
    public wabaId: string,
  ) {
    this.shopId = shopId;
    this.phoneNumberId = phoneNumberId;
    this.code = code;
    this.wabaId = wabaId;
  }

}
