import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserInfo } from '@app/models/account/user/user.info.dto';
import { AuthService } from '@app/services/auth/auth.service';
import { NgbModal, NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
  Observable,
  of,
  OperatorFunction,
  Subject,
  Subscription,
  switchMap,
} from 'rxjs';
import { BroadcastSearchRequest } from '@app/models//utilities/broadcast/broadcast-search-request.dto';
import { BroadcastInfo } from '@app/models//utilities/broadcast/broadcast-info.dto';
import { BroadcastService } from '../../../services/utilities/broadcast.service';
import { Page } from '@app/models//common/page';
import { UserService } from '../../../services/account/user.service';
import { UserSearchRequest } from '@app/models//account/user/user-search-request.dto';
import { ChatTagInfo } from '@app/models//chat/tag/chat-tag-info.dto';
import { ChatTagSearchRequest } from '@app/models//chat/tag/chat-tag-search-request.dto';
import { ChatTagService } from '../../../services/chat/chat-tag.service';
import { ChatTagSearchType, ChatTagSearchTypeMetadata } from '../../../type/chat/chat-tag-search.type';
import { getTypes } from '../../../utils/enum-utils';
import { EnumInfo } from '@app/models//common/enum-info.dto';
import { DataKey, LocalDataService } from '../../../services/data/local-data.service';
import { EventEmitterService, NotificationTopic } from '../../../services/data/event-emitter.service';
import { SendMessageRequest } from '@app/models//chat/message/send-message-request.dto';
import { NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap/offcanvas/offcanvas-ref';
import { timeFormatter } from "../../../utils/date-utils";
import { SaveBroadcastRequest } from '@app/models//utilities/broadcast/save-broadcast-request.dto';

import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridWeekPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import { ChatSearchDangeRangeFieldType } from "../../../type/chat/chat-search-dange-range-field.type";
import { ChatInfo } from "../../../models/chat/chat/chat-info.dto";
import { getLastMessageTypeLabel } from "../../../utils/chat-utils";
import { SearchBroadcastChatsRequest } from "../../../models/utilities/broadcast/search-broadcast-chats-request.dto";
import { BroadcastStatusType } from "../../../type/utilities/broadcast/broadcast-status.type";
import { validateOnlyNumbers } from "../../../utils/phone-utils";
import { SpinnerService } from "../../../services/data/spinner.service";

@Component({
  selector: 'app-broadcasts',
  templateUrl: './broadcast.component.html',
  styleUrls: ['./broadcast.component.css']
})
export class BroadcastComponent implements OnInit, OnDestroy {

  public ChatSearchDangeRangeFieldType = ChatSearchDangeRangeFieldType;
  public BroadcastStatusType = BroadcastStatusType;

  private scheduledForInstance: any;
  private dateRangeInstance: any;

  public scheduledForMinDate: Date;
  public scheduledForMinTime: string;
  public scheduledForInstanceOpens: boolean = false;

  public todayIsSelected: boolean = true;
  public currentDate: Date = new Date();
  public selectedScheduledForDateTime: Date;

  public currentCalendarViewType: string;
  public calendarOptions: CalendarOptions;
  public dateRangeValue: any = {};

  public saveBroadcastRequest: SaveBroadcastRequest;

  public ChatTagSearchType = ChatTagSearchType;
  public chatTagSearchTypes: EnumInfo[] = getTypes(ChatTagSearchType, ChatTagSearchTypeMetadata);

  public broadcastPage: Page<BroadcastInfo>;
  public broadcasts: BroadcastInfo[] = [];
  public firstLoad: boolean = true;

  // Users
  public usersAll: UserInfo[] = [];
  public usersAvailable: UserInfo[] = [];
  public usersChosen: UserInfo[] = [];

  // Chat Tags
  @ViewChild("instance", { static: true }) instance: any;
  public focus$ = new Subject<string>();
  public click$ = new Subject<string>();
  public formatResult = (value: ChatTagInfo) => value.name;
  public formatInput = (value: ChatTagInfo) => value.name;
  public tagsFormControl: FormControl;
  public chatTagsAll: ChatTagInfo[] = [];
  public chatTagsAvailable: ChatTagInfo[] = [];
  public chatTagsChosen: ChatTagInfo[] = [];

  private readonly broadcastChatsPageSize = 50;
  private searchBroadcastChatsSearchRequest: SearchBroadcastChatsRequest = new SearchBroadcastChatsRequest(this.broadcastChatsPageSize);
  public broadcastChatsPage: Page<ChatInfo> = new Page<ChatInfo>();

  public broadcastForm: FormGroup;
  public selectedBroadcast: BroadcastInfo;
  public savingBroadcast: boolean = false;
  public loadingBroadcasts: boolean = false;
  private searchRequest: BroadcastSearchRequest;
  private defaultPageSize: number = 10;
  public searchBroadcastFormControl: FormControl = new FormControl('');

  public currentUser: UserInfo;

  // Modals
  @ViewChild("broadcastChatsModal")
  private broadcastChatsModal: TemplateRef<any>;

  @ViewChild("randomizeBroadcastChatsModal")
  private randomizeBroadcastChatsModal: TemplateRef<any>;
  // Modals

  // OffCanvas
  @ViewChild("saveBroadcastOffCanvas")
  public saveBroadcastOffCanvas: TemplateRef<any>;
  private saveBroadcastOffCanvasRef: NgbOffcanvasRef;

  @ViewChild("chatTemplateSenderOffCanvas")
  public chatTemplateSenderOffCanvas: TemplateRef<any>;
  private chatTemplateSenderOffCanvasRef: NgbOffcanvasRef;

  private currentSaveBroadcastOffCanvasHidden: Subscription;

  private componentSubscriptions: Subscription[] = [];

  constructor(
    private spinnerService: SpinnerService,
    private authService: AuthService,
    private broadcastService: BroadcastService,
    private userService: UserService,
    private chatTagService: ChatTagService,
    private localDataService: LocalDataService,
    private eventEmmitterService: EventEmitterService,
    private toastr: ToastrService,
    private offCanvasService: NgbOffcanvas,
    private modalService: NgbModal,
  ) {
    this.currentUser = this.authService.getUser();
  }

  ngOnInit() {
    this.initializeForm();
    this.initializeBroadcastSearchRequest();
    this.initializeSearchBroadcastFormControl();
    this.initializeBroadcastSaveRequest();
    this.initializeBroadcastMessageTemplateReadyEvent();
    this.initializeCalendar();
    this.loadUsers();
    this.loadChatTags();
  }

  ngOnDestroy() {
    this.componentSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  initializeForm() {
    this.broadcastForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      user: new FormControl(''),
      chatTagSearchType: new FormControl(ChatTagSearchType.AtLeastOne),
    });
  }

  initializeCalendar() {

    const isMobile = window.innerWidth <= 992;

    this.calendarOptions = {
      initialView: isMobile ? 'timeGridDay' : 'dayGridMonth',
      locale: esLocale,
      editable: true,
      height: 'calc(100vh - 280px)',
      dayMaxEvents: 3,
      stickyHeaderDates: false,
      allDaySlot: false,
      slotLabelFormat: {
        hour: '2-digit',
        minute: '2-digit',
      },
      slotDuration: '00:30:00',
      slotLabelInterval: {
        minutes: 30
      },
      nowIndicator: true,
      headerToolbar: {
        right: 'prev,next today',
        center: 'title',
        left: isMobile ? '' : 'dayGridMonth,timeGridWeek,timeGridDay'
      },
      buttonText: {
        month: 'Mensual',
        week: 'Semanal',
        day: 'Por día'
      },
      views: {
        week: {
          eventLimit: 3
        }
      },
      eventTimeFormat: {
        hour: 'numeric',
        minute: '2-digit',
        omitZeroMinute: true,
        meridiem: true
      },
      plugins: [
        dayGridPlugin,
        timeGridWeekPlugin,
        interactionPlugin,
      ],
      eventClassNames: 'text-primary',
      eventTextColor: '#ffffff',
      eventBorderColor: '#3788d8',
      viewDidMount: (info: any) => {
        this.currentCalendarViewType = info.view.type;
      },
      datesSet: (dateInfo: any) => {
        this.searchRequest.fromDate = dateInfo.start.getTime();
        this.searchRequest.toDate = dateInfo.end.getTime();
        this.loadBroadcasts();
      },
      eventClick: (info: any) => {
        const broadcastId = info.event.extendedProps.broadcastId;
        const broadcast = this.broadcasts.find((b: BroadcastInfo) => b.id === broadcastId);
        if (broadcast) this.openSaveBroadcast(broadcast);
      },
      dateClick: (info: any) => {

        const selectedDateTime = new Date(info.date);
        const currentDate = new Date();

        if (this.currentCalendarViewType === 'dayGridMonth') {
          selectedDateTime.setHours(0, 0, 0, 0);
          currentDate.setHours(0, 0, 0, 0);
        }

        if (selectedDateTime < currentDate) {
          this.toastr.warning('No puede programar un broadcast para una fecha anterior a la actual');
          return;
        }

        this.selectedScheduledForDateTime = info.date;

        if (this.selectedScheduledForDateTime.getDate() === this.currentDate.getDate()) {
          this.selectedScheduledForDateTime.setHours(currentDate.getHours() + 1);
          this.todayIsSelected = true;
        } else {
          this.todayIsSelected = false;
        }

        this.openSaveBroadcast();
      },
      defaultTimedEventDuration: '01:00:00',
      events: []
    };

    this.setScrollTime();
  }

  setScrollTime() {
    const now = new Date();
    const hour = now.getHours();
    const minute = now.getMinutes();
    this.calendarOptions.scrollTime = `${hour}:${minute}:00`;
  }

  resetAll() {
    this.initializeForm();
    this.initializeBroadcastSaveRequest();
    this.dateRangeValue = {};
    this.selectedBroadcast = undefined;
    this.chatTagsAvailable = [...this.chatTagsAll];
    this.usersAvailable = [...this.usersAll];
    this.usersChosen = [];
    this.chatTagsChosen = [];
  }

  initializeBroadcastSaveRequest() {
    this.saveBroadcastRequest = new SaveBroadcastRequest();
    this.saveBroadcastRequest.shopId = this.currentUser.shop.id;
    this.saveBroadcastRequest.dateRangeFieldType = ChatSearchDangeRangeFieldType.LastMessageDate;
  }

  initializeBroadcastSearchRequest() {
    this.searchRequest = new BroadcastSearchRequest(
      this.currentUser.shop.id,
      this.defaultPageSize,
    );
  }

  initializeSearchBroadcastFormControl() {
    this.searchBroadcastFormControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((query) => {
          return of(query.trim());
        })
      )
      .subscribe((searchTerm: string) => {
        this.searchRequest.searchTerm = searchTerm;
        this.loadBroadcasts();
      });
  }

  initializeBroadcastMessageTemplateReadyEvent() {

    const eventEmmiter = this.eventEmmitterService.getEventEmitter(NotificationTopic.BroadcastMessageTemplateReady);

    const subscription = eventEmmiter.subscribe((sendMessageRequest: SendMessageRequest) => {
      this.saveBroadcastRequest.sendMessageRequest = sendMessageRequest;
      this.resumeSaveBroadcast();
    });

    this.componentSubscriptions.push(subscription);
  }

  loadBroadcasts() {

    this.loadingBroadcasts = true;

    this.broadcastService.searchBroadcasts(this.searchRequest).subscribe({
      next: (page: Page<BroadcastInfo>) => {
        this.broadcastPage = page;
        this.broadcasts = page.content;
        this.setCalendarEvents(this.broadcasts);
        this.loadingBroadcasts = false;
        if (this.firstLoad) this.firstLoad = false;
      },
      error: (error: any) => {
        console.error("Error en la carga de usuarios:", error);
      },
      complete: () => {},
    });
  }

  setCalendarEvents(broadcasts: BroadcastInfo[]) {
    const events = broadcasts.map((broadcast: BroadcastInfo) => {
      return {
        extendedProps: {
          broadcastId: broadcast.id,
        },
        title: broadcast.name,
        date: new Date(broadcast.scheduledFor),
      }
    });
    this.calendarOptions.events = events;
  }

  loadUsers() {

    const userSearchRequest = new UserSearchRequest(
      this.currentUser.shop.id, this.defaultPageSize
    );

    this.userService.searchUsers(userSearchRequest).subscribe({
      next: (page: Page<UserInfo>) => {
        this.usersAll = [...page.content];
        this.usersAvailable = page.content;
      },
      error: (error: any) => {
        console.error("Error en la carga de chats:", error);
      },
      complete: () => {},
    });
  }

  public onNameChanged() {
    this.saveBroadcastRequest.name = this.broadcastForm.get('name').value;
  }

  public onUserChosen(event: any) {
    const userId: number = Number(event.target.value);
    this.addUserChosen(userId);
  }

  private addUserChosen(userId: number) {

    const selectedUser: UserInfo = this.usersAvailable.find(u => u.id === userId);
    const userIndex: number = this.usersAvailable.indexOf(selectedUser);

    this.usersChosen.push(selectedUser);
    this.saveBroadcastRequest.userIds.push(userId);

    this.usersAvailable.splice(userIndex, 1);
    this.usersAvailable.sort((u1, u2) => u2.id - u1.id);

    this.broadcastForm.get('user').setValue('');

    this.searchBroadcastChats(this.saveBroadcastRequest, false);
  }

  removeUserChosen(user: UserInfo) {

    if (this.selectedBroadcast && this.selectedBroadcast.status === BroadcastStatusType.Sent) return;

    const userChosenIndex = this.usersChosen.indexOf(user);
    const userChosenSaveRequestIndex = this.saveBroadcastRequest.userIds.findIndex((id: number) => id === user.id);

    this.usersChosen.splice(userChosenIndex, 1);
    this.saveBroadcastRequest.userIds.splice(userChosenSaveRequestIndex, 1);

    this.usersAvailable.push(user);
    this.usersAvailable.sort((u1, u2) => u2.id - u1.id);

    this.broadcastForm.get('user').setValue('');

    this.searchBroadcastChats(this.saveBroadcastRequest, false);
  }

  loadChatTags(callback?: () => void) {

    const infiniteSize: number = -1;

    const searchRequest = new ChatTagSearchRequest(
      this.currentUser.shop.id,
      infiniteSize
    );

    this.chatTagService.searchChatTags(searchRequest).subscribe({
      next: (chatTagsPage: Page<ChatTagInfo>) => {
        this.chatTagsAll = [...chatTagsPage.content];
        this.chatTagsAvailable = chatTagsPage.content;
        callback && callback();
      },
      error: (error: any) => {
        console.error("Error en la carga de broadcasts:", error);
      },
      complete: () => {

      },
    });

    this.initializeChatTagsChosenEvent();
  }

  initializeChatTagsChosenEvent() {

    this.tagsFormControl = new FormControl("");

    this.tagsFormControl.valueChanges.subscribe({
      next: (selectedChatTag: ChatTagInfo) => {
        if (selectedChatTag) {
          this.addChatTagChosen(selectedChatTag);
        }
      },
    });
  }

  addChatTagChosen(chatTag: ChatTagInfo) {

    const chatTagChosenIndex = this.chatTagsAvailable.indexOf(chatTag);
    this.chatTagsChosen.push(chatTag);
    this.saveBroadcastRequest.chatTagIds.push(chatTag.id);

    this.chatTagsAvailable.splice(chatTagChosenIndex, 1);
    this.chatTagsAvailable.sort((ct1, ct2) => ct2.id - ct1.id);

    this.tagsFormControl.setValue(null);

    this.searchBroadcastChats(this.saveBroadcastRequest, false);
  }

  removeChatTag(chatTag: ChatTagInfo) {

    if (this.selectedBroadcast && this.selectedBroadcast.status === BroadcastStatusType.Sent) return;

    const chatTagChosenIndex = this.chatTagsChosen.indexOf(chatTag);
    const chatTagChosenSaveRequestIndex = this.saveBroadcastRequest.chatTagIds.findIndex((id: number) => id === chatTag.id);

    this.chatTagsChosen.splice(chatTagChosenIndex, 1);
    this.saveBroadcastRequest.chatTagIds.splice(chatTagChosenSaveRequestIndex, 1);

    this.chatTagsAvailable.push(chatTag);
    this.chatTagsAvailable.sort((ct1, ct2) => ct2.id - ct1.id);

    this.searchBroadcastChats(this.saveBroadcastRequest, false);
  }

  searchChatTags: OperatorFunction<string, readonly ChatTagInfo[]> = (
    text$: Observable<string>
  ) => {
    const debouncedText$ = text$.pipe(debounceTime(1), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance?.isPopupOpen())
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term: string) => {
        if (term === '') return this.chatTagsAvailable;
        return this.chatTagsAvailable.filter((ct: ChatTagInfo) => ct.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10);
      })
    );
  };

  public scheduledForReady(event: any) {
    this.scheduledForInstance = event.instance;
  }
  public dateRangeReady(event: any) {
    this.dateRangeInstance = event.instance;
    if (this.selectedBroadcast) {
      const { fromDate, toDate } = this.selectedBroadcast;
      if (fromDate) {
        const startDate = new Date(fromDate);
        this.saveBroadcastRequest.fromDate = startDate.getTime();
        if (toDate) {
          const endDate = new Date(toDate);
          this.dateRangeInstance.setDate([startDate, endDate]);
          this.saveBroadcastRequest.toDate = endDate.getTime();
        } else {
          const endDate = new Date(fromDate);
          this.dateRangeInstance.setDate([startDate, endDate]);
          this.saveBroadcastRequest.toDate = endDate.getTime();
        }
      }
      if (this.selectedScheduledForDateTime >= this.currentDate) {
      } else {
        this.dateRangeInstance.destroy();
      }
    }
    this.searchBroadcastChats(this.saveBroadcastRequest, false);
  }

  public setSelectedRangeDate(value: any) {

    const { selectedDates } = value;
    const startDate = selectedDates[0];
    const endDate = selectedDates[1];

    if (!startDate || !endDate) return;

    if (endDate.getDate() !== this.currentDate.getDate()) {
      endDate.setHours(23, 59, 59, 0);
    } else {
      endDate.setHours(this.currentDate.getHours(), this.currentDate.getMinutes(), 0, 0);
    }

    this.dateRangeInstance.setDate([startDate, endDate]);

    this.saveBroadcastRequest.fromDate = startDate.getTime();
    this.saveBroadcastRequest.toDate = endDate.getTime();

    this.dateRangeValue = {
      from: startDate,
      to: endDate
    };

    this.searchBroadcastChats(this.saveBroadcastRequest, false);
  }

  public setSelectedDateTime(value: any) {
    if (value.selectedDates?.length > 0) {
      const { selectedDates } = value;
      this.selectedScheduledForDateTime = selectedDates[0];
      this.saveBroadcastRequest.scheduledFor = this.selectedScheduledForDateTime.getTime();
    }
  }

  public changeDateRangeFieldType(dateRangeFieldType: ChatSearchDangeRangeFieldType) {
    this.saveBroadcastRequest.dateRangeFieldType = dateRangeFieldType;
    this.searchBroadcastChats(this.saveBroadcastRequest, false);
  }

  public clearDateRange() {

    this.dateRangeValue = {};
    this.dateRangeInstance.setDate([]);

    this.saveBroadcastRequest.fromDate = null;
    this.saveBroadcastRequest.toDate = null;
    this.saveBroadcastRequest.dateRangeFieldType = ChatSearchDangeRangeFieldType.LastMessageDate;

    this.searchBroadcastChats(this.saveBroadcastRequest, false);
  }

  public showBroadcastChats() {

    const modalRef = this.modalService.open(
      this.broadcastChatsModal,
      {
        modalDialogClass: "broadcast-chats-modal",
        size: "lg",
        centered: true,
      },
    );

    modalRef.hidden.subscribe(() => {
      // this.clearBroadcastChats();
    });
  }

  public showRandomizeBroadcastChats() {

    this.saveBroadcastRequest.randomize = true;
    this.saveBroadcastRequest.randomizeTotal = undefined;

    const modalRef = this.modalService.open(
      this.randomizeBroadcastChatsModal,
      {
        modalDialogClass: "randomize-broadcast-chats-modal",
        size: "lg",
        centered: true,
      },
    );

    modalRef.dismissed.subscribe((reason: string) => {
      if (reason === 'accepted') return;
      this.saveBroadcastRequest.randomize = false;
      this.saveBroadcastRequest.randomizeTotal = undefined;
    });

  }

  public randomizeBroadcastChats() {

    this.searchBroadcastChatsSearchRequest.page = 0;
    this.saveBroadcastRequest.randomize = true;
    this.saveBroadcastRequest.randomizeTotal = Number(this.saveBroadcastRequest.randomizeTotal);
    
    this.clearBroadcastChats();

    this.spinnerService.show('Randomizando...');

    this.searchBroadcastChats(this.saveBroadcastRequest, false, () => {
      this.spinnerService.hide();
      this.modalService.dismissAll('accepted');
      this.showBroadcastChats();
    });
  }

  public validateOnlyNumbers(event: any) {
    validateOnlyNumbers(event);
  }

  public openSaveBroadcast(broadcast?: BroadcastInfo) {

    const now = new Date();

    if (this.selectedScheduledForDateTime == undefined) {
      this.selectedScheduledForDateTime = new Date();
      this.saveBroadcastRequest.scheduledFor = this.selectedScheduledForDateTime.getTime();
    }

    if (broadcast) {

      this.scheduledForMinDate = undefined;

      this.selectedBroadcast = broadcast;
      this.setBroadcastFormValues(broadcast);

      const saveBroadcastRequest = this.getSaveBroadcastRequestFromBroadcast(broadcast);
      this.searchBroadcastChats(saveBroadcastRequest, false);

    } else {

      this.scheduledForMinDate = now;

      this.resetAll();

      this.enableFormInputs();

      if (this.selectedScheduledForDateTime.getDate() === this.currentDate.getDate()) {
        this.scheduledForMinTime = timeFormatter(now);
      } else {
        this.scheduledForMinTime = undefined;
      }

      if (this.currentCalendarViewType === 'dayGridMonth') {
        if (this.selectedScheduledForDateTime.getDate() === this.currentDate.getDate()) {
          this.scheduledForMinDate.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
          if (this.selectedScheduledForDateTime.getDate() === this.currentDate.getDate()) {
            this.selectedScheduledForDateTime.setHours(now.getHours() + 1);
            this.todayIsSelected = true;
          } else {
            this.todayIsSelected = false;
          }
        }
      }

      this.saveBroadcastRequest.scheduledFor = this.selectedScheduledForDateTime.getTime();
    }

    this.saveBroadcastOffCanvasRef = this.offCanvasService.open(this.saveBroadcastOffCanvas, {
      panelClass: "save-broadcast-offcanvas",
      position: "end",
    });

    this.currentSaveBroadcastOffCanvasHidden = this.saveBroadcastOffCanvasRef.hidden.subscribe(() => {
      if (this.selectedBroadcast) {
        this.clearBroadcastFormValues();
        this.clearBroadcastChats();
        this.selectedBroadcast = undefined;
      }
    });

    this.saveBroadcastOffCanvasRef.shown.subscribe(() => {
      this.scheduledForInstance.setDate(this.selectedScheduledForDateTime);
    });

  }

  private getSaveBroadcastRequestFromBroadcast(broadcast: BroadcastInfo) {

    const saveBroadcastRequest = new SaveBroadcastRequest();
    saveBroadcastRequest.id = broadcast.id;
    saveBroadcastRequest.name = broadcast.name;
    saveBroadcastRequest.shopId = this.currentUser.shop.id;
    saveBroadcastRequest.userIds = broadcast.users?.map((u: UserInfo) => u.id);
    saveBroadcastRequest.chatTagIds = broadcast.chatTags?.map((ct: ChatTagInfo) => ct.id);
    saveBroadcastRequest.scheduledFor = broadcast.scheduledFor;
    saveBroadcastRequest.fromDate = broadcast.fromDate;
    saveBroadcastRequest.toDate = broadcast.toDate;
    saveBroadcastRequest.dateRangeFieldType = broadcast.dateRangeFieldType;
    saveBroadcastRequest.sendMessageRequest = JSON.parse(broadcast.sendMessageRequest);

    return saveBroadcastRequest;
  }

  private searchBroadcastChats(saveBroadcastRequest: SaveBroadcastRequest, scrolling: boolean, callback?: () => void) {

    this.searchBroadcastChatsSearchRequest.saveBroadcastRequest = saveBroadcastRequest;

    this.broadcastService.searchBroadcastChats(this.searchBroadcastChatsSearchRequest).subscribe({
      next: (page: Page<ChatInfo>) => {

        for (const broadcastChat of page.content)
          broadcastChat.lastMessageTypeLabel = getLastMessageTypeLabel(broadcastChat);

        if (scrolling) {

          const currentChats = [...this.broadcastChatsPage.content];
          this.broadcastChatsPage = page;
          this.broadcastChatsPage.content = [...currentChats, ...page.content];

        } else {
          this.broadcastChatsPage = page;
        }

        if (callback) callback();
      }
    });
  }

  public onScrolledBroadcastChats() {
    if (this.broadcastChatsPage.pageNumber < this.broadcastChatsPage.totalPages) {
      this.searchBroadcastChatsSearchRequest.page = this.broadcastChatsPage.pageNumber + 1;
      this.searchBroadcastChats(this.saveBroadcastRequest, true);
    }
  }

  private resumeSaveBroadcast() {

    this.chatTemplateSenderOffCanvasRef.hidden.subscribe(() => {

      this.saveBroadcastOffCanvasRef = this.offCanvasService.open(this.saveBroadcastOffCanvas, {
        panelClass: "save-broadcast-offcanvas",
        position: "end",
      });

      this.saveBroadcastOffCanvasRef.shown.subscribe(() => {
        this.scheduledForInstance.setDate(this.selectedScheduledForDateTime);
        this.dateRangeInstance.setDate([this.saveBroadcastRequest.fromDate, this.saveBroadcastRequest.toDate]);
      });

      this.saveBroadcastOffCanvasRef.hidden.subscribe(() => {
        this.clearBroadcastFormValues();
        this.selectedBroadcast = undefined;
      });

    });

    this.offCanvasService.dismiss();
  }

  public openChatTemplateSender() {

    this.localDataService.set(DataKey.ConfiguringBroadcast, 'true');
    this.localDataService.set(DataKey.EmptyMessageTemplateRecipient, 'false');

    if (this.currentSaveBroadcastOffCanvasHidden) this.currentSaveBroadcastOffCanvasHidden.unsubscribe();

    this.currentSaveBroadcastOffCanvasHidden = this.saveBroadcastOffCanvasRef.hidden.subscribe(() => {

      this.chatTemplateSenderOffCanvasRef = this.offCanvasService.open(this.chatTemplateSenderOffCanvas, {
        panelClass: 'message-template-sender-offcanvas',
        position: 'end',
        scroll: true,
        backdrop: false
      });

    });

    this.offCanvasService.dismiss();
  }

  private setBroadcastFormValues(broadcast: BroadcastInfo) {

    const now = new Date();

    this.saveBroadcastRequest.id = broadcast.id;

    this.broadcastForm.get('name').setValue(broadcast.name);
    this.saveBroadcastRequest.name = broadcast.name;

    this.selectedScheduledForDateTime = new Date(broadcast.scheduledFor);
    this.saveBroadcastRequest.scheduledFor = broadcast.scheduledFor;

    if (this.selectedScheduledForDateTime >= now) {
      this.enableFormInputs();
    } else {
      this.disableFormInputs();
    }

    if (broadcast.sendMessageRequest) {
      this.saveBroadcastRequest.sendMessageRequest = JSON.parse(broadcast.sendMessageRequest);
    }

    // todo: set users and chat tags and date range
    if (broadcast.users?.length > 0)
      for (const user of broadcast.users) {
        this.addUserChosen(user.id);
      }

    if (broadcast.chatTags?.length > 0)
      for (const chatTag of broadcast.chatTags) {
        this.addChatTagChosen(chatTag);
      }

  }

  private disableFormInputs() {
    this.scheduledForInstanceOpens = false;
    this.broadcastForm.get('name').disable();
    this.broadcastForm.get('user').disable();
    this.tagsFormControl.disable();
  }

  private enableFormInputs() {
    this.scheduledForInstanceOpens = true;
    this.broadcastForm.get('name').enable();
    this.broadcastForm.get('user').enable();
    this.tagsFormControl.enable();
  }

  private clearBroadcastFormValues() {
    this.broadcastForm.get('name').setValue('');
    this.selectedScheduledForDateTime = undefined;
    this.scheduledForInstanceOpens = true;
    this.broadcastForm.get('name').enable();
    this.saveBroadcastRequest.sendMessageRequest = undefined;
    this.usersChosen = [];
    this.usersAvailable = [...this.usersAll];
    this.chatTagsChosen = [];
    this.chatTagsAvailable = [...this.chatTagsAll];
    this.dateRangeValue = {};
  }

  private clearBroadcastChats() {
    this.searchBroadcastChatsSearchRequest = new SearchBroadcastChatsRequest(this.broadcastChatsPageSize);
    this.broadcastChatsPage = new Page<ChatInfo>();
  }

  public cancelSaveBroadcast() {
    this.resetAll();
    this.offCanvasService.dismiss();
  }

  public async saveBroadcast() {

    if (this.broadcastForm.valid) {

      this.savingBroadcast = true;
      this.broadcastForm.disable();

      const { name, chatTagSearchType } = this.broadcastForm.value;

      this.saveBroadcastRequest.name = name.trim();

      if (this.saveBroadcastRequest.chatTagIds.length > 0) {
        this.saveBroadcastRequest.chatTagSearchType = chatTagSearchType;
      }

      // Updating broadcast
      if (this.selectedBroadcast) {
        this.saveBroadcastRequest.id = this.selectedBroadcast.id;
      }

      this.broadcastService.saveBroadcast(this.saveBroadcastRequest)
        .subscribe({
          next: (savedBroadcast: BroadcastInfo) => {
            setTimeout(() => {
              if (this.selectedBroadcast) {
                const index = this.broadcasts.findIndex((b: BroadcastInfo) => b.id === savedBroadcast.id);
                this.broadcasts[index] = savedBroadcast;
              } else {
                this.broadcasts.unshift(savedBroadcast);
              }
              this.setCalendarEvents(this.broadcasts);
              this.savingBroadcast = false;
              this.offCanvasService.dismiss();
              this.resetAll();
              this.toastr.success('Broadcast guardado con éxito');
            }, 1000);
          },
          error: (error: any) => {
            this.savingBroadcast = false;
            const errorMessage = 'Error al guardar el broadcast';
            this.toastr.error(errorMessage);
            // this.errorCode = '';
            console.error(errorMessage, error);
          },
          complete: () => {

          }
        });
    }

  }

  public abortBroadcast(broadcast: BroadcastInfo) {

      if (!confirm("¿Está seguro que desea abortar este broadcast?")) return;

      this.broadcastService.abortBroadcast(broadcast.id)
        .subscribe({
          next: () => {
            const index = this.broadcasts.findIndex((b: BroadcastInfo) => b.id === broadcast.id);
            this.broadcasts[index].status = BroadcastStatusType.Aborted;
            this.toastr.success("Broadcast abortado con éxito");
          },
          error: (error: any) => {
            const errorMessage = "Error al abortar el broadcast";
            this.toastr.error(errorMessage);
            console.error(errorMessage, error);
          },
          complete: () => {

          }
        });
  }

  sendBroadcast(broadcast: BroadcastInfo) {

    if (!confirm("¿Está seguro que desea enviar este broadcast?")) return;

    this.broadcastService.sendBroadcast(broadcast.id)
      .subscribe({
        next: () => {
          // const index = this.broadcasts.findIndex((b: BroadcastInfo) => b.id === broadcast.id);
          // this.broadcasts[index].sentAt = new Date().getTime();
          this.toastr.success("Broadcast enviado con éxito");
        },
        error: (error: any) => {
          const errorMessage = "Error al enviar el broadcast";
          this.toastr.error(errorMessage);
          console.error(errorMessage, error);
        },
        complete: () => {

        }
      });
  }

  deleteBroadcast(broadcast: BroadcastInfo) {

    if (!confirm('¿Está seguro que desea eliminar este broadcast?')) return;

    this.broadcastService.deleteBroadcast(broadcast.id)
      .subscribe({
        next: () => {
          const index = this.broadcasts.findIndex((b: BroadcastInfo) => b.id === broadcast.id);
          this.broadcasts.splice(index, 1);
          this.setCalendarEvents(this.broadcasts);
          this.toastr.success('Broadcast eliminado con éxito');
        },
        error: (error: any) => {
          const errorMessage = 'Error al eliminar el usuario';
          this.toastr.error(errorMessage);
          console.error(errorMessage, error);
        },
        complete: () => {

        }
      });

  }

}
