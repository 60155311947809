<section class="py-0 px-xl-3">
  <div class="container-md">
    <div class="">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Nueva solicitud de aplicación</h5>
            <form [formGroup]="appRequestForm" (ngSubmit)="submitForm()">
              <div class="mb-3">
                <label for="appName" class="form-label">Nombre de la aplicación</label>
                <input type="text" class="form-control" id="appName" formControlName="name">
              </div>
              <div class="mb-3">
                <label for="iconUrl" class="form-label">Icono de la aplicación</label>
                <input type="file" class="form-control" id="iconUrl" formControlName="icon_url"
                       (change)="onFileSelected($event)">
                <!--                <div-->
                <!--                  *ngIf="appRequestForm.get('icon_url').invalid && (appRequestForm.get('icon_url').dirty || appRequestForm.get('icon_url').touched)">-->
                <!--                  <div *ngIf="appRequestForm.get('icon_url').errors.required" class="text-danger">Se requiere una-->
                <!--                    imagen.-->
                <!--                  </div>-->
                <!--                  <div *ngIf="appRequestForm.get('icon_url').errors.invalidImage" class="text-danger">El archivo-->
                <!--                    seleccionado no es una imagen válida (jpg, jpeg, png, gif).-->
                <!--                  </div>-->
                <!--                </div>-->
                <img *ngIf="appRequestForm.get('icon_url').value" [src]="appRequestForm.get('icon_url').value"
                     class="img-thumbnail mt-2" style="max-width: 100px;">
              </div>
              <div class="mb-3">
                <label for="developer" class="form-label">Desarrollador</label>
                <input type="text" class="form-control" id="developer" formControlName="developer">
              </div>
              <div class="mb-3">
                <label for="description" class="form-label">Descripción</label>
                <textarea class="form-control" id="description" formControlName="description"></textarea>
              </div>
              <div class="mb-3">
                <label for="details" class="form-label">Detalles</label>
                <textarea class="form-control" id="details" formControlName="details"></textarea>
              </div>
              <div class="mb-3">
                <label for="appPayment" class="form-label">Tipo de pago</label>
                <select class="form-select" id="appPayment" formControlName="appPayment">
                  <option value="" selected disabled>Seleccione el plan de pago</option>
                  <option value="free">Gratis</option>
                  <option value="paid">Pago</option>
                </select>
              </div>
              <div class="mb-3" *ngIf="showPriceInput">
                <label for="price" class="form-label">Precio</label>
                <input type="number" class="form-control" id="price" formControlName="price">
              </div>
              <div class="mb-3">
                <label for="categories" class="form-label">Categorías</label>
                <select class="form-select" id="categories" formControlName="categories">
                  <option value="" selected disabled>Seleccione una categoría</option>
                  <option *ngFor="let category of appCategories" [value]="category">{{ translateCategory(category) }}
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <uppy-dashboard
                  [uppy]="uppy"
                  [props]="uppyDashboardProps">
                </uppy-dashboard>
              </div>

              <button type="submit" class="btn btn-primary float-end">Enviar solicitud</button>
            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>