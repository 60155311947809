<div class="card-body" *ngIf="whatsAppMessageTemplate">
  <pre
    class="scrollbar"
    style="font-size: 0.5rem; max-height: 120px;"
    *ngIf="false">
    {{ whatsAppMessageTemplate | json }}
  </pre>
  <div class="deals-items-head d-flex align-items-center mb-1">
    <p class="text-primary fw-bold line-clamp-1 me-3 mb-0">
      {{ whatsAppMessageTemplate?.name }}
    </p>
  </div>
  <div class="d-flex flex-between-center mt-2 overflow-x-scroll scrollbar">
    <div class="d-flex align-items-center">
      <span class="badge badge-phoenix badge-phoenix-info ms-1"
          *ngIf="whatsAppMessageTemplate.category === WhatsAppMessageTemplateType.Marketing">
        Marketing
      </span>
      <span class="badge badge-phoenix badge-phoenix-info ms-1"
          *ngIf="whatsAppMessageTemplate.category === WhatsAppMessageTemplateType.Utility">
        Utilidad
      </span>
      <span class="badge badge-phoenix badge-phoenix-info ms-1"
          *ngIf="whatsAppMessageTemplate.category === WhatsAppMessageTemplateType.Authentication">
        Autenticación
      </span>
      <span class="badge badge-phoenix badge-phoenix-warning ms-1"
          *ngIf="whatsAppMessageTemplate.parametersCount > 0">
        Con parámetros ({{ whatsAppMessageTemplate.parametersCount }})
      </span>
      <span class="badge badge-phoenix badge-phoenix-secondary ms-1"
            *ngIf="whatsAppMessageTemplate.language">
        {{ whatsAppMessageTemplate.language }}
      </span>
    </div>
  </div>
  <div class="card mt-3">
    <ng-container *ngIf="whatsAppMessageTemplate.headerComponent !== undefined">
      <img *ngIf="
          whatsAppMessageTemplate.headerComponent.format === WhatsAppMessageTemplateComponentHeaderType.Image &&
          whatsAppMessageTemplate.headerComponent.example"
           class="card-img-top"
           [src]="whatsAppMessageTemplate.headerComponent.example.header_handle[0]">

      <video *ngIf="
          whatsAppMessageTemplate.headerComponent.format === WhatsAppMessageTemplateComponentHeaderType.Video &&
          whatsAppMessageTemplate.headerComponent.example"
           class="card-img-top"
           controls
           autoplay
           [src]="whatsAppMessageTemplate.headerComponent.example.header_handle[0]">
      </video>
      <div *ngIf="
          whatsAppMessageTemplate.headerComponent.format === WhatsAppMessageTemplateComponentHeaderType.Document &&
          whatsAppMessageTemplate.headerComponent.example"
           class="card-document-top d-flex align-items-center justify-content-center">
        <i class="fa-duotone fa-file-pdf card-document-top-file"></i>
      </div>
      <ng-container *ngIf="whatsAppMessageTemplate.headerComponent.format === WhatsAppMessageTemplateComponentHeaderType.Location">
        <div class="card-document-top d-flex align-items-center justify-content-center">
          <i class="fa-duotone fa-location-dot card-document-top-file"></i>
        </div>
        <div class="card-document-top-data p-2 d-flex flex-column justify-content-start text-dark">
          <p class="mb-1">
            <strong>
              {{ '{{Location name\}\}' }}
            </strong>
          </p>
          <p class="mb-0"> {{ '{{Address\}\}' }} </p>
        </div>
      </ng-container>
    </ng-container>
    <div class="card-body">
      <ng-container *ngIf="whatsAppMessageTemplate.headerComponent !== undefined">
        <p *ngIf="whatsAppMessageTemplate.headerComponent.format === WhatsAppMessageTemplateComponentHeaderType.Text">
          {{ whatsAppMessageTemplate.headerComponent.text }}
        </p>
      </ng-container>
      <ng-container *ngIf="whatsAppMessageTemplate.bodyComponent !== undefined">
        <pre class="card-text mb-0" style="white-space: pre-wrap;">{{ whatsAppMessageTemplate.bodyComponent.text }}</pre>
      </ng-container>
      <ng-container *ngIf="whatsAppMessageTemplate.footerComponent !== undefined">
        <p class="mt-3 pb-1 mb-0 text-muted fs--1">
          {{ whatsAppMessageTemplate.footerComponent.text }}
        </p>
      </ng-container>
    </div>
    <div class="card-footer py-2" *ngIf="whatsAppMessageTemplate.buttonsComponent">
      <div class="d-grid">
        <ng-container
          *ngFor="let button of whatsAppMessageTemplate.buttonsComponent.buttons; let last = last;">
          <button
            *ngIf="button.type === WhatsAppMessageTemplateButtonType.QuickReply"
            type="button"
            class="btn btn-phoenix-primary mb-1">
            <i class="fa fa-reply me-1"></i>
            {{ button.text }}
          </button>
          <button
            *ngIf="button.type === WhatsAppMessageTemplateButtonType.Url"
            type="button"
            class="btn btn-phoenix-primary mb-1">
            <i class="fa-duotone fa-arrow-up-right-from-square me-1"></i>
            {{ button.text }}
          </button>
          <button
            *ngIf="button.type === WhatsAppMessageTemplateButtonType.PhoneNumber"
            type="button"
            class="btn btn-phoenix-primary mb-1">
            <i class="fa-duotone fa-phone me-1"></i>
            {{ button.text }}
          </button>
          <button
            *ngIf="button.type === WhatsAppMessageTemplateButtonType.CopyCode"
            type="button"
            class="btn btn-phoenix-primary mb-1">
            <i class="fa-duotone fa-copy me-1"></i>
            {{ button.text }}
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>