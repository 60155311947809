import { PaginatedSearchRequest } from '../../common/paginated-search-request.dto';

export class BoardSearchRequest extends PaginatedSearchRequest {

  shopId: number;
  searchTerm: string;

  constructor(shopId: number, size: number) {
    super();
    this.shopId = shopId;
    this.size = size;
  }

}