
export class CustomButton {

  index: number;
  text: string;

  constructor() {
    this.text = '';
  }

}