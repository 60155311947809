import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: 'app-landing-page-header-component',
  templateUrl: `./landing-page-header.component.html`,
  styleUrls: ['./landing-page-header.component.css'],
})
export class LandingPageHeaderComponent implements OnInit {

  constructor(
    private router: Router,
  ) {

  }

  ngOnInit() {
  }

}
