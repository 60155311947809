<div class="row">
  <div class="col-xl-12">
    <div class="card shadow-none border border-300">
      <div class="card-header p-4 border-bottom border-300 bg-soft px-3">
        <div class="row g-3 justify-content-between align-items-center">
          <div class="col-12 col-xl-auto">
            <h4 class="text-900 mb-0">
              <i class="fa fa fa-solid fa-layer-group text-primary"></i>
              <span class="text-dark">
                Grupos de respuestas rápidas
              </span>
            </h4>
          </div>
          <div class="col col-md-auto">
            <button type="button" class="btn btn-sm btn-phoenix-primary" (click)="toggleNewGroupForm()">
              <i class="fa fa-plus mr-2"></i> Crear nuevo
            </button>
          </div>
        </div>
        <div class="row">
          <p class="text-700 lh-sm mt-2 mb-0">
            Organiza tus respuestas rápidas
          </p>
        </div>
        <div *ngIf="showNewGroupForm" class="card card-body scrollbar mb-0">
          <form [formGroup]="quickReplyGroupForm" (ngSubmit)="saveGroup()">
            <div class="row mb-3">
              <div class="col-12">
                <div class="form-floating">
                  <input
                    class="form-control"
                    id="newGroup"
                    type="text"
                    maxlength="150"
                    placeholder="Nombre del grupo" formControlName="name"
                    [ngClass]="{
                      'is-invalid': quickReplyGroupForm.get('name')?.touched && quickReplyGroupForm.get('name')?.invalid || duplicatedName,
                      'is-valid': quickReplyGroupForm.get('name')?.valid && !duplicatedName
                    }"
                  />
                  <label for="newGroup">
                    Nombre del grupo
                  </label>
                  <div class="invalid-feedback text-warning"
                    *ngIf="quickReplyGroupForm.get('name')?.touched && quickReplyGroupForm.get('name')?.invalid">
                    Ingrese un nombre válido para el grupo, por favor.
                  </div>
                  <div class="invalid-feedback text-warning" *ngIf="duplicatedName">
                    El grupo ya existe, ingrese otro nombre.
                  </div>
                </div>
              </div>
            </div>
            <button
              type="submit"
              class="btn btn-primary me-2"
              [disabled]="quickReplyGroupForm.invalid">
              Crear
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              (click)="toggleNewGroupForm()">
              Cancelar
            </button>
          </form>
        </div>
      </div>
      <div class="row g-4 my-4 mx-3">
        <div *ngFor="let group of groups" class="col-xl-3 col-lg-4 col-md-6 col-sm-6 mt-0 mb-3">
          <div class="card h-100">
            <div class="card-header bg-soft">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="card-title mb-0 me-2" *ngIf="editingGroupId !== group.id">
                  {{ group.name }}
                </h5>
                <div class="input-group" *ngIf="editingGroupId === group.id">
                  <input [(ngModel)]="editedGroupName"
                         class="form-control form-control-sm"
                         style="max-width: 150px;"
                         (keyup.enter)="saveGroupName(group)"
                         (keyup.escape)="cancelEdit()">
                </div>
                <div class="btn-group ms-2">
                  <button class="btn btn-outline-primary btn-sm" (click)="editGroup(group)">
                    <i class="fa-regular"
                       [ngClass]="{
                          'fa-pen-to-square': editingGroupId !== group.id,
                          'fa-save': editingGroupId === group.id
                       }">
                    </i>
                  </button>
                  <button class="btn btn-outline-primary btn-sm" 
                    (click)="openEditGroup(group)">
                    <i class="fa-solid fa-plus"></i>
                  </button>
                  <button class="btn btn-outline-danger btn-sm" (click)="deleteGroup(group)">
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            </div>
              <div class="card-body">
                <p class="m-3" *ngIf="group.quickReplies?.length === 0">
                  Sin respuestas rápidas para este grupo.
                </p>
                <ul class="list-group list-group-flush">
                  <li *ngFor="let quickReply of group.quickReplies" class="list-group-item">
                    <div class="d-flex justify-content-between">
                      {{ quickReply.name }}
                      <i class="fa fa-trash text-danger" role="button"
                         (click)="removeQuickReplyFromGroup(quickReply, group)"></i>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>

<ng-template #editGroupModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      <strong>{{ selectedGroup?.name }}</strong>
    </h4>
  </div>
  <div class="modal-body scrollbar" [class.p-0]="availableQuickReplies.length > 0">
    <ul class="list-group list-group-flush">
      <li *ngFor="let quickReply of availableQuickReplies" class="list-group-item">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <strong>
              {{ quickReply.name | slice: 0:15 }}
            </strong>
            <span *ngIf="quickReply.textItem">
              - {{ quickReply.textItem.content | slice: 0:32 }}
            </span>
          </div>
          <div>
            <button type="button" class="btn btn-sm btn-phoenix-primary me-2"
                    (click)="addQuickReplyToGroup(quickReply, selectedGroup)">
              <i class="fa fa-plus mr-2"></i>
            </button>
          </div>
        </div>
      </li>
      <li *ngIf="availableQuickReplies.length === 0" class="list-group-item ps-0">
        <b>No hay respuestas rápidas disponibles.</b>
      </li>
    </ul>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Cerrar</button>
  </div>
</ng-template>

<ng-template #deleteGroupModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      Confirmar eliminación
    </h4>
  </div>
  <div class="modal-body">
    <p>¿Estás seguro de que deseas eliminar el grupo <b>{{ selectedGroup?.name }}</b>?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cancel click')">Cancelar</button>
    <button type="button" class="btn btn-danger" (click)="modal.close('confirm')">Eliminar</button>
  </div>
</ng-template>

<ng-template #removeQuickReplyModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      Confirmar remoción
    </h4>
  </div>
  <div class="modal-body">
    <p>¿Estás seguro de que deseas remover la respuesta rápida <b>{{ selectedQuickReply?.name }}</b> del grupo <b>{{ selectedGroup?.name }}</b>?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cancel click')">Cancelar</button>
    <button type="button" class="btn btn-danger" (click)="modal.close('confirm')">Remover</button>
  </div>
</ng-template>