
export class SaveChatRequest {

  id: number;
  shopId: number;
  chatName: string;
  chatPhoneNumber: string;
  contactExtraPhoneNumber: string;
  contactEmail: string;
  contactStreet: string;
  contactColony: string;
  contactCountry: string;
  contactState: string;
  contactCity: string;
  contactAddressReference: string;

}