import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { validateOnlyNumbers } from "@app/utils/phone-utils";
import { SendMessageRequest } from "@models/chat/message/send-message-request.dto";
import { SendMessageRecipientRequest, SendWhatsAppMessageRequest, SendWhatsAppTextRequest } from "@models/chat/message/whatsapp/whatsapp-message-requests.dto";
import { v4 as uuidv4 } from "uuid";
import { MessageType } from "@type/chat/message-content.type";
import { ChannelType } from "@type/communication/channel.type";
import { MessageService } from "@services/chat/message.service";
import { UserInfo } from "@models/account/user/user.info.dto";
import { AuthService } from "@services/auth/auth.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-chat-whatsapp-web-message-sender",
  templateUrl: "./chat-whatsapp-web-message-sender.component.html",
  styleUrls: ["./chat-whatsapp-web-message-sender.component.css"],
})
export class ChatWhatsappWebMessageSenderComponent implements OnInit, OnDestroy {

  public selectedCountryCode: any;
  public recipientForm: FormGroup;
  public sendingMessage: boolean = false;

  private currentUser: UserInfo;

  constructor(
    private offCanvasService: NgbOffcanvas,
    private messageService: MessageService,
    private authService: AuthService,
    private toastr: ToastrService,
  ) {
    this.currentUser = this.authService.getUser();
  }

  ngOnInit() {
    this.initializeRecipientForm();
  }

  ngOnDestroy() {
  }

  private initializeRecipientForm() {
    this.recipientForm = new FormGroup({
      chatPhoneNumber: new FormControl('', [Validators.required]),
      message: new FormControl('', [Validators.required]),
    });

    this.recipientForm.get('chatPhoneNumber').valueChanges.subscribe((value) => {
      if (value) {
        this.recipientForm.get('chatPhoneNumber').setValue(value.trim().replaceAll(' ', ''));
      }
    });
  }

  public setSelectedCountryCode(selectedCountryCode: any) {
    this.selectedCountryCode = selectedCountryCode;
  }

  public validatePhoneNumber(event: any) {
    validateOnlyNumbers(event);
  }

  public sendMessage() {

    this.sendingMessage = true;
    const sendMessageRequest = this.getSendMessageRequest();

    this.messageService.sendMessage(sendMessageRequest).subscribe({
      next: () => {
        this.sendingMessage = false;
        this.toastr.success("Mensaje enviado correctamente");
        this.close();
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  private getSendMessageRequest() {

    const { chatPhoneNumber, message,  } = this.recipientForm.controls;

    const sendMessageRequest = new SendMessageRequest();

    const identifier = uuidv4();

    sendMessageRequest.type = MessageType.Text;
    sendMessageRequest.shopId = this.currentUser.shop.id;
    sendMessageRequest.identifier = identifier;
    sendMessageRequest.channelType = ChannelType.WhatsAppWeb;
    sendMessageRequest.senderId = this.currentUser.id;
    sendMessageRequest.sentBy = this.currentUser.name;

    const sendMessageRecipientRequest = new SendMessageRecipientRequest();
    sendMessageRecipientRequest.chatId = null;
    sendMessageRecipientRequest.chatName = null;
    sendMessageRecipientRequest.chatPhoneNumber = `${this.selectedCountryCode.code}${chatPhoneNumber.value.trim()}`;

    const sendWhatsAppMessageRequest = new SendWhatsAppMessageRequest();

    const sendWhatsAppTextRequest = new SendWhatsAppTextRequest();
    sendWhatsAppTextRequest.body = message.value;

    sendWhatsAppMessageRequest.text = sendWhatsAppTextRequest;
    sendMessageRequest.sendWhatsAppMessageRequest = sendWhatsAppMessageRequest;

    sendMessageRequest.recipient = sendMessageRecipientRequest;

    return sendMessageRequest;
  }

  public close() {
    this.offCanvasService.dismiss();
  }

}
