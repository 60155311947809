// Angular
import {
  AfterViewInit,
  Component, ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

// Toastr
import { ToastrService } from 'ngx-toastr';

// RxJS
import {
  debounceTime,
  distinctUntilChanged, lastValueFrom,
  of,
  Subscription,
  switchMap
} from 'rxjs';

// Quick Reply Models
import { QuickReplyInfo } from '@app/models/chat/quick-reply/quick-reply-info.dto';
import { QuickReplyRequest } from '@app/models/chat/quick-reply/quick-reply-request.dto';
import { QuickReplyItemInfo } from '@app/models/chat/quick-reply/item/quick-reply-item-info.dto';
import { QuickReplyItemRequest } from '@app/models/chat/quick-reply/item/quick-reply-item-request.dto';
import { QuickReplySearchRequest } from '@app/models/chat/quick-reply/quick-reply-search-request.dto';
import { CheckQuickReplyNameExistenceRequest } from '@app/models/chat/quick-reply/check-quick-reply-name-existence-request.dto';

import { Page } from '@app/models/common/page';
import { UserInfo } from '@app/models/account/user/user.info.dto';

// Services
import { AuthService } from '@app/services/auth/auth.service';
import { QuickReplyService } from '@app/services/chat/quick-reply.service';
import Uppy, { UppyFile } from '@uppy/core';
import Dashboard, { DashboardOptions } from '@uppy/dashboard';
import { UppyLocaleValues } from '@constants/uppy-constants';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import AwsS3 from '@uppy/aws-s3';
import { GetSignedUrlRequest } from '@models/media/get-signed-url-request.dto';
import {
  getMessageTypeByMediaType, isMediaMessage,
  MessageType, MessageTypeMetadata,
  messageTypes
} from '@type/chat/message-content.type';
import { MessageService } from '@services/chat/message.service';
import { getTypes } from '@app/utils/enum-utils';
import Sortable from 'sortablejs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Audio from '@uppy/audio';
import ScreenCapture from '@uppy/screen-capture';
import { Fancybox } from '@fancyapps/ui';
import { SpinnerService } from '@services/data/spinner.service';
import { formatBytes } from '@app/utils/file-utils';
import { QuickReplyButtonInfo } from '@models/chat/quick-reply/item/quick-reply-button-info.dto';
import { QuickReplyGroupInfo } from "@models/chat/quick-reply/group/quick-reply-group-info.dto";

@Component({
  selector: 'app-quick-replies',
  templateUrl: './quick-replies.component.html',
  styleUrls: ['./quick-replies.component.css'],
})
export class QuickRepliesComponent implements OnInit, AfterViewInit, OnDestroy {

  public MessageType = MessageType;
  public MessageTypes = getTypes(MessageType, MessageTypeMetadata);
  
  public quickRepliesForm: FormGroup;
  public duplicatedName: boolean = false;
  public sameName: boolean = false;
  public checkingDuplicatedName: string = 'before';
  public addingButton: boolean = false;

  private user: UserInfo;
  private componentSubscriptions: Subscription[] = [];

  public quickReplyButtons: QuickReplyButtonInfo[] = [];
  public selectedQuickReplyItem: QuickReplyItemInfo;
  public selectedQuickReply: QuickReplyInfo;
  public savingQuickReply: boolean = false;

  public firstLoad: boolean = true;
  public loadingQuickReplies: boolean = false;
  public quickReplies: QuickReplyInfo[] = [];
  private searchRequest: QuickReplySearchRequest;
  public searchQuickReplyFormControl: FormControl = new FormControl('');

  public filteredQuickReplies: QuickReplyInfo[] = [];
  public groups: QuickReplyGroupInfo[] = [];
  public selectedGroups: string = 'all';

  // Uppy
  public uppy: Uppy = new Uppy();
  public uppyDashboardProps: DashboardOptions;

  // Sortablejs
  @ViewChild('sortableList', { static: false })
  public sortableList: ElementRef;

  // Modals
  @ViewChild('showQuickReplyTextItemModal')
  private showQuickReplyTextItemModal: TemplateRef<any>;

  constructor(
    private elRef: ElementRef,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private quickReplyService: QuickReplyService,
    private messageService: MessageService,
    private authService: AuthService,
    private spinnerService: SpinnerService,
  ) {
    this.user = this.authService.getUser();
  }

  ngOnInit() {
    this.initializeQuickReplySearchRequest();
    this.initializeForm();
    this.initializeSearchQuickReplyFormControl();
    this.initializeUppy();
    this.loadQuickReplies();
    this.loadGroups(); 
  }

  ngAfterViewInit() {

    Fancybox.bind(this.elRef.nativeElement, '[data-fancybox]', {
      // Custom options
    });

  }

  ngOnDestroy() {
    this.componentSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  initializeForm() {

    this.selectedQuickReply = undefined;

    this.quickRepliesForm = new FormGroup({
      name: new FormControl('', []),
      content: new FormControl('', []),
      buttonText: new FormControl('', []),
    });

    this.checkingDuplicatedName = 'before';
    this.duplicatedName = false;
    this.sameName = false;

    this.initializeQuickReplyNameValidator();
  }

  initializeQuickReplySearchRequest() {
    this.searchRequest = new QuickReplySearchRequest(this.user.shop.id, -1);
  }

  private initializeQuickReplyNameValidator() {
    this.quickRepliesForm.get('name')
      .valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((query) => {
          return of(query.trim());
        })
      )
      .subscribe((name: string) => {
        this.checkQuickReplyNameExistence(name);
      });
  }

  private initializeSearchQuickReplyFormControl() {
    this.searchQuickReplyFormControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((query) => {
          return of(query.trim());
        })
      )
      .subscribe((searchTerm: string) => {
        this.searchRequest.searchTerm = searchTerm;
        this.loadQuickReplies();
      });
  }

  initializeUppy() {

    this.uppy = new Uppy({
      debug: false,
      autoProceed: false,
      locale: UppyLocaleValues,
    });

    this.uppy.on('files-added', (files: UppyFile[]) => {
      this.validateUppyFiles(files);
      if (this.uppy.getFiles().length > 0) {
        setTimeout(() =>
          document.querySelector('.uppy-Dashboard-files').classList.add('scrollbar'), 1);
      }
    });

    this.uppy.on('complete', () => {
      this.uppy.setState({
        files: {},
        currentUploads: {},
        totalProgress: 0,
      });
    });

    this.uppyDashboardProps = {
      width: '100%',
      height: 260,
      hideUploadButton: true,
    };

    this.uppy.use(ThumbnailGenerator);

    this.uppy.use(AwsS3, {
      id: 'uppy-aws-s3',
      getUploadParameters: async (uppyFile: UppyFile) => {

        const prefix = this.user.shop.uuid;
        const getSignedUrlRequest = new GetSignedUrlRequest(prefix, uppyFile.name, uppyFile.type);
        const response = await lastValueFrom(this.messageService.getWhatsAppSignedFileUrl(getSignedUrlRequest));

        (uppyFile as any).fileKey = response.key;

        return {
          method: 'PUT',
          url: response.signedUrl,
          headers: {
            'Content-Type': uppyFile.type,
          }
        }
      }
    });

    this.uppy.use(Dashboard, this.uppyDashboardProps);

    this.uppy.use(Audio, { target: Dashboard });
    this.uppy.use(ScreenCapture, {
      target: Dashboard,
      preferredVideoMimeType: 'video/webm',
      displayMediaConstraints: {
        video: {
          frameRate: 30,
        }
      }
    });

    const theme = localStorage.getItem('phoenixTheme');

    if (theme === 'dark' || theme === 'light') {
      this.uppyDashboardProps.theme = theme;
    }
  }

  private validateUppyFiles(uppyFiles: UppyFile[]) {

    const invalidFiles: UppyFile[] = [];

    for (const uppyFile of uppyFiles) {

      const { type, size } = uppyFile;
      const { sizeLimit } = messageTypes[getMessageTypeByMediaType(type)];

      if (size > sizeLimit) {
        invalidFiles.push(uppyFile);
        this.uppy.removeFile(uppyFile.id);
      }

    }

    for (const invalidFile of invalidFiles) {
      this.toastr.warning(`${(invalidFile.size / 1048576).toFixed(2)} MB`, `${invalidFile.name}`);
    }

    if (invalidFiles.length > 0) {
      let message = 'Los siguientes archivos no pudieron ser agregados debido a que superan el límite de tamaño';
      this.toastr.info(message);
    }
  }

  public filterByGroups(event?: Event) {
    
    if (this.selectedGroups === 'all') {
      this.filteredQuickReplies = this.quickReplies;
    } else {
    
      this.filteredQuickReplies = this.quickReplies.filter(qr => 
        qr.groups.some(group => group.name === this.selectedGroups)
      );
    }
  }

  loadGroups() {
    this.quickReplyService.getQuickReplyGroups().subscribe({
      next: (groups: QuickReplyGroupInfo[]) => {
        this.groups = groups;
      },
      error: (error: any) => {
        console.error('Error loading groups:', error);
        this.toastr.error('Error al cargar las categorias');
      }
    });
  }

  loadQuickReplies() {

    this.loadingQuickReplies = true;
    this.searchRequest.page = 0;

    this.spinnerService.show('Cargando respuestas rápidas...');

    this.quickReplyService.searchQuickReplies(this.searchRequest).subscribe({
      next: (page: Page<QuickReplyInfo>) => {
        this.quickReplies = page.content;
        this.adjustQuickReplies(this.quickReplies);
        this.loadingQuickReplies = false;
        if (this.firstLoad) this.firstLoad = false;
        this.spinnerService.hide();
        this.filterByGroups(); 
        this.firstLoad = false;
      },
      error: (error: any) => {
        this.spinnerService.hide();
        console.error('Error en la carga de quick replies:', error);
      },
    });
   
  }

  openSaveQuickReply(quickReply?: QuickReplyInfo) {

    if (this.selectedQuickReply && this.selectedQuickReply.id === quickReply?.id)
      return;

    this.resetSaveQuickReply();

    if (quickReply) {

      this.selectedQuickReply = quickReply;
      this.adjustQuickReplyItems(this.selectedQuickReply);
      this.quickRepliesForm.get('name').setValue(this.selectedQuickReply.name);

      if (this.selectedQuickReply.textItem) {
        this.quickRepliesForm.get('content').setValue(this.selectedQuickReply.textItem.content);
      } else {
        this.quickRepliesForm.get('content').setValue('');
      }

      this.quickReplyButtons = this.selectedQuickReply.buttons;

      setTimeout(() => {

        new Sortable(this.sortableList.nativeElement, {
          animation: 150,
          onStart: (event) => {
            console.log('event', event);
          },
          onEnd: (event) => {
            console.log('event', event);
          }
        });
        document.querySelector('.uppy-Dashboard-AddFiles-list').classList.add('scrollbar');

      }, 0);


    } else {

      this.quickReplyButtons = [];
      this.selectedQuickReply = undefined;
      this.quickRepliesForm.get('name').setValue('');
      this.quickRepliesForm.get('content').setValue('');
    }

  }

  private adjustQuickReplyItems(quickReply: QuickReplyInfo) {
    for (const quickReplyItem of quickReply.items) {
      quickReplyItem.typeName = this.MessageTypes.find(type => type.value === quickReplyItem.type).description;
      quickReplyItem.size = formatBytes(quickReplyItem.fileSize);
    }
  }

  private adjustQuickReplies(quickReplies: QuickReplyInfo[]) {
    for (const quickReply of quickReplies) {
      quickReply.textItem = quickReply.items.find(i => i.type === MessageType.Text);
      quickReply.mediaItems = quickReply.items.filter(i => isMediaMessage(i.type));
    }
  }

  public cancelSaveQuickReply() {
    this.initializeForm();
    this.uppy.cancelAll({ reason: 'user' });
    this.savingQuickReply = false;
  }

  private resetSaveQuickReply() {
    this.initializeForm();
    this.uppy.cancelAll({ reason: 'user' });
    this.savingQuickReply = true;
  }

  public deleteQuickReply(quickReply: QuickReplyInfo) {

    if (confirm('¿Está seguro que desea eliminar la respuesta rápida?')) {

      this.selectedQuickReply = undefined;
      this.savingQuickReply = false;

      this.quickReplyService.deleteQuickReply(quickReply.id).subscribe({
        next: () => {
          this.loadQuickReplies();
          this.toastr.success('Etiqueta eliminada exitosamente');
        },
        error: (error: any) => {
          console.error('Error al eliminar la etiqueta:', error);
          this.toastr.error('Error al eliminar la etiqueta');
        },
      });

    }

  }

  public showQuickReplyTextItem() {

    const modalRef = this.modalService.open(this.showQuickReplyTextItemModal, {
      size: 'xl',
      centered: true,
      scrollable: true,
    });

    modalRef.dismissed.subscribe(() => {
      this.quickRepliesForm.get('content').setValue(this.quickRepliesForm.get('content').value);
    });
  }

  public showQuickReplyItemDescription(quickReplyItem: QuickReplyItemInfo) {
    alert(quickReplyItem.description);
  }

  public onQuickReplyItemHover(quickReplyItem: QuickReplyItemInfo) {
    this.selectedQuickReplyItem = quickReplyItem;
  }

  public deleteQuickReplyItem(quickReplyItem: QuickReplyItemInfo) {

    if (!confirm('¿Está seguro que desea eliminar el elemento?')) return;

    this.quickReplyService.deleteQuickReplyItem(quickReplyItem.id).subscribe({
      next: () => {
        this.selectedQuickReply.items = this.selectedQuickReply.items.filter(item => item.id !== quickReplyItem.id);
        this.selectedQuickReply.mediaItems = this.selectedQuickReply.mediaItems.filter(item => item.id !== quickReplyItem.id);
        this.toastr.success('Elemento eliminado exitosamente');
      },
      error: (error: any) => {
        console.error('Error al eliminar el elemento:', error);
        this.toastr.error('Error al eliminar el elemento');
      },
    });

  }

  private checkQuickReplyNameExistence(name: string) {

    if (!name || name.length === 0) {
      this.duplicatedName = false;
      return;
    }

    if (this.selectedQuickReply && this.selectedQuickReply.name === name) {
      this.duplicatedName = false;
      this.sameName = true;
      return;
    }

    this.sameName = false;

    this.checkingDuplicatedName = 'checking';
    const afterChecking: string = 'after-checking';

    const checkQuickReplyNameExistenceRequest =
      new CheckQuickReplyNameExistenceRequest(this.user.shop.id, name);

    this.quickReplyService
      .checkQuickReplyNameExistence(checkQuickReplyNameExistenceRequest)
      .subscribe({
        next: () => {
          this.duplicatedName = false;
          this.checkingDuplicatedName = afterChecking;
        },
        error: () => {
          this.duplicatedName = true;
          this.checkingDuplicatedName = afterChecking;
        },
      });
  }

  public addButton() {
    this.addingButton = true;
  }

  public removeButton(quickReplyButton: QuickReplyButtonInfo) {
    const index = this.quickReplyButtons.indexOf(quickReplyButton);
    if (index >= 0) {
      this.quickReplyButtons.splice(index, 1);
      }
  }

  public cancelAddButton() {
    this.addingButton = false;
    this.quickRepliesForm.get('buttonText').setValue('');
    this.quickRepliesForm.get('buttonText').markAsUntouched();
  }

  public submitAddButton() {
    const buttonText = this.quickRepliesForm.get('buttonText').value;
    if (buttonText && buttonText.length > 0) {
      this.quickReplyButtons.push(new QuickReplyButtonInfo(buttonText));
      this.quickRepliesForm.get('buttonText').setValue('');
      this.addingButton = false;
    }
  }

  public async saveQuickReply() {

    const allUploadResponse = await this.uppy.upload();

    const { name, content } = this.quickRepliesForm.value;
    const quickReplyId: number = this.selectedQuickReply ? this.selectedQuickReply.id : null;

    const quickReplyRequest = new QuickReplyRequest(
      this.user.shop.id, quickReplyId, name, [], []
    );

    const items: QuickReplyItemRequest[] = [];
    const buttons: QuickReplyButtonInfo[] = [];

    if (this.selectedQuickReply) {

      const textItem = this.selectedQuickReply.items.find(item => item.type === MessageType.Text);

      if (textItem) {
        const textItemRequest = new QuickReplyItemRequest(textItem.id, MessageType.Text);
        if (content) textItemRequest.content = content;
        else textItemRequest.deleted = true;
        items.push(textItemRequest);
      } else if (content) {
        const textItemRequest = new QuickReplyItemRequest(null, MessageType.Text);
        textItemRequest.content = content;
        items.push(textItemRequest);
      }

      for (const uploadedUppyFile of allUploadResponse.successful) {

        const { uploadURL: fileUrl, name, type, size, fileKey } = uploadedUppyFile as any;
        const messageType = getMessageTypeByMediaType(type);

        const quickReplyItemRequest = new QuickReplyItemRequest(null, messageType);
        quickReplyItemRequest.fileKey = fileKey;
        quickReplyItemRequest.fileUrl = fileUrl;
        quickReplyItemRequest.fileName = name;
        quickReplyItemRequest.fileContentType = type;
        quickReplyItemRequest.fileSize = size;

        items.push(quickReplyItemRequest);
      }

      for (const button of this.quickReplyButtons) {
        buttons.push(button);
      }

    } else {

      if (content) {
        const textItemRequest = new QuickReplyItemRequest(null, MessageType.Text);
        textItemRequest.content = content;
        items.push(textItemRequest);
      }

      for (const uploadedUppyFile of allUploadResponse.successful) {

        const { uploadURL: fileUrl, name, type, size, fileKey } = uploadedUppyFile as any;
        const messageType = getMessageTypeByMediaType(type);

        const quickReplyItemRequest = new QuickReplyItemRequest(null, messageType);
        quickReplyItemRequest.fileKey = fileKey;
        quickReplyItemRequest.fileUrl = fileUrl;
        quickReplyItemRequest.fileName = name;
        quickReplyItemRequest.fileContentType = type;
        quickReplyItemRequest.fileSize = size;

        items.push(quickReplyItemRequest);
      }

      for (const button of this.quickReplyButtons) {
        buttons.push(button);
      }

    }

    quickReplyRequest.items = items;
    quickReplyRequest.buttons = buttons

    this.quickReplyService.saveQuickReply(quickReplyRequest).subscribe({
      next: (savedQuickReply: QuickReplyInfo) => {
        this.checkingDuplicatedName = 'before';
        this.selectedQuickReply = savedQuickReply;
        this.selectedQuickReply.mediaItems = savedQuickReply.items.filter(item => isMediaMessage(item.type));
        this.adjustQuickReplyItems(this.selectedQuickReply);
        this.loadQuickReplies();
        this.toastr.success('Respuesta rápida creada exitosamente');
      },
      error: (httpErrorResponse: HttpErrorResponse) => {
        const { error } = httpErrorResponse;
        const errorCode = error.code;

        if (errorCode === 'DUPLICATED_QUICK_REPLY') {
          this.duplicatedName = true;
        }
      },
    });
  }

}
