
export class AddChatTagRequest {

  chatTagIds: number[];
  chatIds: number[];

  constructor(
    chatIds: number[],
    chatTagIds: number[],
  ) {
    this.chatIds = chatIds;
    this.chatTagIds = chatTagIds;
  }

}
