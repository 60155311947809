import {
  SmartAssistantTopicItemActionType
} from '@type/utilities/smart-assistant/smart-assistant-topic-item-action.type';
import { QuickReplyInfo } from "@models/chat/quick-reply/quick-reply-info.dto";
import { ChatTagInfo } from '@models/chat/tag/chat-tag-info.dto';

export class SmartAssistantTopicItemActionInfo {

  id: number;
  smartAssistantTopicItemId: number;
  actionPosition: number;
  message: string;
  httpCallUrl: string;
  httpCallInstructions: string;
  humanAssistantAttention: boolean;
  type: SmartAssistantTopicItemActionType;
  quickReply: QuickReplyInfo;
  chatTags: ChatTagInfo[];

  // Angular
  temporalId: string;
  typeDescription: string;
  removed: boolean = false;

}
