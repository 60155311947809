import { Component, OnInit } from '@angular/core';
import { SmartAssistantInfo } from '@models/utilities/smart-assistant/smart-assistant-info.dto';
import { SmartAssistantSearchRequest } from '@models/utilities/smart-assistant/smart-assistant-search-request.dto';
import { Page } from '@models/common/page';
import { UserInfo } from '@models/account/user/user.info.dto';
import { SmartAssistantService } from '@services/utilities/smart-assistant/smart-assistant.service';
import { AuthService } from '@services/auth/auth.service';
import { ChangeSmartAssistantStatusRequest } from '@models/utilities/smart-assistant/change-smart-assistant-status-request.dto';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { EventEmitterService, NotificationTopic } from '@services/data/event-emitter.service';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-smart-assistants-list',
  templateUrl: './smart-assistants-list.component.html',
  styleUrls: ['./smart-assistants-list.component.css']
})
export class SmartAssistantsListComponent implements OnInit {

  private user: UserInfo;
  public smartAssistants: SmartAssistantInfo[] = [];

  constructor(
    private readonly authService: AuthService,
    private readonly smartAssistantService: SmartAssistantService,
    private readonly toastr: ToastrService,
    private readonly eventEmitterService: EventEmitterService,
    private readonly toastrService: ToastrService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {
    this.user = this.authService.getUser();
  }

  ngOnInit() {
    this.initializeSmartAssistantSelectedEvent();
    this.loadSmartAssistants(() => this.checkRoute());
  }

  private initializeSmartAssistantSelectedEvent() {
    this.eventEmitterService.getEventEmitter(NotificationTopic.OpenSaveSmartAssistant).subscribe({
      next: (smartAssistant: SmartAssistantInfo) => {
        if (!smartAssistant) {
          this.loadSmartAssistants();

          this.router.navigate([], {
            queryParams: {},
          });
        }
      },
    });
  }

  private checkRoute() {

    const params = this.route.snapshot.queryParamMap;

    const uuid: string = params.get('id');
    if (uuid) {
      const smartAssistant = this.smartAssistants.find(sa => sa.uuid === uuid);
      if (smartAssistant) {
        this.openSaveSmartAssistant(smartAssistant);
      }
    }
  }

  private loadSmartAssistants(callback?: () => void) {

    const smartAssistantSearch = new SmartAssistantSearchRequest(
      this.user.shop.id, -1,
    );

    this.smartAssistantService.searchSmartAssistants(smartAssistantSearch).subscribe({
      next: (page: Page<SmartAssistantInfo>) => {
        this.smartAssistants = page.content;
        (window as any).phoenix.initEverything();
        callback && callback();
      },
      error: (error: any) => {
        console.error('error:', error);
        this.toastr.error('Error en la carga de asistentes');
      },
    });
  }

  public changeSmartAssistantStatus(smartAssistant: SmartAssistantInfo) {

    const changeSmartAssistantStatusRequest = new ChangeSmartAssistantStatusRequest(
      smartAssistant.id, !smartAssistant.enabled,
    );

    this.smartAssistantService.changeSmartAssistantStatus(changeSmartAssistantStatusRequest).subscribe({
      next: () => {
        this.loadSmartAssistants();
        this.toastr.success('Asistente actualizado exitosamente');
      },
      error: (httpErrorResponse: HttpErrorResponse) => {
        const { error } = httpErrorResponse;
        console.log('error', error);
        this.toastrService.error('Error al actualizar el asistente');
      },
    });

  }

  public openSaveSmartAssistant(smartAssistant?: SmartAssistantInfo) {
    if (!smartAssistant) {
      smartAssistant = new SmartAssistantInfo();
    }
    // Set the uuid in the url as a query parameter
    this.router.navigate([], {
      queryParams: {
        id: smartAssistant.uuid,
      },
    });
    this.eventEmitterService.emit(NotificationTopic.OpenSaveSmartAssistant, smartAssistant);
  }

  public openTestSmartAssistant(smartAssistant: SmartAssistantInfo) {
    this.eventEmitterService.emit(NotificationTopic.OpenTestSmartAssistant, {
      smartAssistant,
    });
  }

}
