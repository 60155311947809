
export class UpdateSmartAssistantTopicItemsRequest {

  smartAssistantId: number;
  changes: UpdateSmartAssistantTopicItemRequest[] = [];

}

export class UpdateSmartAssistantTopicItemRequest {

  smartAssistantTopicItemId: number;
  topicPosition: number;

  constructor(smartAssistantTopicItemId: number, topicPosition: number) {
    this.smartAssistantTopicItemId = smartAssistantTopicItemId;
    this.topicPosition = topicPosition;
  }

}