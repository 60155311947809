import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import * as RecordRTC from "recordrtc";

@Injectable({
  providedIn: 'root'
})
export class AudioRecordingService {
  
  private audioStream: MediaStream;
  private audioRecorder: any;
  private interval: any;
  private startTime: number;

  private recordingDoneEmitter: Subject<any> = new Subject<any>();
  private recordingTimeEmitter: Subject<string> = new Subject<string>();
  private recordingFailedEmitter: Subject<void> = new Subject<void>();

  getRecordedBlob(): Observable<any> {
    return this.recordingDoneEmitter.asObservable();
  }

  getRecordedTime(): Observable<string> {
    return this.recordingTimeEmitter.asObservable();
  }

  recordingFailed(): Observable<void> {
    return this.recordingFailedEmitter.asObservable();
  }

  startRecording() {

    if (this.audioRecorder) return;

    this.recordingTimeEmitter.next('00:00');
    
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((audioStream: MediaStream) => {
        
        this.audioStream = audioStream;
        this.record();

        this.visualizeRecording(audioStream);
      })
      .catch(() => {
        alert('Debes aceptar el permiso para poder grabar notas de voz');
        this.recordingFailedEmitter.next();
      });
  }

  visualizeRecording(audioStream: MediaStream) {
    
    var context = new AudioContext();
    var gainNode = context.createGain();

    var src = context.createMediaStreamSource(audioStream);
    src.connect(gainNode);
    gainNode.connect(context.destination);

    gainNode.gain.setTargetAtTime(0, context.currentTime, 0);

    var analyser = context.createAnalyser();

    var canvas = document.getElementById("audioVisualizerCanvas") as any;
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    var ctx = canvas.getContext("2d");

    src.connect(analyser);
    gainNode.connect(analyser);

    var WIDTH = canvas.width;
    var HEIGHT = canvas.height;

    if (WIDTH <= 480) analyser.fftSize = 64;
    if (WIDTH > 480 && WIDTH < 768) analyser.fftSize = 128;
    if (WIDTH >= 768) analyser.fftSize = 256;

    var bufferLength = analyser.frequencyBinCount;
    console.log(bufferLength);

    var dataArray = new Uint8Array(bufferLength);

    var barWidth = (WIDTH / bufferLength) * 5; // Aumento el ancho de las barras
    var barHeight: number;
    var x = 0;

    function renderFrame() {
      requestAnimationFrame(renderFrame);

      x = 0;

      analyser.getByteFrequencyData(dataArray);

      ctx.clearRect(0, 0, WIDTH, HEIGHT);

      ctx.fillStyle = "rgba(0,0,0,0)";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      for (var i = 0; i < bufferLength; i++) {
        barHeight = dataArray[i] * 2; // Aumento la altura de las barras

        var r = barHeight + 25 * (i / bufferLength);
        var g = 250 * (i / bufferLength);
        var b = 50;

        ctx.fillStyle = "rgb(" + r + "," + g + "," + b + ")";
        ctx.fillRect(x, HEIGHT - barHeight, barWidth, barHeight);

        x += barWidth + 5; // Aumento la separación entre barras
      }
    }

    renderFrame();
  }

  abortRecording() {
    this.stopMedia();
  }

  private record() {

    this.audioRecorder = new RecordRTC.StereoAudioRecorder(this.audioStream, {
      type: 'audio',
      mimeType: 'audio/webm',
    });

    this.audioRecorder.record();
    this.startTime = Date.now();
    this.interval = setInterval(() => {
      const elapsedTime = this.calculateElapsedTime();
      const time = this.formatTime(elapsedTime);
      this.recordingTimeEmitter.next(time);
    }, 1000);
  }

  private calculateElapsedTime(): number {
    const currentTime = Date.now();
    return Math.floor((currentTime - this.startTime) / 1000);
  }

  private formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = this.padWithZero(minutes);
    const formattedSeconds = this.padWithZero(remainingSeconds);
    return `${formattedMinutes}:${formattedSeconds}`;
  }

  private padWithZero(value: number): string {
    return value < 10 ? `0${value}` : value.toString();
  }

  stopRecording() {
    
    if (!this.audioRecorder) return;

    const recordingFailed = () => {
      this.recordingFailedEmitter.next();
      this.stopMedia();
    };

    const recordingSuccess = (blob: any) => {
      if (!this.startTime) return;
      this.stopMedia();
      const audioName = encodeURIComponent(`audio_${Date.now()}.mp3`);
      this.recordingDoneEmitter.next({ blob, audioName });
    }

    this.audioRecorder.stop(recordingSuccess, recordingFailed);
  }

  private stopMedia() {
    if (!this.audioRecorder) return;
    clearInterval(this.interval);
    this.audioRecorder = null;
    this.startTime = null;
    if (this.audioStream) {
      this.audioStream.getTracks().forEach((track) => track.stop());
      this.audioStream = null;
    }
  }
}
