// Angular
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { HttpErrorResponse } from "@angular/common/http";

// NgBootstrap
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";

// Angular Datatables
import { DataTableDirective } from "angular-datatables";

// Toastr
import { ToastrService } from "ngx-toastr";

// RxJS
import {
  debounceTime,
  distinctUntilChanged,
  of,
  Subscription,
  switchMap,
} from "rxjs";

// Constants
import { TagsTableOptions } from "@app/constants/datatables-constants";

// Chat Tag Models
import { ChatInfo } from "@app/models/chat/chat/chat-info.dto";
import { ChatTagInfo } from "@app/models/chat/tag/chat-tag-info.dto";
import { ChatTagRequest } from "@app/models/chat/tag/chat-tag-request.dto";
import { ChatTagSearchRequest } from "@app/models/chat/tag/chat-tag-search-request.dto";
import { CheckChatTagNameExistenceRequest } from "@app/models/chat/tag/check-chat-tag-name-existence-request.dto";

import { DataTableContainer } from "@app/models/common/datatable-container";
import { Page } from "@app/models/common/page";
import { UserInfo } from "@app/models/account/user/user.info.dto";

// Services
import { AuthService } from "@app/services/auth/auth.service";
import { ChatTagService } from "@app/services/chat/chat-tag.service";
import { EventEmitterService } from "@app/services/data/event-emitter.service";
import { SpinnerService } from "../../../services/data/spinner.service";

@Component({
  selector: "app-chat-tags",
  templateUrl: "./chat-tags.component.html",
  styleUrls: ["./chat-tags.component.css"],
})
export class ChatTagsComponent implements OnInit, AfterViewInit, OnDestroy {
  
  public chatTagsForm: FormGroup;
  public duplicatedName: boolean = false;
  public sameName: boolean = false;
  public checkingDuplicatedName: string = 'before';
  public defaultColor: string = "#6E7891";

  private user: UserInfo;
  private componentSubscriptions: Subscription[] = [];

  @Input()
  public chat: ChatInfo;
  public selectedChatTag: ChatTagInfo;

  public firstLoad: boolean = true;
  public loadingChatTags: boolean = false;
  public chatTagsPage: Page<ChatTagInfo> = new Page<ChatTagInfo>();
  private searchRequest: ChatTagSearchRequest;
  public searchChatTagFormControl: FormControl = new FormControl('');

  // DataTables
  private defaultPageSize: number = 10;
  @ViewChild(DataTableDirective)
  public dataTable: DataTableDirective;
  public dtc: DataTableContainer = new DataTableContainer(
    this.defaultPageSize,
    TagsTableOptions(this.defaultPageSize)
  );

  // OffCanvas
  @ViewChild("saveChatTagOffCanvas")
  public saveChatTagOffCanvas: TemplateRef<any>;

  constructor(
    private toastr: ToastrService,
    private eventEmitterService: EventEmitterService,
    private spinnerService: SpinnerService,
    private chatTagService: ChatTagService,
    private authService: AuthService,
    private offCanvasService: NgbOffcanvas,
  ) {
    this.user = this.authService.getUser();
  }

  ngOnInit() {
    this.initializeChatTagSearchRequest();
    this.initializeDataTable();
    this.initializeForm();
    this.initializeChatTagNameValidator();
    this.initializeSearchChatTagFormControl();
  }

  ngAfterViewInit() {
    this.dtc.trigger.next(this.dtc.options);
  }

  ngOnDestroy() {
    this.componentSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  initializeForm() {

    this.selectedChatTag = undefined;

    this.chatTagsForm = new FormGroup({
      name: new FormControl("", [Validators.required]),
      color: new FormControl(this.defaultColor, [Validators.required]),
    });

    this.checkingDuplicatedName = 'before';
    this.duplicatedName = false;
    this.sameName = false;
  }

  initializeDataTable() {
    this.dtc.options.ajax = (params, callback, settings) => {
      this.dataTable.dtInstance.then((instance) => {
        this.dtc.instance = instance;
        this.dtc.callback = callback;
        this.loadChatTags();
      });
    };
  }

  initializeChatTagSearchRequest() {
    this.searchRequest = new ChatTagSearchRequest(
      this.user.shop.id,
      this.defaultPageSize,
    );
  }

  initializeChatTagNameValidator() {
    this.chatTagsForm.get("name")
      .valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((query) => {
          return of(query.trim());
        })
      )
      .subscribe((name: string) => {
        this.checkChatTagNameExistence(name);
      });
  }

  initializeSearchChatTagFormControl() {
    this.searchChatTagFormControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((query) => {
          return of(query.trim());
        })
      )
      .subscribe((searchTerm: string) => {
        this.searchRequest.searchTerm = searchTerm;
        this.loadChatTags();
      });
  }

  loadChatTags() {

    this.loadingChatTags = true;

    const { page } = this.dtc.instance.page.info();
    this.searchRequest.page = page;

    this.spinnerService.show('Cargando etiquetas...');

    this.chatTagService.searchChatTags(this.searchRequest).subscribe({
      next: (page: Page<ChatTagInfo>) => {
        this.chatTagsPage = page;
        if (this.chatTagsPage.content.length > 0) {
          this.dtc.callback({
            recordsTotal: page.totalElements,
            recordsFiltered: page.totalElements,
            data: [],
          });
        }
        if (this.firstLoad) this.firstLoad = false;
        this.loadingChatTags = false;
        this.spinnerService.hide();
      },
      error: (error: any) => {
        this.loadingChatTags = false;
        this.spinnerService.hide();
        console.error("Error en la carga de tags:", error);
      },
      complete: () => {},
    });
  }

  openSaveChatTag() {

    const offCanvasRef = this.offCanvasService.open(
      this.saveChatTagOffCanvas,
      {
        position: "end",
      }
    );

    offCanvasRef.hidden.subscribe(() => {
      this.initializeForm();
    });
  }

  openEditChatTag(chatTag: ChatTagInfo) {

    this.selectedChatTag = chatTag;

    this.chatTagsForm.get("name").setValue(chatTag.name);
    this.chatTagsForm.get("color").setValue(chatTag.color);

    this.openSaveChatTag();
  }

  showDeleteChatTagConfirmation(chatTag: ChatTagInfo) {

    if (confirm("¿Está seguro que desea eliminar la etiqueta?")) {

      this.chatTagService.deleteChatTag(chatTag.id).subscribe({
        next: () => {
          this.loadChatTags();
          this.toastr.success("Etiqueta eliminada exitosamente");
        },
        error: (error: any) => {
          console.error("Error al eliminar la etiqueta:", error);
          this.toastr.error("Error al eliminar la etiqueta");
        },
        complete: () => {},
      });

    }

  }

  checkChatTagNameExistence(name: string) {

    if (!name || name.length === 0) {
      this.duplicatedName = false;
      return;
    }

    if (this.selectedChatTag && this.selectedChatTag.name === name) {
      this.duplicatedName = false;
      this.sameName = true;
      return;
    }

    this.sameName = false;

    this.checkingDuplicatedName = "checking";
    const afterChecking = "after-checking";

    const checkChatTagNameExistenceRequest =
      new CheckChatTagNameExistenceRequest(this.user.shop.id, name);

    this.chatTagService
      .checkChatTagNameExistence(checkChatTagNameExistenceRequest)
      .subscribe({
        next: () => {
          this.duplicatedName = false;
          this.checkingDuplicatedName = afterChecking;
        },
        error: () => {
          this.duplicatedName = true;
          this.checkingDuplicatedName = afterChecking;
        },
        complete: () => {},
      });
  }

  saveChatTag() {

    const { name, color } = this.chatTagsForm.value;

    let chatTagId: number = this.selectedChatTag ? this.selectedChatTag.id : null;

    const chatTagRequest = new ChatTagRequest(
      this.user.shop.id,
      name,
      color,
      chatTagId
    );

    this.chatTagService.saveChatTag(chatTagRequest).subscribe({
      next: () => {
        this.loadChatTags();
        this.offCanvasService.dismiss();
        this.toastr.success("Etiqueta creada exitosamente");
      },
      error: (httpErrorResponse: HttpErrorResponse) => {
        const { error } = httpErrorResponse;
        const errorCode = error.code;

        if (errorCode === "DUPLICATED_CHAT_LABEL") {
          this.duplicatedName = true;
        }
      },
      complete: () => {},
    });
  }
}
