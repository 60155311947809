
<div *ngIf="message" class="mb-1 p-3 rounded-2 position-relative" [ngClass]="{
  'received-message-content border': message.customerMessage,
  'sent-message-content bg-primary light text-white': !message.customerMessage,
}">
  <app-chat-context-message
    *ngIf="message.contextMessage"
    [message]="message.contextMessage"
    [chat]="chat">
  </app-chat-context-message>
  <p
    [innerHTML]="message.content"
    class="mb-0 message-content"
    [class.dark-url]="message.customerMessage">
    {{ message.content }}
  </p>
  <span class="reaction-emoji" *ngIf="message.reactionEmoji">
    {{ message.reactionEmoji }}
  </span>
</div>
<ul class="list-unstyled mt-3">
  <li *ngFor="let button of buttons">
    <div class="d-grid">
      <button
        class="btn btn-sm btn-outline-primary mb-2">
        {{ button.reply.title }}
      </button>
    </div>
  </li>
</ul>