
export interface MessageTypeContent {
  mediaTypes: string[];
  sizeLimit?: number;
}

export enum MessageType {
  Text = 'text',
  Template = 'template',
  Document = 'document',
  File = 'file',
  Video = 'video',
  Image = 'image',
  Sticker = 'sticker',
  Audio = 'audio',
  Reaction = 'reaction',
  Contact = 'contacts',
  Button = 'button',
  Location = 'location',
  Note = 'note',
  Interactive = 'interactive',
}

export const MessageTypeMetadata = {
  [MessageType.Text]: {
    description: 'Texto',
  },
  [MessageType.Template]: {
    description: 'Plantilla',
  },
  [MessageType.Document]: {
    description: 'Documento',
  },
  [MessageType.File]: {
    description: 'Archivo',
  },
  [MessageType.Video]: {
    description: 'Video',
  },
  [MessageType.Image]: {
    description: 'Imagen',
  },
  [MessageType.Sticker]: {
    description: 'Sticker',
  },
  [MessageType.Audio]: {
    description: 'Audio',
  },
  [MessageType.Reaction]: {
    description: 'Reacción',
  },
  [MessageType.Contact]: {
    description: 'Contacto',
  },
  [MessageType.Button]: {
    description: 'Botón',
  },
  [MessageType.Location]: {
    description: 'Ubicación',
  },
  [MessageType.Note]: {
    description: 'Nota',
  },
};


export const messageTypes: Record<MessageType, MessageTypeContent> = {
  text: { mediaTypes: [] },
  template: { mediaTypes: [] },
  document: {
    mediaTypes: [
      'text/plain',
      'application/pdf',
      'application/vnd.ms-powerpoint',
      'application/msword',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ],
    sizeLimit: 104857600
  },
  file: {
    mediaTypes: [
      'text/plain',
      'application/pdf',
      'application/vnd.ms-powerpoint',
      'application/msword',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ],
    sizeLimit: 104857600
  },
  video: {
    mediaTypes: [
      'video/mp4',
      'video/3gp'
    ],
    sizeLimit: 16777216
  },
  image: {
    mediaTypes: [
      'image/jpeg',
      'image/png',
      'image/webp'
    ],
    sizeLimit: 5242880
  },
  sticker: {
    mediaTypes: [
      'image/webp'
    ],
    sizeLimit: 102400
  },
  audio: {
    mediaTypes: [
      'audio/webm',
      'audio/webm;codecs=opus',
      'audio/aac',
      'audio/mp4',
      'audio/mpeg',
      'audio/amr',
      'audio/ogg; codecs=opus'
    ],
    sizeLimit: 16777216
  },
  reaction: {
    mediaTypes: []
  },
  contacts: {
    mediaTypes: []
  },
  button: {
    mediaTypes: []
  },
  location: {
    mediaTypes: []
  },
  note: {
    mediaTypes: []
  },
  interactive: {
    mediaTypes: []
  }
};

export const mediaTypes: MessageType[] = [
  MessageType.Image,
  MessageType.Video,
  MessageType.Audio,
  MessageType.Sticker,
  MessageType.Document,
  MessageType.File,
];

export function isMediaMessage(messageType: MessageType) {
  return mediaTypes.includes(messageType);
}

export function getMessageTypeByMediaType(mediaType: string): MessageType {
  for (const messageType in messageTypes) {
    if (messageTypes[messageType].mediaTypes.includes(mediaType)) {
      return messageType as MessageType;
    }
  }
  return MessageType.Document;
}

export function getMessageTypeByValue(value: string): MessageType {
  const foundType = Object.values(MessageType).find(type => type.toLowerCase() === value.toLowerCase());
  return foundType !== undefined ? foundType : null;
}
