import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { BYPASS_LOG } from '../interceptor/auth.interceptor';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  private apiUrl: string = `${environment.apiUrl}/files/get`;

  constructor(
    private http: HttpClient,
  ) {

  }

  async getFileBlob(fileKey: string): Promise<Blob> {
    try {
      const response = await fetch(`${this.apiUrl}?fileKey=${fileKey}`);
      const blob = await response.blob();
      return blob;
    } catch (error) {
      throw new Error(`Error fetching image: ${error.message}`);
    }
  }

}
