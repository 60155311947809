import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { getUserRoutes } from '../constants/user-routes-constants';
import { UserRoleType } from '../type/account/user-role.type';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {

  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (!this.authService.isAuthenticated()) {

      this.router.navigate(['app/sign-in']).then(() => {
        console.log('Redirected to sign-in');
      });

      return false;

    } else {

      const user = this.authService.getUser();
      const userRoutes = getUserRoutes(user.role);

      for (const userRoute of userRoutes) {
        if (state.url.includes(userRoute)) {
          return true;
        }
      }

      if (user.role === UserRoleType.SystemAdmin)
        this.router.navigate(['admin/marketplace']).then(() => {
          console.log('Redirected to admin marketplace');
        });
      else
      this.router.navigate(['app/chat']).then(() => {
        console.log('Redirected to original route');
      });

      return false;
    }
  }
}