<form
  [formGroup]="messageTemplateHeaderForm">
  <div class="row mb-3
    pt-3 border-top border-dashed border-300
    pb-3 border-bottom border-dashed border-300
  ">
    <div class="col-12">
      <div class="row">
        <div class="col">
          <h4>
            Encabezado
            <span
              role="button"
              class="badge badge-phoenix badge-phoenix-info fs--2">
              Opcional
            </span>
          </h4>
          <p class="mb-1 fs--1">
            Agrega un título o elige el tipo de contenido multimedia que usarás para este encabezado.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <div class="form-floating">
            <select id="messageTemplateHeaderMainType" class="form-select"
                    (change)="changeMessageTemplateComponentHeaderMainType($event)"
                    formControlName="headerMainType">
              <option [value]="whatsAppMessageTemplateComponentHeaderMainType.value"
                      *ngFor="let whatsAppMessageTemplateComponentHeaderMainType of WhatsAppMessageTemplateComponentHeaderMainTypes">
                {{ whatsAppMessageTemplateComponentHeaderMainType.description }}
              </option>
            </select>
            <label for="messageTemplateHeaderMainType">
              Tipo de encabezado
            </label>
          </div>
        </div>
        <div class="col-8" *ngIf="selectedWhatsAppMessageTemplateComponentHeaderMainType === WhatsAppMessageTemplateComponentHeaderMainType.Text">
          <div class="form-floating mb-1">
            <input
              type="text"
              class="form-control"
              id="headerText"
              maxlength="60"
              placeholder="Nombre de la plantilla de mensaje de WhatsApp"
              formControlName="headerText">
            <label for="headerText">
              Escribir un texto en Spanish (MEX)
            </label>
          </div>
          <p class="fs--1">
            {{ messageTemplateHeaderForm.get('headerText')?.value.length }} de 60 caracteres
          </p>
        </div>
        <div class="col-8" *ngIf="selectedWhatsAppMessageTemplateComponentHeaderMainType === WhatsAppMessageTemplateComponentHeaderMainType.Media">
          <div class="row mb-3">
            <div class="col-3 px-1">
              <input
                [hidden]="true"
                class="form-control"
                [id]="'fileInput_' + MessageType.Image"
                type="file"
                [accept]="acceptTypes[MessageType.Image]"
                (change)="onFileChosen($event, MessageType.Image)"
              />
              <div class="card border border-primary message-template-header-type-card"
                   [class.message-template-header-type-card-selected]="selectedHeaderMessageType === MessageType.Image"
                   (mouseenter)="setSelectedHeaderMessageType(MessageType.Image)"
                   [ngbPopover]="messageTemplateHeaderMediaCardPopover"
                   (click)="openChooseFile(MessageType.Image)"
                   triggers="mouseenter:mouseleave"
                   popoverTitle="Información">
                <div class="card-body d-flex flex-column align-items-center justify-content-center">
                  <i class="fa-duotone fa-image-polaroid fs-2"></i>
                  <span class="message-template-header-type-label">
                    Imagen
                  </span>
                  <span class="text-center text-truncate w-100"
                        *ngIf="selectedHeaderMessageType === MessageType.Image && selectedFileByType[MessageType.Image] !== undefined">
                    {{ selectedFileByType[MessageType.Image]?.file?.name || selectedFileByType[MessageType.Image]?.fileName }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-3 px-1">
              <input
                [hidden]="true"
                class="form-control"
                [id]="'fileInput_' + MessageType.Video"
                type="file"
                [accept]="acceptTypes[MessageType.Video]"
                (change)="onFileChosen($event, MessageType.Video)"
              />
              <div class="card border border-primary message-template-header-type-card"
                   [class.message-template-header-type-card-selected]="selectedHeaderMessageType === MessageType.Video"
                   (mouseenter)="setSelectedHeaderMessageType(MessageType.Video)"
                   [ngbPopover]="messageTemplateHeaderMediaCardPopover"
                   (click)="openChooseFile(MessageType.Video)"
                   triggers="mouseenter:mouseleave"
                   popoverTitle="Información">
                <div class="card-body d-flex flex-column align-items-center justify-content-center">
                  <i class="fa-duotone fa-video fs-2"></i>
                  <span class="message-template-header-type-label">
                    Video
                  </span>
                  <span class="text-center text-truncate w-100"
                        *ngIf="selectedHeaderMessageType === MessageType.Video && selectedFileByType[MessageType.Video] !== undefined">
                    {{ selectedFileByType[MessageType.Video]?.file?.name || selectedFileByType[MessageType.Video]?.fileName }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-3 px-1">
              <input
                [hidden]="true"
                class="form-control"
                [id]="'fileInput_' + MessageType.Document"
                type="file"
                [accept]="'application/pdf'"
                (change)="onFileChosen($event, MessageType.Document)"
              />
              <div class="card border border-primary message-template-header-type-card"
                   [class.message-template-header-type-card-selected]="selectedHeaderMessageType === MessageType.Document"
                   (mouseenter)="setSelectedHeaderMessageType(MessageType.Document)"
                   [ngbPopover]="messageTemplateHeaderMediaCardPopover"
                   (click)="openChooseFile(MessageType.Document)"
                   triggers="mouseenter:mouseleave"
                   popoverTitle="Información">
                <div class="card-body d-flex flex-column align-items-center justify-content-center">
                  <i class="fa-duotone fa-file-pdf fs-2"></i>
                  <span class="message-template-header-type-label">
                    Documento
                  </span>
                  <span class="text-center text-truncate w-100"
                        *ngIf="selectedHeaderMessageType === MessageType.Document && selectedFileByType[MessageType.Document] !== undefined">
                    {{ selectedFileByType[MessageType.Document]?.file?.name || selectedFileByType[MessageType.Document]?.fileName }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-3 px-1">
              <div class="card border border-primary message-template-header-type-card"
                   (click)="selectLocationHeaderType()">
                <div class="card-body d-flex flex-column align-items-center justify-content-center">
                  <i class="fa-duotone fa-location-dot fs-2"></i>
                  <span class="message-template-header-type-label">
                    Ubicación
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<ng-template #messageTemplateHeaderMediaCardPopover>
  <h5 class="mb-1">
    <strong>
      Tamaño máximo del archivo
    </strong>
  </h5>
  <p class="mb-0">
    {{ selectedHeaderMessageTypeSizeLimit }}
  </p>
</ng-template>