import { Component, Input } from '@angular/core';
import { getStars } from '../../../marketplace-utils/review.utils';
import { AppPluginInfo } from '../../../../../models/marketplace/app-plugin-info.dto';
import { Router } from '@angular/router';

@Component({
  selector: 'app-favorite-apps-slider',
  templateUrl: './favorite-apps-slider.component.html',
  styleUrls: ['./favorite-apps-slider.component.css']
})
export class FavoriteAppsSliderComponent {

  activeIndex: number = 0;
  @Input() apps: AppPluginInfo[] = [];

  constructor(
    private router: Router,
  ) { }

  changeActiveIndex(index: number) {
    this.activeIndex = index;
  }


  public goToAppDetails(appId: number) {
    const url = `app/marketplace/app-detail/${appId}`;
    this.router.navigate([url]);
  }

  protected readonly getStars = getStars;
}
