
export class FileMetadata {

  uuid: string;
  fileUrl: string;
  fileKey: string;
  fileName: string;
  fileContentType: string;
  fileSize: number;

}