import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { UserInfo } from "@models/account/user/user.info.dto";
import { EventEmitterService, NotificationTopic } from "@services/data/event-emitter.service";
import { SpinnerService } from "@services/data/spinner.service";
import { AuthService } from "@services/auth/auth.service";
import { SmartAssistantOrchestratorInfo } from "@models/utilities/smart-assistant/orchestrator/smart-assistant-orchestrator-info.dto";
import { SaveSmartAssistantOrchestratorRequest } from "@models/utilities/smart-assistant/orchestrator/save-smart-assistant-orchestrator-request.dto";
import { SmartAssistantOrchestratorService } from "@services/utilities/smart-assistant/smart-assistant-orchestrator.service";

@Component({
  selector: "app-smart-assistant-orchestrator-info",
  templateUrl: "./smart-assistant-orchestrator-info.component.html",
  styleUrls: ["./smart-assistant-orchestrator-info.component.css"],
})
export class SmartAssistantOrchestratorInfoComponent implements OnInit {

  public user: UserInfo;

  public smartAssistantOrchestrator: SmartAssistantOrchestratorInfo;
  public smartAssistantOrchestratorForm: FormGroup;

  constructor(
    private readonly eventEmitterService: EventEmitterService,
    private readonly toastr: ToastrService,
    private readonly spinnerService: SpinnerService,
    private readonly smartAssistantOrchestratorService: SmartAssistantOrchestratorService,
    private readonly authService: AuthService,
  ) {
    this.user = this.authService.getUser();
  }

  ngOnInit() {
    this.initializeSmartAssistantSelectedEvent();
    this.initializeSmartAssistantOrchestratorForm();
  }

  private initializeSmartAssistantSelectedEvent() {

    this.eventEmitterService.getEventEmitter(NotificationTopic.OpenSaveSmartAssistantOrchestrator).subscribe({
      next: (smartAssistantOrchestrator: SmartAssistantOrchestratorInfo) => {
        this.smartAssistantOrchestrator = smartAssistantOrchestrator;
        this.setSmartAssistantOrchestratorInfo(smartAssistantOrchestrator);
      },
    });

  }

  private initializeSmartAssistantOrchestratorForm() {
    this.smartAssistantOrchestratorForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
    });
  }

  private setSmartAssistantOrchestratorInfo(smartAssistantOrchestrator: SmartAssistantOrchestratorInfo) {
    if (!smartAssistantOrchestrator) return;
    this.smartAssistantOrchestrator = smartAssistantOrchestrator;
    this.smartAssistantOrchestratorForm.get('name').setValue(smartAssistantOrchestrator.name);
  }

  public saveSmartAssistantOrchestrator() {

    const { name } = this.smartAssistantOrchestratorForm.value;

    let smartAssistantOrchestratorId: number = this.smartAssistantOrchestrator ? this.smartAssistantOrchestrator.id : null;

    const saveSmartAssistantRequest = new SaveSmartAssistantOrchestratorRequest(
      smartAssistantOrchestratorId, name, this.user.shop.id,
    );

    this.spinnerService.show('Guardando orquestador...')

    this.smartAssistantOrchestratorService.saveSmartAssistantOrchestrator(saveSmartAssistantRequest).subscribe({
      next: (smartAssistantOrchestrator: SmartAssistantOrchestratorInfo) => {
        this.eventEmitterService.emit(NotificationTopic.OpenSaveSmartAssistantOrchestrator, smartAssistantOrchestrator);
        this.toastr.success('Orquestador creado exitosamente');
        this.spinnerService.hide();
      },
      error: (httpErrorResponse: HttpErrorResponse) => {
        const { error } = httpErrorResponse;
        this.toastr.error('Error al crear el orquestador');
      },
      complete: () => {
      },
    });

  }

  public deleteSmartAssistantOrchestrator() {

    const smartAssistantOrchestrator = this.smartAssistantOrchestrator;

    if (!confirm('¿Está seguro que desea eliminar este orquestador?')) return;

    this.spinnerService.show('Eliminando orquestador...');

    this.smartAssistantOrchestratorService.deleteSmartAssistantOrchestrator(smartAssistantOrchestrator.id)
      .subscribe({
        next: () => {
          this.eventEmitterService.emit(NotificationTopic.OpenSaveSmartAssistantOrchestrator, undefined);
          this.toastr.success('Orquestador eliminado exitosamente');
          this.spinnerService.hide();
          this.showSmartAssistantOrchestratorList();
        },
        error: (httpErrorResponse: HttpErrorResponse) => {
          const { error } = httpErrorResponse;
          console.log('error', error);
        },
        complete: () => {
        },
      });
  }

  public showSmartAssistantOrchestratorList() {
    this.initializeSmartAssistantOrchestratorForm();
    this.eventEmitterService.emit(NotificationTopic.OpenSaveSmartAssistantOrchestrator, undefined);
  }

}
