import {
  SmartAssistantTopicItemActionInfo
} from '@models/utilities/smart-assistant/topic-item-action/smart-assistant-topic-item-action-info.dto';

export class SmartAssistantTopicItemInfo {

  id: number;
  createdAt: any;
  topicPosition: number;
  name: string;
  content: string;
  context: string;
  sendActions: boolean;
  actions: SmartAssistantTopicItemActionInfo[];

  constructor(id: number, topicPosition: number, name: string, content: string, createdAt: any) {
    this.id = id;
    this.topicPosition = topicPosition;
    this.name = name;
    this.content = content;
    this.createdAt = createdAt;
  }

}