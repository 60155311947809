<div class="row"
     [class.transparent]="firstLoad"
     [class.not-transparent]="!firstLoad">
  <div class="col-xl-3 col-lg-4 col-sm-3">
    <div class="card shadow-none border border-300 kanban-boards-list">
      <div class="card-header px-4 py-3 border-bottom border-300 bg-soft">
        <div class="row g-3 justify-content-between align-items-center">
          <div class="col-12 col-md">
            <h4 class="text-900 mb-0">
              <i class="fas fa-chart-kanban text-indigo"></i>
              Tableros
            </h4>
          </div>
          <div class="col col-md-auto">
            <nav class="nav nav-underline justify-content-end doc-tab-nav align-items-center" role="tablist">
              <button type="button" class="btn btn-sm btn-phoenix-primary"
                      (click)="openSaveBoard()">
                <i class="fa fa-plus"></i>
              </button>
            </nav>
          </div>
        </div>
        <div class="row">
          <p class="text-700 lh-sm mt-1 my-0">
            Organiza tus chats en tableros.
          </p>
        </div>
      </div>
      <div class="card-body kanban-boards-card-body-list scrollbar p-0">
        <p class="m-3" *ngIf="boards?.length == 0">
          Sin tableros, crea uno nuevo.
        </p>
        <ul class="list-group list-group-flush" *ngIf="boards?.length !== 0">
          <li class="list-group-item d-flex justify-content-between align-items-center"
              *ngFor="let board of boards">
            {{ board.name }}
            <div class="d-flex justify-content-end">
              <div class="cursor-pointer" (click)="openSaveBoard(board)">
                <i class="fa text-dark fa-edit" title="Editar"></i>
              </div>
              <div class="cursor-pointer" (click)="openBoard(board)">
                <i class="fa-duotone fa-arrow-up-right-from-square text-primary ms-2" title="Visualizar"></i>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-xl-9 col-lg-8 col-sm-9">
    <div class="kanban-content border rounded" *ngIf="selectedBoard !== undefined">
      <div class="kanban-header">
        <div class="row gx-0 justify-content-between justify-content-md-start">
          <div class="col-auto d-flex align-items-center">
            <h3 class="text-1000 my-1">
              <i class="fa-solid fa-square-kanban text-warning"></i>
              {{ selectedBoard?.name }}
            </h3>
          </div>
          <div class="col-md-auto d-flex align-items-center ms-auto mt-2 mt-md-0">
            <ul class="nav w-100 fs--1 d-flex align-items-center">
              <li class="nav-item">
                <a class="nav-link d-flex align-items-center text-900 ps-0 pe-2 px-xl-3 fw-bold"
                   href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#searchBoxModal">
                  <i class="me-1 fas fa-search"></i>
                  <span class="d-none d-xxl-inline">
                Buscar
              </span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link d-flex align-items-center text-900 px-2 px-xl-3 fw-bold" href="javascript:void(0);">
                  <i class="me-1 fas fa-filter"></i>
                  <span class="d-none d-xxl-inline">
                Filtrar
              </span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link d-flex align-items-center text-900 px-2 px-xl-3 fw-bold" href="javascript:void(0);">
                  <span class="me-1 fa-solid fa-right-left"></span>
                  <span class="d-none d-xxl-inline">
                Exportar / Importar
              </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="kanban-container smart-assistant-kanban scrollbar" data-kanban-container="data-kanban-container">
        <div class="kanban-column smart-topic"
             [class.collapsed]="boardColumn.collapsed"
             [attr.kanban-column-id]="boardColumn.id"
             *ngFor="let boardColumn of selectedBoard.columns">
          <div class="kanban-column-header px-4 hover-actions-trigger">
            <div class="d-flex justify-content-between align-items-center border-bottom border-3 py-3 border-primary">
              <h5 class="mb-0 kanban-column-title text-truncate" style="max-width: 80%"
                  data-bs-toggle="tooltip" data-bs-placement="top" [title]="boardColumn.name">
               <span class="smart-topic-name">
                  {{ boardColumn.name }}
               </span>
                <span class="kanban-title-badge">
                  {{ boardColumn.chats.totalElements }}
                </span>
              </h5>
              <div class="d-flex justify-content-end align-content-center">
                <div class="hover-actions-trigger">
                  <i class="fa fa-ellipsis-h cursor-pointer kanban-header-dropdown-btn me-3"
                     data-boundary="viewport"
                     data-bs-toggle="dropdown"></i>
                  <div class="dropdown-menu dropdown-menu-end py-2">
                    <a class="dropdown-item d-flex flex-between-center border-1" role="button"
                       (click)="openSaveBoardColumn(boardColumn)">
                    <span>
                      <i class="fa fa-edit me-2 text-primary"></i>
                      Editar
                    </span>
                    </a>
                    <a class="dropdown-item d-flex flex-between-center border-1" role="button"
                       (click)="deleteBoardColumn(boardColumn)">
                    <span>
                      <i class="fa fa-trash me-2 text-danger"></i>
                      Eliminar
                    </span>
                    </a>
                  </div>
                </div>
                <i class="fa fa-arrows-rotate text-primary fs--1 pt-1 me-2 ms-auto kanban-collapse-icon"
                   *ngIf="!boardColumn.collapsed"
                   (click)="refreshBoardColumnChats(boardColumn)">
                </i>
                <span class="uil uil-left-arrow-to-left text-warning fs-0 ms-auto kanban-collapse-icon"
                      (click)="toggleBoardColumnCollapse(boardColumn)">
                </span>
                <span class="uil uil-arrow-from-right fs-0 ms-auto kanban-collapse-icon"
                      (click)="toggleBoardColumnCollapse(boardColumn)">
                </span>
              </div>
            </div>
          </div>
          <div class="kanban-items-sortable-container scrollbar">
            <div class="kanban-items-container smart-topic-items" [id]="'kc' + boardColumn.id">
              <ng-container
                infiniteScroll
                [infiniteScrollDistance]="3"
                [infiniteScrollThrottle]="50"
                [infiniteScrollContainer]="'#kc' + boardColumn.id"
                [fromRoot]="true"
                (scrolled)="onScrolled(boardColumn)">
                <ng-container *ngFor="let chat of boardColumn.chats.content">
                  <div class="sortable-item-wrapper smart-topic-item border-bottom px-2 py-2"
                       [attr.kanban-column-chat-id]="chat.id">
                    <div class="card sortable-item hover-actions-trigger">
                      <div class="card-body py-1 px-1"
                           (click)="openBoardColumnChat($event, boardColumn, chat)">
                        <div class="d-flex align-items-center justify-content-center chat-list-item p-2">
                          <div class="
                    avatar avatar-xl
                    status-online
                    position-relative
                    me-2">
                            <div
                              class="avatar-name rounded-circle"
                              [ngStyle]="{'color': chat.color}">
                              <span>{{ chat.chatName[0] }}</span>
                            </div>
                          </div>
                          <div class="flex-1 d-xl-block">
                            <div class="d-flex justify-content-between align-items-center">
                              <h5 class="text-900 fw-normal name text-truncate chat-name">
                                {{ chat.chatName }}
                              </h5>
                              <p class="fs--2 text-800 mb-0">
                                {{ chat.lastMessageSentTime | date: 'dd/MM/yyyy HH:mm:ss' }}
                              </p>
                            </div>
                            <div class="d-flex justify-content-between">
                              <p class="fs--1 mb-0 text-600 message text-truncate last-message-label text-truncate">
                                {{ chat.lastMessageTypeLabel }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
        <!-- Add new topic section -->
        <div class="kanban-column position-relative bg-transparent">
          <div class="d-flex h-100 flex-center fw-bold hover-bg-100">
            <a class="text-decoration-none stretched-link text-800" role="button" (click)="openSaveBoardColumn()">
              <div class="circle-btn bg-200 mx-auto mb-2">
                <span class="fas fa-plus" data-fa-transform="shrink-2"></span>
              </div>
              <span>
                Agregar nueva columna
              </span>
            </a>
          </div>
        </div>

      </div>
    </div>
    <div class="card card-body h-100 d-flex justify-content-center align-items-center"
         *ngIf="selectedBoard === undefined">
      <h2 class="text-muted">
        ¡Hola! Da click en visualizar un tablero para iniciar.
      </h2>
    </div>

  </div>
</div>

<ng-template #saveBoardOffCanvas let-offcanvas>
  <div class="offcanvas-header align-items-start border-bottom flex-column">
    <div class="pt-1 w-100 d-flex justify-content-between align-items-start">
      <div>
        <h4 class="mb-2 me-2 lh-sm">
          {{ selectedBoard ? 'Editar tablero' : 'Personaliza tu tablero' }}
        </h4>
        <p class="mb-0 fs--1 text-info-500">
          Elige el nombre y las instrucciones del tablero.
        </p>
      </div>
      <button
        class="btn p-1 fw-bolder"
        type="button"
        (click)="offcanvas.close()">
        <i class="fas fa-xmark fs-2"></i>
      </button>
    </div>
  </div>
  <div class="offcanvas-body scrollbar">
    <form
      [formGroup]="boardForm"
      (submit)="saveBoard()">
      <div class="row mb-2">
        <h5 class="text-dark">
          Datos del tablero
        </h5>
      </div>
      <!-- Nombre del tablero -->
      <div class="row mb-3">
        <div class="col-12">
          <div class="form-floating">
            <input
              class="form-control"
              id="boardName"
              type="text"
              placeholder="Nombre del tablero"
              formControlName="name"
              [ngClass]="{
                'is-invalid': boardForm.get('name').touched && boardForm.get('name').invalid,
                'is-valid': boardForm.get('name').touched && boardForm.get('name').valid
              }">
            <label for="boardName">
              Nombre del tablero
            </label>
            <div
              *ngIf="boardForm.get('name').touched && boardForm.get('name').invalid"
              class="invalid-feedback text-warning">
              Ingrese un nombre para el board, por favor.
            </div>
          </div>
        </div>
      </div>
      <!-- Botones -->
      <div class="row mb-3 g-2 justify-content-end">
        <div class="col-auto">
          <button
            type="button"
            class="btn btn-sm btn-phoenix-secondary"
            (click)="offcanvas.close()">
            Cancelar
          </button>
        </div>
        <div class="col-auto" *ngIf="!creatingBoard">
          <button
            type="button"
            class="btn btn-sm btn-phoenix-danger"
            (click)="deleteBoard()">
            <i class="fa fa-trash me-1"></i>
            Eliminar
          </button>
        </div>
        <div class="col-auto">
          <button
            class="btn btn-sm btn-phoenix-primary"
            [disabled]="boardForm.invalid">
            <i class="fa fa-save me-1"></i>
            Guardar
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #saveBoardColumnOffCanvas let-offcanvas>
  <div class="offcanvas-header align-items-start border-bottom flex-column">
    <div class="pt-1 w-100 d-flex justify-content-between align-items-start">
      <div>
        <h4 class="mb-2 me-2 lh-sm">
          {{ selectedBoardColumn ? 'Editar columna' : 'Crea una columna' }}
        </h4>
        <p class="mb-0 fs--1 text-info-500">
          Elige el nombre de la columna. Las columnas definen los chats que se mostrarán en el board.
        </p>
      </div>
      <button
        class="btn p-1 fw-bolder"
        type="button"
        (click)="offcanvas.close()">
        <i class="fas fa-xmark fs-2"></i>
      </button>
    </div>
  </div>
  <div class="offcanvas-body scrollbar">
    <form
      [formGroup]="boardColumnForm"
      (submit)="saveBoardColumn()">
      <!-- Nombre de la columna -->
      <div class="row mb-3">
        <div class="col-12">
          <div class="form-floating">
            <input
              class="form-control"
              id="boardColumnName"
              type="text"
              placeholder="Nombre de la columna"
              formControlName="name"
              [ngClass]="{
                'is-invalid': boardColumnForm.get('name').touched && boardColumnForm.get('name').invalid,
                'is-valid': boardColumnForm.get('name').touched && boardColumnForm.get('name').valid
              }">
            <label for="boardColumnName">
              Nombre de la columna
            </label>
            <div
              *ngIf="boardColumnForm.get('name').touched && boardColumnForm.get('name').invalid"
              class="invalid-feedback text-warning"
            >
              Ingrese un nombre para la columna, por favor.
            </div>
          </div>
        </div>
      </div>

      <hr>

      <h4 class="text-dark text-center mb-2">
        Filtros de chats
      </h4>

      <label class="form-label ps-0 text-primary">
        Asesores
        <span [ngbPopover]="advisorsInfoPopoverContent"
              triggers="mouseenter:mouseleave"
              popoverTitle="Información">
          <i class="fa fa-info-circle ms-1"></i>
        </span>
      </label>
      <!-- Selección de asesores -->
      <div class="row"
           [class.mb-3]="!usersChosen?.length"
           [class.mb-2]="usersChosen?.length">
        <div class="col-12">
          <select id="advisorSelect" class="form-select"
                  formControlName="user"
                  (change)="onUserChosen($event)">
            <option value="" selected="">
              {{ usersAvailable.length === 0 ? 'No hay más asesores' : 'Elige uno o más asesores' }}
            </option>
            <option [value]="user.id" *ngFor="let user of usersAvailable">
              {{ user.name }}
            </option>
          </select>
        </div>
      </div>
      <!-- Muestreo de asesores -->
      <div class="row mb-2" *ngIf="usersChosen?.length">
        <div class="col-sm-12">
          <div class="mb-1">
            <span
              *ngFor="let user of usersChosen"
              (click)="removeUserChosen(user)"
              role="button"
              class="badge badge-phoenix-success fs--2 me-1"
              [ngStyle]="{
                'border': '1px solid #1c6c09',
              }">
              {{ user.name }}
              <i class="fas fa-xmark tag-xmark-icon badge-icon"></i>
            </span>
          </div>
        </div>
      </div>

      <!-- Título de selección de etiquetas -->
      <label class="form-label ps-0 text-primary">
        Etiquetas de chats
        <span [ngbPopover]="chatTagsInfoPopoverContent"
              container="body"
              triggers="mouseenter:mouseleave"
              popoverTitle="Información">
          <i class="fa fa-info-circle ms-1"></i>
        </span>
      </label>
      <!-- Selección de etiquetas -->
      <div class="row"
           [class.mb-3]="!chatTagsChosen?.length"
           [class.mb-2]="chatTagsChosen?.length">
        <div class="col-12">
          <div class="form-floating w-100">
            <input
              id="floatingInput"
              type="text"
              class="form-control"
              [formControl]="tagsFormControl"
              placeholder="Buscar etiquetas"
              [editable]="false"
              #instance="ngbTypeahead"
              [ngbTypeahead]="searchChatTags"
              [resultFormatter]="formatResult"
              [inputFormatter]="formatInput"
              (focus)="focus$.next($any($event).target.value)"
              (click)="click$.next($any($event).target.value)"
            >
            <label for="floatingInput">
              {{ chatTagsAvailable.length > 0 ? 'Buscar etiquetas de chats' : 'No hay más etiquetas' }}
            </label>
          </div>
        </div>
      </div>
      <!-- Muestreo de etiquetas -->
      <div class="row mb-1" *ngIf="chatTagsChosen?.length">
        <div class="col-sm-12">
          <div class="mb-1">
            <span
              *ngFor="let chatTag of chatTagsChosen"
              (click)="removeChatTag(chatTag)"
              role="button"
              class="badge badge-phoenix fs--2 me-1"
              [ngStyle]="{
                'color': chatTag.color,
                'border': '1px solid ' + chatTag.color,
              }"
            >
              {{ chatTag.name }}
              <i class="fas fa-xmark tag-xmark-icon badge-icon"></i>
            </span>
          </div>
        </div>
      </div>
      <!-- Tipo de selección de chats por etiquetas -->
      <div class="row mb-3" *ngIf="chatTagsChosen?.length">
        <div class="col-12">
          <label class="form-label ps-0 text-primary">
            Tipo de filtro de chats por etiquetas
            <span [ngbPopover]="chatTagSearchTypeInfoPopoverContent"
                  triggers="mouseenter:mouseleave"
                  popoverTitle="Información">
                <i class="fa fa-info-circle ms-1 text-warning"></i>
              </span>
          </label>
          <div class="btn-group" role="group">
            <button type="button" class="btn btn-secondary"
                    [class.btn-active]="saveBoardColumnRequest.chatTagSearchType === ChatTagSearchType.AtLeastOne"
                    (click)="changeChatTagSearchType(ChatTagSearchType.AtLeastOne)">
              Al menos una
            </button>
            <button type="button" class="btn btn-secondary"
                    [class.btn-active]="saveBoardColumnRequest.chatTagSearchType === ChatTagSearchType.IncludeAll"
                    (click)="changeChatTagSearchType(ChatTagSearchType.IncludeAll)">
              Todas
            </button>
            <button type="button" class="btn btn-secondary"
                    [class.btn-active]="saveBoardColumnRequest.chatTagSearchType === ChatTagSearchType.IncludeAllStrict"
                    (click)="changeChatTagSearchType(ChatTagSearchType.IncludeAllStrict)">
              Sólo seleccionadas
            </button>
          </div>
        </div>
      </div>

      <hr>

      <label class="form-label ps-0 text-primary">
        Selección de fecha o rango de fechas
        <span [ngbPopover]="dateRangePickerInfoPopoverContent"
              triggers="mouseenter:mouseleave"
              popoverTitle="Información">
          <i class="fa fa-info-circle ms-1"></i>
        </span>
      </label>
      <!-- Selección de fechas -->
      <div class="row mb-2">
        <div class="col-12">
          <div class="d-flex justify-content-between">
            <div class="w-100 me-2">
              <input
                placeholder="Filtrar por rango de fechas"
                class="form-control"
                type="text"
                (flatpickrReady)="dateRangeReady($event)"
                (flatpickrClose)="setSelectedRangeDate($event)"
                [dateFormat]="'d-m-Y H:i'"
                [maxDate]="maxDate"
                mwlFlatpickr
                mode="range">
            </div>
            <button
              type="button"
              [disabled]="savingBoardColumn"
              class="btn btn-secondary dropdown-toggle square-btn-md me-2"
              data-bs-toggle="dropdown">
              <i class="fa fa-gear"></i>
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item"
                 (click)="changeDateRangeFieldType(ChatSearchDangeRangeFieldType.CreationDate)"
                 [class.active]="saveBoardColumnRequest.dateRangeFieldType === ChatSearchDangeRangeFieldType.CreationDate"
                 href="javascript:void(0);">
                <i class="fa fa-calendar me-2"></i>
                Usar fecha de inicio de chat
              </a>
              <a class="dropdown-item"
                 (click)="changeDateRangeFieldType(ChatSearchDangeRangeFieldType.LastMessageDate)"
                 [class.active]="saveBoardColumnRequest.dateRangeFieldType === ChatSearchDangeRangeFieldType.LastMessageDate"
                 href="javascript:void(0);">
                <i class="fa fa-clock me-2"></i>
                Usar fecha de último mensaje
              </a>
            </div>
            <button
              [disabled]="savingBoardColumn"
              type="button"
              class="btn btn-secondary square-btn-md"
              (click)="clearDateRange()">
              <i class="fa fa-broom"></i>
            </button>
          </div>
        </div>
      </div>

      <!-- Filtros de chat -->
      <div class="mb-3">
        <div class="list-group">
          <li
            class="list-group-item d-flex justify-content-between align-items-center cursor-pointer"
            (click)="toggleNotSeen()">
            <div class="d-flex align-items-center justify-content-center">
              <i class="fas fa-eye-slash pe-2 search-attribute-icon text-danger"></i>
              No leídos
            </div>
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                [(ngModel)]="saveBoardColumnRequest.notSeen"
                [checked]="saveBoardColumnRequest.notSeen">
            </div>
          </li>
          <li
            class="list-group-item d-flex justify-content-between align-items-center cursor-pointer"
            (click)="toggleUnanswered()">
            <div class="d-flex align-items-center">
              <i class="fas fa-message-slash pe-2 search-attribute-icon text-warning"></i>
              No respondidos
            </div>
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                [(ngModel)]="saveBoardColumnRequest.unanswered"
                [checked]="saveBoardColumnRequest.unanswered">
            </div>
          </li>
          <li
            class="list-group-item d-flex justify-content-between align-items-center cursor-pointer"
            (click)="toggleArchived()">
            <div class="d-flex align-items-center">
              <i class="fas fa-box-archive pe-2 search-attribute-icon text-primary"></i>
              Archivados
            </div>
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                [(ngModel)]="saveBoardColumnRequest.archived"
                [checked]="saveBoardColumnRequest.archived">
            </div>
          </li>
        </div>
      </div>
      <!-- Botones -->
      <div class="row g-3 justify-content-end">
        <div class="col-auto">
          <button
            type="button"
            class="btn btn-phoenix-primary"
            (click)="offcanvas.close()">
            Cancelar
          </button>
        </div>
        <div class="col-auto">
          <button
            class="btn btn-primary"
            [disabled]="boardColumnForm.invalid">
            Guardar
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #boardColumnChatModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">
      Agregar nueva tarjeta
    </h5>
  </div>
  <div class="modal-body">
    <h1>cHAT</h1>
  </div>
</ng-template>

<ng-template #advisorsInfoPopoverContent>
  <p class="mb-1">
    Si un chat está asignado a un <strong class="text-primary">asesor</strong> seleccionado, el chat será filtrado por
    este.
  </p>
</ng-template>

<ng-template #chatTagsInfoPopoverContent>
  <p class="mb-1">
    Elija las etiquetas de los chats que desea filtrar y a continuación, el <b class="text-primary">tipo de filtro</b>
    que desea aplicar.
  </p>
</ng-template>

<ng-template #chatTagSearchTypeInfoPopoverContent>
  <p class="mb-1">
    <b class="text-primary">Al menos una:</b> Selecciona los chats que tengan al menos una de las etiquetas
    seleccionadas. <br><br>
    <b class="text-primary">Todas:</b> Selecciona los chats que tengan todas las etiquetas seleccionadas. <br><br>
    <b class="text-primary">Sólo seleccionadas:</b> Selecciona los chats que tengan exactamente las etiquetas
    seleccionadas.
  </p>
</ng-template>

<ng-template #dateRangePickerInfoPopoverContent>
  <p class="mb-1">
    Se tomarán en cuenta los chats que se encuentren dentro del rango de fechas seleccionado. <br> <br>
    <b class="text-primary">Nota:</b> si desea filtrar por una fecha específica, seleccione el mismo día en ambas
    fechas.
  </p>
</ng-template>

<ng-template #chatModal>
  <div class="modal-body">
    <app-chat
      [chat]="selectedChat"
      [showSideChatList]="false"
      [fromContactsComponent]="true">
    </app-chat>
  </div>
</ng-template>