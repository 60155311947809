
<div *ngIf="message" class="mb-1 p-3 rounded-2" [ngClass]="{
  'received-message-content border': message.customerMessage,
  'sent-message-content bg-primary light text-white': !message.customerMessage,
}">
  <app-chat-context-message
    *ngIf="message.contextMessage"
    [message]="message.contextMessage"
    [chat]="chat">
  </app-chat-context-message>
  <audio controls class="mt-1">
    <source [src]="message.fileUrl" type="{{ message.fileContentType }}">
  </audio>
  <div class="scrollbar audio-message-description mt-3 pe-2" *ngIf="message.fileAudioTranscription">
    <p class="mb-0" >
      {{ message.fileAudioTranscription }}
    </p>
  </div>
  <div class="scrollbar mt-3 pe-2" *ngIf="message.fileMediaCaption">
    <p class="mb-0">
      {{ message.fileMediaCaption }}
    </p>
  </div>
  <span class="reaction-emoji" *ngIf="message.reactionEmoji">
    {{ message.reactionEmoji }}
  </span>
</div>
