
import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageInfo } from '@app/models/chat/message/message-info.dto';
import { EventEmitterService } from '@app/services/data/event-emitter.service';
import { ChatInfo } from "../../../../../models/chat/chat/chat-info.dto";

@Component({
  selector: 'app-chat-contacts-message',
  templateUrl: './contacts-message.component.html',
  styleUrls: ['./contacts-message.component.css']
})
export class ContactsMessageComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() 
  public message: MessageInfo;

  @Input()
  public chat: ChatInfo;

  public contacts: any[] = [];

  private componentSubscriptions: Subscription[] = [];

  constructor(
    private eventEmitterService: EventEmitterService,
  ) {

  }

  ngOnInit() {
    this.contacts = JSON.parse(this.message.content);
  }

  ngAfterViewInit() {
    
  }

  ngOnDestroy() {
    this.componentSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
