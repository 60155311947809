<section>
  <h3 class="mb-3">Reseñas</h3>
  <div class="row">
    <div class="col-5">
      <h5>Calificacion general</h5>
      <!-- Total de reviews -->
      <div class="row mb-4">
        <div class="col-auto">
          <div class="d-flex align-items-center flex-wrap">
            <h2 class="fw-bolder me-3" *ngIf="appPluginInfo">{{ appPluginInfo.generalRating }}<span
              class="fs-0 text-500 fw-bold">/5</span></h2>
            <div class="me-3">
              <span class="fs-2" *ngFor="let star of stars" [class]="star"></span>
            </div>
            <!-- Total de ratings y reseñas -->
            <p class="text-900 mb-0 fw-semi-bold" *ngIf="appReviews">{{ appReviews.length }} calificaciones
              y {{ appReviews.length }} reseñas</p>
          </div>
        </div>
      </div>
      <div class="col-auto">
        <button class="btn btn-primary rounded-pill" data-bs-toggle="modal" data-bs-target="#reviewModal">
          Escribir una reseña
        </button>
      </div>
    </div>

    <div class="col">
      <div class="mb-4 hover-actions-trigger btn-reveal-trigger" *ngFor="let review of appReviews">
        <div class="d-flex justify-content-between">
          <h5 class="mb-2">
            <span *ngFor="let star of calcStars(review.rating)" class="fa"
                  [ngClass]="star === 1 ? 'fa-star text-warning' : 'far fa-star text-warning'"></span>
            <span class="text-800 ms-1 fs--1"> por {{ review.authorName }}</span>
          </h5>
          <div class="font-sans-serif btn-reveal-trigger position-static">
            <button class="btn btn-sm dropdown-toggle dropdown-caret-none transition-none btn-reveal"
                    type="button" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true"
                    aria-expanded="false" data-bs-reference="parent"><span
              class="fas fa-ellipsis-h fs--2"></span></button>
            <div class="dropdown-menu dropdown-menu-end py-2"><a class="dropdown-item" href="#!">View</a><a
              class="dropdown-item" href="#!">Export</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item text-danger" href="#!">Remove</a>
            </div>
          </div>
        </div>
        <!--        <p class="text-700 fs&#45;&#45;1 mb-1">35 mins ago</p>-->
        <p class="text-1000 mb-3">{{ review.description }}</p>
        <div class="hover-actions top-0">
          <button class="btn btn-sm btn-phoenix-secondary me-2"><span class="fas fa-thumbs-up"></span>
          </button>
          <button class="btn btn-sm btn-phoenix-secondary me-1"><span class="fas fa-thumbs-down"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center">
    <nav>
      <ul class="pagination mb-0">
        <li class="page-item"><a class="page-link"><span class="fas fa-chevron-left"> </span></a>
        </li>
        <li class="page-item"><a class="page-link">1</a></li>
        <li class="page-item"><a class="page-link">2</a></li>
        <li class="page-item"><a class="page-link">3</a></li>
        <li class="page-item active"><a class="page-link">4</a></li>
        <li class="page-item"><a class="page-link">5</a></li>
        <li class="page-item"><a class="page-link"><span class="fas fa-chevron-right"></span></a>
        </li>
      </ul>
    </nav>
  </div>
</section>


<div class="modal fade" id="reviewModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content p-4">
      <div class="mb-3">
        <h5 class="text-1000 mb-3">Tu reseña</h5>
        <textarea class="form-control" id="reviewTextarea" rows="5"
                  placeholder="Write your review"></textarea>
      </div>
      <div class="d-sm-flex flex-between-center">
        <div class="form-check flex-1">
          <input class="form-check-input" id="reviewAnonymously" type="checkbox" value="" checked="">
          <label class="form-check-label mb-0 text-1100 fw-semi-bold" for="reviewAnonymously">Review
            anonymously</label>
        </div>
        <button class="btn ps-0" data-bs-dismiss="modal">Close</button>
        <button class="btn btn-primary rounded-pill">Submit</button>
      </div>
    </div>
  </div>
</div>