<div class="mb-5">
  <div class="d-flex align-items-center justify-content-between mb-2">
    <h4 class="mb-0">
      <i class="fa fa-file text-warning"></i>
      Archivo de importación
    </h4>
  </div>
  <div class="row g-2 mb-2">
    <div class="col-12">
      <input
        class="form-control"
        id="importContactsFile"
        type="file"
        (change)="onImportFileChosen($event)"
      />
    </div>
  </div>
  <div class="row">
    <a (click)="showImportTemplate()" href="javascript:void(0);">
      <i class="fas fa-download me-1"></i>
      Ver plantilla de importación
    </a>
  </div>
</div>

<div class="mb-3" *ngIf="!creatingChatTag">
  <div class="d-flex align-items-center justify-content-between mb-2">
    <h4 class="mb-0">
      <i class="fas fa-tags"></i>
      Etiquetas
    </h4>
    <button
      class="btn btn-link pe-0"
      type="button"
      (click)="toggleShowCreateChatTag()">
      Crear etiqueta
    </button>
  </div>
  <div class="row g-2" *ngIf="!loadingChatTags">
    <div class="col-sm-12">
      <app-typeahead
        [id]="'chatTags'"
        [placeHolder]="'Buscar etiquetas...'"
        [elementsAll]="chatTags"
        [initialElementsChosen]="selectedChatTags"
        (elementsChosenEvent)="selectChatTags($event)">
      </app-typeahead>
    </div>
  </div>
</div>
<div class="mb-3" *ngIf="creatingChatTag">
  <div class="d-flex align-items-center justify-content-between mb-2">
    <h4 class="mb-0">
      Etiquetas
    </h4>
    <button
      class="btn btn-link pe-0"
      type="button"
      (click)="toggleShowCreateChatTag()">
      Cancelar
    </button>
  </div>
  <div class="row g-2">
    <div class="col-sm-12">
      <form
        [formGroup]="chatTagsForm"
        (submit)="saveChatTag()">
        <div class="row g-3">
          <div class="col-sm-6 col-md-8">
            <div class="form-floating">
              <input
                class="form-control"
                id="name"
                type="text"
                placeholder="Nombre de la etiqueta"
                formControlName="name"
                [ngClass]="{
                  'is-invalid': (chatTagsForm.get('name').touched && chatTagsForm.get('name').invalid) || duplicatedName,
                  'is-valid': checkingDuplicatedName === 'after-checking' && !duplicatedName && chatTagsForm.get('name').valid
                }">
              <label for="name">
                Nombre de la etiqueta
              </label>
              <div class="valid-feedback" *ngIf="!sameName">
                Nombre disponible!
              </div>
              <div
                *ngIf="chatTagsForm.get('name').touched && chatTagsForm.get('name').invalid"
                class="invalid-feedback">
                Ingrese un nombre para la etiqueta, por favor.
              </div>
              <div
                *ngIf="duplicatedName"
                class="invalid-feedback">
                La etiqueta ya existe, ingrese otro nombre.
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-4">
            <input
              class="chat-tag-color-picker"
              type="color"
              name="color"
              formControlName="color">
          </div>
          <div
            class="col-sm-12"
            *ngIf="chatTagsForm.valid">
                <span
                  role="button"
                  class="badge badge-phoenix fs--2 me-1"
                  [ngStyle]="{
                    'color': chatTagsForm.get('color').value,
                    'border': '1px solid ' + chatTagsForm.get('color').value,
                  }">
                  {{ chatTagsForm.get('name').value }}
                  <i class="fas fa-xmark tag-xmark-icon badge-icon"></i>
                </span>
          </div>
          <div class="col-12">
            <div class="row g-3 justify-content-end">
              <div class="col-auto">
                <button
                  class="btn btn-primary"
                  [disabled]="chatTagsForm.invalid">
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="row g-3 justify-content-end">
  <div class="col-auto">
    <button
      type="button"
      class="btn btn-phoenix-primary"
      (click)="close()">
      Cancelar
    </button>
  </div>
  <div class="col-auto">
    <button
      *ngIf="fileImport !== undefined"
      (click)="importContacts()"
      class="btn btn-primary">
      <i class="fa fa-file-import"></i>
      Importar
    </button>
  </div>
</div>


<ng-template #importContactsResultModal let-modal>
  <div class="modal-body scrollbar p-3">
    <h3 class="text-dark">
      Resultado de la importación:
    </h3>
    <div class="row mt-3">
      <div class="col-4">
        <div class="card">
          <h4 class="text-success text-center my-3">
            Procesados
          </h4>
          <ul class="list-group list-group-flush">
            <li class="list-group-item" *ngFor="let contact of importContactsResult.processedContacts">
              {{ contact.name }} - {{ contact.phoneNumber }}
            </li>
            <li class="list-group-item" *ngIf="importContactsResult.processedContacts?.length === 0">
              No se procesaron contactos
            </li>
          </ul>
        </div>
      </div>
      <div class="col-4">
        <div class="card">
          <h4 class="text-warning text-center my-3">
            Existentes
          </h4>
          <ul class="list-group list-group-flush">
            <li class="list-group-item" *ngFor="let contact of importContactsResult.existingContacts">
              {{ contact.name }} - {{ contact.phoneNumber }}
            </li>
            <li class="list-group-item" *ngIf="importContactsResult.existingContacts?.length === 0">
              Sin contactos existentes
            </li>
          </ul>
        </div>
      </div>
      <div class="col-4">
        <div class="card">
          <h4 class="text-danger text-center my-3">
            Con errores
          </h4>
          <ul class="list-group list-group-flush">
            <li class="list-group-item" *ngFor="let contact of importContactsResult.contactsWithErrors">
              {{ contact.name }} - {{ contact.phoneNumber }}
            </li>
            <li class="list-group-item" *ngIf="importContactsResult.contactsWithErrors?.length === 0">
              Sin errores
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-template>