
export class SearchWhatsAppMessageTemplateRequest {

  shopId: number;
  searchTerm: string;

  constructor(shopId: number) {
    this.shopId = shopId;
  }

}