
<div *ngIf="message" class="mb-1 p-3 rounded-2" [ngClass]="{
  'received-message-content border': message.customerMessage, 
  'sent-message-content bg-primary light text-white': !message.customerMessage, 
}">
  <app-chat-context-message
    *ngIf="message.contextMessage"
    [message]="message.contextMessage"
    [chat]="chat">
  </app-chat-context-message>
  <a
    [href]="message.fileUrl"
    data-fancybox="gallery"
  >
    <img
      class="rounded-2 fit-cover mt-1 img-message-content"
      [src]="message.fileUrl"
    >
  </a>
  <span class="reaction-emoji" *ngIf="message.reactionEmoji">
    {{ message.reactionEmoji }}
  </span>
</div>
