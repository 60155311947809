import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TokenRequest } from '@app/models/auth/token-request.dto';
import { TokenResponse } from '@app/models/auth/token-response.dto';
import { UserService } from '@app/services/account/user.service';
import { AuthService } from '@app/services/auth/auth.service';
import { ResetPasswordRequest } from '@models/account/user/reset-password-request.dto';
import { UserRoleType } from '@type/account/user-role.type';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css'],
})
export class SignInComponent implements OnInit {

  public resetPasswordForm: FormGroup;
  public signInForm: FormGroup;
  public loading: boolean = false;
  public resettingPassword: boolean = false;
  public showConfirmationMessage: boolean = false;

  public isMobile: boolean = false;
  public currentTheme: string = 'light';
  public error: string = '';

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
  ) {

  }

  ngOnInit() {

    this.initializeForm();
    this.clearPreviousAuthentication();
    this.checkTheme();

    this.isMobile = window.innerWidth <= 992;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    const isMobile = window.innerWidth <= 992;
    if (isMobile !== this.isMobile) {
      this.isMobile = isMobile;
    }
  }

  private checkTheme() {
    const theme = window.localStorage.getItem('phoenixTheme');
    this.currentTheme = theme ? theme : 'light';
  }

  private clearPreviousAuthentication() {
    this.authService.signOut(false);
  }

  private initializeForm() {

    this.signInForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    });

    this.resetPasswordForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  public signIn() {

    const { username, password } = this.signInForm.controls;

    if (this.signInForm.valid) {

      this.loading = true;

      const tokenRequest = new TokenRequest();
      tokenRequest.username = username.value;
      tokenRequest.password = password.value;

      this.authService.getToken(tokenRequest)
        .subscribe({
          next: (tokenResponse: TokenResponse) => {
            this.processSignIn(tokenResponse);
          },
          error: (error: any) => {
            this.loading = false;
            this.error = 'Credenciales incorrectas. Por favor, verifica tu correo y contraseña.';
            console.error('Error en el inicio de sesión:', error);
          },
          complete: () => {
            console.log('Inicio de sesión completado');
          },
        });
    }

  }

  public processSignIn(tokenResponse: TokenResponse) {

    this.authService.saveAuthentication(tokenResponse);

    this.userService.getUserInfo().subscribe({
      next: (user: any) => {

        this.loading = false;
        this.authService.saveUser(user);

        this.router.navigate(['/app/chat']).then(() => {});

        if (user.role === UserRoleType.SystemAdmin)
          this.router.navigate(['/admin/marketplace']).then(() => {  });
        else
          this.router.navigate(['/app/chat']).then(() => {  });
      },
      error: (error: any) => {
        console.error('Error al obtener la información del usuario:', error);
      },
      complete: () => {

      },
    });
  }

  resetPassword() {
    if (this.resetPasswordForm.invalid) return;
    const { email } = this.resetPasswordForm.controls;

    const resetPasswordRequest: ResetPasswordRequest = {
      email: email.value,
    };

    this.userService.resetPassword(resetPasswordRequest).subscribe({
      next: () => {
        this.showConfirmationMessage = true;
      },
      error: (error) => console.log(error),
    });
  }

  changeView() {
    this.resettingPassword = !this.resettingPassword;
  }
}
