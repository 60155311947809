<div class="mb-9">
  <div class="row g-3 mb-4">
    <div class="col-auto">
      <h2 class="mb-2">
        Teams
      </h2>
      <span class="text-700 lh-sm mb-0">
        Administra aquí tus teams.
      </span>
    </div>
  </div>
  <div class="mb-4">
    <div class="row g-3">
      <div class="col-md-auto col-sm-12">
        <div class="search-box">
          <form
            class="position-relative"
            data-bs-toggle="search"
            data-bs-display="static">
            <input
              class="form-control search-input search"
              type="search"
              placeholder="Buscar teams"
              [formControl]="searchTeamFormControl" />
            <span class="fas fa-search search-box-icon"></span>
          </form>
        </div>
      </div>
      <div class="col-md-auto col-sm-12">
        <button
          class="btn btn-primary"
          (click)="openSaveTeam()">
        <span class="fas fa-plus">
        </span>
          Crear team
        </button>
      </div>
    </div>
  </div>
  <div class="py-5 border-top" [class.transparent]="firstLoad" [class.not-transparent]="!firstLoad">
    <div class="row g-4">
      <div class="col-xl-4 col-lg-4 col-md-3 col-sm-12" *ngFor="let team of teams">
        <div class="swiper-slide">
          <a class="text-decoration-none">
            <div class="card overflow-hidden h-100">
              <div class="position-relative">
              </div>
              <div class="card-body d-flex flex-column justify-content-between">
                <div class="mb-3 d-flex justify-content-between">
                  <h3 class="text-900">
                    {{ team.name }}
                  </h3>
                  <span class="text-600" [class.cursor-pointer]="!team.defaultTeam" (click)="changeDefaultTeam(team)">
                    <i class="text-600" [ngClass]="team.defaultTeam ? 'fa-solid fa-star text-warning' : 'fa-regular fa-star'"></i>
                    {{ team.defaultTeam ? 'Team por defecto' : '' }}
                  </span>
                </div>
                <div class="avatar-group mb-3">
                  <div class="avatar avatar-l  border border-white rounded-circle" *ngFor="let user of team.users">
                    <img class="rounded-circle"
                         [src]="user.profilePictureUrl ? user.profilePictureUrl : './assets/img/placeholder/no_image_found-dark.webp'"
                         alt="" />
                  </div>
                </div>
                <div class="d-flex flex-column gap-0">
                  <span class="text-700">Tamaño:</span>
                  <h5 class="text-900">
                    <i class="fa-solid fa-user text-700 me-1"></i>
                    {{ team.users.length }}
                  </h5>
                </div>
                <div class="d-flex justify-content-end">
                <span class="h5" (click)="deleteTeam(team)" *ngIf="!team.defaultTeam">
                  <i class="fa fa-trash text-danger cursor-pointer me-2 "></i>
                  </span>
                  <span class="h5" (click)="openSaveTeam(team)">
                  <i class="fa fa-pen-to-square text-primary cursor-pointer"></i>
                </span>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #saveTeamOffCanvas let-offcanvas>
  <div class="offcanvas-header align-items-start border-bottom flex-column">
    <div class="pt-1 w-100 d-flex justify-content-between align-items-start">
      <div>
        <h5 class="mb-2 me-2 lh-sm" *ngIf="!selectedTeam">
          Crea un nuevo Team
        </h5>
        <h5 class="mb-2 me-2 lh-sm" *ngIf="selectedTeam">
          Editar Team
        </h5>
        <p class="mb-0 fs--1 text-info-500" *ngIf="!selectedTeam">
          Agrega los datos del team para que empiece a operar en el sistema.
        </p>
        <p class="mb-0 fs--1 text-info-500" *ngIf="selectedTeam">
          Actualiza los datos del team.
        </p>
      </div>
      <button
        class="btn p-1 fw-bolder"
        type="button"
        (click)="offcanvas.close()">
        <i class="fas fa-xmark fs-2"></i>
      </button>
    </div>
  </div>
  <div class="offcanvas-body scrollbar">
    <form
      [formGroup]="teamForm"
      (submit)="saveTeam()">
      <div class="row mb-2">
        <div class="col-12">
          <div class="form-floating">
            <input
              class="form-control"
              id="name"
              type="text"
              placeholder="Nombre"
              formControlName="name"
              [ngClass]="{
              'is-invalid': teamForm.get('name').touched && teamForm.get('name').invalid || duplicatedName,
              'is-valid': checkingDuplicatedName === 'after-checking' && !duplicatedName && teamForm.get('name').valid
            }">
            <label for="name">
              Nombre
            </label>
            <div
              *ngIf="teamForm.get('name').touched && teamForm.get('name').invalid"
              class="invalid-feedback">
              Ingrese el nombre, por favor.
            </div>
            <div
              *ngIf="duplicatedName"
              class="invalid-feedback">
              El equipo ya existe, ingrese otro nombre.
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-12">
          <div class="form-check form-switch">
            <input class="form-check-input"
                   id="flexSwitchCheckDefault"
                   type="checkbox"
                   [disabled]="selectedTeam?.defaultTeam"
                   [checked]="selectedTeam?.defaultTeam"
                   (change)="setDefaultTeam($event)" />
            <label class="form-check-label" for="flexSwitchCheckDefault">
              Team por defecto
            </label>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12">
          <div class="form-floating">
            <input
              id="floatingInputAdvisors"
              type="text"
              class="form-control"
              formControlName="advisors"
              placeholder="Buscar asesores"
              [editable]="false"
              #advisors="ngbTypeahead"
              [ngbTypeahead]="searchChatAdvisors"
              [resultFormatter]="formatResultAdvisor"
              [inputFormatter]="formatInputAdvisor"
              (focus)="focusAdvisor$.next($any($event).target.value)"
              (click)="clickAdvisor$.next($any($event).target.value)">
            <label for="floatingInputAdvisors">
              {{ chatAdvisorsAvailable.length > 0 ? 'Buscar asesores' : 'No hay más asesores' }}
            </label>
          </div>
        </div>
      </div>
      <div class="mb-3 todo-list">
        <label class="form-label ps-0 text-primary" *ngIf="chatAdvisorsChosen?.length">
          Asesores seleccionados:
        </label>
        <div class="d-flex justify-content-between align-items-center py-1 gx-0"
             *ngFor="let chatAdvisor of chatAdvisorsChosen">
          <div class="d-flex align-items-center mb-1 mb-md-0 lh-1 " >
            <div class="avatar avatar-m me-2 text-900"
                 [ngClass]="{ 'opacity-50': chatAdvisor.status === UserStatusType.Disabled }">
              <img class="rounded-circle "
                   [src]="chatAdvisor.profilePictureUrl ? chatAdvisor.profilePictureUrl : './assets/img/placeholder/no_image_found-dark.webp'"
                   alt="" />
            </div>
            <label class="form-check-label mb-0 fs-0 me-2 line-clamp-1 flex-grow-1 flex-md-grow-0"
                   [ngClass]="{ 'text-muted': chatAdvisor.status === UserStatusType.Disabled }">
              {{ chatAdvisor.name }}
              <span *ngIf="chatAdvisor.status === UserStatusType.Disabled" class="ms-1">(Inactivo)</span>
            </label>
          </div>
          <div class="d-flex ms-4 lh-1 align-items-center">
            <i *ngIf="chatAdvisor.status !== UserStatusType.Disabled"
               class="fa-duotone fa-trash cursor-pointer text-danger"
               (click)="removeChatAdvisor(chatAdvisor)"></i>
          </div>
        </div>

      </div>
      <div class="row g-3 justify-content-end">
          <div class="col-auto">
            <button
              type="button"
              class="btn btn-phoenix-primary"
              [disabled]="savingTeam"
              (click)="offcanvas.close()">
              Cancelar
            </button>
          </div>
          <div class="col-auto">
            <button
              class="btn btn-primary"
              *ngIf="!savingTeam"
              [disabled]="teamForm.invalid || chatAdvisorsChosen.length === 0 || duplicatedName">
              Guardar
            </button>
            <button
              class="btn btn-primary"
              *ngIf="savingTeam"
              disabled>
              <span class="spinner-border spinner-border-sm"></span>
              Guardando...
            </button>
          </div>
        </div>
    </form>
  </div>
</ng-template>
