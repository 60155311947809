import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { SaveWhatsAppConfigRequest } from '@models/config/whatsapp/save-whatsapp-config-request.dto';
import { WhatsAppConfigInfo } from '@models/config/whatsapp/whatsapp-config-info.dto';
import { SaveAutomaticWhatsAppConfigRequest } from '@models/config/whatsapp/save-automatic-whatsapp-config-request.dto';
import { ChannelType } from '@type/communication/channel.type';

@Injectable({
  providedIn: 'root'
})
export class WhatsAppConfigService {

  private apiUrl: string = `${environment.apiUrl}/config/whatsapp`;

  constructor(
    private http: HttpClient,
  ) {

  }

  getConfig(): Observable<WhatsAppConfigInfo> {
    const endpoint = `${this.apiUrl}/get`;
    return this.http.get<WhatsAppConfigInfo>(endpoint);
  }

  saveConfig(saveWhatsAppConfigRequest: SaveWhatsAppConfigRequest): Observable<any> {
    const endpoint = `${this.apiUrl}/save`;
    return this.http.post<any>(endpoint, saveWhatsAppConfigRequest);
  }

  saveAutomaticConfig(saveAutomaticWhatsAppConfigRequest: SaveAutomaticWhatsAppConfigRequest): Observable<any> {
    const endpoint = `${this.apiUrl}/automatic-save`;
    return this.http.post<any>(endpoint, saveAutomaticWhatsAppConfigRequest);
  }

  disconnect(channel: ChannelType): Observable<any> {
    const endpoint = `${this.apiUrl}/disconnect/${channel}`;
    return this.http.post<any>(endpoint, {});
  }

  disconnectManual(): Observable<any> {
    const endpoint = `${this.apiUrl}/disconnect-manual`;
    return this.http.post<any>(endpoint, {});
  }

}