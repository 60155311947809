
export class SmartAssistantInfo {

  id: number;
  createdAt: any;
  name: string;
  description: string;
  instructions: string;
  waitTimeInterval: number;
  enabled: boolean;

}