import { Component, Input, OnInit } from '@angular/core';
import { AppReviewService } from '../../../../../services/marketplace/app-review.service';
import { AppPluginInfo } from '../../../../../models/marketplace/app-plugin-info.dto';
import { AppReviewInfo } from '../../../../../models/marketplace/app-review-info.dto';

@Component({
  selector: 'app-app-reviews',
  templateUrl: './app-reviews.component.html',
  styleUrls: ['./app-reviews.component.css'],
})
export class AppReviewsComponent implements OnInit {

  @Input()
  public stars = null;
  @Input()
  public appPluginInfo: AppPluginInfo;
  @Input()
  public appId: number;
  public appReviews: AppReviewInfo[] = [];


  constructor(
    private appReviewService: AppReviewService,
  ) {

  }

  ngOnInit() {
    this.getAppReviews();
  }

  getAppReviews() {
    this.appReviewService.getAppReviewsByAppId(this.appId).subscribe({
      next: (reviews) => {
        this.appReviews = reviews;
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  calcStars(rating: number): number[] {
    const stars: number[] = [];
    for (let i = 0; i < 5; i++) {
      if (i < rating) {
        stars.push(1); // 1 para estrella llena
      } else {
        stars.push(0); // 0 para estrella vacía
      }
    }
    return stars;
  }

}
