
import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageInfo } from '@app/models/chat/message/message-info.dto';
import { WhatsAppMessageTemplateComponentHeaderType } from '@app/models/chat/whatsapp-message-template/type/whatsapp-message-template-component-header.type';
import { WhatsAppMessageTemplateComponentType } from '@app/models/chat/whatsapp-message-template/type/whatsapp-message-template-component.type';
import { WhatsAppMessageTemplateInfo } from '@app/models/chat/whatsapp-message-template/whatsapp-message-template-info.dto';
import { EventEmitterService } from '@app/services/data/event-emitter.service';
import { DataKey, LocalDataService } from '@app/services/data/local-data.service';
import { ChatInfo } from "../../../../../../models/chat/chat/chat-info.dto";

@Component({
  selector: 'app-chat-context-template-message',
  templateUrl: './context-template-message.component.html',
  styleUrls: ['./context-template-message.component.css']
})
export class ContextTemplateMessageComponent implements OnInit, AfterViewInit, OnDestroy {

  // enums
  WhatsAppMessageTemplateComponentHeaderType = WhatsAppMessageTemplateComponentHeaderType;

  @Input()
  public message: MessageInfo;

  @Input()
  public chat: ChatInfo;

  public whatsAppMessageTemplate: WhatsAppMessageTemplateInfo;

  private componentSubscriptions: Subscription[] = [];

  constructor(
    private eventEmitterService: EventEmitterService,
    private localDataService: LocalDataService,
  ) {

  }

  ngOnInit() {

    const whatsAppMessageTemplates = JSON.parse(this.localDataService.get(DataKey.MessageTemplates)) as WhatsAppMessageTemplateInfo[];
    const whatsAppMessageTemplate = whatsAppMessageTemplates.find((t) => t.id === this.message.messageTemplateId);

    if (whatsAppMessageTemplate) {

      const { components } = whatsAppMessageTemplate;

      whatsAppMessageTemplate.headerComponent = components.find(c => c.type === WhatsAppMessageTemplateComponentType.Header);
      whatsAppMessageTemplate.bodyComponent = components.find(c => c.type === WhatsAppMessageTemplateComponentType.Body);
      whatsAppMessageTemplate.footerComponent = components.find(c => c.type === WhatsAppMessageTemplateComponentType.Footer);
      whatsAppMessageTemplate.buttonsComponent = components.find(c => c.type === WhatsAppMessageTemplateComponentType.Buttons);
    }

    this.whatsAppMessageTemplate = whatsAppMessageTemplate;
  }

  ngAfterViewInit() {
    
  }

  ngOnDestroy() {
    this.componentSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
  
}
