import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AuthService } from '@app/services/auth/auth.service';
import { SubscriptionTypeInfo } from '@app/models/subscription/subscription-type-info.dto';
import { trialPeriodDays } from '@app/constants/subscription-constants';
import { UserInfo } from '@app/models/account/user/user.info.dto';
import { SubscriptionType } from '@type/subscription/subscription.type';
import { SubscriptionService } from '@services/subscription/subscription.service';
import { ShopInfo } from '@models/account/shop/shop-info.dto';
import { GetStripeCheckoutSessionRequest } from '@models/subscription/get-stripe-checkout-session-request.dto';
import { SpinnerService } from '@services/data/spinner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '@services/account/user.service';
import { DataKey, LocalDataService } from '@services/data/local-data.service';
import { getTypeByValue } from '@app/utils/enum-utils';
import { UpdateSubscriptionTypeRequest } from '@models/subscription/update-subscription-type-request.dto';
import { ChangeSubscriptionRequest } from '@models/subscription/change-subscription-request.dto';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitterService, NotificationTopic } from '@services/data/event-emitter.service';

@Component({
  selector: 'app-subscription-config',
  templateUrl: './subscription-config.component.html',
  styleUrls: ['./subscription-config.component.css']
})
export class SubscriptionConfigComponent implements OnInit, OnDestroy {

  public user: UserInfo;
  public shop: ShopInfo;
  public trialPeriodDays: number = trialPeriodDays;
  public subscriptionTypes: SubscriptionTypeInfo[] = [];
  public selectedSubscriptionType: SubscriptionTypeInfo;
  public preSelectedSubscriptionType: SubscriptionTypeInfo;

  public loadingSubscription: boolean = false;
  public showChangeSubscriptionType: boolean = false;
  private lastSubscriptionTypeSelected: SubscriptionType;

  // Modals
  @ViewChild("changeSubscriptionConfirmationModal")
  private changeSubscriptionConfirmationModal: TemplateRef<any>;

  constructor(
    private userService: UserService,
    private subscriptionService: SubscriptionService,
    private authService: AuthService,
    private eventEmitterService: EventEmitterService,
    private modalService: NgbModal,
    private spinnerService: SpinnerService,
    private toastService: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private localDataService: LocalDataService,
  ) { 
    this.user = this.authService.getUser();
    this.shop = this.user.shop;
  }

  ngOnInit() {

    this.fillSubscriptionTypes();
    this.checkSubscriptionSuccess();

    if (this.localDataService.get(DataKey.LastSubscriptionTypeSelected)) {
      this.checkLastSubscriptionTypeSelected();
    } else {
      this.checkSubscription();
    }

    this.eventEmitterService.emit(NotificationTopic.BlockUI, false);
  }

  ngOnDestroy() {
    this.eventEmitterService.emit(NotificationTopic.BlockUI, undefined);
  }

  private checkLastSubscriptionTypeSelected(callback?: any) {

    const lastSubscriptionTypeSelectedValue = this.localDataService.get(DataKey.LastSubscriptionTypeSelected);

    if (lastSubscriptionTypeSelectedValue) {

      this.lastSubscriptionTypeSelected = getTypeByValue(SubscriptionType, lastSubscriptionTypeSelectedValue);
      this.shop.subscriptionType = this.lastSubscriptionTypeSelected;

      this.localDataService.remove(DataKey.LastSubscriptionTypeSelected);

      this.updateSubscriptionType(this.lastSubscriptionTypeSelected);
    }
  }

  private updateSubscriptionType(subscriptionType: SubscriptionType) {

    const updateSubscriptionTypeRequest = new UpdateSubscriptionTypeRequest(
      this.shop.id, subscriptionType
    );

    this.subscriptionService.updateSubscriptionType(updateSubscriptionTypeRequest).subscribe({
      next: (response) => {
        this.checkSubscription();
      },
      error: (error) => {
        console.error(error);
      }
    });

  }

  private checkSubscription() {

    this.spinnerService.show('Cargando...');
    this.loadingSubscription = true;

    this.userService.getUserInfo().subscribe({
      next: (user: UserInfo) => {
        this.spinnerService.hide();
        this.loadingSubscription = false;
        this.authService.saveUser(user);
        this.user = user;
        this.shop = user.shop;
      },
      error: (error) => {
        console.error(error);
      }
    });

  }

  private checkSubscriptionSuccess() {

    const queryParam: string = 'subscriptionSuccessful';
    const queryParams = this.route.snapshot.queryParams;

    if (queryParams[queryParam]) {

      this.toastService.success('¡Suscripción exitosa!');

      const originalQueryParams = { ...queryParams };
      delete originalQueryParams[queryParam];

      this.router.navigate([], {
        queryParams: {
          'subscriptionSuccessful': null
        }, queryParamsHandling: 'merge'
      }).then(() => {});
    }
  }

  public selectSubscriptionType(selectedSubscriptionType: SubscriptionTypeInfo) {

    const { alreadySubscribed, subscriptionType } = this.shop;

    // If the user is not subscribed, we go to the checkout session
    if (!alreadySubscribed) {
      const subscriptionType = selectedSubscriptionType.type;
      this.localDataService.set(DataKey.LastSubscriptionTypeSelected, subscriptionType);
      this.goToStripeCheckoutSession(subscriptionType);
      return;
    }

    // If the user is already subscribed, we check if the selected plan is the same as the current one
    if (alreadySubscribed) {

      // If the selected plan is the same as the current one, we go to the checkout session
      if (subscriptionType === selectedSubscriptionType.type) {

        this.goToStripeCheckoutSession(selectedSubscriptionType.type);

      } else { // If the selected plan is different from the current one, we ask for confirmation

        // Only if the user is changing the subscription type, we save the selected plan
        this.preSelectedSubscriptionType = selectedSubscriptionType;

        this.modalService.open(this.changeSubscriptionConfirmationModal, {
          centered: true,
        }).dismissed.subscribe(() => {
          this.preSelectedSubscriptionType = undefined;
        });

      }

    }

  }

  public confirmChangeSubscription() {

    const subscriptionType = this.preSelectedSubscriptionType.type;

    this.spinnerService.show('Cambiando plan...');

    const changeSubscriptionRequest = new ChangeSubscriptionRequest(
      this.shop.id, subscriptionType,
    );

    this.subscriptionService.changeSubscription(changeSubscriptionRequest).subscribe({
      next: (response) => {
        this.checkSubscription();
        this.selectedSubscriptionType = this.preSelectedSubscriptionType;
        this.spinnerService.hide();
        this.toastService.success('¡Plan cambiado exitosamente!');
        this.modalService.dismissAll();
      },
      error: (error) => {
        console.error(error);
      }
    });

  }

  public openChangeSubscriptionType() {
    this.showChangeSubscriptionType = true;
  }

  public goToStripeBillingPortalSession() {

    this.spinnerService.show('Cargando...');

    this.subscriptionService.getStripeBillingPortalSession(this.shop.id).subscribe({
      next: (billingPortalSession: any) => {
        window.location = billingPortalSession.url;
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  public goToSubscriptionResume() {
    this.showChangeSubscriptionType = false;
  }

  private goToStripeCheckoutSession(subscriptionType: SubscriptionType) {

    this.spinnerService.show('Cargando...');

    const getStripeCheckoutSessionRequest = new GetStripeCheckoutSessionRequest(this.shop.id, subscriptionType);

    this.subscriptionService.getStripeCheckoutSession(getStripeCheckoutSessionRequest).subscribe({
      next: (checkoutSession: any) => {
        window.location = checkoutSession.url;
      },
      error: (error) => {
        console.error(error);
      }
    });

  }

  private fillSubscriptionTypes() {
    this.subscriptionService.getSubscriptionTypes().subscribe({
      next: (subscriptionTypes: SubscriptionTypeInfo[]) => {
        this.subscriptionTypes = subscriptionTypes;
        this.updateSubscriptionTypes(subscriptionTypes);
        this.selectedSubscriptionType = subscriptionTypes.find(st => st.type === this.shop.subscriptionType);
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  private updateSubscriptionTypes(subscriptionTypes: SubscriptionTypeInfo[]) {
    for (let i = 0; i < subscriptionTypes.length; i++) {
      const subscriptionType = subscriptionTypes[i];
      if (subscriptionType.type === SubscriptionType.Advanced)
        subscriptionType.mostPopular = true;
    }
  }

}
