<div class="row"
     [class.transparent]="firstLoad"
     [class.not-transparent]="!firstLoad">
  <div class="col-xl-3 col-sm-12">
    <div class="card shadow-none border border-300 quick-replies-list">
      <div class="card-header p-4 border-bottom border-300 bg-soft px-3">
        <div class="row g-3 justify-content-between align-items-center">
          <div class="col-12 col-md-auto">
            <h4 class="text-900 mb-0">
              <i class="fa fa-reply-all text-primary"></i>
              <span class="text-dark">
                Respuestas rápidas
              </span>
            </h4>
          </div>
          <div class="col col-md-auto">
            <nav class="nav nav-underline justify-content-end doc-tab-nav align-items-center" role="tablist">
              <button
                type="button"
                class="btn btn-sm btn-phoenix-primary"
                (click)="openSaveQuickReply()">
                <i class="fa fa-plus"></i>
              </button>
            </nav>
          </div>
        </div>
        <div class="row">
          <p class="text-700 lh-sm mt-1 my-0">
            Ahorra tiempo con las respuestas rápidas
          </p>
        </div>
        <div class="form-floating mt-2">
          <select class="form-select" id="groupSelect" (change)="filterByGroups($event)" [(ngModel)]="selectedGroups">
            <option value="all">Todos los grupos</option>
            <option *ngFor="let category of groups" [value]="category.name">{{ category.name }}</option>
          </select>          
          <label for="groupSelect">Filtrar por grupos</label>
        </div>

      </div>
      <div class="card-body quick-replies-card-body-list scrollbar p-0">
        <p class="m-3" *ngIf="filteredQuickReplies?.length === 0">
            Sin respuestas rápidas para este grupo.
        </p>
        <ul class="list-group list-group-flush" *ngIf="filteredQuickReplies?.length !== 0">
          <li class="list-group-item list-group-item-action cursor-pointer"
              [class.active]="selectedQuickReply?.id === quickReply.id"
              *ngFor="let quickReply of filteredQuickReplies"
              (click)="openSaveQuickReply(quickReply)">
                <div class="d-flex justify-content-between align-items-center">
                    <span class="text-truncate">
                        {{ quickReply.name }}
                    </span>
                </div>
                <div *ngIf="quickReply.groups.length > 0" class="mt-2">
                    <span
                        *ngFor="let group of quickReply.groups"
                        role="button"
                        class="badge bg-light text-dark me-1 mb-1"
                        [ngStyle]="{
                            'border-color': '#007bff',
                            'color': '#007bff',
                            'background-color': 'rgba(0, 123, 255, 0.1)',
                            'border': '1px solid #007bff',
                            'font-size': '0.8rem',
                            'border-radius': '4px'
                        }"
                        [title]="group.name">
                        {{ group.name }}
                    </span>
                </div>
            </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-xl-9 col-sm-12"
       [class.transparent]="!selectedQuickReply || !savingQuickReply"
       [class.not-transparent]="selectedQuickReply || savingQuickReply">
    <ng-container *ngIf="selectedQuickReply === undefined && !savingQuickReply">
      <div class="card card-body px-12 h-100 d-flex justify-content-center align-items-center">
        <h2 class="text-muted">¡Hola! Da click en visualizar un respuesta rápida para iniciar.</h2>
      </div>
    </ng-container>
    <div class="card card-body quick-reply-save-container scrollbar" *ngIf="savingQuickReply">
      <form
        [formGroup]="quickRepliesForm"
        (submit)="saveQuickReply()">
        <!-- Tag de información del chat -->
        <div class="row mb-2">
          <h5>
            <i class="fa fa-reply-all text-primary"></i>
            {{ selectedQuickReply?.name || 'Nueva respuesta rápida' }}
          </h5>
        </div>
        <!-- Nombre de la respuesta rápida -->
        <div class="row mb-3">
          <div class="col-12">
            <div class="form-floating">
              <input
                class="form-control"
                id="name"
                type="text"
                maxlength="150"
                placeholder="Nombre de la respuesta rápida"
                formControlName="name"
                [ngClass]="{
                  'is-invalid': (quickRepliesForm.get('name').touched && quickRepliesForm.get('name').value.length === 0) || duplicatedName,
                  'is-valid': checkingDuplicatedName === 'after-checking' && !duplicatedName && quickRepliesForm.get('name').valid
                }">
              <label for="name">
                Nombre de la respuesta rápida
              </label>
              <div class="valid-feedback text-success" *ngIf="quickRepliesForm.get('name').value.length > 0 && !sameName">
                Nombre disponible!
              </div>
              <div
                *ngIf="quickRepliesForm.get('name').touched && quickRepliesForm.get('name').value.length === 0"
                class="invalid-feedback text-warning"
              >
                Ingrese un nombre para la respuesta rápida, por favor.
              </div>
              <div
                *ngIf="duplicatedName"
                class="invalid-feedback text-warning"
              >
                La respuesta rápida ya existe, ingrese otro nombre.
              </div>
            </div>
          </div>
        </div>
        <!-- Texto de la respuesta rápida -->
        <div class="row mb-3">
          <div class="col-8 pe-0">
            <label class="form-label" for="content">
              Texto
            </label>
            <div class="position-relative">
              <textarea
                class="form-control scrollbar"
                rows="6"
                id="content"
                placeholder="Introduzca un texto de la respuesta rápida"
                formControlName="content"
                [ngClass]="{
                  'is-invalid': quickRepliesForm.get('content').touched && quickRepliesForm.get('content').invalid,
                }"></textarea>
              <button type="button" (click)="showQuickReplyTextItem()" tabindex="0" class="btn btn-sm btn-minimal btn-neutral expand-textarea-btn">
                <span class="btn-label-wrap">
                  <span class="btn-label-inner">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 24 24" size="20">
                      <path fill-rule="evenodd" d="M12 7a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8h-3a1 1 0 0 1-1-1Zm-5 5a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1Z" clip-rule="evenodd">
                      </path>
                    </svg>
                  </span>
                </span>
              </button>
            </div>
            <div
              *ngIf="quickRepliesForm.get('content').touched && quickRepliesForm.get('content').invalid"
              class="invalid-feedback text-warning">
              Ingrese el texto de la respuesta rápida.
            </div>
          </div>
          <div class="col-4">
            <label class="form-label ps-0">
              Botones
            </label>
            <div class="row mb-2" *ngFor="let quickReplyButton of quickReplyButtons">
              <div class="col-10">
                <div class="d-grid">
                  <button class="btn btn-sm btn-outline-primary" type="button">
                    {{ quickReplyButton.text }}
                  </button>
                </div>
              </div>
              <div class="col-2 px-1 d-flex align-items-center">
                <i class="fa fa-trash text-danger" role="button" (click)="removeButton(quickReplyButton)"></i>
              </div>
            </div>
            <div class="row" *ngIf="!addingButton">
              <div class="col-10">
                <div class="d-grid">
                  <button
                    class="btn btn-sm btn-outline-secondary"
                    type="button"
                    [disabled]="quickReplyButtons?.length === 3 || quickRepliesForm.get('content').value.length === 0"
                    (click)="addButton()">
                    <i class="fa fa-plus"></i>
                    Agregar nuevo
                  </button>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="addingButton">
              <div class="col-10">
                <div class="form-floating">
                  <input
                    class="form-control"
                    id="buttonText"
                    type="text"
                    placeholder="Nombre del botón"
                    formControlName="buttonText"
                    (keydown.enter)="submitAddButton()"
                    [ngClass]="{
                      'is-invalid': quickRepliesForm.get('buttonText').touched && quickRepliesForm.get('buttonText').value.length === 0,
                    }">
                  <label for="buttonText">
                    Texto del botón
                  </label>
                  <div
                    *ngIf="quickRepliesForm.get('buttonText').touched && quickRepliesForm.get('buttonText').value.length === 0"
                    class="invalid-feedback text-warning">
                    Ingrese el nombre del botón.
                  </div>
                </div>
              </div>
              <div class="col-2 px-1 d-flex align-items-center">
                <i class="fa fa-check-circle"
                   [class.text-secondary]="quickRepliesForm.get('buttonText').value.length === 0"
                   [class.text-success]="quickRepliesForm.get('buttonText').value.length > 0"
                   [ngStyle]="{ cursor: quickRepliesForm.get('buttonText').value.length === 0 ? 'not-allowed' : 'pointer' }"
                   role="button" (click)="submitAddButton()"></i>
                <i class="fa fa-trash text-danger ms-2" role="button" (click)="cancelAddButton()"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-6" *ngIf="selectedQuickReply && selectedQuickReply?.mediaItems?.length">
            <label class="form-label">
              Archivos cargados ({{ selectedQuickReply?.mediaItems?.length }})
            </label>
            <ul class="list-group list-group-flush quick-reply-items-list scrollbar" #sortableList>
              <ng-container *ngFor="let quickReplyItem of selectedQuickReply?.mediaItems">
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  <span class="h6 quick-reply-items-list-name-column d-flex align-content-center">
                    <i class="fa fa-grip-lines text-primary me-3"></i>
                    <span class="text-truncate">
                      {{ quickReplyItem.fileName }}
                    </span>
                  </span>
                  <span class="h6 quick-reply-items-list-column">
                    {{ quickReplyItem.typeName }}
                  </span>
                  <span class="h6 quick-reply-items-list-column">
                    {{ quickReplyItem.size }}
                  </span>
                  <span class="quick-reply-items-list-column d-flex justify-content-center align-items-center">
                    <div class="icon-div quick-reply-item-icon"
                         (click)="showQuickReplyItemDescription(quickReplyItem)"
                         container="body"
                         [ngbPopover]="quickReplyItemDescriptionPopoverContent"
                         triggers="mouseenter:mouseleave"
                         placement="left"
                         [popoverContext]="quickReplyItem"
                         popoverTitle="Descripción">
                      <i class="fa fa-bolt text-warning"></i>
                    </div>
                    <div class="icon-div quick-reply-item-icon ms-2">
                      <a [href]="quickReplyItem.fileUrl"
                         data-fancybox="gallery"
                         *ngIf="quickReplyItem.type === MessageType.Image"
                         container="body"
                         [ngbPopover]="quickReplyItemImagePopoverContent"
                         triggers="mouseenter:mouseleave"
                         placement="left"
                         [popoverContext]="quickReplyItem"
                         popoverTitle="Información">
                        <i class="fa fa-eye text-primary"></i>
                      </a>
                      <a [href]="quickReplyItem.fileUrl"
                         *ngIf="quickReplyItem.type !== MessageType.Image"
                         data-fancybox="gallery">
                        <i class="fa fa-eye text-primary"></i>
                      </a>
                    </div>
                    <div class="icon-div quick-reply-item-icon ms-2" (click)="deleteQuickReplyItem(quickReplyItem)">
                      <i class="fa fa-trash text-danger"></i>
                    </div>
                  </span>
                </li>
              </ng-container>
            </ul>
          </div>
          <div
            [class.col-6]="selectedQuickReply"
            [class.col-12]="!selectedQuickReply || selectedQuickReply.mediaItems?.length === 0">
            <label class="form-label">
              Adjuntar nuevos archivos
            </label>
            <uppy-dashboard
              [uppy]="uppy"
              [props]="uppyDashboardProps">
            </uppy-dashboard>
          </div>
        </div>
        <!-- Botones -->
        <div class="row g-3 justify-content-end">
          <div class="col-auto">
            <button
              class="btn btn-secondary"
              type="button"
              (click)="cancelSaveQuickReply()">
              Cancelar
            </button>
          </div>
          <div class="col-auto">
            <button
              type="button"
              (click)="deleteQuickReply(selectedQuickReply)"
              class="btn btn-danger">
              <i class="fa fa-trash me-1"></i>
              Eliminar
            </button>
          </div>
          <div class="col-auto">
            <button
              type="submit"
              class="btn btn-primary"
              [disabled]="!quickRepliesForm.get('name').value || !quickRepliesForm.get('content').value && uppy?.getFiles().length === 0 && selectedQuickReply?.items.length === 0">
              <i class="fa fa-save me-1"></i>
              Guardar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #showQuickReplyTextItemModal let-modal>
  <div class="modal-body scrollbar p-3">
    <form
      [formGroup]="quickRepliesForm">
      <textarea
        class="form-control scrollbar"
        rows="30"
        placeholder="Introduzca un texto de la respuesta rápida"
        formControlName="content"
        [ngClass]="{
          'is-invalid': quickRepliesForm.get('content').touched && quickRepliesForm.get('content').invalid,
        }"></textarea>
    </form>
  </div>
</ng-template>

<ng-template #quickReplyItemImagePopoverContent let-fileUrl="fileUrl">
  <img [src]="fileUrl" width="200px">
</ng-template>

<ng-template #quickReplyItemDescriptionPopoverContent let-description="description">
  <p>
    {{ description }}
  </p>
</ng-template>