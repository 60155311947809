
<div class="kanban-content border rounded" *ngIf="smartAssistant?.id">
  <div class="kanban-header">
    <div class="row gx-0 justify-content-between justify-content-md-start">
      <div class="col-auto d-flex align-items-center">
        <h3 class="text-1000 my-1">
          <i class="fa-duotone fa-cards-blank text-warning"></i>
          Tarjetas inteligentes
        </h3>
      </div>
      <div class="col-md-auto d-flex align-items-center ms-auto mt-2 mt-md-0">
        <ul class="nav w-100 fs--1 d-flex align-items-center">
          <li class="nav-item me-3">
            <button type="button" class="btn btn-outline-success px-3" (click)="openTestSmartAssistant(smartAssistant)">
              <i class="fa-duotone fa-flask text-success"></i>
              <span class="d-none d-sm-inline ms-1">
                Probar
              </span>
            </button>
          </li>
          <li class="nav-item">
            <a class="nav-link d-flex align-items-center text-900 ps-0 pe-2 px-xl-3 fw-bold"
               href="#" data-bs-toggle="modal" data-bs-target="#searchBoxModal">
              <i class="me-1 fas fa-search"></i>
              <span class="d-none d-xxl-inline">
                Buscar
              </span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link d-flex align-items-center text-900 px-2 px-xl-3 fw-bold" href="#">
              <i class="me-1 fas fa-filter"></i>
              <span class="d-none d-xxl-inline">
                Filtrar
              </span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link d-flex align-items-center text-900 px-2 px-xl-3 fw-bold" href="#">
              <span class="me-1 fa-solid fa-right-left"></span>
              <span class="d-none d-xxl-inline">
                Exportar / Importar
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="kanban-container smart-assistant-kanban scrollbar" data-kanban-container="data-kanban-container">

    <div class="kanban-column smart-topic"
         [attr.smart-topic-id]="smartAssistantTopic.id"
         *ngFor="let smartAssistantTopic of smartAssistantTopics">
      <div class="kanban-column-header px-4 hover-actions-trigger">
        <div class="d-flex align-items-center border-bottom border-3 py-3 border-primary">
          <h5 class="mb-0 kanban-column-title text-truncate" style="max-width: 80%"
              data-bs-toggle="tooltip" data-bs-placement="top" [title]="smartAssistantTopic.name">
            <span class="smart-topic-name">
              {{ smartAssistantTopic.name }}
            </span>
            <span class="kanban-title-badge">
              {{ smartAssistantTopic.items.length }}
            </span>
          </h5>
          <div class="hover-actions-trigger">
            <button class="btn btn-sm btn-phoenix-default kanban-header-dropdown-btn show"
                    type="button"
                    data-boundary="viewport"
                    data-bs-toggle="dropdown">
              <span class="fas fa-ellipsis-h"></span>
            </button>
            <div class="dropdown-menu dropdown-menu-end py-2">
              <a class="dropdown-item d-flex flex-between-center border-1" role="button"
                 (click)="openSaveSmartAssistantTopic(smartAssistantTopic)">
                <span>
                  <i class="fa fa-edit me-2 text-primary"></i>
                  Editar
                </span>
              </a>
              <a class="dropdown-item d-flex flex-between-center border-1" role="button"
                 (click)="deleteSmartAssistantTopic(smartAssistantTopic)">
                <span>
                  <i class="fa fa-trash me-2 text-danger"></i>
                  Eliminar
                </span>
              </a>
            </div>
          </div>
          <span class="uil uil-left-arrow-to-left fs-0 ms-auto kanban-collapse-icon" data-kanban-collapse="data-kanban-collapse">
          </span>
          <span class="uil uil-arrow-from-right fs-0 ms-auto kanban-collapse-icon"  data-kanban-collapse="data-kanban-collapse">
          </span>
        </div>
      </div>
      <div class="pt-3 pb-1 px-4 kanban-add-task">
        <a class="text-primary smart-topic-item-save" role="button"
           (click)="openSaveSmartAssistantTopicItem($event, smartAssistantTopic, null)">
          <i class="fa fa-plus"></i>
          Agregar nueva tarjeta
        </a>
      </div>
      <div class="kanban-items-sortable-container scrollbar">
        <div class="kanban-items-container smart-topic-items">
          <div class="sortable-item-wrapper smart-topic-item border-bottom px-2 py-2"
               [attr.smart-topic-item-id]="smartAssistantTopicItem.id"
               [attr.smart-topic-item-position]="smartAssistantTopicItem.topicPosition"
               *ngFor="let smartAssistantTopicItem of smartAssistantTopic.items">
            <div class="card sortable-item hover-actions-trigger">
              <div class="card-body py-3 px-3"
                   (click)="openSaveSmartAssistantTopicItem($event, smartAssistantTopic, smartAssistantTopicItem)">
                <div class="kanban-status mb-1 position-relative lh-1">
                <span
                  class="d-inline-block"
                  [ngClass]="{
                    'fa-notes text-primary me-1': smartAssistantTopicItem.actions?.length === 0,
                    'fa-bolt text-warning': smartAssistantTopicItem.actions?.length > 0,
                    'fa-solid': smartAssistantTopicItem.sendActions,
                    'fa-duotone': !smartAssistantTopicItem.sendActions
                  }"
                  style="min-width: 1rem;">
                </span>
                  <span class="mb-0 smart-topic-item-date text-600 fs--1 lh-1 me-3 white-space-nowrap">
                    {{ smartAssistantTopicItem.createdAt | date: 'dd/MM/yyyy HH:mm:ss' }}
                  </span>
                  <div class="font-sans-serif">
                    <button class="btn btn-sm btn-phoenix-default kanban-item-dropdown-btn hover-actions"
                            type="button" data-boundary="viewport" data-bs-toggle="dropdown">
                      <span class="fas fa-ellipsis-h fa-rotate-90" data-fa-transform="shrink-2"></span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end py-2">
                      <a class="dropdown-item smart-topic-item-save d-flex flex-between-center border-1" role="button"
                         (click)="openSaveSmartAssistantTopicItem($event, smartAssistantTopic, smartAssistantTopicItem)">
                        <span>
                          <i class="fa fa-edit me-2 text-primary"></i>
                          Editar
                        </span>
                      </a>
                      <a class="dropdown-item d-flex flex-between-center border-1 text-danger" role="button"
                         (click)="deleteSmartAssistantTopicItem(smartAssistantTopic, smartAssistantTopicItem)">
                        <span>
                          <i class="fa fa-trash me-2 text-danger"></i>
                          Eliminar
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <p class="mb-0 smart-topic-item-name stretched-link text-primary-200">
                  <b>
                    {{ smartAssistantTopicItem.name }}
                  </b>
                </p>
                <div class="text-truncate-container">
                  <p class="mb-0 smart-topic-item-content smart-topic-item-save stretched-link">
                    {{ smartAssistantTopicItem.content || smartAssistantTopicItem.context }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add new topic section -->
    <div class="kanban-column position-relative bg-transparent">
      <div class="d-flex h-100 flex-center fw-bold hover-bg-100">
        <a class="text-decoration-none stretched-link text-800" role="button"
           (click)="openSaveSmartAssistantTopic()">
          <div class="circle-btn bg-200 mx-auto mb-2">
            <span class="fas fa-plus" data-fa-transform="shrink-2"></span>
          </div>
          <span>
            Agregar nuevo tópico
          </span>
        </a>
      </div>
    </div>

  </div>
</div>

<ng-template #saveSmartAssistantTopicOffCanvas let-offcanvas>
  <div class="offcanvas-header align-items-start border-bottom flex-column">
    <div class="pt-1 w-100 d-flex justify-content-between align-items-start">
      <div>
        <h4 class="mb-2 me-2 lh-sm">
          {{ smartAssistantTopic ? 'Editar tópico' : 'Crea un tópico' }}
        </h4>
        <p class="mb-0 fs--1 text-info-500">
          Elige el nombre del tópico. Los tópicos son las categorías que el asistente puede responder.
        </p>
      </div>
      <button
        class="btn p-1 fw-bolder"
        type="button"
        (click)="offcanvas.close()">
        <i class="fas fa-xmark fs-2"></i>
      </button>
    </div>
  </div>
  <div class="offcanvas-body">
    <form
      [formGroup]="smartAssistantTopicForm"
      (submit)="saveSmartAssistantTopic()">
      <!-- Nombre del tópico -->
      <div class="row mb-3">
        <div class="col-12">
          <div class="form-floating">
            <input
              class="form-control"
              id="smartAssistantTopicName"
              type="text"
              placeholder="Nombre del tópico"
              formControlName="name"
              [ngClass]="{
                'is-invalid': smartAssistantTopicForm.get('name').touched && smartAssistantTopicForm.get('name').invalid,
                'is-valid': smartAssistantTopicForm.get('name').touched && smartAssistantTopicForm.get('name').valid
              }">
            <label for="smartAssistantTopicName">
              Nombre del tópico
            </label>
            <div
              *ngIf="smartAssistantTopicForm.get('name').touched && smartAssistantTopicForm.get('name').invalid"
              class="invalid-feedback text-warning"
            >
              Ingrese un nombre para el tópico, por favor.
            </div>
          </div>
        </div>
      </div>
      <!-- Botones -->
      <div class="row g-3 justify-content-end">
        <div class="col-auto">
          <button
            type="button"
            class="btn btn-phoenix-primary"
            (click)="offcanvas.close()">
            Cancelar
          </button>
        </div>
        <div class="col-auto">
          <button
            class="btn btn-primary"
            [disabled]="smartAssistantTopicForm.invalid">
            Guardar
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #saveSmartAssistantTopicItemModal>

  <app-smart-assistants-topic-item
    [smartAssistant]="smartAssistant"
    [smartAssistantTopic]="smartAssistantTopic"
    [smartAssistantTopicItem]="smartAssistantTopicItem">
  </app-smart-assistants-topic-item>

</ng-template>