
import { Directive, ElementRef, Renderer2, OnInit, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appBlockUi]'
})
export class BlockUiDirective implements OnInit, OnDestroy {

  private observer: MutationObserver;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.setupBlockUi();
    this.observer = new MutationObserver(() => {
      if (!document.body.contains(this.el.nativeElement)) {
        this.setupBlockUi();
      }
    });

    this.observer.observe(document.body, { childList: true, subtree: true });
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }

  private setupBlockUi(): void {
    this.renderer.appendChild(document.body, this.el.nativeElement);
  }

}