import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MarketplaceComponent } from './marketplace.component';
import { AppListComponent } from './app-list/app-list.component';
import { AppDetailComponent } from './app-detail/app-detail.component';

const routes: Routes = [
  {
    path: '',
    component: MarketplaceComponent,
    children: [
      { path: '', component: AppListComponent }, // Ruta para la lista de aplicaciones
      { path: 'app-detail/:id', component: AppDetailComponent },
      // { path: 'new', component: NewComponent } // Nueva ruta para el nuevo componente
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MarketplaceRoutingModule { }