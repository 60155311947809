export function loadStyles(styles: string[], theme?: string) {
  return new Promise<void>((resolve, reject) => {
    const stylePromises = styles.map(style => loadStyle(style, theme));
    Promise.all(stylePromises).then(() => {
      resolve();
    });
  });
}

export function loadStyle(path: string, theme?: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const head = document.getElementsByTagName('head')[0];
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = path;
    link.type = 'text/css';
    if (theme) {
      link.setAttribute('theme', theme);
    }
    link.onload = () => resolve();
    head.appendChild(link);
  });
}

export function removeStyles(theme?: string) {
  const links: NodeListOf<Element> = document.querySelectorAll('[theme="' + theme + '"]');
  for (const link of Array.from(links)) {
    link.remove();
  }
}

export function loadScripts(basePath: string, scripts: string[], theme: string) {
  return new Promise<void>((resolve) => {
    const scriptPromises = scripts.map(script => {
      const scriptPath = basePath ? `${basePath}${script}` : script;
      return loadScript(scriptPath, theme);
    });
    Promise.all(scriptPromises).then(() => {
      loadScript('./assets/lib/leap/js/theme.js').then(() => {
        resolve();
      });
    });
  });
}

export function loadScript(path: string, theme?: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const script = document.createElement('script');
    script.src = path;
    script.type = 'text/javascript';
    if (theme) {
      script.setAttribute('theme', theme);
    }
    script.onload = () => resolve();
    script.onerror = (error) => reject(error);
    document.body.appendChild(script);
  });
}
