import { Component } from '@angular/core';

@Component({
  selector: 'app-app-list-header',
  templateUrl: './app-list-header.component.html',
  styleUrls: ['./app-list-header.component.css']
})
export class AppListHeaderComponent {

}
