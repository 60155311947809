// marketplace.module.ts

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarketplaceComponent } from './marketplace.component';
import { AppListComponent } from './app-list/app-list.component';
import { AppDetailComponent } from './app-detail/app-detail.component';
import { MarketplaceRoutingModule } from './marketplace-routing.module';
import { AppGalleryComponent } from './app-detail/components/app-gallery/app-gallery.component';
import { AppReviewsComponent } from './app-detail/components/app-reviews/app-reviews.component';
import { AppInfoComponent } from './app-detail/components/app-info/app-info.component';
import { FavoriteAppsSliderComponent } from './app-list/components/favorite-apps-slider/favorite-apps-slider.component';
import { AppListHeaderComponent } from './app-list/components/app-list-header/app-list-header.component';
import { AppListPopularsComponent } from './app-list/components/app-list-populars/app-list-populars.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UppyAngularDashboardModule } from '@uppy/angular';
import { LightgalleryModule } from 'lightgallery/angular';

@NgModule({
  declarations: [
    MarketplaceComponent,
    AppListComponent,
    AppDetailComponent,
    AppGalleryComponent,
    AppReviewsComponent,
    AppInfoComponent,
    FavoriteAppsSliderComponent,
    AppListHeaderComponent,
    AppListPopularsComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MarketplaceRoutingModule,
    UppyAngularDashboardModule,
    LightgalleryModule
  ],
})
export class MarketplaceModule { }
