import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@app/services/account/user.service';
import { AuthService } from '@app/services/auth/auth.service';
import { ResetPasswordRequest } from '../../../models/account/user/reset-password-request.dto';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {

  public resetPasswordForm: FormGroup;
  public token: string;
  public resettingPassword: boolean = false;
  public resettingPasswordError: boolean = false;
  public showForm: boolean = true;
  public loading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParams;
    const token = queryParams['token'];

    if (token) {
      this.token = token;
    }

    this.resetPasswordForm = new FormGroup({
      newPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
      confirmNewPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
    }, {
      validators: this.passwordMatchValidator,
    });

  }

  resetPassword() {
    const { newPassword, confirmNewPassword } = this.resetPasswordForm.controls;

    if (this.resetPasswordForm.valid) {
      this.resettingPassword = true;
      this.showForm = false;
      this.loading = true;
      if (newPassword) {
        const resetPasswordRequest = new ResetPasswordRequest();
        resetPasswordRequest.newPassword = newPassword.value;
        resetPasswordRequest.token = this.token;

        this.userService.confirmResetPassword(resetPasswordRequest).subscribe({
          next: (response) => {
            this.resettingPassword = false;
            this.loading = false;

            setTimeout(() => {
              this.router.navigateByUrl('/');
            }, 3000);
          },
          error: (error) => {
            this.resettingPasswordError = true;
            this.resettingPassword = false;
          },
        });
      }
    }

  }

  passwordMatchValidator: ValidatorFn = (abstractControl: any): ValidationErrors | null => {
    const { controls } = abstractControl;
    const newPassword = controls.newPassword;
    const confirmNewPassword = controls.confirmNewPassword;

    if (newPassword.value !== confirmNewPassword.value) {
      confirmNewPassword.setErrors({ passwordMismatch: true });
    }

    return null;
  };
}
