<div class="preloader-container"
     [class.hidden]="allLoaded"
     [class.d-none]="allLoadedAfter">
  <div class="preloader"></div>
</div>

<div class="main-container"
     [class.not-transparent]="allLoaded"
     [class.transparent]="!allLoaded">

  <app-landing-page-header-component></app-landing-page-header-component>

  <section class="pb-0 pt-5 pt-lg-6">
    <div class="container border-bottom">
      <div class="row align-items-center justify-content-between">
        <div class="col-lg-6 px-md-6 px-lg-0 main-content-left">
          <h1 class="display-5 main-header-text">
            <span class="text-grey-color">
              Haz tu atención a <br>
              clientes 24 hrs con <br>
            </span>
            <span class="text-deep-blue">
              Inteligencia Artificial
            </span>
          </h1>
          <p class="lead">
            Comienza a digitalizarte, incrementar ventas <br>
            y mejorar la experiencia de atención al <br>
            cliente con ayuda de <strong class="text-deep-blue">IVANA</strong>
            <br><br>
            La <strong class="text-deep-blue">IA</strong> será <b class="text-deep-blue">1+</b> de tu work team
          </p>
          <a href="#" class="btn btn-deep-blue btn-lg mt-3 ff-kanit fw-regular" [routerLink]="['/precios']">
            <span>Prueba</span>
            <span class="text-uppercase font-weight-bold mx-1">gratuita</span>
            <span>por {{ trialPeriodDays }} días</span>
          </a>
        </div>
        <div class="col-lg-6 main-content-right">
          <img src="./assets/img/landing-page/ivana-screenshot.png" alt="Image" data-aos="zoom-out-right">
        </div>
      </div>
    </div>
  </section>
  <section class="py-4">
    <div class="container">
      <div class="row text-center mb-5">
        <div class="col">
          <small class="text-uppercase ff-kanit fw-regular">
            Empresas que confían en IVANA
          </small>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <ul class="d-flex flex-wrap justify-content-center list-unstyled">
            <li class="mx-4 mb-5" data-aos="fade-up" data-aos-delay="100">
              <img class="icon icon-logo icon-md opacity-70"
                   src="./assets/img/landing-page/capland-logo.png" alt="icon" />
            </li>
            <li class="mx-4 mb-5" data-aos="fade-up" data-aos-delay="200">
              <img class="icon icon-logo icon-md opacity-70"
                   src="./assets/img/landing-page/adis-logo.png" alt="icon" />
            </li>
            <li class="mx-4 mb-5" data-aos="fade-up" data-aos-delay="300">
              <img class="icon icon-logo icon-md opacity-70"
                   src="./assets/img/landing-page/premium-tactical-logo.png" alt="icon" />
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <ul class="d-flex flex-wrap justify-content-center list-unstyled">
            <li class="mx-4 mb-5" data-aos="fade-up" data-aos-delay="400">
              <img class="icon icon-logo icon-md opacity-70"
                   src="./assets/img/landing-page/tux-tareas-logo.png" alt="icon" />
            </li>
            <li class="mx-4 mb-5" data-aos="fade-up" data-aos-delay="500">
              <img class="icon icon-logo icon-md opacity-70"
                   src="./assets/img/landing-page/raites-chicanos.png" alt="icon" />
            </li>
            <li class="mx-4 mb-5" data-aos="fade-up" data-aos-delay="600">
              <img class="icon icon-logo icon-md opacity-70"
                   src="./assets/img/landing-page/danta-express-logo.png" alt="icon" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <app-pricing-list-component></app-pricing-list-component>

  <app-feature-list></app-feature-list>
  <app-whatsapp-bubble></app-whatsapp-bubble>

  <section *ngIf="false" class="pt-0">
    <div class="container">
      <div class="row justify-content-center text-center mb-6">
        <div class="col-xl-8 col-lg-9">
          <h2 class="display-4 mx-xl-6">Build it from the ground up.</h2>
          <p class="lead"> Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa. </p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-xl-9">
          <div class="video-poster rounded">
            <a data-fancybox href="https://vimeo.com/40842620" class="btn btn-lg btn-primary btn-round">
              <img class="icon" src="./assets/lib/leap/img/icons/theme/media/play.svg" alt="play icon" data-inject-svg />
            </a>
            <img src="./assets/lib/leap/img/article-5.jpg" alt="Image">
          </div>
        </div>
      </div>
    </div>
  </section>
  <section *ngIf="false" class="has-divider">
    <div class="container pt-0">
      <div class="row text-center">
        <div class="col-md-4 mb-3 mb-md-0" data-aos="fade-up">
          <div class="px-xl-2">
            <div class="process-circle bg-primary mb-3 d-inline-flex">1</div>
            <h4>Create your account</h4>
            <p> Autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae. </p>
          </div>
        </div>
        <div class="col-md-4 mb-3 mb-md-0" data-aos="fade-up" data-aos-delay="200">
          <div class="px-xl-2">
            <div class="process-circle bg-primary-2 mb-3 d-inline-flex">2</div>
            <h4>Build great websites</h4>
            <p> Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium. </p>
          </div>
        </div>
        <div class="col-md-4 mb-3 mb-md-0" data-aos="fade-up" data-aos-delay="300">
          <div class="px-xl-2">
            <div class="process-circle bg-primary-3 mb-3 d-inline-flex">3</div>
            <h4>Deploy and make money</h4>
            <p> Sit voluptatem accusantium doloremque laudantium, totam rem aperiam. </p>
          </div>
        </div>
      </div>
    </div>
    <div class="divider">
      <img class="bg-primary-alt" src="./assets/lib/leap/img/dividers/divider-1.svg" alt="divider graphic" data-inject-svg />
    </div>
  </section>
  <section *ngIf="false" class="has-divider bg-primary-alt">
    <div class="container">
      <div class="row justify-content-around align-items-center">
        <div class="col-xl-3 col-lg-4 col-md-3 d-flex mb-5 mb-md-0" data-aos="fade-right">
          <div class="row justify-content-center">
            <div class="col-8 col-md">
              <div class="mobile-screenshot rotate-left layer-2">
                <img src="./assets/lib/leap/img/food-app-1.png" alt="Screenshot">
                <img src="./assets/lib/leap/img/iphone-xr.svg" alt="iPhone XR">
              </div>
              <div class="decoration-wrapper d-none d-md-block">
                <div class="decoration middle-y scale-2" data-jarallax-element="40">
                  <img class="bg-primary-2" src="./assets/lib/leap/img/decorations/deco-blob-8.svg" alt="deco-blob-8 decoration" data-inject-svg />
                </div>
                <div class="decoration left top scale-2">
                  <img class="bg-primary" src="./assets/lib/leap/img/decorations/deco-dots-6.svg" alt="deco-dots-6 decoration" data-inject-svg />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-8 d-flex flex-column">
          <div class="mb-3">
            <span class="badge badge-primary">New in V2</span>
            <h3 class="h1 mt-3"> Advertise features of your mobile application. </h3>
            <p class="lead"> Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae. </p>
          </div>
          <div class="row">
            <div class="col-6">
              <img class="icon icon-md bg-primary" src="./assets/lib/leap/img/icons/theme/devices/apple-watch.svg" alt="apple-watch icon" data-inject-svg />
              <div class="mt-2">
                <h5>Smart Features</h5>
              </div>
              <p> Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium. </p>
            </div>
            <div class="col-6">
              <img class="icon icon-md bg-primary" src="./assets/lib/leap/img/icons/theme/devices/airpods.svg" alt="airpods icon" data-inject-svg />
              <div class="mt-2">
                <h5>Connectivity</h5>
              </div>
              <p> Voluptatem accusantium doloremque laudantium, totam rem aperiam. </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="divider flip-x">
      <img class="bg-primary-3" src="./assets/lib/leap/img/dividers/divider-1.svg" alt="divider graphic" data-inject-svg />
    </div>
  </section>
  <section *ngIf="false" class="bg-primary-3">
    <div class="container">
      <div class="row justify-content-center text-center mb-6 text-light">
        <div class="col-xl-8 col-lg-9">
          <h2 class="display-4 mx-xl-6">Customers love it.</h2>
          <p class="lead"> Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa. </p>
        </div>
      </div>
      <div class="row" data-isotope-collection>
        <div class="col-sm-6 col-lg-4" data-isotope-item>
          <div class="card card-body">
            <div class="flex-grow-1 mb-3">
              <p class="lead"> &ldquo;This is a top quality product. No need to think twice before purchasing, you simply could not go wrong&rdquo; </p>
            </div>
            <div class="avatar-author align-items-center">
              <img src="./assets/lib/leap/img/avatars/male-4.jpg" alt="Avatar" class="avatar">
              <div class="ml-2">
                <h6>Jonathan Wilson</h6>
                <span>Designer</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-4" data-isotope-item>
          <div class="card card-body">
            <div class="flex-grow-1 mb-3">
              <p class="lead"> &ldquo;I was thrilled by the quality of the customer support. The team were helpful throughout the entire process, I couldn’t be happier with the results – will use again!&rdquo; </p>
            </div>
            <div class="avatar-author align-items-center">
              <img src="./assets/lib/leap/img/avatars/female-1.jpg" alt="Avatar" class="avatar">
              <div class="ml-2">
                <h6>Annabelle Porter</h6>
                <span>Developer</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-4" data-isotope-item>
          <div class="card card-body">
            <div class="flex-grow-1 mb-3">
              <p class="lead"> &ldquo;Finally, I’ve found a template that covers all bases for a bootstrapped startup. We were able to launch in days, not months.&rdquo; </p>
            </div>
            <div class="avatar-author align-items-center">
              <img src="./assets/lib/leap/img/avatars/female-4.jpg" alt="Avatar" class="avatar">
              <div class="ml-2">
                <h6>Anna Souzakis</h6>
                <span>Cusomter</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-4" data-isotope-item>
          <div class="card card-body">
            <div class="flex-grow-1 mb-3">
              <p class="lead"> &ldquo;Once again, my expectations have been surpassed – Bravo!&rdquo; </p>
            </div>
            <div class="avatar-author align-items-center">
              <img src="./assets/lib/leap/img/avatars/male-5.jpg" alt="Avatar" class="avatar">
              <div class="ml-2">
                <h6>Brett Thompson</h6>
                <span>Designer</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-4" data-isotope-item>
          <div class="card card-body">
            <div class="flex-grow-1 mb-3">
              <p class="lead"> &ldquo;Simply the best. Better than all the rest. I’d recommend this product to beginners and advanced users.&rdquo; </p>
            </div>
            <div class="avatar-author align-items-center">
              <img src="./assets/lib/leap/img/avatars/male-2.jpg" alt="Avatar" class="avatar">
              <div class="ml-2">
                <h6>Ravi</h6>
                <span>Designer</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-4" data-isotope-item>
          <div class="card card-body">
            <div class="flex-grow-1 mb-3">
              <p class="lead"> &ldquo;What usually takes at least 2 or 3 weeks was reduced to 4 days. That in itself is invaluable.&rdquo; </p>
            </div>
            <div class="avatar-author align-items-center">
              <img src="./assets/lib/leap/img/avatars/female-2.jpg" alt="Avatar" class="avatar">
              <div class="ml-2">
                <h6>Abigail Roberts</h6>
                <span>Marketer</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section *ngIf="false" class="o-hidden">
    <div class="container">
      <div class="row justify-content-center text-center mb-6">
        <div class="col-xl-8 col-lg-9">
          <h2 class="display-4 mx-xl-6">Take a closer look</h2>
          <p class="lead"> Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa. </p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="slider-phone">
            <img src="./assets/lib/leap/img/iphone-xr.svg" alt="Phone" class="col-9 col-md-5 col-lg-4 col-xl-3">
            <div data-flickity='{ "imagesLoaded": true, "wrapAround": true }'>
              <div class="carousel-cell col-9 col-md-5 col-lg-4 col-xl-3 mx-4">
                <img src="./assets/lib/leap/img/food-app-1.png" alt="Image">
              </div>
              <div class="carousel-cell col-9 col-md-5 col-lg-4 col-xl-3 mx-4">
                <img src="./assets/lib/leap/img/food-app-2.png" alt="Image">
              </div>
              <div class="carousel-cell col-9 col-md-5 col-lg-4 col-xl-3 mx-4">
                <img src="./assets/lib/leap/img/food-app-3.png" alt="Image">
              </div>
              <div class="carousel-cell col-9 col-md-5 col-lg-4 col-xl-3 mx-4">
                <img src="./assets/lib/leap/img/food-app-4.png" alt="Image">
              </div>
              <div class="carousel-cell col-9 col-md-5 col-lg-4 col-xl-3 mx-4">
                <img src="./assets/lib/leap/img/food-app-5.png" alt="Image">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section *ngIf="false" class="">
    <div class="container">
      <div class="row justify-content-center text-center" data-aos="fade-up">
        <div class="col-lg-6 col-md-8">
          <h3 class="display-4">Download it now.</h3>
          <div class="d-sm-flex justify-content-center my-2 my-md-4">
            <a href="#" class="btn btn-lg btn-primary mx-sm-2 mb-3 mb-sm-0">
              <img class="icon" src="./assets/lib/leap/img/icons/social/apple.svg" alt="apple social icon" data-inject-svg />
              <span>App Store</span>
            </a>
            <a href="#" class="btn btn-lg btn-primary mx-sm-2 mb-3 mb-sm-0">
              <img class="icon" src="./assets/lib/leap/img/icons/social/googleplay.svg" alt="googleplay social icon" data-inject-svg />
              <span>Google Play</span>
            </a>
          </div>
          <div class="mx-lg-5 text-small text-muted">Add some helper text here to explain the finer details of your product or service.</div>
        </div>
      </div>
    </div>
  </section>

  <app-landing-page-footer></app-landing-page-footer>
</div>