
<div class="d-flex flex-column">
  <p
    [innerHTML]="message.content"
    class="mb-0 message-content"
    [class.dark-url]="message.customerMessage">
    {{ message.content }}
  </p>
  <ul class="list-unstyled mt-3">
    <li *ngFor="let button of buttons">
      <div class="d-grid">
        <button
          class="btn btn-sm btn-outline-primary mb-2">
          {{ button.reply.title }}
        </button>
      </div>
    </li>
  </ul>
</div>