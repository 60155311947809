import { Component, Input } from '@angular/core';
import {
  TestSmartAssistantMessageContainer
} from '@models/utilities/smart-assistant/internal/test-smart-assistant-message-container.dto';
import {
  SmartAssistantTopicItemActionType
} from '@type/utilities/smart-assistant/smart-assistant-topic-item-action.type';
import { ChatInfo } from '@models/chat/chat/chat-info.dto';
import { MessageType } from '@type/chat/message-content.type';

@Component({
  selector: 'app-test-smart-assistant-message-container',
  templateUrl: './test-smart-assistant-message-container.component.html',
  styleUrls: ['./test-smart-assistant-message-container.component.css']
})
export class TestSmartAssistantMessageContainerComponent {

  public SmartAssistantTopicItemActionType = SmartAssistantTopicItemActionType;
  public MessageType = MessageType;

  @Input()
  public testMessage: TestSmartAssistantMessageContainer;

  @Input()
  public chat: ChatInfo;

}
