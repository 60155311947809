import { Component } from '@angular/core';

@Component({
  selector: 'app-empty-smart-assistant-board',
  templateUrl: './empty-smart-assistant-board.component.html',
  styleUrls: ['./empty-smart-assistant-board.component.css']
})
export class EmptySmartAssistantBoardComponent {

}
