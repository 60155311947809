<div class="container">
  <div class="row g-3 mb-3">
    <!-- Imagen principal -->
    <div class="col-12">
      <div class="d-flex align-items-center border rounded-3 text-center p-4 h-100">
        <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide" [onInit]="onInit">
          <img [src]="selectedImage" alt="..." class="img-fluid">
        </lightgallery>

      </div>
    </div>
  </div>
  <div class="row g-3">
    <!-- Miniaturas -->
    <div class="col-3" *ngFor="let image of images.slice(0, 4)">
      <div class="d-flex align-items-center border rounded-3 text-center p-2 h-100">
        <img [src]="image" alt="..." class="img-fluid" (click)="onSelectImage(image)">
      </div>
    </div>

  </div>
</div>
