import { PaginatedSearchRequest } from '../../../common/paginated-search-request.dto';

export class SmartAssistantOrchestratorSearchRequest extends PaginatedSearchRequest {

  shopId: number;
  searchTerm: string;

  constructor(shopId: number, size: number) {
    super();
    this.shopId = shopId;
    this.page = 0;
    this.size = size;
  }

}