import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { Page } from '../../models/common/page';
import { TeamInfo } from '../../models/account/team/team.info.dto';
import { SaveTeamRequest } from '../../models/account/team/save-team-request.dto';
import { TeamSearchRequest } from '../../models/account/team/team-search-request.dto';
import { CheckTeamNameExistenceRequest } from '../../models/account/team/check-team-name-existence-request.dto';
import { ChangeDefaultTeamRequest } from '../../models/account/team/change-default-team-request.dto';

@Injectable({
  providedIn: 'root',
})
export class TeamService {

  private apiUrl: string = `${environment.apiUrl}/teams`;

  constructor(
    private http: HttpClient,
  ) {

  }

  saveTeam(saveTeamRequest: SaveTeamRequest): Observable<TeamInfo> {
    const endpoint = `${this.apiUrl}/save-team`;
    return this.http.post<TeamInfo>(endpoint, saveTeamRequest);
  }

  searchTeams(teamSearch: TeamSearchRequest): Observable<Page<TeamInfo>> {
    const endpoint = `${this.apiUrl}/search-teams`;
    return this.http.post<Page<TeamInfo>>(endpoint, teamSearch);
  }

  deleteTeam(id: number): Observable<void> {
    const endpoint = `${this.apiUrl}/delete-team/${id}`;
    return this.http.delete<void>(endpoint);
  }

  checkTeamNameExistence(checkTeamNameExistence: CheckTeamNameExistenceRequest): Observable<Page<TeamInfo>> {
    const endpoint = `${this.apiUrl}/check-team-name-existence`;
    return this.http.post<any>(endpoint, checkTeamNameExistence);
  }

  changeDefaultTeam(changeDefaultTeamRequest: ChangeDefaultTeamRequest): Observable<void> {
    const endpoint = `${this.apiUrl}/change-default-team`;
    return this.http.post<void>(endpoint, changeDefaultTeamRequest);
  }

}