<form
  [formGroup]="messageTemplateBodyForm">
  <div class="row mb-3 border-bottom border-dashed border-300">
    <div class="col-12">
      <div class="row mb-1">
        <div class="col">
          <h4>
            Cuerpo
            <span
              role="button"
              class="badge badge-phoenix badge-phoenix-warning fs--2">
              Obligatorio
            </span>
          </h4>
          <p class="mb-1 fs--1">
            Ingresa el texto del mensaje en el idioma que seleccionaste.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="position-relative">
            <textarea
              class="form-control scrollbar mb-1"
              rows="6"
              maxlength="1024"
              id="bodyText"
              placeholder="Introduzca el contenido del cuerpo"
              formControlName="bodyText"
              [ngClass]="{
              'is-invalid': messageTemplateBodyForm.get('bodyText').touched && messageTemplateBodyForm.get('bodyText').invalid,
            }"></textarea>
            <button type="button" (click)="showMessageTemplateBody()" tabindex="0"
              class="btn btn-sm btn-minimal btn-neutral expand-textarea-btn">
              <span class="btn-label-wrap">
                <span class="btn-label-inner">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                       viewBox="0 0 24 24" size="20">
                    <path fill-rule="evenodd"
                          d="M12 7a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8h-3a1 1 0 0 1-1-1Zm-5 5a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1Z"
                          clip-rule="evenodd">
                    </path>
                  </svg>
                </span>
              </span>
            </button>
            <p class="fs--1">
              {{ messageTemplateBodyForm.get('bodyText')?.value.length }} de 1024 caracteres
            </p>
          </div>
          <div
            *ngIf="messageTemplateBodyForm.get('bodyText').touched && messageTemplateBodyForm.get('bodyText').invalid"
            class="invalid-feedback text-warning">
            Ingrese el texto del cuerpo de la plantilla de mensaje de WhatsApp, por favor.
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<ng-template #showMessageTemplateBodyModal>
  <div class="modal-body scrollbar p-3">
    <form
      [formGroup]="messageTemplateBodyForm">
      <textarea
        class="form-control scrollbar"
        rows="30"
        placeholder="Introduzca el contenido del cuerpo"
        formControlName="bodyText"
        [ngClass]="{
          'is-invalid': messageTemplateBodyForm.get('bodyText').touched && messageTemplateBodyForm.get('bodyText').invalid,
        }"></textarea>
    </form>
  </div>
</ng-template>
