
export class MarkChatAsSeenRequest {

  chatId: number;
  seen: boolean;

  constructor(chatId: number, seen: boolean) {
    this.chatId = chatId;
    this.seen = seen;
  }

}
