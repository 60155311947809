import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../../services/account/user.service';
import { ActivateUserRequest } from '../../../../models/account/user/activate-user-request.dto';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-activate-user',
  templateUrl: './activate-user.component.html',
  styleUrls: ['./activate-user.component.css'],
})
export class ActivateUserComponent implements OnInit {

  public currentTheme: string = 'light';
  public activeUserForm: FormGroup;
  public loading: boolean = false;
  public showConfirmationMessage: boolean = false;
  public error: string = '';
  public token: string;

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router) {
  }

  ngOnInit() {
    this.initializeForm();
    this.checkTheme();
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
      if (!this.token) {
        this.router.navigate(['/']);
      }
    });
  }

  private checkTheme() {
    const theme = window.localStorage.getItem('phoenixTheme');
    this.currentTheme = theme ? theme : 'light';
  }

  private initializeForm() {
    this.activeUserForm = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      confirmPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
    });

  }

  activeUser() {
    this.loading = true;

    const { password, confirmPassword } = this.activeUserForm.controls;
    if (password.value !== confirmPassword.value) {
      this.error = 'Las contraseñas no coinciden';
      this.loading = false;
      return;
    }

    const activeUserRequest: ActivateUserRequest = {
      token: this.token,
      newPassword: password.value,
      confirmNewPassword: confirmPassword.value,
    };


    this.userService.activeUser(activeUserRequest).subscribe({
      next: () => {
        this.showConfirmationMessage = true;
        this.toastr.success('User activated successfully');
      },
      error: (error: any) => {
        this.toastr.error(error.error.message);
      },
      complete: () => {
      },
    });


  }

  changeView() {
    this.router.navigate(['/sign-in']);
  }
}