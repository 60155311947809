import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  ElementRef,
  Input, OnDestroy,
  OnInit, ViewChild,
} from '@angular/core';
import { SmartAssistantInfo } from '@models/utilities/smart-assistant/smart-assistant-info.dto';
import { UserInfo } from '@models/account/user/user.info.dto';
import { AuthService } from '@services/auth/auth.service';
import { MessageInfo } from '@models/chat/message/message-info.dto';
import { ChatInfo } from '@models/chat/chat/chat-info.dto';
import { isMediaMessage, MessageType } from '@type/chat/message-content.type';
import { MessageStatus } from '@type/chat/message-status.type';
import { FormControl } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { getMessageStatusClassString } from '@app/utils/chat-utils';
import { TestSmartAssistantService } from '@services/utilities/smart-assistant/test-smart-assistant.service';
import {
  SendTestSmartAssistantMessageRequest,
} from '@models/utilities/smart-assistant/test/send-test-smart-assistant-message-request.dto';
import { Subscription } from 'rxjs';
import {
  SendTestSmartAssistantMessageResponse,
} from '@models/utilities/smart-assistant/test/send-test-smart-assistant-message-response.dto';
import { EventEmitterService, NotificationTopic } from '@services/data/event-emitter.service';
import { QuickReplyInfo } from '@models/chat/quick-reply/quick-reply-info.dto';
import {
  SmartAssistantTopicItemActionType,
} from '@type/utilities/smart-assistant/smart-assistant-topic-item-action.type';
import {
  TestSmartAssistantMessageResponse,
} from '@models/utilities/smart-assistant/test/test-smart-assistant-message-response.dto';
import { SmartAssistantTopicItemType } from '@type/utilities/smart-assistant/smart-assistant-topic-item.type';
import {
  SmartAssistantTopicItemInfo,
} from '@models/utilities/smart-assistant/topic-item/smart-assistant-topic-item-info.dto';
import {
  TestSmartAssistantMessageContainer,
} from '@models/utilities/smart-assistant/internal/test-smart-assistant-message-container.dto';
import { ChatTagInfo } from '@models/chat/tag/chat-tag-info.dto';
import {
  SmartAssistantOrchestratorInfo,
} from '@models/utilities/smart-assistant/orchestrator/smart-assistant-orchestrator-info.dto';

@Component({
  selector: 'app-test-smart-assistant',
  templateUrl: './test-smart-assistant.component.html',
  styleUrls: ['./test-smart-assistant.component.css'],
})
export class TestSmartAssistantComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input()
  public smartAssistant: SmartAssistantInfo;

  @Input()
  public smartAssistantOrchestrator: SmartAssistantOrchestratorInfo;

  @ViewChild('messageField')
  private messageField: ElementRef;

  @ViewChild('chatScrollContainer')
  private chatScrollContainer: ElementRef;

  public textAreaFormControl: FormControl = new FormControl('');
  public MessageType = MessageType;

  public user: UserInfo;
  public testMessages: TestSmartAssistantMessageContainer[] = [];
  public chat: ChatInfo;

  public threadId: string;
  public chatIdentifier: string;

  private componentSubscriptions: Subscription[] = [];

  constructor(
    private authService: AuthService,
    private testSmartAssistantService: TestSmartAssistantService,
    private cdr: ChangeDetectorRef,
    private eventEmitterService: EventEmitterService,
  ) {
    this.user = this.authService.getUser();
  }

  ngOnInit() {
    this.chatIdentifier = uuidv4();
    this.bulidTestChat();
    this.initializeTestSmartAssistantMessageReceivedEvent();
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.componentSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  private initializeTestSmartAssistantMessageReceivedEvent() {

    const subscription = this.eventEmitterService.getEventEmitter(NotificationTopic.TestSmartAssistantMessageReceived)
      .subscribe({
        next: (data: TestSmartAssistantMessageResponse) => {

          const {
            type,
            messages,
            smartMessages,
            smartAssistantTopicItem,
          } = data;

          switch (type) {
            case SmartAssistantTopicItemType.Message:
              for (const message of messages)
                this.addMessage(message);
              break;
            case SmartAssistantTopicItemType.Action:
              this.addActionMessages(smartAssistantTopicItem);
              for (const smartMessage of smartMessages)
                this.addMessage(smartMessage);
              break;
          }

          this.cdr.detectChanges();
          this.scrollToBottom();
        },
      });

    this.componentSubscriptions.push(subscription);
  }

  private addMessage(message: string) {

    const textMessage: MessageInfo = new MessageInfo();

    textMessage.content = message;
    textMessage.type = MessageType.Text;
    textMessage.sentTime = new Date();
    textMessage.status = MessageStatus.Sent;
    textMessage.customerMessage = true;

    const testMessage = new TestSmartAssistantMessageContainer(SmartAssistantTopicItemActionType.Message);
    testMessage.message = textMessage;

    this.testMessages.push(testMessage);
  }

  private addActionMessages(smartAssistantTopicItem: SmartAssistantTopicItemInfo) {

    const { actions } = smartAssistantTopicItem;

    for (const action of actions) {

      const {
        type,
        message,
        quickReply,
        chatTags,
        httpCallUrl
      } = action;

      switch (type) {
        case SmartAssistantTopicItemActionType.HttpCall:
          this.addHttpCallResponseMessage(httpCallUrl);
          break;
        case SmartAssistantTopicItemActionType.Message:
          this.addMessage(message);
          break;
        case SmartAssistantTopicItemActionType.QuickReply:
          this.addQuickReplyMessages(quickReply);
          break;
        case SmartAssistantTopicItemActionType.ChatTag:
          this.addChatTagMessages(chatTags);
          break;
        case SmartAssistantTopicItemActionType.HummanAssitantAttention:
          this.addHummanAssitantAttentionMessage();
          break;
      }

    }

  }

  private addQuickReplyMessages(quickReply: QuickReplyInfo) {

    const textItem = quickReply.items.find(item => item.type === MessageType.Text);
    const mediaItems = quickReply.items.filter(item => isMediaMessage(item.type));
    const buttons = quickReply.buttons;

    for (const mediaItem of mediaItems) {

      const message: MessageInfo = new MessageInfo();

      message.sentTime = new Date();
      message.status = MessageStatus.Delivered;
      message.customerMessage = true;

      message.fileUrl = mediaItem.fileUrl;
      message.fileKey = mediaItem.fileKey;
      message.fileName = mediaItem.fileName;
      message.fileContentType = mediaItem.fileContentType;
      message.fileSize = mediaItem.fileSize;
      message.type = mediaItem.type;

      const testMessage = new TestSmartAssistantMessageContainer(SmartAssistantTopicItemActionType.Message);
      testMessage.message = message;

      this.testMessages.push(testMessage);
    }

    if (textItem) {

      const message: MessageInfo = new MessageInfo();
      message.content = textItem.content;
      message.type = textItem.type;
      message.sentTime = new Date();
      message.status = MessageStatus.Delivered;
      message.customerMessage = true;

      if (buttons?.length > 0) {

        message.type = MessageType.Interactive;

        const jsonButtons = [];
        for (const button of buttons) {
          jsonButtons.push({
            type: 'reply',
            reply: {
              title: button.text,
              id: '',
            },
          });
        }
        message.interactiveButtons = JSON.stringify(jsonButtons);
      }

      const testMessage = new TestSmartAssistantMessageContainer(SmartAssistantTopicItemActionType.Message);
      testMessage.message = message;

      this.testMessages.push(testMessage);
    }

  }

  private addChatTagMessages(chatTags: ChatTagInfo[]) {
    const testMessage = new TestSmartAssistantMessageContainer(SmartAssistantTopicItemActionType.ChatTag);
    testMessage.chatTags = chatTags;
    this.testMessages.push(testMessage);
  }

  private addHummanAssitantAttentionMessage() {
    const testMessage = new TestSmartAssistantMessageContainer(SmartAssistantTopicItemActionType.HummanAssitantAttention);
    this.testMessages.push(testMessage);
  }

  private addHttpCallResponseMessage(httpCallUrl: string) {
    const testMessage = new TestSmartAssistantMessageContainer(SmartAssistantTopicItemActionType.HttpCall);
    testMessage.httpCallUrl = httpCallUrl;
    this.testMessages.push(testMessage);
  }

  private bulidTestChat() {
    this.chat = new ChatInfo();
    this.chat.chatName = this.smartAssistant?.name || this.smartAssistantOrchestrator?.name;
    this.chat.color = '#3874ff';
  }

  public onKeydown(event: any) {
    const textAreaElement = this.messageField.nativeElement;
    let textAreaContent = this.textAreaFormControl.value;

    if (event.code === 'Enter') {
      if (event.ctrlKey) {
        this.textAreaFormControl.setValue(textAreaContent + '\n');
        textAreaElement.scrollTop = textAreaElement.scrollHeight;
      } else if (event.shiftKey) {
      } else {
        textAreaContent = textAreaContent.trim();
        event.preventDefault();
        if (textAreaContent) {
          this.textAreaFormControl.setValue(textAreaContent);
          this.sendMessage();
        }
      }
    }
  }

  public sendMessage() {
    const textAreaContent = this.textAreaFormControl.value.trim();
    if (textAreaContent) {
      this.sendTextMessage(textAreaContent);
      this.textAreaFormControl.setValue('');
    }
  }

  private sendTextMessage(textMessage: string) {

    const messageType = MessageType.Text;
    const messageIdentifier = uuidv4();

    const message = new MessageInfo();
    message.id = undefined;
    message.identifier = messageIdentifier;
    message.chatId = this.chat.id;
    message.phoneNumber = this.chat.chatPhoneNumber;
    message.wamid = undefined;
    message.content = textMessage;
    message.sentTime = new Date();
    message.status = MessageStatus.Delivered;
    message.type = messageType;
    message.customerMessage = false;
    message.statusClass = getMessageStatusClassString(message.status);
    message.hidden = false;

    const testMessage = new TestSmartAssistantMessageContainer(SmartAssistantTopicItemActionType.Message);
    testMessage.message = message;

    this.testMessages.push(testMessage);

    this.cdr.detectChanges();
    this.scrollToBottom();

    const sendTestSmartAssistantMessageRequest = new SendTestSmartAssistantMessageRequest(
      this.chatIdentifier,
      this.threadId,
      this.smartAssistant?.id,
      this.smartAssistantOrchestrator?.id,
      textMessage,
    );

    const subscription = this.testSmartAssistantService.sendTestSmartAssistantMessage(sendTestSmartAssistantMessageRequest)
      .subscribe({
        next: (response: SendTestSmartAssistantMessageResponse) => {
          this.threadId = response.threadId;
        },
        error: (error) => {
          console.log(error);
        },
      });

    this.componentSubscriptions.push(subscription);
  }

  private scrollToBottom() {
    const scrollElement = this.chatScrollContainer.nativeElement;
    scrollElement.scrollTop = scrollElement.scrollHeight;
  }

  protected readonly SmartAssistantTopicItemActionType = SmartAssistantTopicItemActionType;
}
