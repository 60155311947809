import { CallToActionType } from "./type/call-to-action.type";
import { CallToActionUrlType } from "./type/call-to-action-url.type";

export class CallToActionButton {

  index: number;
  type: CallToActionType;
  text: string;
  urlType: CallToActionUrlType;
  websiteUrl: string;
  example: string;
  countryCallingCode: string;
  phoneNumber: string;
  offerCode: string;

  constructor(type: CallToActionType, index: number) {
    this.index = index;
    this.type = type;
    this.text = '';
    this.urlType = CallToActionUrlType.Static;
    this.websiteUrl = '';
    this.countryCallingCode = '';
    this.phoneNumber = '';
    this.offerCode = '';
  }

}