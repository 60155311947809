// Angular
import { AfterViewInit, Component, Input, OnDestroy, OnInit, Output } from "@angular/core";

// RxJS
import { Subject, Subscription } from "rxjs";
import { CallToActionButton } from "@app/models/chat/whatsapp-message-template/ui/call-to-action-button.dto";
import { CallToActionType } from "@app/models/chat/whatsapp-message-template/ui/type/call-to-action.type";
import { separatePhoneNumber, validateOnlyNumbers } from "@app/utils/phone-utils";
import { CustomButton } from "@app/models/chat/whatsapp-message-template/ui/custom-button.dto";
import { EventEmitterService, NotificationTopic } from "../../../../../services/data/event-emitter.service";
import {
  CallToActionUrlType
} from "../../../../../models/chat/whatsapp-message-template/ui/type/call-to-action-url.type";
import {
  WhatsAppMessageTemplateInfo
} from "../../../../../models/chat/whatsapp-message-template/whatsapp-message-template-info.dto";
import {
  WhatsAppMessageTemplateButtonType
} from "../../../../../models/chat/whatsapp-message-template/type/whatsapp-message-template-button.type";

@Component({
  selector: "app-message-template-buttons",
  templateUrl: "./message-template-buttons.component.html",
  styleUrls: ["./message-template-buttons.component.css"],
})
export class MessageTemplateButtonsComponent implements OnInit, AfterViewInit, OnDestroy {

  protected readonly CallToActionType = CallToActionType;
  protected readonly CallToActionUrlType = CallToActionUrlType;

  @Input()
  public whatsAppMessageTemplate: WhatsAppMessageTemplateInfo;

  @Output()
  public onButtonsChange: Subject<any> = new Subject<any>();

  // State
  public selectedCountryCode: any;
  // State

  // Component Data
  private componentSubscriptions: Subscription[] = [];
  // Component Data

  @Input()
  public callToActionButtons: CallToActionButton[] = [];

  // Custom buttons
  @Input()
  public customButtons: CustomButton[] = [];

  public buttonTypeCounter: any = {
    [CallToActionType.CallToPhoneNumber]: 0,
    [CallToActionType.CallToWhatsAppNumber]: 0,
    [CallToActionType.GoToWebSite]: 0,
    [CallToActionType.CopyOfferCode]: 0,
  };

  constructor(
    private eventEmitterService: EventEmitterService,
  ) {

  }

  ngOnInit() {
    this.initializeButtons();
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.componentSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  private initializeButtons() {

    const { buttonsComponent } = this.whatsAppMessageTemplate;

    if (buttonsComponent) {

      const quickReplyButtons = buttonsComponent.buttons.filter(b => {
        return b.type === WhatsAppMessageTemplateButtonType.QuickReply;
      });

      const callToPhoneNumberButtons = buttonsComponent.buttons.filter(b => {
        return b.type === WhatsAppMessageTemplateButtonType.PhoneNumber;
      });

      // const callToWhatsAppNumberButtons = buttonsComponent.buttons.filter(b => {
      //   return b.type === WhatsAppMessageTemplateButtonType.PhoneNumber;
      // });

      const goToWebSiteButtons = buttonsComponent.buttons.filter(b => {
        return b.type === WhatsAppMessageTemplateButtonType.Url;
      });

      const copyCodeButtons = buttonsComponent.buttons.filter(b => {
        return b.type === WhatsAppMessageTemplateButtonType.CopyCode;
      });

      for (const button of quickReplyButtons) {
        const customButton = new CustomButton();
        customButton.text = button.text;
        customButton.index = this.customButtons.length;
        this.customButtons.push(customButton);
        this.notifyChanges();
      }

      for (const button of callToPhoneNumberButtons) {

        const type = CallToActionType.CallToPhoneNumber;
        const phoneNumberValue = separatePhoneNumber(button.phone_number);

        const callToActionButton = new CallToActionButton(type, this.callToActionButtons.length);
        callToActionButton.text = button.text;
        callToActionButton.countryCallingCode = phoneNumberValue.countryCode;
        callToActionButton.phoneNumber = phoneNumberValue.phoneNumber;
        this.callToActionButtons.push(callToActionButton);

        this.buttonTypeCounter[type] = this.buttonTypeCounter[type] ? this.buttonTypeCounter[type] + 1 : 1;
      }

      for (const button of goToWebSiteButtons) {

        const type = CallToActionType.GoToWebSite;

        const callToActionButton = new CallToActionButton(type, this.callToActionButtons.length);
        callToActionButton.text = button.text;
        callToActionButton.urlType = CallToActionUrlType.Static;
        callToActionButton.websiteUrl = button.url;
        this.callToActionButtons.push(callToActionButton);

        this.buttonTypeCounter[type] = this.buttonTypeCounter[type] ? this.buttonTypeCounter[type] + 1 : 1;
      }

      for (const button of copyCodeButtons) {


        const type = CallToActionType.CopyOfferCode;

        const callToActionButton = new CallToActionButton(type, this.callToActionButtons.length);
        callToActionButton.text = button.text;
        // callToActionButton.offerCode = button.offer_code;

        this.callToActionButtons.push(callToActionButton);

        this.buttonTypeCounter[type] = this.buttonTypeCounter[type] ? this.buttonTypeCounter[type] + 1 : 1;
      }

      // for (const button of callToWhatsAppNumberButtons) {
      //
      //   const type = CallToActionType.CallToWhatsAppNumber;
      //
      //   const callToActionButton = new CallToActionButton(type, this.callToActionButtons.length);
      //   callToActionButton.text = button.text;
      //   this.callToActionButtons.push(callToActionButton);
      //
      //   this.buttonTypeCounter[type] = this.buttonTypeCounter[type] ? this.buttonTypeCounter[type] + 1 : 1;
      // }
    }

  }

  public validatePhoneNumber(event: any) {
    validateOnlyNumbers(event);
  }

  public setSelectedCountryCode(selectedCountryCode: any) {
    this.selectedCountryCode = selectedCountryCode;
    const callToPhoneNumberButtons = this.getActionButtonsByType(CallToActionType.CallToPhoneNumber);
    if (callToPhoneNumberButtons?.length > 0)
      callToPhoneNumberButtons[0].countryCallingCode = selectedCountryCode.code;
    this.notifyChanges();
  }

  public addCustomButton() {
    const customButton = new CustomButton();
    customButton.index = this.customButtons.length;
    this.customButtons.push(customButton);
    this.notifyChanges();
  }

  public removeCustomButton(customButton: CustomButton) {
    this.customButtons.splice(customButton.index, 1);
    for (let i = 0; i < this.customButtons.length; i++) {
      this.customButtons[i].index = i;
    }
    this.notifyChanges();
  }

  public addCallToActionButton(type: CallToActionType) {

    const callToActionButtons = this.callToActionButtons.filter(b => b.type === type);
    const callToActionButton = new CallToActionButton(type, callToActionButtons.length);

    switch (type) {
      case CallToActionType.GoToWebSite:
        if (callToActionButtons.length === 2) return;
        break;
      case CallToActionType.CallToPhoneNumber:
        if (callToActionButtons.length === 1) return;
        break;
      case CallToActionType.CallToWhatsAppNumber:
        if (callToActionButtons.length === 1) return;
        break;
      case CallToActionType.CopyOfferCode:
        callToActionButton.text = 'Copiar código de oferta';
        if (callToActionButtons.length === 1) return;
        break;
    }

    this.buttonTypeCounter[type] = this.buttonTypeCounter[type] ? this.buttonTypeCounter[type] + 1 : 1;

    this.callToActionButtons.push(callToActionButton);

    this.notifyChanges();
  }

  public removeCallToActionButton(callToActionButton: CallToActionButton) {

    const { index, type } = callToActionButton;

    const indexFound = this.callToActionButtons.findIndex(b => b.type === type && b.index === index)
    this.callToActionButtons.splice(indexFound, 1);

    const callToActionButtonsOfType = this.callToActionButtons.filter(b => b.type === type)

    for (let i = 0; i < callToActionButtonsOfType.length; i++)
      callToActionButtonsOfType[i].index = i;

    this.buttonTypeCounter[type] = this.buttonTypeCounter[type] - 1;

    this.notifyChanges();
  }

  public notifyChanges() {
    this.eventEmitterService.getEventEmitter(NotificationTopic.MessageTemplateButtonsChange).emit();
  }

  public getActionButtonsByType(type: CallToActionType) {
    return this.callToActionButtons.filter(b => b.type === type);
  }

}
