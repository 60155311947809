import { ChatSearchDangeRangeFieldType } from "../../../../type/chat/chat-search-dange-range-field.type";
import { ChatTagSearchType } from "../../../../type/chat/chat-tag-search.type";

export class SaveBoardColumnRequest {

  id: number;
  name: string;
  boardId: number;

  fromDate: number;
  toDate: number;
  dateRangeFieldType: ChatSearchDangeRangeFieldType;

  userIds: number[] = [];
  chatTagIds: number[] = [];
  chatTagSearchType: ChatTagSearchType;

  sortDirection: string;
  notSeen: boolean;
  unanswered: boolean;
  archived: boolean;

}