
export class ToggleBoardColumnCollapsedRequest {

  id: number;
  collapsed: boolean;

  constructor(id: number, collapsed: boolean) {
    this.id = id;
    this.collapsed = collapsed;
  }

}