<div class="row g-5 mb-5" *ngIf="!loadingSubscription">

  <pre *ngIf="false">{{
      {
        alreadySubscribed: shop.alreadySubscribed,
        showChangeSubscriptionType,
        subscriptionCancelled: shop.subscriptionCancelled,

      } | json
    }}
  </pre>

  <div class="col-12" *ngIf="!shop.alreadySubscribed || showChangeSubscriptionType || shop.subscriptionCancelled">

    <section class="pt-0 pb-2">
      <div class="container">
        <div class="row pt-2 pb-0 text-center justify-content-center align-items-center">
          <div class="col-xl-12 col-lg-12 position-relative">

            <h1 class="display-5 py-3">
              <b>Planes de suscripción</b>
            </h1>

            <!-- Si se está escogiendo un plan por primera vez -->
            <ng-container *ngIf="!showChangeSubscriptionType">
              <p class="lead mb-1">
                Inicia tu suscripción a <b>IVANA.</b>
              </p>
              <p class="lead mb-0">
                ¡Obtén acceso a la <b class="text-indigo">IA conversacional</b> para tu tienda en línea!
              </p>
            </ng-container>

            <!-- Si se está cambiando de plan -->
            <ng-container *ngIf="showChangeSubscriptionType">
              <button class="btn btn-outline-indigo position-absolute top-0"
                      style="top: 0; right: 0"
                      (click)="goToSubscriptionResume()">
                <i class="fa fa-turn-left"></i>
                Volver
              </button>
              <p class="lead mb-1">
                Cambia tu plan de suscripción a <b class="text-indigo">IVANA.</b>
              </p>
            </ng-container>

            <ng-container *ngIf="shop.subscriptionCancelled">
              <p class="lead mb-0">
                ¡Sigue utilizando el poder de la <b class="text-indigo">IA conversacional!</b>
              </p>
            </ng-container>

          </div>
        </div>
      </div>
    </section>

    <section class="py-3">
      <div class="row mb-4 d-flex justify-content-center">
        <div class="col-lg-3 col-md-6 col-sm-12 mb-3" data-aos="fade-up"
             *ngFor="let subscriptionType of subscriptionTypes">
          <div class="card card-body border border-indigo shadow-sm align-items-center shadow-3d hover-shadow-3d">

            <div class="pt-md-2 mb-2 text-center">
              <h4 class="fs-2">
                {{ subscriptionType.name }}
              </h4>
              <span class="text-warning" *ngIf="selectedSubscriptionType === subscriptionType">
                <b class="fs-1">(Plan actual)</b>
              </span>
            </div>
            <div class="d-flex align-items-center pb-md-2">
              <span class="h3 me-1 mt-1">$</span>
              <span class="display-4 mb-0">
                {{ subscriptionType.price }}
              </span>
              <span class="h3 ms-1 mt-1">
                MXN
              </span>
            </div>
            <span class="text-small text-success mb-1">
              <b>Mensual</b>
            </span>

            <!-- Si se está escogiendo un plan por primera vez -->
            <ng-container *ngIf="!showChangeSubscriptionType">
              <button type="button" class="btn btn-outline-indigo fs-0"
                      (click)="selectSubscriptionType(subscriptionType)">
                <b>Seleccionar</b>
              </button>
            </ng-container>

            <!-- Si se está cambiando de plan -->
            <ng-container *ngIf="showChangeSubscriptionType">
              <!-- Si el plan actual no es el seleccionado -->
              <button type="button" class="btn btn-outline-indigo fs-0"
                      (click)="selectSubscriptionType(subscriptionType)"
                      *ngIf="selectedSubscriptionType !== subscriptionType">
                Cambiar a <b>{{ subscriptionType.name }}</b>
              </button>

            </ng-container>

            <ul class="text-center list-unstyled mt-2 mt-md-4">
              <li class="py-0" *ngFor="let subscriptionFeature of subscriptionType.features">
                <i class="fa-solid fa-check text-success"></i>
                <span>
                  {{ subscriptionFeature.name }}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

  </div>

  <ng-container *ngIf="shop.alreadySubscribed && !showChangeSubscriptionType">
    <ng-container *ngTemplateOutlet="subscriptionInfo"></ng-container>
  </ng-container>

</div>

<!-- Subscription information -->
<ng-template #subscriptionInfo>
  <div class="col-12" >
    <section class="pt-0 pb-2">
      <div class="container">
        <div class="row pt-2 pb-0 align-items-center">
          <div class="col-xl-6 col-lg-12">
            <h1 class="display-5 py-1">
              <b>Suscripción</b>
            </h1>
            <p class="lead mb-1">
              Información de tu cuenta <b class="text-indigo">IVANA.</b>
            </p>
            <div class="card">
              <div class="card-body">
                <div class="px-3">
                  <span class="badge bg-success mb-3">
                    Miembro desde {{ shop.createdAt | date: 'dd/MM/yyyy' }}
                  </span>
                  <h3>
                    Plan: {{ selectedSubscriptionType?.name }}
                  </h3>
                </div>
                <ul class="list-group list-group-flush mt-4 subscription-actions">
                  <li class="list-group-item d-flex justify-content-between align-items-center cursor-pointer py-2 px-3"
                      (click)="goToStripeBillingPortalSession()">
                    <span class="h4">
                      <i class="fa-duotone fa-credit-card text-primary"></i>
                      Administrar suscripción
                    </span>
                    <i class="fa fa-chevron-right"></i>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center cursor-pointer py-2 px-3"
                      (click)="openChangeSubscriptionType()">
                    <span class="h4">
                      <i class="fa-duotone fa-cards-blank text-warning"></i>
                      Cambiar de plan
                    </span>
                    <i class="fa fa-chevron-right"></i>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</ng-template>

<ng-template #changeSubscriptionConfirmationModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">
      Confirmar cambio de plan
    </h5>
    <button
      type="button"
      class="btn-close"
      (click)="modal.close()">
    </button>
  </div>
  <div class="modal-body">
    <p class="text-700 lh-lg mb-0">
      ¿Estás seguro de que deseas cambiar tu plan de suscripción a <b>{{ preSelectedSubscriptionType?.name }}</b>?
    </p>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" type="button" (click)="confirmChangeSubscription()">
      Confirmar
    </button>
    <button class="btn btn-outline-secondary" type="button" (click)="modal.close()">
      Cancelar
    </button>
  </div>
</ng-template>