// Angular
import {
  AfterViewInit,
  Component, Input,
  OnDestroy,
  OnInit, Output
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

// Toastr
import { ToastrService } from "ngx-toastr";

// RxJS
import { Subject, Subscription } from "rxjs";

// Message Template Models
import { WhatsAppMessageTemplateInfo } from "@app/models/chat/whatsapp-message-template/whatsapp-message-template-info.dto";

// Services
import { AuthService } from "@app/services/auth/auth.service";

import { getTypeByValue, getTypes } from "@app/utils/enum-utils";
import { WhatsAppMessageTemplateComponentHeaderMainType, WhatsAppMessageTemplateComponentHeaderMainTypeMetadata } from "@app/models/chat/whatsapp-message-template/type/whatsapp-message-template-component-header-main.type";
import {
  MessageType,
  MessageTypeMetadata,
  messageTypes
} from "@app/type/chat/message-content.type";
import { UserInfo } from "../../../../../models/account/user/user.info.dto";
import { formatBytes } from "../../../../../utils/file-utils";
import {
  WhatsAppMessageTemplateComponentInfo
} from "../../../../../models/chat/whatsapp-message-template/whatsapp-message-template-component-info.dto";
import {
  WhatsAppMessageTemplateComponentType
} from "../../../../../models/chat/whatsapp-message-template/type/whatsapp-message-template-component.type";
import {
  WhatsAppMessageTemplateComponentHeaderType
} from "../../../../../models/chat/whatsapp-message-template/type/whatsapp-message-template-component-header.type";
import { SpinnerService } from "../../../../../services/data/spinner.service";

@Component({
  selector: "app-message-template-header",
  templateUrl: "./message-template-header.component.html",
  styleUrls: ["./message-template-header.component.css"],
})
export class MessageTemplateHeaderComponent implements OnInit, AfterViewInit, OnDestroy {

  public selectedFileByType: any = {};
  public acceptTypes = {
    [MessageType.Document]: messageTypes[MessageType.Document].mediaTypes.join(','),
    [MessageType.File]: messageTypes[MessageType.File].mediaTypes.join(','),
    [MessageType.Video]: messageTypes[MessageType.Video].mediaTypes.join(','),
    [MessageType.Image]: messageTypes[MessageType.Image].mediaTypes.join(','),
  }

  @Input()
  public whatsAppMessageTemplate: WhatsAppMessageTemplateInfo;

  @Output()
  public onHeaderTextChange: Subject<string> = new Subject<string>();

  // Enums
  public WhatsAppMessageTemplateComponentHeaderMainType = WhatsAppMessageTemplateComponentHeaderMainType;
  public WhatsAppMessageTemplateComponentHeaderMainTypes = getTypes(WhatsAppMessageTemplateComponentHeaderMainType, WhatsAppMessageTemplateComponentHeaderMainTypeMetadata);
  public selectedWhatsAppMessageTemplateComponentHeaderMainType: WhatsAppMessageTemplateComponentHeaderMainType = WhatsAppMessageTemplateComponentHeaderMainType.None;

  public MessageType = MessageType;
  public MessageTypes = getTypes(MessageType, MessageTypeMetadata);
  // Enums

  // State & Data
  public selectedHeaderMessageType: MessageType;
  public selectedHeaderMessageTypeSizeLimit: string;
  public headerMessageTypeSelection: MessageType = undefined;

  // Component Data
  private user: UserInfo;
  private componentSubscriptions: Subscription[] = [];
  // Component Data

  // Forms
  public messageTemplateHeaderForm: FormGroup;
  // Forms

  constructor(
    private toastr: ToastrService,
    private spinnerService: SpinnerService,
    private authService: AuthService,
  ) {
    this.user = authService.getUser();
  }

  ngOnInit() {
    this.initializeForm();
    this.initializeHeader();
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.componentSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  private initializeForm() {

    this.messageTemplateHeaderForm = new FormGroup({
      headerMainType: new FormControl(this.selectedWhatsAppMessageTemplateComponentHeaderMainType, [
        Validators.required
      ]),
      headerText: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(60),
      ]),
    });

    this.messageTemplateHeaderForm.get('headerText').valueChanges
      .subscribe( {
        next: (value: string) => {
          this.setTextHeader(value);
        }
      });

  }

  private initializeHeader() {

    let headerMainType = WhatsAppMessageTemplateComponentHeaderMainType.None;

    if (this.whatsAppMessageTemplate.headerComponent) {

      const type = this.whatsAppMessageTemplate.headerComponent.format;

      switch (type) {
        case WhatsAppMessageTemplateComponentHeaderType.Image:
        case WhatsAppMessageTemplateComponentHeaderType.Video:
        case WhatsAppMessageTemplateComponentHeaderType.Document:
        case WhatsAppMessageTemplateComponentHeaderType.Location:
          headerMainType = WhatsAppMessageTemplateComponentHeaderMainType.Media;
          break;
        case WhatsAppMessageTemplateComponentHeaderType.Text:
          headerMainType = WhatsAppMessageTemplateComponentHeaderMainType.Text;
          this.messageTemplateHeaderForm.get('headerText').setValue(this.whatsAppMessageTemplate.headerComponent.text);
          break;
      }
    }

    this.setMessageTemplateComponentHeaderMainType(headerMainType);
    this.messageTemplateHeaderForm.get('headerMainType').setValue(headerMainType);
  }

  private setMessageTemplateComponentHeaderMainType(type: WhatsAppMessageTemplateComponentHeaderMainType, clear: boolean = false) {

    this.selectedWhatsAppMessageTemplateComponentHeaderMainType = type;

    switch (type) {
      case WhatsAppMessageTemplateComponentHeaderMainType.None:

        if (clear) this.clearHeader();

        break;
      case WhatsAppMessageTemplateComponentHeaderMainType.Text:

        if (clear) this.clearMediaHeader();
        else {
          this.messageTemplateHeaderForm.get('headerText').setValue(this.whatsAppMessageTemplate.headerComponent.text, { emitEvent: false });
        }

        break;
      case WhatsAppMessageTemplateComponentHeaderMainType.Media:

        if (clear) this.clearTextHeader();
        else {

          if (this.whatsAppMessageTemplate.headerComponent) {

            const messageType = getTypeByValue(MessageType, this.whatsAppMessageTemplate.headerComponent.format.toLowerCase());
            this.selectedHeaderMessageType = messageType;

            switch (messageType) {
              case MessageType.Image:
              case MessageType.Video:
              case MessageType.Document:

                const exampleAttachment =
                  this.whatsAppMessageTemplate.attachments.find(a => a.exampleUrl !== undefined);

                this.selectedFileByType[messageType] = {
                  fileUrl: exampleAttachment.exampleUrl,
                  fileName: exampleAttachment.fileName,
                  fileChanged: false
                };

                break;

              case MessageType.Location:
                break;
              case MessageType.Text:
                this.messageTemplateHeaderForm.get('headerText').setValue(this.whatsAppMessageTemplate.headerComponent.text, { emitEvent: false });
                break;
            }

            // Limpia los archivos seleccionados de los otros tipos
            this.clearOtherSelectedFiles();

            // Establece el header con el archivo seleccionado
            // PENDING!!!
            // this.setMediaHeader(result, messageType);
          }
        }
        break;
    }
  }

  public changeMessageTemplateComponentHeaderMainType(event: any) {
    const type = getTypeByValue(WhatsAppMessageTemplateComponentHeaderMainType, event.target.value);
    this.setMessageTemplateComponentHeaderMainType(type, true);
  }

  public setSelectedHeaderMessageType(messageType: MessageType) {
    const { sizeLimit } = messageTypes[messageType];
    this.selectedHeaderMessageTypeSizeLimit = formatBytes(sizeLimit);
  }

  public selectLocationHeaderType() {

    this.selectedHeaderMessageType = MessageType.Location;
    this.selectedHeaderMessageTypeSizeLimit = undefined;

    this.clearOtherSelectedFiles();
    this.clearTextHeader();
    this.clearMediaHeader();

    let headerComponent = this.whatsAppMessageTemplate.headerComponent;

    if (!headerComponent) {
      headerComponent = new WhatsAppMessageTemplateComponentInfo();
      headerComponent.type = WhatsAppMessageTemplateComponentType.Header;
      headerComponent.format = WhatsAppMessageTemplateComponentHeaderType.Location;
    }

    this.whatsAppMessageTemplate.headerComponent = headerComponent;
  }

  public openChooseFile(messageType: MessageType) {
    this.headerMessageTypeSelection = messageType;
    const input = document.getElementById(`fileInput_${messageType}`);
    input.click();
  }

  public async onFileChosen(event: Event, messageType: MessageType) {

    this.spinnerService.show('Cargando archivo...');

    setTimeout(async () => {

      // Obtiene los datos necesarios para procesar los atributos del archivo
      const result: any = await this.processFile(event);

      // Valida si el archivo es válido, sino limpia el input
      if (!this.validateFile(result.file, messageType)) {
        const fileInput = document.getElementById(`fileInput_${messageType}`) as HTMLInputElement;
        fileInput.value = '';
        return;
      }

      // Establece el archivo seleccionado
      this.selectedHeaderMessageType = this.headerMessageTypeSelection;
      this.selectedFileByType[messageType] = result;

      // Limpia los archivos seleccionados de los otros tipos
      this.clearOtherSelectedFiles();

      // Establece el header con el archivo seleccionado
      this.setMediaHeader(result, messageType);

      this.spinnerService.hide();
    }, 500);

  }

  private async processFile(event: Event) {
    return new Promise((resolve, reject) => {
      const input = event.target as HTMLInputElement;
      if (input.files && input.files.length > 0) {
        const file = input.files[0];
        const reader = new FileReader();
        reader.onload = (event: any) => {
          //const fileUrl = event.target.result;
          const fileUrl = URL.createObjectURL(file);
          resolve({
            file,
            fileUrl,
            fileChanged: false
          });
        };
        reader.readAsDataURL(file);
      }
    });
  }

  private clearOtherSelectedFiles() {
    for (const messageType of this.MessageTypes) {
      if (messageType.value !== this.selectedHeaderMessageType) {
        this.selectedFileByType[messageType.value] = undefined;
        const fileInput = document.getElementById(`fileInput_${messageType.value}`) as HTMLInputElement;
        if (fileInput) {
          fileInput.value = '';
        }
      }
    }
  }

  private setTextHeader(text: string) {

    let headerComponent = this.whatsAppMessageTemplate.headerComponent;

    if (!headerComponent) {
      headerComponent = new WhatsAppMessageTemplateComponentInfo();
      headerComponent.type = WhatsAppMessageTemplateComponentType.Header;
      headerComponent.format = WhatsAppMessageTemplateComponentHeaderType.Text;
    }

    headerComponent.text = text;

    if (text.length === 0)
      headerComponent = undefined;

    this.whatsAppMessageTemplate.headerComponent = headerComponent;
  }

  private setMediaHeader(fileResult: any, messageType: MessageType) {

    let headerComponent = new WhatsAppMessageTemplateComponentInfo();
    headerComponent.type = WhatsAppMessageTemplateComponentType.Header;

    const foundType = Object.values(WhatsAppMessageTemplateComponentHeaderType)
      .find(type => type.toLowerCase() === messageType.toLowerCase());

    if (foundType) {
      headerComponent.format = foundType;
    }

    headerComponent.example = {
      header_handle: [
        fileResult.fileUrl
      ]
    };

    this.whatsAppMessageTemplate.headerComponent = headerComponent;
    this.whatsAppMessageTemplate.file = fileResult.file;
  }

  private clearHeader() {
    this.clearMediaHeader();
    this.clearTextHeader();
  }

  private clearMediaHeader() {
    this.selectedHeaderMessageType = undefined;
    this.selectedHeaderMessageTypeSizeLimit = undefined;
    this.selectedFileByType = {};
    this.whatsAppMessageTemplate.headerComponent = undefined;
  }

  private clearTextHeader() {
    this.messageTemplateHeaderForm.get('headerText').setValue('');
    this.whatsAppMessageTemplate.headerComponent = undefined;
  }

  private validateFile(file: any, messageType: MessageType) {
    const { type, size } = file;
    const { mediaTypes, sizeLimit } = messageTypes[messageType];
    if (!mediaTypes.includes(type)) {
      this.toastr.error('El archivo seleccionado no es válido. Por favor elige un archivo válido.', 'Error de tipo de archivo');
      return false;
    }
    if (size > sizeLimit) {
      const formatBytesSize = formatBytes(sizeLimit);
      this.toastr.error(`El archivo es muy grande. Por favor elige un archivo con tamaño máximo de ${formatBytesSize}.`, 'Error de tamaño de archivo');
      return false;
    }
    return true;
  }

}
