
<ul class="nav nav-underline d-flex justify-content-center" id="myTab" role="tablist">
  <li class="nav-item">
    <a class="nav-link text-success active"
       id="home-tab"
       data-bs-toggle="tab"
       href="#tab-whatsapp"
       role="tab">
      <i class="fab fa-whatsapp"></i>
      WhatsApp
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link text-primary"
       id="profile-tab"
       data-bs-toggle="tab"
       href="#tab-messenger"
       role="tab">
      <i class="fab fa-facebook"></i>
      Facebook Messenger
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link text-danger"
       id="contact-tab"
       data-bs-toggle="tab"
       href="#tab-instagram"
       role="tab">
      <i class="fab fa-instagram"></i>
      Instagram
    </a>
  </li>
</ul>
<div class="d-flex justify-content-center">
  <div class="tab-content mt-3" style="min-width: 600px; max-width: 600px;">
    <div class="tab-pane fade show active" id="tab-whatsapp" role="tabpanel">
      <app-whatsapp-config></app-whatsapp-config>
    </div>
    <div class="tab-pane fade" id="tab-messenger" role="tabpanel">
      <app-messenger-config></app-messenger-config>
    </div>
    <div class="tab-pane fade" id="tab-instagram" role="tabpanel">
      <div class="card mb-3">
        <div class="card-body">
          <h4 class="card-title mb-3">
            Instagram
          </h4>
          <div class="row gx-3">
            <p>Proximamente...</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
