
export enum ChatTagSearchType {

  AtLeastOne = 'at-least-one',
  IncludeAll = 'include-all',
  IncludeAllStrict = 'include-all-strict',

}

export const ChatTagSearchTypeMetadata = {
  [ChatTagSearchType.AtLeastOne]: {
    description: 'Al menos una' // devuelve resultados que contienen al menos una de las etiquetas especificadas.
  },
  [ChatTagSearchType.IncludeAll]: {
    description: 'Incluir todas' // devuelve resultados que contienen todas las etiquetas especificadas, pero puede incluir etiquetas adicionales.
  },
  [ChatTagSearchType.IncludeAllStrict]: {
    description: 'Incluir todas estricto' // devuelve resultados que contienen exactamente todas las etiquetas especificadas, sin etiquetas adicionales.
  },
};
