<div class="container-small hero-header-container px-lg-7 px-xxl-3 py-1">
  <div class="row align-items-center">
    <div class="col-12 col-lg-auto order-0 order-md-1 text-end order-1">
      <div class="position-relative p-5 p-md-7 d-lg-none">
        <div class="bg-holder"
             style="background-image:url(./assets/img/background/bg-23.png);background-size:contain;">
        </div>
      </div>
      <div class="hero-image-container position-absolute top-0 bottom-0 end-0 d-none d-lg-block">
        <div class="position-relative h-100 w-100">
          <div class="position-absolute h-100 top-0 d-flex align-items-center end-0 hero-image-container-bg">
            <img class="pt-7 pt-md-0 w-100" src="./assets/img/background/bg-1-2.png" alt="hero-header" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6 text-lg-start text-center pt-8 pb-6 order-0 position-relative">
      <h1 class="fs-5 fs-lg-6 fs-md-7 fs-lg-6 fs-xl-7 fs fw-black mb-4"><span
        class=" me-3">Potencia</span>a <span class="text-primary">Ivana</span> con<br />Aplicaciones Poderosas</h1>
      <p class="mb-5">Descubre una amplia gama de aplicaciones poderosas diseñadas para mejorar tu experiencia con
        IVANA. Encuentra las herramientas perfectas para las necesidades de tu negocio sin tener que buscar
        más.</p>
      <a class="btn btn-lg btn-primary rounded-pill me-3" role="button">Mostrar más</a>
    </div>

  </div>
</div>