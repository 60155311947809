
export class SendTestSmartAssistantMessageRequest {

  identifier: string;
  threadId: string;
  smartAssistantId: number;
  smartAssistantOrchestratorId: number;
  message: string;

  constructor(identifier: string, threadId: string, smartAssistantId: number, smartAssistantOrchestratorId: number, message: string) {
    this.identifier = identifier;
    this.threadId = threadId;
    this.smartAssistantId = smartAssistantId;
    this.smartAssistantOrchestratorId = smartAssistantOrchestratorId;
    this.message = message;
  }

}