import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { WhatsAppMessageTemplateComponentHeaderType } from '@app/models/chat/whatsapp-message-template/type/whatsapp-message-template-component-header.type';
import { WhatsAppMessageTemplateInfo } from '@app/models/chat/whatsapp-message-template/whatsapp-message-template-info.dto';
import { ChatInfo } from "@app/models/chat/chat/chat-info.dto";
import { MessageStatus } from "@app/type/chat/message-status.type";
import { WhatsAppMessageTemplateType } from "@app/models/chat/whatsapp-message-template/type/whatsapp-message-template.type";
import { WhatsAppMessageTemplateButtonType } from "@app/models/chat/whatsapp-message-template/type/whatsapp-message-template-button.type";

@Component({
  selector: 'app-chat-preview-message-template',
  templateUrl: './preview-message-template.component.html',
  styleUrls: ['./preview-message-template.component.css']
})
export class PreviewMessageTemplateComponent implements OnInit, AfterViewInit, OnDestroy {

  public readonly WhatsAppMessageTemplateButtonType = WhatsAppMessageTemplateButtonType;

  // enums
  public readonly WhatsAppMessageTemplateType = WhatsAppMessageTemplateType;
  public readonly WhatsAppMessageTemplateComponentHeaderType = WhatsAppMessageTemplateComponentHeaderType;

  public readonly MessageStatus = MessageStatus;
  public readonly Object = Object;

  @Input()
  public chat: ChatInfo;

  @Input()
  public whatsAppMessageTemplate: WhatsAppMessageTemplateInfo;

  private componentSubscriptions: Subscription[] = [];

  constructor() {

  }

  ngOnInit() {

  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.componentSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
