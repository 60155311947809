<div class="form-floating mb-2">
  <input
    id="floatingInputAdvisors"
    type="text"
    class="form-control"
    [formControl]="advisorsFormControl"
    placeholder="Buscar asesores"
    [editable]="false"
    #users="ngbTypeahead"
    [ngbTypeahead]="searchChatAdvisors"
    [resultFormatter]="formatResultAdvisor"
    [inputFormatter]="formatInputAdvisor"
    (focus)="focusAdvisor$.next($any($event).target.value)"
    (click)="clickAdvisor$.next($any($event).target.value)">
  <label for="floatingInputAdvisors">
    {{ chatAdvisorsAvailable.length > 0 ? 'Buscar asesores' : 'No hay más asesores' }}
  </label>
</div>

<div class="mb-3" *ngIf="chatAdvisorsChosen">
  <span
    (click)="removeChatAdvisor(chatAdvisorsChosen)"
    role="button"
    class="badge badge-phoenix text-primary fs--2 me-1 border border-primary">
    {{ chatAdvisorsChosen.name }}
    <i class="fas fa-xmark tag-xmark-icon badge-icon"></i>
  </span>
</div>

