
export enum WhatsAppMessageTemplateComponentHeaderMainType {

  None = 'none',
  Text = 'text',
  Media = 'media',

}

export const WhatsAppMessageTemplateComponentHeaderMainTypeMetadata = {
  [WhatsAppMessageTemplateComponentHeaderMainType.None]: {
    description: 'Ninguno'
  },
  [WhatsAppMessageTemplateComponentHeaderMainType.Text]: {
    description: 'Texto'
  },
  [WhatsAppMessageTemplateComponentHeaderMainType.Media]: {
    description: 'Medios'
  },
};