<section class="header-inner o-hidden" data-aos-delay="1000">
  <div class="container">
    <div class="row py-3 text-center justify-content-center align-items-center">
      <div class="col-xl-12 col-lg-12">
        <h1 class="display-5">
          <b>Planes de suscripción</b>
        </h1>
        <p class="lead mb-2">
          Inicia probando <b>IVANA </b> <b class="text-deep-blue">gratis por {{ trialPeriodDays }} días</b>. Sin necesidad de un pago inicial.
        </p>
        <p class="lead mb-0">
          ¡Prueba ahora y descrube el poder de la <b class="text-indigo">IA conversacional</b>!
        </p>
      </div>
    </div>
  </div>
</section>
<section class="py-3">
  <div class="container">
    <div class="row mb-4 d-flex justify-content-center">
      <div class="col-md-4"
           *ngFor="let subscriptionType of subscriptionTypes"
           data-aos="fade-up" [attr.data-aos-delay]="subscriptionType.aosDelay">
        <div class="card card-body align-items-center shadow-3d hover-shadow-3d">
          <span class="badge badge-top badge-dark" *ngIf="subscriptionType.mostPopular">
            Más popular
          </span>
          <div class="pt-md-2">
            <h4>{{ subscriptionType.name }}</h4>
          </div>
          <div class="d-flex align-items-center pb-md-2">
            <span class="h3 mr-1 mt-1">$</span>
            <span class="display-4 mb-0">
              {{ subscriptionType.price }}
            </span>
            <span class="h3 ml-1 mt-1">
              MXN
            </span>
          </div>
          <span class="text-small text-muted mb-1">
            Mensual
          </span>
          <button type="button" class="btn btn-outline-primary"
                  (click)="selectSubscriptionType(subscriptionType)">
            Prueba gratuita por {{ trialPeriodDays }} días
          </button>
          <ul class="text-center list-unstyled mt-2 mt-md-4">
            <li class="py-0" *ngFor="let subscriptionFeature of subscriptionType.features">
              <i class="fa-solid fa-check text-success"></i>
              <span>
                {{ subscriptionFeature.name }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <span>
          Estás interesado en un plan personalizado? <a href="#">Ponte en contacto</a>
        </span>
      </div>
    </div>
  </div>
</section>