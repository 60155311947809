import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppDetailGalleryRequest } from '../../models/marketplace/app-detail-gallery-request.dto';

@Injectable({
  providedIn: 'root'
})
export class AppDetailGalleryService{

  private apiUrl: string = `${environment.apiUrl}/app-gallery`;

  constructor(
    private http: HttpClient,
  ) {

  }

  getAppGallery(id: number): Observable<any> {
    const endpoint = `${this.apiUrl}/app-gallery/${id}`;
    return this.http.get<any>(endpoint);
  }

  createAppGallery(appGalleryRequest: AppDetailGalleryRequest): Observable<any> {
    const endpoint = `${this.apiUrl}/create-app-gallery`;
    return this.http.post<any>(endpoint, appGalleryRequest);
  }

  getAppGalleryImages(appId: number): Observable<any> {
    const endpoint = `${this.apiUrl}/app-gallery-by-app-id/${appId}`;
    return this.http.get<any>(endpoint);
  }

}
