import { MessageType } from "@type/chat/message-content.type";
import { WhatsAppMessageTemplateComponentParameterType } from "../../whatsapp-message-template/type/whatsapp-message-template-component-parameter.type";
import { WhatsAppMessageTemplateAttachmentInfo } from "../../whatsapp-message-template/internal/whatsapp-message-template-attachment-info.dto";

export class SendMessageRecipientRequest {
  chatId: number;
  chatName: string;
  chatPhoneNumber: string;
  chatMessengerContactId: string;
}

export class SendWhatsAppMessageRequest {

  messaging_product: string;
  recipient_type: string;
  to: string;
  type: MessageType;

  template: SendWhatsAppMessageTemplateRequest
  context: SendWhatsAppMessageContextRequest;
  reaction: SendWhatsAppReactionRequest;

  interactive: SendWhatsAppInteractiveRequest;
  text: SendWhatsAppTextRequest;
  image: SendWhatsAppMediaRequest;
  sticker: SendWhatsAppMediaRequest;
  video: SendWhatsAppMediaRequest;
  audio: SendWhatsAppMediaRequest;
  document: SendWhatsAppMediaRequest;
  location: SendWhatsAppLocationRequest;

}

export class SendWhatsAppMessageTemplateRequest {

  name: string;
  language: SendWhatsAppMessageTemplateLanguageRequest;
  components: SendWhatsAppMessageTemplateComponentRequest[];

}

export class SendWhatsAppMessageContextRequest {
  message_id: string;
  contextFrom: string;
}

export class SendWhatsAppReactionRequest {
  message_id: string;
  emoji: string;
}

export class SendWhatsAppMessageTemplateLanguageRequest {

  code: string;

}

export class SendWhatsAppMessageTemplateComponentRequest {

  type: string;
  parameters: SendWhatsAppMessageTemplateComponentParameterRequest[];

  // Buttons
  sub_type?: string;
  index?: number;

}

export class SendWhatsAppMessageTemplateComponentParameterRequest {

  type: WhatsAppMessageTemplateComponentParameterType;

  text: string;
  image: SendWhatsAppMediaTemplateRequest;
  video: SendWhatsAppMediaTemplateRequest;
  document: SendWhatsAppMediaTemplateRequest;

  attachment: WhatsAppMessageTemplateAttachmentInfo;

}

// Normal Classes
export class SendWhatsAppTextRequest {
  previewUrl: string;
  body: string;
}

export class SendWhatsAppInteractiveRequest {
  type: string;
  body: SendWhatsAppInteractiveBodyRequest;
  action: SendWhatsAppInteractiveActionRequest;
}

export class SendWhatsAppInteractiveActionRequest {
  buttons: SendWhatsAppInteractiveButtonRequest[] = [];
}

export class SendWhatsAppInteractiveBodyRequest {
  text: string;
}

export class SendWhatsAppInteractiveButtonRequest {
  type: string;
  reply: SendWhatsAppInteractiveButtonReplyRequest;
}

export class SendWhatsAppInteractiveButtonReplyRequest {
  id: string;
  title: string;
}

export class SendWhatsAppMediaRequest {
  link: string;
  caption: string;
  filename: string;
}

export class SendWhatsAppLocationRequest {
  latitude: number;
  longitude: number;
  name: string;
  address: string;
}
// Normal Classes

// Template Classes
export class SendWhatsAppMediaTemplateRequest {
  link: string;
}
// Template Classes