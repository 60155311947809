<div class="offcanvas-header align-items-start border-bottom flex-column">
  <div class="pt-1 w-100 d-flex justify-content-between align-items-center">
    <div>
      <h4 class="me-2 lh-sm">
        Abre un chat de WhatsApp Web
      </h4>
    </div>
    <button class="btn p-1 fw-bolder" type="button" (click)="close()">
      <i class="fas fa-xmark fs-2"></i>
    </button>
  </div>
</div>
<div class="offcanvas-body scrollbar">
  <div class="row g-3">
    <div class="col-sm-12">
      <form
        [formGroup]="recipientForm"
        (submit)="sendMessage()">
        <div class="row g-2">
          <div class="col-5">
            <app-phone-number-typeahead
              (countryCodeChosenEvent)="setSelectedCountryCode($event)">
            </app-phone-number-typeahead>
          </div>
          <div class="col-7">
            <div class="form-floating mb-2">
              <input
                id="chatPhoneNumber"
                type="text"
                class="form-control"
                placeholder="Introduce el número del contacto"
                formControlName="chatPhoneNumber"
                maxlength="14"
                (keydown)="validatePhoneNumber($event)"
                [ngClass]="{
                'is-invalid': recipientForm.get('chatPhoneNumber').touched && recipientForm.get('chatPhoneNumber').errors,
                'is-valid': recipientForm.get('chatPhoneNumber').valid
              }">
              <label for="chatPhoneNumber">
                Número de WhatsApp
              </label>
            </div>
          </div>
        </div>
        <div class="row g-2">
          <div class="col-12">
            <div class="form-floating mb-4">
              <textarea
                id="message"
                class="form-control scrollbar chat-message"
                placeholder="Escribe tu mensaje"
                formControlName="message"
                rows="4"
                [ngClass]="{
                  'is-invalid': recipientForm.get('message').touched && recipientForm.get('message').errors,
                  'is-valid': recipientForm.get('message').valid
              }"></textarea>
              <label for="message">
                Mensaje
              </label>
            </div>
          </div>
        </div>
        <div class="d-grid mb-2">
          <button
            [disabled]="selectedCountryCode === undefined || recipientForm.invalid || sendingMessage"
            type="submit"
            class="btn btn-phoenix-success mb-1">
            <i class="fa fa-paper-plane me-1"></i>
            {{ sendingMessage ? 'Enviando mensaje...' : 'Enviar mensaje' }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
