import { ChatTagSearchType } from "@app/type/chat/chat-tag-search.type";
import { PaginatedSearchRequest } from "../../common/paginated-search-request.dto";
import { ChatSearchDangeRangeFieldType } from "@type/chat/chat-search-dange-range-field.type";
import { UserInfo } from '../../account/user/user.info.dto';

export class ChatSearchRequest extends PaginatedSearchRequest {

  currentUser: UserInfo;
  shopId: number;
  userIds: number[];
  chatTagIds: number[];
  searchTerm: string;
  chatTagSearchType: ChatTagSearchType;
  notSeen: boolean = false;
  unanswered: boolean = false;
  archived: boolean = false;
  fromDate: number;
  toDate: number;
  dateRangeFieldType: ChatSearchDangeRangeFieldType = ChatSearchDangeRangeFieldType.LastMessageDate;
  sortDirection: string;

  constructor(shopId: number, size: number, currentUser: UserInfo) {
    super();
    this.shopId = shopId;
    this.sortDirection = 'DESC';
    this.page = 0;
    this.size = size;
    this.currentUser = currentUser;
  }

}
