
export class CheckQuickReplyNameExistenceRequest {

  shopId: number;
  name: string;

  constructor(shopId: number, name: string) {
    this.shopId = shopId;
    this.name = name;
  }

}
