import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { ChatInfo } from '@app/models/chat/chat/chat-info.dto';
import { ChatSearchRequest } from '@app/models/chat/chat/chat-search-request.dto';
import { Page } from '@app/models/common/page';
import { AddChatTagRequest } from '@app/models/chat/chat/add-chat-tag-request.dto';
import { RemoveChatTagRequest } from '@app/models/chat/chat/remove-chat-tag-request.dto';
import { MarkChatAsSeenRequest } from '@app/models/chat/chat/mark-chat-as-seen-request.dto';
import { SaveChatNoteRequest } from '@app/models/chat/chat/save-chat-note-request.dto';
import { ArchiveChatRequest } from '@models/chat/chat/archive-chat-request.dto';
import { AssignChatAdvisorRequest } from '@models/chat/chat/assign-chat-advisor-request.dto';
import { SaveChatRequest } from '@models/chat/chat/save-chat-request.dto';
import { ChangeChatSmartAssistantStatusRequest } from '@models/chat/chat/change-chat-smart-assistant-status-request.dto';
import { ImportContactsRequest } from '@models/chat/chat/import-contacts-request.dto';
import { GetSignedUrlRequest } from '@models/media/get-signed-url-request.dto';
import { GetSignedUrlResponse } from '@models/media/get-signed-url-response.dto';
import { MessageInfo } from '@app/models/chat/message/message-info.dto';
import { SetChatAliasRequest } from '@app/models/chat/chat/set-chat-alias-request.dto';
import { StartExportWhatsAppWebContactsRequest } from "@models/chat/chat/start-export-whatsapp-web-contacts-request.dto";
import { ChatSummaryInfo } from '@models/chat/chat/chat-summary-info.dto';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  private apiUrl: string = `${environment.apiUrl}/chats`;

  constructor(
    private http: HttpClient,
  ) {

  }

  searchChats(chatSearch: ChatSearchRequest): Observable<Page<ChatInfo>> {
    const endpoint = `${this.apiUrl}/search-chats`;
    return this.http.post<Page<ChatInfo>>(endpoint, chatSearch);
  }

  saveChat(saveChatRequest: SaveChatRequest): Observable<void> {
    const endpoint = `${this.apiUrl}/save-chat`;
    return this.http.post<void>(endpoint, saveChatRequest);
  }

  getChatById(chatId: number): Observable<ChatInfo> {
    const endpoint = `${this.apiUrl}/get-chat-by-id/${chatId}`;
    return this.http.get<ChatInfo>(endpoint);
  }

  getChatByUuid(chatUuid: string): Observable<ChatInfo> {
    const endpoint = `${this.apiUrl}/get-chat-by-uuid/${chatUuid}`;
    return this.http.get<ChatInfo>(endpoint);
  }

  getChatThread(chatId: number): Observable<any> {
    const endpoint = `${this.apiUrl}/get-chat-thread/${chatId}`;
    return this.http.get<any>(endpoint);
  }

  addChatTag(addChatTagRequest: AddChatTagRequest): Observable<void> {
    const endpoint = `${this.apiUrl}/add-chat-tag`;
    return this.http.post<void>(endpoint, addChatTagRequest);
  }

  importContacts(importContactsRequest: ImportContactsRequest): Observable<any> {
    const endpoint = `${this.apiUrl}/import-contacts`;
    return this.http.post<any>(endpoint, importContactsRequest);
  }

  generateImportContactsSignedUrl(getSignedUrlRequest: GetSignedUrlRequest): Observable<GetSignedUrlResponse> {
    const endpoint = `${this.apiUrl}/generate-import-contacts-signed-url`;
    return this.http.post<GetSignedUrlResponse>(endpoint, getSignedUrlRequest);
  }

  startExportWhatsAppWebContacts(startExportWhatsAppWebContactsRequest: StartExportWhatsAppWebContactsRequest) {
    const endpoint = `${this.apiUrl}/start-export-whatsapp-web-contacts`;
    return this.http.post<void>(endpoint, startExportWhatsAppWebContactsRequest);
  }

  disconnectExportWhatsAppWebContacts(startExportWhatsAppWebContactsRequest: StartExportWhatsAppWebContactsRequest) {
    const endpoint = `${this.apiUrl}/disconnect-export-whatsapp-web-contacts`;
    return this.http.post<void>(endpoint, startExportWhatsAppWebContactsRequest);
  }

  removeChatTag(removeChatTagRequest: RemoveChatTagRequest): Observable<void> {
    const endpoint = `${this.apiUrl}/remove-chat-tag`;
    return this.http.post<void>(endpoint, removeChatTagRequest);
  }

  archiveChat(archiveChatRequest: ArchiveChatRequest): Observable<void> {
    const endpoint = `${this.apiUrl}/archive-chat`;
    return this.http.patch<void>(endpoint, archiveChatRequest);
  }

  assignChatAdvisor(assignChatAdvisorRequest: AssignChatAdvisorRequest): Observable<void> {
    const endpoint = `${this.apiUrl}/assign-chat-advisor`;
    return this.http.patch<void>(endpoint, assignChatAdvisorRequest);
  }

  markChatAsSeen(markChatAsSeenRequest: MarkChatAsSeenRequest): Observable<void> {
    const endpoint = `${this.apiUrl}/mark-chat-as-seen`;
    return this.http.post<void>(endpoint, markChatAsSeenRequest);
  }

  saveChatNote(saveChatNoteRequest: SaveChatNoteRequest): Observable<void> {
    const endpoint = `${this.apiUrl}/save-chat-note`;
    return this.http.post<void>(endpoint, saveChatNoteRequest);
  }

  changeChatSmartAssistantStatus(changeChatSmartAssistantStatusRequest: ChangeChatSmartAssistantStatusRequest) {
    const endpoint = `${this.apiUrl}/change-chat-smart-assistant-status`;
    return this.http.post<void>(endpoint, changeChatSmartAssistantStatusRequest);
  }

  getMediaMessages(chatId: number): Observable<MessageInfo[]> {
    const endpoint = `${this.apiUrl}/get-media-messages/${chatId}`;
    return this.http.get<MessageInfo[]>(endpoint);
  }

  setChatAlias(setChatAliasRequest: SetChatAliasRequest): Observable<any> {
    const endpoint = `${this.apiUrl}/set-chat-alias`;
    return this.http.put<any>(endpoint, setChatAliasRequest);
  }

  summaryChat(chatId: number): Observable<ChatSummaryInfo> {
    const endpoint = `${this.apiUrl}/resume-chat/${chatId}`;
    return this.http.post<ChatSummaryInfo>(endpoint, null);
  }

}