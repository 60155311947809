export enum SubscriptionType {

  Basic = 'basic',
  Advanced = 'advanced',

}

export const SubscriptionTypeMetadata = {
  [SubscriptionType.Basic]: {
    description: 'Básico'
  },
  [SubscriptionType.Advanced]: {
    description: 'Avanzado'
  },
};