<div
  id="chatList"
  [hidden]="filtering">
  <div class="d-none d-sm-block d-xl-none">
    <ul class="dropdown-menu dropdown-menu-end p-0">
      <li>
        <a
          class="dropdown-item"
          (click)="filterAllChats()">
          Todos ({{ totalChats }})
        </a>
      </li>
      <li>
        <a
          class="dropdown-item"
          (click)="filterNotSeenChats()">
          No leídos
        </a>
      </li>
      <li>
        <a
          class="dropdown-item"
          (click)="filterUnanswered()">
          Sin atender
        </a>
      </li>
    </ul>
  </div>
  <div class="d-flex align-items-center mb-2">
    <div class="form-icon-container d-xl-block w-100">
      <input
        class="form-control form-icon-input"
        type="text"
        [formControl]="chatSearchTermFormControl"
        placeholder="Buscar chat" />
      <span class="fas fa-user text-900 fs--1 form-icon"></span>
    </div>
    <button
      class="btn btn-outline-primary square-btn ms-2"
      (click)="filterChats()">
      <i class="fas fa-rotate-right"></i>
    </button>
    <button
      class="btn btn-outline-primary square-btn ms-2"
      (click)="showOpenNewMessage()">
      <i class="fas fa-plus"></i>
    </button>
    <button
      class="btn btn-outline-primary square-btn ms-2"
      (click)="sortChats()">
      <div *ngIf="chatSearchRequest.sortDirection === 'DESC'">
        <i class="fas fa-arrow-down-wide-short"></i>
      </div>
      <div *ngIf="chatSearchRequest.sortDirection === 'ASC'">
        <i class="fas fa-arrow-up-wide-short"></i>
      </div>
    </button>
    <button
      class="btn btn-outline-primary square-btn ms-2"
      (click)="toggleFilter()">
      <i class="fas fa-filter"></i>
    </button>
  </div>
  <ul
    class="nav nav-phoenix-pills mb-3 d-xl-flex"
    id="contactListTab"
    role="tablist">
    <li
      class="nav-item">
      <a
        (click)="filterAllChats()"
        class="nav-link cursor-pointer active"
        data-bs-toggle="tab"
        role="tab">
        Todos
        <span *ngIf="selectedTab === ChatListTab.All">({{ totalChats }})</span>
      </a>
    </li>
    <li
      class="nav-item">
      <a
        (click)="filterNotSeenChats()"
        class="nav-link cursor-pointer"
        data-bs-toggle="tab"
        role="tab">
        No leídos
        <span *ngIf="selectedTab === ChatListTab.NotSeen">({{ totalChats }})</span>
      </a>
    </li>
    <li
      class="nav-item">
      <a
        (click)="filterUnanswered()"
        role="tab"
        class="nav-link cursor-pointer"
        data-bs-toggle="tab">
        Sin atender
        <span *ngIf="selectedTab === ChatListTab.Unanswered">({{ totalChats }})</span>
      </a>
    </li>
  </ul>
  <div *ngIf="chats.length === 0">
    <h4 class="text-black mb-3" *ngIf="shouldShowEmptyChats()">
      Aún no hay chats.
    </h4>
    <div class="card border border-primary mb-2" *ngIf="shouldShowConfig()">
      <div class="card-body">
        <h4 class="card-title lh-sm">
          <i class="fa fa-smile text-success"></i>
          Bienvenido a <span class="text-success">IVANA</span>
        </h4>
        <p class="card-text mb-2">
          Para comenzar, configura tu <b>WhatsApp</b> o <b>Messenger</b> dando clic en el botón de abajo.
        </p>
        <a
          class="btn btn-phoenix-success float-end configure-whatsapp-btn"
          [routerLink]="['/app/config/integrations']">
          <i class="fab fa-whatsapp"></i>
          Configurar
        </a>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-start px-2 pt-2" *ngIf="loadingChats">
      <div class="spinner-border text-primary" style="font-size: 1rem"></div>
      <h4 class="text-600 ms-2 pt-1">
        Cargando chats...
      </h4>
    </div>
  </div>
  <cdk-virtual-scroll-viewport
    itemSize="50" class="chat-thread-tab scrollbar"
    (scrolledIndexChange)="onScrolled()" *ngIf="chats.length > 0">
    <ul class="nav">
      <li *cdkVirtualFor="let chat of chats"
          class="nav-item w-100"
          role="presentation"
          (click)="selectChat(chat)">
        <app-chat-list-item [chat]="chat"></app-chat-list-item>
      </li>
    </ul>
  </cdk-virtual-scroll-viewport>
</div>
<div
  id="chatFilter"
  [hidden]="!filtering">
  <div class="d-flex align-items-center mb-3">
    <h4 class="w-100 mb-0">
      Filtrar chats
    </h4>
    <button
      class="btn btn-outline-secondary square-btn ms-2"
      (click)="toggleFilter()">
      <i class="fas fa-arrow-left"></i>
    </button>
  </div>
  <div class="form-floating mb-2">
    <input
      id="floatingInput"
      type="text"
      class="form-control"
      [formControl]="tagsFormControl"
      placeholder="Buscar etiquetas"
      [editable]="false"
      #instance="ngbTypeahead"
      [ngbTypeahead]="searchChatTags"
      [resultFormatter]="formatResult"
      [inputFormatter]="formatInput"
      (focus)="focus$.next($any($event).target.value)"
      (click)="click$.next($any($event).target.value)">
    <label for="floatingInput">
      {{ chatTagsAvailable.length > 0 ? 'Buscar etiquetas' : 'No hay más etiquetas' }}
    </label>
  </div>
  <div class="mb-3">
    <span
      *ngFor="let chatTag of chatTagsChosen"
      (click)="removeChatTag(chatTag)"
      role="button"
      class="badge badge-phoenix fs--2 me-1"
      [ngStyle]="{
        'color': chatTag.color,
        'border': '1px solid ' + chatTag.color,
      }">
      {{ chatTag.name }}
      <i class="fas fa-xmark tag-xmark-icon badge-icon"></i>
    </span>
    <!-- Tipo de selección de chats por etiquetas -->
  </div>
  <div class="row mb-3" *ngIf="chatTagsChosen?.length">
    <div class="col-12">
      <label class="form-label ps-0 text-primary">
        Tipo de filtro de chats por etiquetas
        <span [ngbPopover]="chatTagSearchTypeInfoPopoverContent"
              triggers="mouseenter:mouseleave"
              popoverTitle="Información">
          <i class="fa fa-info-circle ms-1"></i>
        </span>
      </label>
      <div class="btn-group" role="group">
        <button class="btn btn-secondary" type="button"
                (click)="selectSearchType(ChatTagSearchType.AtLeastOne)">
          Al menos una
        </button>
        <button class="btn btn-secondary" type="button"
                (click)="selectSearchType(ChatTagSearchType.IncludeAll)">
          Todas
        </button>
        <button class="btn btn-secondary" type="button"
                (click)="selectSearchType(ChatTagSearchType.IncludeAllStrict)">
          Sólo seleccionadas
        </button>
      </div>
    </div>
  </div>
<!--  <app-advisors-input></app-advisors-input>-->
  <div class="form-floating mb-2" [hidden]="isAdvisor">
    <input
      id="floatingInputAdvisors"
      type="text"
      class="form-control"
      [formControl]="advisorsFormControl"
      placeholder="Buscar asesores"
      [editable]="false"
      #advisors="ngbTypeahead"
      [ngbTypeahead]="searchChatAdvisors"
      [resultFormatter]="formatResultAdvisor"
      [inputFormatter]="formatInputAdvisor"
      (focus)="focusAdvisor$.next($any($event).target.value)"
      (click)="clickAdvisor$.next($any($event).target.value)">
    <label for="floatingInputAdvisors">
      {{ chatAdvisorsAvailable.length > 0 ? 'Buscar asesores' : 'No hay más asesores' }}
    </label>
  </div>
  <div class="mb-3">
    <span
      *ngFor="let chatAdvisor of chatAdvisorsChosen"
      (click)="removeChatAdvisor(chatAdvisor)"
      role="button"
      class="badge badge-phoenix text-primary fs--2 me-1 border border-primary">
      {{ chatAdvisor.name }}
      <i class="fas fa-xmark tag-xmark-icon badge-icon"></i>
    </span>
  </div>
  <div class="mb-3">
    <div class="d-flex justify-content-between">
      <div class="w-100 me-2">
        <input
          type="text"
          class="form-control"
          placeholder="Filtrar por rango de fechas"
          [formatDate]="formatDate"
          (flatpickrClose)="filterByDate($event)"
          mwlFlatpickr
          [(ngModel)]="rangeValue"
          [altInput]="true"
          [convertModelValue]="true"
          [maxDate]="currentDate"
          mode="range">
      </div>
      <button
        class="btn btn-secondary dropdown-toggle square-btn-md me-2"
        data-bs-toggle="dropdown">
        <i class="fa fa-gear"></i>
      </button>
      <div class="dropdown-menu">
        <a class="dropdown-item"
           (click)="changeDateRangeFieldType(ChatSearchDangeRangeFieldType.CreationDate)"
           [class.active]="chatSearchRequest.dateRangeFieldType === ChatSearchDangeRangeFieldType.CreationDate"
           role="button">
          <i class="fa fa-calendar me-2"></i>
          Usar fecha de inicio de chat
        </a>
        <a class="dropdown-item"
           (click)="changeDateRangeFieldType(ChatSearchDangeRangeFieldType.LastMessageDate)"
           [class.active]="chatSearchRequest.dateRangeFieldType === ChatSearchDangeRangeFieldType.LastMessageDate"
           role="button">
          <i class="fa fa-clock me-2"></i>
          Usar fecha de último mensaje
        </a>
      </div>
      <button class="btn btn-secondary square-btn-md" (click)="clearDateRange()">
        <i class="fa fa-broom"></i>
      </button>
    </div>
  </div>
  <div class="mb-3">
    <div class="list-group">
      <li
        class="list-group-item d-flex justify-content-between align-items-center cursor-pointer"
        (click)="toggleNotSeen()">
        <div class="d-flex align-items-center justify-content-center">
          <i class="fas fa-eye-slash pe-2 search-attribute-icon text-danger"></i>
          No leídos
        </div>
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            [(ngModel)]="chatSearchRequest.notSeen"
            [checked]="chatSearchRequest.notSeen">
        </div>
      </li>
      <li
        class="list-group-item d-flex justify-content-between align-items-center cursor-pointer"
        (click)="toggleUnanswered()">
        <div class="d-flex align-items-center">
          <i class="fas fa-message-slash pe-2 search-attribute-icon text-warning"></i>
          No respondidos
        </div>
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            [(ngModel)]="chatSearchRequest.unanswered"
            [checked]="chatSearchRequest.unanswered">
        </div>
      </li>
      <li
        class="list-group-item d-flex justify-content-between align-items-center cursor-pointer"
        (click)="toggleArchived()">
        <div class="d-flex align-items-center">
          <i class="fas fa-box-archive pe-2 search-attribute-icon text-primary"></i>
          Archivados
        </div>
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            [(ngModel)]="chatSearchRequest.archived"
            [checked]="chatSearchRequest.archived">
        </div>
      </li>
    </div>
  </div>
  <div class="mb-3">
    <div class="d-grid gap-2">
      <button
        class="btn btn-outline-primary"
        (click)="filterChats()">
        Filtrar
      </button>
      <button
        class="btn btn-outline-danger"
        (click)="clearChatFilter()">
        Limpiar
      </button>
    </div>
  </div>
</div>

<ng-template #chatTagSearchTypeInfoPopoverContent>
  <p class="mb-1">
    <b class="text-primary">Al menos una:</b> Selecciona los chats que tengan al menos una de las etiquetas
    seleccionadas. <br><br>
    <b class="text-primary">Todas:</b> Selecciona los chats que tengan todas las etiquetas seleccionadas. <br><br>
    <b class="text-primary">Sólo seleccionadas:</b> Selecciona los chats que tengan exactamente las etiquetas
    seleccionadas.
  </p>
</ng-template>
