// Angular
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

// NgBootstrap
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

// Toastr
import { ToastrService } from 'ngx-toastr';

// RxJS
import {
  debounceTime,
  distinctUntilChanged, filter, map, merge, Observable,
  of, OperatorFunction, Subject,
  Subscription,
  switchMap,
} from 'rxjs';

// Chat Models
import { ChatInfo } from '@app/models/chat/chat/chat-info.dto';

import { Page } from '@app/models/common/page';
import { UserInfo } from '@app/models/account/user/user.info.dto';

// Services
import { AuthService } from '@app/services/auth/auth.service';
import { ChatSearchRequest } from '@app/models/chat/chat/chat-search-request.dto';
import { ChatService } from '@services/chat/chat.service';
import { ChannelType } from '@type/communication/channel.type';
import { UserService } from '@services/account/user.service';
import { UserSearchRequest } from '@app/models/account/user/user-search-request.dto';
import { AssignChatAdvisorRequest } from '@app/models/chat/chat/assign-chat-advisor-request.dto';
import { ArchiveChatRequest } from '@app/models/chat/chat/archive-chat-request.dto';
import { ChatTagInfo } from '@app/models/chat/tag/chat-tag-info.dto';
import { ChatTagSearchRequest } from '@app/models/chat/tag/chat-tag-search-request.dto';
import { ChatTagService } from '@services/chat/chat-tag.service';
import { AddChatTagRequest } from '@app/models/chat/chat/add-chat-tag-request.dto';
import { SaveChatRequest } from '@app/models/chat/chat/save-chat-request.dto';
import { HttpErrorResponse } from '@angular/common/http';

import 'moment/locale/es';
import { dateFormatter } from '@app/utils/date-utils';
import { ChatSearchDangeRangeFieldType } from '@type/chat/chat-search-dange-range-field.type';
import { EventEmitterService, NotificationTopic } from '@services/data/event-emitter.service';
import { SpinnerService } from '@services/data/spinner.service';

export enum ChatTabType {
  All = 'all-chats',
  Archived = 'archived-chats',
}

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css'],
})
export class ContactsComponent implements OnInit, AfterViewInit, OnDestroy {

  public readonly ChatSearchDangeRangeFieldType = ChatSearchDangeRangeFieldType;

  public currentDate: Date = new Date();
  public rangeValue: any = {};
  public formatDate = dateFormatter;

  public currentTab: ChatTabType = ChatTabType.All;
  public ChatTabType = ChatTabType;
  public ChannelType = ChannelType;

  public chatForm: FormGroup;
  public advisorForm: FormGroup;

  private readonly user: UserInfo;
  private componentSubscriptions: Subscription[] = [];

  public selectedChat: ChatInfo;

  public firstLoad: boolean = true;
  public loadingChats: boolean = false;

  public chats: ChatInfo[] = [];
  public lastPage: Page<ChatInfo>;
  public allChatsCount: number;
  public archivedChatsCount: number;

  public chatSearchRequest: ChatSearchRequest;
  public searchChatsFormControl: FormControl = new FormControl('');

  // DataTables
  private defaultPageSize: number = 20;

  // Local data
  public users: UserInfo[] = [];
  public selectedChats: ChatInfo[] = [];
  public allChatsChecked: boolean = false;

  // Chat Tags
  @ViewChild('instance', { static: true }) instance: any;
  public focus$ = new Subject<string>();
  public click$ = new Subject<string>();
  public formatResult = (value: ChatTagInfo) => value.name;
  public formatInput = (value: ChatTagInfo) => value.name;
  public tagsFormControl: FormControl;
  public chatTagsAll: ChatTagInfo[] = [];
  public chatTagsAvailable: ChatTagInfo[] = [];
  public chatTagsChosen: ChatTagInfo[] = [];

  // OffCanvas
  @ViewChild('saveChatOffCanvas')
  public saveChatOffCanvas: TemplateRef<any>;

  @ViewChild('assignChatAdvisorOffCanvas')
  public assignChatAdvisorOffCanvas: TemplateRef<any>;

  @ViewChild('tagChatsOffCanvas')
  public tagChatsOffCanvas: TemplateRef<any>;

  @ViewChild('importContactsOffCanvas')
  public importContactsOffCanvas: TemplateRef<any>;

  // Modals
  @ViewChild('chatModal')
  private chatModal: TemplateRef<any>;

  constructor(
    private toastr: ToastrService,
    private userService: UserService,
    private chatTagService: ChatTagService,
    private chatService: ChatService,
    private authService: AuthService,
    private offCanvasService: NgbOffcanvas,
    private modalService: NgbModal,
    private spinnerService: SpinnerService,
    private eventEmitterService: EventEmitterService,
  ) {
    this.user = this.authService.getUser();
  }

  ngOnInit() {
    this.loadUsers();
    this.loadChatTags();
    this.initializeChatSearchRequest();
    this.loadChats();
    this.initializeChatForm();
    this.initializeAdvisorForm();
    this.initializeSearchChatsFormControl();
    this.initializeImportContactsCompletedEvent();
  }

  ngAfterViewInit() {
    try {
      const windowAny = (window as any);
      const tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
      if (tooltips)
        new windowAny.bootstrap.Tooltip(tooltips);
    } catch (err) {

    }
  }

  ngOnDestroy() {
    this.componentSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  private initializeImportContactsCompletedEvent() {

    const subscription = this.eventEmitterService.getEventEmitter(NotificationTopic.ImportContactsCompleted).subscribe({
      next: () => {
        this.loadChats();
      },
    });

    this.componentSubscriptions.push(subscription);
  }

  private getArchivedChatsCount() {
    const searchRequest = new ChatSearchRequest(this.user.shop.id, 1, this.user);
    searchRequest.archived = true;
    this.getChatsCount(searchRequest, (page: Page<ChatInfo>) => {
      this.archivedChatsCount = page.totalElements;
    });
  }

  private getAllChatsCount() {
    const searchRequest = new ChatSearchRequest(this.user.shop.id, 1, this.user);
    this.getChatsCount(searchRequest, (page: Page<ChatInfo>) => {
      this.allChatsCount = page.totalElements;
    });
  }

  private getChatsCount(searchRequest: ChatSearchRequest, callback?: (page: Page<ChatInfo>) => void) {

    this.chatService.searchChats(searchRequest).subscribe({
      next: (page: Page<ChatInfo>) => {
        callback(page);
      },
      error: (error: any) => {
        console.error('Error en la carga de chats:', error);
      },
      complete: () => {
      },
    });

  }

  private syncChatsCount(page: Page<ChatInfo>) {
    if (this.currentTab === ChatTabType.All) {
      this.allChatsCount = page.totalElements;
      this.getArchivedChatsCount();
    } else if (this.currentTab === ChatTabType.Archived) {
      this.archivedChatsCount = page.totalElements;
      this.getAllChatsCount();
    }
  }

  private loadUsers() {

    const userSearchRequest = new UserSearchRequest(
      this.user.shop.id, this.defaultPageSize,
    );

    this.userService.searchUsers(userSearchRequest).subscribe({
      next: (page: Page<UserInfo>) => {
        this.users = page.content;
      },
      error: (error: any) => {
        console.error('Error en la carga de chats:', error);
      },
      complete: () => {
      },
    });
  }

  private loadChatTags(callback?: () => void) {

    const infiniteSize: number = -1;

    const searchRequest = new ChatTagSearchRequest(
      this.user.shop.id,
      infiniteSize,
    );

    this.chatTagService.searchChatTags(searchRequest).subscribe({
      next: (chatTagsPage: Page<ChatTagInfo>) => {
        this.chatTagsAvailable = chatTagsPage.content;
        this.chatTagsAll = [...chatTagsPage.content];
        callback && callback();
      },
      error: (error: any) => {
        console.error('Error en la carga de tags:', error);
      },
      complete: () => {

      },
    });

    this.initializeChatTagsChosenEvent();
  }

  private resetChatTags() {
    this.chatTagsChosen = [];
    this.chatTagsAvailable = [...this.chatTagsAll];
  }

  private initializeChatTagsChosenEvent() {

    this.tagsFormControl = new FormControl('');

    this.tagsFormControl.valueChanges.subscribe({
      next: (selectedChatTag: ChatTagInfo) => {

        if (selectedChatTag) {

          this.chatTagsChosen.push(selectedChatTag);
          this.tagsFormControl.setValue(null);

          const chatTagChosenIndex =
            this.chatTagsAvailable.indexOf(selectedChatTag);

          this.chatTagsAvailable.splice(chatTagChosenIndex, 1);
          this.chatTagsAvailable.sort((ct1, ct2) => ct2.id - ct1.id);
        }
      },
    });
  }

  public removeChatTag(chatTag: ChatTagInfo) {

    const chatTagChosenIndex = this.chatTagsChosen.indexOf(chatTag);
    this.chatTagsChosen.splice(chatTagChosenIndex, 1);

    this.chatTagsAvailable.push(chatTag);
    this.chatTagsAvailable.sort((ct1, ct2) => ct2.id - ct1.id);
  }

  public searchChatTags: OperatorFunction<string, readonly ChatTagInfo[]> = (
    text$: Observable<string>,
  ) => {
    const debouncedText$ = text$.pipe(debounceTime(1), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance?.isPopupOpen()),
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term: string) => {
        if (term === '') return this.chatTagsAvailable;
        return this.chatTagsAvailable.filter((ct: ChatTagInfo) => ct.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10);
      }),
    );
  };

  private initializeChatForm() {
    this.selectedChat = undefined;
    this.chatForm = new FormGroup({
      // Mandatory fields
      chatName: new FormControl('', [Validators.required]),
      chatPhoneNumber: new FormControl('', [Validators.required]),
      // Extra fields
      contactExtraPhoneNumber: new FormControl(''),
      contactEmail: new FormControl(''),
      contactStreet: new FormControl(''),
      contactColony: new FormControl(''),
      contactCountry: new FormControl(''),
      contactState: new FormControl(''),
      contactCity: new FormControl(''),
      contactAddressReference: new FormControl(''),
    });
  }

  private initializeAdvisorForm() {
    this.advisorForm = new FormGroup({
      user: new FormControl(''),
    });
  }

  private initializeChatSearchRequest() {
    this.chatSearchRequest = new ChatSearchRequest(
      this.user.shop.id,
      this.defaultPageSize,
      this.user,
    );
  }

  private initializeSearchChatsFormControl() {
    this.searchChatsFormControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((query) => {
          return of(query.trim());
        }),
      )
      .subscribe((searchTerm: string) => {
        this.chatSearchRequest.searchTerm = searchTerm;
        this.loadChats();
      });
  }

  public changeTab(tab: ChatTabType) {
    this.currentTab = tab;
    this.resetChatsSelection();
    this.chatSearchRequest.archived = tab === ChatTabType.Archived;
    this.loadChats();
  }

  public toggleAllChatsSelection(event: any) {
    const checked = event.target.checked;
    this.allChatsChecked = checked;
    for (const chat of this.chats) {
      chat.checked = checked;
      if (chat.checked) {
        this.selectedChats.push(chat);
      } else {
        this.selectedChats.splice(this.selectedChats.indexOf(chat), 1);
      }
    }
  }

  public toggleChatSelection(event: any, chat: ChatInfo) {
    chat.checked = event.target.checked;
    if (chat.checked) {
      this.selectedChats.push(chat);
    } else {
      this.selectedChats.splice(this.selectedChats.indexOf(chat), 1);
    }
    this.allChatsChecked = this.selectedChats.length === this.chats.length;
  }

  private markAllChatsAsNotSelected() {
    for (const chat of this.chats) {
      chat.checked = false;
    }
  }

  private loadChats() {
    this.spinnerService.show('Cargando contactos...');
    this.loadingChats = true;
    this.chatSearchRequest.page = 0;
    this.chatService.searchChats(this.chatSearchRequest).subscribe({
      next: (page: Page<ChatInfo>) => {
        this.chats = page.content;
        this.lastPage = page;
        this.loadingChats = false;
        this.markAllChatsAsNotSelected();
        this.syncChatsCount(page);
        this.spinnerService.hide();
      },
      error: (error: any) => {
        console.error('Error en la carga de chats:', error);
      },
      complete: () => {
      },
    });
  }

  public filterByDate(value: any) {

    const { selectedDates } = value;
    const startDate = selectedDates[0];
    const endDate = selectedDates[1];

    if (!startDate || !endDate) return;

    this.chatSearchRequest.fromDate = startDate.getTime();
    this.chatSearchRequest.toDate = endDate.getTime();
    this.loadChats();
  }

  public clearDateRange() {
    this.rangeValue = {};
    this.chatSearchRequest.fromDate = null;
    this.chatSearchRequest.toDate = null;
    this.loadChats();
  }

  public changeDateRangeFieldType(dateRangeFieldType: ChatSearchDangeRangeFieldType) {
    this.chatSearchRequest.dateRangeFieldType = dateRangeFieldType;
    const { fromDate, toDate } = this.chatSearchRequest;
    if (fromDate && toDate) this.loadChats();
  }

  public openSaveChat(chat?: ChatInfo) {

    if (chat) {
      this.selectedChat = chat;
      this.chatForm.get('chatName').setValue(chat.chatName);
      this.chatForm.get('chatPhoneNumber').setValue(chat.chatPhoneNumber);
      this.chatForm.get('contactExtraPhoneNumber').setValue(chat.contactExtraPhoneNumber);
      this.chatForm.get('contactEmail').setValue(chat.contactEmail);
      this.chatForm.get('contactStreet').setValue(chat.contactStreet);
      this.chatForm.get('contactColony').setValue(chat.contactColony);
      this.chatForm.get('contactCountry').setValue(chat.contactCountry);
      this.chatForm.get('contactState').setValue(chat.contactState);
      this.chatForm.get('contactCity').setValue(chat.contactCity);
      this.chatForm.get('contactAddressReference').setValue(chat.contactAddressReference);

      this.chatForm.get('chatPhoneNumber').disable();
    }

    const offCanvasRef = this.offCanvasService.open(
      this.saveChatOffCanvas,
      {
        position: 'end',
      },
    );

    offCanvasRef.hidden.subscribe(() => {
      this.initializeChatForm();
    });
  }

  public openChat(chat: ChatInfo) {

    this.selectedChat = chat;

    const modalRef = this.modalService.open(this.chatModal, {
      // backdrop: 'static',
      size: 'xl',
      centered: true,
    });

    modalRef.hidden.subscribe(() => {

    });
  }

  public openAssignChatAdvisor() {

    if (this.selectedChats.length === 0) {
      alert('Debe seleccionar al menos un contacto');
      return;
    }

    if (this.selectedChats.length === 1) {
      const singleChat = this.selectedChats[0];
      this.advisorForm.get('user').setValue(singleChat.userId ? singleChat.userId : '');
    }

    const offCanvasRef = this.offCanvasService.open(
      this.assignChatAdvisorOffCanvas,
      {
        position: 'end',
      },
    );

    offCanvasRef.hidden.subscribe(() => {
      this.initializeAdvisorForm();
    });
  }

  public assignChatAdvisor() {

    const { user } = this.advisorForm.value;
    const userId: number = user ? Number(user) : null;
    const userName: string = user ? this.users.find(u => u.id === userId).name : null;
    const chatIds: number[] = this.selectedChats.map(c => c.id);
    let unassign: boolean = !userId;

    let beforeActionMessage: string = unassign ? 'desasignar' : 'asignar';
    let afterActionMessage: string = unassign ? 'desasignados' : 'asignados';

    if (chatIds.length === 1) {
      afterActionMessage = unassign ? 'desasignado' : 'asignado';
      if (unassign) {
        beforeActionMessage = `Está seguro que desea ${beforeActionMessage} el contacto seleccionado?`;
        afterActionMessage = `Contacto ${afterActionMessage} exitosamente`;
      } else {
        beforeActionMessage = `Está seguro que desea ${beforeActionMessage} el contacto seleccionado al asesor ${userName}?`;
        afterActionMessage = `Contacto ${afterActionMessage} exitosamente`;
      }
    } else {
      if (unassign) {
        beforeActionMessage = `Está seguro que desea ${beforeActionMessage} los contactos seleccionados?`;
      } else {
        beforeActionMessage = `Está seguro que desea ${beforeActionMessage} los contactos seleccionados al asesor ${userName}?`;
      }
      afterActionMessage = `Contactos ${afterActionMessage} exitosamente`;
    }

    if (unassign && !confirm(beforeActionMessage)) {
      return;
    } else if (!confirm(beforeActionMessage)) {
      return;
    }

    const assignChatAdvisorRequest = new AssignChatAdvisorRequest(
      chatIds, userId,
    );

    this.chatService.assignChatAdvisor(assignChatAdvisorRequest).subscribe({
      next: () => {
        this.resetChatsSelection();
        this.loadChats();
        this.offCanvasService.dismiss();
        this.toastr.success(afterActionMessage);
      },
      error: (error: any) => {
        console.error('Error al asignar el contacto:', error);
        this.toastr.error('Error al asignar el contacto');
      },
      complete: () => {
      },
    });
  }

  private resetChatsSelection() {
    this.selectedChats = [];
    if (this.allChatsChecked) this.allChatsChecked = false;
  }

  public archiveChats(archive: boolean) {

    const chatIds: number[] = this.selectedChats.map(c => c.id);

    if (chatIds.length === 0) {
      alert('Debe seleccionar al menos un contacto');
      return;
    } else {
      const appliesForAction: boolean = this.selectedChats.filter(c => c.archived !== archive).length > 0;
      if (!appliesForAction) {
        alert('Los contactos seleccionados ya se encuentran ' + (archive ? 'archivados' : 'desarchivados'));
        return;
      }
    }

    let beforeActionMessage: string = archive ? 'archivar' : 'desarchivar';
    let afterActionMessage: string = archive ? 'archivado' : 'desarchivados';
    if (chatIds.length === 1) {
      beforeActionMessage = `Está seguro que desea ${beforeActionMessage} el contacto seleccionado?`;
      afterActionMessage = `Contacto ${afterActionMessage} exitosamente`;
    } else {
      beforeActionMessage = `Está seguro que desea ${beforeActionMessage} los contactos seleccionados?`;
      afterActionMessage = `Contactos ${afterActionMessage} exitosamente`;
    }
    if (!confirm(beforeActionMessage)) return;

    const archiveChatRequest: ArchiveChatRequest = new ArchiveChatRequest(
      chatIds, archive,
    );

    this.chatService.archiveChat(archiveChatRequest).subscribe({
      next: () => {
        this.resetChatsSelection();
        this.loadChats();
        this.offCanvasService.dismiss();
        this.toastr.success(afterActionMessage);
      },
      error: (error: any) => {
        console.error('Error al archivar el contacto:', error);
        this.toastr.error('Error al archivar el contacto');
      },
      complete: () => {
      },
    });

  }

  public openTagChats() {

    if (this.selectedChats.length === 0) {
      alert('Debe seleccionar al menos un contacto');
      return;
    }

    const offCanvasRef = this.offCanvasService.open(
      this.tagChatsOffCanvas,
      {
        position: 'end',
      },
    );

    offCanvasRef.hidden.subscribe(() => {
      //this.initializeAdvisorForm();
      this.resetChatTags();
    });
  }

  public assignChatTags() {

    const chatTagIds = this.chatTagsChosen.map(ct => ct.id);
    const chatIds = this.selectedChats.map(c => c.id);

    let beforeActionMessage: string = 'etiquetar';
    let afterActionMessage: string = 'etiquetados';
    if (chatIds.length === 1) {
      beforeActionMessage = `Está seguro que desea ${beforeActionMessage} el contacto seleccionado?`;
      afterActionMessage = `Contacto ${afterActionMessage} exitosamente`;
    } else {
      beforeActionMessage = `Está seguro que desea ${beforeActionMessage} los contactos seleccionados?`;
      afterActionMessage = `Contactos ${afterActionMessage} exitosamente`;
    }
    if (!confirm(beforeActionMessage)) return;

    const addChatTagRequest = new AddChatTagRequest(
      chatIds, chatTagIds,
    );

    this.chatService.addChatTag(addChatTagRequest).subscribe({
      next: () => {
        this.resetChatsSelection();
        this.loadChats();
        this.offCanvasService.dismiss();
        this.toastr.success(afterActionMessage);
      },
      error: (error: any) => {
        console.error('Error al asignar etiquetas:', error);
        this.toastr.error('Error al asignar etiquetas');
      },
      complete: () => {
      },
    });

  }

  public saveChat() {

    const {
      chatName,
      chatPhoneNumber,
      contactExtraPhoneNumber,
      contactEmail,
      contactStreet,
      contactColony,
      contactCountry,
      contactState,
      contactCity,
      contactAddressReference,
    } = this.chatForm.value;

    const saveChatRequest = new SaveChatRequest();
    saveChatRequest.chatName = chatName;
    saveChatRequest.contactExtraPhoneNumber = contactExtraPhoneNumber;
    saveChatRequest.contactEmail = contactEmail;
    saveChatRequest.contactStreet = contactStreet;
    saveChatRequest.contactColony = contactColony;
    saveChatRequest.contactCountry = contactCountry;
    saveChatRequest.contactState = contactState;
    saveChatRequest.contactCity = contactCity;
    saveChatRequest.contactAddressReference = contactAddressReference;

    if (this.selectedChat) {
      saveChatRequest.id = this.selectedChat.id;
    } else {
      saveChatRequest.shopId = this.user.shop.id;
      saveChatRequest.chatPhoneNumber = chatPhoneNumber;
    }

    this.chatService.saveChat(saveChatRequest).subscribe({
      next: () => {
        this.loadChats();
        this.offCanvasService.dismiss();
        this.toastr.success('Contacto guardado exitosamente');
      },
      error: (httpErrorResponse: HttpErrorResponse) => {
        this.toastr.error('Error al guardar el contacto');
        const { error } = httpErrorResponse;
        console.error('Error al guardar el contacto:', error);
      },
      complete: () => {
      },
    });
  }

  public openImportContacts() {

    this.offCanvasService.open(
      this.importContactsOffCanvas,
      {
        backdrop: 'static',
        position: 'end',
      },
    );

  }

  public onScroll() {

    if (!this.lastPage.last) {

      this.loadingChats = true;
      this.chatSearchRequest.page++;

      this.chatService.searchChats(this.chatSearchRequest).subscribe({
        next: (page: Page<ChatInfo>) => {
          this.loadingChats = false;
          this.chats = this.chats.concat(page.content);
          this.lastPage = page;
        },
        error: (error: any) => {
          console.error('Error en la carga de chats:', error);
        },
        complete: () => {
        },
      });
    }
  }

}
