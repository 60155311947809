import {
  SmartAssistantTopicItemActionType
} from '@type/utilities/smart-assistant/smart-assistant-topic-item-action.type';

export class SaveSmartAssistantTopicItemActionRequest {

  id: number;
  removed: boolean;
  actionPosition: number;
  message: string;
  httpCallUrl: string;
  httpCallInstructions: string;
  type: SmartAssistantTopicItemActionType;
  quickReplyId: number;
  chatTagIds: number[];

  constructor(type: SmartAssistantTopicItemActionType) {
    this.type = type;
  }

}
