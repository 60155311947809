
export class SaveSmartAssistantRequest {

  id: number;
  name: string;
  instructions: string;
  waitTimeInterval: number;
  shopId: number;

  constructor(id: number, name: string, instructions: string, waitTimeInterval: number, shopId: number) {
    this.id = id;
    this.name = name;
    this.instructions = instructions;
    this.waitTimeInterval = waitTimeInterval;
    this.shopId = shopId;
  }

}