<div class="card shadow-none border border-300 smart-assistants-list">
  <div class="card-header px-4 py-3 border-bottom border-300 bg-soft">
    <div class="row g-3 justify-content-between align-items-center">
      <div class="col-12 col-md d-flex justify-content-between align-items-center">
        <h4 class="text-900 text-truncate mb-0 pe-2">
          <i class="fa fa-bolt text-warning"></i>
          <ng-container *ngIf="smartAssistantForm.get('name').value">
            {{ smartAssistantForm.get('name').value }}
          </ng-container>
          <ng-container *ngIf="!smartAssistantForm.get('name').value && !smartAssistant?.id">
            <span class="text-warning">
              Nuevo asistente
            </span>
          </ng-container>
        </h4>
        <button type="button" class="btn btn-sm btn-phoenix-primary" (click)="showSmartAssistantList()">
          <i class="fa fa-arrow-left"></i>
        </button>
      </div>
    </div>
    <div class="row">
      <p class="text-700 lh-sm mt-2 mb-0">
        Elige el nombre y las instrucciones del asistente.
      </p>
    </div>
  </div>
  <div class="card-body smart-assistants-card-body-list scrollbar p-3">
    <form
      [formGroup]="smartAssistantForm"
      (submit)="saveSmartAssistant()">
      <div class="row mb-2">
        <h5 class="text-dark">
          Datos del asistente
        </h5>
      </div>
      <!-- Nombre del asistente -->
      <div class="row mb-3">
        <div class="col-12">
          <div class="form-floating">
            <input
              class="form-control"
              id="createSmartAssistantName"
              type="text"
              placeholder="Nombre del asistente"
              formControlName="name"
              [ngClass]="{
              'is-invalid': smartAssistantForm.get('name').touched && smartAssistantForm.get('name').invalid,
              'is-valid': smartAssistantForm.get('name').touched && smartAssistantForm.get('name').valid
            }">
            <label for="createSmartAssistantName">
              Nombre del asistente
            </label>
            <div
              *ngIf="smartAssistantForm.get('name').touched && smartAssistantForm.get('name').invalid"
              class="invalid-feedback text-warning">
              Ingrese un nombre para el asistente, por favor.
            </div>
          </div>
        </div>
      </div>
      <!-- Instrucciones del asistente -->
      <div class="row mb-3">
        <div class="col-12">
          <div class="position-relative">
            <label class="text-dark" for="createSmartAssistantInstructions">
              Instrucciones del asistente
            </label>
            <textarea
              class="form-control scrollbar"
              rows="8"
              id="createSmartAssistantInstructions"
              placeholder="Instrucciones del asistente"
              formControlName="instructions"
              [ngClass]="{
              'is-invalid': smartAssistantForm.get('instructions').touched && smartAssistantForm.get('instructions').invalid,
              'is-valid': smartAssistantForm.get('instructions').touched && smartAssistantForm.get('instructions').valid
            }"></textarea>
            <button type="button" (click)="showSmartAssistantInstructions()" tabindex="0" class="btn btn-sm btn-minimal btn-neutral expand-textarea-btn">
            <span class="btn-label-wrap">
              <span class="btn-label-inner">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 24 24" size="20">
                  <path fill-rule="evenodd" d="M12 7a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8h-3a1 1 0 0 1-1-1Zm-5 5a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1Z" clip-rule="evenodd">
                  </path>
                </svg>
              </span>
            </span>
            </button>
          </div>
          <div
            *ngIf="smartAssistantForm.get('instructions').touched && smartAssistantForm.get('instructions').invalid"
            class="invalid-feedback text-warning">
            Ingrese las instrucciones para el asistente, por favor.
          </div>
        </div>
      </div>
      <!-- Tiempo de espera del asistente entre mensajes -->
      <div class="row mb-3">
        <div class="col-12">
          <label class="text-dark" for="createSmartAssistantWaitTimeInterval">
            Tiempo de espera entre mensajes
          </label>
          <input
            class="form-control input-only-numbers"
            id="createSmartAssistantWaitTimeInterval"
            type="number"
            placeholder="Tiempo de espera entre mensajes"
            formControlName="waitTimeInterval"
            min="0"
            max="180"
            [ngClass]="{
              'is-invalid': smartAssistantForm.get('waitTimeInterval').invalid,
              'is-valid': smartAssistantForm.get('waitTimeInterval').valid
            }">
          <div
            *ngIf="smartAssistantForm.get('instructions').touched && smartAssistantForm.get('instructions').invalid"
            class="invalid-feedback text-warning">
            Ingrese las instrucciones para el asistente, por favor.
          </div>
        </div>
      </div>
      <!-- Botones -->
      <div class="row mb-3 g-2 justify-content-end">

        <div class="col-auto" *ngIf="smartAssistant?.id !== undefined">
          <button
            type="button"
            class="btn btn-sm btn-phoenix-danger"
            (click)="deleteSmartAssistant()">
            <i class="fa fa-trash me-1"></i>
            Eliminar
          </button>
        </div>
        <div class="col-auto">
          <button
            class="btn btn-sm btn-phoenix-primary"
            [disabled]="smartAssistantForm.invalid">
            <i class="fa fa-save me-1"></i>
            Guardar
          </button>
        </div>
      </div>
      <!-- Sincronizar -->
      <div class="row g-2 justify-content-end" *ngIf="false">
        <div class="col-auto">
          <button
            class="btn btn-sm btn-phoenix-warning"
            [disabled]="smartAssistantForm.invalid">
            <i class="fa fa-bolt me-1"></i>
            Sincronizar
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<ng-template #showSmartAssistantInstructionsModal let-modal>
  <div class="modal-body scrollbar p-3">
    <form
      [formGroup]="smartAssistantForm">
      <textarea
        class="form-control scrollbar"
        rows="30"
        placeholder="Instrucciones del asistente"
        formControlName="instructions"
        [ngClass]="{
          'is-invalid': smartAssistantForm.get('instructions').touched && smartAssistantForm.get('instructions').invalid,
        }"></textarea>
    </form>
  </div>
</ng-template>
