// Angular
import {
  AfterViewInit,
  Component, Input,
  OnDestroy,
  OnInit, Output
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

// RxJS
import { Subject, Subscription } from "rxjs";
import {
  WhatsAppMessageTemplateInfo
} from "../../../../../models/chat/whatsapp-message-template/whatsapp-message-template-info.dto";

@Component({
  selector: "app-message-template-footer",
  templateUrl: "./message-template-footer.component.html",
  styleUrls: ["./message-template-footer.component.css"],
})
export class MessageTemplateFooterComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input()
  public whatsAppMessageTemplate: WhatsAppMessageTemplateInfo;

  @Output()
  public onFooterTextChange: Subject<string> = new Subject<string>();

  // Component Data
  private componentSubscriptions: Subscription[] = [];
  // Component Data

  // Forms
  public messageTemplateFooterForm: FormGroup;
  // Forms

  constructor() {

  }

  ngOnInit() {
    this.initializeForm();
    this.initializeFooter();
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.componentSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  private initializeForm() {

    this.messageTemplateFooterForm = new FormGroup({
      footerText: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(1024),
      ]),
    });

    this.messageTemplateFooterForm.get('footerText').valueChanges
      .subscribe( {
        next: (value: string) => {
          this.onFooterTextChange.next(value);
        }
      });

  }

  private initializeFooter() {
    if (this.whatsAppMessageTemplate.footerComponent) {
      this.messageTemplateFooterForm.get("footerText").setValue(this.whatsAppMessageTemplate.footerComponent.text);
    }
  }

}
