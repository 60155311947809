
export class SaveWhatsAppConfigRequest {

  constructor(
    public shopId: number,
    public phoneNumber: string,
    public phoneNumberId: string,
    public accessToken: string,
    public wabaId: string,
    public appId: string,
    public id?: number,
  ) {

    this.shopId = shopId;
    this.phoneNumber = phoneNumber;
    this.phoneNumberId = phoneNumberId;
    this.accessToken = accessToken;
    this.wabaId = wabaId;
    this.appId = appId;

    if (id !== undefined) {
      this.id = id;
    }
  }

}
