import {
  SaveSmartAssistantTopicItemActionRequest
} from "@models/utilities/smart-assistant/topic-item-action/save-smart-assistant-topic-item-action-request.dto";

export class SaveSmartAssistantTopicItemRequest {

  id: number;
  name: string;
  content: string;
  context: string;
  sendActions: boolean;
  actions: SaveSmartAssistantTopicItemActionRequest[] = [];
  smartAssistantTopicId: number;

  constructor(
    id: number,
    name: string,
    smartAssistantTopicId: number,
  ) {
    this.id = id;
    this.name = name;
    this.smartAssistantTopicId = smartAssistantTopicId;
  }

}