import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Page } from "@models/common/page";
import { SmartAssistantSearchRequest } from "@models/utilities/smart-assistant/smart-assistant-search-request.dto";
import { SmartAssistantInfo } from "@models/utilities/smart-assistant/smart-assistant-info.dto";
import { SaveSmartAssistantRequest } from "@models/utilities/smart-assistant/save-smart-assistant-request.dto";
import { SmartAssistantTopicInfo } from "@models/utilities/smart-assistant/topic/smart-assistant-topic-info.dto";
import { SaveSmartAssistantTopicRequest } from "@models/utilities/smart-assistant/topic/save-smart-assistant-topic-request.dto";
import { SmartAssistantTopicSearchRequest } from "@models/utilities/smart-assistant/topic/smart-assistant-topic-search-request.dto";
import { SaveSmartAssistantTopicItemRequest } from "@models/utilities/smart-assistant/topic-item/save-smart-assistant-topic-item-request.dto";
import { SmartAssistantTopicItemInfo } from "@models/utilities/smart-assistant/topic-item/smart-assistant-topic-item-info.dto";
import { DeleteSmartAssistantTopicItemRequest } from "@models/utilities/smart-assistant/topic-item/delete-smart-assistant-topic-item-request.dto";
import { ChangeSmartAssistantStatusRequest } from "@models/utilities/smart-assistant/change-smart-assistant-status-request.dto";
import { UpdateSmartAssistantTopicItemsRequest } from "@models/utilities/smart-assistant/topic-item/update-smart-assistant-topic-items-request.dto";
import { SmartAssistantTopicItemActionInfo } from "@models/utilities/smart-assistant/topic-item-action/smart-assistant-topic-item-action-info.dto";
import { SaveSmartAssistantTopicItemActionRequest } from "@models/utilities/smart-assistant/topic-item-action/save-smart-assistant-topic-item-action-request.dto";

@Injectable({
  providedIn: 'root'
})
export class SmartAssistantService {

  private apiUrl: string = `${environment.apiUrl}/smart-assistants`;

  constructor(
    private http: HttpClient,
  ) {

  }

  searchSmartAssistants(smartAssistantSearch: SmartAssistantSearchRequest): Observable<Page<SmartAssistantInfo>> {
    const endpoint = `${this.apiUrl}/search-smart-assistants`;
    return this.http.post<Page<SmartAssistantInfo>>(endpoint, smartAssistantSearch);
  }

  saveSmartAssistant(saveSmartAssistantRequest: SaveSmartAssistantRequest): Observable<SmartAssistantInfo> {
    const endpoint = `${this.apiUrl}/save-smart-assistant`;
    return this.http.post<SmartAssistantInfo>(endpoint, saveSmartAssistantRequest);
  }

  deleteSmartAssistant(id: number): Observable<void> {
    const endpoint = `${this.apiUrl}/delete-smart-assistant/${id}`;
    return this.http.delete<void>(endpoint);
  }

  searchSmartAssistantTopics(smartAssistantTopicSearch: SmartAssistantTopicSearchRequest): Observable<Page<SmartAssistantTopicInfo>> {
    const endpoint = `${this.apiUrl}/search-smart-assistant-topics`;
    return this.http.post<Page<SmartAssistantTopicInfo>>(endpoint, smartAssistantTopicSearch);
  }

  saveSmartAssistantTopic(saveSmartAssistantTopicRequest: SaveSmartAssistantTopicRequest): Observable<SmartAssistantTopicInfo> {
    const endpoint = `${this.apiUrl}/save-smart-assistant-topic`;
    return this.http.post<SmartAssistantTopicInfo>(endpoint, saveSmartAssistantTopicRequest);
  }

  deleteSmartAssistantTopic(id: number): Observable<void> {
    const endpoint = `${this.apiUrl}/delete-smart-assistant-topic/${id}`;
    return this.http.delete<void>(endpoint);
  }

  saveSmartAssistantTopicItem(saveSmartAssistantTopicItemRequest: SaveSmartAssistantTopicItemRequest): Observable<SmartAssistantTopicItemInfo> {
    const endpoint = `${this.apiUrl}/save-smart-assistant-topic-item`;
    return this.http.post<SmartAssistantTopicItemInfo>(endpoint, saveSmartAssistantTopicItemRequest);
  }

  deleteSmartAssistantTopicItem(deleteSmartAssistantTopicItemRequest: DeleteSmartAssistantTopicItemRequest): Observable<void> {
    const endpoint = `${this.apiUrl}/delete-smart-assistant-topic-item`;
    return this.http.post<void>(endpoint, deleteSmartAssistantTopicItemRequest);
  }

  updateSmartAssistantTopicItems(updateSmartAssistantTopicItemsRequest: UpdateSmartAssistantTopicItemsRequest): Observable<void> {
    const endpoint = `${this.apiUrl}/update-smart-assistant-topic-items`;
    return this.http.post<void>(endpoint, updateSmartAssistantTopicItemsRequest);
  }

  saveSmartAssistantTopicItemAction(saveSmartAssistantTopicItemActionRequest: SaveSmartAssistantTopicItemActionRequest): Observable<SmartAssistantTopicItemActionInfo> {
    const endpoint = `${this.apiUrl}/save-smart-assistant-topic-item-action`;
    return this.http.post<SmartAssistantTopicItemActionInfo>(endpoint, saveSmartAssistantTopicItemActionRequest);
  }

  deleteSmartAssistantTopicItemAction(id: number): Observable<void> {
    const endpoint = `${this.apiUrl}/delete-smart-assistant-topic-item-action/${id}`;
    return this.http.delete<void>(endpoint);
  }

  changeSmartAssistantStatus(changeSmartAssistantStatusRequest: ChangeSmartAssistantStatusRequest): Observable<void> {
    const endpoint = `${this.apiUrl}/change-smart-assistant-status`;
    return this.http.post<void>(endpoint, changeSmartAssistantStatusRequest);
  }

}