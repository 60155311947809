<section>
  <div class="container"> 
    <div class="row py-3 text-center justify-content-center align-items-center ff-kanit">
      <h2 class="ivana-title">¿CÓMO FUNCIONA <strong class="text-light-purple ivana-title-strong">IVANA</strong>?</h2>
      <p class="lead">
        Como una herramienta inteligente que utiliza IA para comprender y responder a las 
        consultas de los clientes, automatizando procesos empresariales y mejorando la eficiencia en la 
        interacción con los clientes en tiempo real
      </p>
    </div>
    <div class="row g-0">
      <!-- Primera columna con imagen muy alta -->
      <div class="col-lg-6 col-sm-12 d-flex justify-content-center mb-4">
        <img src="../assets/img/landing-page/assistant_preview.png" alt="Imagen Muy Alta" class="mt-3" id="assistant_preview">
      </div>
      <!-- Segunda columna con lista de puntos e iconos y texto -->
      <div class="col-lg-6 col-sm-12">
        <ul class="list-unstyled ul-size">
          <li class="my-2 py-1">
            <div class="d-flex align-items-center">
              <i class="fa-solid fa-wand-magic-sparkles fa-2x mx-1 px-3 text-deep-blue"></i>
              <div class="content-i-align">
                <strong class="text-light-purple">Personalización:</strong> Utiliza información sobre el cliente, como su historial de interacciones y preferencias, para personalizar aún más la respuesta y brindar una experiencia más individualizada
              </div>
            </div>
          </li>
          <li class="my-3 py-2">
            <div class="d-flex align-items-center">
              <i class="fa-regular fa-comment-dots fa-2x mx-1 px-3 text-deep-blue"></i>
              <div class="content-i-align">
                <strong class="text-light-purple">Recepción de mensajes:</strong> Recibe mensajes de los clientes a través de diferentes canales, como WhatsApp, Social Media ADS, Broadcasts, Correos Electrónicos y más…
              </div>
            </div>
          </li>
          <li class="my-3 py-2">
            <div class="d-flex align-items-center">
              <i class="fa-solid fa-repeat fa-2x mx-1 px-3 text-deep-blue"></i>
              <div class="content-i-align">
                <strong class="text-light-purple">Procesamiento de mensajes: </strong>Utiliza algoritmos avanzados de IA, analizando el mensaje del cliente para comprender su intención y proporcionar una respuesta adecuada
              </div>
            </div>
          </li>
          <li class="my-3 py-2">
            <div class="d-flex align-items-center">
              <i class="fa-solid fa-shuffle fa-2x mx-1 px-3 text-deep-blue"></i>
              <div class="content-i-align">
                <strong class="text-light-purple">Generación de respuestas:</strong> Basándose en el mensaje del cliente y en la información disponible en su base de datos, se genera una respuesta personalizada y relevante
              </div>
            </div>
          </li>
          <li class="my-3 py-2">
            <div class="d-flex align-items-center">
              <i class="fa-solid fa-arrow-turn-down fa-2x mx-1 px-3 text-deep-blue"></i>
              <div class="content-i-align">
                <strong class="text-light-purple">Entrega de respuestas:</strong> Envia la respuesta al cliente de manera rápida y precisa, a través del mismo canal de comunicación
              </div>
            </div>
          </li>
          <li class="my-3 py-2">
            <div class="d-flex align-items-center">
              <i class="fa-solid fa-flask fa-2x mx-1 px-3 text-deep-blue"></i>
              <div class="content-i-align">
                <strong class="text-light-purple">Aprendizaje continuo:</strong> IVANA está diseñada para aprender y mejorar con el tiempo. Utiliza técnicas de aprendizaje automáticas para adaptarse a nuevos escenarios y mejorar su capacidad de respuesta.
              </div>
            </div>
          </li>
        </ul>
      </div>       
    </div>
  </div>  
</section>
