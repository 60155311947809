import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  merge,
  Observable,
  OperatorFunction,
  Subject,
  Subscription,
} from "rxjs";
import { getContryCodes } from "../../../utils/phone-utils";
import { FormControl } from "@angular/forms";
import { LocalDataService, DataKey } from "../../../services/data/local-data.service"; // Asegúrate de importar DataKey

@Component({
  selector: "app-phone-number-typeahead",
  templateUrl: "./phone-number-typeahead.component.html",
})
export class PhoneNumberTypeaheadComponent implements OnInit, OnDestroy {

  private componentSubscriptions: Subscription[] = [];

  @Input()
  public countryCodeChosen: any = undefined;

  @Input()
  public floating: boolean = true;

  @Output()
  public countryCodeChosenEvent: Subject<any> = new Subject<any>();

  // Phone Country Codes
  @ViewChild("instance", { static: true }) instance: any;
  public focus$ = new Subject<string>();
  public click$ = new Subject<string>();
  public formatResult = (value: any) => {
    return `+${value.code} ${value.country}`;
  };
  public formatInput = (value: any) => {
    if (value) {
      return `+${value.code} ${value.country}`;
    }
    return '';
  };
  public countryCodesFormControl: FormControl;
  public countryCodesAll: any[] = getContryCodes();
  public selectedCountryCode: any;

  constructor(private localDataService: LocalDataService) { }

  ngOnInit() {
    this.initializeCountryCodeChosenEvent();
  
    const savedCountryCode = this.localDataService.get(DataKey.LastCountryCodeSelected);
    if (savedCountryCode) {
      const countryCodeValue = this.countryCodesAll.find((cc) => cc.code === savedCountryCode);
      if (countryCodeValue) {
        this.countryCodesFormControl.setValue(countryCodeValue, { emitEvent: true }); 
        this.selectedCountryCode = countryCodeValue;
        this.countryCodeChosenEvent.next(countryCodeValue);
      }
    }
  }
  
  
  ngOnDestroy() {
    this.componentSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  initializeCountryCodeChosenEvent() {
    this.countryCodesFormControl = new FormControl("");

    this.countryCodesFormControl.valueChanges.subscribe({
      next: (selectedCountryCode: any) => {
        if (selectedCountryCode) {
          this.selectedCountryCode = selectedCountryCode;
          this.countryCodeChosenEvent.next(selectedCountryCode);

          this.localDataService.set(DataKey.LastCountryCodeSelected, selectedCountryCode.code); 
        }
      },
    });
  }

  /* Filter Country Code Section */
  searchCountryCodes: OperatorFunction<string, readonly any[]> = (
    text$: Observable<string>,
  ) => {
    const debouncedText$ = text$.pipe(debounceTime(1), distinctUntilChanged());
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) => {
        if (term === "") {
          return this.countryCodesAll.slice(0, 50);
        } else {
          const lowerTerm = term.toLowerCase();
          return this.countryCodesAll
            .filter(
              (cc) =>
                cc.country.toLowerCase().indexOf(lowerTerm) > -1 ||
                cc.code.toLowerCase().indexOf(lowerTerm) > -1 ||
                cc.iso.toLowerCase().indexOf(lowerTerm) > -1,
            )
            .slice(0, 50);
        }
      }),
    );
  };
}
