<div class="preloader-container"
     [class.hidden]="allLoaded"
     [class.d-none]="allLoadedAfter">
  <div class="preloader"></div>
</div>

<div class="main-container"
     [class.not-transparent]="allLoaded"
     [class.transparent]="!allLoaded">
  <app-landing-page-header-component></app-landing-page-header-component>
  <app-pricing-list-component [delay]="false"></app-pricing-list-component>
</div>
