
<div *ngIf="message" class="mb-1 p-3 rounded-2 position-relative" [ngClass]="{
  'received-message-content border': message.customerMessage,
  'sent-message-content bg-primary light text-white': !message.customerMessage,
}">
  <app-chat-context-message
    *ngIf="message.contextMessage"
    [message]="message.contextMessage"
    [chat]="chat">
  </app-chat-context-message>
  <p class="fw-bold mb-1">{{ contacts.length === 1 ? 'Contacto:' : 'Contactos:' }}</p>
  <div class="row" *ngFor="let contact of contacts">
    <div class="card border-0">
      <div class="card-body p-1 py-2">
        <h4 class="card-title mb-2">
          {{ contact.name.formatted_name }}
        </h4>
        <p class="card-text">
          {{ contact.phones[0].phone }}
        </p>
      </div>
    </div>
  </div>
  <span class="reaction-emoji" *ngIf="message.reactionEmoji">
    {{ message.reactionEmoji }}
  </span>
</div>