<div *ngIf="message" class="mb-1 p-3 rounded-2 position-relative opacity-100" [ngClass]="{
  'received-message-content border': message.customerMessage,
  'note-message-content bg-secondary dark text-dark': !message.customerMessage,
}">
  <div class="text-end">
    <span class="fa-regular fa-pen-to-square mx-1 cursor-pointer" (click)="editMessageNote(message)"></span>
    <span class="fa-regular fa-trash-can mx-1 cursor-pointer" (click)="deleteMessageNote(message)"></span>
  </div>
  <p [innerHTML]="message.content ? message.content.replace(regexLink, link) : ''" class="mb-0 message-content">
    {{ message.content }}
  </p>
</div>

