import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import {
  Observable,
  OperatorFunction,
  Subject,
  Subscription,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
  of,
  switchMap,
} from 'rxjs';
import { AddChatTagRequest } from '@app/models/chat/chat/add-chat-tag-request.dto';
import { ChatInfo } from '@app/models/chat/chat/chat-info.dto';
import { RemoveChatTagRequest } from '@app/models/chat/chat/remove-chat-tag-request.dto';
import { SaveChatNoteRequest } from '@app/models/chat/chat/save-chat-note-request.dto';
import { ChatTagInfo } from '@app/models/chat/tag/chat-tag-info.dto';
import { ChatTagRequest } from '@app/models/chat/tag/chat-tag-request.dto';
import { ChatTagSearchRequest } from '@app/models/chat/tag/chat-tag-search-request.dto';
import { CheckChatTagNameExistenceRequest } from '@app/models/chat/tag/check-chat-tag-name-existence-request.dto';
import { Page } from '@app/models/common/page';
import { UserInfo } from '@app/models/account/user/user.info.dto';
import { AuthService } from '@app/services/auth/auth.service';
import { ChatTagService } from '@app/services/chat/chat-tag.service';
import { ChatService } from '@app/services/chat/chat.service';
import { EventEmitterService, NotificationTopic } from '@app/services/data/event-emitter.service';
import {
  ChangeChatSmartAssistantStatusRequest,
} from '@app/models/chat/chat/change-chat-smart-assistant-status-request.dto';
import { MessageInfo } from '../../../models/chat/message/message-info.dto';
import { MessageService } from '../../../services/chat/message.service';
import { MessageType, MessageTypeMetadata } from '../../../type/chat/message-content.type';
import { MessageSearchRequest } from '../../../models/chat/message/message-search-request.dto';
import { MessageStatus } from '../../../type/chat/message-status.type';
import { HighlightMessageRequest } from '../../../models/chat/message/highlight-message-request.dto';
import { SaveChatRequest } from '@app/models/chat/chat/save-chat-request.dto';

@Component({
  selector: 'app-chat-settings',
  templateUrl: './chat-settings.component.html',
  styleUrls: ['./chat-settings.component.css'],
})
export class ChatSettingsComponent implements OnInit, OnChanges, OnDestroy {

  private currentUser: UserInfo;
  private componentSubscriptions: Subscription[] = [];

  @Input()
  public chat: ChatInfo;

  // Chat Tags
  @ViewChild('instance', { static: true }) instance: any;
  public focus$ = new Subject<string>();
  public click$ = new Subject<string>();
  public formatResult = (value: ChatTagInfo) => value.name;
  public formatInput = (value: ChatTagInfo) => value.name;
  public tagsFormControl: FormControl;
  public chatTagsAll: ChatTagInfo[] = [];
  public chatTagsAvailable: ChatTagInfo[] = [];
  public chatTagsChosen: ChatTagInfo[] = [];
  private loadedChatTags: boolean = false;

  // Chat Tags Form
  public creatingChatTag: boolean = false;
  public chatTagsForm: FormGroup;
  public duplicatedName: boolean = false;
  public sameName: boolean = false;
  public checkingDuplicatedName: string = 'before';
  public defaultColor: string = '#6E7891';

  // Notes
  public chatNoteFormControl: FormControl = new FormControl('');
  public editingChatNote: boolean = false;

  //Message Notes
  public messageNotes: MessageInfo[] = [];

  public highlightedMessages: MessageInfo[] = [];

  public chatForm: FormGroup;

  protected readonly MessageTypeMetadata = MessageTypeMetadata;
  protected readonly MessageStatus = MessageStatus;
  protected readonly Object = Object;
  protected readonly MessageType = MessageType;

  constructor(
    private offCanvasService: NgbOffcanvas,
    private eventEmitterService: EventEmitterService,
    private toastr: ToastrService,
    private chatTagService: ChatTagService,
    private messageService: MessageService,
    private chatService: ChatService,
    private authService: AuthService,
  ) {
    this.currentUser = this.authService.getUser();
  }

  ngOnInit() {
    this.initializeChatTagForm();
    this.initializeChatTagNameValidator();
    this.initializeMessageHighlightedSubscription();
    this.initializeChatForm();
  }

  ngOnChanges(changes: SimpleChanges) {

    const chatChanges = changes['chat'];

    if (chatChanges) {

      const currentChat: ChatInfo = chatChanges.currentValue;
      const previousChat: ChatInfo = chatChanges.previousValue;

      if (!previousChat || currentChat.id !== previousChat.id) {

        if (!this.changeOriginatedFromSameChat(currentChat, previousChat)) {

          if (!this.loadedChatTags) {

            this.loadChatTags(() => {
              this.fillChatInfo(currentChat);
            });

          } else {

            this.fillChatInfo(currentChat);
          }
        }
      }
    }
  }

  fillChatInfo(chat: ChatInfo) {
    if (!chat) return;
    this.updateChatInfo(chat);
    this.initializeMessageNotes();
    this.initializeHighlightedMessages();
  }

  changeOriginatedFromSameChat(currentChat: ChatInfo, previousChat: ChatInfo): boolean {
    return previousChat && currentChat.id === previousChat.id;
  }

  initializeMessageHighlightedSubscription() {
    const eventEmitter = this.eventEmitterService.getEventEmitter(
      NotificationTopic.MessageHighlighted,
    );

    const subscription = eventEmitter.subscribe((message: MessageInfo) => {
      if (message.chatId === this.chat.id) {
        if (message.highlighted) {
          this.highlightedMessages.push(message);
        } else {
          const messageIndex = this.highlightedMessages.indexOf(message);
          if (messageIndex !== -1) {
            this.highlightedMessages.splice(messageIndex, 1);
          }
        }
      }
    });

    this.componentSubscriptions.push(subscription);
  }

  private initializeChatForm() {
    this.chatForm = new FormGroup({
      contactPhoneNumber: new FormControl(''),
      contactExtraPhoneNumber: new FormControl(''),
      contactEmail: new FormControl(''),
      contactStreet: new FormControl(''),
      contactColony: new FormControl(''),
      contactCountry: new FormControl(''),
      contactState: new FormControl(''),
      contactCity: new FormControl(''),
      contactAddressReference: new FormControl(''),
    });
  }

  private initializeMessageNotes() {
    this.messageService.getMessageNotes(this.chat.id).subscribe({
      next: (messageNotes: MessageInfo[]) => {
        this.messageNotes = messageNotes;
      },
      error: (error: any) => {
        console.error('Error en la carga de notas de mensajes:', error);
      },
      complete: () => {
      },
    });
  }

  private initializeHighlightedMessages() {
    this.messageService.getHighlightedMessages(this.chat.id).subscribe({
      next: (highlightedMessages: MessageInfo[]) => {
        this.highlightedMessages = highlightedMessages;
      },
      error: (error: any) => {
        console.error('Error en la carga de mensajes destacados:', error);
      },
      complete: () => {
      },
    });
  }

  private updateChatInfo(currentChat: ChatInfo) {
    this.selectChatTags(currentChat);
    this.chatNoteFormControl.setValue(currentChat.note);
    this.initializeChatNoteChangeEvent();
    this.changeChatInfo(currentChat);
  }

  ngOnDestroy() {
    this.componentSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  selectChatTags(chat: ChatInfo) {

    this.chatTagsAvailable = [...this.chatTagsAll];
    this.chatTagsChosen = [...chat.chatTags];

    this.chatTagsAvailable = this.chatTagsAvailable.filter((cta: ChatTagInfo) => {
      return !this.chatTagsChosen.find(ctc => ctc.id === cta.id);
    });

    this.chatTagsAvailable.sort((ct1, ct2) => ct2.id - ct1.id);
  }

  changeChatInfo(chat: ChatInfo) {
    this.chatForm.setValue({
      contactPhoneNumber: chat.chatPhoneNumber,
      contactExtraPhoneNumber: chat.contactExtraPhoneNumber,
      contactEmail: chat.contactEmail,
      contactStreet: chat.contactStreet,
      contactColony: chat.contactColony,
      contactCountry: chat.contactCountry,
      contactState: chat.contactState,
      contactCity: chat.contactCity,
      contactAddressReference: chat.contactAddressReference,
    });
  }

  initializeChatTagForm() {

    this.chatTagsForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      color: new FormControl(this.defaultColor, [Validators.required]),
    });

    this.checkingDuplicatedName = 'before';
    this.duplicatedName = false;
    this.sameName = false;
  }

  initializeChatTagNameValidator() {
    this.chatTagsForm
      .get('name')
      .valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((query) => {
        return of(query.trim());
      }),
    )
      .subscribe((name: string) => {
        this.checkChatTagNameExistence(name);
      });
  }

  checkChatTagNameExistence(name: string) {

    if (!name || name.length === 0) {
      this.duplicatedName = false;
      return;
    }

    this.sameName = false;

    this.checkingDuplicatedName = 'checking';
    const afterChecking = 'after-checking';

    const checkChatTagNameExistenceRequest =
      new CheckChatTagNameExistenceRequest(this.currentUser.shop.id, name);

    this.chatTagService
      .checkChatTagNameExistence(checkChatTagNameExistenceRequest)
      .subscribe({
        next: (response: any) => {
          this.duplicatedName = false;
          this.checkingDuplicatedName = afterChecking;
        },
        error: (error: any) => {
          this.duplicatedName = true;
          this.checkingDuplicatedName = afterChecking;
        },
        complete: () => {
        },
      });
  }

  initializeChatNoteChangeEvent() {
    this.chatNoteFormControl
      .valueChanges.pipe(
      debounceTime(50),
      distinctUntilChanged(),
    ).subscribe(() => {
      this.editingChatNote = this.chat.note !== this.chatNoteFormControl.value;
    });
  }

  toggleEditingChatNote() {
    this.editingChatNote = !this.editingChatNote;
    if (!this.editingChatNote) {
      this.chatNoteFormControl.setValue(this.chat.note);
    }
  }

  loadChatTags(callback?: () => void) {

    const infiniteSize: number = -1;

    const searchRequest = new ChatTagSearchRequest(
      this.currentUser.shop.id,
      infiniteSize,
    );

    this.chatTagService.searchChatTags(searchRequest).subscribe({
      next: (chatTagsPage: Page<ChatTagInfo>) => {
        this.chatTagsAvailable = chatTagsPage.content;
        this.chatTagsAll = [...chatTagsPage.content];
        this.loadedChatTags = true;
        callback && callback();
      },
      error: (error: any) => {
        console.error('Error en la carga de tags:', error);
        this.loadedChatTags = true;
      },
      complete: () => {

      },
    });

    this.initializeChatTagsChosenEvent();
  }

  initializeChatTagsChosenEvent() {

    this.tagsFormControl = new FormControl('');

    this.tagsFormControl.valueChanges.subscribe({
      next: (selectedChatTag: ChatTagInfo) => {

        if (selectedChatTag) {

          this.chatTagsChosen.push(selectedChatTag);
          this.tagsFormControl.setValue(null);

          const chatTagChosenIndex = this.chatTagsAvailable.indexOf(selectedChatTag);

          this.chatTagsAvailable.splice(chatTagChosenIndex, 1);
          this.chatTagsAvailable.sort((ct1, ct2) => ct2.id - ct1.id);

          const addChatTagRequest = new AddChatTagRequest(
            [this.chat.id],
            [selectedChatTag.id],
          );

          const eventEmitterRequest = {
            chatId: this.chat.id,
            chatTag: selectedChatTag,
          };

          this.eventEmitterService.emit(NotificationTopic.SelectedChatTag, eventEmitterRequest);
          this.chatService.addChatTag(addChatTagRequest).subscribe({
            next: (response: any) => {

            },
            error: (error: any) => {

            },
          });
        }
      },
    });
  }

  removeChatTag(chatTag: ChatTagInfo) {

    const chatTagChosenIndex = this.chatTagsChosen.indexOf(chatTag);
    this.chatTagsChosen.splice(chatTagChosenIndex, 1);

    this.chatTagsAvailable.push(chatTag);
    this.chatTagsAvailable.sort((ct1, ct2) => ct2.id - ct1.id);

    const removeChatTagRequest = new RemoveChatTagRequest(
      chatTag.id,
      this.chat.id,
    );

    const eventEmitterRequest = {
      chatId: this.chat.id,
      chatTag: chatTag,
    };

    this.eventEmitterService.emit(NotificationTopic.RemoveSelectedChatTag, eventEmitterRequest);
    this.chatService.removeChatTag(removeChatTagRequest).subscribe({
      next: (response: any) => {
      },
      error: (error: any) => {
      },
      complete: () => {
      },
    });
  }

  searchChatTags: OperatorFunction<string, readonly ChatTagInfo[]> = (
    text$: Observable<string>,
  ) => {
    const debouncedText$ = text$.pipe(debounceTime(1), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance?.isPopupOpen()),
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term: string) => {
        if (term === '') return this.chatTagsAvailable;
        return this.chatTagsAvailable.filter((ct: ChatTagInfo) => ct.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10);
      }),
    );
  };

  toggleShowCreateChatTag() {
    this.creatingChatTag = !this.creatingChatTag;
  }

  saveChatTag() {

    const { name, color } = this.chatTagsForm.value;

    let chatTagId: number = null;

    const chatTagRequest = new ChatTagRequest(
      this.currentUser.shop.id,
      name,
      color,
      chatTagId,
    );

    this.chatTagService.saveChatTag(chatTagRequest).subscribe({
      next: (chatTag: ChatTagInfo) => {

        if (this.chat.chatTags?.length > 0) {
          this.chat.chatTags.unshift(chatTag);
        } else {
          this.chat.chatTags = [chatTag];
        }

        const addChatTagRequest = new AddChatTagRequest(
          [this.chat.id],
          [chatTag.id],
        );

        const eventEmitterRequest: any = {
          chatId: this.chat.id, chatTag: chatTag,
        };

        this.eventEmitterService.emit(NotificationTopic.SelectedChatTag, eventEmitterRequest);

        this.chatService.addChatTag(addChatTagRequest).subscribe({
          next: (response: any) => {},
          error: (error: any) => {},
        });

        this.creatingChatTag = false;
        this.initializeChatTagForm();
        this.loadChatTags(() => this.selectChatTags(this.chat));

        this.toastr.success('Etiqueta creada exitosamente');
      },
      error: (httpErrorResponse: HttpErrorResponse) => {
        const { error } = httpErrorResponse;
        const errorCode = error.code;

        if (errorCode === 'DUPLICATED_CHAT_LABEL') {
          this.duplicatedName = true;
        }
      },
      complete: () => {
      },
    });
  }

  saveChatNote() {

    const note = this.chatNoteFormControl.value;

    const saveChatNoteRequest = new SaveChatNoteRequest(this.chat.id, note);

    this.chatService.saveChatNote(saveChatNoteRequest).subscribe({
      next: (response: any) => {
        this.editingChatNote = false;
        this.chat.note = note;
        this.toastr.success('Nota guardada exitosamente');
      },
      error: (error: any) => {
        this.toastr.error('Error al guardar nota');
        console.error('Error al guardar nota:', error);
      },
      complete: () => {
      },
    });
  }

  public changeChatSmartAssistantStatus(event: any) {

    const chatSmartAssistantStatus = !this.chat.smartAssistantEnabled;

    const changeChatSmartAssistantStatusRequest = new ChangeChatSmartAssistantStatusRequest(
      this.chat.id,
      chatSmartAssistantStatus,
    );

    this.chatService.changeChatSmartAssistantStatus(changeChatSmartAssistantStatusRequest).subscribe({
      next: (response: any) => {
        this.chat.smartAssistantEnabled = chatSmartAssistantStatus;
        this.toastr.success('El estado del asistente cambiado exitosamente');
      },
      error: (error: any) => {
        this.toastr.error('Error al cambiar estado del asistente');
        console.error('Error al cambiar estado del asistente:', error);
      },
      complete: () => {
      },
    });

  }

  close() {
    this.offCanvasService.dismiss();
  }

  goToHighlightMessage(message: MessageInfo) {

    const messageSearch = new MessageSearchRequest();
    messageSearch.chatId = this.chat.id;
    messageSearch.searchTerm = message.content;
    messageSearch.messageId = message.id;
    messageSearch.size = 20;


    this.messageService.getSelectedMessagePage(messageSearch).subscribe((response: any) => {
      console.log(response);

      const data = {
        messageId: message.id,
        page: response.page,
      };
      this.eventEmitterService.emit(NotificationTopic.GoToMessage, data);
    });
  }

  deleteMessageNote(selectedMessageNote: MessageInfo) {
    if (confirm('¿Está seguro que desea eliminar la nota?')) {
      this.eventEmitterService.emit(NotificationTopic.DeleteMessageNote, selectedMessageNote);
      const messageNoteIndex = this.messageNotes.indexOf(selectedMessageNote);
      this.messageNotes.splice(messageNoteIndex, 1);
    }
  }

  editMessageNote(selectedMessageNote: MessageInfo) {
    this.offCanvasService.dismiss();
    this.eventEmitterService.emit(NotificationTopic.ShowMessageNote, selectedMessageNote);
  }

  public highlightSelectedMessage(message: MessageInfo) {

    const messageIndex = this.highlightedMessages.indexOf(message);
    if (messageIndex !== -1) {
      this.highlightedMessages.splice(messageIndex, 1);
    }
    const request: HighlightMessageRequest = {
      messageId: message.id,
      highlighted: !message.highlighted,
    };

    this.messageService.highlightMessage(request).subscribe({
      next: (response: MessageInfo) => {
        message.highlighted = !message.highlighted;
        this.eventEmitterService.emit(NotificationTopic.RemoveMessageHighlight, message);
      },
      error: (error) => {
        console.error(error);
      },
    });


  }

  public saveChat() {

    const {
      contactExtraPhoneNumber,
      contactEmail,
      contactStreet,
      contactColony,
      contactCountry,
      contactState,
      contactCity,
      contactAddressReference,
    } = this.chatForm.value;

    const saveChatRequest = new SaveChatRequest();
    saveChatRequest.chatPhoneNumber = this.chat.chatPhoneNumber;
    saveChatRequest.chatName = this.chat.chatName;
    saveChatRequest.contactExtraPhoneNumber = contactExtraPhoneNumber;
    saveChatRequest.contactEmail = contactEmail;
    saveChatRequest.contactStreet = contactStreet;
    saveChatRequest.contactColony = contactColony;
    saveChatRequest.contactCountry = contactCountry;
    saveChatRequest.contactState = contactState;
    saveChatRequest.contactCity = contactCity;
    saveChatRequest.contactAddressReference = contactAddressReference;
    saveChatRequest.id = this.chat.id;
    saveChatRequest.shopId = this.currentUser.shop.id;

    this.chatService.saveChat(saveChatRequest).subscribe({
      next: (response: any) => {
        const dataResponse = response;
        console.log(dataResponse);
        this.toastr.success('Datos guardados exitosamente');

      },
      error: (httpErrorResponse: HttpErrorResponse) => {
        this.toastr.error('Error al guardar los datos');
      },
      complete: () => {
      },
    });

  }

}
