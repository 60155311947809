<section class="pb-14 overflow-x-hidden">

  <div class="container-md px-lg-7 px-xxl-3">
    <div class="custom-shadow position-relative dark__bg-1100 mt-5">
      <div class="bg-holder d-none d-md-block"
           style="background-image:url(./assets/img/background/39.png);background-size:186px;background-position:top 20px right 20px;">
      </div>
      <!--/.bg-holder-->
      <img class="position-absolute d-none d-lg-block" src="./assets/img/background/bg-left-22.png" width="150" alt=""
           style="top: -90px; left: -70px" />
      <img class="position-absolute d-none d-lg-block" src="./assets/img/background/bg-right-22.png" width="150"
           alt=""
           style="bottom: -80px; right: -80px" />
      <div>
        <div class="py-8 px-5 px-xl-15 ">
          <div class="row g-5 mb-5 mb-lg-8" data-product-details="data-product-details">
            <div class="col-12 col-lg-6" *ngIf="appPluginInfo">
              <app-app-info [appPluginInfo]="appPluginInfo" [stars]="stars"></app-app-info>
            </div>
            <div class="col-12 col-lg-6">
              <app-app-gallery [appId]="appId"></app-app-gallery>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end of .container-->

</section>

<section class=" pb-lg-6 pb-xl-8 pt-4">
  <!--/.bg-holder-->
  <div>
    <div class="bg-holder d-dark-none"
         style="background-image:url(./assets/img/background/bg-5.png);background-size:auto;">
    </div>
    <div class="bg-holder d-light-none"
         style="background-image:url(./assets/img/background/bg-dark-5.png);background-size:auto;">
    </div>
    <div class="bg-holder"
         style="background-image:url(./assets/img/background/bg-left-5.png);background-position:left;background-size:auto;">
    </div>
    <div class="bg-holder"
         style="background-image:url(./assets/img/background/bg-right-6.png);background-position:right;background-size:auto;">
    </div>
  </div>
  <!--/.bg-holder-->
  <div class="container-md position-relative px-lg-7 px-xxl-3">
    <div class="bg-white rounded-3 p-4 pt-0 border border-200">
      <section class="mb-4" *ngIf="appPluginInfo">
        <h3 class="mb-3">Detalles</h3>
        <p>{{
            appPluginInfo.details
          }}</p>
      </section>
      <hr>
      <app-app-reviews [appPluginInfo]="appPluginInfo" [stars]="stars" [appId]="appId"></app-app-reviews>
    </div>
  </div>
</section>