import { ChatTagSearchType } from "../../../type/chat/chat-tag-search.type";
import { SendMessageRequest } from "../../chat/message/send-message-request.dto";
import { ChatSearchDangeRangeFieldType } from "../../../type/chat/chat-search-dange-range-field.type";

export class SaveBroadcastRequest {

  id: number;
  shopId: number;
  name: string = '';
  randomize: boolean;
  randomizeTotal: number;
  scheduledFor: number;

  fromDate: number;
  toDate: number;
  dateRangeFieldType: ChatSearchDangeRangeFieldType = ChatSearchDangeRangeFieldType.LastMessageDate;

  userIds: number[] = [];
  chatTagIds: number[] = [];
  chatTagSearchType: ChatTagSearchType = ChatTagSearchType.AtLeastOne;

  // Container for the message template data
  sendMessageRequest: SendMessageRequest;

}