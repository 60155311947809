<div class="offcanvas-header align-items-start border-bottom flex-column">
  <div class="pt-1 w-100 d-flex justify-content-between align-items-start">
    <div>
      <h4 class="mb-2 me-2 lh-sm">
        Administrador de chat
      </h4>
      <p class="mb-0 fs--1 text-info-500">
        Elige lo que quieras cambiar de tu chat para tener mayor control.
      </p>
    </div>
    <button class="btn p-1 fw-bolder" type="button" (click)="close()">
      <i class="fas fa-xmark fs-2"></i>
    </button>
  </div>
</div>
<div class="offcanvas-body scrollbar" id="settingsOffCanvasBody">
  <div class="mb-4">
    <div class="mb-3">
      <div class="d-flex justify-content-between mb-2">
        <h4 class="mb-2">
          <i class="fas fa-note-sticky text-info-500"></i>
          Notas
        </h4>
        <div class="d-flex" *ngIf="editingChatNote">
          <button
            class="btn btn-link pe-0"
            type="button"
            (click)="toggleEditingChatNote()">
            Cancelar
          </button>
          <button
            class="btn btn-link pe-0"
            type="button"
            (click)="saveChatNote()">
            Guardar
          </button>
        </div>
      </div>
      <div class="row g-3">
        <div class="col-sm-12">
          <div class="form-icon-container">
            <div class="form-floating">
              <textarea
                class="form-control form-icon-input scrollbar"
                id="chatNotes"
                placeholder="Escriba su nota"
                [formControl]="chatNoteFormControl"
              ></textarea>
              <label
                class="text-700 form-icon-label"
                for="chatNotes">
                Escriba su nota
              </label>
            </div>
            <i class="fa fa-circle-info text-900 fs--1 form-icon"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-3" *ngIf="!creatingChatTag">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <h4 class="mb-0">
          <i class="fas fa-tags"></i>
          Etiquetas
        </h4>
        <button
          class="btn btn-link pe-0"
          type="button"
          (click)="toggleShowCreateChatTag()">
          Crear etiqueta
        </button>
      </div>
      <div class="row g-2">
        <div class="col-sm-12">
          <div class="form-floating">
            <input
              id="floatingInput"
              type="text"
              class="form-control"
              [formControl]="tagsFormControl"
              placeholder="Buscar etiquetas"
              [editable]="false"
              #instance="ngbTypeahead"
              [ngbTypeahead]="searchChatTags"
              [resultFormatter]="formatResult"
              [inputFormatter]="formatInput"
              (focus)="focus$.next($any($event).target.value)"
              (click)="click$.next($any($event).target.value)">
            <label for="floatingInput">
              {{ chatTagsAvailable.length > 0 ? 'Buscar etiquetas' : 'No hay más etiquetas' }}
            </label>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="mb-1">
            <span
              *ngFor="let chatTag of chatTagsChosen"
              (click)="removeChatTag(chatTag)"
              role="button"
              class="badge badge-phoenix fs--2 me-1"
              [ngStyle]="{
                'color': chatTag.color,
                'border': '1px solid ' + chatTag.color,
              }">
              {{ chatTag.name }}
              <i class="fas fa-xmark tag-xmark-icon badge-icon"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-3" *ngIf="creatingChatTag">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <h4 class="mb-0">
          Etiquetas
        </h4>
        <button
          class="btn btn-link pe-0"
          type="button"
          (click)="toggleShowCreateChatTag()">
          Cancelar
        </button>
      </div>
      <div class="row g-2">
        <div class="col-sm-12">
          <form
            [formGroup]="chatTagsForm"
            (submit)="saveChatTag()">
            <div class="row g-3">
              <div class="col-sm-6 col-md-8">
                <div class="form-floating">
                  <input
                    class="form-control"
                    id="name"
                    type="text"
                    placeholder="Nombre de la etiqueta"
                    formControlName="name"
                    [ngClass]="{
                      'is-invalid': (chatTagsForm.get('name').touched && chatTagsForm.get('name').invalid) || duplicatedName,
                      'is-valid': checkingDuplicatedName === 'after-checking' && !duplicatedName && chatTagsForm.get('name').valid
                    }">
                  <label for="name">
                    Nombre de la etiqueta
                  </label>
                  <div class="valid-feedback" *ngIf="!sameName">
                    Nombre disponible!
                  </div>
                  <div
                    *ngIf="chatTagsForm.get('name').touched && chatTagsForm.get('name').invalid"
                    class="invalid-feedback">
                    Ingrese un nombre para la etiqueta, por favor.
                  </div>
                  <div
                    *ngIf="duplicatedName"
                    class="invalid-feedback">
                    La etiqueta ya existe, ingrese otro nombre.
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-4">
                <input
                  class="chat-tag-color-picker"
                  type="color"
                  name="color"
                  formControlName="color">
              </div>
              <div
                class="col-sm-12"
                *ngIf="chatTagsForm.valid">
                <span
                  role="button"
                  class="badge badge-phoenix fs--2 me-1"
                  [ngStyle]="{
                    'color': chatTagsForm.get('color').value,
                    'border': '1px solid ' + chatTagsForm.get('color').value,
                  }">
                  {{ chatTagsForm.get('name').value }}
                  <i class="fas fa-xmark tag-xmark-icon badge-icon"></i>
                </span>
              </div>
              <div class="col-12">
                <div class="row g-3 justify-content-end">
                  <div class="col-auto">
                    <button
                      class="btn btn-primary"
                      [disabled]="chatTagsForm.invalid">
                      Guardar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="mb-1">
      <div class="d-flex justify-content-start mb-2">
        <h4 class="mb-2 me-3">
          <i class="fas fa-bolt text-warning"></i>
          Asistente inteligente
        </h4>
        <div class="form-check form-switch me-1">
          <input type="checkbox" class="form-check-input"
                 (change)="changeChatSmartAssistantStatus($event)"
                 [checked]="chat?.smartAssistantEnabled">
        </div>
      </div>
    </div>
    <div class="accordion" id="accordionExample">
      <div class="accordion-item px-0">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                aria-expanded="true" aria-controls="collapseOne">
          <h4 class="mb-2">
            <i class="fa-solid fa-message-lines text-info-500"></i>
            Notas de mensaje
          </h4>
        </button>
        <div class="accordion-collapse collapse" id="collapseOne" aria-labelledby="headingOne"
          data-bs-parent="#accordionExample">
          <div class="accordion-body pt-0 scrollbar scrollbar-height">
            <p *ngIf="messageNotes.length === 0">No hay ninguna nota creada. </p>
            <ng-container *ngFor="let messageNote of messageNotes">
              <div *ngIf="messageNote"
                class="mb-2 p-3 rounded-2 position-relative received-message-content border dark bg-secondary opacity-100"
                (click)="goToHighlightMessage(messageNote)">
                <div class="d-flex justify-content-between">
                  <p class="mb-0 message-content text-truncate text-dark">
                    {{ messageNote.content }}
                  </p>
                  <div>
                    <span class="fa-regular fa-pen-to-square mx-1 cursor-pointer"
                          (click)="editMessageNote(messageNote)">
                    </span>
                    <span class="fa-solid fa-trash-can mx-1 cursor-pointer"
                          (click)="deleteMessageNote(messageNote)">
                    </span>
                  </div>
                </div>
        
                <div class="d-flex ms-0">
                  <p class="mb-0 fs--2 text-900 fw-semi-bold dark__text-white">
                    {{ messageNote.sentTime | date: 'dd/MM/yyyy HH:mm:ss' }}
                  </p>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="accordion-item px-0 ">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
          aria-expanded="true" aria-controls="collapseTwo">
          <h4 class="mb-2">
            <i class="fa-solid fa-star text-warning"></i>
            Mensajes destacados
          </h4>
        </button>
        <div class="accordion-collapse collapse" id="collapseTwo" aria-labelledby="headingTwo"
          data-bs-parent="#accordionExample">
          <div class="accordion-body pt-0 scrollbar scrollbar-height">
            <p *ngIf="highlightedMessages.length === 0">No hay ningún mensaje destacado. </p>
            <ng-container *ngFor="let highlightMessage of highlightedMessages">
              <div *ngIf="highlightMessage" class="mb-2 p-3 rounded-2 position-relative"
                (click)="goToHighlightMessage(highlightMessage)"
                [ngClass]="{
                  'received-message-content border dark': highlightMessage.customerMessage,
                  'sent-message-content bg-primary light text-white': !highlightMessage.customerMessage,
                 }">
                <div class="d-flex justify-content-between">
                  <p class="mb-0 message-content">
                    {{
                    highlightMessage.type === MessageType.Text ? highlightMessage.content :
                    MessageTypeMetadata[highlightMessage.type].description
                    }}
                  </p>
                  <span class="fa-solid fa-trash-can mx-1 cursor-pointer"
                    (click)="highlightSelectedMessage(highlightMessage)"></span>
                </div>
      
                <div class="d-flex ms-0">
                  <p class="mb-0 fs--2 fw-semi-bold"
                    [ngClass]="highlightMessage.customerMessage ? '' : 'text-white'">
                    {{ highlightMessage.sentTime | date: 'dd/MM/yyyy HH:mm:ss' }}
                  </p>
                  <ng-container *ngIf="!highlightMessage.customerMessage">
                    <div *ngFor="let status of Object.values(MessageStatus)">
                      <div *ngIf="highlightMessage.status === status">
                        <i class="fa ms-2 fs--1" [ngClass]="highlightMessage.statusClass"></i>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="accordion-item px-0 ">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="true" aria-controls="collapseThree">
          <h4 class="mb-2">
            <i class="fa fa-address-card contact-info-icon"></i>
            Información del contacto
          </h4>
        </button>
        <div class="accordion-collapse collapse"
             id="collapseThree"
             aria-labelledby="headingThree"
             data-bs-parent="#accordionExample">
          <form
            [formGroup]="chatForm"
            (submit)="saveChat()">
            <div class="contacts-extra-info mb-3">
              <!-- Teléfono extra -->
              <div class="row mb-3">
                <div class="col-12">
                  <div class="form-floating">
                    <input
                      class="form-control"
                      id="contactExtraPhoneNumber"
                      type="text"
                      placeholder="Número de teléfono adicional"
                      formControlName="contactExtraPhoneNumber"
                      [ngClass]="{
                          'is-invalid': chatForm.get('contactExtraPhoneNumber')?.touched && chatForm.get('contactExtraPhoneNumber')?.invalid,
                          'is-valid': chatForm.get('contactExtraPhoneNumber')?.touched && chatForm.get('contactExtraPhoneNumber')?.valid
                        }">
                    <label for="contactExtraPhoneNumber">Número de teléfono adicional</label>
                    <div
                      *ngIf="chatForm.get('contactExtraPhoneNumber')?.touched && chatForm.get('contactExtraPhoneNumber')?.invalid"
                      class="invalid-feedback">
                      Ingrese un número de teléfono adicional válido, por favor.
                    </div>
                  </div>
                </div>
              </div>
              <!-- Email -->
              <div class="row mb-3">
                <div class="col-12">
                  <div class="form-floating">
                    <input
                      class="form-control"
                      id="contactEmail"
                      type="text"
                      placeholder="Correo electrónico de contacto"
                      formControlName="contactEmail"
                      [ngClass]="{
                          'is-invalid': chatForm.get('contactEmail')?.touched && chatForm.get('contactEmail')?.invalid,
                          'is-valid': chatForm.get('contactEmail')?.touched && chatForm.get('contactEmail')?.valid
                        }">
                    <label for="contactEmail">Correo electrónico de contacto</label>
                    <div
                      *ngIf="chatForm.get('contactEmail')?.touched && chatForm.get('contactEmail')?.invalid"
                      class="invalid-feedback">
                      Ingrese un correo electrónico válido, por favor.
                    </div>
                  </div>
                </div>
              </div>
              <!-- Calle -->
              <div class="row mb-3">
                <div class="col-12">
                  <div class="form-floating">
                    <input
                      class="form-control"
                      id="contactStreet"
                      type="text"
                      placeholder="Calle"
                      formControlName="contactStreet"
                      [ngClass]="{
                          'is-invalid': chatForm.get('contactStreet')?.touched && chatForm.get('contactStreet')?.invalid,
                          'is-valid': chatForm.get('contactStreet')?.touched && chatForm.get('contactStreet')?.valid
                        }">
                    <label for="contactStreet">Calle</label>
                    <div
                      *ngIf="chatForm.get('contactStreet')?.touched && chatForm.get('contactStreet')?.invalid"
                      class="invalid-feedback">
                      Ingrese una calle válida, por favor.
                    </div>
                  </div>
                </div>
              </div>
              <!-- Colonia -->
              <div class="row mb-3">
                <div class="col-12">
                  <div class="form-floating">
                    <input
                      class="form-control"
                      id="contactColony"
                      type="text"
                      placeholder="Colonia"
                      formControlName="contactColony"
                      [ngClass]="{
                          'is-invalid': chatForm.get('contactColony')?.touched && chatForm.get('contactColony')?.invalid,
                          'is-valid': chatForm.get('contactColony').touched && chatForm.get('contactColony')?.valid
                        }">
                    <label for="contactColony">Colonia</label>
                    <div
                      *ngIf="chatForm.get('contactColony')?.touched && chatForm.get('contactColony')?.invalid"
                      class="invalid-feedback">
                      Ingrese una colonia válida, por favor.
                    </div>
                  </div>
                </div>
              </div>
              <!-- País -->
              <div class="row mb-3">
                <div class="col-12">
                  <div class="form-floating">
                    <input
                      class="form-control"
                      id="contactCountry"
                      type="text"
                      placeholder="País"
                      formControlName="contactCountry"
                      [ngClass]="{
                          'is-invalid': chatForm.get('contactCountry')?.touched && chatForm.get('contactCountry')?.invalid,
                          'is-valid': chatForm.get('contactCountry')?.touched && chatForm.get('contactCountry')?.valid
                        }">
                    <label for="contactCountry">País</label>
                    <div
                      *ngIf="chatForm.get('contactCountry')?.touched && chatForm.get('contactCountry')?.invalid"
                      class="invalid-feedback">
                      Ingrese un país válido, por favor.
                    </div>
                  </div>
                </div>
              </div>
              <!-- Estado -->
              <div class="row mb-3">
                <div class="col-12">
                  <div class="form-floating">
                    <input
                      class="form-control"
                      id="contactState"
                      type="text"
                      placeholder="Estado"
                      formControlName="contactState"
                      [ngClass]="{
                          'is-invalid': chatForm.get('contactState')?.touched && chatForm.get('contactState')?.invalid,
                          'is-valid': chatForm.get('contactState')?.touched && chatForm.get('contactState')?.valid
                        }">
                    <label for="contactState">Estado</label>
                    <div
                      *ngIf="chatForm.get('contactState')?.touched && chatForm.get('contactState')?.invalid"
                      class="invalid-feedback">
                      Ingrese un estado válido, por favor.
                    </div>
                  </div>
                </div>
              </div>
              <!-- Ciudad -->
              <div class="row mb-3">
                <div class="col-12">
                  <div class="form-floating">
                    <input
                      class="form-control"
                      id="contactCity"
                      type="text"
                      placeholder="Ciudad"
                      formControlName="contactCity"
                      [ngClass]="{
                          'is-invalid': chatForm.get('contactCity')?.touched && chatForm.get('contactCity')?.invalid,
                          'is-valid': chatForm.get('contactCity')?.touched && chatForm.get('contactCity')?.valid
                        }">
                    <label for="contactCity">Ciudad</label>
                    <div
                      *ngIf="chatForm.get('contactCity')?.touched && chatForm.get('contactCity')?.invalid"
                      class="invalid-feedback">
                      Ingrese una ciudad válida, por favor.
                    </div>
                  </div>
                </div>
              </div>
              <!-- Referencia de dirección -->
              <div class="row">
                <div class="col-12">
                  <div class="form-floating">
                    <input
                      class="form-control"
                      id="contactAddressReference"
                      type="text"
                      placeholder="Referencia de dirección"
                      formControlName="contactAddressReference"
                      [ngClass]="{
                          'is-invalid': chatForm.get('contactAddressReference')?.touched && chatForm.get('contactAddressReference')?.invalid,
                          'is-valid': chatForm.get('contactAddressReference')?.touched && chatForm.get('contactAddressReference')?.valid
                        }">
                    <label for="contactAddressReference">Referencia de dirección</label>
                    <div
                      *ngIf="chatForm.get('contactAddressReference')?.touched && chatForm.get('contactAddressReference')?.invalid"
                      class="invalid-feedback"
                    >
                      Ingrese una referencia de dirección válida, por favor.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Botones -->
            <div class="row g-3 justify-content-end">
              <div class="col-auto">
                <button
                  class="btn btn-primary"
                  [disabled]="chatForm.invalid">
                  Guardar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

