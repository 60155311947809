import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { SocketService } from '@services/socket/socket.service';
import { EventEmitterService, NotificationTopic } from '@services/data/event-emitter.service';
import { Subscription } from 'rxjs';
import { SpinnerService } from '@services/data/spinner.service';
import { loadScripts, loadStyles, removeStyles } from '@app/utils/page-utils';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-landing-page-layout',
  template: `
    <router-outlet></router-outlet>
    <ngx-spinner [fullScreen]="true" [type]="spinnerType" size="medium" style="color: white;">
      <p class="loading-spinner">{{ spinnerMessage ? spinnerMessage : '' }}</p>
    </ngx-spinner>
  `,
})
export class LandingPageLayoutComponent implements OnInit, AfterViewInit, OnDestroy, AfterContentChecked {

  public spinnerMessage: string = '';
  public spinnerType: string = 'square-spin';
  private spinnerSubscription: Subscription;
  public isMobile: boolean;

  constructor(
    private socketService: SocketService,
    private eventEmitterService: EventEmitterService,
    private spinnerService: SpinnerService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private router: Router,
  ) {
  }

  ngOnInit() {

    if (this.authService.isAuthenticated()) {
      this.router.navigate(['/app/chat']).then(() => {  });
    }

    removeStyles('app');

    this.loadStyles().then(() => {
      this.loadScripts().then(() => {

      });
    });

    this.isMobile = window.innerWidth <= 992;

    this.initializeWebSocketEvents();
    this.initializeSpinnerEvent();
  }

  private loadStyles() {

    const styles: string[] = [
      './assets/lib/leap/css/theme.css',
      './assets/css/landing-page.css',
    ];

    return loadStyles(styles, 'landing-page');
  }

  private loadScripts() {

    const baseScriptsPath = './assets/lib/leap/js/';
    const scripts = [
      'aos.js',
      'clipboard.min.js',
      'jquery.fancybox.min.js',
      'flatpickr.min.js',
      'flickity.pkgd.min.js',
      'ion.rangeSlider.min.js',
      'isotope.pkgd.min.js',
      'jarallax.min.js',
      'jarallax-video.min.js',
      'jarallax-element.min.js',
      'jquery.countdown.min.js',
      'jquery.smartWizard.min.js',
      'plyr.polyfilled.min.js',
      'prism.js',
      'scrollMonitor.js',
      'smooth-scroll.polyfills.min.js',
      'svg-injector.umd.production.js',
      'twitterFetcher_min.js',
      'typed.min.js',
    ];

    return loadScripts(baseScriptsPath, scripts, 'landing-page');
  }

  ngAfterViewInit() {
    (window as any).phoenix.initEverything();
  }

  ngAfterContentChecked() {
    this.spinnerType = 'ball-scale-multiple';
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.spinnerSubscription.unsubscribe();
  }

  private initializeWebSocketEvents() {

    const socket = this.socketService.getSocket();

    if (socket) {

    }
  }

  private initializeSpinnerEvent() {
    this.spinnerSubscription = this.spinnerService.spinnerSubject
      .subscribe((spinnerData: any) => {
        this.spinnerMessage = spinnerData.message;
        this.spinnerType = spinnerData.type;
      });
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    const isMobile = window.innerWidth <= 992;
    if (isMobile !== this.isMobile) {
      this.isMobile = isMobile;
      this.eventEmitterService.emit(NotificationTopic.IsMobile, isMobile);
    }
  }

}
