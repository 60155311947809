
<div class="mb-2">
  <div class="row g-3 mb-3">
    <div class="col-auto">
      <h2 class="mb-2">
        Broadcasts
      </h2>
      <p class="text-700 lh-sm mb-0">
        Administra tus broadcast de WhatsApp
      </p>
    </div>
  </div>
  <div class="mb-3">
    <div class="row g-3">
      <div class="col-md-auto col-sm-12">
        <div class="search-box">
          <form
            class="position-relative"
            data-bs-toggle="search"
            data-bs-display="static">
            <input
              class="form-control search-input search"
              type="search"
              placeholder="Buscar broadcasts"
              [formControl]="searchBroadcastFormControl"/>
            <span class="fas fa-search search-box-icon"></span>
          </form>
        </div>
      </div>
      <div class="col-md-auto col-sm-12">
        <button
          class="btn btn-phoenix-primary"
          (click)="openSaveBroadcast()">
        <span class="fas fa-plus">
        </span>
          Programar broadcast
        </button>
      </div>
    </div>
  </div>
  <div [class.transparent]="firstLoad"
       [class.not-transparent]="!firstLoad">
    <div class="row g-4">
      <full-calendar [options]="calendarOptions"></full-calendar>
    </div>
  </div>
</div>

<ng-template #saveBroadcastOffCanvas let-offcanvas>
  <div class="offcanvas-header align-items-start border-bottom flex-column">
    <div class="pt-1 w-100 d-flex justify-content-between align-items-start">
      <div>
        <h5 class="mb-2 me-2 lh-sm" *ngIf="!selectedBroadcast">
          Programa un broadcast
        </h5>
        <h5 class="mb-2 me-2 lh-sm" *ngIf="selectedBroadcast">
          Editar broadcast
        </h5>
        <p class="mb-0 fs--1 text-info-500" *ngIf="!selectedBroadcast">
          Elige filtros que coincidan con los chats a los que quieres enviar el broadcast.
        </p>
        <p class="mb-0 fs--1 text-info-500" *ngIf="selectedBroadcast">
          Edita los datos del broadcast para programarlo.
        </p>
      </div>
      <button
        class="btn p-1 fw-bolder"
        type="button"
        (click)="offcanvas.close()">
        <i class="fas fa-xmark fs-2"></i>
      </button>
    </div>
  </div>
  <div class="offcanvas-body scrollbar">
    <form
      [formGroup]="broadcastForm"
      (submit)="saveBroadcast()">
      <div class="row g-3 mb-3" *ngIf="false">
        <pre>{{ saveBroadcastRequest | json }}</pre>
      </div>
      <!-- Nombre del broadcast -->
      <div class="row mb-3">
        <div class="col-12">
          <div class="form-floating">
            <input
              class="form-control"
              id="name"
              type="text"
              placeholder="Nombre del broadcast  **"
              formControlName="name"
              (input)="onNameChanged()"
              [ngClass]="{
                'is-invalid': broadcastForm.get('name').invalid,
                'is-valid': broadcastForm.get('name').valid
              }">
            <label for="name">
              Nombre del broadcast **
            </label>
            <div
              *ngIf="broadcastForm.get('name').invalid"
              class="invalid-feedback text-warning">
              Ingrese el nombre del broadcast, por favor.
            </div>
          </div>
        </div>
      </div>
      <!-- Selección de fecha y hora de envío programado -->
      <div class="row mb-3">
        <div class="col-12">
          <div class="form-floating">
            <input
              placeholder="Fecha y hora de envío programado"
              class="form-control"
              type="text"
              [time24hr]="false"
              [enableTime]="true"
              [dateFormat]="'d-m-Y H:i'"
              (flatpickrReady)="scheduledForReady($event)"
              (flatpickrClose)="setSelectedDateTime($event)"
              [minDate]="scheduledForMinDate"
              [minTime]="scheduledForMinTime"
              [clickOpens]="scheduledForInstanceOpens"
              mwlFlatpickr>
            <label>
              Fecha y hora de envío programado
            </label>
          </div>
          <small *ngIf="todayIsSelected"
            class="text-muted">
            <i class="fa fa-info-circle me-1"></i>
            Una hora después de la fecha actual, por default.
          </small>
        </div>
      </div>

      <hr>

      <h4 class="text-dark text-center mb-2">
        Filtros de chats
      </h4>

      <div class="chats-filter-container scrollbar">
        <label class="form-label ps-0 text-primary">
          Asesores
          <span [ngbPopover]="advisorsInfoPopoverContent"
                triggers="mouseenter:mouseleave"
                popoverTitle="Información">
          <i class="fa fa-info-circle ms-1"></i>
        </span>
        </label>
        <!-- Selección de asesores -->
        <div class="row"
             [class.mb-3]="!usersChosen?.length"
             [class.mb-2]="usersChosen?.length">
          <div class="col-12">
            <select id="advisorSelect" class="form-select"
                    formControlName="user"
                    (change)="onUserChosen($event)">
              <option value="" selected="">
                {{ usersAvailable.length === 0 ? 'No hay más asesores' : 'Elige uno o más asesores' }}
              </option>
              <option [value]="user.id" *ngFor="let user of usersAvailable">
                {{ user.name }}
              </option>
            </select>
          </div>
        </div>
        <!-- Muestreo de asesores -->
        <div class="row mb-3" *ngIf="usersChosen?.length">
          <div class="col-sm-12">
            <div class="mb-1">
            <span
              *ngFor="let user of usersChosen"
              (click)="removeUserChosen(user)"
              role="button"
              class="badge badge-phoenix-success fs--2 me-1"
              [ngStyle]="{
                'border': '1px solid #1c6c09',
              }">
              {{ user.name }}
              <i class="fas fa-xmark tag-xmark-icon badge-icon"></i>
            </span>
            </div>
          </div>
        </div>
        <label class="form-label ps-0 text-primary">
          Etiquetas de chats
          <span [ngbPopover]="chatTagsInfoPopoverContent"
                triggers="mouseenter:mouseleave"
                popoverTitle="Información">
          <i class="fa fa-info-circle ms-1"></i>
        </span>
        </label>
        <!-- Selección de etiquetas -->
        <div class="row"
             [class.mb-3]="!chatTagsChosen?.length"
             [class.mb-2]="chatTagsChosen?.length">
          <div class="col-12">
            <div class="form-floating w-100">
              <input
                id="floatingInput"
                type="text"
                class="form-control"
                [formControl]="tagsFormControl"
                placeholder="Buscar etiquetas"
                [editable]="false"
                #instance="ngbTypeahead"
                [ngbTypeahead]="searchChatTags"
                [resultFormatter]="formatResult"
                [inputFormatter]="formatInput"
                (focus)="focus$.next($any($event).target.value)"
                (click)="click$.next($any($event).target.value)">
              <label for="floatingInput">
                {{ chatTagsAvailable.length > 0 ? 'Buscar etiquetas de chats' : 'No hay más etiquetas' }}
              </label>
            </div>
          </div>
        </div>
        <!-- Muestreo de etiquetas -->
        <div class="row mb-1" *ngIf="chatTagsChosen?.length">
          <div class="col-sm-12">
            <div class="mb-1">
            <span
              *ngFor="let chatTag of chatTagsChosen"
              (click)="removeChatTag(chatTag)"
              role="button"
              class="badge badge-phoenix fs--2 me-1"
              [ngStyle]="{
                'color': chatTag.color,
                'border': '1px solid ' + chatTag.color,
              }">
              {{ chatTag.name }}
              <i class="fas fa-xmark tag-xmark-icon badge-icon"></i>
            </span>
            </div>
          </div>
        </div>
        <!-- Tipo de selección de chats por etiquetas -->
        <div class="row mb-3" *ngIf="chatTagsChosen?.length">
          <div class="col-12">
            <label class="form-label ps-0 text-primary">
              Tipo de filtro de chats por etiquetas
              <span [ngbPopover]="chatTagSearchTypeInfoPopoverContent"
                    triggers="mouseenter:mouseleave"
                    popoverTitle="Información">
              <i class="fa fa-info-circle ms-1 text-warnings"></i>
            </span>
            </label>
            <div class="btn-group" role="group">
              <button class="btn btn-secondary" type="button">Al menos una</button>
              <button class="btn btn-secondary" type="button">Todas</button>
              <button class="btn btn-secondary" type="button">Sólo seleccionadas</button>
            </div>
            <select id="chatTagSearchTypeSelect" class="form-select" *ngIf="false"
                    formControlName="chatTagSearchType">
              <option [value]="chatTagSearchType.value" *ngFor="let chatTagSearchType of chatTagSearchTypes">
                {{ chatTagSearchType.description }}
              </option>
            </select>
          </div>
        </div>
        <hr>
        <label class="form-label ps-0 text-primary">
          Selección de fecha o rango de fechas
          <span [ngbPopover]="dateRangePickerInfoPopoverContent"
                triggers="mouseenter:mouseleave"
                popoverTitle="Información">
          <i class="fa fa-info-circle ms-1"></i>
        </span>
        </label>
        <!-- Selección de fechas -->
        <div class="row mb-1">
          <div class="col-12">
            <div class="d-flex justify-content-between">
              <div class="w-100 me-2">
                <input
                  placeholder="Filtrar por rango de fechas"
                  class="form-control"
                  type="text"
                  (flatpickrReady)="dateRangeReady($event)"
                  (flatpickrClose)="setSelectedRangeDate($event)"
                  [dateFormat]="'d-m-Y H:i'"
                  [maxDate]="currentDate"
                  mwlFlatpickr
                  mode="range">
              </div>
              <button
                type="button"
                [disabled]="savingBroadcast"
                class="btn btn-secondary dropdown-toggle square-btn-md me-2"
                data-bs-toggle="dropdown">
                <i class="fa fa-gear"></i>
              </button>
              <div class="dropdown-menu">
                <a class="dropdown-item"
                   (click)="changeDateRangeFieldType(ChatSearchDangeRangeFieldType.CreationDate)"
                   [class.active]="saveBroadcastRequest.dateRangeFieldType === ChatSearchDangeRangeFieldType.CreationDate"
                   href="javascript:void(0);">
                  <i class="fa fa-calendar me-2"></i>
                  Usar fecha de inicio de chat
                </a>
                <a class="dropdown-item"
                   (click)="changeDateRangeFieldType(ChatSearchDangeRangeFieldType.LastMessageDate)"
                   [class.active]="saveBroadcastRequest.dateRangeFieldType === ChatSearchDangeRangeFieldType.LastMessageDate"
                   href="javascript:void(0);">
                  <i class="fa fa-clock me-2"></i>
                  Usar fecha de último mensaje
                </a>
              </div>
              <button
                [disabled]="savingBroadcast"
                type="button"
                class="btn btn-secondary square-btn-md"
                (click)="clearDateRange()">
                <i class="fa fa-broom"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Plantilla seleccionada y total de contactos -->
      <div class="row mb-3">
        <div class="col-12">
          <div class="d-flex justify-content-between">
            <div>
              <label class="form-label ps-0 text-primary">
                Plantilla seleccionada
              </label>
              <p class="mb-0">
                <strong class="text-success" *ngIf="saveBroadcastRequest.sendMessageRequest">
                  {{
                    saveBroadcastRequest
                      .sendMessageRequest
                      .sendWhatsAppMessageRequest
                      .template
                      .name
                  }}
                </strong>
                <strong class="text-warning" *ngIf="!saveBroadcastRequest.sendMessageRequest">
                  Sin plantilla
                </strong>
              </p>
            </div>
            <div class="text-center">
              <label class="form-label ps-0 text-primary">
                Total de contactos
              </label>
              <div class="d-flex justify-content-between">
                <button type="button" class="btn btn-sm btn-phoenix-primary me-2"
                        (click)="showBroadcastChats()">
                  <i class="fa fa-users"></i>
                  Ver ({{ selectedBroadcast?.totalChats || broadcastChatsPage?.totalElements || 0 }})
                </button>
                <div
                  [ngbPopover]="randomizeInfoPopoverContent"
                  triggers="mouseenter:mouseleave"
                  popoverTitle="Información">
                  <button type="button" class="btn btn-sm btn-phoenix-purple"
                          [disabled]="saveBroadcastRequest.fromDate === undefined || saveBroadcastRequest.toDate === undefined"
                          (click)="showRandomizeBroadcastChats()">
                    <i class="fa-duotone fa-shuffle"></i>
                    Randomizar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Status del broadcast -->
      <div class="row mb-1" *ngIf="selectedBroadcast">
        <div class="col-12">
          <div class="d-flex justify-content-between">
            <div>
              <label class="form-label ps-0 text-primary">
                Estado de envío
              </label>
              <p class="mb-0">
                <strong class="text-primary" *ngIf="selectedBroadcast.status === BroadcastStatusType.Scheduled">
                  Programado
                </strong>
                <strong class="text-success" *ngIf="selectedBroadcast.status === BroadcastStatusType.Sent">
                  Enviado
                </strong>
                <strong class="text-danger" *ngIf="selectedBroadcast.status === BroadcastStatusType.Aborted">
                  Cancelado
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>

      <hr>

      <!-- Botones -->

      <!-- Cuando el broadcast está siendo creado -->
      <ng-container *ngIf="!selectedBroadcast">
        <div class="row g-3 justify-content-end">
          <div class="col-auto">
            <button
              type="button"
              class="btn btn-phoenix-primary"
              [disabled]="savingBroadcast"
              (click)="cancelSaveBroadcast()">
              Cancelar
            </button>
          </div>
          <div class="col-auto">

            <!-- Elegir plantilla -->
            <button
              type="button"
              class="btn btn-secondary"
              *ngIf="saveBroadcastRequest.sendMessageRequest === undefined"
              [disabled]="broadcastForm.get('name').invalid || savingBroadcast || selectedScheduledForDateTime === undefined"
              (click)="openChatTemplateSender()">
              Elegir plantilla
              <i class="fa fa-arrow-right ms-1"></i>
            </button>

            <!-- Cambiar plantilla -->
            <button
              type="button"
              class="btn btn-secondary"
              *ngIf="saveBroadcastRequest.sendMessageRequest !== undefined"
              [disabled]="broadcastForm.get('name').invalid || savingBroadcast || selectedScheduledForDateTime === undefined"
              (click)="openChatTemplateSender()">
              Cambiar plantilla
              <i class="fa fa-rotate-left ms-1"></i>
            </button>

            <!-- Programar broadcast -->
            <button
              type="submit"
              class="btn btn-success ms-2"
              *ngIf="!savingBroadcast && saveBroadcastRequest.sendMessageRequest !== undefined"
              [disabled]="!broadcastForm.valid">
              <i class="fa fa-calendar"></i>
              Programar
            </button>

            <!-- Bóton guardando -->
            <button
              class="btn btn-primary ms-2"
              *ngIf="savingBroadcast"
              disabled>
              <span class="spinner-border spinner-border-sm"></span>
              Guardando...
            </button>

          </div>
        </div>
      </ng-container>

      <!-- Cuando el broadcast es futuro y se puede editar -->
      <ng-container *ngIf="selectedBroadcast && selectedBroadcast.scheduledFor >= currentDate">
        <div class="row g-2 justify-content-end mb-2">
          <!-- Abortar envío -->
          <div class="col-auto">
            <button
              type="button"
              class="btn btn-phoenix-danger"
              *ngIf="selectedBroadcast.status !== BroadcastStatusType.Aborted"
              [disabled]="savingBroadcast"
              (click)="abortBroadcast(selectedBroadcast)">
              <i class="fa fa-stop ms-1"></i>
              Abortar
            </button>
          </div>
          <!-- Cambiar plantilla -->
          <div class="col-auto">
            <button
              type="button"
              class="btn btn-phoenix-primary"
              [disabled]="savingBroadcast"
              (click)="openChatTemplateSender()">
              Cambiar plantilla
              <i class="fa fa-rotate-left ms-1"></i>
            </button>
          </div>
        </div>
        <div class="row g-2 justify-content-end">
          <!-- Cerrar edición de broadcast -->
          <div class="col-auto">
            <button
              type="button"
              class="btn btn-phoenix-secondary"
              [disabled]="savingBroadcast"
              (click)="cancelSaveBroadcast()">
              Cancelar
            </button>
          </div>
          <!-- Programar broadcast -->
          <div class="col-auto" *ngIf="!savingBroadcast && saveBroadcastRequest.sendMessageRequest !== undefined">
            <button
              type="submit"
              class="btn btn-phoenix-success"
              *ngIf="selectedBroadcast.status !== BroadcastStatusType.Aborted"
              [disabled]="!broadcastForm.valid || savingBroadcast">
              <i class="fa-duotone fa-save"></i>
              Guardar
            </button>
            <button
              type="submit"
              class="btn btn-phoenix-success"
              *ngIf="selectedBroadcast.status === BroadcastStatusType.Aborted"
              [disabled]="!broadcastForm.valid || savingBroadcast">
              <i class="fa-duotone fa-calendar-clock"></i>
              Reactivar
            </button>
          </div>
          <!-- Bóton guardando -->
          <div class="col-auto" *ngIf="savingBroadcast">
            <button
              class="btn btn-primary"
              disabled>
              <span class="spinner-border spinner-border-sm"></span>
              Guardando...
            </button>
          </div>
        </div>
      </ng-container>

      <!-- Cuando el broadcast ya es pasado (Sin implementar) -->
      <ng-container *ngIf="selectedBroadcast && selectedBroadcast.scheduledFor < currentDate">

      </ng-container>

    </form>
  </div>
</ng-template>

<ng-template #chatTemplateSenderOffCanvas>
  <app-chat-template-sender></app-chat-template-sender>
</ng-template>

<ng-template #advisorsInfoPopoverContent>
  <p class="mb-1">
    Si un chat está asignado a un <strong class="text-primary">asesor</strong> seleccionado, el chat será filtrado por este.
  </p>
</ng-template>

<ng-template #chatTagsInfoPopoverContent>
  <p class="mb-1">
    Elija las etiquetas de los chats que desea filtrar y a continuación, el <b class="text-primary">tipo de filtro</b> que desea aplicar.
  </p>
</ng-template>

<ng-template #chatTagSearchTypeInfoPopoverContent>
  <p class="mb-1">
    <b class="text-primary">Al menos una:</b> Selecciona los chats que tengan al menos una de las etiquetas seleccionadas. <br><br>
    <b class="text-primary">Todas:</b> Selecciona los chats que tengan todas las etiquetas seleccionadas. <br><br>
    <b class="text-primary">Sólo seleccionadas:</b> Selecciona los chats que tengan exactamente las etiquetas seleccionadas.
  </p>
</ng-template>

<ng-template #dateRangePickerInfoPopoverContent>
  <p class="mb-1">
    Se tomarán en cuenta los chats que se encuentren dentro del rango de fechas seleccionado. <br> <br>
    <b class="text-primary">Nota:</b> si desea filtrar por una fecha específica, seleccione el mismo día en ambas fechas.
  </p>
</ng-template>

<ng-template #randomizeInfoPopoverContent>
  <p class="mb-1">
    Primero elija un <span class="text-success">rango de fechas</span> y luego, elija la cantidad de contactos que desea obtener <span class="text-purple">aleatoriamente</span>.
  </p>
</ng-template>

<ng-template #broadcastChatsModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      Chats del broadcast ({{ selectedBroadcast?.totalChats || broadcastChatsPage?.totalElements || 0 }})
    </h4>
    <button
      type="button"
      class="btn-close"
      (click)="modal.close()">
    </button>
  </div>
  <div class="modal-body">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <div *ngIf="broadcastChatsPage.content.length === 0">
            <h4 class="text-muted">
              No hay chats
            </h4>
          </div>
          <div class="broadcast-chats-preview-container scrollbar" id="broadcastChatsPreviewContainer">
            <ng-container
              infiniteScroll
              [infiniteScrollDistance]="3"
              [infiniteScrollThrottle]="50"
              [infiniteScrollContainer]="'#broadcastChatsPreviewContainer'"
              [fromRoot]="true"
              (scrolled)="onScrolledBroadcastChats()">
              <ng-container *ngFor="let chat of broadcastChatsPage.content">
                <div class="d-flex align-items-center justify-content-center chat-list-item p-2">
                  <div class="
                    avatar avatar-xl
                    status-online
                    position-relative
                    me-2">
                    <div
                      class="avatar-name rounded-circle"
                      [ngStyle]="{'color': chat.color}">
                      <span>{{ chat.chatName[0] }}</span>
                    </div>
                  </div>
                  <div class="flex-1 d-xl-block">
                    <div class="d-flex justify-content-between align-items-center">
                      <h5 class="text-900 fw-normal name text-truncate chat-name">
                        {{ chat.chatName }}
                      </h5>
                      <p class="fs--2 text-800 mb-0" *ngIf="chat.lastMessageSentTime">
                        {{ chat.lastMessageSentTime | date: 'dd/MM/yyyy HH:mm:ss' }}
                      </p>
                    </div>
                    <div class="d-flex justify-content-between">
                      <p class="fs--1 mb-0 text-600 message text-truncate last-message-label text-truncate" *ngIf="chat.lastMessageSentTime">
                        {{ chat.lastMessageTypeLabel }}
                      </p>
                      <p class="fs--1 mb-0 text-600 message text-truncate text-warning last-message-label text-truncate" *ngIf="!chat.lastMessageSentTime">
                        {{ chat.lastMessageTypeLabel }}
                      </p>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #randomizeBroadcastChatsModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      Obtener chats aleatorios de tu selección
    </h4>
    <button
      type="button"
      class="btn-close"
      (click)="modal.close()">
    </button>
  </div>
  <div class="modal-body">
    <div class="card-body">
      <div class="row mb-3">
        <div class="col-12">
          <div class="form-floating">
            <input
              class="form-control"
              id="randomizeBroadcastChatsTotal"
              type="text"
              maxlength="6"
              placeholder="Nombre del tópico"
              name="randomizeTotal"
              [(ngModel)]="saveBroadcastRequest.randomizeTotal"
              (keydown)="validateOnlyNumbers($event)"
              [ngClass]="{
                'is-invalid': !saveBroadcastRequest.randomizeTotal,
                'is-valid': saveBroadcastRequest.randomizeTotal > 0
              }">
            <label for="randomizeBroadcastChatsTotal">
              Cantidad de contactos
            </label>
            <div
              *ngIf="saveBroadcastRequest.randomizeTotal === 0"
              class="invalid-feedback text-warning">
              Ingrese una cantidad de contactos, por favor.
            </div>
          </div>
        </div>
      </div>
      <div class="row g-3 justify-content-end">
        <div class="col-auto">
          <button
            type="button"
            class="btn btn-phoenix-primary"
            (click)="modal.close()">
            Cancelar
          </button>
        </div>
        <div class="col-auto">
          <button
            class="btn btn-primary"
            (click)="randomizeBroadcastChats()"
            [disabled]="!saveBroadcastRequest.randomizeTotal">
            <i class="fas fa-check me-1"></i>
            Aceptar
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>