
export class SaveMessengerConfigRequest {

  shopId: number;
  userId: string;
  accessToken: string;

  constructor(shopId: number, userId: string, accessToken: string) {
    this.shopId = shopId;
    this.userId = userId;
    this.accessToken = accessToken;
  }

}
